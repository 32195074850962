import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import MypagInquiry from 'components/views/MypagInquiry/MypagInquiry';

export default function MypagInquiryWrapper({
    title,
    content,
    onChange,
    onUpload,
    onRemove,
    onSubmit,
    files,
}) {
    return (
        <>
            <Header />
            <MypagInquiry
                title={title}
                content={content}
                onChange={onChange}
                onUpload={onUpload}
                onRemove={onRemove}
                onSubmit={onSubmit}
                files={files}
            />
            <Footer />
        </>
    )
}
