import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Partner.module.scss';
import CancelSolutionButton from 'components/common/CancelSolutionButton';
import { NavLink } from 'react-router-dom';
import Popup from '../Popup/Popup';
import PrevPopup from '../PrevPopup';

const cx = classNames.bind(styles);

export default function Partner() {
  const [buttonPopup, setButtonPopup] = useState({
    checklist: false,
    prevPopup: false,
  });

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>파트너사 사업주 모집</h4>
            </div>
            <div className={cx('content_inner')}>
              <div className={cx('content_sub_inner')}>
                <div className={cx('iframe_bg')}>
                  <iframe
                    src="https://o22d6pnr56k.typeform.com/to/Ko6R1OiZ"
                    frameBorder="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
