import React from 'react';
import classNames from 'classnames/bind';
import styles from './TimeContent.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function TimeContent() {
  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <div className={cx('inner_box')}>
          <div {...useScrollFadeIn('up', 1, 0.4)} className={cx('title_box')}>
            <h4 className={cx('title')}>
              저렴한 가격으로, <br className={cx('mobile_br')} />
              <b className={cx('title_color')}>하루 만에 설치</b>
            </h4>
          </div>
          <div
            {...useScrollFadeIn('bottom', 1, 0.8)}
            className={cx('text_box')}
          >
            <p className={cx('text')}>
              일반적인 인테리어보다 훨씬 저렴하고,
              <br className={cx('mobile_br')} /> 단 하루면 인테리어가
              완성됩니다.
            </p>
          </div>
          <div
            {...useScrollClipPath('bottom', 1, 1.0)}
            className={cx('time_bg_box')}
          >
            <img
              className={cx('time_bg')}
              src="/statics/images/time_bg.png"
              alt="time_bg"
            />
          </div>
          {/* 모바일 */}
          <div
            {...useScrollClipPath('right', 1, 0.4)}
            className={cx('mobile_time_bg_box')}
          >
            <img
              className={cx('mobile_time_bg')}
              src="/statics/images/time_bg.png"
              alt="time_bg"
            />
          </div>
          <img
            {...useScrollClipPath('bottom', 1, 1.0)}
            className={cx('time_box_img')}
            src="/statics/images/time.png"
            alt="time"
          />
        </div>
      </div>
    </div>
  );
}
