import React, {
    useState,
} from 'react';
import * as api from 'apis';
import MypagInquiryWrapper from 'components/MypagInquiryWrapper/MypagInquiryWrapper';

export default function MypagInquiryContainer() {
    const [data, setData] = useState({
        title: '',
        content: '',
        files: [],
    });

    const handleChange = (e) => {
        setData(prev => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleUpload = async (e) => {
        let formData = new FormData();
        [...e.target.files].forEach((file) => {
            formData.append(e.target.name, file);
        });

        try {
            const res = await api.uploadFiles(formData);
            setData(prev => ({ ...prev, files: [...prev.files, ...res.data] }));
        } catch (err) {
            console.log(err);
        }
        e.target.value = null;
    };

    const handleRemove = (e, index) => {
        e.preventDefault();
        const tempFiles = [...data.files].filter((item, i) => i !== index);
        setData(prev => ({ ...prev, files: tempFiles }));
    };

    const handleSubmit = async () => {
        try {
            const res = await api.postInquiry({
                fileUrl: data.files.map((el) => el.url),
                title: data.title,
                content: data.content,
            });
            setData({
                title: '',
                content: '',
                files: [],
            });
            alert('빠른 시일내에 답변드리도록 하겠습니다 : )');
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <MypagInquiryWrapper
            title={data.title}
            content={data.content}
            files={data.files}
            onChange={handleChange}
            onUpload={handleUpload}
            onRemove={handleRemove}
            onSubmit={handleSubmit}
        />
    )
}
