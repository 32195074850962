import React, { useRef, useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';
import * as api from 'apis';
import styles from './FileUpload.module.scss';

const cx = classNames.bind(styles);
const imgs = ['gif', 'jpg', 'jpeg', 'png', 'bmp', 'ico', 'apng'];

export default function FileUpload({ onChangeImage, index, file, style }) {
  // 파일 업로드
  const fileRef = useRef(null);

  const onClickUpload = () => {
    fileRef.current?.click();
  };

  const onChangeUpload = useCallback(
    async (e) => {
      const file = e.target.files?.[0];
      if (!file) {
        return;
      }

      // api 들어갈곳
      try {
        let formData = new FormData();
        formData.append(e.target.name, e.target.files[0]);
        const res = await api.uploadFiles(formData);
        onChangeImage(res?.data[0], index);
      } catch (error) {
        console.log(error.message);
      }
    },
    [index],
  );

  const isImage = useMemo(() => {
    if (!file?.type) return false;
    return imgs.some((type) => file?.type?.indexOf(type) > -1);
  }, [file]);

  const handleDownload = useCallback(() => {
    if (!file?.url) return;
    try {
      fetch(file.url)
        .then((resp) => resp.arrayBuffer())
        .then((resp) => {
          // set the blog type to final pdf
          const blobFile = new Blob([resp], { type: file?.type });

          // process to auto download it
          const fileURL = URL.createObjectURL(blobFile);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = file.filename;
          link.click();
          link.remove();
        });
    } catch (err) {}
  }, [file]);

  const handleRemove = useCallback(() => {
    onChangeImage({}, index);
  }, [index]);

  const format = useMemo(() => {
    if (!file?.filename) return;
    const list = file?.filename?.split('.');
    return list[list.length - 1];
  }, [file]);

  return (
    <div className={cx('upload_wrapper')}>
      {!!file?.url ? (
        <div className={cx('file_upload_preview_box')} style={style}>
          {isImage ? (
            <img
              className={cx('file_upload_img')}
              src={file?.url}
              alt="upload_image"
            />
          ) : (
            <div
              className={cx('file_upload_file')}
              role="presentation"
              onClick={handleDownload}
            >
              {format}
            </div>
          )}
          <img
            className={cx('file_upload_delete_img')}
            src="/statics/images/ic_photo_del.png"
            alt="upload_button"
            onClick={handleRemove}
          />
        </div>
      ) : (
        <div
          className={cx('file_upload_button')}
          style={style}
          onClick={onClickUpload}
        >
          <img src="/statics/images/ic_photo_add.png" alt="upload_button" />
        </div>
      )}
      <input
        className={cx('hidden_file_input')}
        ref={fileRef}
        onChange={onChangeUpload}
        type="file"
      />
    </div>
  );
}
