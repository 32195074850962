import React, { useEffect } from 'react';
import * as api from 'apis';
import useContract from 'hooks/useContract';
import MypageScheduleAccessWrapper from 'components/MypageScheduleAccessWrapper/MypageScheduleAccessWrapper';

export default function MypageScheduleAccessContainer() {
  const {
    contractId,
    visitDate,
    visitManagerName,
    visitManagerPhone,
    visitManagerProfileImageUrl,
    handleChangeContractData,
  } = useContract();

  useEffect(() => {
    (async () => {
      try {
        const res = await api.getMyContract();
        handleChangeContractData({
          contractId: res?.data?.id,
          contractFile: res?.data?.clientSignPdfUrl,
          visitDate: res?.data?.visitDate,
          visitManagerName: res?.data?.visitManagerName,
          visitManagerPhone: res?.data?.visitManagerPhone,
          visitManagerProfileImageUrl: res?.data?.visitManagerProfileImageUrl,
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, [handleChangeContractData]);

  return (
    <MypageScheduleAccessWrapper
      visitDate={visitDate}
      visitManagerName={visitManagerName}
      visitManagerPhone={visitManagerPhone}
      visitManagerProfileImageUrl={visitManagerProfileImageUrl}
    />
  );
}
