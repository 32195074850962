import React, { useState } from 'react'
import classNames from 'classnames/bind';
import styles from './SignupPasswordProcess.module.scss';

const cx = classNames.bind(styles);

export default function SignupPasswordProcess({
    password,
    handleChange,
    isPasswordError,
    handlePasswordProcess,
}) {
    /* password 숨기기 */
    const [passwordType, setPasswordType] = useState({
        type: 'password',
        visible: false
    });

    const handlePasswordType = (e) => {
        e.preventDefault();
        setPasswordType(() => {
            if (!passwordType.visible) {
                return { type: 'text', visible: true };
            }
            return { type: 'password', visible: false };
        })
    }

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <div className={cx('password_box')}>
                            <div className={cx('step_box')}>
                                <p className={cx('step_text')}>
                                    <b className={cx('step_text_b')}>2 </b>/ 3 단계
                                </p>
                            </div>
                            <div className={cx('title_box')}>
                                <h4 className={cx('title')}>
                                    비밀번호를 정해주세요!
                                </h4>
                            </div>
                            <div className={cx('password_input_box')}>
                                <div className={cx('password_img_box')}>
                                    <input
                                        className={cx('password_input')}
                                        type={passwordType.type}
                                        placeholder="숫자와 특수문자 1개포함, 8자이상"
                                        name="password"
                                        value={password}
                                        onChange={handleChange}
                                    />
                                    <button className={cx('password_view_img')} onClick={handlePasswordType}>
                                        {
                                            passwordType.visible ?
                                                <img src="/statics/images/ic_pw_visibility_on.png" alt="hide" /> :
                                                <img src="/statics/images/ic_pw_visibility_off.png" alt="view" />
                                        }
                                    </button>
                                </div>
                                {
                                    isPasswordError.format &&
                                    <div className={cx('password_fail_box')}>
                                        <img className={cx('password_fail_img')} src="/statics/images/ic_input_info_error.png" alt="info_check" />
                                        <p className={cx('password_fail_text')}>숫자와 특수문자 1개 포함, 8자 이상 입력해주세요.</p>
                                    </div>
                                }
                            </div>
                            <div className={cx('button_box')}>
                                <button className={cx('button')} onClick={handlePasswordProcess}>다음</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
