import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './MypagePaymentHistoryDetail.module.scss';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MypagePaymentHistoryDetailList from './MypagePaymentHistoryDetailList';
// import RefundPopup from './RefundPopup';
import { numberWithCommas } from 'libs/numbers';
import ExamplePopup from 'components/views/ExamplePopup';
import { ADVERTISE_STATUS } from 'constants/advertisement';
import moment from 'moment';

const cx = classNames.bind(styles);

export default function MypagePaymentHistoryDetail({
  data,
  handleClick,
  selectedData,
  isOpen,
  onClose,
}) {
  const [popup, setPopup] = useState(false);
  const navigator = useNavigate();

  // #ff5360

  return (
    <>
      <div className={cx('container')}>
        <div className={cx('inner')}>
          <button onClick={() => navigator(-1)} className={cx('back_box')}>
            <img
              className={cx('back_img')}
              src="/statics/images/ic_profile_back.png"
              alt="ic_profile_back"
            />
            <div className={cx('back_text_box')}>
              <p className={cx('back_text')}>뒤로 가기</p>
            </div>
          </button>
          <div className={cx('order_box')}>
            <p className={cx('order_text')}>
              구매 번호
              <b className={cx('order_number')}>{data?.merchant_uid}</b>
            </p>
          </div>
          <div className={cx('order_inner')}>
            <div
              className={cx('media_box')}
              role="presentation"
              onClick={handleClick}
            >
              <video
                className={cx('artist_media_img')}
                src={data?.videoUrl}
              ></video>
              <div className={cx('media_play_button_box')}>
                <img
                  className={cx('media_play_button_img')}
                  src="/statics/images/media_play_button_arrow.png"
                  alt="재생 버튼"
                />
              </div>
            </div>
            <div className={cx('info_box')}>
              <div className={cx('play_info_box')}>
                <p className={cx('play_info')}>{`${moment(
                  data?.startDate,
                ).format('YYYY.MM.DD')} ~ ${moment(data?.endDate).format(
                  'YYYY.MM.DD',
                )}`}</p>
              </div>
              <div className={cx('select_info_box')}>
                <p className={cx('select_info')}>
                  상태 :{' '}
                  <span
                    className={cx(
                      'select_info',
                      data?.status === 'READY' && 'mobile_tbody_td_wating',
                      data?.status === 'PAID' && 'mobile_tbody_td_completion',
                      data?.status === 'RUNNING' && 'mobile_tbody_td_rogress',
                      data?.status === 'REJECTED' && 'mobile_tbody_td_refuse',
                      data?.status === 'FAIL' && 'mobile_tbody_td_refuse',
                      data?.status === 'REFUNDED' && 'mobile_tbody_td_refuse',
                      data?.status === 'REQUEST_REFUND' &&
                        'mobile_tbody_td_refuse',
                      data?.status === 'FINISH' &&
                        'mobile_tbody_td_advertisement',
                    )}
                  >
                    {ADVERTISE_STATUS[data?.status] || '-'}
                    {data?.status !== 'REFUNDED' && data?.isRejected && (
                      <span className={cx('refuse_date')}>
                        (
                        {moment(data?.rejectedDate).format(
                          'YYYY-MM-DD HH:mm:ss',
                        )}
                        )
                      </span>
                    )}
                    {data?.status === 'REFUNDED' && data?.isRefunded && (
                      <span className={cx('refuse_date')}>
                        (
                        {moment(data?.refundedDate).format(
                          'YYYY-MM-DD HH:mm:ss',
                        )}
                        )
                      </span>
                    )}
                  </span>
                </p>
              </div>
              <div className={cx('select_info_box')}>
                <p className={cx('select_info')}>
                  총 매체수: {data?.advertises?.length || ' - '}EA
                </p>
                <p className={cx('select_info_price')}>
                  {(data?.price && numberWithCommas(data?.price)) || ' - '}원
                </p>
              </div>
              {/* {data?.status === 'REQUEST_REFUND' && (
                <div className={cx('refuse_info_box')}>
                  환불 신청 사유
                  <p className={cx('refuse_info')}>{data?.refundText}</p>
                </div>
              )} */}
              {data?.isRejected && (
                <div className={cx('refuse_info_box')}>
                  광고 거절 사유
                  <p className={cx('refuse_info')}>{data?.rejectedText}</p>
                </div>
              )}
              {/* {data?.isRefunded && (
                <div className={cx('refuse_info_box')}>
                  환불 금액 :{' '}
                  <>{numberWithCommas(data?.refundAmount) || ' - '} 원</>
                </div>
              )} */}
              {/* {(data?.status === 'PAID' || data?.status === 'RUNNING') && (
                <div className={cx('button_box')}>
                  <button
                    onClick={() => setPopup((prev) => !prev)}
                    className={cx('button')}
                  >
                    환불하기
                  </button>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <MypagePaymentHistoryDetailList
        data={data?.advertises}
        startDate={data?.startDate}
        endDate={data?.endDate}
      />
      {/* {popup && <RefundPopup advertiseOrder_id={data?.id} />} */}
      <ExamplePopup data={selectedData} isShow={isOpen} onClose={onClose} />
    </>
  );
}
