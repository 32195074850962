import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './SolutionSuccess.module.scss';
import { NavLink } from 'react-router-dom';
import Popup from '../Popup/Popup';

const cx = classNames.bind(styles);

export default function SolutionSuccess() {
  const [buttonPopup, setButtonPopup] = useState(false);

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <div
              className={cx('checklist_button')}
              onClick={() => setButtonPopup(true)}
            >
              <div className={cx('checklist_icon_box')}>
                <img
                  className={cx('checklist_icon')}
                  src="/statics/images/check_list.png"
                  alt="check_list"
                />
              </div>
              <div className={cx('hide_text_box')}>
                <p className={cx('hide_text')}>체크리스트 보기</p>
              </div>
            </div>
            <div className={cx('img_box')}>
              <img
                src="/statics/images/img_solution_estimate.png"
                alt="img_solution_estimate"
              />
            </div>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>
                나만의 멋진 미디어 인테리어, 마음껏 PLAY 하세요!
              </h4>
            </div>
            <div className={cx('text_box')}>
              <p className={cx('text')}>계약이 완료되었습니다.</p>
              <p className={cx('text')}>
                계약 현황은 마이페이지에서 확인하실 수 있습니다.
              </p>
            </div>
            <div className={cx('button_box')}>
              <NavLink to="/play-list/search" className={cx('button')}>
                콘텐츠 둘러보기
              </NavLink>
            </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} />
          </div>
        </div>
      </div>
    </div>
  );
}
