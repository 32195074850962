import React from 'react';
import Header from 'components/views/Header';
import Signup from 'components/views/Signup';

export default function SiginupWrapper({
    signup,
    isOpenPost,
    handleOpenPost,
    handleClosePost,
    isEmailSuccess,
    isEmailError,
    isCheckedEmail,
    handleEmail,
    handleChange,
    handleAgree,
    handleAllAgree,
    emailProcessDisabled,
    handleEmailProcess,
    isPasswordError,
    handlePasswordProcess,
    isPhoneError,
    isBusinessError,
    isPhoneExpired,
    isCodeError,
    handleCode,
    handleVerifiedPhone,
    startTime,
    endTime,
    handleEditPhone,
    handleClickAddress,
    handleCheckBusinessNumber,
    handleClickGender,
    handleSubmit,
    handlePrivacy,
    handleTerms,
}) {
    return (
        <>
            <Header />
            <Signup
                signup={signup}
                isEmailSuccess={isEmailSuccess}
                isEmailError={isEmailError}
                isCheckedEmail={isCheckedEmail}
                isOpenPost={isOpenPost}
                handleOpenPost={handleOpenPost}
                handleClosePost={handleClosePost}
                handleEmail={handleEmail}
                handleChange={handleChange}
                handleAgree={handleAgree}
                handleAllAgree={handleAllAgree}
                emailProcessDisabled={emailProcessDisabled}
                handleEmailProcess={handleEmailProcess}
                isPasswordError={isPasswordError}
                handlePasswordProcess={handlePasswordProcess}
                isPhoneError={isPhoneError}
                isBusinessError={isBusinessError}
                isPhoneExpired={isPhoneExpired}
                isCodeError={isCodeError}
                handleCode={handleCode}
                handleVerifiedPhone={handleVerifiedPhone}
                startTime={startTime}
                endTime={endTime}
                handleEditPhone={handleEditPhone}
                handleClickAddress={handleClickAddress}
                handleCheckBusinessNumber={handleCheckBusinessNumber}
                handleClickGender={handleClickGender}
                handleSubmit={handleSubmit}
                handlePrivacy={handlePrivacy}
                handleTerms={handleTerms}
            />
        </>
    )
}
