import React from 'react';
import { Footer, Header } from 'components/views';
import IntroAdvertisement from 'components/views/IntroAdvertisement';

export default function IntroAdvertisementWrapper() {
  return (
    <>
      <Header />
      <IntroAdvertisement />
      <Footer />
    </>
  );
}
