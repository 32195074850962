import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionChoiceProduct from 'components/views/SolutionChoiceProduct/SolutionChoiceProduct';
import Footer from 'components/views/Footer/Footer';

export default function SolutionChoiceProductWrapper({
    hardware,
    onSubmit,
}) {
    return (
        <>
            <Header />
            <SolutionChoiceProduct
                hardware={hardware}
                onSubmit={onSubmit}
            />
        </>
    )
}
