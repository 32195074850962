import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import MypagAsDetail from 'components/views/MypagAsDetail/MypagAsDetail';

export default function MypagAsDetailWrapper({
  data,
  fileUrls,
  handleFileUrls,
}) {
  return (
    <>
      <Header />
      <MypagAsDetail
        data={data}
        fileUrls={fileUrls}
        handleFileUrls={handleFileUrls}
      />
      <Footer />
    </>
  );
}
