import React, { useEffect, useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import classNames from 'classnames/bind';
import styles from './SearchAddress.module.scss';

const cx = classNames.bind(styles);

const SearchAddress = ({
  isOpenPost,
  handleClosePost,
  handleClickAddress,
}) => {

  const onCompletePost = (data) => {
    let fullAddress = data.address;
    let extraAddr = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddr += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddr !== '' ? ` (${extraAddr})` : '';
    }
    handleClickAddress(data.zonecode, fullAddress, data.sido);
    handleClosePost();
  };


  const postCodeStyle = {
    width: '100vw',
    height: '100vh',
    maxWidth: '400px',
    maxHeight: '400px',
    padding: '10px',
    border: '1px solid #dfdfdf',
    borderRadius: '20px',
    backgroundColor: '#fff',
  };



  return (
    <>
      {
        isOpenPost &&
        <div className={cx('box_post', { active: isOpenPost })}>
          <button className={cx('btn_close')} onClick={handleClosePost}></button>
          <DaumPostcode style={postCodeStyle} autoClose onComplete={onCompletePost} />
        </div>
      }
    </>
  );
};

export default SearchAddress;