import React from 'react';
import classNames from 'classnames/bind';
import styles from './ContentMain.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function ContentMain() {
  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <div className={cx('box')}>
          <div className={cx('text_inner')}>
            <div {...useScrollFadeIn('up', 1, 0.4)} className={cx('title_box')}>
              <h2 className={cx('title')}>
                <b className={cx('title_color')}>
                  클릭 한 번으로 바뀌는 인테리어{' '}
                </b>
                <br className={cx('pc_br')} />
                차원이 다른 편리함을 느껴보세요!
              </h2>
            </div>
            <div
              {...useScrollFadeIn('bottom', 1, 0.8)}
              className={cx('text_box')}
            >
              <p className={cx('text')}>
                많은 비용과 시간은 이제 그만!
                <br className={cx('mobile_br')} /> 단 1분이면 인테리어를 바꿀 수
                있습니다.
              </p>
            </div>
          </div>
          <div {...useScrollClipPath('up', 1, 0.4)} className={cx('img_inner')}>
            <div
              {...useScrollFadeIn('up', 1, 0.6)}
              className={cx('left_img_box')}
            >
              <div
                style={{
                  backgroundImage: 'url(/statics/images/flower_garden.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/mirrorball.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/fireplace.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/pink_beach.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/pink_cloud.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/particle.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/mirrorball.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
            </div>
            <div
              {...useScrollFadeIn('up', 1, 0.6)}
              className={cx('center_img_box')}
            >
              <div
                style={{
                  backgroundImage: 'url(/statics/images/moon_beach.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/flawless.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/rainbow_particle.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/halloween.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/beach.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/abstract_wave.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/palm_tree.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/whale.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/rainbow_particle.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
            </div>
            <div
              {...useScrollFadeIn('up', 1, 0.6)}
              className={cx('right_img_box')}
            >
              <div
                style={{
                  backgroundImage: 'url(/statics/images/space.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/curve.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/earth.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/window.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/cloud_moon.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/rainbow_palm_tree.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
              <div
                style={{
                  backgroundImage: 'url(/statics/images/flower_particle.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={cx('img_box')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
