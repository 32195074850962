import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionInstallmentExaminationContractComplete from 'components/views/SolutionInstallmentExaminationContractComplete/SolutionInstallmentExaminationContractComplete';

export default function SolutionInstallmentExaminationContractCompleteWrapper() {
    return (
        <>
            <Header />
            <SolutionInstallmentExaminationContractComplete />
        </>
    )
}
