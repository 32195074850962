import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './MediaContent.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function MediaContent({ usingExamples, onExampleClick }) {
  const scollButton = useRef(null);

  const slide = (shift) => {
    scollButton.current.scrollLeft += shift;
  };

  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <div className={cx('text_inner')}>
          <div {...useScrollFadeIn('up', 1, 0.4)} className={cx('title_box')}>
            <h4 className={cx('title')}>이유가 확실한 인기</h4>
          </div>
          <div
            {...useScrollFadeIn('bottom', 1, 0.8)}
            className={cx('text_box')}
          >
            <p className={cx('text')}>
              이미 많은 분들에게 사랑받는 다양한 미디어 인테리어 사례를 구경해
              보세요.
            </p>
          </div>
        </div>
        <div className={cx('media_wrapper')}>
          <div className={cx('media_theme_left_button_box')}>
            <img
              onClick={() => slide(-350)}
              className={cx('hover_media_left_button')}
              src="/statics/images/ic-viausl-prev-on.png"
              alt="media_arrow"
            />
          </div>
          <div className={cx('media_theme_right_button_box')}>
            <img
              onClick={() => slide(+350)}
              className={cx('hover_media_right_button')}
              src="/statics/images/ic-viausl-next-on.png"
              alt="media_arrow"
            />
          </div>
          <div {...useScrollClipPath('left', 1, 1.2)}>
            <div className={cx('media_inner')} ref={scollButton}>
              {usingExamples?.map((el, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => onExampleClick(el, i)}
                    role="presentation"
                    className={cx('media_box')}
                  >
                    <div className={cx('media_sub_box')}>
                      <div className={cx('media_play_button_box')}>
                        <img
                          className={cx('media_play_button')}
                          src="/statics/images/media_play_button_arrow.png"
                          alt="media_play_button"
                        />
                      </div>
                      <img
                        className={cx('media_play_img')}
                        src={el?.thumbnailImageUrl}
                        alt="media"
                      />
                    </div>
                    <div className={cx('media_infor_box')}>
                      <div className={cx('media_infor_icon_box')}>
                        <img
                          className={cx('media_infor_icon')}
                          src={el?.profileImageUrl}
                          alt="icon"
                        />
                        <div className={cx('media_infor_text_box')}>
                          <p className={cx('media_infor_text')}>
                            {el?.name}({el?.location})
                          </p>
                        </div>
                      </div>
                      {el.type === 'LED' ? (
                        <div className={cx('media_infor_LED_box')}>
                          <p className={cx('media_infor_LED_text')}>LED</p>
                        </div>
                      ) : (
                        <div className={cx('media_infor_vim_box')}>
                          <p className={cx('media_infor_vim_text')}>
                            빔프로젝터
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
