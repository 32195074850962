import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import Mypage from 'components/views/Mypage/Mypage';

export default function MypageWrapper({
    email,
    name,
    profileImageUrl,
    grade,
    handleChangeProfileImage,
    wishArtistPlayList,
    isUnlimitedPlaylist,
}) {
    return (
        <>
            <Header />
            <Mypage
                email={email}
                name={name}
                grade={grade}
                profileImageUrl={profileImageUrl}
                handleChangeProfileImage={handleChangeProfileImage}
                wishArtistPlayList={wishArtistPlayList}
                isUnlimitedPlaylist={isUnlimitedPlaylist}
            />
            <Footer />
        </>
    )
}
