import React from 'react';
import { Header } from 'components/views';
import SolutionInstallRequest from 'components/views/SolutionInstallRequest';

export default function SolutionInstallRequestWrapper({ phone, onClick, onDownload }) {
  return (
    <>
      <Header />
      <SolutionInstallRequest phone={phone} onClick={onClick}
        onDownload={onDownload} />
    </>
  );
}
