import React from 'react';
import RouteChangeTracker from './RouteChangeTracker';
import PixelTracker from './PixelTracker';
import { Routes, Route } from 'react-router-dom';
import {
  IndexPage,
  IntroducePage,
  LoginPage,
  SignupPage,
  FindPasswordPage,
  PlayListPage,
  PlayListSearchPage,
  MediaPlayListPage,
  IntroExamplePage,
  IntroServicePage,
  SolutionChoiceProductPage,
  SolutionRequestEstimateLedPage,
  SolutionRequestEstimateBeamPage,
  SolutionRequestEstimatePlacePage,
  SolutionRequestEstimateSolutionPage,
  SolutionPublishEstimateWaitPage,
  SolutionPublishEstimateSuccessPage,
  SolutionPublishEstimatePaymentPage,
  SolutionInstallmentExaminationChoicePage,
  SolutionInstallmentExaminationReviewPage,
  SolutionInstallmentExaminationCompletedPage,
  SolutionInstallmentExaminationInfoPage,
  SolutionInstallmentExaminationConfirmPage,
  SolutionInstallmentExaminationPaymentPage,
  SolutionInstallmentExaminationPaymentCompletePage,
  SolutionInstallmentExaminationContractCompletePage,
  SolutionSchedulePage,
  SolutionSuccessPage,
  MypagePage,
  MypageEditPage,
  MypagePasswordPage,
  MypageSolutionPage,
  MypagePaymentPage,
  MypageScheduleAccessPage,
  MypageScheduleWaitingPage,
  MypageScheduleFailPage,
  MypagFaqPage,
  MypagInquiryPage,
  MypagAsPage,
  MypagAsListPage,
  MypagAsDetailPage,
  MypaAsInquiryPage,
  MypageScheduleEditPage,
  MypageSecessionPage,
  SolutionRentalWaitPage,
  SolutionRentalCompletePage,
  SolutionDepositWaitPage,
  SolutionDepositCheckPage,
  SolutionDepositCompletePage,
  SolutionSealPage,
  SolutionSealCompletePage,
  SolutionRentalInfoPage,
  SolutionContractWaitingPage,
  SolutionContractCompletePage,
  SolutionScheduleCompletePage,
  PlayPage,
  PrivacyPage,
  TermsPage,
  SolutionInstallRequestPage,
  SolutionInstallSuccessPage,
  SolutionInstallFailPage,
  SolutionUserInfoPage,
  AdvertisementPage,
  AdvertisementUploadPage,
  AdvertisementPaymentPage,
  PlayListArtistPage,
  PlayListArtistDetailPage,
  MypageBasketArtistPage,
  MypagePaymentArtistPage,
  MypagePaymentHistoryPage,
  MypagePaymentHistoryDetailPage,
  AdvertisementPaymentCompletePage,
  AdvertisementPaymentCancellationPage,
  AdvertisementReservationExpiredPage,
  IntroAdvertisementPage,
  RefundPage,
  PlayTestPage,
  PlayTestVimeoPage,
  SolutionTempPage,
  PartnerPage,
} from 'pages';

function App() {
  RouteChangeTracker();
  PixelTracker();

  return (
    <Routes>
      <Route exact path="/" element={<IndexPage />} />
      <Route path="/intro/company" element={<IntroducePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/find-password" element={<FindPasswordPage />} />
      <Route path="/play-list/play" element={<PlayPage />} />
      <Route path="/play-list" element={<PlayListPage />} />
      <Route path="/play-list/search" element={<PlayListSearchPage />} />
      <Route path="/play-list/media" element={<MediaPlayListPage />} />
      <Route path="/intro/example" element={<IntroExamplePage />} />
      <Route path="/intro/service" element={<IntroServicePage />} />
      <Route
        path="/solution/choice-product"
        element={<SolutionChoiceProductPage />}
      />
      <Route
        path="/solution/request-estimate/led"
        element={<SolutionRequestEstimateLedPage />}
      />
      <Route
        path="/solution/request-estimate/beam"
        element={<SolutionRequestEstimateBeamPage />}
      />
      <Route
        path="/solution/request-estimate/place"
        element={<SolutionRequestEstimatePlacePage />}
      />
      <Route
        path="/solution/request-estimate/solution"
        element={<SolutionRequestEstimateSolutionPage />}
      />
      <Route
        path="/solution/publish-estimate/wait"
        element={<SolutionPublishEstimateWaitPage />}
      />
      <Route
        path="/solution/publish-estimate/success"
        element={<SolutionPublishEstimateSuccessPage />}
      />
      <Route
        path="/solution/publish-estimate/payment"
        element={<SolutionPublishEstimatePaymentPage />}
      />
      <Route
        path="/solution/installment-examination/info"
        element={<SolutionInstallmentExaminationInfoPage />}
      />
      <Route
        path="/solution/installment-examination/choice"
        element={<SolutionInstallmentExaminationChoicePage />}
      />
      <Route
        path="/solution/installment-examination/review"
        element={<SolutionInstallmentExaminationReviewPage />}
      />
      <Route
        path="/solution/installment-examination/completed"
        element={<SolutionInstallmentExaminationCompletedPage />}
      />
      <Route
        path="/solution/installment-examination/confirm"
        element={<SolutionInstallmentExaminationConfirmPage />}
      />
      <Route
        path="/solution/installment-examination/payment"
        element={<SolutionInstallmentExaminationPaymentPage />}
      />
      <Route
        path="/solution/installment-examination/payment-complete"
        element={<SolutionInstallmentExaminationPaymentCompletePage />}
      />
      <Route
        path="/solution/installment-examination/contract-complete"
        element={<SolutionInstallmentExaminationContractCompletePage />}
      />
      <Route path="/solution/schedule" element={<SolutionSchedulePage />} />
      <Route path="/solution/success" element={<SolutionSuccessPage />} />
      <Route path="/mypage" element={<MypagePage />} />
      <Route path="/mypage/edit" element={<MypageEditPage />} />
      <Route path="/mypage/password" element={<MypagePasswordPage />} />
      <Route path="/mypage/solution" element={<MypageSolutionPage />} />
      <Route path="/mypage/payment" element={<MypagePaymentPage />} />
      <Route
        path="/mypage/schedule/access"
        element={<MypageScheduleAccessPage />}
      />
      <Route
        path="/mypage/schedule/waiting"
        element={<MypageScheduleWaitingPage />}
      />
      <Route
        path="/mypage/schedule/fail"
        element={<MypageScheduleFailPage />}
      />
      <Route path="/mypage/faq" element={<MypagFaqPage />} />
      <Route path="/mypage/inquiry" element={<MypagInquiryPage />} />
      <Route path="/mypage/as" element={<MypagAsPage />} />
      <Route path="/mypage/as/list" element={<MypagAsListPage />} />
      <Route path="/mypage/as/detail" element={<MypagAsDetailPage />} />
      <Route path="/mypage/as/inquiry" element={<MypaAsInquiryPage />} />
      <Route
        path="/mypage/schedule/edit"
        element={<MypageScheduleEditPage />}
      />
      <Route path="/mypage/secession" element={<MypageSecessionPage />} />
      <Route
        path="/solution/rental-wait"
        element={<SolutionRentalWaitPage />}
      />
      <Route
        path="/solution/rental-complete"
        element={<SolutionRentalCompletePage />}
      />
      <Route
        path="/solution/deposit-wait"
        element={<SolutionDepositWaitPage />}
      />
      <Route
        path="/solution/deposit-check"
        element={<SolutionDepositCheckPage />}
      />
      <Route
        path="/solution/deposit-complete"
        element={<SolutionDepositCompletePage />}
      />
      <Route path="/solution/seal" element={<SolutionSealPage />} />
      <Route
        path="/solution/seal-complete"
        element={<SolutionSealCompletePage />}
      />
      <Route
        path="/solution/rental-info"
        element={<SolutionRentalInfoPage />}
      />
      <Route
        path="/solution/contract-waiting"
        element={<SolutionContractWaitingPage />}
      />
      <Route
        path="/solution/contract-complete"
        element={<SolutionContractCompletePage />}
      />
      <Route
        path="/solution/schedule-complete"
        element={<SolutionScheduleCompletePage />}
      />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route
        path="/solution/install/request"
        element={<SolutionInstallRequestPage />}
      />
      <Route
        path="/solution/install/success"
        element={<SolutionInstallSuccessPage />}
      />
      <Route
        path="/solution/install/fail"
        element={<SolutionInstallFailPage />}
      />
      <Route path="/solution/user-info" element={<SolutionUserInfoPage />} />
      <Route path="/advertisement" element={<AdvertisementPage />} />
      <Route
        path="/advertisement/upload"
        element={<AdvertisementUploadPage />}
      />
      <Route
        path="/advertisement/payment"
        element={<AdvertisementPaymentPage />}
      />
      <Route path="/play-list/artist" element={<PlayListArtistPage />} />
      <Route
        path="/play-list/artist/detail"
        element={<PlayListArtistDetailPage />}
      />
      <Route
        path="/mypage/basket/artist"
        element={<MypageBasketArtistPage />}
      />
      <Route
        path="/mypage/payment/artist"
        element={<MypagePaymentArtistPage />}
      />
      <Route
        path="/mypage/payment-history"
        element={<MypagePaymentHistoryPage />}
      />
      <Route
        path="/mypage/payment-history/detail"
        element={<MypagePaymentHistoryDetailPage />}
      />
      <Route
        path="/payment-complete"
        element={<AdvertisementPaymentCompletePage />}
      />
      <Route
        path="/payment-cancel"
        element={<AdvertisementPaymentCancellationPage />}
      />
      <Route
        path="/reservation-expired"
        element={<AdvertisementReservationExpiredPage />}
      />
      <Route path="/intro/advertisement" element={<IntroAdvertisementPage />} />
      <Route path="/refund" element={<RefundPage />} />
      <Route path="/test/play" element={<PlayTestPage />} />
      <Route path="/test/vimeo/play" element={<PlayTestVimeoPage />} />
      <Route
        path="/solution"
        element={<SolutionTempPage />}
      />
      <Route
        path="/partner"
        element={<PartnerPage />}
      />
    </Routes>
  );
}

export default App;
