import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import { LOCATION_LIST } from 'constants/region';
import { numberWithCommas } from 'libs/numbers';
import styles from './AdvertisementRegion.module.scss';

const cx = classNames.bind(styles);

export default function AdvertisementRegion({
  regionCountArray,
  handleSelectRegion,
  selectedRegionArray,
}) {

  const counts = useMemo(() => {
    return LOCATION_LIST.reduce((acc, location, i) => {
      const regionCount = regionCountArray?.filter(region => region.name === location)[0]?.count;
      acc[location] = '-';
      if (regionCount > 0) acc[location] = numberWithCommas(regionCount);
      if (regionCount === 0) acc[location] = 0;
      return acc;
    }, {});
  }, [regionCountArray]);

  return (
    <div className={cx('category_inner')}>
      <div className={cx('category_title_box')}>
        <p className={cx('category_title')}>
          지역
          <b className={cx('category_text_b')}>
            이 상품은 스토어 지역 변경이 불가능 합니다.
          </b>
        </p>
      </div>
      <div className={cx('region_category_inner')}>
        <div className={cx('region_category_box')}>
          <div className={cx('region_category_header_box')}>
            <p className={cx('region_category_header')}>수도권</p>
          </div>
          <ul className={cx('region_list_box')}>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('서울') > -1 })}
                id="seoul"
                onClick={() => handleSelectRegion('서울')}
              />
              <label className={cx('check_box_label')} htmlFor="seoul">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>서울</b>
              </label>
              <p className={cx('check_number')}>{counts['서울']}</p>
            </li>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('경기') > -1 })}
                id="gyeonggi"
                onClick={() => handleSelectRegion('경기')}
              />
              <label className={cx('check_box_label')} htmlFor="gyeonggi">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>경기</b>
              </label>
              <p className={cx('check_number')}>{counts['경기']}</p>
            </li>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('인천') > -1 })}
                id="incheon"
                onClick={() => handleSelectRegion('인천')}
              />
              <label className={cx('check_box_label')} htmlFor="incheon">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>인천</b>
              </label>
              <p className={cx('check_number')}>{counts['인천']}</p>
            </li>
          </ul>
        </div>
        <div className={cx('region_category_box')}>
          <div className={cx('region_category_header_box')}>
            <p className={cx('region_category_header')}>충청/강원</p>
          </div>
          <ul className={cx('region_list_box')}>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('대전') > -1 })}
                id="daejeon"
                onClick={() => handleSelectRegion('대전')}
              />
              <label className={cx('check_box_label')} htmlFor="daejeon">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>대전</b>
              </label>
              <p className={cx('check_number')}>{counts['대전']}</p>
            </li>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('세종') > -1 })}
                id="sejong"
                onClick={() => handleSelectRegion('세종')}
              />
              <label className={cx('check_box_label')} htmlFor="sejong">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>세종</b>
              </label>
              <p className={cx('check_number')}>{counts['세종']}</p>
            </li>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('충남') > -1 })}
                id="chungnam"
                onClick={() => handleSelectRegion('충남')}
              />
              <label className={cx('check_box_label')} htmlFor="chungnam">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>충남</b>
              </label>
              <p className={cx('check_number')}>{counts['충남']}</p>
            </li>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('충북') > -1 })}
                id="chungbug"
                onClick={() => handleSelectRegion('충북')}
              />
              <label className={cx('check_box_label')} htmlFor="chungbug">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>충북</b>
              </label>
              <p className={cx('check_number')}>{counts['충북']}</p>
            </li>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('강원') > -1 })}
                id="gangwon"
                onClick={() => handleSelectRegion('강원')}
              />
              <label className={cx('check_box_label')} htmlFor="gangwon">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>강원</b>
              </label>
              <p className={cx('check_number')}>{counts['강원']}</p>
            </li>
          </ul>
        </div>
        <div className={cx('region_category_box')}>
          <div className={cx('region_category_header_box')}>
            <p className={cx('region_category_header')}>영남</p>
          </div>
          <ul className={cx('region_list_box')}>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('부산') > -1 })}
                id="busan"
                onClick={() => handleSelectRegion('부산')}
              />
              <label className={cx('check_box_label')} htmlFor="busan">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>부산</b>
              </label>
              <p className={cx('check_number')}>{counts['부산']}</p>
            </li>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('대구') > -1 })}
                id="daegu"
                onClick={() => handleSelectRegion('대구')}
              />
              <label className={cx('check_box_label')} htmlFor="daegu">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>대구</b>
              </label>
              <p className={cx('check_number')}>{counts['대구']}</p>
            </li>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('울산') > -1 })}
                id="ulsan"
                onClick={() => handleSelectRegion('울산')}
              />
              <label className={cx('check_box_label')} htmlFor="ulsan">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>울산</b>
              </label>
              <p className={cx('check_number')}>{counts['울산']}</p>
            </li>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('경남') > -1 })}
                id="gyeongnam"
                onClick={() => handleSelectRegion('경남')}
              />
              <label className={cx('check_box_label')} htmlFor="gyeongnam">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>경남</b>
              </label>
              <p className={cx('check_number')}>{counts['경남']}</p>
            </li>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('경북') > -1 })}
                id="gyeongbug"
                onClick={() => handleSelectRegion('경북')}
              />
              <label className={cx('check_box_label')} htmlFor="gyeongbug">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>경북</b>
              </label>
              <p className={cx('check_number')}>{counts['경북']}</p>
            </li>
          </ul>
        </div>
        <div className={cx('region_category_box')}>
          <div className={cx('region_category_header_box')}>
            <p className={cx('region_category_header')}>호남/제주</p>
          </div>
          <ul className={cx('region_list_box')}>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('광주') > -1 })}
                id="gwangju"
                onClick={() => handleSelectRegion('광주')}
              />
              <label className={cx('check_box_label')} htmlFor="gwangju">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>광주</b>
              </label>
              <p className={cx('check_number')}>{counts['광주']}</p>
            </li>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('전남') > -1 })}
                id="jeonnam"
                onClick={() => handleSelectRegion('전남')}
              />
              <label className={cx('check_box_label')} htmlFor="jeonnam">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>전남</b>
              </label>
              <p className={cx('check_number')}>{counts['전남']}</p>
            </li>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('전북') > -1 })}
                id="jeonbug"
                onClick={() => handleSelectRegion('전북')}
              />
              <label className={cx('check_box_label')} htmlFor="jeonbug">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>전북</b>
              </label>
              <p className={cx('check_number')}>{counts['전북']}</p>
            </li>
            <li className={cx('region_list_itme')}>
              <input
                type="checkbox"
                className={cx('hide_check_box', { active: selectedRegionArray?.indexOf('제주') > -1 })}
                id="jeju"
                onClick={() => handleSelectRegion('제주')}
              />
              <label className={cx('check_box_label')} htmlFor="jeju">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                <b className={cx('check_text')}>제주</b>
              </label>
              <p className={cx('check_number')}>{counts['제주']}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
