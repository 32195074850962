import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from 'apis';
import qs from 'query-string';
import usePlayList from 'hooks/usePlayList';
import PlayWrapper from 'components/PlayWrapper';
import { useLocation } from 'react-router-dom';
import BeamMonsterLoader from 'components/common/BeamMonsterLoader';
import AdvertisementPlay from 'components/views/AdvertisementPlay';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import _ from 'lodash/fp';
import $ from 'jquery';

var realPlayList;
var realPlayDuration = 0;
var leftDuration = 0;
var isLoop = false;
var isOnlyOne = false;
var isPreparing = false;
var isNeedEnd = false;
var isVideoLengthPlay = false;
var containerPlayIndex = 0;
var spareTime = 7;
var VIDEO_BLOB = {};
var USE_CONVERT = false;
var CHECK_STATUS = true;

async function convertBlob(id, url) {
  try {
    const blob = await fetch(url).then((r) => r.blob());
    // console.log('blob111 ', blob);
    const buf = await blob.arrayBuffer();
    // console.log('blo222222322 ', buf);
    // console.log('buf123123 ', buf);
    const blobUrl = await window.URL.createObjectURL(new Blob([buf]));
    // console.log('blobUrl33333 ', blobUrl);
    VIDEO_BLOB[id] = blobUrl;
  } catch (err) {
    VIDEO_BLOB[id] = null;
    console.log(err);
  }
}

var HIGH_VIMEO_LIST = [
  {
    name: 'test_h_1',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/840466322/rendition/2160p/file.mp4?loc=external&signature=b3541ba5fae12b0c9e8750d6b062baec2c4a8928fcfafdb99c3c955164d519b5',
    },
    videoLength: 81,
  },
  {
    name: 'test_h_2',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/840466440/rendition/2160p/file.mp4?loc=external&signature=a9b85b8b715b54ddff4c2603fe66753078ca8c51cebc7b5d667cbd3d440a3311',
    },
    videoLength: 89,
  },
  {
    name: 'test_h_3',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/840466522/rendition/2160p/file.mp4?loc=external&signature=d4071e55752f85bc69e8b3dc57bb56a550b0b08c0c3c7bd0e9d270ce4cc96b35',
    },
    videoLength: 59,
  },
  {
    name: 'test_h_4',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/840466596/rendition/2160p/file.mp4?loc=external&signature=c1c9a3e3e78aee45c87c187445407bf1e2657a407c8b5ccce89ab6b2784ed834',
    },
    videoLength: 64,
  },
  {
    name: 'test_h_5',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/840466666/rendition/2160p/file.mp4?loc=external&signature=a3def263754bcc955a5fe1f392a5c053d0e46ba8d92f32dbf773af266f208c0e',
    },
    videoLength: 76,
  },
  {
    name: 'test_h_6',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/840466759/rendition/2160p/file.mp4?loc=external&signature=7ca8280c5aa615ee0e37e5a040ff1e8b85117df2436f5f7048a0eaa62a50405d',
    },
    videoLength: 100,
  },
  {
    name: 'test_h_7',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/840466854/rendition/2160p/file.mp4?loc=external&signature=040c33a656622236401f879621cf55dc4129533537ec619068e8ecc6b53d39bb',
    },
    videoLength: 71,
  },
  {
    name: 'test_h_8',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/840466940/rendition/2160p/file.mp4?loc=external&signature=dc5bba3aa15b6f2a4510c77cb459e2136d38cf5c0b01ea963c0311462943a1a8',
    },
    videoLength: 79,
  },
  {
    name: 'test_h_9',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/840467031/rendition/2160p/file.mp4?loc=external&signature=56581461904586015b856df230895af281c23d2fa9efb0e57507035e0bff92a4',
    },
    videoLength: 71,
  },
  {
    name: 'test_h_10',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/840467133/rendition/2160p/file.mp4?loc=external&signature=da03559f64bebf9864121fdf55355d41fd7833ebe194261c64b0de64896b2e0a',
    },
    videoLength: 60,
  },
  {
    name: 'test_h_11',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/840467209/rendition/2160p/file.mp4?loc=external&signature=b42b8f887297f018ee7382253f5c0165de5682b91182fbd7e1bb80052f007f31',
    },
    videoLength: 50,
  },
];

var LOW_VIMEO_LIST = [
  {
    name: 'low_1',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/842178447/rendition/2160p/file.mp4?loc=external&signature=b0f70dc1316071792a22ef25a8312e20135a0827cc35e26f9ff2caa52561f318',
    },
    videoLength: 30,
  },
  {
    name: 'low_2',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/842178510/rendition/2160p/file.mp4?loc=external&signature=2836832e56cc5b019841acbc4e7b167e1742b3bf2e78d19c09f41a11856f83bd',
    },
    videoLength: 30,
  },
  {
    name: 'low_3',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/842178573/rendition/2160p/file.mp4?loc=external&signature=4cd656fd59e24af9a816c361c745653d39f5bf826e133d69074e9d08a79148ac',
    },
    videoLength: 30,
  },
  {
    name: 'low_4',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/842178621/rendition/2160p/file.mp4?loc=external&signature=df5b6432fb8c2f373d15bee9d331374ef17dd1abb03a52bdbb11e4e158e6eab2',
    },
    videoLength: 30,
  },
  {
    name: 'low_5',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/842178667/rendition/2160p/file.mp4?loc=external&signature=ef46b4717e259fcb3a79afe0b515cb14dad0c3fd84c0d3dfc67fe1f1e60552ed',
    },
    videoLength: 30,
  },
  {
    name: 'low_6',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/842178724/rendition/2160p/file.mp4?loc=external&signature=3230d2942da3dc7d2cfc21c787e3fa8380d5b753a7cc4355ce6e1a8dc596428a',
    },
    videoLength: 30,
  },
  {
    name: 'low_7',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/842178774/rendition/2160p/file.mp4?loc=external&signature=193c500fa9c2d20e754795ff30879cf3878bae53b5f5b42c99de8ffc2f61e9c1',
    },
    videoLength: 30,
  },
  {
    name: 'low_8',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/842178813/rendition/2160p/file.mp4?loc=external&signature=bee202ea323de9d1e8d09c75c5d8ae78d890cb1cb682775050cc489b5299358b',
    },
    videoLength: 30,
  },
  {
    name: 'low_9',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/842178906/rendition/2160p/file.mp4?loc=external&signature=4bb6cc678328ffd9d992ea27f6f3183a19f8bb8710859e7de4687ff1309ad9c8',
    },
    videoLength: 30,
  },
  {
    name: 'low_10',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/842178870/rendition/2160p/file.mp4?loc=external&signature=489c7e02acc4d2a113a39e0c094c0aef94103886dcddc0ee8154861d332b11f2',
    },
    videoLength: 30,
  },
];

export default function PlayTestVimeoContainer() {
  const navigator = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isInit, setIsInit] = useState(false);
  const [isOnlyOne, setIsOnlyOne] = useState(false);
  const [videoType, setVideoType] = useState('4k');
  const [videoMode, setVideoMode] = useState('low');
  const [playInfo, setPlayInfo] = useState({
    playType: 'Ordering',
    playList: [],
    shufflePlayList: [],
    playId: null,
    playStart: true, // 플레이중
    playLoop: false, // 반복
    playMute: true, // 음소거
    playVolume: 100,
    currentPlayTime: null,
    selectedPlay: null,
    playDuration: '300', // 5분 Default
    realPlayList: [],
  });
  // const {
  //     playList,
  //     playLoop,
  //     playMute,
  //     playStart,
  //     playVolume,
  //     handleChangePlayData,
  //     currentPlayTime,
  // } = usePlayList();

  const [needRefetch, setNeedRefetch] = useState(true);
  const [advertiseInterval, setAdvertiseInterval] = useState(10);
  const [currentAdvertise, setCurrentAdvertise] = useState();
  const [today, setToday] = useState();
  const [advertises, setAdvertises] = useState([]);
  const [logs, setLogs] = useState({});

  const playThrottle = useRef(null);

  const checkUserRef = useRef(null);

  const timeRef = useRef(null);

  const loginUserRef = useRef(null);

  const location = useLocation();
  const { videoQuality, videoMethod, videoLoop } = qs.parse(location.search);

  const { handleResetAuthData } = useAuth();

  const makeVideo = (videoUrl, index, isShow = false) => {
    var video = document.createElement('video');
    video.setAttribute('id', `video-${index}`);
    video.setAttribute('data-index', `${index}`);
    video.style.width = '100vw';
    video.style.height = '100vh';
    video.loop = true;
    if (!isShow) {
      video.setAttribute('src', videoUrl);
      video.style.visibility = 'hidden';
    } else {
      video.setAttribute('src', videoUrl);
    }
    return video;
  };

  const makeAdVideo = (videoUrl) => {
    var video = document.createElement('video');
    video.setAttribute('id', `ad-video`);
    video.style.visibility = 'hidden';
    video.setAttribute('src', videoUrl);
    return video;
  };

  const throttle = (callback, limit = 1000) => {
    let waiting = false;
    return function () {
      if (!waiting) {
        waiting = true;
        callback.apply(this, arguments);
        setTimeout(() => {
          waiting = false;
        }, limit);
      }
    };
  };

  // const handleVideoPause = async () => {
  //   try {
  //     console.log('VIDEO_BLOB111 ', VIDEO_BLOB);
  //     const currentIndex = containerPlayIndex;
  //     let video = $(`#video-${currentIndex}`)[0];
  //     $(`#video-${currentIndex}`).off('timeupdate');
  //     $(`#video-${currentIndex}`).off('pause');
  //     video.pause();
  //     if (window.confirm('영상 플레이가 일시정지되었습니다. 다시 이어서 플레이 하시겠습니까?')) {
  //       console.log('leftDuration before ', leftDuration);
  //       // const res = await api.getContent(realPlayList[0].id);
  //       // if (USE_CONVERT) {
  //       //   await convertBlob(realPlayList[0].id, res?.data?.videoUrl);
  //       //   video.src = VIDEO_BLOB[realPlayList[0].id]?.blobUrl;
  //       // } else {
  //       //   video.src = res?.data?.videoUrl;
  //       // }

  //       video.src = realPlayList[0].videoUrl;

  //       await video.load();
  //       video.onloadeddata = function () {
  //         video.play();
  //       };
  //       leftDuration = realPlayDuration;
  //       isPreparing = false;
  //       $(`#video-${currentIndex}`).bind('timeupdate', videoListener);
  //       $(`#video-${currentIndex}`).bind('pause', handleVideoPause);
  //       console.log('VIDEO_BLOB222 ', VIDEO_BLOB);
  //     } else {
  //       window?.opener?.postMessage(
  //         { windowStatus: 'close' },
  //         process.env.REACT_APP_ORIGIN,
  //       );
  //       window.close();
  //     }
  //     console.log('leftDuration after ', leftDuration);
  //   } catch (err) {
  //     console.log('handleOnlineVideo err ', err);
  //     console.log('handleOnlineVideo err response ', err?.response);
  //   }
  // };

  const handleVideoError = async () => {
    try {
      console.log('handleVideoError!!!!!!!!!!!!');
      console.log('VIDEO_BLOB111 ', VIDEO_BLOB);
      const currentIndex = containerPlayIndex;
      console.log('leftDuration before ', leftDuration);
      $(`#video-${currentIndex}`).off('timeupdate');
      $(`#video-${currentIndex}`).off('error');
      let video = $(`#video-${currentIndex}`)[0];
      // video.pause();
      // if (window.confirm('영상 플레이가 일시정지되었습니다. 다시 이어서 플레이 하시겠습니까?')) {
      const res = await api.getContent(realPlayList[0].id);
      if (USE_CONVERT) {
        await convertBlob(realPlayList[0].id, res?.data?.videoUrl);
        video.src = VIDEO_BLOB[realPlayList[0].id]?.blobUrl;
      } else {
        video.src = res?.data?.videoUrl;
      }

      await video.load();
      video.onloadeddata = function () {
        video.play();
      };
      leftDuration = realPlayDuration;
      isPreparing = false;
      $(`#video-${currentIndex}`).bind('timeupdate', videoListener);
      $(`#video-${currentIndex}`).bind('error', handleVideoError);
      console.log('VIDEO_BLOB222 ', VIDEO_BLOB);
      console.log('leftDuration after ', leftDuration);
    } catch (err) {
      console.log('handleOnlineVideo err ', err);
      console.log('handleOnlineVideo err response ', err?.response);
    }
  };

  const videoListener = async function () {
    window?.opener?.postMessage(
      { currentPlayTime: this.currentTime },
      process.env.REACT_APP_ORIGIN,
    );
    console.log('this.currentTime ', this.currentTime);
    console.log('!isVideoLengthPlay ', !isVideoLengthPlay);
    console.log('!isPreparing ', !isPreparing);
    console.log('leftDuration ', leftDuration);
    console.log('realPlayList[0].videoLength ', realPlayList);
    console.log('realPlayList[0].videoLength ', realPlayList[0].videoLength);
    console.log('spareTime ', spareTime);
    console.log(
      'this.currentTime >= (realPlayList[0].videoLength - spareTime) ',
      this.currentTime >= realPlayList[0].videoLength - spareTime,
    );
    console.log(
      '(this.currentTime >= leftDuration - spareTime) ',
      this.currentTime >= leftDuration - spareTime,
    );
    console.log(
      '(this.currentTime >= (realPlayList[0].videoLength - spareTime) || (this.currentTime >= leftDuration - spareTime)) ',
      this.currentTime >= realPlayList[0].videoLength - spareTime ||
        this.currentTime >= leftDuration - spareTime,
    );
    console.log(
      'window?.navigator?.connection ',
      window?.navigator?.connection,
    );
    if (
      !isVideoLengthPlay &&
      !isPreparing &&
      (this.currentTime >= realPlayList[0].videoLength - spareTime ||
        this.currentTime >= leftDuration - spareTime)
    ) {
      isPreparing = true;
      if (this.currentTime >= realPlayList[0].videoLength - spareTime) {
        leftDuration = leftDuration - realPlayList[0].videoLength - spareTime;
      }

      // console.log('videoListener - this.currentTime ', this.currentTime);
      // console.log('videoListener - (realPlayList[0].videoLength - spareTime) ', (realPlayList[0].videoLength - spareTime));
      // console.log('videoListener - leftDuration ', leftDuration);
      // console.log('videoListener - this.currentTime >= (realPlayList[0].videoLength - spareTime) && leftDuration > (realPlayList[0].videoLength - spareTime) ', this.currentTime >= (realPlayList[0].videoLength - spareTime) && leftDuration > (realPlayList[0].videoLength - spareTime));
      // console.log('videoListener - this.currentTime >= (realPlayList[0].videoLength - spareTime) ', this.currentTime >= (realPlayList[0].videoLength - spareTime));
      // console.log('videoListener - && leftDuration > (realPlayList[0].videoLength - spareTime) ', leftDuration > (realPlayList[0].videoLength - spareTime))

      if (isLoop || isOnlyOne) {
        console.log('videoListener - 루핑');
        console.log('videoListener - isLoop || isOnlyOne');
        setTimeout(() => {
          this.currentTime = 0;
          leftDuration = realPlayDuration;
          window?.opener?.postMessage(
            { currentPlayTime: 0, playId: realPlayList[0].id },
            process.env.REACT_APP_ORIGIN,
          );
          isPreparing = false;
        }, spareTime * 1000);
      } else {
        if (leftDuration > realPlayList[0].videoLength - spareTime) {
          console.log('videoListener - 남은시간이 더 많음');
          console.log(
            'videoListener - leftDuration > (realPlayList[0].videoLength - spareTime',
          );
          // if (this.currentTime >= (realPlayList[0].videoLength - spareTime) && leftDuration > (realPlayList[0].videoLength - spareTime)) {
          setTimeout(async () => {
            this.currentTime = 0;
            leftDuration = leftDuration + spareTime;
            window?.opener?.postMessage(
              { currentPlayTime: 0, playId: realPlayList[0].id },
              process.env.REACT_APP_ORIGIN,
            );
            isPreparing = false;
          }, spareTime * 1000);
        } else {
          console.log('videoListener - 다음 콘텐츠');
          console.log('videoListener - else');
          const index = Number($(this).attr('data-index'));
          $(`#video-${index}`).off('ended');
          document.getElementById(`video-${index}`).loop = true;
          $(`#video-${index}`)[0].play();
          const nextIndex = Number($(this).attr('data-index')) === 0 ? 1 : 0;
          await $(`#video-${nextIndex}`)[0].load();
          const [video, ...tempVideo] = realPlayList;
          realPlayList = [...tempVideo, video];
          leftDuration = realPlayDuration;
          await handleVideo(nextIndex, false, false);
        }
      }
    }
  };

  const adVideoEnded = async function () {
    try {
      $(`#ad-video`).off('ended');
      $(`#ad-video`).removeClass('active');
      $(`#ad-video`)
        .delay(1000)
        .queue(function () {
          $(this).remove();
        });
    } catch (error) {
      console.error(error);
    }
  };

  // const videoEnded = async function () {
  //   try {
  //     if (isLoop || isOnlyOne) {
  //       // console.log('videoEnded - 루핑');
  //       // console.log('videoEnded - isLoop || isOnlyOne');
  //       const index = Number($(this).attr("data-index"));
  //       // $(`#video-${index}`).off('ended');
  //       // $(`#video-${index}`).off('timeupdate');
  //       leftDuration = realPlayDuration;
  //       $(`#video-${index}`)[0].play();
  //       await handleVideo(index, false, true);
  //       window?.opener?.postMessage({ currentPlayTime: 0, playId: realPlayList[0].id }, process.env.REACT_APP_ORIGIN);
  //     } else {
  //       // console.log('videoEnded - 반복');
  //       // console.log('videoEnded - else');
  //       // console.log('videoEnded - leftDuration > (realPlayList[0].videoLength - spareTime)', leftDuration > (realPlayList[0].videoLength - spareTime));
  //       // console.log('videoEnded - leftDuration', leftDuration);
  //       // console.log('videoEnded - realPlayList[0].videoLength', realPlayList[0].videoLength);
  //       // console.log('videoEnded - spareTime', spareTime);
  //       const index = Number($(this).attr("data-index"));
  //       // $(`#video-${index}`).off('ended');
  //       // $(`#video-${index}`).off('timeupdate');
  //       leftDuration = leftDuration + spareTime;
  //       $(`#video-${index}`)[0].play();
  //       await handleVideo(index, false, true);
  //       window?.opener?.postMessage({ currentPlayTime: 0, playId: realPlayList[0].id }, process.env.REACT_APP_ORIGIN);
  //       // if (leftDuration > (realPlayList[0].videoLength - spareTime)) {
  //       //   const index = Number($(this).attr("data-index"));
  //       //   await $(`#video-${index}`)[0].load();
  //       //   $(`#video-${index}`).off('ended');
  //       //   $(`#video-${index}`).off('timeupdate');
  //       //   leftDuration = leftDuration + spareTime;
  //       //   await handleVideo(index, false, true);
  //       //   window?.opener?.postMessage({ currentPlayTime: 0, playId: realPlayList[0].id }, process.env.REACT_APP_ORIGIN);
  //       // }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleVideo = async (index, isInit = false, looping = false) => {
    // console.log('VIDEO_BLOB ', VIDEO_BLOB);
    try {
      if ((!isInit && isLoop) || (!isInit && isOnlyOne) || looping) {
        // console.log('play 반복');

        // $(`#video-${index}`).bind('timeupdate', videoListener);
        // $(`#video-${index}`).bind('ended', videoEnded);
        isPreparing = false;
      } else {
        if (!isInit) {
          // console.log('play 새로운 것');
          const animationTimeIn = 5000;
          const animationTimeOut = 10000;
          if (index === 0) {
            // $(`#video-0`).animate({ opacity: 0 }, 0);
            $(`#video-0`).css('visibility', 'visible');
            await $(`#video-0`)[0].play();
            window?.opener?.postMessage(
              { currentPlayTime: 0, playId: realPlayList[0].id },
              process.env.REACT_APP_ORIGIN,
            );
            isPreparing = false;
            $(`#video-0`).bind('timeupdate', videoListener);
            if (CHECK_STATUS) {
              // $(`#video-0`).bind('pause', handleVideoPause);
              $(`#video-0`).bind('error', handleVideoError);
            }
            $(`#video-0`).addClass('active');

            await $(`#video-1`).off('timeupdate');
            if (CHECK_STATUS) {
              // $(`#video-1`).off('pause');
              $(`#video-1`).off('error');
            }
            containerPlayIndex = 0;
            // $(`#video-1`).stop(true).fadeOut(10000, function () {
            //     $(this).remove();
            // });
            $(`#video-1`).removeClass('active');
            $(`#video-1`)
              .delay(10000)
              .queue(function () {
                $(this).remove();
              });
            // $(`#video-1`).delay(30000, function () {
            //     $(this).remove();
            // })
          } else {
            // $(`#video-1`).animate({ opacity: 0 }, 0);
            $(`#video-1`).css('visibility', 'visible');
            await $(`#video-1`)[0].play();
            window?.opener?.postMessage(
              { currentPlayTime: 0, playId: realPlayList[0].id },
              process.env.REACT_APP_ORIGIN,
            );
            isPreparing = false;
            $(`#video-1`).bind('timeupdate', videoListener);
            if (CHECK_STATUS) {
              // $(`#video-1`).bind('pause', handleVideoPause);
              $(`#video-1`).bind('error', handleVideoError);
            }
            // $(`#video-1`).animate({ opacity: 1 }, 10000);
            $(`#video-1`).addClass('active');

            await $(`#video-0`).off('timeupdate');
            if (CHECK_STATUS) {
              // $(`#video-0`).off('pause');
              $(`#video-0`).off('error');
            }
            containerPlayIndex = 1;
            $(`#video-0`).removeClass('active');
            $(`#video-0`)
              .delay(10000)
              .queue(function () {
                $(this).remove();
              });
            // $(`#video-0`).delay(30000, function () {
            //     $(this).remove();
            // })
            // $(`#video-0`).stop(true).fadeOut(10000, function () {
            //     $(this).remove();
            // });
          }
        }

        const nextIndex = index === 0 ? 1 : 0;
        if (USE_CONVERT) {
          if (!VIDEO_BLOB[realPlayList[1]?.id]) {
            try {
              const res2 = await api.getContent(realPlayList[1].id);
              await convertBlob(realPlayList[1].id, res2?.data?.videoUrl);
            } catch (err) {
              console.log(err);
            }
          }
          const nextVideo = makeVideo(
            VIDEO_BLOB[realPlayList[1]?.id] || '',
            nextIndex,
            false,
          );
          // await nextVideo.load();
          $('#video-container').append(nextVideo);
        } else {
          try {
            // const res2 = await api.getContent(realPlayList[1].id);
            const nextVideo = makeVideo(
              realPlayList[1].videoUrl || '',
              nextIndex,
              false,
            );
            $('#video-container').append(nextVideo);
          } catch (err) {
            console.log(err);
          }
        }

        // 처음에만
        if (isInit) {
          if (USE_CONVERT) {
            if (!VIDEO_BLOB[realPlayList[index]?.id]) {
              try {
                const res = await api.getContent(realPlayList[index].id);
                await convertBlob(realPlayList[index].id, res?.data?.videoUrl);
              } catch (err) {
                console.log(err);
              }
            }

            const currentVideo = makeVideo(
              VIDEO_BLOB[realPlayList[index].id] || '',
              0,
              true,
            );
            await currentVideo.load();
            $('#video-container').append(currentVideo);
            currentVideo.muted = playInfo?.playMute;
            currentVideo.volume = playInfo?.playVolume / 100;
            currentVideo.currentTime = playInfo?.currentPlayTime;
            // if (isLoop) {
            //     currentVideo.loop = true;
            // }
            if (playInfo?.playStart) {
              currentVideo.play();
            }
            $('#video-0').addClass('active');
            $('#video-0').bind('timeupdate', videoListener);
            if (CHECK_STATUS) {
              // $(`#video-0`).bind('pause', handleVideoPause);
              $(`#video-0`).bind('error', handleVideoError);
            }
            // $('#video-0').bind('ended', videoEnded);
            setIsLoading(false);
          } else {
            try {
              // const res = await api.getContent(realPlayList[index].id);
              // videoUrl
              const currentVideo = makeVideo(
                realPlayList[index].videoUrl || '',
                0,
                true,
              );
              await currentVideo.load();
              $('#video-container').append(currentVideo);
              currentVideo.muted = playInfo?.playMute;
              currentVideo.volume = playInfo?.playVolume / 100;
              currentVideo.currentTime = playInfo?.currentPlayTime;
              // if (isLoop) {
              //     currentVideo.loop = true;
              // }
              if (playInfo?.playStart) {
                currentVideo.onloadeddata = function () {
                  currentVideo.play();
                };
              }
              $('#video-0').addClass('active');
              $('#video-0').bind('timeupdate', videoListener);
              if (CHECK_STATUS) {
                // $(`#video-0`).bind('pause', handleVideoPause);
                $(`#video-0`).bind('error', handleVideoError);
              }
              // $('#video-0').bind('ended', videoEnded);
              setIsLoading(false);
            } catch (err) {
              console.log(err);
            }
          }
        }
      }
    } catch (err) {
      // alert('인증이 유효하지않습니다.');
      console.error(err);
      // window?.opener?.postMessage({ windowStatus: 'close' }, process.env.REACT_APP_ORIGIN);
      // window.close();
    }
  };

  const handleUserKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        if (isFullScreen) {
          setIsFullScreen(false);
        } else {
          window?.opener?.postMessage(
            { windowStatus: 'close' },
            process.env.REACT_APP_ORIGIN,
          );
          window.close();
        }
      }
    },
    [isFullScreen, setIsFullScreen],
  );

  const exitHandler = useCallback(() => {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setIsFullScreen(false);
    }
  }, [isFullScreen, setIsFullScreen]);

  const handlePlayNextVideo = async (id) => {
    try {
      const nextIndex = containerPlayIndex === 0 ? 1 : 0;
      const video = document.getElementById(`video-${nextIndex}`);
      if (USE_CONVERT) {
        if (!VIDEO_BLOB[id]) {
          try {
            const res = await api.getContent(id);
            await convertBlob(id, res?.data?.videoUrl);
          } catch (err) {
            console.error(err);
          }
        }
        try {
          await video.setAttribute('src', VIDEO_BLOB[id] || '');
        } catch (err) {
          VIDEO_BLOB[id] = null;
          await handlePlayNextVideo(id);
        }
      } else {
        try {
          const res = await api.getContent(id);
          await video.setAttribute('src', res?.data?.videoUrl || '');
        } catch (err) {
          VIDEO_BLOB[id] = null;
          await handlePlayNextVideo(id);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlePlayCurrentVideo = async (id, playStart) => {
    try {
      const currentIndex = containerPlayIndex;
      const video = document.getElementById(`video-${currentIndex}`);
      $(`#video-${currentIndex}`).off('timeupdate');
      if (CHECK_STATUS) {
        // $(`#video-${currentIndex}`).off('pause');
        $(`#video-${currentIndex}`).off('error');
      }
      if (USE_CONVERT) {
        if (!VIDEO_BLOB[id]) {
          try {
            const res = await api.getContent(id);
            await convertBlob(id, res?.data?.videoUrl);
          } catch (err) {
            console.log(err);
          }
        }

        try {
          await video.setAttribute('src', VIDEO_BLOB[id] || '');
          await video.load();
          if (playStart) {
            video.play();
          }
          $(`#video-${currentIndex}`).addClass('active');
          $(`#video-${currentIndex}`).bind('timeupdate', videoListener);
          if (CHECK_STATUS) {
            // $(`#video-${currentIndex}`).bind('pause', handleVideoPause);
            $(`#video-${currentIndex}`).bind('error', handleVideoError);
          }
          // $(`#video-${currentIndex}`).bind('ended', videoEnded);
        } catch (err) {
          $(`#video-${currentIndex}`).removeClass('active');
          $(`#video-${currentIndex}`).off('timeupdate');
          if (CHECK_STATUS) {
            // $(`#video-${currentIndex}`).off('pause');
            $(`#video-${currentIndex}`).off('error');
          }
          VIDEO_BLOB[id] = null;
          await handlePlayCurrentVideo(id, playStart);
        }
      } else {
        try {
          const res = await api.getContent(id);
          await video.setAttribute('src', res?.data?.videoUrl || '');
          await video.load();
          if (playStart) {
            video.play();
          }
          $(`#video-${currentIndex}`).addClass('active');
          $(`#video-${currentIndex}`).bind('timeupdate', videoListener);
          if (CHECK_STATUS) {
            // $(`#video-${currentIndex}`).bind('pause', handleVideoPause);
            $(`#video-${currentIndex}`).bind('error', handleVideoError);
          }
        } catch (err) {
          $(`#video-${currentIndex}`).removeClass('active');
          $(`#video-${currentIndex}`).off('timeupdate');
          if (CHECK_STATUS) {
            // $(`#video-${currentIndex}`).off('pause');
            $(`#video-${currentIndex}`).off('error');
          }
          VIDEO_BLOB[id] = null;
          await handlePlayCurrentVideo(id, playStart);
        }
      }
      // $(`#video-${currentIndex}`).bind('timeupdate', throttle(videoListener));
    } catch (err) {
      console.error(err);
    }
  };

  const handleParentData = async (data) => {
    setPlayInfo(data);
    if (data?.playNext || data?.playPrev) {
      setIsLoading(true);
      $(`#video-0`).off('timeupdate');
      $(`#video-1`).off('timeupdate');
      if (CHECK_STATUS) {
        // $(`#video-0`).off('pause');
        // $(`#video-1`).off('pause');
        $(`#video-0`).off('error');
        $(`#video-1`).off('error');
      }
    }

    if (playThrottle.current) {
      clearTimeout(playThrottle.current);
    }

    playThrottle.current = setTimeout(async () => {
      // handleChangePlayData(data);
      console.log('data ', data);
      const element = `#video-${containerPlayIndex}`;
      try {
        if (data.hasOwnProperty('playList')) {
          realPlayList = data.playList;
          if (realPlayList.length === 1) {
            realPlayList = [
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
            ];
          }

          if (!realPlayList || realPlayList.length === 0) {
            alert('등록된 플레이리스트가 없습니다!');
            window?.opener?.postMessage(
              { windowStatus: 'close' },
              process.env.REACT_APP_ORIGIN,
            );
            window.close();
            return;
          }
        }

        if (data.hasOwnProperty('playId')) {
          if (!data.playList || data?.playList?.length === 0) {
            alert('등록된 플레이리스트가 없습니다!');
            return;
          }

          let playIndex;
          data.playList.some((el, index) => {
            if (el.id === data.playId) {
              playIndex = index;
            }
            return el.id === data.playId;
          });

          if (playIndex === 0) {
            realPlayList = data.playList;
          } else if (playIndex === data.playList.length - 1) {
            const last = data.playList[playIndex];
            const restArr = data.playList.filter((el) => el.id !== last.id);
            realPlayList = [last, ...restArr];
          } else {
            const pastArray = data.playList.slice(0, playIndex);
            const newArray = data.playList.slice(
              playIndex,
              data.playList.length,
            );
            realPlayList = [...newArray, ...pastArray];
          }

          if (realPlayList.length === 1) {
            isOnlyOne = true;
            realPlayList = [
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
            ];
          }

          if (data?.playNext) {
            leftDuration = realPlayDuration;
            await handlePlayCurrentVideo(realPlayList[0].id, data?.playStart);
            handlePlayNextVideo(realPlayList[1].id);
            isPreparing = false;
          }

          if (data?.playPrev) {
            leftDuration = realPlayDuration;
            await handlePlayCurrentVideo(realPlayList[0].id, data?.playStart);
            handlePlayNextVideo(realPlayList[1].id);
            isPreparing = false;
          }

          if (data?.removed) {
            leftDuration = realPlayDuration;
            await handlePlayCurrentVideo(realPlayList[0].id, data?.playStart);
            handlePlayNextVideo(realPlayList[1].id);
          }
        }

        if (data.hasOwnProperty('currentPlayTime')) {
          $(element).prop('currentTime', data.currentPlayTime);
        }

        if (data.hasOwnProperty('playMute')) {
          if (data.playMute) {
            $(element).prop('muted', true);
          } else {
            $(element).prop('muted', false);
          }
        }

        if (data.hasOwnProperty('playVolume')) {
          $(element).prop('volume', data.playVolume / 100);
        }

        if (data.hasOwnProperty('playLoop')) {
          isLoop = data.playLoop;
        }

        if (data.hasOwnProperty('playStart')) {
          try {
            if ($(element).length) {
              await $(element)[0].load();
              if (data.playStart) {
                await $(element)[0].play();
              } else {
                await $(element)[0].pause();
              }
            }
          } catch (err) {
            console.error(err);
          }
        }

        if (data.hasOwnProperty('playDuration')) {
          realPlayDuration =
            data.playDuration === 'auto' ? 0 : Number(data.playDuration);
          leftDuration =
            data.playDuration === 'auto' ? 0 : Number(data.playDuration);
          isVideoLengthPlay = data.playDuration === 'auto';
        }

        if (data.hasOwnProperty('isInit')) {
          if (data?.isInit) {
            await handleVideo(0, true, false);
          }
        }
      } catch (err) {
        console.error(err);
        window?.opener?.postMessage(
          { windowStatus: 'close' },
          process.env.REACT_APP_ORIGIN,
        );
        window.close();
      }
      if (data?.playNext || data?.playPrev) {
        setIsLoading(false);
      }
    }, 2000);
  };

  // const receiveMessage = async (e) => {
  //   if (e.origin !== process.env.REACT_APP_ORIGIN) return;
  //   if (e.data.mode === 'play') {
  //     await handleParentData(e.data);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('message', receiveMessage, false);
  //   window?.opener?.postMessage(
  //     { windowStatus: 'ready' },
  //     process.env.REACT_APP_ORIGIN,
  //   );
  //   return () => {
  //     window.removeEventListener('message', receiveMessage, false);
  //   };
  // }, []);

  window.onbeforeunload = function (e) {
    window?.opener?.postMessage(
      { windowStatus: 'refresh' },
      process.env.REACT_APP_ORIGIN,
    );
  };

  // useEffect(() => {
  //     return () => {
  //         $('#video-0').remove();
  //         $('#video-1').remove();
  //     }
  // }, [isLoading]);

  useEffect(() => {
    if (isInit) return;
    setIsInit(true);
    (async () => {
      let type = videoQuality || videoType;
      let mode = videoMethod || videoMode;
      let vList = mode === 'high' ? HIGH_VIMEO_LIST : LOW_VIMEO_LIST;

      const testData = {
        currentPlayTime: null,
        isInit: true,
        mode: 'play',
        playDuration: '300',
        playId: 262,
        playList: vList?.map((video) => {
          return {
            category: '모던',
            createdAt: '2023-02-04T10:02:02.000Z',
            creator: 1,
            description: video?.name,
            displayPrice: 1000,
            freeVideoUrl: video?.videoUrl[videoType],
            grade: 'A',
            id: 262,
            isAcutalPlay: true,
            isRemoved: false,
            likeCount: 1,
            liked: true,
            price: 1000,
            resolution: '4K',
            sellCount: 2,
            shortDescription: video?.name,
            tags: '',
            thumbnailUrl: 'https://picsum.photos/200',
            title: `테스트 작가 컨텐츠 ${video?.name} ${type}`,
            updatedAt: '2023-02-19T09:08:14.000Z',
            usingCount: 41,
            videoLength: video?.videoLength,
            videoUrl: video?.videoUrl[type],
          };
        }),
        playLoop: false,
        playMute: true,
        playStart: true,
        playType: 'Ordering',
        playVolume: 100,
      };
      console.log(`mode : ${mode} / type : ${type} / videoLoop : ${videoLoop}`);
      await handleParentData(testData);
      setVideoType(type);
      setVideoMode(mode);
    })();
  }, [isInit, videoType, videoQuality, videoMethod, videoMode, setIsInit]);

  const alertUser = () => {
    window?.opener?.postMessage(
      { windowStatus: 'close' },
      process.env.REACT_APP_ORIGIN,
    );
    window.close();
  };

  const handleOnlineVideo = async () => {
    try {
      console.log('VIDEO_BLOB111 ', VIDEO_BLOB);
      const currentIndex = containerPlayIndex;
      let video = $(`#video-${currentIndex}`)[0];
      $(`#video-${currentIndex}`).off('timeupdate');
      // $(`#video-${currentIndex}`).off('pause');
      $(`#video-${currentIndex}`).off('error');
      video.pause();
      if (
        window.confirm(
          '네트워크가 불안정하여 영상 플레이가 일시정지되었습니다. 다시 이어서 플레이 하시겠습니까?',
        )
      ) {
        // console.log('leftDuration before ', leftDuration);
        // $(`#video-${currentIndex}`).off('timeupdate');
        // $(`#video-${currentIndex}`).off('pause');
        // const res = await api.getContent(realPlayList[0].id);

        // if (USE_CONVERT) {
        //   await convertBlob(realPlayList[0].id, res?.data?.videoUrl);
        //   video.src = VIDEO_BLOB[realPlayList[0].id]?.blobUrl;
        // } else {
        //   video.src = res?.data?.videoUrl;
        // }
        video.src = realPlayList[0].videoUrl;
        await video.load();
        video.onloadeddata = function () {
          video.play();
        };
        leftDuration = realPlayDuration;
        isPreparing = false;
        $(`#video-${currentIndex}`).bind('timeupdate', videoListener);
        // $(`#video-${currentIndex}`).bind('pause', handleVideoPause);
        $(`#video-${currentIndex}`).bind('error', handleVideoError);
        console.log('VIDEO_BLOB222 ', VIDEO_BLOB);
      } else {
        window?.opener?.postMessage(
          { windowStatus: 'close' },
          process.env.REACT_APP_ORIGIN,
        );
        window.close();
      }
      console.log('leftDuration after ', leftDuration);
    } catch (err) {
      console.log('handleOnlineVideo err ', err);
      console.log('handleOnlineVideo err response ', err?.response);
    }
  };

  const handleOfflineVideo = () => {
    alert('offline!!!');
  };

  async function handleChangeNetwork(e) {
    // Handle change of connection type here.
    console.error(
      'CHANGE - Navigator.connection ',
      window?.navigator?.connection,
    );
    console.error('CHANGE - Navigator.connection eee ', e);
    console.error('CHANGE - Navigator.connection eee 111 ', e?.currentTarget);
    console.error('CHANGE - Navigator.connection eee 222 ', e?.srcElement);
    console.error('CHANGE - Navigator.connection eee 333 ', e?.target);

    // await handleOnlineVideo();

    // if (window?.navigator?.connection?.downlink < 1.5) {
    //   alert('인테넷 속도 1.5Mb 미만');
    // }
  }

  useEffect(() => {
    if (CHECK_STATUS) {
      window?.navigator?.connection?.addEventListener(
        'change',
        handleChangeNetwork,
      );
      window.addEventListener('online', handleOnlineVideo);
      window.addEventListener('offline', handleOfflineVideo);
    }
    window.addEventListener('beforeunload', alertUser);
    window.addEventListener('webkitfullscreenchange', exitHandler, false);
    window.addEventListener('mozfullscreenchange', exitHandler, false);
    window.addEventListener('fullscreenchange', exitHandler, false);
    window.addEventListener('MSFullscreenChange', exitHandler, false);
    window.addEventListener('keydown', handleUserKeyPress, false);
    window.addEventListener('keypress', handleUserKeyPress, false);

    return () => {
      if (CHECK_STATUS) {
        window?.navigator?.connection?.removeEventListener(
          'change',
          handleChangeNetwork,
        );
        window.removeEventListener('online', handleOnlineVideo);
        window.removeEventListener('offline', handleOfflineVideo);
      }
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('webkitfullscreenchange', exitHandler, false);
      window.removeEventListener('mozfullscreenchange', exitHandler, false);
      window.removeEventListener('fullscreenchange', exitHandler, false);
      window.removeEventListener('MSFullscreenChange', exitHandler, false);
      window.removeEventListener('keydown', handleUserKeyPress, false);
      window.addEventListener('keypress', handleUserKeyPress, false);
    };
  }, []);

  const handleClick = async (e, index) => {
    e.preventDefault();
    setIsFullScreen(true);
    window.document.documentElement.requestFullscreen();
  };

  if (!isInit) return <></>;

  return (
    <>
      {isLoading && <BeamMonsterLoader />}
      {<AdvertisementPlay />}
      <PlayWrapper
        isFullScreen={isFullScreen}
        playList={playInfo?.playList}
        onClick={handleClick}
      />
    </>
  );
}
