import React from 'react';
import * as api from 'apis';
import useAuth from 'hooks/useAuth';
import useEstimate from 'hooks/useEstimate';
import useContract from 'hooks/useContract';
import { useNavigate } from 'react-router-dom';
import SolutionInstallFailWrapper from 'components/SolutionInstallFailWrapper';

export default function SolutionInstallFailContainer() {
  const {
    isLogIn,
    estimate,
  } = useAuth();
  const navigator = useNavigate();

  const { handleResetData } = useEstimate();
  const { handleResetContractData } = useContract();

  const handleCancel = async () => {
    try {
      if (!isLogIn || !estimate?.id) return;
      const res = await api.cancelSolutionApply(estimate.id);
      handleResetData();
      handleResetContractData();
      window.scrollTo(0, 0);
      navigator('/solution/choice-product');
    } catch (err) {
      console.log(err);
    }
  }

  return <SolutionInstallFailWrapper handleCancel={handleCancel} />;
}
