import React, { useEffect, useRef, useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './ThirdCheckList.module.scss';

const cx = classNames.bind(styles);

export default function ThirdCheckList() {
  const [activeTab, setActiveTab] = useState(0);

  const tabClick = (i) => {
    setActiveTab(i);
  };

  const tabArr = [
    {
      tabTitle: (
        <div
          className={cx(activeTab === 0 ? 'bar_active_box' : 'bar_title_box')}
          onClick={() => tabClick(0)}
        >
          <h4 className={cx('title')}>바 & 펍</h4>
          <div className={cx('hr')}></div>
        </div>
      ),
      tabContent: (
        <div className={cx('content_box')}>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/low_teabar.png"
              alt="low_teabar"
            />
          </div>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/minder_gap.png"
              alt="minder_gap"
            />
          </div>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/bilboard.png"
              alt="bilboard"
            />
          </div>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/d_tree.png"
              alt="d_tree"
            />
          </div>
        </div>
      ),
    },
    {
      tabTitle: (
        <div
          className={cx(activeTab === 1 ? 'cafe_active_box' : 'cafe_title_box')}
          onClick={() => tabClick(1)}
        >
          <h4 className={cx('title')}>카페</h4>
          <div className={cx('hr')}></div>
        </div>
      ),
      tabContent: (
        <div className={cx('content_box')}>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/cafe_signature.jpg"
              alt="cafe_signature"
            />
          </div>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/cafe_mejury.jpg"
              alt="cafe_mejury"
            />
          </div>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/cafe_weding.png"
              alt="cafe_weding"
            />
          </div>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/cafe_modeny.jpg"
              alt="cafe_modeny"
            />
          </div>
        </div>
      ),
    },
    {
      tabTitle: (
        <div
          className={cx(
            activeTab === 2 ? 'fitness_active' : 'fitness_title_box',
          )}
          onClick={() => tabClick(2)}
        >
          <h4 className={cx('title')}>휘트니스</h4>
          <div className={cx('hr')}></div>
        </div>
      ),
      tabContent: (
        <div className={cx('content_box')}>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/fitness_resort.jpg"
              alt="fitness_resort"
            />
          </div>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/fitness_rukate.jpg"
              alt="fitness_rukate"
            />
          </div>
        </div>
      ),
    },
    {
      tabTitle: (
        <div
          className={cx(activeTab === 3 ? 'cafe_active_box' : 'cafe_title_box')}
          onClick={() => tabClick(3)}
        >
          <h4 className={cx('title')}>식당</h4>
        </div>
      ),
      tabContent: (
        <div className={cx('content_box')}>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/restaurant_captain.jpg"
              alt="restaurant_captain"
            />
          </div>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/restaurant_jeju.jpg"
              alt="restaurant_jeju"
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className={cx('brightness_box')}>
        <div className={'brightness'}>
          <div className={cx('title_inner')}>
            {tabArr.map((el, i) => {
              return <div key={i}>{el.tabTitle}</div>;
            })}
          </div>
          <div className={cx('content_inner')}>
            {tabArr[activeTab].tabContent}
          </div>
          <div className={cx('caution_inner')}>
            <div>
              <img
                src="/statics/images/ic_input_info_error.png"
                alt="ic_input_info_error"
              />
            </div>
            <div className={cx('caution_text_box')}>
              <p className={cx('caution_text')}>
                사진 보다 더 밝은 공간의 경우는 LED 상품을 추천드립니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
