import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import classNames from 'classnames/bind';
import styles from './PaymentButton.module.scss';

const cx = classNames.bind(styles);

const PaymentButton = ({
  payType,
  merchantUid,
  handleSuccess,
  handlePaymentStart,
  useVerified,
  isVerified,
  amount,
  productName,
}) => {
  const [isStart, setIsStart] = useState(true);

  const { email, name, phone, businessAddress, businessDetailAddress } =
    useAuth();

  const buttonRef = useRef(null);

  const navigator = useNavigate();

  useEffect(() => {
    const jquery = document.createElement('script');
    jquery.src = 'https://code.jquery.com/jquery-1.12.4.min.js';
    const iamport = document.createElement('script');
    iamport.src = 'https://cdn.iamport.kr/js/iamport.payment-1.1.7.js';
    document.head.appendChild(jquery);
    document.head.appendChild(iamport);
    return () => {
      document.head.removeChild(jquery);
      document.head.removeChild(iamport);
    };
  }, []);

  const handleStart = async () => {
    if (handlePaymentStart) {
      const res = await handlePaymentStart();
      if (!res) return;
      setIsStart(false);
      if (!useVerified || (useVerified && isVerified)) {
        buttonRef?.current?.click();
      }
    }
  };

  const callback = (response) => {
    const {
      success,
      error_msg,
      imp_uid,
      merchant_uid,
      pay_method,
      paid_amount,
      status,
    } = response;
    setIsStart(true);
    if (success) {
      handleSuccess && handleSuccess(response);
    } else {
      navigator('/payment-cancel');
    }
  };

  const onClickPayment = useCallback(async () => {
    const { IMP } = window;
    IMP.init(process.env.REACT_APP_IAMPORT_STORE_KEY); // 결제 데이터 정의
    const data = {
      pg: 'smartro', // PG사 (필수항목)
      pay_method: payType, // 결제수단 (필수항목)
      merchant_uid: merchantUid, //상점에서 생성한 고유 주문번호
      name: productName, // 주문명 (필수항목)
      amount, // 금액 (필수항목)
      // custom_data: { name: '부가정보', desc: '세부 부가정보' },
      buyer_name: name, // 구매자 이름
      buyer_tel: phone, // 구매자 전화번호 (필수항목)
      buyer_email: email, // 구매자 이메일
      bypass: {
        acceptmethod: 'no_receipt:--va_receipt', //PC를 위한 파라메터
        P_RESERVED: 'vbank_receipt=N&bank_receipt=N', //모바일을 위한 파라메터
      },
    };
    IMP.request_pay(data, callback);
  }, [merchantUid, payType, name, phone, email, callback, amount, productName]);

  return (
    <>
      {isStart ? (
        <button className={cx('payment_button')} onClick={handleStart}>
          결제하기
        </button>
      ) : (
          <button
            className={cx('payment_button')}
            ref={buttonRef}
            onClick={onClickPayment}
          >
            결제하기
        </button>
        )}
    </>
  );
};

export default PaymentButton;
