import React from 'react';
import classNames from 'classnames/bind';
import styles from './ConceptContent.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function ConceptContent() {
  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <div {...useScrollFadeIn('up', 1, 0.4)} className={cx('title_box')}>
              <h4 className={cx('title')}>
                다양한 컨셉으로.
                <br className={cx('mobile_br')} /> 평생, 무제한 이용
              </h4>
            </div>
            <div {...useScrollFadeIn('up', 1, 0.8)} className={cx('text_box')}>
              <p className={cx('text')}>
                상시 업데이트 되는 다양한 콘텐츠와 함께
                <br className={cx('mobile_br')} /> 매번 새로운 분위기의
                인테리어를 마음껏 연출해 보세요.
              </p>
            </div>
          </div>
        </div>
        <div className={cx('circle_box')}>
          <div
            {...useScrollClipPath('left', 1, 0.2)}
            className={cx('dotted_img_box')}
          >
            <img
              src="/statics/images/concept_dotted_circle.png"
              alt="dotted_circle"
            />
          </div>
          <div
            {...useScrollClipPath('up', 1, 0.2)}
            className={cx('circle_img_box')}
          >
            <img src="/statics/images/concept_circle.png" alt="dotted_circle" />
          </div>
          <div
            {...useScrollClipPath('right', 1, 0.2)}
            className={cx('troangle_img_box')}
          >
            <img
              src="/statics/images/concept_triangle.png"
              alt="dotted_circle"
            />
          </div>
        </div>
        <div className={cx('theme_inner')}>
          <div
            {...useScrollClipPath('left', 1, 1)}
            className={cx('top_theme_box')}
          >
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/moon_beach.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/aurora.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/purple_beach.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/pink_beach.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/pink_cloud.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/moon_beach.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/abstract_wave.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/flower_picture.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/space.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/rainbow_palm_tree.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/flower_particle.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/prev_curve.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/earth.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/rainbow_particle.jpg"
                alt="beach"
              />
            </div>
          </div>
          <div
            {...useScrollClipPath('right', 1, 1.4)}
            className={cx('bottom_theme_box')}
          >
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/mirrorball.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/particle.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/glacier.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/flower_garden.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/whale.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/palm_tree.jpg"
                alt="beach"
              />
            </div>
            <div className={cx('theme_img_box')}>
              <img
                className={cx('theme_img')}
                src="/statics/images/green_aurora.jpg"
                alt="beach"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
