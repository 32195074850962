import React, { useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './MypagePayment.module.scss';
import { Link } from 'react-router-dom';
import Payment from './Payment';
import Installment from './Installment';

const cx = classNames.bind(styles);

export default function MypagePayment() {

    const [activeTab, setActiveTab] = useState(0);

    const tabClick = (i) => {
        setActiveTab(i);
    };

    const paginationTab = [
        {
            tabTitle: (
                <div className={cx(activeTab === 0 ? 'active_account_title_box' : 'account_title_box')} onClick={()=>tabClick(0)}>
                    <h4 className={cx('title')}>
                        결재내역
                    </h4>
                    <hr className={cx('hr')} />
                </div>
            ),
            tabContent:(
                <Payment />
            )
        },
        {
            tabTitle: (
                <div className={cx(activeTab === 1 ? 'active_installment_title_box' : 'installment_title_box')} onClick={()=>tabClick(1)}>
                    <h4 className={cx('title')}>
                        할부현황
                    </h4>
                </div>
            ),
            tabContent:(
                <Installment />
            )
        }
    ]

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <Link to='/mypage' className={cx('back_box')}>
                        <img className={cx('back_img')} src="/statics/images/ic_profile_back.png" alt="ic_profile_back" />
                        <div className={cx('back_text_box')}>
                            <p className={cx('back_text')}>마이페이지</p>
                        </div>
                    </Link>
                    <div className={cx('inner_box')}>
                        <div className={cx('header_inner')}>
                            <div className={cx('title_inner')}>     
                                {paginationTab.map((el, i) => {
                                    return el.tabTitle
                                })}
                            </div>
                            <div className={cx('text_box')}>
                                <p className={cx('text')}>
                                    내역을 클릭하시면 계약서를 확인하실 수 있습니다.
                                </p>
                            </div>
                        </div>
                        <div className={cx('table_inner')}>
                            {paginationTab[activeTab].tabContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
