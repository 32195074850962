
export const LOCATION_LIST = [
  '서울',
  '경기',
  '인천',
  '대전',
  '세종',
  '충남',
  '충북',
  '강원',
  '부산',
  '대구',
  '울산',
  '경남',
  '경북',
  '광주',
  '전남',
  '전북',
  '제주'
];