import React from 'react';
import { Footer, Header } from 'components/views';
import PlayListArtist from 'components/views/PlayListArtist';
import MediaContentPopup from 'components/views/MediaContentPopup';

export default function PlayListArtistWrapper({
  data,
  selectedData,
  handleClickDetail,
  isShow,
  handleClickLike,
  handlePopupClose,
  wishPlayList,
  handleRemove,
  handleWishList,

  contents,
  categories,
  orderBy,
  currentCategory,
  search,
  currentPage,
  lastPage,
  perPage,
  onChange,
  onClick,
  onSearch,
  onKeyPress,
  handlePage,
  total,
  handleLike,
  myPlayList,
  isUnlimitedPlaylist,
  handleUpdatePlayList,
}) {
  return (
    <>
      <Header />
      <PlayListArtist
        data={data}
        handleClickLike={handleClickLike}
        handleClickDetail={handleClickDetail}
        wishPlayList={wishPlayList}
        handleRemove={handleRemove}
        handleWishList={handleWishList}
        isUnlimitedPlaylist={isUnlimitedPlaylist}
        handleUpdatePlayList={handleUpdatePlayList}

        contents={contents}
        categories={categories}
        orderBy={orderBy}
        currentCategory={currentCategory}
        search={search}
        currentPage={currentPage}
        lastPage={lastPage}
        perPage={perPage}
        onChange={onChange}
        onClick={onClick}
        onSearch={onSearch}
        onKeyPress={onKeyPress}
        handlePage={handlePage}
        total={total}
        handleLike={handleLike}
        myPlayList={myPlayList}
      />
      <MediaContentPopup
        key={selectedData?.id}
        data={selectedData}
        isShow={isShow}
        onClose={handlePopupClose}
      />
      <Footer />
    </>
  );
}
