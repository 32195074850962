import React from 'react';
import useAuth from 'hooks/useAuth';
import useCancel from 'hooks/useCancel';
import classNames from 'classnames/bind';
import styles from './CancelSolutionButton.module.scss';

const cx = classNames.bind(styles);

export default function CancelSolutionButton({ }) {
  const { cancelSolutionApply } = useCancel();
  const {
    isLogIn,
    estimate,
  } = useAuth();

  return (
    <>
      {
        isLogIn && estimate?.id &&
        <div className={cx('cancel_button')} onClick={() => cancelSolutionApply()}>
          <div className={cx('cancel_icon_box')}>
            <img className={cx('cancel_icon')} src="/statics/images/close.png" alt="cancel" />
          </div>
          <div className={cx('hide_text_box')}>
            <p className={cx('hide_text')}>솔루션 구매 취소</p>
          </div>
        </div>
      }
    </>
  )
}