
import React, { useState, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './PaymentMethod.module.scss';
import FileUpload from 'components/common/FileUpload';
import * as api from 'apis';

const cx = classNames.bind(styles);

export default function PaymentMethod({
  payType,
  setPayType,
  setBillApply,
  billApply,
  onChange,
  onValid,
  businessNumber,
  businessName,
  businessEmail,
  isBusinessVerified,
  fileUrls,
  handleFileUrls,
}) {
  const [errors, setErrors] = useState({
    isBusinessVerified: false,
  });

  const handleCheckBusinessNumber = useCallback(async () => {
    try {
      const res = await api.checkBusinessNumber(businessNumber);
      if (res?.data?.isValid) {
        onValid(true);
      } else {
        onValid(false);
        setErrors(prev => ({
          ...prev,
          isBusinessVerified: true,
        }));
      }
    } catch (err) {
      onValid(false);
      setErrors(prev => ({
        ...prev,
        isBusinessVerified: true,
      }));
      console.log(err);
    }
  }, [businessNumber, onValid]);

  const onChangeImage = (file, index) => {
    handleFileUrls((prev) => ({
      ...prev,
      [index]: file,
    }));
  };

  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <div className={cx('selection_inner')}>
          <div className={cx('selection_title_box')}>
            <p className={cx('selection_title')}>결제 방식</p>
            <p className={cx('selection_text')}>
              원하시는 결제 방식을 선택해 주세요.
      </p>
          </div>
          <div className={cx('selection_way_inner')}>
            <div className={cx('selection_way_box')}>
              <div
                onClick={() => setPayType('trans')}
                className={cx('way_button_box')}
              >
                {payType === 'trans' ? (
                  <button className={cx('active_way_button')}>
                    <img
                      className={cx('way_button_img')}
                      src="/statics/images/ic-pay-account-on.png"
                      alt="계좌이체 아이콘"
                    />
                    실시간 계좌 이체
            </button>
                ) : (
                    <button className={cx('way_button')}>
                      <img
                        className={cx('way_button_img')}
                        src="/statics/images/ic-pay-account-off.png"
                        alt="계좌이체 아이콘"
                      />
                      실시간 계좌 이체
            </button>
                  )}
              </div>
              <div
                onClick={() => setPayType('card')}
                className={cx('way_button_box')}
              >
                {payType === 'card' ? (
                  <button className={cx('active_way_button')}>
                    <img
                      className={cx('way_button_img')}
                      src="/statics/images/ic-pay-card-on.png"
                      alt="카드 아이콘"
                    />
                    신용카드
            </button>
                ) : (
                    <button className={cx('way_button')}>
                      <img
                        className={cx('way_button_img')}
                        src="/statics/images/ic-pay-card-off.png"
                        alt="카드 아이콘"
                      />
                      신용카드
            </button>
                  )}
              </div>
            </div>
          </div>
          {payType === 'trans' ? (
            <div className={cx('tax_inner')}>
              <div className={cx('selection_title_box')}>
                <p className={cx('tax_title')}>
                  사업자증빙용<b className={cx('tax_dot')}></b>세금계산서
          </p>
              </div>
              <div className={cx('tax_application_inner')}>
                <div className={cx('tax_application_box')}>
                  <input
                    className={cx('hidden_radio_input')}
                    type="radio"
                    checked={!billApply}
                    readOnly
                  />
                  <label
                    onClick={() =>
                      setBillApply(false)
                    }
                    className={cx('radio_label')}
                    htmlFor="notapplied"
                  >
                    <div className={cx('radio_cricle')}></div>
                    <p className={cx('radio_text')}>미신청</p>
                  </label>
                </div>
                <div className={cx('tax_application_box')}>
                  <input
                    className={cx('hidden_radio_input')}
                    type="radio"
                    checked={billApply}
                    readOnly
                  />
                  <label
                    onClick={() =>
                      setBillApply(true)
                    }
                    className={cx('radio_label')}
                    htmlFor="applied"
                  >
                    <div className={cx('radio_cricle')}></div>
                    <p className={cx('radio_text')}>신청</p>
                  </label>
                </div>
              </div>
              {billApply && (
                <div className={cx('tax_input_inner')}>
                  <div className={cx('input_inner')}>
                    <div className={cx('input_box')}>
                      <label className={cx('input_label')}>사업자 번호</label>
                      <div className={cx('input_number_box')}>
                        <input
                          className={cx('input_number')}
                          type="text"
                          placeholder="사업자 번호 입력"
                          name="businessNumber"
                          value={businessNumber}
                          onChange={(e) => { onChange(e); setErrors((prev) => ({ ...prev, isBusinessVerified: false })) }}
                        />
                        <button
                          disabled={!businessNumber}
                          className={cx('certification_button', {
                            active: businessNumber,
                            success: isBusinessVerified,
                          })}
                          onClick={handleCheckBusinessNumber}
                        >
                          {isBusinessVerified ? '완료' : '인증'}
                        </button>
                      </div>
                      {errors.isBusinessVerified && (
                        <div className={cx('fail_box')}>
                          <img
                            className={cx('fail_img')}
                            src="/statics/images/ic_input_info_error.png"
                            alt="info_check"
                          />
                          <p className={cx('fail_text')}>
                            사업자번호가 유효하지않습니다.
                      </p>
                        </div>
                      )}
                    </div>
                    <div className={cx('input_box')}>
                      <label className={cx('input_label')}>
                        사업자 상호 또는 브랜드명
                </label>
                      <div className={cx('input_number_box')}>
                        <input
                          className={cx('input_text')}
                          type="text"
                          placeholder="직접 입력"
                          name="businessName"
                          value={businessName}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={cx('input_inner')}>
                    <div className={cx('input_box')}>
                      <label className={cx('input_label')}>
                        세금계산서 발행을 위한 이메일
                </label>
                      <div className={cx('input_number_box')}>
                        <input
                          className={cx('input_text')}
                          type="text"
                          placeholder="이메일 입력"
                          name="businessEmail"
                          value={businessEmail}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className={cx('input_box')}>
                      <label className={cx('input_label')}>
                        사업자 등록증을 업로드해 주세요.
                </label>
                      <div className={cx('input_number_box')}>
                        {Object.keys(fileUrls).map((key, i) => (
                          <FileUpload
                            key={`${fileUrls[key]?.url}-${i}`}
                            index={i}
                            file={fileUrls[key]}
                            onChangeImage={onChangeImage}
                            style={{
                              width: '60px',
                              height: '60px',
                              marginLeft: '0',
                            }}
                          />
                        ))}
                        <input
                          className={cx('upload_input')}
                          type="text"
                          value={fileUrls[0]?.filename}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
              <div></div>
            )}
        </div>
      </div>
    </div>
  );
}