import React, {
    useCallback,
    useEffect,
    useRef,
} from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import * as api from 'apis';
import useEstimate from 'hooks/useEstimate';
import useAuth from 'hooks/useAuth';
import {
    handleCheckSolution,
} from 'libs/authService';
import SolutionChoiceProductWrapper from 'components/SolutionChoiceProductWrapper/SolutionChoiceProductWrapper';

export default function SolutionChoiceProductContainer() {
    const navigator = useNavigate();
    const {
        isLogIn,
    } = useAuth();

    const {
        estimateId,
        hardware,
        handleChangeData,
    } = useEstimate();

    useEffect(() => {
        (async () => {
            try {
                const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })])
                handleChangeData({
                    estimateId: res[0]?.data?.id || null,
                    hardware: res[0]?.data?.hardware || null,
                });

                handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
            } catch (err) {
                console.log(err);
            }
        })()
    }, [handleChangeData, isLogIn, navigator]);

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            alert('솔루션 신청을 위해 로그인이 필요합니다 : )');
            navigator('/login');
            return;
        }
    }, []);


    const handleSubmit = useCallback(async (data) => {
        if (!data.led && !data.beam) {
            alert('하드웨어를 선택해주세요 : )');
            return;
        }
        try {
            let res;
            if (estimateId) {
                res = await api.updateEstimate(estimateId, {
                    hardware: data.led ? 'LED' : 'BEAM',
                });
            } else {
                res = await api.postEstimate({
                    hardware: data.led ? 'LED' : 'BEAM',
                });
            }
            handleChangeData({
                estimateId: res?.data?.id,
                hardware: data.led ? 'LED' : 'BEAM',
            });
            navigator(`/solution/request-estimate/${data.led ? 'led' : 'beam'}`);
        } catch (err) {
            console.log(err);
        }
    }, [navigator, estimateId, handleChangeData]);

    return (
        <SolutionChoiceProductWrapper
            onSubmit={handleSubmit}
            hardware={hardware}
        />
    )
}
