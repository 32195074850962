import React, { useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './MypageScheduleEdit.module.scss';

const cx = classNames.bind(styles);

export default function CalendarDate(props) {

    const { lastDate, firstDate, el, findToday, month, year, i } = props;

    const [userInput, setUserInput] = useState({});
    const [evtList, setEvtList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    let dateKey = `${month}` + `${el}`;
    const registEvent = (value) => {
        setEvtList([...evtList, value]);
        setUserInput('');
        setOpenModal(false);
    };

    return (
        <div>
            <div
                i={i}
                lastDate={lastDate}
                firstDate={firstDate}
                findToday={findToday}
            >
                <div findToday={findToday}>{el}</div>
            </div>
            {Boolean(evtList[0]) && (
                <div>
                    {evtList.map((list, i) => {
                    return (
                        list.slice(0, list.indexOf('_')) === dateKey && (
                        <div
                            key={i}
                            onClick={() => {
                            setOpenModal(true);
                            }}
                        >
                            {list.slice(list.indexOf('_') + 1, list.length)}
                        </div>
                        )
                    );
                    })}
                </div>
            )}
        </div>
    )
}
