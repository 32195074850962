import React, { useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './MypageSolution.module.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function MypageSolution() {
    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <Link to='/mypage' className={cx('back_box')}>
                        <img className={cx('back_img')} src="/statics/images/ic_profile_back.png" alt="ic_profile_back" />
                        <div className={cx('back_text_box')}>
                            <p className={cx('back_text')}>마이페이지</p>
                        </div>
                    </Link>
                    <div className={cx('inner_box')}>
                        <div className={cx('title_box')}>
                            <h4 className={cx('title')}>
                                내 솔루션 구매 현황
                            </h4>
                        </div>
                        <div className={cx('proccess_inner')}>
                            <div className={cx('proccess_box')}>
                                <div className={cx('selected_process_box')}>
                                    <div className={cx('process_title_box')}>
                                        <h4 className={cx('selected_process_title')}>상품선택</h4>
                                    </div>
                                </div>
                                <div className={cx('selected_process_bar')}></div>
                                <div className={cx('selected_process_box')}>
                                    <div className={cx('process_title_box')}>
                                        <h4 className={cx('selected_process_title')}>견적요청</h4>
                                    </div>
                                </div>
                                <div className={cx('selected_process_bar')}></div>
                                <div className={cx('selected_process_box')}>
                                    <div className={cx('selected_process_title_box')}>
                                        <h4 className={cx('selected_process_title')}>견적발행</h4>
                                    </div>
                                </div>
                                <div className={cx('select_process_bar')}></div>
                                <div className={cx('select_process_box')}>
                                    <div className={cx('select_process_img_box')}>
                                        <img src="/statics/images/solution_select.png" alt="solution_select"/>
                                    </div>
                                    <div className={cx('select_process_title_box')}>
                                        <h4 className={cx('select_process_title')}>결제방식</h4>
                                    </div>
                                </div>
                                {/* 모바일 상태 박스 */}
                                <div className={cx('mobile_process_step_box')}>
                                    <p className={cx('process_step')}>
                                        하위 단계 상태 노출
                                    </p>
                                </div>
                                <div className={cx('process_bar')}></div>
                                <div className={cx('process_box')}>
                                    <div className={cx('process_title_box')}>
                                        <h4 className={cx('process_title')}>계약현황 확인</h4>
                                    </div>
                                </div>
                                <div className={cx('process_bar')}></div>
                                <div className={cx('process_box')}>
                                    <div className={cx('process_title_box')}>
                                        <h4 className={cx('process_title')}>계약서 작성</h4>
                                    </div>
                                </div>
                                <div className={cx('process_bar')}></div>
                                <div className={cx('process_box')}>
                                    <div className={cx('process_title_box')}>
                                        <h4 className={cx('process_title')}>입금 확인</h4>
                                    </div>
                                </div>
                                <div className={cx('process_bar')}></div>
                                <div className={cx('process_box')}>
                                    <div className={cx('process_title_box')}>
                                        <h4 className={cx('process_title')}>설치 스케줄</h4>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('process_step_box')}>
                                <p className={cx('process_step')}>
                                    하위 단계 상태 노출
                                </p>
                            </div>
                            <Link to='/solution/choice-product' className={cx('button_box')}>
                                <button className={cx('button')}>솔루션 구매 페이지 이동</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
