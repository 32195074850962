import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './SolutionRequestEstimateBeam.module.scss';
import CancelSolutionButton from 'components/common/CancelSolutionButton';
import { NavLink } from 'react-router-dom';
import Popup from '../Popup/Popup';

const cx = classNames.bind(styles);

export default function SolutionRequestEstimateBeam({
  data,
  onChange,
  onSubmit,
}) {
  const [buttonPopup, setButtonPopup] = useState(false);

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('header_container')}>
          <div className={cx('inner')}>
            <div className={cx('header_inner')}>
              <div className={cx('selected_process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('selected_process_title')}>상품선택</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('select_process_box')}>
                <div className={cx('select_process_img_box')}>
                  <img
                    src="/statics/images/solution_select.png"
                    alt="solution_select"
                  />
                </div>
                <div className={cx('select_process_title_box')}>
                  <h4 className={cx('select_process_title')}>견적요청</h4>
                </div>
              </div>
              <div className={cx('select_process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>견적검토</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>설치확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>유저정보</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>결제방식</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_status_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>계약현황 확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>계약서 작성</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>입금 확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>설치 스케줄</h4>
                </div>
              </div>
            </div>
            <div className={cx('mobile_header_inner')}>
              <div className={cx('mobile_header_progressbar_box')}>
                <div className={cx('mobile_header_progressbar')}></div>
              </div>
              <div className={cx('select_process_box')}>
                <div className={cx('mobile_select_text_box')}>
                  <p className={cx('mobile_select_text')}>step.2</p>
                </div>
                <div className={cx('mobile_select_process_title_box')}>
                  <div className={cx('select_process_title_box')}>
                    <h4 className={cx('select_process_title')}>견적요청</h4>
                  </div>
                  <div className={cx('select_process_img_box')}>
                    <img
                      src="/statics/images/solution_select.png"
                      alt="solution_select"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <CancelSolutionButton />
            <div
              className={cx('checklist_button')}
              onClick={() => setButtonPopup(true)}
            >
              <div className={cx('checklist_icon_box')}>
                <img
                  className={cx('checklist_icon')}
                  src="/statics/images/check_list.png"
                  alt="check_list"
                />
              </div>
              <div className={cx('hide_text_box')}>
                <p className={cx('hide_text')}>체크리스트 보기</p>
              </div>
            </div>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>빔 프로젝터 투사 면적 선택</h4>
            </div>
            <div className={cx('size_inner')}>
              <div className={cx('size_view_inner')}>
                <div className={cx('size_height_panel')}>
                  <hr className={cx('height_panel')} />
                  <p className={cx('panel_text')}>H</p>
                  <hr className={cx('height_panel')} />
                </div>
                <div className={cx('size_width_panel')}>
                  <hr className={cx('panel_hr')} />
                  <p className={cx('panel_text')}>W</p>
                  <hr className={cx('panel_hr')} />
                </div>
                <div className={cx('size_view_box')}>
                  <p className={cx('size_view_text')}>
                    빔 프로젝터 투사 면적 선택
                  </p>
                </div>
              </div>
              <div className={cx('size_select_box')}>
                <div className={cx('select_input_inner')}>
                  <div className={cx('size_title_box')}>
                    <p className={cx('size_title')}>W X H(m) 선택</p>
                  </div>
                  <div className={cx('vim_size_select_inner')}>
                    <div className={cx('vim_size_select_box')}>
                      <input
                        name="size"
                        className={cx('none_radio', {
                          active:
                            data.size === '1대: 넓이 2.5~4.5 X 높이 1.3~2.5(m)',
                        })}
                        id="first"
                        value="1대: 넓이 2.5~4.5 X 높이 1.3~2.5(m)"
                        type="radio"
                        onClick={onChange}
                      />
                      <label
                        className={cx('radio_label')}
                        htmlFor="first"
                      ></label>
                      <label className={cx('input_label')} htmlFor="first">
                        1대: 넓이 1.33~4.4 X 높이 0.75~2.5(m)
                      </label>
                    </div>
                    <div className={cx('vim_size_select_box')}>
                      <input
                        name="size"
                        className={cx('none_radio', {
                          active:
                            data.size === '2대: 넓이 4.3~7 X 높이 1.3~2.5(m)',
                        })}
                        id="second"
                        value="2대: 넓이 4.3~7 X 높이 1.3~2.5(m)"
                        type="radio"
                        onClick={onChange}
                      />
                      <label
                        className={cx('radio_label')}
                        htmlFor="second"
                      ></label>
                      <label className={cx('input_label')} htmlFor="second">
                        2대: 넓이 4.5~7.1 X 높이 0.75~2.5(m)
                      </label>
                    </div>
                  </div>
                  <div className={cx('vim_size_select_inner')}>
                    <div className={cx('vim_size_select_box')}>
                      <input
                        name="size"
                        className={cx('none_radio', {
                          active:
                            data.size ===
                            '3대: 넓이 6.5~11.5 X 높이 1.3~2.5(m)',
                        })}
                        id="third"
                        value="3대: 넓이 6.5~11.5 X 높이 1.3~2.5(m)"
                        type="radio"
                        onClick={onChange}
                      />
                      <label
                        className={cx('radio_label')}
                        htmlFor="third"
                      ></label>
                      <label className={cx('input_label')} htmlFor="third">
                        3대: 넓이 7.2~10.6 X 높이 0.75~2.5(m)
                      </label>
                    </div>
                    <div className={cx('vim_size_select_box')}>
                      <input
                        name="size"
                        className={cx('none_radio', {
                          active:
                            data.size === '4대: 넓이 11~14 X 높이 1.3~2.5(m)',
                        })}
                        id="four"
                        value="4대: 넓이 11~14 X 높이 1.3~2.5(m)"
                        type="radio"
                        onClick={onChange}
                      />
                      <label
                        className={cx('radio_label')}
                        htmlFor="four"
                      ></label>
                      <label className={cx('input_label')} htmlFor="four">
                        4대: 넓이 10.7~14.2 X 높이 0.75~2.5(m)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx('button_box')}>
              <div className={cx('prev_button_box')}>
                <NavLink
                  to="/solution/choice-product"
                  className={cx('prev_button')}
                >
                  이전 단계 확인
                </NavLink>
              </div>
              <div className={cx('next_button_box')}>
                <button className={cx('next_button')} onClick={onSubmit}>
                  다음 단계로 이동
                </button>
              </div>
            </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} />
          </div>
        </div>
      </div>
    </div>
  );
}
