import React, { useEffect, useState } from 'react';
import RefundWrapper from 'components/RefundWrapper';
import * as api from 'apis';

export default function RefundContainer() {
  const [content, setContent] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const res = await api.getCompanyInfo();
        setContent(res?.data?.value?.advertiseRefundTerm);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return <RefundWrapper content={content} />;
}
