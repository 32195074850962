import React, { useEffect, useState, } from 'react';
import {
  Header,
  Footer,
} from 'components/views';
import * as api from 'apis';

function PrivacyPage() {
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await api.getCompanyInfo();
        setData(res?.data?.value?.privacyTerm);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [setData]);

  return <>
    <Header />
    <div style={{
      padding: '120px 20px 60px 20px',
      minHeight: '100vh',
      maxWidth: '1280px',
      margin: '0 auto',
    }} dangerouslySetInnerHTML={{ __html: data?.replace(/(?:\r\n|\r|\n)/g, "<br />") }}>
    </div>
    <Footer />
  </>;
}

export default PrivacyPage;