import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from 'apis';
import classNames from 'classnames/bind';
import styles from './Header.module.scss';
import useAuth from 'hooks/useAuth';
import useEstimate from 'hooks/useEstimate';
import useContract from 'hooks/useContract';
import useCompany from 'hooks/useCompany';
import useArtist from 'hooks/useArtist';
import { useLocation } from 'react-router-dom';
import {
  handleSolution,
  handlePlay,
  handleAdvertisement,
} from 'libs/authService';
import moment from 'moment';
import _ from 'lodash/fp';
import { Link, NavLink } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function Header({ type, checkLogin = true }) {
  const navigator = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [estimate, setEstimate] = useState({});
  const [contract, setContract] = useState({});

  const [dropdown, setDropdown] = useState({
    intro: false,
    mypage: false,
  });

  const { handleChangeCompanyData } = useCompany();

  const { handleResetData, handleChangeData } = useEstimate();
  const { handleResetContractData, handleChangeContractData } = useContract();

  const {
    isLogIn,
    name,
    profileImageUrl,
    isTestAccount,
    isTempBlock,
    handleChangeAuthData,
    isUnlimitedPlaylist,
    handleResetAuthData,
    checkAuthPlay,
  } = useAuth();

  const AS_MENU = isTempBlock ? 'A/S신청' : '설치 및 A/S신청';

  const { wishArtistPlayList, handleUpdateArtist } = useArtist();

  useEffect(() => {
    if (!checkLogin) return;
    (async () => {
      try {
        handleResetData();
        handleResetContractData();
        if (!localStorage.getItem('token')) return;
        const res = await api.checkAuth();
        const {
          name,
          phone,
          profileImageUrl,
          birthday,
          businessAddress,
          businessDetailAddress,
          businessBusyHour,
          businessCategory,
          businessSubCategory,
          businessCloseTime,
          businessCustomerAge,
          businessDailyVisitor,
          businessMaxSeat,
          businessName,
          businessNumber,
          businessOpenTime,
          businessTimeMemo,
          contract,
          email,
          estimate,
          gender,
          dayoff,
          playlistResetCount,
          isUnlimitedPlaylist,
        } = res?.data;

        handleChangeAuthData({
          isLogIn: true,
          name,
          phone,
          profileImageUrl,
          birthday: moment(birthday).format('YYYY-MM-DD'),
          businessAddress,
          businessDetailAddress,
          businessBusyHour,
          businessCategory,
          businessSubCategory,
          businessCloseTime,
          businessCustomerAge,
          businessDailyVisitor,
          businessMaxSeat,
          businessName,
          businessNumber,
          businessOpenTime,
          businessTimeMemo,
          contract,
          email,
          estimate,
          gender,
          dayoff,
          playlistResetCount,
          isUnlimitedPlaylist,
        });
      } catch (err) {
        console.log(err);
        if (
          err?.response?.status === 403 &&
          err?.response?.data?.message === 'invalid token'
        ) {
          await localStorage.clear();
          await sessionStorage.clear();
          handleResetAuthData();
          alert('다른 기기에서 중복로그인 되었습니다.');
          window.location.reload();
        }

        navigator('/login');
      }
    })();
  }, [handleResetData, handleResetContractData, handleChangeAuthData]);

  useEffect(() => {
    if (!localStorage.getItem('token')) return;
    (async () => {
      try {
        const res = await Promise.all([
          api.getMyEstimate().catch(() => { }),
          api.getMyContract().catch(() => { }),
        ]);
        handleChangeData({
          estimateId: res[0]?.data?.id || null,
          hardware: res[0]?.data?.hardware || '',
          ledWidth: res[0]?.data?.ledWidth * 1000 || '',
          ledHeight: res[0]?.data?.ledHeight * 1000 || '',
          beamSize: res[0]?.data?.beamSize || '',
          address: res[0]?.data?.address || '',
          detailAddress: res[0]?.data?.detailAddress || '',
          extraInfo: res[0]?.data?.extraInfo || '',
          solutionType: res[0]?.data?.solutionType || '',
          status: res[0]?.data?.status || '',
        });
        handleChangeContractData({
          contractId: res[1]?.data?.id || null,
          status: res[1]?.data?.status || '',
          paymentType: res[1]?.data?.paymentType || '',
          filename: res[1]?.data?.filename || '',
          fileUrl: res[1]?.data?.fileUrl || '',
          contractFile: res[1]?.data?.clientSignPdfUrl || '',
          visitDate: res[1]?.data?.visitDate || '',
          visitManagerName: res[1]?.data?.visitManagerName || '',
          visitManagerPhone: res[1]?.data?.visitManagerPhone || '',
          visitManagerProfileImageUrl:
            res[1]?.data?.visitManagerProfileImageUrl || '',
          pdfImageUrls: res[1]?.data?.pdfImageUrls || [],
          shouldSignImageIndex: res[1]?.data?.shouldSignImageIndex || [],
        });

        setEstimate(res[0]?.data || {});
        setContract(res[1]?.data || {});
      } catch (err) {
        console.log(err);
      }
    })();
  }, [
    setEstimate,
    setContract,
    location,
    handleChangeData,
    handleChangeContractData,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const res = await api.getCompanyInfo();
        handleChangeCompanyData(res?.data?.value);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [handleChangeCompanyData]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       await handleUpdateArtist();
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   })();
  // }, []);

  const handleMyPlay = () => {
    if (!isLogIn) {
      alert('콘텐츠 플레이는 로그인이 필요한 서비스입니다 : )');
      navigator('/login');
      return;
    }

    if (isUnlimitedPlaylist) return navigator('/play-list/media');

    if (estimate && estimate.status === 'CONFIRM') {
      if (
        contract &&
        (contract.status === 'FINISH' || contract.status === 'SCHEDULED')
      ) {
        if (estimate.solutionType === 'PREMIUM') {
          navigator('/play-list/media');
        } else if (estimate.solutionType === 'STANDARD') {
          navigator('/play-list/media');
        } else if (estimate.solutionType === 'BASIC') {
          navigator('/play-list/media');
        } else {
          checkAuthPlay(estimate, contract);
          alert(
            '마이 콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )',
          );
        }
      } else {
        checkAuthPlay(estimate, contract);
        alert('마이 콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
      }
    } else {
      checkAuthPlay(estimate, contract);
      alert('마이 콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
    }
  };

  const handleLogout = async () => {
    await localStorage.clear();
    await sessionStorage.clear();
    handleResetAuthData();
    navigator('/login');
  };

  // 장바구니 이동(PC버전)
  const handleBasket = () => {
    navigator('/mypage/basket/artist');
  };

  const handlePartner = () => {
    navigator('/partner');
  };

  const isShowedContent = useMemo(() => {
    if (isUnlimitedPlaylist) return true;

    if (estimate && estimate.status === 'CONFIRM') {
      if (
        contract &&
        (contract.status === 'FINISH' || contract.status === 'SCHEDULED')
      ) {
        if (estimate.solutionType === 'PREMIUM') {
          return true;
        } else if (estimate.solutionType === 'STANDARD') {
          return true;
        } else if (estimate.solutionType === 'BASIC') {
          return true;
        } else {
          // alert('상영할 준비가 되셨나요? 콘텐츠 플랫폼 구독 및 로그인 후 이용 가능합니다');
          return true;
        }
      } else {
        // alert('상영할 준비가 되셨나요? 콘텐츠 플랫폼 구독 및 로그인 후 이용 가능합니다');
        return true;
      }
    } else {
      // alert('상영할 준비가 되셨나요? 콘텐츠 플랫폼 구독 및 로그인 후 이용 가능합니다');
      return true;
    }
  }, [isUnlimitedPlaylist, estimate, contract]);

  const moveSolution = useCallback(() => {
    window.location.href = 'https://beammonsterstore.wixsite.com/store';
  }, []);

  return (
    <div className={cx('container')}>
      {type === 'black' ? (
        <div className={cx('sub_container')}>
          <div className={cx('inner')}>
            <div className={cx('inner_box')}>
              <div className={cx('left_inner')}>
                <Link to="/" className={cx('logo_box')}>
                  <img src="/statics/images/gnb_logo.png" alt="main_logo" />
                </Link>
                <div className={cx('menu_list_box')}>
                  <button
                    className={cx('menu_item_box')}
                    onClick={() =>
                      // handleSolution(isLogIn, estimate, contract, navigator)
                      moveSolution()
                    }
                  >
                    <div className={cx('menu_item_img_box')}>
                      <img
                        className={cx('menu_list_img')}
                        src="/statics/images/gnb_solution_off.png"
                        alt="solution"
                      />
                      <img
                        className={cx('menu_list_hover_img')}
                        src="/statics/images/gnb_solution.png"
                        alt="solution"
                      />
                    </div>
                    <div className={cx('menu_text_box')}>
                      <h4 className={cx('menu_text')}>솔루션 구매</h4>
                    </div>
                  </button>
                  <div className={cx('menu_item_box')}>
                    <div className={cx('menu_item_img_box')}>
                      <img
                        className={cx('menu_list_img')}
                        src="/statics/images/gnb_info_off.png"
                        alt="info"
                      />
                      <img
                        className={cx('menu_list_hover_img')}
                        src="/statics/images/gnb_info.png"
                        alt="info"
                      />
                    </div>
                    <div className={cx('menu_text_box')}>
                      <h4 className={cx('menu_text')}>
                        소개
                        <div className={cx('menu_list_hover_box')}>
                          <ul className={cx('menu_item_list_box')}>
                            <li className={cx('menu_item')}>
                              <NavLink
                                to="/intro/service"
                                className={({ isActive }) =>
                                  cx({ active: isActive })
                                }
                              >
                                미디어 인테리어
                                <br />
                                서비스 소개
                              </NavLink>
                            </li>
                            {!isTempBlock && (
                              <li className={cx('menu_item')}>
                                <NavLink
                                  to="/intro/advertisement"
                                  className={({ isActive }) =>
                                    cx({ active: isActive })
                                  }
                                >
                                  옥외 광고
                                  <br />
                                  서비스 소개
                                </NavLink>
                              </li>
                            )}
                            <li className={cx('menu_item')}>
                              <NavLink
                                to="/intro/example"
                                className={({ isActive }) =>
                                  cx({ active: isActive })
                                }
                              >
                                서비스 이용 사례
                              </NavLink>
                            </li>
                            <li className={cx('menu_item')}>
                              <NavLink
                                to="/intro/company"
                                className={({ isActive }) =>
                                  cx({ active: isActive })
                                }
                              >
                                회사 소개
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </h4>
                    </div>
                  </div>
                  <button
                    className={cx('menu_item_box')}
                    onClick={() => handlePartner()}
                  >
                    <div className={cx('menu_item_img_box')}>
                      <img
                        className={cx('menu_list_img', 'menu_list_partner_img')}
                        src="/statics/images/partner_off.png"
                        alt="solution"
                      />
                      <img
                        className={cx(
                          'menu_list_hover_img',
                          'menu_list_partner_hover_img',
                        )}
                        src="/statics/images/partner.png"
                        alt="solution"
                      />
                    </div>
                    <div className={cx('menu_text_box')}>
                      <h4 className={cx('menu_text')}>파트너사 모집</h4>
                    </div>
                  </button>
                  {isShowedContent && (
                    <button
                      className={cx('menu_item_box')}
                      onClick={() =>
                        handlePlay(
                          isLogIn,
                          estimate,
                          contract,
                          navigator,
                          isUnlimitedPlaylist,
                        )
                      }
                    >
                      <div className={cx('menu_item_img_box')}>
                        <img
                          className={cx('menu_list_img')}
                          src="/statics/images/gnb_playlist_off.png"
                          alt="playlist"
                        />
                        <img
                          className={cx('menu_list_hover_img')}
                          src="/statics/images/gnb_playlist.png"
                          alt="playlist"
                        />
                      </div>
                      <div className={cx('menu_text_box')}>
                        <h4 className={cx('menu_text')}>콘텐츠 플레이</h4>
                      </div>
                    </button>
                  )}
                  {!isTempBlock && (
                    <button
                      className={cx('menu_item_box')}
                      onClick={() => handleAdvertisement(navigator)}
                    >
                      <div className={cx('menu_item_img_box')}>
                        <img
                          className={cx('menu_list_img')}
                          src="/statics/images/gnb-ad-off.png"
                          alt="광고문의"
                        />
                        <img
                          className={cx('menu_list_hover_img')}
                          src="/statics/images/gnb-ad.png"
                          alt="광과문의"
                        />
                      </div>
                      <div className={cx('menu_text_box')}>
                        <h4 className={cx('menu_text')}>광고 문의</h4>
                      </div>
                    </button>
                  )}
                </div>
              </div>
              <div className={cx('right_inner')}>
                {isLogIn ? (
                  <div className={cx('login_inner')}>
                    <div className={cx('login_img_box')}>
                      <img
                        className={cx('img_profile')}
                        src={
                          profileImageUrl
                            ? profileImageUrl
                            : '/statics/images/login_img.png'
                        }
                        alt="login_img"
                      />
                    </div>
                    <div className={cx('login_nick_name_box')}>
                      <p className={cx('black_login_nick_name')}>{name}</p>
                      <div>
                        <img
                          src="/statics/images/gnb_profile_more.png"
                          alt="gnb_profile_more"
                        />
                      </div>
                      <div className={cx('hide_login_inner')}>
                        <div className={cx('hide_login_list_item_box')}>
                          <button
                            className={cx('hide_login_list_item')}
                            onClick={handleMyPlay}
                          >
                            나의 플레이리스트
                          </button>
                        </div>
                        <div className={cx('hide_login_list_item_box')}>
                          <Link
                            className={cx('hide_login_list_item')}
                            to="/mypage"
                          >
                            마이페이지
                          </Link>
                        </div>
                        <div className={cx('hide_login_list_item_box')}>
                          <Link
                            className={cx('hide_login_list_item')}
                            to="/mypage/faq"
                          >
                            고객센터
                          </Link>
                        </div>
                        <div className={cx('hide_login_list_item_box')}>
                          <Link
                            className={cx('hide_login_list_item')}
                            to="/mypage/as"
                          >
                            {AS_MENU}
                          </Link>
                        </div>
                        {/* {!isTempBlock && ( */}
                        <div className={cx('hide_login_list_item_box')}>
                          <Link
                            className={cx('hide_login_list_item')}
                            to="/mypage/payment-history"
                          >
                            결제 내역
                            </Link>
                        </div>
                        {/* )} */}
                        {/* <div className={cx('hide_login_list_item_box')}>
                          <Link
                            className={cx('hide_login_list_item')}
                            to="/mypage/advertisement"
                          >
                            내 광고
                          </Link>
                        </div> */}
                        {/* {!isTempBlock && ( */}
                        <div className={cx('hide_login_list_item_box')}>
                          <Link
                            className={cx('hide_login_list_item')}
                            to="/mypage/basket/artist"
                          >
                            장바구니
                            </Link>
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                    <div className={cx('logout_box', 'black_logout_box')}>
                      <img
                        className={cx('logout_img')}
                        onClick={handleLogout}
                        src="/statics/images/logout.png"
                        alt="logout"
                      />
                    </div>
                    {/* {!isTempBlock && ( */}
                    <div className={cx('basket_box')}>
                      <Link to="/mypage/basket/artist">
                        <div className={cx('basket_number_box')}>
                          <span className={cx('basket_number')}>
                            {wishArtistPlayList?.length > 99
                              ? `99+`
                              : wishArtistPlayList?.length > 10
                                ? wishArtistPlayList.length
                                : `0${wishArtistPlayList.length}`}
                          </span>
                        </div>
                        <img
                          className={cx('basket_img')}
                          onClick={handleBasket}
                          src="/statics/images/gnb-cart.png"
                          alt="장바구니"
                        />
                      </Link>
                    </div>
                    {/* )} */}
                  </div>
                ) : (
                    <div className={cx('button_inner')}>
                      <div className={cx('button_box')}>
                        <Link className={cx('signup_button')} to="/signup">
                          회원가입
                      </Link>
                      </div>
                      <div className={cx('button_box')}>
                        <Link className={cx('signin_button')} to="/login">
                          로그인
                      </Link>
                      </div>
                    </div>
                  )}
                <div
                  onClick={() => setOpen(!open)}
                  className={cx('mobile_hamburger_bar')}
                >
                  {location.pathname === '/' ? (
                    <img src="/statics/images/nav_menu.png" alt="nav_menu" />
                  ) : (
                      <img
                        src="/statics/images/nav_menu_w.png"
                        alt="nav_menu_w"
                      />
                    )}
                </div>
              </div>
            </div>

            {/* 모바일 */}
            <div
              className={cx(
                open ? 'active_mobile_menu_inner' : 'mobile_menu_inner',
              )}
            >
              <div className={cx('mobile_header_inner')}>
                <div className={cx('mobile_header_box')}>
                  <Link to="/" className={cx('logo_box')}>
                    <img
                      src="/statics/images/gnb_logo_w.png"
                      alt="gnb_logo_w"
                    />
                  </Link>
                  <img
                    onClick={() => setOpen(!open)}
                    className={cx('mobile_menu_inner_close')}
                    src="/statics/images/nav_close_w.png"
                    alt="nav_close_w"
                  />
                </div>
                <div className={cx('mobile_login_inner')}>
                  <div className={cx('mobile_login_profile_box')}>
                    <div className={cx('login_img_box')}>
                      <img
                        className={cx('img_profile')}
                        src={
                          profileImageUrl
                            ? profileImageUrl
                            : '/statics/images/login_img.png'
                        }
                        alt="login_img"
                      />
                    </div>
                    <div className={cx('login_nick_name_box')}>
                      {/* 로그인 후 */}
                      {isLogIn ? (
                        <p className={cx('login_nick_name')}>{name}</p>
                      ) : (
                          <Link to="/login" className={cx('login_nick_name')}>
                            로그인을 해주세요
                        </Link>
                        )}
                      <div>
                        <img
                          src="/statics/images/gnb_profile_more_close.png"
                          alt="gnb_profile_more_close"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {isLogIn ? (
                      <div className={cx('logout_box')}>
                        <img
                          className={cx('logout_img')}
                          onClick={handleLogout}
                          src="/statics/images/mobile_logout.png"
                          alt="mobile_logout"
                        />
                      </div>
                    ) : (
                        <div className={cx('mobile_button_box')}>
                          <Link
                            className={cx('mobile_signup_button')}
                            to="/signup"
                          >
                            회원가입
                        </Link>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className={cx('mobile_menu_list_box')}>
                <div className={cx('mobile_menu_item_container')}>
                  <button
                    className={cx('mobile_menu_item_inner')}
                    onClick={() =>
                      // handleSolution(isLogIn, estimate, contract, navigator)
                      moveSolution()
                    }
                  >
                    <div className={cx('mobile_menu_item_box')}>
                      <div className={cx('mobile_menu_item_img_box')}>
                        <img
                          className={cx('mobile_menu_list_img')}
                          src="/statics/images/gnb_solution_w.png"
                          alt="solution"
                        />
                      </div>
                      <div className={cx('mobile_menu_text_box')}>
                        <h4 className={cx('mobile_menu_text')}>솔루션 구매</h4>
                      </div>
                    </div>
                    <div>
                      <img
                        src="/statics/images/ic_profile_list_more.png"
                        alt="ic_profile_list_more"
                      />
                    </div>
                  </button>
                  <div
                    onClick={() =>
                      setDropdown((prev) => ({ ...prev, intro: !prev.intro }))
                    }
                    className={cx('mobile_menu_item_inner')}
                  >
                    <div className={cx('mobile_menu_item_box')}>
                      <div className={cx('mobile_menu_item_img_box')}>
                        <img
                          className={cx('mobile_menu_list_img')}
                          src="/statics/images/gnb_info_w.png"
                          alt="info"
                        />
                      </div>
                      <div className={cx('mobile_menu_text_box')}>
                        <h4 className={cx('mobile_menu_text')}>소개</h4>
                      </div>
                    </div>
                    <div
                      className={cx(
                        dropdown.intro
                          ? 'active_mobile_menu_hide_box_img'
                          : 'mobile_menu_hide_box_img',
                      )}
                    >
                      <img
                        src="/statics/images/ic_side_list_open.png"
                        alt="ic_side_list_open"
                      />
                    </div>
                  </div>
                  <div>
                    {dropdown.intro && (
                      <div className={cx('mobile_menu_list_hover_box')}>
                        <ul className={cx('mobile_menu_item_list_box')}>
                          <li className={cx('mobile_menu_item')}>
                            <NavLink
                              to="/intro/service"
                              className={({ isActive }) =>
                                cx({ active: isActive })
                              }
                            >
                              미디어 인테리어 서비스 소개
                            </NavLink>
                          </li>
                          {!isTempBlock && (
                            <li className={cx('mobile_menu_item')}>
                              <NavLink
                                to="/intro/advertisement"
                                className={({ isActive }) =>
                                  cx({ active: isActive })
                                }
                              >
                                옥외 광고 서비스 소개
                              </NavLink>
                            </li>
                          )}
                          <li className={cx('mobile_menu_item')}>
                            <NavLink
                              to="/intro/example"
                              className={({ isActive }) =>
                                cx({ active: isActive })
                              }
                            >
                              서비스 이용 사례
                            </NavLink>
                          </li>
                          <li className={cx('mobile_menu_item')}>
                            <NavLink
                              to="/intro/company"
                              className={({ isActive }) =>
                                cx({ active: isActive })
                              }
                            >
                              회사 소개
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <button
                    className={cx('mobile_menu_item_inner')}
                    onClick={() => handlePartner()}
                  >
                    <div className={cx('mobile_menu_item_box')}>
                      <div className={cx('mobile_menu_item_img_box')}>
                        <img
                          className={cx('mobile_menu_list_img')}
                          src="/statics/images/partner_off.png"
                          alt="solution"
                        />
                      </div>
                      <div className={cx('mobile_menu_text_box')}>
                        <h4 className={cx('mobile_menu_text')}>
                          파트너사 모집
                        </h4>
                      </div>
                    </div>
                    <div>
                      <img
                        src="/statics/images/ic_profile_list_more.png"
                        alt="ic_profile_list_more"
                      />
                    </div>
                  </button>
                  {isShowedContent && (
                    <button
                      className={cx('mobile_menu_item_inner')}
                      onClick={() =>
                        handlePlay(
                          isLogIn,
                          estimate,
                          contract,
                          navigator,
                          isUnlimitedPlaylist,
                        )
                      }
                    >
                      <div className={cx('mobile_menu_item_box')}>
                        <div className={cx('mobile_menu_item_img_box')}>
                          <img
                            className={cx('mobile_menu_list_img')}
                            src="/statics/images/gnb_playlist_w.png"
                            alt="playlist"
                          />
                        </div>
                        <div className={cx('mobile_menu_text_box')}>
                          <h4 className={cx('mobile_menu_text')}>
                            콘텐츠 플레이
                          </h4>
                        </div>
                      </div>
                      <div>
                        <img
                          src="/statics/images/ic_profile_list_more.png"
                          alt="ic_profile_list_more"
                        />
                      </div>
                    </button>
                  )}
                  {!isTempBlock && (
                    <button
                      className={cx('mobile_menu_item_inner')}
                      onClick={() => handleAdvertisement(navigator)}
                    >
                      <div className={cx('mobile_menu_item_box')}>
                        <div className={cx('mobile_menu_item_img_box')}>
                          <img
                            className={cx('mobile_menu_list_img')}
                            src="/statics/images/gnb-ad-w.png"
                            alt="solution"
                          />
                        </div>
                        <div className={cx('mobile_menu_text_box')}>
                          <h4 className={cx('mobile_menu_text')}>광고 문의</h4>
                        </div>
                      </div>
                      <div>
                        <img
                          src="/statics/images/ic_profile_list_more.png"
                          alt="ic_profile_list_more"
                        />
                      </div>
                    </button>
                  )}
                  {isLogIn && (
                    <div
                      onClick={() =>
                        setDropdown((prev) => ({
                          ...prev,
                          mypage: !prev.mypage,
                        }))
                      }
                      className={cx('mobile_menu_item_inner')}
                    >
                      <div className={cx('mobile_menu_item_box')}>
                        <div className={cx('mobile_menu_item_img_box')}>
                          <img
                            className={cx('mobile_menu_list_img')}
                            src="/statics/images/gnb_my.png"
                            alt="info"
                          />
                        </div>
                        <div className={cx('mobile_menu_text_box')}>
                          <h4 className={cx('mobile_menu_text')}>내 계정</h4>
                        </div>
                      </div>
                      <div
                        className={cx(
                          dropdown.mypage
                            ? 'active_mobile_menu_hide_box_img'
                            : 'mobile_menu_hide_box_img',
                        )}
                      >
                        <img
                          src="/statics/images/ic_side_list_open.png"
                          alt="ic_side_list_open"
                        />
                      </div>
                    </div>
                  )}
                  <div>
                    {dropdown.mypage && (
                      <div className={cx('mobile_menu_list_hover_box')}>
                        <ul className={cx('mobile_menu_item_list_box')}>
                          <li className={cx('mobile_menu_item')}>
                            {/* <NavLink to="/play-list/media" className={({ isActive }) => cx({ active: isActive })}>나의 플레이리스트</NavLink> */}
                            <button onClick={handleMyPlay}>
                              나의 플레이리스트
                            </button>
                          </li>
                          <li className={cx('mobile_menu_item')}>
                            <NavLink
                              to="/mypage"
                              className={({ isActive }) =>
                                cx({ active: isActive })
                              }
                            >
                              마이페이지
                            </NavLink>
                          </li>
                          <li className={cx('mobile_menu_item')}>
                            <NavLink
                              to="/mypage/faq"
                              className={({ isActive }) =>
                                cx({ active: isActive })
                              }
                            >
                              고객센터
                            </NavLink>
                          </li>
                          <li className={cx('mobile_menu_item')}>
                            <NavLink
                              to="/mypage/as"
                              className={({ isActive }) =>
                                cx({ active: isActive })
                              }
                            >
                              {AS_MENU}
                            </NavLink>
                          </li>
                          {/* {!isTempBlock && ( */}
                          <li className={cx('mobile_menu_item')}>
                            <NavLink
                              to="/mypage/payment-history"
                              className={({ isActive }) =>
                                cx({ active: isActive })
                              }
                            >
                              결제 내역
                              </NavLink>
                          </li>
                          {/* )} */}
                          {/* <li className={cx('mobile_menu_item')}>
                            <NavLink
                              to="/mypage/advertisement"
                              className={({ isActive }) =>
                                cx({ active: isActive })
                              }
                            >
                              내 광고
                            </NavLink>
                          </li> */}
                          {/* {!isTempBlock && ( */}
                          <li className={cx('mobile_menu_item')}>
                            <NavLink
                              to="/mypage/basket/artist"
                              className={({ isActive }) =>
                                cx({ active: isActive })
                              }
                            >
                              장바구니
                              </NavLink>
                            <span className={cx('mobile_basket_number')}>
                              {wishArtistPlayList?.length > 99
                                ? `99+`
                                : wishArtistPlayList?.length > 10
                                  ? wishArtistPlayList.length
                                  : `0${wishArtistPlayList.length}`}
                            </span>
                          </li>
                          {/* )} */}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={cx('mobile_menu_footer_inner')}>
                  <div className={cx('mobile_menu_footer_icon_box')}>
                    <div className={cx('mobile_menu_footer_menu_box')}>
                      <img
                        src="/statics/images/footer_sns_youtube.png"
                        alt="footer_sns_youtube"
                      />
                    </div>
                    <div className={cx('mobile_menu_footer_menu_box')}>
                      <img
                        src="/statics/images/footer_sns_fb.png"
                        alt="footer_sns_fb"
                      />
                    </div>
                    <div className={cx('mobile_menu_footer_menu_box')}>
                      <img
                        src="/statics/images/footer_sns_insta.png"
                        alt="footer_sns_insta"
                      />
                    </div>
                  </div>
                  <div className={cx('mobile_menu_footer_box')}>
                    <div className={cx('mobile_menu_footer_menu_box')}>
                      <p className={cx('mobile_menu_footer_text')}>
                        개인정보처리방침
                      </p>
                    </div>
                    <div className={cx('mobile_menu_footer_menu_box')}>
                      <p className={cx('mobile_menu_footer_text')}>이용약관</p>
                    </div>
                    <div className={cx('mobile_menu_footer_menu_box')}>
                      <p className={cx('mobile_menu_footer_text')}>
                        서비스소개
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
          <div className={cx('sub_container')}>
            <div className={cx('white_inner')}>
              <div className={cx('inner_box')}>
                <div className={cx('left_inner')}>
                  <Link to="/" className={cx('logo_box')}>
                    <img src="/statics/images/gnb_logo.png" alt="main_logo" />
                  </Link>
                  <div className={cx('menu_list_box')}>
                    <button
                      className={cx('menu_item_box')}
                      onClick={() =>
                        // handleSolution(isLogIn, estimate, contract, navigator)
                        moveSolution()
                      }
                    >
                      <div className={cx('menu_item_img_box')}>
                        <img
                          className={cx('white_menu_list_img')}
                          src="/statics/images/fixed_gnb_solution.png"
                          alt="solution"
                        />
                        <img
                          className={cx('white_menu_list_hover_img')}
                          src="/statics/images/gnb_solution_w_on.png"
                          alt="solution"
                        />
                      </div>
                      <div className={cx('menu_text_box')}>
                        <h4 className={cx('white_menu_text')}>솔루션 구매</h4>
                      </div>
                    </button>
                    <div className={cx('menu_item_box')}>
                      <div className={cx('menu_item_img_box')}>
                        <img
                          className={cx('white_menu_list_img')}
                          src="/statics/images/fixed_gnb_info_w.png"
                          alt="info"
                        />
                        <img
                          className={cx('white_menu_list_hover_img')}
                          src="/statics/images/gnb_info_w_on.png"
                          alt="info"
                        />
                      </div>
                      <div className={cx('menu_text_box')}>
                        <h4 className={cx('white_menu_text')}>
                          소개
                        <div className={cx('menu_list_hover_box')}>
                            <ul className={cx('menu_item_list_box')}>
                              <li className={cx('menu_item')}>
                                <NavLink
                                  to="/intro/service"
                                  className={({ isActive }) =>
                                    cx({ active: isActive })
                                  }
                                >
                                  미디어 인테리어
                                <br />
                                  서비스 소개
                              </NavLink>
                              </li>
                              {!isTempBlock && (
                                <li className={cx('menu_item')}>
                                  <NavLink
                                    to="/intro/advertisement"
                                    className={({ isActive }) =>
                                      cx({ active: isActive })
                                    }
                                  >
                                    옥외 광고
                                  <br />
                                    서비스 소개
                                </NavLink>
                                </li>
                              )}
                              <li className={cx('menu_item')}>
                                <NavLink
                                  to="/intro/example"
                                  className={({ isActive }) =>
                                    cx({ active: isActive })
                                  }
                                >
                                  서비스 이용 사례
                              </NavLink>
                              </li>
                              <li className={cx('menu_item')}>
                                <NavLink
                                  to="/intro/company"
                                  className={({ isActive }) =>
                                    cx({ active: isActive })
                                  }
                                >
                                  회사 소개
                              </NavLink>
                              </li>
                            </ul>
                          </div>
                        </h4>
                      </div>
                    </div>
                    <button
                      className={cx('menu_item_box')}
                      onClick={() => handlePartner()}
                    >
                      <div className={cx('menu_item_img_box')}>
                        <img
                          className={cx(
                            'white_menu_list_img',
                            'menu_list_partner_img',
                          )}
                          src="/statics/images/partner_off.png"
                          alt="solution"
                        />
                        <img
                          className={cx(
                            'white_menu_list_hover_img',
                            'menu_list_partner_img',
                          )}
                          src="/statics/images/partner_w.png"
                          alt="solution"
                        />
                      </div>
                      <div className={cx('menu_text_box')}>
                        <h4 className={cx('white_menu_text')}>파트너사 모집</h4>
                      </div>
                    </button>
                    {isShowedContent && (
                      <button
                        className={cx('menu_item_box')}
                        onClick={() =>
                          handlePlay(
                            isLogIn,
                            estimate,
                            contract,
                            navigator,
                            isUnlimitedPlaylist,
                          )
                        }
                      >
                        <div className={cx('menu_item_img_box')}>
                          <img
                            className={cx('white_menu_list_img')}
                            src="/statics/images/fixed_gnb_playlist.png"
                            alt="playlist"
                          />
                          <img
                            className={cx('white_menu_list_hover_img')}
                            src="/statics/images/gnb_playlist_w_on.png"
                            alt="playlist"
                          />
                        </div>
                        <div className={cx('menu_text_box')}>
                          <h4 className={cx('white_menu_text')}>콘텐츠 플레이</h4>
                        </div>
                      </button>
                    )}
                    {!isTempBlock && (
                      <button
                        className={cx('menu_item_box')}
                        onClick={() => handleAdvertisement(navigator)}
                      >
                        <div className={cx('menu_item_img_box')}>
                          <img
                            className={cx('menu_list_img')}
                            src="/statics/images/gnb-ad-w.png"
                            alt="광고문의"
                          />
                          <img
                            className={cx('white_menu_list_hover_img')}
                            src="/statics/images/gnb-ad-w-on.png"
                            alt="광과문의"
                          />
                        </div>
                        <div className={cx('menu_text_box')}>
                          <h4 className={cx('white_menu_text')}>광고 문의</h4>
                        </div>
                      </button>
                    )}
                  </div>
                </div>
                <div className={cx('right_inner')}>
                  {isLogIn ? (
                    <div className={cx('login_inner')}>
                      <div className={cx('login_img_box')}>
                        <img
                          className={cx('img_profile')}
                          src={
                            profileImageUrl
                              ? profileImageUrl
                              : '/statics/images/login_img.png'
                          }
                          alt="login_img"
                        />
                      </div>
                      <div className={cx('login_nick_name_box')}>
                        <p className={cx('login_nick_name')}>{name}</p>
                        <div>
                          <img
                            src="/statics/images/gnb_profile_more_off.png"
                            alt="gnb_profile_more_off"
                          />
                        </div>
                        <div className={cx('hide_login_inner')}>
                          <div className={cx('hide_login_list_item_box')}>
                            <button
                              className={cx('hide_login_list_item')}
                              onClick={handleMyPlay}
                            >
                              나의 플레이리스트
                          </button>
                          </div>
                          <div className={cx('hide_login_list_item_box')}>
                            <Link
                              className={cx('hide_login_list_item')}
                              to="/mypage"
                            >
                              마이페이지
                          </Link>
                          </div>
                          <div className={cx('hide_login_list_item_box')}>
                            <Link
                              className={cx('hide_login_list_item')}
                              to="/mypage/faq"
                            >
                              고객센터
                          </Link>
                          </div>
                          <div className={cx('hide_login_list_item_box')}>
                            <Link
                              className={cx('hide_login_list_item')}
                              to="/mypage/as"
                            >
                              {AS_MENU}
                            </Link>
                          </div>
                          {/* {!isTempBlock && ( */}
                          <div className={cx('hide_login_list_item_box')}>
                            <Link
                              className={cx('hide_login_list_item')}
                              to="/mypage/payment-history"
                            >
                              결제 내역
                            </Link>
                          </div>
                          {/* )} */}
                          {/* <div className={cx('hide_login_list_item_box')}>
                          <Link
                            className={cx('hide_login_list_item')}
                            to="/mypage/advertisement"
                          >
                            내 광고
                          </Link>
                        </div> */}
                          {/* {!isTempBlock && ( */}
                          <div className={cx('hide_login_list_item_box')}>
                            <Link
                              className={cx('hide_login_list_item')}
                              to="/mypage/basket/artist"
                            >
                              장바구니
                            </Link>
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                      <div className={cx('logout_box')}>
                        <img
                          className={cx('logout_img')}
                          onClick={handleLogout}
                          src="/statics/images/logout.png"
                          alt="logout"
                        />
                      </div>
                      {/* {!isTempBlock && ( */}
                      <div className={cx('basket_box')}>
                        <Link to="/mypage/basket/artist">
                          <div className={cx('basket_number_box')}>
                            <span className={cx('basket_number')}>
                              {wishArtistPlayList?.length > 99
                                ? `99+`
                                : wishArtistPlayList?.length > 10
                                  ? wishArtistPlayList.length
                                  : `0${wishArtistPlayList.length}`}
                            </span>
                          </div>
                          <img
                            className={cx('basket_img')}
                            onClick={handleBasket}
                            src="/statics/images/gnb-cart.png"
                            alt="장바구니"
                          />
                        </Link>
                      </div>
                      {/* )} */}
                    </div>
                  ) : (
                      <div className={cx('button_inner')}>
                        <div className={cx('button_box')}>
                          <Link className={cx('white_signup_button')} to="/signup">
                            회원가입
                      </Link>
                        </div>
                        <div className={cx('button_box')}>
                          <Link className={cx('white_signin_button')} to="/login">
                            로그인
                      </Link>
                        </div>
                      </div>
                    )}
                  <div
                    onClick={() => setOpen(!open)}
                    className={cx('mobile_hamburger_bar')}
                  >
                    <img src="/statics/images/nav_menu.png" alt="nav_menu" />
                  </div>
                </div>
              </div>
            </div>
            {/* 모바일 */}
            <div
              className={cx(
                open ? 'active_mobile_menu_inner' : 'mobile_menu_inner',
              )}
            >
              <div className={cx('mobile_header_inner')}>
                <div className={cx('mobile_header_box')}>
                  <Link to="/" className={cx('logo_box')}>
                    <img src="/statics/images/gnb_logo_w.png" alt="gnb_logo_w" />
                  </Link>
                  <img
                    onClick={() => setOpen(!open)}
                    className={cx('mobile_menu_inner_close')}
                    src="/statics/images/nav_close_w.png"
                    alt="nav_close_w"
                  />
                </div>
                <div className={cx('mobile_login_inner')}>
                  <div className={cx('mobile_login_profile_box')}>
                    <div className={cx('login_img_box')}>
                      <img
                        className={cx('img_profile')}
                        src={
                          profileImageUrl
                            ? profileImageUrl
                            : '/statics/images/login_img.png'
                        }
                        alt="login_img"
                      />
                    </div>
                    <div className={cx('login_nick_name_box')}>
                      {isLogIn ? (
                        <p className={cx('login_nick_name')}>{name}</p>
                      ) : (
                          <Link to="/login" className={cx('login_nick_name')}>
                            로그인을 해주세요
                      </Link>
                        )}
                      <div>
                        <img
                          src="/statics/images/gnb_profile_more_close.png"
                          alt="gnb_profile_more_close"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {isLogIn ? (
                      <div className={cx('logout_box')}>
                        <img
                          className={cx('logout_img')}
                          onClick={handleLogout}
                          src="/statics/images/mobile_logout.png"
                          alt="mobile_logout"
                        />
                      </div>
                    ) : (
                        <div className={cx('mobile_button_box')}>
                          <Link className={cx('mobile_signup_button')} to="/signup">
                            회원가입
                      </Link>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className={cx('mobile_menu_list_box')}>
                <button
                  className={cx('mobile_menu_item_inner')}
                  onClick={() =>
                    // handleSolution(isLogIn, estimate, contract, navigator)
                    moveSolution()
                  }
                >
                  <div className={cx('mobile_menu_item_box')}>
                    <div className={cx('mobile_menu_item_img_box')}>
                      <img
                        className={cx('mobile_menu_list_img')}
                        src="/statics/images/gnb_solution_w.png"
                        alt="solution"
                      />
                    </div>
                    <div className={cx('mobile_menu_text_box')}>
                      <h4 className={cx('mobile_menu_text')}>솔루션 구매</h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src="/statics/images/ic_profile_list_more.png"
                      alt="ic_profile_list_more"
                    />
                  </div>
                </button>
                <div
                  onClick={() =>
                    setDropdown((prev) => ({ ...prev, intro: !prev.intro }))
                  }
                  className={cx('mobile_menu_item_inner')}
                >
                  <div className={cx('mobile_menu_item_box')}>
                    <div className={cx('mobile_menu_item_img_box')}>
                      <img
                        className={cx('mobile_menu_list_img')}
                        src="/statics/images/gnb_info_w.png"
                        alt="info"
                      />
                    </div>
                    <div className={cx('mobile_menu_text_box')}>
                      <h4 className={cx('mobile_menu_text')}>소개</h4>
                    </div>
                  </div>
                  <div
                    className={cx(
                      dropdown.intro
                        ? 'active_mobile_menu_hide_box_img'
                        : 'mobile_menu_hide_box_img',
                    )}
                  >
                    <img
                      src="/statics/images/ic_side_list_open.png"
                      alt="ic_side_list_open"
                    />
                  </div>
                </div>
                <div>
                  {dropdown.intro && (
                    <div className={cx('mobile_menu_list_hover_box')}>
                      <ul className={cx('mobile_menu_item_list_box')}>
                        <li className={cx('mobile_menu_item')}>
                          <NavLink
                            to="/intro/service"
                            className={({ isActive }) => cx({ active: isActive })}
                          >
                            미디어 인테리어 서비스 소개
                        </NavLink>
                        </li>
                        {!isTempBlock && (
                          <li className={cx('mobile_menu_item')}>
                            <NavLink
                              to="/intro/advertisement"
                              className={({ isActive }) =>
                                cx({ active: isActive })
                              }
                            >
                              옥외 광고 서비스 소개
                          </NavLink>
                          </li>
                        )}
                        <li className={cx('mobile_menu_item')}>
                          <NavLink
                            to="/intro/example"
                            className={({ isActive }) => cx({ active: isActive })}
                          >
                            서비스 이용 사례
                        </NavLink>
                        </li>
                        <li className={cx('mobile_menu_item')}>
                          <NavLink
                            to="/intro/company"
                            className={({ isActive }) => cx({ active: isActive })}
                          >
                            회사 소개
                        </NavLink>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <button
                  className={cx('mobile_menu_item_inner')}
                  onClick={() => handlePartner()}
                >
                  <div className={cx('mobile_menu_item_box')}>
                    <div className={cx('mobile_menu_item_img_box')}>
                      <img
                        className={cx('mobile_menu_list_img')}
                        src="/statics/images/partner_off.png"
                        alt="solution"
                      />
                    </div>
                    <div className={cx('mobile_menu_text_box')}>
                      <h4 className={cx('mobile_menu_text')}>파트너사 모집</h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src="/statics/images/ic_profile_list_more.png"
                      alt="ic_profile_list_more"
                    />
                  </div>
                </button>
                {isShowedContent && (
                  <button
                    className={cx('mobile_menu_item_inner')}
                    onClick={() =>
                      handlePlay(
                        isLogIn,
                        estimate,
                        contract,
                        navigator,
                        isUnlimitedPlaylist,
                      )
                    }
                  >
                    <div className={cx('mobile_menu_item_box')}>
                      <div className={cx('mobile_menu_item_img_box')}>
                        <img
                          className={cx('mobile_menu_list_img')}
                          src="/statics/images/gnb_playlist_w.png"
                          alt="playlist"
                        />
                      </div>
                      <div className={cx('mobile_menu_text_box')}>
                        <h4 className={cx('mobile_menu_text')}>콘텐츠 플레이</h4>
                      </div>
                    </div>
                    <div>
                      <img
                        src="/statics/images/ic_profile_list_more.png"
                        alt="ic_profile_list_more"
                      />
                    </div>
                  </button>
                )}
                {!isTempBlock && (
                  <button
                    className={cx('mobile_menu_item_inner')}
                    onClick={() => handleAdvertisement(navigator)}
                  >
                    <div className={cx('mobile_menu_item_box')}>
                      <div className={cx('mobile_menu_item_img_box')}>
                        <img
                          className={cx('mobile_menu_list_img')}
                          src="/statics/images/gnb-ad-w.png"
                          alt="solution"
                        />
                      </div>
                      <div className={cx('mobile_menu_text_box')}>
                        <h4 className={cx('mobile_menu_text')}>광고 문의</h4>
                      </div>
                    </div>
                    <div>
                      <img
                        src="/statics/images/ic_profile_list_more.png"
                        alt="ic_profile_list_more"
                      />
                    </div>
                  </button>
                )}
                {isLogIn && (
                  <div
                    onClick={() =>
                      setDropdown((prev) => ({ ...prev, mypage: !prev.mypage }))
                    }
                    className={cx('mobile_menu_item_inner')}
                  >
                    <div className={cx('mobile_menu_item_box')}>
                      <div className={cx('mobile_menu_item_img_box')}>
                        <img
                          className={cx('mobile_menu_list_img')}
                          src="/statics/images/gnb_my.png"
                          alt="info"
                        />
                      </div>
                      <div className={cx('mobile_menu_text_box')}>
                        <h4 className={cx('mobile_menu_text')}>내 계정</h4>
                      </div>
                    </div>
                    <div
                      className={cx(
                        dropdown.mypage
                          ? 'active_mobile_menu_hide_box_img'
                          : 'mobile_menu_hide_box_img',
                      )}
                    >
                      <img
                        src="/statics/images/ic_side_list_open.png"
                        alt="ic_side_list_open"
                      />
                    </div>
                  </div>
                )}
                <div>
                  {dropdown.mypage && (
                    <div className={cx('mobile_menu_list_hover_box')}>
                      <ul className={cx('mobile_menu_item_list_box')}>
                        <li className={cx('mobile_menu_item')}>
                          {/* <NavLink to="/play-list" className={({ isActive }) => cx({ active: isActive })}>나의 플레이리스트</NavLink> */}
                          <button onClick={handleMyPlay}>
                            나의 플레이리스트
                        </button>
                        </li>
                        <li className={cx('mobile_menu_item')}>
                          <NavLink
                            to="/mypage"
                            className={({ isActive }) => cx({ active: isActive })}
                          >
                            마이페이지
                        </NavLink>
                        </li>
                        <li className={cx('mobile_menu_item')}>
                          <NavLink
                            to="/mypage/faq"
                            className={({ isActive }) => cx({ active: isActive })}
                          >
                            고객센터
                        </NavLink>
                        </li>
                        <li className={cx('mobile_menu_item')}>
                          <NavLink
                            to="/mypage/as"
                            className={({ isActive }) => cx({ active: isActive })}
                          >
                            {AS_MENU}
                          </NavLink>
                        </li>
                        {/* {!isTempBlock && ( */}
                        <li className={cx('mobile_menu_item')}>
                          <NavLink
                            to="/mypage/payment-history"
                            className={({ isActive }) =>
                              cx({ active: isActive })
                            }
                          >
                            결제 내역
                          </NavLink>
                        </li>
                        {/* )} */}
                        {/* <li className={cx('mobile_menu_item')}>
                        <NavLink
                          to="/mypage/advertisement"
                          className={({ isActive }) => cx({ active: isActive })}
                        >
                          내 광고
                        </NavLink>
                      </li> */}
                        {/* {!isTempBlock && ( */}
                        <li className={cx('mobile_menu_item')}>
                          <NavLink
                            to="/mypage/basket/artist"
                            className={({ isActive }) =>
                              cx({ active: isActive })
                            }
                          >
                            장바구니
                          </NavLink>
                          <span className={cx('mobile_basket_number')}>
                            {wishArtistPlayList?.length > 99
                              ? `99+`
                              : wishArtistPlayList?.length > 10
                                ? wishArtistPlayList.length
                                : `0${wishArtistPlayList.length}`}
                          </span>
                        </li>
                        {/* )} */}
                      </ul>
                    </div>
                  )}
                </div>
                <div className={cx('mobile_menu_footer_inner')}>
                  <div className={cx('mobile_menu_footer_icon_box')}>
                    <div className={cx('mobile_menu_footer_menu_box')}>
                      <img
                        src="/statics/images/footer_sns_youtube.png"
                        alt="footer_sns_youtube"
                      />
                    </div>
                    <div className={cx('mobile_menu_footer_menu_box')}>
                      <img
                        src="/statics/images/footer_sns_fb.png"
                        alt="footer_sns_fb"
                      />
                    </div>
                    <div className={cx('mobile_menu_footer_menu_box')}>
                      <img
                        src="/statics/images/footer_sns_insta.png"
                        alt="footer_sns_insta"
                      />
                    </div>
                  </div>
                  <div className={cx('mobile_menu_footer_box')}>
                    <div className={cx('mobile_menu_footer_menu_box')}>
                      <p className={cx('mobile_menu_footer_text')}>
                        개인정보처리방침
                    </p>
                    </div>
                    <div className={cx('mobile_menu_footer_menu_box')}>
                      <p className={cx('mobile_menu_footer_text')}>이용약관</p>
                    </div>
                    <div className={cx('mobile_menu_footer_menu_box')}>
                      <p className={cx('mobile_menu_footer_text')}>서비스소개</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
