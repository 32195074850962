import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from 'apis';
import useAuth from 'hooks/useAuth';
import * as Sentry from '@sentry/react';
import LoginWrapper from 'components/LoginWrapper/LoginWrapper';

export default function LoginContainer() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: '',
    password: '',
  });
  const [isError, setIsError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);

  const { handleResetAuthData } = useAuth();

  const handleChange = useCallback(
    (e) => {
      setData((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
        };
      });
      setIsError(false);
      setIsEmailError(false);
    },
    [setData, setIsError],
  );

  const handleLogin = useCallback(async () => {
    try {
      if (!data.email || !data.password) return;
      const res = await api.login(data.email, data.password);
      if (res.status === 201) {
        localStorage.setItem('token', res.data.access_token);
        window.scrollTo(0, 0);
        localStorage.removeItem('persist:root');
        sessionStorage.removeItem('persist:root');
        navigate('/');
      } else {
        setIsError(true);
      }
    } catch (err) {
      if (err.response.data.message === 'EMAIL_NOT_VERIFIED') {
        setIsEmailError(true);
      } else {
        setIsError(true);

        const scope = new Sentry.Scope();
        scope.setTag('login', 'loginError');

        if (err?.config) {
          const { method, url, params, data, headers } = err.config; // axios의 error객체

          scope.setContext('login API Request Detail', {
            method,
            url,
            params,
            data,
            headers,
          });
        }

        if (err?.response) {
          const { data, status } = err.response;

          scope.setContext('login API Response Detail', {
            status,
            data,
          });
        }

        scope.setContext('loginContext', {
          err: err?.response,
        });

        Sentry.captureException(err, () => scope);
      }
    }
  }, [data.email, data.password, navigate]);

  const handleResend = async () => {
    try {
      const res = await api.resendEmail(data.email);
      if (res?.data?.isSuccess) {
        alert(
          `${data.email}로 다시 인증메일을 발송해드렸습니다.\n확인 부탁드립니다 : )`,
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  useEffect(() => {
    (async () => {
      await localStorage.clear();
      await sessionStorage.clear();
      handleResetAuthData();
    })();
  }, []);

  return (
    <LoginWrapper
      email={data.email}
      password={data.password}
      isError={isError}
      isEmailError={isEmailError}
      onChange={handleChange}
      onClick={handleLogin}
      handleResend={handleResend}
      handleKeypress={handleKeypress}
    />
  );
}
