import React from 'react';
import { Header } from 'components/views';
import SolutionContractComplete from 'components/views/SolutionContractComplete';

export default function SolutionContractCompleteWrapper({
  onClick,
}) {
  return (
    <>
      <Header />
      <SolutionContractComplete
        onClick={onClick}
      />
    </>
  )
}
