import React, { useState } from 'react';
import Pagination from 'components/common/Pagination';
import classNames from 'classnames/bind';
import styles from './MypagFaq.module.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function MypagFaq({
    faqList,
    categoryList,
    currentCategory,
    search,
    currentPage,
    lastPage,
    perPage,
    onChange,
    onSearch,
    onKeyPress,
    handlePage,
}) {
    const [open, setOpen] = useState({});

    const rendered = () => {
        if (faqList.length === 0) return;
        return (
            faqList.map((el, i) => {
                return (
                    <div onClick={() => setOpen(prev => ({ ...prev, [i]: !prev[i] }))} className={cx(!!open[i] ? 'acive_data_inner' : 'data_inner')} key={i}>
                        <div className={cx('list_cross_box')}>
                            <span className={cx('list_cross_x')}></span>
                            <span className={cx('list_cross_y')}></span>
                        </div>
                        <div className={cx('data_title_box')}>
                            <p className={cx('data_title')}>
                                {el.title}
                            </p>
                        </div>
                        <hr className={cx('hr')} />
                        <div className={cx('data_text_box')}>
                            <p className={cx('data_text')}>
                                {el.content}
                            </p>
                        </div>
                    </div>
                )
            })
        )
    }

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <div className={cx('title_box')}>
                            <h4 className={cx('title')}>
                                고객센터
                            </h4>
                        </div>
                        <div className={cx('tab_inner')}>
                            <hr className={cx('tab_hr')} />
                            <Link to='/mypage/faq' className={cx('tab_box')}>
                                <h5 className={cx('tab')}>
                                    자주 묻는 질문
                                </h5>
                            </Link>
                            <Link to='/mypage/inquiry' className={cx('tab_box')}>
                                <h5 className={cx('sub_tab')}>
                                    제휴 문의
                                </h5>
                            </Link>
                        </div>
                        <div className={cx('input_inner')}>

                            {/* 데이터 없을때 */}

                            {/* <div className={cx('empty_box')}>
                                <div className={cx('empty_text_box')}>
                                    <p className={cx('empty_text')}>
                                        아직 접수된 내용이 없습니다.
                                    </p>
                                    <p className={cx('empty_text')}>
                                        제휴 문의 시, 하단 버튼을 눌러 내용을 접수해주세요.
                                    </p>
                                </div>
                                <div className={cx('button_box')}>
                                    <Link to='/mypage/inquiry'><button className={cx('button')}>문의 하기</button></Link>
                                </div>
                            </div> */}

                            {/* 데이터 있을때 */}
                            <select
                                className={cx('select_box')}
                                style={{ backgroundImage: 'url(/statics/images/ic_expand_more.png)' }}
                                name="currentCategory"
                                onChange={onChange}
                                value={currentCategory}
                            >
                                <option value=''>선택해주세요</option>
                                {
                                    categoryList.map((el) => {
                                        return (
                                            <option key={el} value={el} selected={currentCategory === el}>{el}</option>
                                        );
                                    })
                                }
                            </select>
                            <div className={cx('search_input_box')}>
                                <input className={cx('search_input')} placeholder="도움이 필요한 내용을 검색해보세요!" type="text" name="search" value={search} onChange={onChange} onKeyPress={onKeyPress} />
                                <button className={cx('search_input_img')} onClick={() => onSearch(1)}>
                                    <img src="/statics/images/ic_input_search.png" alt="ic_input_search" />
                                </button>
                            </div>
                        </div>
                        <div className={cx('list_inner')}>
                            {/* 데이터 있을때 */}
                            {rendered()}
                        </div>
                    </div>
                    {/* 데이터 있을때 */}
                    <Pagination
                        currentPage={currentPage}
                        lastPage={lastPage}
                        perPage={perPage}
                        handlePage={handlePage}
                    />
                </div>
            </div>
        </div>
    )
}
