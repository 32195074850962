import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionInstallmentExaminationCompleted from 'components/views/SolutionInstallmentExaminationCompleted/SolutionInstallmentExaminationCompleted';

export default function SolutionInstallmentExaminationCompletedWrapper() {
    return (
        <>
            <Header />   
            <SolutionInstallmentExaminationCompleted />
        </>
    )
}
