import React, {
  useCallback,
} from 'react';
import {
  useSelector,
  useDispatch,
} from "react-redux";
import { changeData, resetData } from 'store/modules/estimate';

export default function useEstimate() {
  const estimate = useSelector(state => state.estimate);
  const {
    estimateId,
    ledWidth,
    ledHeight,
    beamSize,
    address,
    detailAddress,
    extraInfo,
    solutionType,
    status,
    hardware,
  } = estimate;
  const dispatch = useDispatch();

  const handleChangeData = useCallback((payload) => dispatch(changeData(payload)), [dispatch]);
  const handleResetData = useCallback(() => dispatch(resetData()), [dispatch]);

  return {
    estimate,
    estimateId,
    ledWidth,
    ledHeight,
    beamSize,
    address,
    detailAddress,
    extraInfo,
    solutionType,
    status,
    hardware,
    handleResetData,
    handleChangeData,
  }
};