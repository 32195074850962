import React from 'react';
import classNames from 'classnames/bind';
import styles from './IntroServiceMain.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function IntroServiceMain() {
    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div style={{ backgroundImage: "url(/statics/images/intro_bg.png)" }} className={cx('container_bg')}>
                    <div className={cx('inner')}>
                        <div className={cx('inner_box')}>
                            <div className={cx('left_box')}>
                                <div {...useScrollFadeIn('up', 1, 0.1)} className={cx('sub_title_box')}>
                                    <h3 className={cx('sub_title')}>SERVICE</h3>
                                </div>
                                <div {...useScrollFadeIn('up', 1, 0.2)} className={cx('title_box')}>
                                    <h4 className={cx('title')}>클릭 한 번으로 바뀌는 미디어 인테리어</h4>
                                </div>
                                <div {...useScrollFadeIn('up', 1, 0.3)} className={cx('text_box')}>
                                    <p className={cx('text')}>
                                        컨셉을 소비하는 시대. 기존의 인테리어 방식은 빠르게 변하는 컨셉과 트렌드를 따라잡기 어렵습니다.
                                        하지만 빔 몬스터 인테리어 솔루션과 함께라면, 자체 제공하는 1,000여 개의 다양한 콘텐츠를 통해
                                        클릭 한 번으로 인테리어 컨셉을 바꿀 수 있습니다.
                                    </p>
                                </div>
                            </div>
                            <div {...useScrollClipPath('left', 1, 0.3)} className={cx('right_box')}>
                                <img className={cx('right_box_img')} src="/statics/images/service_main_img.png" alt="service_main_img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
