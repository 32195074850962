import React, { useEffect, useCallback, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import * as api from 'apis';
import useAuth from 'hooks/useAuth';
import { handleCheckSolution } from 'libs/authService';
import SolutionInstallRequestWrapper from 'components/SolutionInstallRequestWrapper';
import useCompany from 'hooks/useCompany';

export default function SolutionInstallRequesContainer() {
  const navigator = useNavigate();
  const { isLogIn, estimate } = useAuth();

  const [data, setData] = useState({
    fileUrl: '',
  });

  const { phone } = useCompany();

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([
          api.getMyEstimate().catch(() => {}),
          api.getMyContract().catch(() => {}),
        ]);
        if (res[0]?.data?.status === 'ADMIN_REJECTED') {
          navigator('/solution/install/fail');
          return;
        }

        if (res[0]?.data?.status === 'ADMIN_CONFIRM') {
          navigator('/solution/install/success');
          return;
        }

        setData({
          fileUrl: res[0]?.data?.templateUrl,
        });

        handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [setData, isLogIn, navigator]);

  const handleClick = useCallback(() => {
    if (!data) return;
    window.open(
      data.fileUrl,
      `빔몬스터 견적서(${moment().format('YYYY-MM-DD HH:mm:ss')})`,
      'status=no, menubar=no, toolbar=no, resizable=no',
    );
  }, [data]);

  const handleDownload = useCallback(() => {
    try {
      fetch(data.fileUrl)
        .then((resp) => resp.arrayBuffer())
        .then((resp) => {
          // set the blog type to final pdf
          const file = new Blob([resp], { type: 'application/pdf' });

          // process to auto download it
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = `빔몬스터 견적서(${moment().format(
            'YYYY-MM-DD HH:mm:ss',
          )}).pdf`;
          link.click();
          link.remove();
        });
    } catch (err) {}
  }, [data]);

  return (
    <SolutionInstallRequestWrapper
      phone={phone}
      onClick={handleClick}
      onDownload={handleDownload}
    />
  );
}
