import React from 'react';
import { Footer, Header } from 'components/views';
import MypagePaymentHistoryDetail from 'components/views/MypagePaymentHistoryDetail';

export default function MypagePaymentHistoryDetailWrapper({
  data,
  handleClick,
  selectedData,
  isOpen,
  onClose,
}) {
  return (
    <>
      <Header />
      <MypagePaymentHistoryDetail
        data={data}
        handleClick={handleClick}
        selectedData={selectedData}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Footer />
    </>
  );
}
