import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useArtist from 'hooks/useArtist';
import * as api from 'apis';
import useAuth from 'hooks/useAuth';
import MypageBasketArtistWrapper from 'components/MypageBasketArtistWrapper';

export default function MypageBasketArtistContainer() {
  const [checkPlayList, setCheckPlayList] = useState([]);

  const {
    isUnlimitedPlaylist,
  } = useAuth();

  const navigator = useNavigate();

  const [myPlayList, setMyPlayList] = useState([]);
  const { wishArtistPlayList, handleUpdateArtist, handleChangeArtist, handleMyArtistPlayList } = useArtist();

  useEffect(() => {
    (async () => {
      try {
        const playList = await handleMyArtistPlayList();
        const playIds = [...playList.map((p) => p.id)];
        setMyPlayList(playIds);
      } catch (err) {

      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const wish = await handleUpdateArtist();
        const wishIds = [...wish?.map((el) => el.id)];

        const promises = wishIds.map(async id => {
          return await api.getContent(id);
        });
        const result = await Promise.all(promises);
        const realWish = result.map((item) => item?.data);

        handleChangeArtist({ wishArtistPlayList: realWish });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleWishList = async (e, content) => {
    e.stopPropagation();
    try {
      const wishIds = [...wishArtistPlayList?.map((w) => w.id)];
      let data = [];
      if (wishIds.indexOf(content.id) > -1) {
        data = [...wishArtistPlayList?.filter((item) => item.id !== content.id)];
      } else {
        data = [...wishArtistPlayList, content];
      }

      handleChangeArtist({ wishArtistPlayList: data });
    } catch (err) {
      console.log(err);
    }
  };

  const handleCheckList = (e, content) => {
    e.stopPropagation();

    setCheckPlayList((prev) => {
      if (prev.indexOf(content.id) > -1) {
        return [...prev.filter((id) => id !== content.id)];
      } else {
        return [...prev, content.id];
      }
    });
  };

  const handleCheckAll = () => {
    if (checkPlayList?.length !== wishArtistPlayList?.length) {
      setCheckPlayList([...wishArtistPlayList?.map((w) => w.id)]);
    } else {
      setCheckPlayList([]);
    }
  };

  const handleRemove = () => {
    if (!checkPlayList?.length) return;
    let data = [...wishArtistPlayList];
    checkPlayList.map((id, index) => {
      if (data.some((item) => item.id === id)) {
        data = [...data.filter((item) => item.id !== id)];
      }
    });
    setCheckPlayList([]);
    handleChangeArtist({ wishArtistPlayList: data });
  };

  const handleUpdatePlayList = async () => {
    try {
      const wishIds = [...wishArtistPlayList.map((w) => w.id)].sort();
      const playIds = [...myPlayList.map((p) => p.id)].sort();
      const isSame = wishIds.every((el, i) => {
        return el === (playIds[i] || null);
      });

      if (wishIds.length === 0 || isSame) return;
      const newIds = wishIds.filter((el, i) => {
        return el !== (playIds[i] || null);
      });
      if (newIds.length === 0) return;
      await api.postMyPlayList({
        content_ids: newIds,
      });
      alert('아티스트 콘텐츠 구매 완료 : )');
      handleChangeArtist({ wishArtistPlayList: [] });
      navigator('/play-list/media');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MypageBasketArtistWrapper
      wishPlayList={wishArtistPlayList}
      handleWishList={handleWishList}
      checkPlayList={checkPlayList}
      handleCheckList={handleCheckList}
      handleCheckAll={handleCheckAll}
      handleRemove={handleRemove}
      isUnlimitedPlaylist={isUnlimitedPlaylist}
      handleUpdatePlayList={handleUpdatePlayList}
    />
  );
}
