import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionRequestEstimateSolution from 'components/views/SolutionRequestEstimateSolution/SolutionRequestEstimateSolution';

export default function SolutionRequestEstimateSolutionWrapper({
    solutionType,
    onSubmit,
}) {
    return (
        <>
            <Header />
            <SolutionRequestEstimateSolution
                solutionType={solutionType}
                onSubmit={onSubmit}
            />
        </>
    )
}
