export const CHANGE_DATA = 'playList/CHANGE_DATA';

export const changePlayData = (payload) => ({ type: CHANGE_DATA, payload });

const initialState = {
  playType: 'Ordering',
  playList: [],
  shufflePlayList: [],
  playId: null,
  playStart: true, // 플레이중
  playLoop: false, // 반복
  playMute: false, // 음소거
  playVolume: 100,
  currentPlayTime: null,
  selectedPlay: null,
  playDuration: "300", // 5분 Default
  realPlayList: [],
};

function playList(state = initialState, action) {
  switch (action.type) {
    case CHANGE_DATA:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state;
  }
};

export default playList;