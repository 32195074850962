import React from 'react';
import { Header } from 'components/views';
import SolutionInstallSuccess from 'components/views/SolutionInstallSuccess';

export default function SolutionInstallSuccessWrapper({ phone }) {
  return (
    <>
      <Header />
      <SolutionInstallSuccess phone={phone} />
    </>
  );
}
