import React, { useRef, useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './FileReader.module.scss';

const cx = classNames.bind(styles);
const imgs = ['gif', 'jpg', 'jpeg', 'png', 'bmp', 'ico', 'apng'];

export default function FileReader({ url, style }) {
  const handleDownload = useCallback(() => {
    if (!url) return;
    const list = url?.split('.');
    try {
      fetch(`${url}?timestamp=${new Date().getTime()}`)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.click();
          link.remove();
        })
        .catch(console.error);
    } catch (err) { }
  }, [url]);

  const format = useMemo(() => {
    if (!url) return;
    const list = url?.split('.');
    return list[list.length - 1];
  }, [url]);

  const isImage = false;

  // useMemo(() => {
  //   if (!format) return false;
  //   return imgs.some((type) => format.indexOf(type) > -1);
  // }, [format]);

  return (
    <div>
      {!!url && (
        <div className={cx('file_upload_preview_box')} style={style} role="presentation"
        >
          {isImage ? (
            <a href={url} download>
              <img
                className={cx('file_upload_img')}
                src={url}
                alt="upload_image"
              />
            </a>
          ) : (
              <a href={url} download>
                <div
                  className={cx('file_upload_file')}
                  role="presentation"
                >
                  첨부파일
              </div>
              </a>
            )}
        </div>
      )}
    </div>
  );
}
