import React from 'react'
import classNames from 'classnames/bind';
import styles from './SignupEmailProcess.module.scss';

const cx = classNames.bind(styles);

export default function SignupEmailProcess({
    email,
    allAgree,
    termsAgree,
    privacyAgree,
    marketingAgree,
    isEmailSuccess,
    isEmailError,
    isCheckedEmail,
    handleEmail,
    handleChange,
    handleAgree,
    handleAllAgree,
    emailProcessDisabled,
    handleEmailProcess,
    handlePrivacy,
    handleTerms,
}) {
    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <div className={cx('email_box')}>
                            <div className={cx('step_box')}>
                                <p className={cx('step_text')}>
                                    <b className={cx('step_text_b')}>1 </b>/ 3 단계
                                </p>
                            </div>
                            <div className={cx('title_box')}>
                                <h4 className={cx('title')}>
                                    이메일을 입력하세요 :)
                                </h4>
                            </div>
                            <div className={cx('email_input_box')}>
                                <div className={cx('half_box')}>
                                    <input
                                        value={email}
                                        onChange={handleChange}
                                        className={cx('email_input')}
                                        name="email"
                                        type="email"
                                        placeholder="이메일 주소를 입력해주세요."
                                    />
                                    <div className={cx('input_button_box', { active: email, success: isCheckedEmail })}>
                                        <button className={cx('input_button')} type="button" disabled={isCheckedEmail || !email} onClick={handleEmail}>
                                            {isCheckedEmail ? '완료' : '확인'}
                                        </button>
                                    </div>
                                </div>
                                {
                                    isEmailSuccess &&
                                    <div className={cx('email_success_box')}>
                                        <img className={cx('email_success_img')} src="/statics/images/ic_input_info_check.png" alt="info_check" />
                                        <p className={cx('email_success_text')}>사용 가능한 이메일입니다.</p>
                                    </div>
                                }
                                {
                                    isEmailError.format &&
                                    <div className={cx('email_fail_box')}>
                                        <img className={cx('email_fail_img')} src="/statics/images/ic_input_info_error.png" alt="info_check" />
                                        <p className={cx('email_fail_text')}>형식에 맞게 입력해주세요.</p>
                                    </div>
                                }
                                {
                                    isEmailError.duplicated &&
                                    <div className={cx('email_fail_box')}>
                                        <img className={cx('email_fail_img')} src="/statics/images/ic_input_info_error.png" alt="info_check" />
                                        <p className={cx('email_fail_text')}>이미 사용 중인 이메일입니다.</p>
                                    </div>
                                }
                            </div>
                            <div className={cx('all_checkbox')}>
                                <input className={cx('checkbox', { checked: allAgree })} id="all" type="checkbox" name="allAgree" onClick={handleAllAgree} /><label className={cx('checklabel')} htmlFor="all"></label>
                                <label htmlFor="all" className={cx('agree_text')}>전체 약관에 동의합니다.</label>
                            </div>
                            <div className={cx('agree_checkbox_inner')}>
                                <div className={cx('agree_checkbox')}>
                                    <input className={cx('checkbox', { checked: termsAgree })} id="vim_check" type="checkbox" name="termsAgree" onClick={handleAgree} /><label className={cx('checklabel')} htmlFor="vim_check"></label>
                                    <label className={cx('agree_text')} htmlFor="vim_check">
                                        <b className={cx('agree_text_b')} onClick={handleTerms}>빔몬스터 이용약관</b>
                                        에 동의합니다.
                                    </label>
                                </div>
                                <div className={cx('agree_checkbox')}>
                                    <input className={cx('checkbox', { checked: privacyAgree })} id="infor_check" type="checkbox" name="privacyAgree" onClick={handleAgree} /><label className={cx('checklabel')} htmlFor="infor_check"></label>
                                    <label className={cx('agree_text')} htmlFor="infor_check"><b className={cx('agree_text_b')} onClick={handlePrivacy}>개인정보 수집 및 이용에 대한 안내</b>에 동의합니다.</label>
                                </div>
                                <div className={cx('agree_checkbox')}>
                                    <input className={cx('checkbox', { checked: marketingAgree })} id="event_check" type="checkbox" name="marketingAgree" onClick={handleAgree} /><label className={cx('checklabel')} htmlFor="event_check"></label>
                                    <label className={cx('agree_text')} htmlFor="event_check">이벤트 정보 수신에 동의합니다. (선택)</label>
                                </div>
                            </div>
                            <div className={cx('button_box')}>
                                <button className={cx('button', { disabled: emailProcessDisabled })} disabled={emailProcessDisabled} onClick={handleEmailProcess}>다음</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
