import React, {
    useState,
    useCallback,
    useEffect,
} from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import useEstimate from 'hooks/useEstimate';
import useAuth from 'hooks/useAuth';
import {
    handleCheckSolution,
} from 'libs/authService';
import * as api from 'apis';
import SolutionRequestEstimateLedWrapper from 'components/SolutionRequestEstimateLedWrapper/SolutionRequestEstimateLedWrapper';

export default function SolutionRequestEstimateLedContainer() {
    const navigator = useNavigate();
    const {
        isLogIn,
    } = useAuth();

    const {
        estimateId,
        ledWidth,
        ledHeight,
        handleChangeData,
    } = useEstimate();
    const [data, setData] = useState({
        width: '',
        height: '',
    });

    useEffect(() => {
        (async () => {
            try {
                const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })])
                handleChangeData({
                    estimateId: res[0]?.data?.id || null,
                    hardware: res[0]?.data?.hardware || null,
                    beamSize: res[0]?.data?.beamSize || '',
                    ledWidth: res[0]?.data?.ledWidth * 1000 || '',
                    ledHeight: res[0]?.data?.ledHeight * 1000 || '',
                });

                handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
            } catch (err) {
                console.log(err);
            }
        })()
    }, [handleChangeData, isLogIn, navigator]);

    const handleChage = useCallback((e) => {
        const value = Number(e.target.value.toString().replace(/,/gi, ''));
        if (isNaN(value)) return;

        if (e.target.name === 'width') {
            if (value > 6400) {
                return alert('최대 넓이는 6,400 mm 입니다 : )');
            }
        }

        if (e.target.name === 'height') {
            if (value > 3840) {
                return alert('최대 높이는 3,840 mm 입니다 : )');
            }
        }
        setData(prev => ({
            ...prev,
            [e.target.name]: e.target.value.toString().replace(/,/gi, '').replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        }));
    }, [setData]);

    const handleSubmit = useCallback(async () => {
        try {
            const width = Number(data.width.replace(/,/gi, ''));
            const height = Number(data.height.replace(/,/gi, ''));
            if (!width || !height) {
                alert('LED 패널 크기를 입력해주세요 : )');
                return;
            }

            if (width < 2240) {
                return alert('최소 넓이는 2,240 mm 입니다 : )');
            }

            if (height < 1280) {
                return alert('최소 높이는 1,280 mm 입니다 : )');
            }

            const size = Math.ceil((width / 1000) * (height / 1000) * 100) / 100;
            if (size > 24.576) {
                alert('LED 패널 크기는 W * H가 24.576 m2 보다 작게 입력해주세요 : )');
                return;
            }

            const submitData = {
                ledWidth: width / 1000,
                ledHeight: height / 1000,
            };
            await api.updateEstimate(estimateId, submitData);
            handleChangeData(submitData);
            navigator('/solution/request-estimate/place');
        } catch (err) {
            console.log(err);
        }
    }, [data, estimateId, navigator, handleChangeData]);

    // useEffect(() => {
    //     if (estimateId) return;
    //     alert('잘못된 접근입니다.');
    //     navigator('/');
    // }, [estimateId, navigator]);

    useEffect(() => {
        setData(prev => ({
            ...prev,
            width: ledWidth?.toString().replace(/,/gi, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
            height: ledHeight?.toString().replace(/,/gi, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
        }));
    }, [ledWidth, ledHeight, setData]);

    return (
        <SolutionRequestEstimateLedWrapper
            data={data}
            onChange={handleChage}
            onSubmit={handleSubmit}
        />
    )
}
