import React, { Fragment } from 'react';
import classNames from 'classnames/bind';
import { numberWithCommas } from 'libs/numbers';
import styles from './AdvertisementStoreList.module.scss';
import Pagination from 'components/common/Pagination';

const cx = classNames.bind(styles);

const WEEKS = {
  MONDAY: '월요일',
  TUESDAY: '화요일',
  WEDNESDAY: '수요일',
  THURSDAY: '목요일',
  FRIDAY: '금요일',
  SATURDAY: '토요일',
  SUNDAY: '일요일',
};

export default function AdvertisementStoreList({
  availableStores,
  currentStorePage,
  selectedStores,
  handlePage,
  handleSelectStore,
}) {
  return (
    <div className={cx('category_inner')}>
      <div className={cx('selected_store_inner')}>
        <div className={cx('selected_header_box')}>
          <div className={cx('store_title_box')}>
            <p className={cx('category_title', 'mobile_category_title')}>
              전체 스토어
            </p>
            <b className={cx('store_text')}>{currentStorePage?.total}</b>
          </div>
          {/* <div className={cx('store_select_box')}>
            <button className={cx('all_button')}>
              <>
                <input
                  type="checkbox"
                  className={cx('store_hide_check_box')}
                  id="store_select_all"
                  onChange={testClick}
                  checked={true}
                  readOnly
                />
                <label
                  className={cx('store_check_box_label')}
                  htmlFor="store_select_all"
                >
                  <span className={cx('store_check_box')}>
                    <span className={cx('store_check_box_checked')}></span>
                  </span>
                  <b className={cx('store_check_text')}>전체 선택</b>
                </label>
              </>
            </button>
          </div> */}
        </div>
        <div className={cx('selected_list_inner')}>
          {currentStorePage?.total === 0 ? (
            <div className={cx('select_text_box')}>
              <p className={cx('select_text')}>선택 가능한 업체가 없습니다.</p>
            </div>
          ) : (
            <>
              {availableStores[currentStorePage?.currentPage]?.length > 0 &&
                availableStores[currentStorePage?.currentPage].map((el, i) => {
                  return (
                    <Fragment key={el.id}>
                      {el.canBuy ? (
                        <div
                          onClick={() => handleSelectStore(el)}
                          className={cx('selected_list_box')}
                        >
                          {[...selectedStores].some(
                            (item) => item.id === el.id,
                          ) ? (
                            <div
                              className={cx('active_selected_store_item')}
                              style={{
                                backgroundImage: el?.profileImageUrl
                                  ? `url(${el?.profileImageUrl})`
                                  : 'url(/statics/images/beammonster.jpeg)',
                              }}
                            >
                              <img
                                className={cx('acive_selected_img')}
                                src="/statics/images/ic-task-alt.png"
                                alt="선택 표시 이미지"
                              />
                            </div>
                          ) : (
                            <div
                              className={cx('selected_store_item')}
                              style={{
                                backgroundImage: el?.profileImageUrl
                                  ? `url(${el?.profileImageUrl})`
                                  : 'url(/statics/images/beammonster.jpeg)',
                              }}
                            ></div>
                          )}
                          <div className={cx('selected_store_info_inner')}>
                            <p className={cx('selected_store_info_title')}>
                              {el?.businessName}
                            </p>
                            <div className={cx('selected_store_info_box')}>
                              <div className={cx('select_store_info_avg_box')}>
                                <p className={cx('select_store_info_avg_text')}>
                                  업종
                                </p>
                                <p
                                  className={cx(
                                    'selected_store_info_avg_text_b',
                                  )}
                                >
                                  {`${el?.businessCategory} - ${el?.businessSubCategory}`}
                                </p>
                              </div>
                            </div>
                            <div className={cx('selected_store_info_box')}>
                              <div
                                className={cx('selected_store_info_avg_box')}
                              >
                                <p
                                  className={cx('selected_store_info_avg_text')}
                                >
                                  일 평균 방문객
                                </p>
                                <p
                                  className={cx(
                                    'selected_store_info_avg_text_b',
                                  )}
                                >
                                  {numberWithCommas(el?.businessDailyVisitor) ||
                                    '-'}
                                </p>
                              </div>
                              <span
                                className={cx(
                                  'selected_store_info_avg_text_hr',
                                )}
                              ></span>
                              <div
                                className={cx('selected_store_info_avg_box')}
                              >
                                <p
                                  className={cx('selected_store_info_avg_text')}
                                >
                                  최대수용 인원
                                </p>
                                <p
                                  className={cx(
                                    'selected_store_info_avg_text_b',
                                  )}
                                >
                                  {numberWithCommas(el?.businessMaxSeat) || '-'}
                                </p>
                              </div>
                            </div>
                            <div
                              className={cx('selected_store_info_address_box')}
                            >
                              <p
                                className={cx('selected_store_info_text')}
                              >{`${el?.businessAddress} ${el?.businessDetailAddress}`}</p>
                              <div
                                className={cx('selected_store_info_text_box')}
                              >
                                <p
                                  className={cx('selected_store_info_text')}
                                >{`${el?.businessOpenTime} ~ ${el?.businessCloseTime}`}</p>
                                <span
                                  className={cx('selected_store_info_rest')}
                                ></span>
                                <p className={cx('selected_store_info_text')}>
                                  {el?.dayoff?.length > 0
                                    ? `매주 ${el?.dayoff
                                        ?.map((el) => WEEKS[el])
                                        .join(', ')}`
                                    : '휴일 없음'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={cx('not_selected_list_box')}>
                          <div
                            className={cx('selected_store_item')}
                            style={{
                              backgroundImage: el?.profileImageUrl
                                ? `url(${el?.profileImageUrl})`
                                : 'url(/statics/images/beammonster.jpeg)',
                            }}
                          ></div>
                          <div className={cx('selected_store_info_inner')}>
                            <p className={cx('selected_store_info_title')}>
                              {el?.businessName}
                            </p>
                            <div className={cx('selected_store_info_box')}>
                              <div className={cx('select_store_info_avg_box')}>
                                <p className={cx('select_store_info_avg_text')}>
                                  업종
                                </p>
                                <p
                                  className={cx(
                                    'selected_store_info_avg_text_b',
                                  )}
                                >
                                  {`${el?.businessCategory} - ${el?.businessSubCategory}`}
                                </p>
                              </div>
                            </div>
                            <div className={cx('selected_store_info_box')}>
                              <div
                                className={cx('selected_store_info_avg_box')}
                              >
                                <p
                                  className={cx('selected_store_info_avg_text')}
                                >
                                  일 평균 방문객
                                </p>
                                <p
                                  className={cx(
                                    'selected_store_info_avg_text_b',
                                  )}
                                >
                                  {numberWithCommas(el?.businessDailyVisitor) ||
                                    '-'}
                                </p>
                              </div>
                              <span
                                className={cx(
                                  'selected_store_info_avg_text_hr',
                                )}
                              ></span>
                              <div
                                className={cx('selected_store_info_avg_box')}
                              >
                                <p
                                  className={cx('selected_store_info_avg_text')}
                                >
                                  최대수용 인원
                                </p>
                                <p
                                  className={cx(
                                    'selected_store_info_avg_text_b',
                                  )}
                                >
                                  {numberWithCommas(el?.businessMaxSeat) || '-'}
                                </p>
                              </div>
                            </div>
                            <div
                              className={cx('selected_store_info_address_box')}
                            >
                              <p className={cx('selected_store_info_text')}>
                                {`${el?.businessAddress} ${el?.businessDetailAddress}`}
                              </p>
                              <div
                                className={cx('selected_store_info_text_box')}
                              >
                                <p className={cx('selected_store_info_text')}>
                                  {`${el?.businessOpenTime} ~ ${el?.businessCloseTime}`}
                                </p>
                                <span
                                  className={cx('selected_store_info_rest')}
                                ></span>
                                <p className={cx('selected_store_info_text')}>
                                  {el?.dayoff?.length > 0
                                    ? `매주 ${el?.dayoff
                                        ?.map((el) => WEEKS[el])
                                        .join(', ')}`
                                    : '휴일 없음'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  );
                })}
            </>
          )}
        </div>
        {currentStorePage.total && currentStorePage.total !== 0 && (
          <Pagination
            currentPage={currentStorePage.currentPage}
            lastPage={currentStorePage.lastPage}
            perPage={currentStorePage.perPage}
            handlePage={handlePage}
          />
        )}
      </div>
    </div>
  );
}
