import React from 'react';
import classNames from 'classnames/bind';
import styles from './SecondCheckList.module.scss';

const cx = classNames.bind(styles);

export default function SecondCheckList() {

    return (
        <div>
            <div className={cx('brightness_box')}>
                <div className={'brightness'}>
                    <div className={cx('main_img_box')}>
                        <img className={cx('main_img')} src="/statics/images/popup_second_main.png" alt="popup_second_main" />
                    </div>
                    <div className={cx('list_title_inner')}>
                        <h4 className={cx('list_title', 'black')}>
                            빛 밝기 정도에 따른 영상의 선명도 차이
                        </h4>
                    </div>
                    <div className={cx('list_img_inner')}>
                        <div className={cx('list_img_box')}>
                            <img className={cx('list_img')} src="/statics/images/second_first_list_img.png" alt="second_first_list_img" />
                        </div>
                        <div className={cx('list_img_box')}>
                            <img className={cx('list_img')} src="/statics/images/second_second_list_img.png" alt="second_second_list_img" />
                        </div>
                        <div className={cx('list_img_box')}>
                            <img className={cx('list_img')} src="/statics/images/second_third_list_img.png" alt="second_third_list_img" />
                        </div>
                    </div>
                    <div className={cx('list_title_inner')}>
                        <img src="/statics/images/ic_input_info_error.png" alt="ic_input_info_error" />
                        <div className={cx('list_title_box')}>
                            <h4 className={cx('list_title')}>
                                이런 환경은 안돼요!!
                            </h4>
                        </div>
                    </div>
                    <div className={cx('sub_list_img_inner')}>
                        <div className={cx('list_img_item_box')}>
                            <img className={cx('list_img')} src="/statics/images/list_first_item_img.png" alt="list_first_item_img" />
                            <div className={cx('list_img_item_text_box')}>
                                <p className={cx('list_img_item_text')}>
                                    자연광 노출
                                </p>
                            </div>
                        </div>
                        <div className={cx('list_img_item_box')}>
                            <img className={cx('list_img')} src="/statics/images/list_second_item_img.png" alt="list_second_item_img" />
                            <div className={cx('list_img_item_text_box')}>
                                <p className={cx('list_img_item_text')}>
                                    벽면 조명 위치
                                </p>
                            </div>
                        </div>
                        <div className={cx('list_img_item_box')}>
                            <img className={cx('list_img')} src="/statics/images/list_third_item_img.png" alt="list_third_item_img" />
                            <div className={cx('list_img_item_text_box')}>
                                <p className={cx('list_img_item_text')}>
                                    어두운 색상의 벽면
                                </p>
                            </div>
                        </div>
                        <div className={cx('list_img_item_box')}>
                            <img className={cx('list_img')} src="/statics/images/list_four_item_img.png" alt="list_four_item_img" />
                            <div className={cx('list_img_item_text_box')}>
                                <p className={cx('list_img_item_text')}>
                                    그림자
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={cx('caution_inner')}>
                        <div>
                            <img src="/statics/images/ic_input_info_error.png" alt="ic_input_info_error" />
                        </div>
                        <div className={cx('caution_text_box')}>
                            <p className={cx('caution_text')}>
                                빔프로젝터는 창문 개수, 직사광선 유무에 따라 현장 밝기에 영향을 받습니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
