
export const CHANGE_DATA = 'CHANGE_DATA';
export const RESET_DATA = 'RESET_DATA';

export const changeData = (payload) => ({ type: CHANGE_DATA, payload });
export const resetData = () => ({ type: RESET_DATA, })

const initialState = {
  estimateId: null,
  ledWidth: "",
  ledHeight: "",
  beamSize: "",
  address: "",
  detailAddress: "",
  extraInfo: "",
  solutionType: "",
  status: "",
  hardware: "",
};

function estimate(state = initialState, action) {
  switch (action.type) {
    case CHANGE_DATA:
      return {
        ...state,
        ...action.payload,
      }
    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
};

export default estimate;