import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './FaqContent.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function FaqContent({
    faqs,
}) {
    const [open, setOpen] = useState({});
    const initial = faqs?.reduce((init, el, i) => { init[i] = false; return init; }, {});

    return (
        <div className={cx('container')}>
            <div className={cx('inner')}>
                <div className={cx('inner_box')}>
                    <div className={cx('text_inner')}>
                        <div {...useScrollFadeIn('up', 1, 0.4)} className={cx('text_box')}>
                            <p className={cx('text')}>FAQ</p>
                        </div>
                        <div {...useScrollFadeIn('up', 1, 0.8)} className={cx('title_box')}>
                            <h5 className={cx('title')}>자주 묻는 질문</h5>
                        </div>
                    </div>
                    <div className={cx('list_container')}>
                        <div {...useScrollFadeIn('left', 1, 1.2)} className={cx('list_inner')}>
                            {
                                faqs?.map((el, i) => {
                                    return (
                                        <div key={i} onClick={() => setOpen(prev => ({ ...initial, [i]: !prev[i] }))} className={cx('list_item_inner')}>
                                            <div className={cx('list_item_box')}>
                                                <div className={cx('list_sub_box')}>
                                                    <p className={cx(open[i] ? 'active_list_text' : 'list_text')}>{el.title}</p>
                                                    <div className={cx('list_cross_box')}>
                                                        <span className={cx(open[i] ? 'active_list_cross_x' : 'list_cross_x')}></span>
                                                        <span className={cx(open[i] ? 'active_list_cross_y' : 'list_cross_y')}></span>
                                                    </div>
                                                </div>
                                                {
                                                    open[i] &&
                                                    <div className={cx('list_hide_box')}>
                                                        <p className={cx('list_hide_text')}>
                                                            {el.content}
                                                        </p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
