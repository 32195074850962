import React from 'react';
import classNames from 'classnames/bind';
import styles from './IntroServiceSolution.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function IntroServiceSolution() {
  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <div {...useScrollFadeIn('up', 1, 0)} className={cx('title_box')}>
              <h4 className={cx('title')}>
                특별한 ONE-STOP
                <br className={cx('mobile_br')} /> 솔루션을 만나보세요!
              </h4>
            </div>
            <div className={cx('service_solution_inner')}>
              <div className={cx('left_solution_box')}>
                <div
                  {...useScrollClipPath('up', 1, 0)}
                  className={cx('hardware_box')}
                >
                  <div className={cx('solution_img_box')}>
                    <img
                      className={cx('solution_img')}
                      src="/statics/images/img_intro_solution_first.png"
                      alt="img_intro_solution_first"
                    />
                  </div>
                  <div className={cx('detail_text_inner')}>
                    <div className={cx('detail_title_box')}>
                      <h4 className={cx('detail_title')}>하드웨어</h4>
                    </div>
                    <div className={cx('detail_text_sub_inner')}>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          레이저 프로젝터 최대 4대 제공
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          PC 및 4K 모니터 2대 제공
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          무료 설치 및 부가 악세서리 제공
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  {...useScrollClipPath('up', 1, 0.4)}
                  className={cx('interior_box')}
                >
                  <div className={cx('solution_img_box')}>
                    <img
                      className={cx('solution_img')}
                      src="/statics/images/img_intro_solution_third.png"
                      alt="img_intro_solution_third"
                    />
                  </div>
                  <div className={cx('detail_text_inner')}>
                    <div className={cx('detail_title_box')}>
                      <h4 className={cx('detail_title')}>인테리어 콘텐츠</h4>
                    </div>
                    <div className={cx('detail_text_sub_inner')}>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          매월 신규 콘텐츠 업데이트
                        </p>
                      </div>
                      <div className={cx('detail_select_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('Advertisingv_detail_text')}>
                          테마 별 콘텐츠 구성으로
                          <br />
                          다양한 분위기로 연출 가능
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={cx('right_solution_box')}>
                <div
                  {...useScrollClipPath('up', 1, 0.2)}
                  className={cx('software_box')}
                >
                  <div className={cx('solution_img_box')}>
                    <img
                      className={cx('solution_img')}
                      src="/statics/images/img_intro_solution_second.png"
                      alt="img_intro_solution_second"
                    />
                  </div>
                  <div className={cx('detail_text_inner')}>
                    <div className={cx('detail_title_box')}>
                      <h4 className={cx('detail_title')}>소프트웨어</h4>
                    </div>
                    <div className={cx('detail_text_sub_inner')}>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>맵핑 프로그램</p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          엣지 블랜딩 프로그램
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          실시간 비디오 송출 프로그램
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>원격 제어 프로그램</p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          멀티 스크린 프로그램
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          플레이 리스트 프로그램
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          실시간 광고 송출 프로그램 (예정)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  {...useScrollClipPath('up', 1, 0.6)}
                  className={cx('Advertising_box')}
                >
                  <div className={cx('solution_img_box')}>
                    <img
                      className={cx('solution_img')}
                      src="/statics/images/img_intro_solution_four.png"
                      alt="img_intro_solution_four"
                    />
                  </div>
                  <div className={cx('detail_text_inner')}>
                    <div className={cx('detail_title_box')}>
                      <h4 className={cx('detail_title')}>
                        광고 <b className={cx('detail_title_b')}>(선택사항)</b>
                      </h4>
                    </div>
                    <div className={cx('detail_text_sub_inner')}>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          프리미엄 요금제 유저를 위한 혜택
                        </p>
                      </div>
                      <div className={cx('detail_select_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('Advertisingv_detail_text')}>
                          제공되는 실시간 광고 송출 시스템으로
                          <br /> 발생한 광고 수익금 공유
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_select_text')}>
                          추후 론칭 예정 (2023년 초)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx('mobile_service_solution_inner')}>
              <div
                {...useScrollClipPath('up', 1, 0)}
                className={cx('left_solution_box')}
              >
                <div className={cx('hardware_box')}>
                  <div className={cx('solution_img_box')}>
                    <img
                      className={cx('solution_img')}
                      src="/statics/images/img_intro_solution_first.png"
                      alt="img_intro_solution_first"
                    />
                  </div>
                  <div className={cx('detail_text_inner')}>
                    <div className={cx('detail_title_box')}>
                      <h4 className={cx('detail_title')}>하드웨어</h4>
                    </div>
                    <div className={cx('detail_text_sub_inner')}>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          레이저 프로젝터 최대 4대 제공
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          PC 및 4K 모니터 2대 제공
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          무료 설치 및 부가 악세서리 제공
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  {...useScrollClipPath('up', 1, 0.1)}
                  className={cx('software_box')}
                >
                  <div className={cx('solution_img_box')}>
                    <img
                      className={cx('solution_img')}
                      src="/statics/images/img_intro_solution_second.png"
                      alt="img_intro_solution_second"
                    />
                  </div>
                  <div className={cx('detail_text_inner')}>
                    <div className={cx('detail_title_box')}>
                      <h4 className={cx('detail_title')}>소프트웨어</h4>
                    </div>
                    <div className={cx('detail_text_sub_inner')}>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>맵핑 프로그램</p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          엣지 블랜딩 프로그램
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          실시간 비디오 송출 프로그램
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>원격 제어 프로그램</p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          멀티 스크린 프로그램
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          플레이 리스트 프로그램
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          실시간 광고 송출 프로그램 (예정)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                {...useScrollClipPath('up', 1, 0.2)}
                className={cx('right_solution_box')}
              >
                <div className={cx('interior_box')}>
                  <div className={cx('solution_img_box')}>
                    <img
                      className={cx('solution_img')}
                      src="/statics/images/img_intro_solution_third.png"
                      alt="img_intro_solution_third"
                    />
                  </div>
                  <div className={cx('detail_text_inner')}>
                    <div className={cx('detail_title_box')}>
                      <h4 className={cx('detail_title')}>인테리어 콘텐츠</h4>
                    </div>
                    <div className={cx('detail_text_sub_inner')}>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          레이저 프로젝터 최대 4대
                        </p>
                      </div>
                      <div className={cx('detail_select_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('Advertisingv_detail_text')}>
                          맵핑 프로그램 및 실시간 송출
                          <br /> 프로그램 제공
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          무료 설치 및 부속 악세서리 제공
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  {...useScrollClipPath('up', 1, 0.3)}
                  className={cx('Advertising_box')}
                >
                  <div className={cx('solution_img_box')}>
                    <img
                      className={cx('solution_img')}
                      src="/statics/images/img_intro_solution_four.png"
                      alt="img_intro_solution_four"
                    />
                  </div>
                  <div className={cx('detail_text_inner')}>
                    <div className={cx('detail_title_box')}>
                      <h4 className={cx('detail_title')}>
                        광고 <b className={cx('detail_title_b')}>(선택사항)</b>
                      </h4>
                    </div>
                    <div className={cx('detail_text_sub_inner')}>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_text')}>
                          프리미엄 요금제 유저를 위한 혜택
                        </p>
                      </div>
                      <div className={cx('detail_select_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('Advertisingv_detail_text')}>
                          제공되는 실시간 광고 송출 시스템으로
                          <br /> 발생한 광고 수익금 공유
                        </p>
                      </div>
                      <div className={cx('detail_text_box')}>
                        <div className={cx('detail_text_img_box')}>
                          <img
                            className={cx('detail_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt"
                          />
                        </div>
                        <p className={cx('detail_select_text')}>
                          추후 론칭 예정 (2023년 초)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
