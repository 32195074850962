import { Header } from 'components/views';
import SolutionDepositWait from 'components/views/SolutionDepositWait';
import React from 'react';

export default function SolutionDepositWaitWrapper({
  onClick,
  contractFile,
  phone,
}) {
  return (
    <>
      <Header />
      <SolutionDepositWait
        onClick={onClick}
        contractFile={contractFile}
        phone={phone}
      />
    </>
  )
}
