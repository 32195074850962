import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './MypagePassword.module.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function MypagePassword({
    password,
    newPassword,
    confirmPassword,
    handleChange,
    handleSubmit,
    isPasswordError,
}) {

    const [passwordType, setPasswordType] = useState({
        type: 'password',
        visible: false
    });

    const handlePasswordType = (e) => {
        e.preventDefault();
        setPasswordType(() => {
            if (!passwordType.visible) {
                return { type: 'text', visible: true };
            }
            return { type: 'password', visible: false };
        })
    }

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <Link to='/mypage' className={cx('back_box')}>
                        <img className={cx('back_img')} src="/statics/images/ic_profile_back.png" alt="ic_profile_back" />
                        <div className={cx('back_text_box')}>
                            <p className={cx('back_text')}>마이페이지</p>
                        </div>
                    </Link>
                    <div className={cx('inner_box')}>
                        <div className={cx('title_box')}>
                            <h4 className={cx('title')}>
                                새 비밀번호 생성
                            </h4>
                        </div>
                        <div className={cx('password_input_box')}>
                            <div className={cx('password_img_box')}>
                                <input
                                    className={cx('password_input')}
                                    type={passwordType.type}
                                    placeholder="기존 비밀번호 입력"
                                    name="password"
                                    value={password}
                                    onChange={handleChange}
                                />
                                <span className={cx('password_view_img')} onClick={handlePasswordType}>
                                    {
                                        passwordType.visible ?
                                            <img src="/statics/images/ic_pw_visibility_on.png" alt="hide" /> :
                                            <img src="/statics/images/ic_pw_visibility_off.png" alt="view" />
                                    }
                                </span>
                            </div>
                            <div className={cx('password_img_box')}>
                                <input
                                    className={cx('password_input')}
                                    type={passwordType.type}
                                    placeholder="새로운 비밀번호 입력"
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={handleChange}
                                />
                                <span className={cx('password_view_img')} onClick={handlePasswordType}>
                                    {
                                        passwordType.visible ?
                                            <img src="/statics/images/ic_pw_visibility_on.png" alt="hide" /> :
                                            <img src="/statics/images/ic_pw_visibility_off.png" alt="view" />
                                    }
                                </span>
                            </div>
                            <div className={cx('last_password_img_box')}>
                                <input
                                    className={cx('password_input')}
                                    type={passwordType.type}
                                    placeholder="새로운 비밀번호 재입력"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={handleChange}
                                />
                                <span className={cx('password_view_img')} onClick={handlePasswordType}>
                                    {
                                        passwordType.visible ?
                                            <img src="/statics/images/ic_pw_visibility_on.png" alt="hide" /> :
                                            <img src="/statics/images/ic_pw_visibility_off.png" alt="view" />
                                    }
                                </span>
                            </div>
                            <div className={cx('password_fail_inner')}>
                                {
                                    isPasswordError.format &&
                                    <div className={cx('password_fail_box')}>
                                        <img className={cx('password_fail_img')} src="/statics/images/ic_input_info_error.png" alt="info_check" />
                                        <p className={cx('password_fail_text')}>숫자와 특수문자 1개 포함, 8자 이상 입력해주세요.</p>
                                    </div>
                                }
                                {
                                    isPasswordError.same &&
                                    <div className={cx('password_fail_box')}>
                                        <img className={cx('password_fail_img')} src="/statics/images/ic_input_info_error.png" alt="info_check" />
                                        <p className={cx('password_fail_text')}>기존 비밀번호와 동일합니다.</p>
                                    </div>
                                }
                                {
                                    isPasswordError.check &&
                                    <div className={cx('password_fail_box')}>
                                        <img className={cx('password_fail_img')} src="/statics/images/ic_input_info_error.png" alt="info_check" />
                                        <p className={cx('password_fail_text')}>입력하신 새로운 비밀번호가 일치하지 않습니다.</p>
                                    </div>
                                }
                                {
                                    isPasswordError.unvalid &&
                                    <div className={cx('password_fail_box')}>
                                        <img className={cx('password_fail_img')} src="/statics/images/ic_input_info_error.png" alt="info_check" />
                                        <p className={cx('password_fail_text')}>비밀번호가 틀립니다.</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={cx('button_box')}>
                            <button className={cx('button', { disabled: (!password || !newPassword || !confirmPassword) })} disabled={(!password || !newPassword || !confirmPassword)} onClick={handleSubmit}>변경하기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
