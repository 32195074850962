import React from 'react';
import { Footer, Header } from 'components/views';
import MypagePaymentArtist from 'components/views/MypagePaymentArtist';

export default function MypagePaymentArtistWrapper({
  merchantUid,
  handleSuccess,
  handlePaymentStart,
  setPayType,
  payType,
  info,
  handleChange,
  billApply,
  setBillApply,
  handleValid,
  fileUrls,
  handleFileUrls,

  pageData,
  totalPrice,
  wishPlayList,
  handlePage,
}) {
  return (
    <>
      <Header />
      <MypagePaymentArtist
        merchantUid={merchantUid}
        handleSuccess={handleSuccess}
        handlePaymentStart={handlePaymentStart}
        setPayType={setPayType}
        payType={payType}
        info={info}
        handleChange={handleChange}
        billApply={billApply}
        setBillApply={setBillApply}
        handleValid={handleValid}
        fileUrls={fileUrls}
        handleFileUrls={handleFileUrls}

        pageData={pageData}
        totalPrice={totalPrice}
        wishPlayList={wishPlayList}
        handlePage={handlePage}
      />
      <Footer />
    </>
  );
}
