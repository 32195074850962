import React, {
  useEffect,
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import * as api from 'apis';
import useAuth from 'hooks/useAuth';
import {
  handleCheckSolution,
} from 'libs/authService';
import SolutionContractWaitingWrapper from 'components/SolutionContractWaitingWrapper';

export default function SolutionContractWaitingContainer() {
  const navigator = useNavigate();
  const {
    isLogIn,
  } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })]);
        if (res[1]?.data?.status === "UPLOADED") {
          navigator('/solution/contract-complete');
        } else {
          handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [navigator, isLogIn]);

  return (
    <SolutionContractWaitingWrapper />
  )
}
