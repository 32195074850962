import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './SolutionRequestEstimateSolution.module.scss';
import CancelSolutionButton from 'components/common/CancelSolutionButton';
import { useNavigate } from 'react-router-dom';
import Popup from '../Popup/Popup';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const cx = classNames.bind(styles);

export default function SolutionRequestEstimateSolution({
  solutionType,
  onSubmit,
}) {
  const navigate = useNavigate();
  const [active, setActive] = useState({
    basic: false,
    standard: false,
    premium: false,
  });

  useEffect(() => {
    if (solutionType === 'BASIC') {
      setActive({
        basic: true,
        standard: false,
        premium: false,
      });
    }

    if (solutionType === 'STANDARD') {
      setActive({
        basic: false,
        standard: true,
        premium: false,
      });
    }

    if (solutionType === 'PREMIUM') {
      setActive({
        basic: false,
        standard: false,
        premium: true,
      });
    }
  }, [solutionType, setActive]);

  const [buttonPopup, setButtonPopup] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    arrows: false,
  };

  // 모바일 캐러셀
  const slides = [
    {
      slide: (
        <div
          className={cx(
            active.basic ? 'active_content_sub_box' : 'content_sub_box',
          )}
        >
          <div
            role="presentation"
            onClick={() =>
              setActive((prev) => ({
                ...prev,
                basic: !prev.basic,
                standard: false,
                premium: false,
              }))
            }
          >
            <div className={cx('content_title_box')}>
              <h4 className={cx('content_title')}>베이직</h4>
            </div>
            <div className={cx('content_sub_title_box')}>
              <h4 className={cx('content_sub_title')}>
                미디어 인테리어를 시작하는 가장 쉬운 방법
              </h4>
            </div>
            <div className={cx('content_price_box')}>
              <h4 className={cx('content_price')}>1,000,000 ￦</h4>
            </div>
            <div className={cx('content_hr')}></div>
            <div className={cx('content_text_inner')}>
              <div className={cx('content_text_box')}>
                <div className={cx('content_text_img_box')}>
                  {active.basic ? (
                    <img
                      className={cx('content_text_img')}
                      src="/statics/images/ic_task_alt.png"
                      alt="ic_task_alt_off"
                    />
                  ) : (
                    <img
                      className={cx('content_text_img')}
                      src="/statics/images/ic_task_alt_off.png"
                      alt="ic_task_alt_off"
                    />
                  )}
                </div>
                <div className={cx('content_sub_text_box')}>
                  <p className={cx('content_text')}>
                    원하는 미디어 콘텐츠 10종 선택
                  </p>
                </div>
              </div>
              <div className={cx('content_bottom_text_box')}>
                <div className={cx('content_text_img_box')}>
                  {active.basic ? (
                    <img
                      className={cx('content_text_img')}
                      src="/statics/images/ic_task_alt.png"
                      alt="ic_task_alt_off"
                    />
                  ) : (
                    <img
                      className={cx('content_text_img')}
                      src="/statics/images/ic_task_alt_off.png"
                      alt="ic_task_alt_off"
                    />
                  )}
                </div>
                <div className={cx('content_sub_text_box')}>
                  <p className={cx('content_sub_text')}>
                    선택한 콘텐츠 전체 1회 변경 가능
                    <br />
                    (리셋 1회)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={cx('content_button_box')}>
            <button
              onClick={() =>
                setActive((prev) => ({
                  ...prev,
                  basic: !prev.basic,
                  standard: false,
                  premium: false,
                }))
              }
              className={cx(
                active.basic ? 'active_content_button' : 'content_button',
              )}
            >
              {active.basic ? <>선택됨</> : <>선택하기</>}
            </button>
          </div>
        </div>
      ),
    },
    {
      slide: (
        <div
          className={cx(
            active.standard ? 'active_content_sub_box' : 'content_sub_box',
          )}
        >
          <div
            role="presentation"
            onClick={() =>
              setActive((prev) => ({
                ...prev,
                standard: !prev.standard,
                basic: false,
                premium: false,
              }))
            }
          >
            <div className={cx('content_title_box')}>
              <h4 className={cx('content_title')}>스탠다드</h4>
            </div>
            <div className={cx('content_sub_title_box')}>
              <h4 className={cx('content_sub_title')}>
                가장 보편적으로 즐기는 합리적인 선택
              </h4>
            </div>
            <div className={cx('content_price_box')}>
              <h4 className={cx('content_price')}>3,000,000 ￦</h4>
            </div>
            <div className={cx('content_hr')}></div>
            <div className={cx('content_text_inner')}>
              <div className={cx('content_text_box')}>
                <div className={cx('content_text_img_box')}>
                  {active.standard ? (
                    <img
                      className={cx('content_text_img')}
                      src="/statics/images/ic_task_alt.png"
                      alt="ic_task_alt_off"
                    />
                  ) : (
                    <img
                      className={cx('content_text_img')}
                      src="/statics/images/ic_task_alt_off.png"
                      alt="ic_task_alt_off"
                    />
                  )}
                </div>
                <div className={cx('content_sub_text_box')}>
                  <p className={cx('content_text')}>
                    원하는 미디어 콘텐츠 30종 선택
                  </p>
                </div>
              </div>
              <div className={cx('content_bottom_text_box')}>
                <div className={cx('content_text_img_box')}>
                  {active.standard ? (
                    <img
                      className={cx('content_text_img')}
                      src="/statics/images/ic_task_alt.png"
                      alt="ic_task_alt_off"
                    />
                  ) : (
                    <img
                      className={cx('content_text_img')}
                      src="/statics/images/ic_task_alt_off.png"
                      alt="ic_task_alt_off"
                    />
                  )}
                </div>
                <div className={cx('content_sub_text_box')}>
                  <p className={cx('content_sub_text')}>
                    선택한 콘텐츠 전체 2회 변경 가능
                    <br />
                    (리셋 2회)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={cx('content_button_box')}>
            <button
              onClick={() =>
                setActive((prev) => ({
                  ...prev,
                  standard: !prev.standard,
                  basic: false,
                  premium: false,
                }))
              }
              className={cx(
                active.standard ? 'active_content_button' : 'content_button',
              )}
            >
              {active.standard ? <>선택됨</> : <>선택하기</>}
            </button>
          </div>
        </div>
      ),
    },
    {
      slide: (
        <div
          className={cx(
            active.premium ? 'active_content_sub_box' : 'content_sub_box',
          )}
        >
          <div
            role="presentation"
            onClick={() =>
              setActive((prev) => ({
                ...prev,
                premium: !prev.premium,
                basic: false,
                standard: false,
              }))
            }
          >
            <div className={cx('premium_box')}>
              <h4 className={cx('premium_title')}>가장 저렴한 솔루션</h4>
            </div>
            <div className={cx('content_title_box')}>
              <h4 className={cx('content_title')}>프리미엄</h4>
            </div>
            <div className={cx('content_sub_title_box')}>
              <h4 className={cx('content_sub_title')}>
                마음껏 사용하면서 새로운 수익까지 창출
              </h4>
            </div>
            <div className={cx('content_price_box')}>
              <h4 className={cx('content_price')}>FREE</h4>
            </div>
            <div className={cx('content_hr')}></div>
            <div className={cx('content_text_inner')}>
              <div className={cx('content_text_box')}>
                <div className={cx('content_text_img_box')}>
                  {active.premium ? (
                    <img
                      className={cx('content_text_img')}
                      src="/statics/images/ic_task_alt.png"
                      alt="ic_task_alt_off"
                    />
                  ) : (
                    <img
                      className={cx('content_text_img')}
                      src="/statics/images/ic_task_alt_off.png"
                      alt="ic_task_alt_off"
                    />
                  )}
                </div>
                <div className={cx('content_sub_text_box')}>
                  <p className={cx('content_text')}>
                    미디어 콘텐츠 무제한 이용 가능
                  </p>
                </div>
              </div>
              <div className={cx('content_bottom_text_box')}>
                <div className={cx('content_text_img_box')}>
                  {active.premium ? (
                    <img
                      className={cx('content_text_img')}
                      src="/statics/images/ic_task_alt.png"
                      alt="ic_task_alt_off"
                    />
                  ) : (
                    <img
                      className={cx('content_text_img')}
                      src="/statics/images/ic_task_alt_off.png"
                      alt="ic_task_alt_off"
                    />
                  )}
                </div>
                <div className={cx('content_sub_text_box')}>
                  <p className={cx('content_sub_text')}>
                    광고 송출 제휴를 통한 부가 수익 창출
                    <br />
                    (향후 출시 예정)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={cx('content_button_box')}>
            <button
              onClick={() =>
                setActive((prev) => ({
                  ...prev,
                  premium: !prev.premium,
                  basic: false,
                  standard: false,
                }))
              }
              className={cx(
                active.premium ? 'active_content_button' : 'content_button',
              )}
            >
              {active.premium ? <>선택됨</> : <>선택하기</>}
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('header_container')}>
          <div className={cx('inner')}>
            <div className={cx('header_inner')}>
              <div className={cx('selected_process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('selected_process_title')}>상품선택</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('select_process_box')}>
                <div className={cx('select_process_img_box')}>
                  <img
                    src="/statics/images/solution_select.png"
                    alt="solution_select"
                  />
                </div>
                <div className={cx('select_process_title_box')}>
                  <h4 className={cx('select_process_title')}>견적요청</h4>
                </div>
              </div>
              <div className={cx('select_process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>견적검토</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>설치확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>유저정보</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>결제방식</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_status_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>계약현황 확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>계약서 작성</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>입금 확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>설치 스케줄</h4>
                </div>
              </div>
            </div>
            <div className={cx('mobile_header_inner')}>
              <div className={cx('mobile_header_progressbar_box')}>
                <div className={cx('mobile_header_progressbar')}></div>
              </div>
              <div className={cx('select_process_box')}>
                <div className={cx('mobile_select_text_box')}>
                  <p className={cx('mobile_select_text')}>step.2</p>
                </div>
                <div className={cx('mobile_select_process_title_box')}>
                  <div className={cx('select_process_title_box')}>
                    <h4 className={cx('select_process_title')}>견적요청</h4>
                  </div>
                  <div className={cx('select_process_img_box')}>
                    <img
                      src="/statics/images/solution_select.png"
                      alt="solution_select"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <CancelSolutionButton />
            <div
              className={cx('checklist_button')}
              onClick={() => setButtonPopup(true)}
            >
              <div className={cx('checklist_icon_box')}>
                <img
                  className={cx('checklist_icon')}
                  src="/statics/images/check_list.png"
                  alt="check_list"
                />
              </div>
              <div className={cx('hide_text_box')}>
                <p className={cx('hide_text')}>체크리스트 보기</p>
              </div>
            </div>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>솔루션 구성 선택</h4>
            </div>
            <div className={cx('content_inner')}>
              <div
                className={cx(
                  active.basic ? 'active_content_sub_box' : 'content_sub_box',
                )}
              >
                <div
                  role="presentation"
                  onClick={() =>
                    setActive((prev) => ({
                      ...prev,
                      basic: !prev.basic,
                      standard: false,
                      premium: false,
                    }))
                  }
                >
                  <div className={cx('content_title_box')}>
                    <h4 className={cx('content_title')}>베이직</h4>
                  </div>
                  <div className={cx('content_sub_title_box')}>
                    <h4 className={cx('content_sub_title')}>
                      미디어 인테리어를 시작하는 가장 쉬운 방법
                    </h4>
                  </div>
                  <div className={cx('content_price_box')}>
                    <h4 className={cx('content_price')}>1,000,000 ￦</h4>
                  </div>
                  <div className={cx('content_hr')}></div>
                  <div className={cx('content_text_inner')}>
                    <div className={cx('content_text_box')}>
                      <div className={cx('content_text_img_box')}>
                        {active.basic ? (
                          <img
                            className={cx('content_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt_off"
                          />
                        ) : (
                          <img
                            className={cx('content_text_img')}
                            src="/statics/images/ic_task_alt_off.png"
                            alt="ic_task_alt_off"
                          />
                        )}
                      </div>
                      <div className={cx('content_sub_text_box')}>
                        <p className={cx('content_text')}>
                          원하는 미디어 콘텐츠 10종 선택
                        </p>
                      </div>
                    </div>
                    <div className={cx('content_bottom_text_box')}>
                      <div className={cx('content_text_img_box')}>
                        {active.basic ? (
                          <img
                            className={cx('content_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt_off"
                          />
                        ) : (
                          <img
                            className={cx('content_text_img')}
                            src="/statics/images/ic_task_alt_off.png"
                            alt="ic_task_alt_off"
                          />
                        )}
                      </div>
                      <div className={cx('content_sub_text_box')}>
                        <p className={cx('content_sub_text')}>
                          선택한 콘텐츠 전체 1회 변경 가능
                          <br />
                          (리셋 1회)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cx('content_button_box')}>
                  <button
                    onClick={() =>
                      setActive((prev) => ({
                        ...prev,
                        basic: !prev.basic,
                        standard: false,
                        premium: false,
                      }))
                    }
                    className={cx(
                      active.basic ? 'active_content_button' : 'content_button',
                    )}
                  >
                    {active.basic ? <>선택됨</> : <>선택하기</>}
                  </button>
                </div>
              </div>
              <div
                className={cx(
                  active.standard
                    ? 'active_content_sub_box'
                    : 'content_sub_box',
                )}
              >
                <div
                  role="presentation"
                  onClick={() =>
                    setActive((prev) => ({
                      ...prev,
                      standard: !prev.standard,
                      basic: false,
                      premium: false,
                    }))
                  }
                >
                  <div className={cx('content_title_box')}>
                    <h4 className={cx('content_title')}>스탠다드</h4>
                  </div>
                  <div className={cx('content_sub_title_box')}>
                    <h4 className={cx('content_sub_title')}>
                      가장 보편적으로 즐기는 합리적인 선택
                    </h4>
                  </div>
                  <div className={cx('content_price_box')}>
                    <h4 className={cx('content_price')}>3,000,000 ￦</h4>
                  </div>
                  <div className={cx('content_hr')}></div>
                  <div className={cx('content_text_inner')}>
                    <div className={cx('content_text_box')}>
                      <div className={cx('content_text_img_box')}>
                        {active.standard ? (
                          <img
                            className={cx('content_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt_off"
                          />
                        ) : (
                          <img
                            className={cx('content_text_img')}
                            src="/statics/images/ic_task_alt_off.png"
                            alt="ic_task_alt_off"
                          />
                        )}
                      </div>
                      <div className={cx('content_sub_text_box')}>
                        <p className={cx('content_text')}>
                          원하는 미디어 콘텐츠 30종 선택
                        </p>
                      </div>
                    </div>
                    <div className={cx('content_bottom_text_box')}>
                      <div className={cx('content_text_img_box')}>
                        {active.standard ? (
                          <img
                            className={cx('content_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt_off"
                          />
                        ) : (
                          <img
                            className={cx('content_text_img')}
                            src="/statics/images/ic_task_alt_off.png"
                            alt="ic_task_alt_off"
                          />
                        )}
                      </div>
                      <div className={cx('content_sub_text_box')}>
                        <p className={cx('content_sub_text')}>
                          선택한 콘텐츠 전체 2회 변경 가능
                          <br />
                          (리셋 2회)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cx('content_button_box')}>
                  <button
                    onClick={() =>
                      setActive((prev) => ({
                        ...prev,
                        standard: !prev.standard,
                        basic: false,
                        premium: false,
                      }))
                    }
                    className={cx(
                      active.standard
                        ? 'active_content_button'
                        : 'content_button',
                    )}
                  >
                    {active.standard ? <>선택됨</> : <>선택하기</>}
                  </button>
                </div>
              </div>
              <div
                className={cx(
                  active.premium ? 'active_content_sub_box' : 'content_sub_box',
                )}
              >
                <div className={cx('premium_box')}>
                  <h4 className={cx('premium_title')}>가장 저렴한 솔루션</h4>
                </div>
                <div
                  role="presentation"
                  onClick={() =>
                    setActive((prev) => ({
                      ...prev,
                      premium: !prev.premium,
                      basic: false,
                      standard: false,
                    }))
                  }
                >
                  <div className={cx('content_title_box')}>
                    <h4 className={cx('content_title')}>프리미엄</h4>
                  </div>
                  <div className={cx('content_sub_title_box')}>
                    <h4 className={cx('content_sub_title')}>
                      마음껏 사용하면서 새로운 수익까지 창출
                    </h4>
                  </div>
                  <div className={cx('content_price_box')}>
                    <h4 className={cx('content_price')}>FREE</h4>
                  </div>
                  <div className={cx('content_hr')}></div>
                  <div className={cx('content_text_inner')}>
                    <div className={cx('content_text_box')}>
                      <div className={cx('content_text_img_box')}>
                        {active.premium ? (
                          <img
                            className={cx('content_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt_off"
                          />
                        ) : (
                          <img
                            className={cx('content_text_img')}
                            src="/statics/images/ic_task_alt_off.png"
                            alt="ic_task_alt_off"
                          />
                        )}
                      </div>
                      <div className={cx('content_sub_text_box')}>
                        <p className={cx('content_text')}>
                          미디어 콘텐츠 무제한 이용 가능
                        </p>
                      </div>
                    </div>
                    <div className={cx('content_bottom_text_box')}>
                      <div className={cx('content_text_img_box')}>
                        {active.premium ? (
                          <img
                            className={cx('content_text_img')}
                            src="/statics/images/ic_task_alt.png"
                            alt="ic_task_alt_off"
                          />
                        ) : (
                          <img
                            className={cx('content_text_img')}
                            src="/statics/images/ic_task_alt_off.png"
                            alt="ic_task_alt_off"
                          />
                        )}
                      </div>
                      <div className={cx('content_sub_text_box')}>
                        <p className={cx('content_sub_text')}>
                          광고 송출 제휴를 통한 부가 수익 창출
                          <br />
                          (향후 출시 예정)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cx('content_button_box')}>
                  <button
                    onClick={() =>
                      setActive((prev) => ({
                        ...prev,
                        premium: !prev.premium,
                        basic: false,
                        standard: false,
                      }))
                    }
                    className={cx(
                      active.premium
                        ? 'active_content_button'
                        : 'content_button',
                    )}
                  >
                    {active.premium ? <>선택됨</> : <>선택하기</>}
                  </button>
                </div>
              </div>
            </div>
            <div className={cx('mobile_slider_box')}>
              <Slider {...settings}>{slides.map((el) => el.slide)}</Slider>
            </div>
            <div className={cx('button_box')}>
              <div className={cx('prev_button_box')}>
                <button
                  className={cx('prev_button')}
                  onClick={() => navigate(-1)}
                >
                  이전 단계 확인
                </button>
              </div>
              <div className={cx('next_button_box')}>
                <button
                  className={cx('next_button')}
                  onClick={() => onSubmit(active)}
                >
                  다음 단계로 이동
                </button>
              </div>
            </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} />
          </div>
        </div>
      </div>
    </div>
  );
}
