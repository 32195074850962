import React from 'react';
import { PROCESS } from 'constants/signup';
import SignupEmailProcess from 'components/views/SignupEmailProcess';
import SignupPasswordProcess from 'components/views/SignupPasswordProcess';
import SignupOtherProcess from 'components/views/SignupOtherProcess';

export default function Signup({
  signup,
  isEmailSuccess,
  isEmailError,
  isCheckedEmail,
  isOpenPost,
  handleOpenPost,
  handleClosePost,
  handleEmail,
  handleChange,
  handleAgree,
  handleAllAgree,
  emailProcessDisabled,
  handleEmailProcess,
  isPasswordError,
  handlePasswordProcess,
  isPhoneError,
  isBusinessError,
  isPhoneExpired,
  handleVerifiedPhone,
  startTime,
  endTime,
  isCodeError,
  handleCode,
  handleEditPhone,
  handleClickAddress,
  handleCheckBusinessNumber,
  handleClickGender,
  handleSubmit,
  handlePrivacy,
  handleTerms,
}) {
  return (
    <>
      {signup.process === PROCESS.FIRST && (
        <SignupEmailProcess
          email={signup.email}
          allAgree={signup.allAgree}
          termsAgree={signup.termsAgree}
          privacyAgree={signup.privacyAgree}
          marketingAgree={signup.marketingAgree}
          isEmailSuccess={isEmailSuccess}
          isEmailError={isEmailError}
          isCheckedEmail={isCheckedEmail}
          handleEmail={handleEmail}
          handleChange={handleChange}
          handleAgree={handleAgree}
          handleAllAgree={handleAllAgree}
          emailProcessDisabled={emailProcessDisabled}
          handleEmailProcess={handleEmailProcess}
          handlePrivacy={handlePrivacy}
          handleTerms={handleTerms}
        />
      )}
      {signup.process === PROCESS.SECOND && (
        <SignupPasswordProcess
          password={signup.password}
          handleChange={handleChange}
          isPasswordError={isPasswordError}
          handlePasswordProcess={handlePasswordProcess}
        />
      )}
      {signup.process === PROCESS.THIRD && (
        <SignupOtherProcess
          name={signup.name}
          phone={signup.phone}
          isSendCode={signup.isSendCode}
          isPhoneVerified={signup.isPhoneVerified}
          code={signup.code}
          gender={signup.gender}
          birthday={signup.birthday}
          businessCategory={signup.businessCategory}
          businessNumber={signup.businessNumber}
          businessName={signup.businessName}
          businessAddress={signup.businessAddress}
          businessDetailAddress={signup.businessDetailAddress}
          businessCustomerAge={signup.businessCustomerAge}
          businessOpenTime={signup.businessOpenTime}
          businessCloseTime={signup.businessCloseTime}
          businessOpenMemo={signup.businessOpenMemo}
          businessBusyHour={signup.businessBusyHour}
          businessMaxSeat={signup.businessMaxSeat}
          businessDailyVisitor={signup.businessDailyVisitor}
          handleChange={handleChange}
          isOpenPost={isOpenPost}
          handleOpenPost={handleOpenPost}
          handleClosePost={handleClosePost}
          isPhoneError={isPhoneError}
          isBusinessError={isBusinessError}
          isBusinessVerified={signup.isBusinessVerified}
          isPhoneExpired={isPhoneExpired}
          isCodeError={isCodeError}
          handleCode={handleCode}
          handleVerifiedPhone={handleVerifiedPhone}
          startTime={startTime}
          endTime={endTime}
          handleEditPhone={handleEditPhone}
          handleClickAddress={handleClickAddress}
          handleCheckBusinessNumber={handleCheckBusinessNumber}
          handleClickGender={handleClickGender}
          handleSubmit={handleSubmit}
          signupDisabled={
            !signup.email
            || !signup.password
            || !signup.name
            || !signup.phone
            || !signup.isPhoneVerified
            || !signup.birthday
            || !signup.gender
          }
        />
      )}
    </>
  );
}
