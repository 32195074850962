import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './SolutionInstallmentExaminationConfirm.module.scss';
import { NavLink } from 'react-router-dom';
import Popup from '../Popup/Popup';

const cx = classNames.bind(styles);

export default function SolutionInstallmentExaminationConfirm() {

    const [buttonPopup, setButtonPopup] = useState(false);

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('header_container')}>
                    <div className={cx('inner')}>
                        <div className={cx('header_inner')}>
                            <div className={cx('selected_process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('selected_process_title')}>상품선택</h4>
                                </div>
                            </div>
                            <div className={cx('selected_process_bar')}></div>
                            <div className={cx('selected_process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('selected_process_title')}>견적요청</h4>
                                </div>
                            </div>
                            <div className={cx('selected_process_bar')}></div>
                            <div className={cx('selected_process_box')}>
                                <div className={cx('selected_process_title_box')}>
                                    <h4 className={cx('selected_process_title')}>견적발행</h4>
                                </div>
                            </div>
                            <div className={cx('selected_process_bar')}></div>
                            <div className={cx('selected_process_box')}>
                                <div className={cx('selected_process_title_box')}>
                                    <h4 className={cx('selected_process_title')}>결제방식</h4>
                                </div>
                            </div>
                            <div className={cx('selected_process_bar')}></div>
                            <div className={cx('select_status_process_box')}>
                                <div className={cx('select_process_img_box')}>
                                    <img src="/statics/images/solution_select.png" alt="solution_select"/>
                                </div>
                                <div className={cx('select_process_title_box')}>
                                    <h4 className={cx('select_process_title')}>계약현황 확인</h4>
                                </div>
                            </div>
                            <div className={cx('select_process_bar')}></div>
                            <div className={cx('process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('process_title')}>계약서 작성</h4>
                                </div>
                            </div>
                            <div className={cx('process_bar')}></div>
                            <div className={cx('process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('process_title')}>입금 확인</h4>
                                </div>
                            </div>
                            <div className={cx('process_bar')}></div>
                            <div className={cx('process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('process_title')}>설치 스케줄</h4>
                                </div>
                            </div>
                        </div>
                        <div className={cx('mobile_header_inner')}>
                            <div className={cx('mobile_header_progressbar_box')}>
                                <div className={cx('mobile_header_progressbar')}></div>
                            </div>
                            <div className={cx('select_process_box')}>
                                <div className={cx('mobile_select_text_box')}>
                                    <p className={cx('mobile_select_text')}>step.5</p>
                                </div>
                                <div className={cx('mobile_select_process_title_box')}>
                                    <div className={cx('select_process_title_box')}>
                                        <h4 className={cx('select_process_title')}>계약 현황 확인</h4>
                                    </div>
                                    <div className={cx('select_process_img_box')}>
                                        <img src="/statics/images/solution_select.png" alt="solution_select"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <div className={cx('checklist_button')} onClick={() => setButtonPopup(true)}>
                            <div className={cx('checklist_icon_box')}>
                                <img className={cx('checklist_icon')} src="/statics/images/check_list.png" alt="check_list" />
                            </div>
                            <div className={cx('hide_text_box')}>
                                <p className={cx('hide_text')}>체크리스트 보기</p>
                            </div>
                        </div>
                        <div className={cx('title_box')}>
                            <h4 className={cx('title')}>할부 심사 진행</h4>
                        </div>
                        <div className={cx('content_inner')}>
                            <div className={cx('content_box')}>
                                <div className={cx('content_header')}>
                                    <div className={cx('content_step_inner')}>
                                        <div className={cx('content_step_box')}>
                                            <p className={cx('content_step')}>
                                                STEP. 01
                                            </p>
                                        </div>
                                        <div className={cx('content_step_title_box')}>
                                            <h4 className={cx('content_step_title')}>
                                                정보 입력
                                            </h4>
                                        </div>
                                    </div>
                                    <div className={cx('content_step_inner')}>
                                        <div className={cx('content_step_box')}>
                                            <p className={cx('content_step')}>
                                                STEP. 02
                                            </p>
                                        </div>
                                        <div className={cx('content_step_title_box')}>
                                            <h4 className={cx('content_step_title')}>
                                                할부 조건 선택
                                            </h4>
                                        </div>
                                    </div>
                                    <div className={cx('content_step_inner')}>
                                        <div className={cx('content_step_box')}>
                                            <p className={cx('content_step')}>
                                                STEP. 03
                                            </p>
                                        </div>
                                        <div className={cx('content_step_title_box')}>
                                            <h4 className={cx('content_step_title')}>
                                                할부 심사
                                            </h4>
                                        </div>
                                    </div>
                                    <div className={cx('content_step_inner')}>
                                        <div className={cx('content_step_box')}>
                                            <p className={cx('content_step')}>
                                                STEP. 04
                                            </p>
                                        </div>
                                        <div className={cx('content_step_title_box')}>
                                            <h4 className={cx('content_step_title')}>
                                                할부 심사 완료
                                            </h4>
                                        </div>
                                    </div>
                                    <div className={cx('select_content_step_inner')}>
                                        <img className={cx('content_step_img')} src="/statics/images/Installment_progress.png" alt="Installment_progress"/>
                                        <div className={cx('content_step_box')}>
                                            <p className={cx('select_content_step')}>
                                                STEP. 05
                                            </p>
                                        </div>
                                        <div className={cx('content_step_title_box')}>
                                            <h4 className={cx('select_content_step_title')}>
                                                계약서 확인
                                            </h4>
                                        </div>
                                    </div>
                                    <div className={cx('next_content_step_inner')}>
                                        <div className={cx('content_step_box')}>
                                            <p className={cx('content_step')}>
                                                STEP. 06
                                            </p>
                                        </div>
                                        <div className={cx('content_step_title_box')}>
                                            <h4 className={cx('content_step_title')}>
                                                대금 결제
                                            </h4>
                                        </div>
                                    </div>
                                    <div className={cx('content_step_inner')}>
                                        <div className={cx('content_step_box')}>
                                            <p className={cx('content_step')}>
                                                STEP. 07
                                            </p>
                                        </div>
                                        <div className={cx('content_step_title_box')}>
                                            <h4 className={cx('content_step_title')}>
                                                결제 완료/서명
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <hr className={cx('content_hr')} />
                                <div className={cx('content_sub_inner')}>
                                    
                                </div>
                            </div>
                        </div>
                        <div className={cx('button_box')}>
                            <div className={cx('prev_button_box')}>
                                <NavLink to='/solution/installment-examination/completed' className={cx('prev_button')}>이전 단계 확인</NavLink>
                            </div>
                            <div className={cx('next_button_box')}>
                                <NavLink to='/solution/installment-examination/payment' className={cx('next_button')}>다음 단계로 이동</NavLink>
                            </div>
                        </div>
                        <Popup trigger={buttonPopup} setTrigger={setButtonPopup} />
                    </div>
                </div>
            </div>
        </div>
    )
}
