import React from 'react';
import { Header } from 'components/views';
import SolutionScheduleComplete from 'components/views/SolutionScheduleComplete';

export default function SolutionScheduleCompleteWrapper({
    visitDate,
    visitManagerName,
    visitManagerPhone,
    visitManagerProfileImageUrl,
}) {
    return (
        <>
            <Header />
            <SolutionScheduleComplete
                visitDate={visitDate}
                visitManagerName={visitManagerName}
                visitManagerPhone={visitManagerPhone}
                visitManagerProfileImageUrl={visitManagerProfileImageUrl}
            />
        </>
    )
}
