import styled from '@emotion/styled';

export const ImgBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
  & div {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    & img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const CalendarInner = styled.div`
  position: absolute;
  top: 120px;
  border: 0.5px solid #333;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 4px 4px 6px 0 rgb(0 0 0 / 10%);
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  .react-calendar__navigation {
    display: flex;
    margin-bottom: 26px;
    border: 0;

    .react-calendar__navigation__label {
      /* font-weight: bold; */
    }

    .react-calendar__navigation__arrow {
      /* display: none; */
      flex-grow: 0.333;
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }
  }

  /* 요일 텍스트 가운데로 */
  .react-calendar__month-view__weekdays {
    text-decoration: none;
    text-align: center;
    abbr {
      font-size: 12px;
      color: #9e9e9e;
      text-decoration: none;
    }
  }

  /* 날짜 버튼 css */
  button {
    /* margin: 3px; */
    background-color: #fff;
    /* border: 1px solid #fdb1a0; */
    border: 0;
    border-radius: 3px;
    color: #333;
    padding: 10px 0;
    cursor: pointer;

    &:hover {
      background-color: #32c4ff;
      color: #fff;
    }

    &:active {
      background-color: #32c4ff;
      color: #fff;
    }
  }

  /* .react-calendar__tile {
    &:nth-of-type(${(props) => props.resultDate && 'n'}) {
      opacity: 0.5;
    }
  } */

  /* 지난달 날짜 & 다음달 날짜 opacity */
  .react-calendar__month-view__days__day--neighboringMonth.date_disabled {
    opacity: 0.5;
  }

  .react-calendar__month-view__days__day--weekend {
    /* color: #388dff; */
    &:nth-of-type(1n) {
      color: #d95858;
    }
    &:nth-of-type(7n) {
      color: #388dff;
    }
    &:hover {
      background-color: #32c4ff;
      color: #fff;
    }

    &:active {
      background-color: #32c4ff;
      color: #fff;
    }
  }

  /* 요일 클릭했을때 */
  .react-calendar__tile--range {
    background-color: #32c4ff;
    color: #000000;
    /* box-shadow: 4px 4px 6px 0 rgb(0 0 0 / 10%); */
  }

  .date_disabled {
    opacity: 50%;
  }
`;
