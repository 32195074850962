import React, { useCallback } from 'react';
import Pagination from 'components/common/Pagination';
import classNames from 'classnames/bind';
import styles from './AdvertisementPaymentHistory.module.scss';
import { useNavigate } from 'react-router-dom';
import { numberWithCommas } from 'libs/numbers';
import { ADVERTISE_STATUS } from 'constants/advertisement';
import moment from 'moment';

const cx = classNames.bind(styles);

export default function AdvertisementPaymentHistory({
  handlePage,
  pageData,
  data,
}) {
  // 상세페이지 이동

  const navigator = useNavigate();

  const onClickHistoryDetailMove = useCallback((id) => {
    navigator(`/mypage/payment-history/detail?id=${id}`);
  }, []);

  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <table className={cx('table')}>
          {data?.length > 0 && (
            <thead className={cx('thead')}>
              <tr className={cx('thead_tr')}>
                <td className={cx('thead_td')}>구매 번호</td>
                <td className={cx('thead_td')}>매체수</td>
                <td className={cx('thead_td')}>구매 일자</td>
                <td className={cx('thead_td')}>상태</td>
                <td className={cx('thead_td')}>결제 금액</td>
              </tr>
            </thead>
          )}
          <tbody className={cx('tbody')}>
            {data?.length > 0 ? (
              data?.map((el) => {
                return (
                  <tr
                    onClick={() => onClickHistoryDetailMove(el?.id)}
                    className={cx('tbody_tr')}
                    key={el?.id}
                  >
                    <td className={cx('tbody_td')}>{el?.merchant_uid}</td>
                    <td className={cx('tbody_td')}>
                      {numberWithCommas(el?.advertises?.length) || '-'}EA
                    </td>
                    <td className={cx('tbody_td')}>
                      {(el?.paidDate &&
                        moment(el?.paidDate).format('YYYY.MM.DD')) ||
                        '-'}
                    </td>
                    <td
                      className={cx(
                        el?.status === 'READY' && 'tbody_td_wating',
                        el?.status === 'PAID' && 'tbody_td_completion',
                        el?.status === 'RUNNING' && 'tbody_td_rogress',
                        el?.status === 'REJECTED' && 'tbody_td_refuse',
                        el?.status === 'FAIL' && 'tbody_td_refuse',
                        el?.status === 'REFUNDED' && 'tbody_td_refuse',
                        el?.status === 'REQUEST_REFUND' && 'tbody_td_refuse',
                        el?.status === 'FINISH' && 'tbody_td_advertisement',
                      )}
                    >
                      {ADVERTISE_STATUS[el?.status] || '-'}
                    </td>
                    <td className={cx('tbody_td', 'tbody_td_payment')}>
                      {numberWithCommas(el?.price) || '-'}원
                    </td>
                    <td
                      className={cx('mobile_tbody_td', 'mobile_tbody_sub_td')}
                    >
                      <p className={cx('mobile_tbody_text')}>
                        구매 번호: {el?.merchant_uid}
                      </p>
                      <p
                        className={cx(
                          el?.status === 'READY' && 'mobile_tbody_td_wating',
                          el?.status === 'PAID' && 'mobile_tbody_td_completion',
                          el?.status === 'RUNNING' && 'mobile_tbody_td_rogress',
                          el?.status === 'REJECTED' && 'mobile_tbody_td_refuse',
                          el?.status === 'FAIL' && 'mobile_tbody_td_refuse',
                          el?.status === 'REFUNDED' && 'mobile_tbody_td_refuse',
                          el?.status === 'REQUEST_REFUND' &&
                            'mobile_tbody_td_refuse',
                          el?.status === 'FINISH' &&
                            'mobile_tbody_td_advertisement',
                        )}
                      >
                        {ADVERTISE_STATUS[el?.status] || '-'}
                      </p>
                    </td>
                    <td className={cx('mobile_tbody_td')}>
                      <p className={cx('mobile_tbody_number_text')}>
                        매체수:{' '}
                        {numberWithCommas(el?.advertises?.length) || '-'}EA
                      </p>
                      <p className={cx('mobile_tbody_price_text')}>
                        결제 금액: {numberWithCommas(el?.price) || '-'}원
                      </p>
                    </td>
                    <td className={cx('mobile_tbody_td')}>
                      <p className={cx('mobile_tbody_date_text')}>
                        구매 일자:{' '}
                        {(el?.paidDate &&
                          moment(el?.paidDate).format('YYYY.MM.DD')) ||
                          '-'}
                      </p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <div className={cx('empty_box')}>
                <p className={cx('empty_text')}>결제 내역이 없습니다.</p>
              </div>
            )}
          </tbody>
        </table>
        {data.length > 0 && (
          <Pagination
            currentPage={pageData.currentPage}
            lastPage={pageData.lastPage}
            perPage={pageData.perPage}
            handlePage={handlePage}
          />
        )}
      </div>
    </div>
  );
}
