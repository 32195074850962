import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import MypageSolution from 'components/views/MypageSolution/MypageSolution';

export default function MypageSolutionWrapper() {
    return (
        <>
            <Header />
            <MypageSolution />
            <Footer />
        </>
    )
}
