import React from 'react';
import { Footer, Header } from 'components/views';
import AdvertisementPayment from 'components/views/AdvertisementPayment';

export default function AdvertisementPaymentWrapper({
  handleSuccess,
  handlePaymentStart,
  handleChange,
  businessInfo,
  paymentType,
  handlePaymentType,
  fileUrls,
  handleFileUrls,
  selectedStores,
  handlePage,
  pageData,
  data,
  merchantUid,
  estimateInfo,
  setPayType,
  payType,
  info,
  billApply,
  setBillApply,
  handleValid,
  count,
  totalPrice,
}) {
  return (
    <>
      <Header />
      <AdvertisementPayment
        merchantUid={merchantUid}
        selectedStores={selectedStores}
        handleSuccess={handleSuccess}
        handlePaymentStart={handlePaymentStart}
        handleChange={handleChange}
        fileUrls={fileUrls}
        handleFileUrls={handleFileUrls}
        handlePage={handlePage}
        pageData={pageData}
        data={data}
        estimateInfo={estimateInfo}

        setPayType={setPayType}
        payType={payType}
        info={info}
        billApply={billApply}
        setBillApply={setBillApply}
        handleValid={handleValid}

        count={count}
        totalPrice={totalPrice}
      />
      <Footer />
    </>
  );
}
