export const CHANGE_DATA = 'auth/CHANGE_DATA';
export const RESET_DATA = 'auth/RESET_DATA';

export const changeAuthData = (payload) => ({ type: CHANGE_DATA, payload });
export const resetAuthData = () => ({ type: RESET_DATA });

export const initialState = {
  isLogIn: false,
  name: '',
  phone: '',
  profileImageUrl: '',
  birthday: '',
  businessAddress: '',
  businessDetailAddress: '',
  businessBusyHour: '',
  businessCategory: '',
  businessSubCategory: '',
  businessCloseTime: '',
  businessCustomerAge: '',
  businessDailyVisitor: '',
  businessMaxSeat: '',
  businessName: '',
  businessNumber: '',
  businessOpenTime: '',
  businessTimeMemo: '',
  contract: null,
  email: '',
  estimate: null,
  gender: '',
  playlistResetCount: 0,
  dayoff: [],
  isUnlimitedPlaylist: false,
};

function auth(state = initialState, action) {
  switch (action.type) {
    case CHANGE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
}

export default auth;
