import React, { useEffect, useState, useCallback } from 'react';
import AdvertisementPaymentWrapper from 'components/AdvertisementPaymentWrapper';
import useAdvertisement from 'hooks/useAdvertisement';
import { useNavigate } from 'react-router-dom';
import { regEmail } from 'constants/regExp';
import * as api from 'apis';

export default function AdvertisementPaymentContainer() {
  const {
    selectedCategory,
    selectedBusinessCategories,
    startDate,
    endDate,
    selectedStores,
    estimateInfo,
    videoUrl,
    advertiseOrderId,
    merchantUid,
    handleChangeAdvertisementData,
  } = useAdvertisement();

  const navigator = useNavigate();

  const [payType, setPayType] = useState('trans'); // trans (계좌이체), card
  const [billApply, setBillApply] = useState(true);

  const [info, setInfo] = useState({
    businessEmail: '',
    registrationNumber: '',
    businessName: '',
    isBusinessVerified: false,
  });

  const [fileUrls, setFileUrls] = useState({
    0: {},
  });

  const [data, setData] = useState({});
  const [count, setCount] = useState({
    totalViewCount: 0,
    totalVisitorCount: 0,
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [pageData, setPageData] = useState({
    total: 0,
    currentPage: 1,
    lastPage: 0,
    perPage: 5,
    limit: 10,
    search: '',
    orderBy: '',
  });

  const handlePaymentStart = async () => {
    const {
      businessEmail,
      registrationNumber,
      businessName,
      isBusinessVerified,
    } = info;
    try {
      const storeUser_ids = selectedStores.map((store) => store.id);

      if (payType === 'trans' && billApply) {
        if (!registrationNumber) {
          alert('사업자 번호를 입력해주세요 : )');
          return;
        }

        if (!isBusinessVerified) {
          alert('사업자 번호를 인증해주세요 : )');
          return;
        }

        if (!businessName) {
          alert('사업자 상호를 입력해주세요 : )');
          return;
        }

        if (!businessEmail) {
          alert('세금계산서 발행을 위한 이메일을 입력해주세요 : )');
          return;
        }

        if (!regEmail.test(businessEmail)) {
          alert('이메일 형식에 맞게 입력해주세요 : )');
          return;
        }

        if (!fileUrls[0]?.url) {
          alert('사업자등록증을 업로드해주세요 : )');
          return;
        }
        const res = await api.postAdvertisesOrdersReady(
          startDate,
          endDate,
          storeUser_ids,
          videoUrl,
          selectedCategory.category,
          {
            businessEmail,
            registrationNumber,
            businessName,
            businessRegistrationImgUrl: fileUrls[0]?.url,
          },
        );
        handleChangeAdvertisementData({
          advertiseOrderId: res?.data?.advertiseOrder_id,
          merchantUid: res?.data?.merchant_uid,
        });
      } else {
        const res = await api.postAdvertisesOrdersReady(
          startDate,
          endDate,
          storeUser_ids,
          videoUrl,
          selectedCategory.category,
          {},
        );
        handleChangeAdvertisementData({
          advertiseOrderId: res?.data?.advertiseOrder_id,
          merchantUid: res?.data?.merchant_uid,
        });
      }
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const handleSuccess = async (response) => {
    try {
      const {
        success,
        error_msg,
        imp_uid,
        merchant_uid,
        pay_method,
        paid_amount,
        status,
      } = response;
      await api.postAdvertisesOrdersConfirm(imp_uid, advertiseOrderId);
      navigator('/payment-complete?type=advertisement');
    } catch (err) {
      navigator('/payment-cancel');
      console.log(err);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === 'businessNumber') {
      setInfo((prev) => ({
        ...prev,
        registrationNumber: e.target.value,
        isBusinessVerified: false,
      }));
    } else {
      setInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleValid = (value) => {
    setInfo((prev) => ({ ...prev, isBusinessVerified: value }));
  };

  useEffect(() => {
    const res = estimateInfo.reduce(
      (acc, item, index) => {
        const key = Math.ceil((index + 1) / 10);
        if (!acc.data[key]) {
          acc.data[key] = [];
        }
        acc.data[key].push(item);
        acc.price += item.price;
        acc.dailyViewCount += item.dailyViewCount;
        acc.dailyVisitorCount += item.dailyVisitorCount;
        acc.totalViewCount += item.totalViewCount;
        acc.totalVisitorCount += item.totalVisitorCount;
        return acc;
      },
      {
        data: {},
        price: 0,
        dailyViewCount: 0,
        dailyVisitorCount: 0,
        totalViewCount: 0,
        totalVisitorCount: 0,
      },
    );
    setData(res?.data);
    setTotalPrice(res?.price);
    setCount({
      totalViewCount: res?.totalViewCount,
      totalVisitorCount: res?.totalVisitorCount,
    });
    setPageData((prev) => ({
      ...prev,
      total: estimateInfo?.length,
      lastPage: Math.ceil(estimateInfo?.length / 10) || 1,
    }));
  }, [estimateInfo]);

  const handlePage = (currentPage) => {
    setPageData((prev) => ({
      ...prev,
      currentPage,
    }));
  };

  const handlePriceEstimate = useCallback(
    async (startDate, endDate, selectedStores) => {
      try {
        const storeUser_ids = selectedStores.map((store) => store.id);
        const res = await api.getAdvertisesPriceEstimate(
          startDate,
          endDate,
          storeUser_ids,
        );
        handleChangeAdvertisementData({
          estimateInfo: res?.data,
        });
      } catch (error) {
        console.error(error);
      }
    },
    [],
  );

  useEffect(() => {
    if (!startDate || !endDate || selectedStores?.length === 0) return;
    (async () => {
      await handlePriceEstimate(startDate, endDate, selectedStores);
    })();
  }, [startDate, endDate, selectedStores, handlePriceEstimate]);

  useEffect(() => {
    if (!selectedStores?.length) return navigator('/advertisement');
  }, [selectedStores]);

  return (
    <AdvertisementPaymentWrapper
      selectedStores={selectedStores}
      merchantUid={merchantUid}
      handleSuccess={handleSuccess}
      handlePaymentStart={handlePaymentStart}
      handleChange={handleChange}
      fileUrls={fileUrls}
      handleFileUrls={setFileUrls}
      handlePage={handlePage}
      pageData={pageData}
      data={data}
      estimateInfo={estimateInfo}
      setPayType={setPayType}
      payType={payType}
      info={info}
      billApply={billApply}
      setBillApply={setBillApply}
      handleValid={handleValid}
      count={count}
      totalPrice={totalPrice}
    />
  );
}
