import Header from 'components/views/Header/Header'
import React from 'react';
import MediaPlayList from 'components/views/MediaPlayList/MediaPlayList'
import Footer from 'components/views/Footer/Footer';

export default function MediaPlayListWrapper({
    playList,
    onClick,
    handleRemove,
    handleClickLike,
    selectedPlay,
    playId,
    handleUp,
    handleDown,
    handlePlayOrdering,
    handlePlayShuffle,
    handlePlayLoop,
    playLoop,
    playType,
    handlePlayType,
    playVolume,
    handleVolumnChange,
    currentPlayTime,
    handlePlayTimeChange,
    playMute,
    handlePlayMute,
    playStart,
    handlePlayStart,
    hanldeNextPlay,
    hanldePrevPlay,
    playDuration,
    handlePlayDuration,
    handleMoveClick,
    grade,
    handleAgree,
    handleAllAgree,
    setTempMaxCount,
    tempMaxCount,
    isFetching,
}) {
    return (
        <>
            <Header />
            <MediaPlayList
                playId={playId}
                playList={playList}
                isFetching={isFetching}
                onClick={onClick}
                selectedPlay={selectedPlay}
                handleRemove={handleRemove}
                handleClickLike={handleClickLike}
                handleUp={handleUp}
                handleDown={handleDown}
                handlePlayOrdering={handlePlayOrdering}
                handlePlayShuffle={handlePlayShuffle}
                handlePlayLoop={handlePlayLoop}
                playLoop={playLoop}
                playType={playType}
                handlePlayType={handlePlayType}
                playVolume={playVolume}
                handleVolumnChange={handleVolumnChange}
                currentPlayTime={currentPlayTime}
                handlePlayTimeChange={handlePlayTimeChange}
                playMute={playMute}
                handlePlayMute={handlePlayMute}
                playStart={playStart}
                handlePlayStart={handlePlayStart}
                hanldeNextPlay={hanldeNextPlay}
                hanldePrevPlay={hanldePrevPlay}
                playDuration={playDuration}
                handlePlayDuration={handlePlayDuration}
                handleMoveClick={handleMoveClick}
                grade={grade}
                handleAgree={handleAgree}
                handleAllAgree={handleAllAgree}
                setTempMaxCount={setTempMaxCount}
                tempMaxCount={tempMaxCount}
            />
            <Footer />
        </>
    )
}
