import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './SolutionChoiceProduct.module.scss';
import { NavLink } from 'react-router-dom';
import CancelSolutionButton from 'components/common/CancelSolutionButton';
import Popup from '../Popup/Popup';

const cx = classNames.bind(styles);

export default function SolutionChoiceProduct({ onSubmit, hardware }) {
  const [active, setActive] = useState({
    led: false,
    beam: false,
  });

  useEffect(() => {
    if (hardware === 'LED') {
      setActive((prev) => ({
        ...prev,
        led: true,
      }));
    }

    if (hardware === 'BEAM') {
      setActive((prev) => ({
        ...prev,
        beam: true,
      }));
    }
  }, [hardware, setActive]);

  const [buttonPopup, setButtonPopup] = useState(true);

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('header_container')}>
          <div className={cx('inner')}>
            <div className={cx('header_inner')}>
              <div className={cx('select_process_box')}>
                <div className={cx('select_process_img_box')}>
                  <img
                    src="/statics/images/solution_select.png"
                    alt="solution_select"
                  />
                </div>
                <div className={cx('select_process_title_box')}>
                  <h4 className={cx('select_process_title')}>상품선택</h4>
                </div>
              </div>
              <div className={cx('select_process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>견적요청</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>견적검토</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>설치확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>유저정보</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>결제방식</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_status_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>계약현황 확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>계약서 작성</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>입금 확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>설치 스케줄</h4>
                </div>
              </div>
            </div>
            <div className={cx('mobile_header_inner')}>
              <div className={cx('mobile_header_progressbar_box')}>
                <div className={cx('mobile_header_progressbar')}></div>
              </div>
              <div className={cx('select_process_box')}>
                <div className={cx('mobile_select_text_box')}>
                  <p className={cx('mobile_select_text')}>step.1</p>
                </div>
                <div className={cx('mobile_select_process_title_box')}>
                  <div className={cx('select_process_title_box')}>
                    <h4 className={cx('select_process_title')}>상품선택</h4>
                  </div>
                  <div className={cx('select_process_img_box')}>
                    <img
                      src="/statics/images/solution_select.png"
                      alt="solution_select"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <CancelSolutionButton />
            <div
              className={cx('checklist_button')}
              onClick={() => setButtonPopup(true)}
            >
              <div className={cx('checklist_icon_box')}>
                <img
                  className={cx('checklist_icon')}
                  src="/statics/images/check_list.png"
                  alt="check_list"
                />
              </div>
              <div className={cx('hide_text_box')}>
                <p className={cx('hide_text')}>체크리스트 보기</p>
              </div>
            </div>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>하드웨어 선택</h4>
            </div>
            <div className={cx('hardware_inner')}>
              <div
                onClick={() =>
                  setActive((prev) => ({
                    ...prev,
                    led: !prev.led,
                    beam: false,
                  }))
                }
                className={cx(active.led ? 'active_choice_box' : 'choice_box')}
              >
                <img
                  className={cx('choice_img')}
                  src="/statics/images/choice_img.png"
                  alt="choice_img"
                />
                <img
                  className={cx('solution_img')}
                  src="/statics/images/solution_led.png"
                  alt="solution_led"
                />
                <div className={cx('choice_title_box')}>
                  <h4 className={cx('choice_title')}>LED</h4>
                </div>
                <div className={cx('choice_text_box')}>
                  <p className={cx('choice_text')}>
                    LED 패널을 통하여 영상을 재생
                  </p>
                </div>
              </div>
              <div
                onClick={() =>
                  setActive((prev) => ({
                    ...prev,
                    beam: !prev.beam,
                    led: false,
                  }))
                }
                className={cx(active.beam ? 'active_choice_box' : 'choice_box')}
              >
                <img
                  className={cx('choice_img')}
                  src="/statics/images/choice_img.png"
                  alt="choice_img"
                />
                <img
                  className={cx('solution_img')}
                  src="/statics/images/img_intro_hardware_second.png"
                  alt="img_intro_hardware_second"
                />
                <div className={cx('choice_title_box')}>
                  <h4 className={cx('choice_title')}>빔 프로젝터</h4>
                </div>
                <div className={cx('choice_text_box')}>
                  <p className={cx('choice_text')}>
                    빛을 벽면에 비추어 영상을 재생
                  </p>
                </div>
              </div>
            </div>
            <div className={cx('button_box')}>
              <button
                className={cx('next_button')}
                onClick={() => onSubmit(active)}
              >
                다음 단계로 이동
              </button>
            </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} />
          </div>
        </div>
      </div>
    </div>
  );
}
