import React, {
  useCallback,
  useEffect,
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import * as api from 'apis';
import useAuth from 'hooks/useAuth';
import {
  handleCheckSolution,
} from 'libs/authService';
import SolutionInstallSuccessWrapper from 'components/SolutionInstallSuccessWrapper';
import useCompany from 'hooks/useCompany';

export default function SolutionInstallSuccessContainer() {
  const { phone } = useCompany();

  const navigator = useNavigate();
  const {
    isLogIn,
  } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })])

        handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
      } catch (err) {
        console.log(err);
      }
    })()
  }, [isLogIn, navigator]);
  return <SolutionInstallSuccessWrapper phone={phone} />;
}
