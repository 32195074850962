import React, {
  useCallback,
} from 'react';
import {
  useSelector,
  useDispatch,
} from "react-redux";
import { changeData, resetSingupData } from 'store/modules/signup';

export default function useSignup() {
  const signup = useSelector(state => state.signup);
  const dispatch = useDispatch();

  const handleChangeData = useCallback((payload) => dispatch(changeData(payload)), [dispatch]);
  const handleResetSignupData = useCallback(() => dispatch(resetSingupData()), [dispatch]);

  return {
    signup,
    handleChangeData,
    handleResetSignupData,
  }
};