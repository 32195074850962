import React from 'react'
import classNames from 'classnames/bind';
import styles from './IntroduceCompanyContent.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';

const cx = classNames.bind(styles);

export default function IntroduceCompanyContent() {

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <div {...useScrollFadeIn('up', 1, 0)} className={cx('content_box')}>
                        <div className={cx('sub_title_box')}>
                            <p className={cx('sub_title')}>
                                Partners
                            </p>
                        </div>
                        <div {...useScrollFadeIn('up', 1, 0.2)} className={cx('title_box')}>
                            <h4 className={cx('title')}>
                                수많은 기업 및 <br className={cx('mobile_br')} /> 공공기관들과 <br />
                                함께 신뢰를 나눕니다.
                            </h4>
                        </div>
                    </div>
                </div>
                <div className={cx('list_inner')}>
                    <div {...useScrollFadeIn('left', 1, 0.3)} className={cx('list_top_box')}>
                        <div className={cx('list_box')}>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/gallery.png" alt="gallery" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/istern.png" alt="istern" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/chahong.png" alt="chahong" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/samsung.png" alt="samsung" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/jeju.png" alt="jeju" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/tourism.png" alt="tourism" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/heuer.png" alt="heuer" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/bonte.png" alt="bonte" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/elle.png" alt="elle" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/contents.png" alt="contents" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/culture.png" alt="culture" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/jejusee.png" alt="jejusee" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/cj.png" alt="cj" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/culturalheritage.png" alt="culturalheritage" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/lotte.png" alt="lotte" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/jtbc.png" alt="jtbc" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/house.png" alt="house" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/honda.png" alt="honda" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/glo.png" alt="glo" />
                            </div>
                        </div>
                    </div>
                    <div {...useScrollFadeIn('right', 1, 0.3)} className={cx('list_bottom_box')}>
                        <div className={cx('bottom_list_box')}>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/glo.png" alt="glo" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/honda.png" alt="honda" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/house.png" alt="house" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/jtbc.png" alt="jtbc" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/lotte.png" alt="lotte" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/culturalheritage.png" alt="culturalheritage" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/cj.png" alt="cj" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/jejusee.png" alt="jejusee" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/culture.png" alt="culture" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/contents.png" alt="contents" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/elle.png" alt="elle" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/bonte.png" alt="bonte" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/heuer.png" alt="heuer" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/tourism.png" alt="tourism" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/jeju.png" alt="jeju" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/samsung.png" alt="samsung" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/chahong.png" alt="chahong" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/istern.png" alt="istern" />
                            </div>
                            <div className={cx('list_img_box')}>
                                <img className={cx('list_img')} src="/statics/images/gallery.png" alt="gallery" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
