import React from 'react';
import {
    Login,
    Header,
} from '../views';


export default function LoginWrapper({
    email,
    password,
    isError,
    isEmailError,
    onChange,
    onClick,
    handleResend,
    handleKeypress,
}) {
    return (
        <>
            <Header type='white' />
            <Login
                email={email}
                password={password}
                isError={isError}
                isEmailError={isEmailError}
                handleResend={handleResend}
                onChange={onChange}
                onClick={onClick}
                handleKeypress={handleKeypress}
            />
        </>
    )
}
