import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionRequestEstimateLed from 'components/views/SolutionRequestEstimateLed/SolutionRequestEstimateLed';

export default function SolutionRequestEstimateLedWrapper({
    data,
    onChange,
    onSubmit,
}) {
    return (
        <>
            <Header />
            <SolutionRequestEstimateLed
                data={data}
                onChange={onChange}
                onSubmit={onSubmit}
            />
        </>
    )
}
