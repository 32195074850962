import React, { useEffect, useRef, useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './SevenCheckList.module.scss';

const cx = classNames.bind(styles);

export default function SevenCheckList() {
  return (
    <div>
      <div className={cx('inner_box')}>
        <div className={cx('title_box')}>
          <h4 className={cx('title')}>투사 거리 확보</h4>
        </div>
        <div className={cx('text_box')}>
          <p className={cx('text')}>
            최소 3.1m 이상 거리를 확보해야 하며, 에어컨, 공조시설이 없어야
            합니다.
          </p>
        </div>
        <div className={cx('img_box')}>
          <img
            className={cx('img')}
            src="/statics/images/beam_distance.png"
            alt="checklist_throw_distance"
          />
        </div>
      </div>
      <div className={cx('inner_box')}>
        <div className={cx('title_box')}>
          <h4 className={cx('title')}>전기 배선 필요</h4>
        </div>
        <div className={cx('text_box')}>
          <p className={cx('text')}>
            필요 거리는 1.5m 이내이며, 필요 구수는 2구 220V 입니다.
          </p>
        </div>
        <div className={cx('img_box')}>
          <img
            className={cx('img')}
            src="/statics/images/electrical_wiring_distance.png"
            alt="checklist_electrical_wiring"
          />
        </div>
      </div>
      <div className={cx('caution_inner')}>
        <div className={cx('caution_img_box')}>
          <img
            src="/statics/images/ic_input_info_error.png"
            alt="ic_input_info_error"
          />
        </div>
        <div className={cx('caution_text_box')}>
          <p className={cx('caution_text')}>
            투사 벽면과 천장 설치 위치에는 방해 요소 (배관, 골조 등)이 없어야
            합니다.
            {/* 빔프로젝터 배치 위치는 벽면으로부터 <b className={cx('caution_text_b')}>최소 10m 정도의 거리가 확보</b>되어야 하며, 투사 벽면과 천장 설치 위치에는 방해요소 (배관, 골조 등)가 없어야 합니다. */}
          </p>
        </div>
      </div>
    </div>
  );
}
