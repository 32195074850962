import React, { useState, useRef, useEffect, useCallback } from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';
import styles from './MypagAsDetail.module.scss';
import { Link } from 'react-router-dom';
import FileReader from 'components/common/FileReader';

const cx = classNames.bind(styles);

export default function MypagAsDetail({ data }) {
  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('inner')}>
          <Link to="/mypage/as" className={cx('back_box')}>
            <img
              className={cx('back_img')}
              src="/statics/images/ic_profile_back.png"
              alt="ic_profile_back"
            />
            <div className={cx('back_text_box')}>
              <p className={cx('back_text')}>A/S 신청 현황</p>
            </div>
          </Link>
          <div className={cx('inner_box')}>
            <div className={cx('category_box')}>
              <p className={cx('category')}>{data?.category}</p>
              <div className={cx('date_box')}>
                <p className={cx('date')}>
                  {`요청 날짜 : ${moment(data?.createdAt).format(
                    'YYYY-MM-DD HH:mm',
                  )}`}
                </p>
              </div>
            </div>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>{data?.content}</h4>
            </div>
            <div className={cx('file_upload_box')}>
              {data?.files?.length > 0 && data?.files?.map((url, i) => (
                <FileReader
                  key={`${url}-${i}`}
                  url={url}
                />
              ))}
            </div>
            <hr className={cx('hr')} />
            <div className={cx('process_box')}>
              {data?.status === 'READY' ? (
                <>
                  <div className={cx('select_process_text_box')}>
                    <p className={cx('select_process_text')}>접수 대기</p>
                  </div>
                  <img
                    className={cx('process_img')}
                    src="/statics/images/ic_as_arrow_forward_on.png"
                    alt="ic_as_arrow_forward_on"
                  />
                </>
              ) : (
                  <>
                    <div className={cx('process_text_box')}>
                      <p className={cx('process_text')}>접수 대기</p>
                    </div>
                    <img
                      className={cx('process_img')}
                      src="/statics/images/ic_as_arrow_forward_off.png"
                      alt="ic_as_arrow_forward_off"
                    />
                  </>
                )}
              {data?.status === 'DONE' ? (
                <>
                  <div className={cx('select_process_text_box')}>
                    <p className={cx('select_process_text')}>접수 완료</p>
                  </div>
                </>
              ) : (
                  <>
                    <div className={cx('process_text_box')}>
                      <p className={cx('process_text')}>접수 완료</p>
                    </div>
                  </>
                )}
              {/* <div className={cx('select_process_text_box')}>
                                <p className={cx('select_process_text')}>
                                    담당자 배정
                                </p>
                            </div> */}
              {/* <img className={cx('process_img')} src="/statics/images/ic_as_arrow_forward_on.png" alt="ic_as_arrow_forward_on" />
                            <div className={cx('process_text_box')}>
                                <p className={cx('process_text')}>
                                    접수 완료
                                </p>
                            </div> */}
            </div>
            <div className={cx('content_box')}>
              <p className={cx('content_text')}>{data?.response}</p>
            </div>
            {data?.status === 'DONE' && data?.visitDate && data?.managerName && (
              <div className={cx('engineer_inner')}>
                <div className={cx('engineer_box')}>
                  <div className={cx('engineer_profile_img_box')}>
                    <img
                      className={cx('engineer_profile_img')}
                      src={
                        data?.managerProfileImageUrl
                          ? data?.managerProfileImageUrl
                          : '/statics/images/profile.png'
                      }
                      alt="profile"
                    />
                  </div>
                  <div className={cx('profile_name_box')}>
                    <p className={cx('profile_name')}>
                      {data?.managerName || '-'}
                    </p>
                    <p className={cx('profile_tel')}>
                      {data?.managerPhone || '-'}
                    </p>
                  </div>
                </div>
                <div className={cx('engineer_vist_box')}>
                  <p className={cx('engineer_vist')}>
                    {data?.visitDate ? (
                      <>
                        {moment(data?.visitDate).format('MM')}월{' '}
                        {moment(data?.visitDate).format('DD')}일 방문 예정
                      </>
                    ) : (
                        <>미정</>
                      )}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
