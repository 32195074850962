import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import MypaAsInquiry from 'components/views/MypaAsInquiry/MypaAsInquiry';

export default function MypaAsInquiryWrapper({
    category,
    newCategory,
    content,
    onChange,
    onSubmit,
    fileUrls,
    handleFileUrls,
}) {
    return (
        <>
            <Header />
            <MypaAsInquiry
                category={category}
                newCategory={newCategory}
                content={content}
                onChange={onChange}
                onSubmit={onSubmit}
                fileUrls={fileUrls}
                handleFileUrls={handleFileUrls}
            />
            <Footer />
        </>
    )
}
