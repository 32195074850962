import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import * as api from 'apis';
import { regPhone } from 'constants/regExp';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import MypageEditWrapper from 'components/MypageEditWrapper/MypageEditWrapper';

export default function MypageEditContainer() {
  const navigate = useNavigate();
  const [isInit, setIsInit] = useState(false);
  const {
    isLogIn,
    name,
    phone,
    profileImageUrl,
    birthday,
    businessAddress,
    businessDetailAddress,
    businessBusyHour,
    businessCategory,
    businessSubCategory,
    businessCloseTime,
    businessCustomerAge,
    businessDailyVisitor,
    businessMaxSeat,
    businessName,
    businessNumber,
    businessOpenTime,
    businessTimeMemo,
    email,
    gender,
    dayoff,
    handleChangeAuthData,
    isTempBlock,
  } = useAuth();

  const [data, setData] = useState({
    name: '',
    phone: '',
    profileImageUrl: '',
    birthday: '',
    businessAddress: '',
    businessDetailAddress: '',
    businessBusyHour: '',
    businessBusyHourOpen: '',
    businessBusyHourClose: '',
    businessCategory: '',
    businessSubCategory: '',
    businessCloseTime: '',
    businessCustomerAge: '',
    businessDailyVisitor: '',
    businessMaxSeat: '',
    businessName: '',
    businessNumber: '',
    businessOpenTime: '',
    businessTimeMemo: '',
    email: '',
    gender: '',
    isPhoneVerified: true,
    isBusinessVerified: true,
    isSendCode: false,
    code: '',
    dayoff: [],
  });

  const [isOpenPost, setIsOpenPost] = useState(false);
  const [isOtherError, setOtherError] = useState({
    phone: false,
    business: false,
    expired: false,
    code: false,
  });
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const timerRef = useRef(null);

  const handleChange = useCallback(
    (e) => {
      if (e.target.name === 'phone') {
        setData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
          isPhoneVerified: false,
          isSendCode: false,
          code: '',
        }));
        setOtherError((prev) => ({
          ...prev,
          phone: false,
          expired: false,
          code: false,
        }));
      } else if (e.target.name === 'businessNumber') {
        setData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
          isBusinessVerified: false,
        }));
        setOtherError((prev) => ({
          ...prev,
          business: false,
        }));
      } else if (e.target.name === 'businessCategory') {
        setData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
          businessSubCategory: '',
        }));
      } else {
        setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    },
    [setData, setOtherError],
  );

  const handleChangeDate = useCallback(
    (e) => {
      const d = moment(new Date()).format('YYYY-MM-DD');
      if (e.target.name === 'businessOpenTime') {
        const openTime = moment(`${d} ${e.target.value}`);
        const closeTime = data?.businessCloseTime
          ? moment(`${d} ${data?.businessCloseTime}`)
          : null;
        const isSame = closeTime && openTime.isSame(closeTime);
        if (isSame) return alert('종료 운영시간과 다르게 설정해주세요 : )');

        setData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
      }

      if (e.target.name === 'businessCloseTime') {
        const openTime = data?.businessOpenTime
          ? moment(`${d} ${data?.businessOpenTime}`)
          : null;
        const closeTime = moment(`${d} ${e.target.value}`);
        const isSame = openTime && closeTime.isSame(openTime);
        if (isSame) return alert('시작 운영시간과 다르게 설정해주세요 : )');

        setData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
      }
    },
    [setData, data?.businessOpenTime, data?.businessCloseTime],
  );

  const handleOpenPost = useCallback(() => {
    setIsOpenPost(true);
  }, [setIsOpenPost]);

  const handleClosePost = useCallback(() => {
    setIsOpenPost(false);
  }, [setIsOpenPost]);

  const handleVerifiedPhone = useCallback(async () => {
    if (!regPhone.test(data.phone)) {
      setOtherError((prev) => ({ ...prev, phone: true }));
      return;
    }
    setData((prev) => ({ ...prev, isSendCode: true }));
    setOtherError((prev) => ({
      ...prev,
      phone: false,
      expired: false,
      code: false,
    }));
    setStartTime(new Date().getTime());
    setEndTime(new Date().getTime() + 1000 * 60 * 3);
    try {
      const res = await api.sendPhoneCode(data.phone);
      setData((prev) => ({ ...prev, isSendCode: true }));
    } catch (err) {
      console.log(err);
    }
  }, [data.phone, setData, setOtherError, setStartTime, setEndTime]);

  const handleCode = useCallback(async () => {
    try {
      const res = await api.checkEmailOrPhone(data.phone, data.code);
      if (res?.data?.isSuccess) {
        setData((prev) => ({ ...prev, isPhoneVerified: true }));
      } else {
        setOtherError((prev) => ({
          ...prev,
          phone: false,
          expired: false,
          code: true,
        }));
        setData((prev) => ({
          ...prev,
          isPhoneVerified: false,
          isSendCode: false,
          code: '',
        }));
      }
      clearInterval(timerRef.current);
      timerRef.current = null;
    } catch (err) {
      setOtherError((prev) => ({
        ...prev,
        code: true,
        expired: false,
        phone: false,
      }));
      setData((prev) => ({
        ...prev,
        isPhoneVerified: false,
        isSendCode: false,
        code: '',
      }));
      clearInterval(timerRef.current);
      timerRef.current = null;
      console.log(err);
    }
  }, [data.phone, data.code, setOtherError, setData]);

  const handleCheckBusinessNumber = useCallback(async () => {
    try {
      const res = await api.checkBusinessNumber(data.businessNumber);
      if (res?.data?.isValid) {
        setData((prev) => ({ ...prev, isBusinessVerified: true }));
      } else {
        setData((prev) => ({ ...prev, isBusinessVerified: false }));
        setOtherError((prev) => ({
          ...prev,
          business: true,
        }));
      }
    } catch (err) {
      setData((prev) => ({ ...prev, isBusinessVerified: false }));
      setOtherError((prev) => ({
        ...prev,
        business: true,
      }));
      console.log(err);
    }
  }, [data.businessNumber, setData, setOtherError]);

  const handleEditPhone = useCallback(() => {
    if (window.confirm('수정하시겠습니까?')) {
      setData((prev) => ({
        ...prev,
        phone: '',
        isPhoneVerified: false,
        isSendCode: false,
        code: '',
      }));
    }
  }, [setData]);

  const handleClickAddress = useCallback(
    (zonecode, address) => {
      setData((prev) => ({
        ...prev,
        businessAddress: address,
        businessDetailAddress: '',
      }));
    },
    [setData],
  );

  const handleClickGender = useCallback(
    (gender) => {
      setData((prev) => ({ ...prev, gender }));
    },
    [setData],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const {
        name,
        phone,
        birthday,
        businessAddress,
        businessDetailAddress,
        businessBusyHour,
        businessBusyHourOpen,
        businessBusyHourClose,
        businessCategory,
        businessSubCategory,
        businessCloseTime,
        businessCustomerAge,
        businessDailyVisitor,
        businessMaxSeat,
        businessName,
        businessNumber,
        businessOpenTime,
        businessTimeMemo,
        email,
        gender,
        dayoff,
      } = data;

      const res = await api.updateMyInfo({
        name,
        phone,
        birthday,
        businessAddress,
        businessDetailAddress,
        businessBusyHour: `${businessBusyHourOpen} ~ ${businessBusyHourClose}`,
        businessCategory,
        businessSubCategory,
        businessCloseTime,
        businessCustomerAge,
        businessDailyVisitor,
        businessMaxSeat,
        businessName,
        businessNumber,
        businessOpenTime,
        businessTimeMemo,
        email,
        gender,
        dayoff,
      });

      alert('정보수정 변경완료 : )');
      navigate('/mypage');
    } catch (err) {
      console.log(err);
    }
  }, [data]);

  useEffect(() => {
    if (!data.isSendCode) {
      return;
    }

    if (startTime > endTime) {
      clearInterval(timerRef.current);
      timerRef.current = null;
      setData((prev) => ({
        ...prev,
        isPhoneVerified: false,
        isSendCode: false,
        code: '',
      }));
      setOtherError((prev) => ({
        ...prev,
        expired: true,
      }));
      return;
    }

    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    // setInterval 사용부
    timerRef.current = setInterval(() => {
      setStartTime(new Date().getTime());
    }, 1000);
  }, [timerRef, data.isSendCode, startTime, endTime, setOtherError, setData]);

  useEffect(() => {
    const busyHour = businessBusyHour?.split(' ~ ');
    setData((prev) => ({
      ...prev,
      name,
      phone,
      profileImageUrl,
      birthday,
      businessAddress,
      businessDetailAddress,
      businessBusyHour,
      businessBusyHourOpen: busyHour?.[0] || '',
      businessBusyHourClose: busyHour?.[1] || '',
      businessCategory,
      businessSubCategory,
      businessCloseTime,
      businessCustomerAge,
      businessDailyVisitor,
      businessMaxSeat,
      businessName,
      businessNumber,
      businessOpenTime,
      businessTimeMemo,
      email,
      gender,
      dayoff,
    }));
  }, [
    name,
    phone,
    profileImageUrl,
    birthday,
    businessAddress,
    businessDetailAddress,
    businessBusyHour,
    businessCategory,
    businessSubCategory,
    businessCloseTime,
    businessCustomerAge,
    businessDailyVisitor,
    businessMaxSeat,
    businessName,
    businessNumber,
    businessOpenTime,
    businessTimeMemo,
    email,
    gender,
    dayoff,
  ]);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (isInit) return;
    (async () => {
      try {
        const res = await api.getBusinessCategories();
        setCategories(res?.data?.value?.data);
        setIsInit(true);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [isInit]);

  const handleWeeks = useCallback(
    (e) => {
      if (data?.dayoff.indexOf(e.target.value) > -1) {
        setData((prev) => ({
          ...prev,
          dayoff: data?.dayoff.filter((el) => el !== e.target.value),
        }));
      } else {
        setData((prev) => ({
          ...prev,
          dayoff: [...data?.dayoff, e.target.value],
        }));
      }
    },
    [data.dayoff],
  );

  return (
    <MypageEditWrapper
      name={data.name}
      phone={data.phone}
      profileImageUrl={data.profileImageUrl}
      birthday={data.birthday}
      businessAddress={data.businessAddress}
      businessDetailAddress={data.businessDetailAddress}
      businessBusyHour={data.businessBusyHour}
      businessBusyHourOpen={data.businessBusyHourOpen}
      businessBusyHourClose={data.businessBusyHourClose}
      businessCategory={data.businessCategory}
      businessSubCategory={data.businessSubCategory}
      businessCloseTime={data.businessCloseTime}
      businessCustomerAge={data.businessCustomerAge}
      businessDailyVisitor={data.businessDailyVisitor}
      businessMaxSeat={data.businessMaxSeat}
      businessName={data.businessName}
      businessNumber={data.businessNumber}
      businessOpenTime={data.businessOpenTime}
      businessTimeMemo={data.businessTimeMemo}
      isBusinessVerified={data.isBusinessVerified}
      email={data.email}
      gender={data.gender}
      isSendCode={data.isSendCode}
      code={data.code}
      categories={categories}
      isOpenPost={isOpenPost}
      handleOpenPost={handleOpenPost}
      handleClosePost={handleClosePost}
      isPhoneVerified={data.isPhoneVerified}
      handleChange={handleChange}
      handleChangeDate={handleChangeDate}
      isPhoneError={isOtherError.phone}
      isBusinessError={isOtherError.business}
      isPhoneExpired={isOtherError.expired}
      isCodeError={isOtherError.code}
      handleCode={handleCode}
      handleVerifiedPhone={handleVerifiedPhone}
      startTime={startTime}
      endTime={endTime}
      handleEditPhone={handleEditPhone}
      handleClickAddress={handleClickAddress}
      handleCheckBusinessNumber={handleCheckBusinessNumber}
      handleClickGender={handleClickGender}
      handleSubmit={handleSubmit}
      dayoff={data.dayoff}
      handleWeeks={handleWeeks}
      isTempBlock={isTempBlock}
    />
  );
}
