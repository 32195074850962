import React from 'react';
import classNames from 'classnames/bind';
import styles from './SubFooter.module.scss';
import { useNavigate } from 'react-router-dom';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function SubFooter({
    handleSolution,
}) {

    const navigator = useNavigate()

    // const serviceNavigator = () => {
    //     navigator('/signup');
    //     window.scrollTo(0, 0)
    // }

    return (
        <div className={cx('container')}>
            <div
                className={cx('bg')}
            // style={{
            //     backgroundImage: "url(/statics/images/sub_footer.png)",
            //     backgroundRepeat: "no-repeat",
            //     backgroundSize: "cover",
            //     backgroundPosition: "center"
            // }}
            >
                <div {...useScrollClipPath('up', 1, 0.4)} className={cx('box_img')}>
                    <img src="/statics/images/sub_footer.png" alt="" />
                </div>
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <div {...useScrollFadeIn('up', 1, 0.8)} className={cx('title_box')}>
                            <h5 className={cx('title')}>
                                인테리어를 PLAY 하는<br className={cx('mobile_br')} /> 새로운 방법
                            </h5>
                        </div>
                        <div {...useScrollFadeIn('bottom', 1, 1.2)} className={cx('button_box')}>
                            <button className={cx('button')} onClick={handleSolution}>
                                간편 상담 신청
                                <img className={cx('button_arrow')} src="/statics/images/main_start_arrow.png" alt="start_arrow" />
                                <img className={cx('hover_button_arrow')} src="/statics/images/main_hover_start_arrow.png" alt="start_arrow" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
