import React from 'react';
import { Footer, Header } from 'components/views';
import AdvertisementReservationExpired from 'components/views/AdvertisementReservationExpired';

export default function AdvertisementReservationExpiredWrapper() {
  return (
    <>
      <Header />
      <AdvertisementReservationExpired />
      <Footer />
    </>
  );
}
