import _ from 'lodash/fp';
import * as Sentry from '@sentry/react';

const checkAuthPlay = async (estimate, contract) => {
  try {
    const scope = new Sentry.Scope();
    scope.setTag('playAuthService', 'playAuthServiceError');

    scope.setContext('playAuthServiceContext', {
      estimate,
      contract,
    });

    Sentry.captureException(estimate, () => scope);
  } catch (err) {
    const scope = new Sentry.Scope();
    scope.setTag('playAuthServiceError', 'playAuthServiceError');

    scope.setContext('playAuthServiceContext', {
      estimate,
      contract,
    });

    Sentry.captureException(err, () => scope);
  }
};

export const handleSolution = async (
  isLogIn,
  estimate,
  contract,
  navigator,
) => {
  // if (!isLogIn) {
  //   alert('솔루션 신청을 위해 로그인이 필요합니다 : )');
  //   navigator('/login');
  //   return;
  // }
  navigator('/solution');

  // switch (estimate?.status) {
  //   case 'READY':
  //     navigator('/solution/choice-product');
  //     break;
  //   case 'READY_LED_SIZE_DONE':
  //     navigator('/solution/request-estimate/led');
  //     break;
  //   case 'READY_BEAM_SIZE_DONE':
  //     navigator('/solution/request-estimate/beam');
  //     break;
  //   case 'READY_ADDRESS_DONE':
  //     navigator('/solution/request-estimate/place');
  //     break;
  //   case 'READY_SOLUTION_TYPE_DONE':
  //     navigator('/solution/request-estimate/solution');
  //     break;
  //   case 'PENDING_CHECK_ADMIN':
  //     navigator('/solution/install/request');
  //     break;
  //   case 'ADMIN_REJECTED':
  //     navigator('/solution/install/fail');
  //     break;
  //   case 'ADMIN_CONFIRM':
  //     navigator('/solution/install/success');
  //     break;
  //   case 'SUBMIT':
  //     navigator('/solution/publish-estimate/wait');
  //     break;
  //   case 'CONFIRM':
  //     if (contract?.paymentType === 'ONCE') {
  //       if (contract?.status === 'READY') {
  //         navigator('/solution/contract-waiting');
  //       } else if (contract?.status === 'UPLOADED') {
  //         if (contract?.clientSignPdfUrl) {
  //           navigator('/solution/deposit-wait');
  //         } else {
  //           navigator('/solution/contract-complete');
  //         }
  //       } else if (contract?.status === 'MONEY_CHECKED') {
  //         navigator('/solution/deposit-complete');
  //       } else if (contract?.status === 'SCHEDULED') {
  //         navigator('/solution/schedule-complete');
  //       } else if (contract?.status === 'FINISH') {
  //         navigator('/solution/success');
  //       } else {
  //         navigator('/solution/publish-estimate/payment');
  //       }
  //     } else if (contract?.paymentType === 'RENTAL') {
  //       if (contract?.status === 'READY') {
  //         navigator('/solution/rental-info');
  //       } else if (contract?.status === 'RENTAL_READY') {
  //         navigator('/solution/rental-wait');
  //       } else if (contract?.status === 'RENTAL_CHECKED') {
  //         navigator('/solution/contract-waiting');
  //       } else if (contract?.status === 'UPLOADED') {
  //         if (contract?.clientSignPdfUrl) {
  //           navigator('/solution/deposit-wait');
  //         } else {
  //           navigator('/solution/contract-complete');
  //         }
  //       } else if (contract?.status === 'MONEY_CHECKED') {
  //         navigator('/solution/deposit-complete');
  //       } else if (contract?.status === 'SCHEDULED') {
  //         navigator('/solution/schedule-complete');
  //       } else if (contract?.status === 'FINISH') {
  //         navigator('/solution/success');
  //       } else {
  //         navigator('/solution/publish-estimate/payment');
  //       }
  //     } else {
  //       navigator('/solution/publish-estimate/payment');
  //     }
  //     break;
  //   default:
  //     navigator('/solution/choice-product');
  //     break;
  // }
};

export const handleCheckSolution = async (
  isLogIn,
  estimate,
  contract,
  navigator,
) => {
  if (!isLogIn) {
    alert('솔루션 신청을 위해 로그인이 필요합니다 : )');
    navigator('/login');
    return;
  }

  if (!estimate?.status) {
    navigator('/solution/choice-product');
    return;
  }

  switch (estimate?.status) {
    case 'CONFIRM':
      if (contract?.status === 'FINISH') {
        navigator('/solution/success');
      }
      break;
    default:
      break;
  }
};

export const handlePlay = (
  isLogIn,
  estimate,
  contract,
  navigator,
  isUnlimitedPlaylist,
) => {
  if (!isLogIn) {
    alert('상영할 준비가 되셨나요? 콘텐츠 플랫폼 구독 및 로그인 후 이용 가능합니다');
    // alert('콘텐츠 플레이는 로그인이 필요한 서비스입니다 : )');
    navigator('/login');
    return;
  }

  if (isUnlimitedPlaylist) return navigator('/play-list/search');

  if (estimate && estimate.status === 'CONFIRM') {
    if (
      contract &&
      (contract.status === 'FINISH' || contract.status === 'SCHEDULED')
    ) {
      if (estimate.solutionType === 'PREMIUM') {
        navigator('/play-list/search');
      } else if (estimate.solutionType === 'STANDARD') {
        navigator('/play-list/search');
      } else if (estimate.solutionType === 'BASIC') {
        navigator('/play-list/search');
      } else {
        checkAuthPlay(estimate, contract);
        alert('상영할 준비가 되셨나요? 콘텐츠 플랫폼 구독 및 로그인 후 이용 가능합니다');
        // alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
      }
    } else {
      checkAuthPlay(estimate, contract);
      alert('상영할 준비가 되셨나요? 콘텐츠 플랫폼 구독 및 로그인 후 이용 가능합니다');
      // alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
    }
  } else {
    checkAuthPlay(estimate, contract);
    alert('상영할 준비가 되셨나요? 콘텐츠 플랫폼 구독 및 로그인 후 이용 가능합니다');
    // alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
  }
};

export const handleAdvertisement = (navigator) => {
  navigator('/advertisement');
};
