import axios from 'axios';

const getApiUrl = () => 'https://api.beammonster.com/api';
// dev 'https://dev-api.beammonster.com/api';
// prod 'https://api.beammonster.com/api';

// 로그인
export const login = (email, password) =>
  axios.post(`${getApiUrl()}/auth/login`, { email, password });

// 회원가입
export const signup = (data) =>
  axios.post(`${getApiUrl()}/auth/register`, { ...data });

// 회원정보 수정
export const updateMyInfo = (data) =>
  axios.patch(`${getApiUrl()}/auth/me`, data);

// 회원탈퇴
export const deleteMe = () => axios.delete(`${getApiUrl()}/auth/me`);

// 로그인 인증 체크
export const checkAuth = () => axios.get(`${getApiUrl()}/auth/me`);

// 이메일 중복체크
export const checkEmailDuplicated = (email) =>
  axios.get(`${getApiUrl()}/auth/validation/email?email=${email}`);

// 핸드폰 인증코드 발송
export const sendPhoneCode = (phone) =>
  axios.post(`${getApiUrl()}/auth/verification/phone`, { phone });

// 휴대폰 또는 이메일 검증
export const checkEmailOrPhone = (emailOrPhone, authCode) =>
  axios.post(`${getApiUrl()}/auth/verification`, {
    emailOrPhone,
    authCode,
  });

// 사업자 번호 검증
export const checkBusinessNumber = (businessNumber) =>
  axios.get(`${getApiUrl()}/auth/validation/business-number`, {
    params: {
      businessNumber,
    },
  });

// 비밀번호 찾기 이메일 전송
export const resetPassword = (email) =>
  axios.post(`${getApiUrl()}/auth/reset-password`, { email });

// 회사 정보조회
export const getCompanyInfo = () =>
  axios.get(`${getApiUrl()}/service-statics/company`);

// 자주 묻는 질문 조회
export const getFaqs = ({ search, limit, page, category }) =>
  axios.get(`${getApiUrl()}/service-statics/faqs`, {
    params: {
      search,
      limit,
      page,
      category,
    },
  });

// 파일 업로드
export const uploadFiles = (files) =>
  axios.post(`${getApiUrl()}/utils/upload-files`, files);

// 비메오 업로드
export const uploadViemo = (files) =>
  axios.post(`${getApiUrl()}/utils/create-vimeo-video`, files, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

// 비메오 링크
export const getVimeoLink = (id) =>
  axios.get(`${getApiUrl()}/utils/playable-vimeo-link?url=${id}`);

// 문의 생성
export const postInquiry = (data) =>
  axios.post(`${getApiUrl()}/contacts`, data);

// 메인페이지 데이터
export const getMainPageInfo = () =>
  axios.get(`${getApiUrl()}/service-statics/mainpage`);

// 서비스 이용 사례 조회
export const getServiceUsage = ({ limit, page, search, location }) =>
  axios.get(`${getApiUrl()}/service-statics/service-usages`, {
    params: {
      limit,
      page,
      search,
      location,
    },
  });

// a/s 서비스 조회
export const getAfterServiceList = ({ limit, page, search }) =>
  axios.get(`${getApiUrl()}/after-services`, {
    params: {
      limit,
      page,
      search,
    },
  });

// a/s 서비스 단건 조회
export const getAfterService = (id) =>
  axios.get(`${getApiUrl()}/after-services/${id}`);

// a/s 서비스 작성
export const postAfterServiceList = (data) =>
  axios.post(`${getApiUrl()}/after-services`, data);

// 솔루션 구매 시작
export const postEstimate = (data) =>
  axios.post(`${getApiUrl()}/estimates`, data);

// 솔루션 구매 내용 업데이트
export const updateEstimate = (estimateId, data) =>
  axios.patch(`${getApiUrl()}/estimates/${estimateId}`, data);

// 나의 견적서 조회
export const getMyEstimate = () => axios.get(`${getApiUrl()}/estimates/me`);

// 견적서 어드민 체크 요청
export const requestAdminConfirm = (estimateId) =>
  axios.post(`${getApiUrl()}/estimates/${estimateId}/request-admin-confirm`);

// 견적서 제출
export const requestEstimate = (estimateId) =>
  axios.post(`${getApiUrl()}/estimates/${estimateId}/submit`);

// 계약서 생성
export const requestContract = (data) =>
  axios.post(`${getApiUrl()}/contracts`, data);

// 나의 계약서 내용 조회
export const getMyContract = () => axios.get(`${getApiUrl()}/contracts/me`);

// 계약서 임시저장
export const updateContract = (contractId, data) =>
  axios.patch(`${getApiUrl()}/contracts/${contractId}`, data);

// 이메일 인증 다시보내기
export const resendEmail = (email) =>
  axios.post(`${getApiUrl()}/auth/verification/email`, { email });

// 콘텐츠 카테고리
export const getContentsCategories = (isCreatorContent) =>
  axios.get(`${getApiUrl()}/contents/categories`, {
    params: {
      isCreatorContent,
    },
  });

// 메인 콘텐츠 조회
export const getMainContents = (isCreator) =>
  axios.get(`${getApiUrl()}/contents/mainpage`, {
    params: {
      isCreator,
    },
  });

// 콘텐츠 조회
export const getContentList = ({
  creator_id,
  isCreatorContent,
  limit,
  page,
  search,
  category,
  orderBy,
}) =>
  axios.get(`${getApiUrl()}/contents`, {
    params: {
      creator_id,
      isCreatorContent,
      limit,
      page,
      search,
      category,
      orderBy,
    },
  });

// 콘텐츠 좋아요
export const likeContent = (contentId) =>
  axios.post(`${getApiUrl()}/contents/${contentId}/like`);

// 콘텐츠 좋아요 취소
export const cancelLikeContent = (contentId) =>
  axios.delete(`${getApiUrl()}/contents/${contentId}/like`);

// 콘텐츠 진짜 플레이 리스트
export const updateActualContent = (contentId) =>
  axios.put(`${getApiUrl()}/contents/my-playlist/actual-play/${contentId}`);

// 마이 콘텐츠 조회
export const getMyPlayList = (isCreatorContent) =>
  axios.get(`${getApiUrl()}/contents/my-playlist`, {
    params: {
      isCreatorContent,
    },
  });

export const getMyAllPlayList = () =>
  axios.get(`${getApiUrl()}/contents/my-playlist`);

// 마이 콘텐츠 업데이트
export const postMyPlayList = (data) =>
  axios.post(`${getApiUrl()}/contents/my-playlist`, data);

// 마이 콘텐츠 수정
export const patchMyPlayList = (data) =>
  axios.patch(`${getApiUrl()}/contents/my-playlist`, data);

// 마이 콘텐츠 삭제
export const deleteMyPlayList = (content_ids) =>
  axios.delete(`${getApiUrl()}/contents/my-playlist`, {
    params: {
      content_ids,
    },
  });

// 콘텐츠 하나 조회
export const getContent = (contentId, useVimeoPlayerUrl) =>
  axios.get(
    `${getApiUrl()}/contents/${contentId}${
      useVimeoPlayerUrl ? '?useVimeoPlayerUrl=true' : ''
    }`,
  );

// 상품별 변경 횟수
export const getContentChangeCounts = () =>
  axios.get(`${getApiUrl()}/service-statics/content-change-counts`);

// 솔루션 구매 취소
export const cancelSolutionApply = (estimateId) =>
  axios.delete(`${getApiUrl()}/estimates/${estimateId}`);

// 광고 카테고리 조회
export const getAdvertiseCategories = () =>
  axios.get(`${getApiUrl()}/service-statics/advertise-categories`);

// 유저 비즈니스 카테고리 조회
export const getBusinessCategories = () =>
  axios.get(`${getApiUrl()}/service-statics/business-categories`);

// 지역별 스토어 갯수 조회
export const getStoresCountPerLocation = (businessCategories) =>
  axios.get(`${getApiUrl()}/advertises/stores/count-per-location`, {
    params: {
      businessCategories,
    },
  });

// 구매 가능한 스토어 조회
export const getAvailableAdvertiseStores = (
  locations,
  businessCategories,
  startDate,
  endDate,
  page,
) =>
  axios.get(`${getApiUrl()}/advertises/stores`, {
    params: {
      locations,
      businessCategories,
      startDate,
      endDate,
      page,
      limit: 12,
    },
  });

// 가격 산정
export const getAdvertisesPriceEstimate = (startDate, endDate, storeUser_ids) =>
  axios.get(`${getApiUrl()}/advertises/price-estimate`, {
    params: {
      startDate,
      endDate,
      storeUser_ids,
    },
  });

// 광고 결제 준비
export const postAdvertisesOrdersReady = (
  startDate,
  endDate,
  storeUser_ids,
  videoUrl,
  category,
  info,
) =>
  axios.post(`${getApiUrl()}/advertises/orders/ready`, {
    startDate,
    endDate,
    storeUser_ids,
    videoUrl,
    category,
    ...info,
  });

// 광고  PG 결제 확인
export const postAdvertisesOrdersConfirm = (imp_uid, advertiseOrder_id) =>
  axios.post(`${getApiUrl()}/advertises/orders/confirm`, {
    imp_uid,
    advertiseOrder_id,
  });

// 광고 주문 페이지네이션 조회
export const getAdvertisesOrders = (page, limit, search, status) =>
  axios.get(`${getApiUrl()}/advertises/orders`, {
    params: {
      page,
      limit,
      search,
      status,
    },
  });

// 주문 하나 조회
export const getAdvertiesOrder = (advertiseOrder_id) =>
  axios.get(`${getApiUrl()}/advertises/orders/${advertiseOrder_id}`);

// 광고 환불 신청
export const refundAdversiesOrder = (advertiseOrder_id, refundText) =>
  axios.post(`${getApiUrl()}/advertises/orders/${advertiseOrder_id}/refund`, {
    refundText,
  });

export const getCurrentTime = () => axios.get(`${getApiUrl()}/time`);

export const getAdvertisesToday = () =>
  axios.get(`${getApiUrl()}/advertises/today`);

// 작가 컨텐츠 결제 준비
export const postCreatorsOrdersReady = (content_ids, info) =>
  axios.post(`${getApiUrl()}/creators/orders/ready`, {
    content_ids,
    ...info,
  });

// 작가 컨텐츠 PG 결제 확인
export const postCreatorsOrdersConfirm = (imp_uid, creatorOrder_id) =>
  axios.post(`${getApiUrl()}/creators/orders/confirm`, {
    imp_uid,
    creatorOrder_id,
  });

// 작가 콘텐츠 페이지네이션 조회
export const getCreatorsOrders = (page, limit, search, status) =>
  axios.get(`${getApiUrl()}/creators/orders`, {
    params: {
      page,
      limit,
      search,
      status,
    },
  });

// 작가 콘텐츠 페이지네이션 조회 (새로운것)
export const getCreatorsArtistOrders = (page, limit) =>
  axios.get(`${getApiUrl()}/creators/orders/contents`, {
    params: {
      page,
      limit,
    },
  });

export const getAdvertiseLogs = (date) =>
  axios.get(`${getApiUrl()}/logs/advertises`, {
    params: {
      date,
    },
  });

export const postAdvertiseLogs = (slot, datetime) =>
  axios.post(`${getApiUrl()}/logs/advertises`, {
    slot,
    datetime,
  });

// 광고 송출 조회
export const getAdvertiseInfo = (date) =>
  axios.get(`${getApiUrl()}/service-statics/advertise`);
