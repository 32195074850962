import React, { useEffect, useState, useMemo } from 'react';
import * as api from 'apis';
import _ from 'lodash/fp';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { handleCheckSolution } from 'libs/authService';
import useContract from 'hooks/useContract';
import SolutionSealWrapper from 'components/SolutionSealWrapper';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const initState = {
  seal: null,
  tempSeal: null,
  sign: null,
  isConfirmed: false,
  xAxis: {},
  yAxis: {},
  checking: {},
  contract: null,
};

export default function SolutionSealContainer() {
  const navigator = useNavigate();
  const { isLogIn } = useAuth();
  const [data, setData] = useState(initState);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [buttonPopup, setButtonPopup] = useState({
    sign: false,
    seal: false,
  });
  const [pdfUrl, setPdfUrl] = useState(null);
  const {
    contractId,
    fileUrl,
    filename,
    handleChangeContractData,
    shouldSignImageIndex,
    pdfImageUrls,
  } = useContract();
  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([
          api.getMyEstimate().catch(() => { }),
          api.getMyContract().catch(() => { }),
        ]);
        handleChangeContractData({
          contractId: res[1]?.data?.id,
          fileUrl: res[1]?.data?.fileUrl,
          filename: res[1]?.data?.filename,
          pdfImageUrls: res[1]?.data?.pdfImageUrls || [],
          shouldSignImageIndex: res[1]?.data?.shouldSignImageIndex || [],
        });
        const check = res[1]?.data?.shouldSignImageIndex.reduce((init, el) => {
          init[el] = 0;
          return init;
        }, {});
        setData((prev) => ({
          ...prev,
          xAxis: _.cloneDeep(check),
          yAxis: _.cloneDeep(check),
          checking: _.cloneDeep(check),
        }));
        fetch(res[1]?.data?.fileUrl)
          .then((res) => res.blob()) // Gets the response and returns it as a blob
          .then((blob) => {
            // Here's where you get access to the blob
            // And you can use it for whatever you want
            // Like calling ref().put(blob)

            // Here, I use it to make an image appear on the page
            setPdfUrl(URL.createObjectURL(blob));
          });

        handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [handleChangeContractData, setPdfUrl, isLogIn, navigator]);

  const handleClick = async () => {
    if (!data.tempSeal) return alert('인감도장을 선택해주세요 : )');
    let formData = new FormData();
    formData.append(data.tempSeal.name, data.tempSeal);

    try {
      const res = await api.uploadFiles(formData);
      setData((prev) => ({
        ...prev,
        seal: res?.data[0]?.url || null,
        tempSeal: null,
      }));
      setButtonPopup({
        sign: false,
        seal: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = async (file) => {
    setData((prev) => ({
      ...prev,
      tempSeal: file,
    }));
  };

  const handleSign = (canvas) => {
    canvas.toBlob(async function (blob) {
      let formData = new FormData();
      formData.append(`sign`, blob, `sign.png`);
      try {
        const res = await api.uploadFiles(formData);
        setData((prev) => ({
          ...prev,
          sign: res?.data[0]?.url || null,
        }));
        setButtonPopup({
          sign: false,
          seal: false,
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  const handleCheckSign = (page, x, y) => {
    const tempData = _.cloneDeep(data);
    tempData.xAxis[page] = tempData.checking[page] === 1 ? 0 : x;
    tempData.yAxis[page] = tempData.checking[page] === 1 ? 0 : y;
    tempData.checking[page] = tempData.checking[page] === 1 ? 0 : 1;
    setData(tempData);
  };

  const handlePosition = (page, x, y) => {
    const tempData = _.cloneDeep(data);
    tempData.xAxis[page] = x;
    tempData.yAxis[page] = y;
    setData(tempData);
  };

  const handleReset = () => {
    if (
      window.confirm(
        '모든 페이지에 다시 서명하셔야 합니다?\n다시 서명하시겠습니까?',
      )
    ) {
      const check = shouldSignImageIndex.reduce((init, el) => {
        init[el] = 0;
        return init;
      }, {});

      setData({
        ...initState,
        xAxis: _.cloneDeep(check),
        yAxis: _.cloneDeep(check),
        checking: _.cloneDeep(check),
      });
    }
  };

  const handleAgree = () => {
    const tempData = _.cloneDeep(data);
    tempData.isConfirmed = !tempData.isConfirmed;
    setData(tempData);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setCurrentPage(1);
    // pdfImageUrls
    // shouldSignImageIndex

    const domElement = document.getElementById('signPdf');
    const width = domElement.offsetWidth;
    const height = domElement.offsetHeight;
    const doc = new jsPDF('p', 'pt', [width, height]);

    const makeContract = async (p) => {
      // const domElement = document.getElementById('signPdf');
      // const width = domElement.offsetWidth;
      // const height = domElement.offsetHeight;

      await html2canvas(domElement, {
        backgroundColor: 'transparent',
        allowTaint: true,
        useCORS: true,
        dpi: 300, // Set to 300 DPI
      }).then(async (canvas) => {
        if (p !== 1) {
          doc.addPage();
        }
        const img = canvas.toDataURL('image/jpeg', 1);
        await doc.addImage(img, 'JPEG', 0, 0, width, height);

        // await canvas.toBlob(async function (blob) {
        //   let formData = new FormData();
        //   formData.append(`contract`, blob, `contract-${p}.jpg`);
        //   try {
        //     const res = await api.uploadFiles(formData);
        //   } catch (err) {
        //     console.log(err);
        //   }
        // }, 'image/jpeg', 1);

        // pdf 저장 로직
        // var img = canvas.toDataURL('image/jpeg', 1);
        // var doc = new jsPDF('p', 'pt', [width, height]);
        // doc.addImage(img, 'JPEG', 0, 0, width, height);
        // const blob = await doc.output('blob');
        // try {
        //   let formData = new FormData();
        //   formData.append(`contract`, blob, `contract.pdf`);
        //   const res = await api.uploadFiles(formData);
        //   setData(prev => ({
        //     ...prev,
        //     contract: res?.data[0]?.url || null,
        //   }));
        //   // handleChangeContractData({
        //   //   contractFile: res?.data[0]?.url,
        //   // });
        //   // await api.updateContract(contractId, {
        //   //   clientSignImageUrl: res?.data[0]?.url,
        //   // });
        //   // navigator('/solution/deposit-wait');
        // } catch (err) {
        //   console.log(err);
        // }
      });
    };

    for (let page = 1; page <= pdfImageUrls.length; page++) {
      await setCurrentPage(page);
      await new Promise(function (resolve, reject) {
        setTimeout(async function () {
          await makeContract(page);
          resolve();
        }, 500);
      });
    }

    const blob = await doc.output('blob');
    let formData = new FormData();
    formData.append(`contract`, blob, `contract.pdf`);
    const res = await api.uploadFiles(formData);
    setData((prev) => ({
      ...prev,
      contract: res?.data[0]?.url || null,
    }));
    handleChangeContractData({
      contractFile: res?.data[0]?.url,
    });
    await api.updateContract(contractId, {
      clientSignPdfUrl: res?.data[0]?.url,
    });
    setLoading(false);
    navigator('/solution/deposit-wait');




    // const domElement = document.getElementById('signPdf');
    // const width = domElement.offsetWidth;
    // const height = domElement.offsetHeight;
    // html2canvas(domElement, {
    //   backgroundColor: "transparent",
    //   allowTaint: true,
    //   useCORS: true,
    //   dpi: 300, // Set to 300 DPI
    // }).then(async (canvas) => {
    //   var img = canvas.toDataURL('image/jpeg', 1);
    //   var doc = new jsPDF('p', 'pt', [width, height]);
    //   doc.addImage(img, 'JPEG', 0, 0, width, height);

    //   // const blob = await doc.output('blob');
    //   // try {
    //   //   let formData = new FormData();
    //   //   formData.append(`contract`, blob, `contract.pdf`);
    //   //   const res = await api.uploadFiles(formData);
    //   //   setData(prev => ({
    //   //     ...prev,
    //   //     contract: res?.data[0]?.url || null,
    //   //   }));
    //   //   // handleChangeContractData({
    //   //   //   contractFile: res?.data[0]?.url,
    //   //   // });
    //   //   // await api.updateContract(contractId, {
    //   //   //   clientSignImageUrl: res?.data[0]?.url,
    //   //   // });
    //   //   // navigator('/solution/deposit-wait');
    //   // } catch (err) {
    //   //   console.log(err);
    //   // }
    // });
  };

  return (
    <SolutionSealWrapper
      seal={data.seal}
      sign={data.sign}
      isConfirmed={data.isConfirmed}
      xAxis={data.xAxis}
      yAxis={data.yAxis}
      fileUrl={fileUrl}
      onClick={handleClick}
      onChange={handleChange}
      onSign={handleSign}
      buttonPopup={buttonPopup}
      setButtonPopup={setButtonPopup}
      handleReset={handleReset}
      handleAgree={handleAgree}
      handleSubmit={handleSubmit}
      shouldSignImageIndex={shouldSignImageIndex}
      checking={data.checking}
      handleCheckSign={handleCheckSign}
      handlePosition={handlePosition}
      isLoading={isLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  );
}
