import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import _ from 'lodash/fp';
import * as api from 'apis';
import useArtist from 'hooks/useArtist';
import PlayListArtistWrapper from 'components/PlayListArtistWrapper';

export default function PlayListArtistContainer() {
  const [isInit, setIsInit] = useState(false);
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const [categories, setCategories] = useState([]);
  const [contents, setContents] = useState([]);
  const [pageData, setPageData] = useState({
    total: 0,
    currentCategory: '',
    currentPage: 1,
    lastPage: 0,
    perPage: 5,
    limit: 12,
    search: '',
    orderBy: '',
  });

  const [myPlayList, setMyPlayList] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const navigator = useNavigate();

  const { isUnlimitedPlaylist, checkAuthPlay } = useAuth();

  const {
    wishArtistPlayList,
    handleUpdateArtist,
    handleChangeArtist,
    handleMyArtistPlayList,
  } = useArtist();

  useEffect(() => {
    if (isUnlimitedPlaylist) return setIsInit(true);
    (async () => {
      try {
        const res = await Promise.all([
          api.getMyEstimate().catch(() => {}),
          api.getMyContract().catch(() => {}),
        ]);
        const estimate = res[0]?.data;
        const contract = res[1]?.data;
        if (estimate && estimate?.status === 'CONFIRM') {
          if (
            contract &&
            (contract?.status === 'FINISH' || contract?.status === 'SCHEDULED')
          ) {
            if (estimate?.solutionType === 'PREMIUM') {
              setIsInit(true);
            } else if (estimate?.solutionType === 'STANDARD') {
              setIsInit(true);
            } else if (estimate?.solutionType === 'BASIC') {
              setIsInit(true);
            } else {
              checkAuthPlay(estimate, contract);
              alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
              navigator('/');
            }
          } else {
            checkAuthPlay(estimate, contract);
            alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
            navigator('/');
          }
        } else {
          checkAuthPlay(estimate, contract);
          alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
          navigator('/');
        }
      } catch (err) {}
    })();
  }, [navigator, setIsInit, isUnlimitedPlaylist]);

  useEffect(() => {
    (async () => {
      try {
        const res = await api.getMainContents(true);
        setData(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([
          api.getContentsCategories(true).catch(() => []),
          api
            .getContentList({ limit: 12, page: 0, isCreatorContent: true })
            .catch(() => []),
          handleMyArtistPlayList(),
          handleUpdateArtist(),
        ]);
        const categories = res[0]?.data || [];
        const contents = res[1]?.data?.data || [];
        const playList = res[2] || [];
        const playIds = [...playList.map((p) => p.id)];
        setMyPlayList(playIds);
        setCategories(categories);
        setContents(contents);
        setPageData((prev) => ({
          ...prev,
          total: res[1]?.data?.total || 0,
          lastPage: Math.ceil(res[1]?.data?.total / 12) || 1,
        }));
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleWishList = async (e, content) => {
    e.stopPropagation();
    try {
      if (myPlayList.indexOf(content.id) > -1) return;
      const wishIds = [...wishArtistPlayList.map((w) => w.id)];
      let data = [];
      if (wishIds.indexOf(content.id) > -1) {
        data = [...wishArtistPlayList.filter((item) => item.id !== content.id)];
      } else {
        data = [...wishArtistPlayList, content];
      }
      handleChangeArtist({ wishArtistPlayList: data });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemove = (id) => {
    if (window.confirm('장바구니에서 제외하시겠습니까?')) {
      const data = [...wishArtistPlayList.filter((item) => item.id !== id)];
      handleChangeArtist({ wishArtistPlayList: data });
    }
  };

  const handleClickDetail = (e, content) => {
    e.preventDefault();
    setSelectedData(content);
    setView(true);
  };

  const handlePopupClose = () => {
    setView(false);
  };

  const handleClickLike = async (e, listIndex, itemIndex, contentId) => {
    e.stopPropagation();
    const tempData = _.cloneDeep(data);
    try {
      if (tempData[listIndex].contents[itemIndex].liked) {
        await api.cancelLikeContent(contentId);
      } else {
        await api.likeContent(contentId);
      }
      tempData[listIndex].contents[itemIndex].liked =
        !tempData[listIndex].contents[itemIndex].liked;
      setData(tempData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdatePlayList = async () => {
    try {
      const wishIds = [...wishArtistPlayList.map((w) => w.id)].sort();
      const playIds = [...myPlayList.map((p) => p.id)].sort();
      const isSame = wishIds.every((el, i) => {
        return el === (playIds[i] || null);
      });

      if (wishIds.length === 0 || isSame) return;
      const newIds = wishIds.filter((el, i) => {
        return el !== (playIds[i] || null);
      });
      if (newIds.length === 0) return;
      await api.postMyPlayList({
        content_ids: newIds,
      });
      alert('아티스트 콘텐츠 구매 완료 : )');
      handleChangeArtist({ wishArtistPlayList: [] });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = useCallback(
    async (currentPage) => {
      try {
        const res = await api.getContentList({
          page: currentPage - 1,
          limit: 12,
          search: pageData.search ? pageData.search : null,
          category: pageData.currentCategory ? pageData.currentCategory : null,
          orderBy: pageData.orderBy ? pageData.orderBy : null,
          isCreatorContent: true,
        });

        const { total, data } = res?.data;

        setPageData((prev) => ({
          ...prev,
          total,
          currentPage,
          lastPage: Math.ceil(total / 12) || 1,
        }));
        setContents(data);
      } catch (err) {
        console.log(err);
      }
    },
    [pageData, setPageData, setContents],
  );

  const handleLike = async (e, itemIndex, contentId) => {
    e.stopPropagation();
    const tempData = _.cloneDeep(contents);
    try {
      if (contents[itemIndex].liked) {
        await api.cancelLikeContent(contentId);
      } else {
        await api.likeContent(contentId);
      }
      tempData[itemIndex].liked = !tempData[itemIndex].liked;
      setContents(tempData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key !== 'Enter') return;
    handleSearch(1);
  };

  const handleClick = () => {
    handleSearch(1);
  };

  const handleChange = (e) => {
    setPageData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePage = (currentPage) => {
    handleSearch(currentPage);
  };

  if (!isInit) return <></>;

  return (
    <PlayListArtistWrapper
      data={data}
      isShow={view}
      selectedData={selectedData}
      handleClickDetail={handleClickDetail}
      handleClickLike={handleClickLike}
      handlePopupClose={handlePopupClose}
      wishPlayList={wishArtistPlayList}
      handleRemove={handleRemove}
      handleWishList={handleWishList}
      myPlayList={myPlayList}
      isUnlimitedPlaylist={isUnlimitedPlaylist}
      handleUpdatePlayList={handleUpdatePlayList}
      contents={contents}
      categories={categories}
      orderBy={pageData.orderBy}
      currentCategory={pageData.currentCategory}
      search={pageData.search}
      currentPage={pageData.currentPage}
      lastPage={pageData.lastPage}
      perPage={pageData.perPage}
      onChange={handleChange}
      onClick={handleClick}
      onSearch={handleSearch}
      onKeyPress={handleKeyPress}
      handlePage={handlePage}
      total={pageData.total}
      handleLike={handleLike}
    />
  );
}
