import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionSuccess from 'components/views/SolutionSuccess/SolutionSuccess';

export default function SolutionSuccessWrapper() {
    return (
        <>
            <Header />
            <SolutionSuccess />
        </>
    )
}
