import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionPublishEstimateSuccess from 'components/views/SolutionPublishEstimateSuccess/SolutionPublishEstimateSuccess';

export default function SolutionPublishEstimateSuccessWrapper({
    fileUrl,
    fileUrls,
    handleFileUrls,
    onClick,
    onDownload,
    phone,
    estimateUrl,
    currentPage,
    setCurrentPage,
    estimateId,
    handleRequest,
}) {
    return (
        <>
            <Header />
            <SolutionPublishEstimateSuccess
                estimateId={estimateId}
                fileUrl={fileUrl}
                fileUrls={fileUrls}
                handleFileUrls={handleFileUrls}
                onClick={onClick}
                onDownload={onDownload}
                phone={phone}
                estimateUrl={estimateUrl}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleRequest={handleRequest}
            />
        </>
    )
}
