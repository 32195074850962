import React, { useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './MypageScheduleWaiting.module.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function MypageScheduleWaiting() {
    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <Link to='/mypage' className={cx('back_box')}>
                        <img className={cx('back_img')} src="/statics/images/ic_profile_back.png" alt="ic_profile_back" />
                        <div className={cx('back_text_box')}>
                            <p className={cx('back_text')}>마이페이지</p>
                        </div>
                    </Link>
                    <div className={cx('inner_box')}>
                        <div className={cx('header_inner')}>
                            <div className={cx('title_inner')}>
                                <Link to='/mypage/schedule/access' className={cx('title_box')}>
                                    <h4 className={cx('title')}>
                                        설치 스케줄 현황
                                    </h4>
                                    <hr className={cx('hr')} />
                                </Link>
                                <Link to='/mypage/as' className={cx('sub_title_box')}>
                                    <h4 className={cx('sub_title')}>
                                        A/S 신청 현황
                                    </h4>
                                </Link>
                            </div>
                            <Link to='/mypage/schedule/edit' className={cx('change_box')}>
                                <p className={cx('change_button')}>
                                    스케줄 변경 신청하기
                                </p>
                            </Link>
                        </div>
                        <div className={cx('content_inner')}>
                            <div className={cx('engineer_box')}>
                                <div className={cx('engineer_charge_box')}>
                                    <p className={cx('engineer_charge')}>
                                        설치 담당 기사
                                    </p>
                                </div>
                                <div className={cx('engineer_profile_box')}>
                                    <img src="/statics/images/profile.png" alt="profile" />
                                </div>
                                <div className={cx('engineer_visit_box')}>
                                    <p className={cx('engineer_visit')}>
                                        담당기사님을 배정 중입니다.
                                    </p>
                                </div>
                            </div>
                            <div className={cx('place_box')}>
                                <div className={cx('place_text_box')}>
                                    <p className={cx('place_text')}>
                                        설치 장소 이미지 (최대 10장)
                                    </p>
                                </div>
                                <div className={cx('place_photo_add_inner')}>
                                    <div className={cx('place_photo_add_sub_inner')}>
                                        <div className={cx('place_photo_add_box')}>
                                            <img src="/statics/images/ic_photo_add.png" alt="ic_photo_add" />
                                        </div>
                                        <div className={cx('place_photo_select_inner')}>
                                            <div style={{ backgroundImage: 'url(/statics/images/select_ex_img.png)' }} className={cx('place_photo_select_box')}>
                                                <img className={cx('cancel_button')} src="/statics/images/ic_photo_del.png" alt="ic_photo_del" />
                                            </div>
                                            <div style={{ backgroundImage: 'url(/statics/images/select_ex_img.png)' }} className={cx('place_photo_select_box')}>
                                                <img className={cx('cancel_button')} src="/statics/images/ic_photo_del.png" alt="ic_photo_del" />
                                            </div>
                                            <div style={{ backgroundImage: 'url(/statics/images/select_ex_img.png)' }} className={cx('place_photo_select_box')}>
                                                <img className={cx('cancel_button')} src="/statics/images/ic_photo_del.png" alt="ic_photo_del" />
                                            </div>
                                            <div style={{ backgroundImage: 'url(/statics/images/select_ex_img.png)' }} className={cx('place_photo_select_box')}>
                                                <img className={cx('cancel_button')} src="/statics/images/ic_photo_del.png" alt="ic_photo_del" />
                                            </div>
                                            <div style={{ backgroundImage: 'url(/statics/images/select_ex_img.png)' }} className={cx('place_photo_select_box')}>
                                                <img className={cx('cancel_button')} src="/statics/images/ic_photo_del.png" alt="ic_photo_del" />
                                            </div>
                                            <div style={{ backgroundImage: 'url(/statics/images/select_ex_img.png)' }} className={cx('place_photo_select_box')}>
                                                <img className={cx('cancel_button')} src="/statics/images/ic_photo_del.png" alt="ic_photo_del" />
                                            </div>
                                            <div style={{ backgroundImage: 'url(/statics/images/select_ex_img.png)' }} className={cx('place_photo_select_box')}>
                                                <img className={cx('cancel_button')} src="/statics/images/ic_photo_del.png" alt="ic_photo_del" />
                                            </div>
                                            <div style={{ backgroundImage: 'url(/statics/images/select_ex_img.png)' }} className={cx('place_photo_select_box')}>
                                                <img className={cx('cancel_button')} src="/statics/images/ic_photo_del.png" alt="ic_photo_del" />
                                            </div>
                                            <div style={{ backgroundImage: 'url(/statics/images/select_ex_img.png)' }} className={cx('place_photo_select_box')}>
                                                <img className={cx('cancel_button')} src="/statics/images/ic_photo_del.png" alt="ic_photo_del" />
                                            </div>
                                            <div style={{ backgroundImage: 'url(/statics/images/select_ex_img.png)' }} className={cx('place_photo_select_box')}>
                                                <img className={cx('cancel_button')} src="/statics/images/ic_photo_del.png" alt="ic_photo_del" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx('detail_box')}>
                                        <p className={cx('detail_text')}>
                                            자세한 설치 시간은 담당 기사님 배정 이후 전화드릴 예정입니다.
                                        </p>
                                        <p className={cx('detail_text')}>
                                            ※ 현장 상황에 따라 추가 비용이 발생하실 수 있습니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
