import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './DragDropFile.module.scss';

const cx = classNames.bind(styles);

function DragDropFile({
  onChange,
}) {
  // drag state
  const [files, setFiles] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFiles(e.dataTransfer.files[0]);
      if (onChange) {
        onChange(e.dataTransfer.files[0]);
      }
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFiles(e.target.files[0]);
      if (onChange) {
        onChange(e.target.files[0]);
      }
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleRemove = () => {
    setFiles([]);
    if (onChange) {
      onChange(null);
    }
  }

  return (
    <>
      <form className={cx("form-file-upload")} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        <input ref={inputRef} type="file" className={cx("input-file-upload")} multiple={true} onChange={handleChange} />
        <label id="label-file-upload" htmlFor="input-file-upload" className={cx('label-file-upload', { dragActive: dragActive })}>
          <div className={cx('box_seal')}>
            {
              files.length !== 0
                ? (
                  <span className={cx('btn_remove')}>
                    {files.name}
                    <button onClick={handleRemove}>
                      <img src="/statics/images/ic_photo_del.png" alt="" />
                    </button>
                  </span>
                )
                : (
                  <>
                    <div className={cx('seal_img_box')}>
                      <img className={cx('seal_img')} src="/statics/images/ic_sign_upload.png" alt="ic_sign_upload" />
                    </div>
                    <p className={cx('sign_text')}>
                      파일 찾기 버튼을 눌러 서명 또는<br /> 인감도장 이미지를 등록해주세요.
                    </p>
                    <button className={cx("upload-button")} onClick={onButtonClick} />
                  </>
                )
            }
          </div>
        </label>
        {dragActive && <div className={cx("drag-file-element")} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
      </form>
    </>
  );
};

export default DragDropFile;