import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import MypageScheduleAccess from 'components/views/MypageScheduleAccess';

export default function MypageScheduleAccessWrapper({
    visitDate,
    visitManagerName,
    visitManagerPhone,
    visitManagerProfileImageUrl,
}) {
    return (
        <>
            <Header />
            <MypageScheduleAccess
                visitDate={visitDate}
                visitManagerName={visitManagerName}
                visitManagerPhone={visitManagerPhone}
                visitManagerProfileImageUrl={visitManagerProfileImageUrl}
            />
            <Footer />
        </>
    )
}
