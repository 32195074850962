import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './PlayListArtist.module.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import PlayListArtistSearch from './PlayListArtistSearch';
import { numberWithCommas } from 'libs/numbers';

const cx = classNames.bind(styles);

export default function PlayListArtist({
  data,
  handleClickLike,
  handleClickDetail,
  wishPlayList,
  handleWishList,
  handleRemove,

  contents,
  categories,
  orderBy,
  currentCategory,
  search,
  currentPage,
  lastPage,
  perPage,
  onChange,
  onClick,
  onSearch,
  onKeyPress,
  handlePage,
  total,
  handleLike,
  myPlayList,
  isUnlimitedPlaylist,
  handleUpdatePlayList,
}) {
  // 모바일 아래 탭 메뉴
  const [listMenu, setListMenu] = useState(false);

  // 디테일 페이지 이동
  const navigator = useNavigate();

  const onClickDetailMove = (e, id) => {
    e.stopPropagation();
    navigator(`/play-list/artist/detail?id=${id}`);
  };

  const onClickDirectBasketMove = () => {
    navigator('/mypage/basket/artist');
  };

  const scrollRef = useRef(null);

  const slide = (index, shift) => {
    scrollRef.current[index].scrollLeft += shift;
  };

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('side_container')}>
          <div className={cx('side_inner')}>
            <div>
              <NavLink to="/play-list/search" className={cx('menu_list_box')}>
                <div className={cx('menu_list_item_box')}>
                  <img
                    className={cx('img_on')}
                    src="/statics/images/ic_play_list_on.png"
                    alt="play_list_on_icon"
                  />
                  <img
                    className={cx('img_off')}
                    src="/statics/images/ic_play_list_off.png"
                    alt="play_list_off_icon"
                  />
                  <p className={cx('list_text')}>빔몬스터 콘텐츠</p>
                </div>
              </NavLink>
              <NavLink
                to="/play-list/artist"
                className={({ isActive }) =>
                  cx('menu_list_box', { active: isActive })
                }
              >
                <div className={cx('menu_list_item_box')}>
                  <img
                    className={cx('img_on')}
                    src="/statics/images/ic-play-search-on.png"
                    alt="search_om_icon"
                  />
                  <img
                    className={cx('img_off')}
                    src="/statics/images/ic-play-search-off.png"
                    alt="search_off_icon"
                  />
                  <p className={cx('list_text')}>아티스트 콘텐츠</p>
                </div>
              </NavLink>
              <NavLink to="/play-list/media" className={cx('menu_list_box')}>
                <div className={cx('menu_list_item_box')}>
                  <img
                    className={cx('img_on')}
                    src="/statics/images/ic_play_playlist_on.png"
                    alt="play_playlist_on_icon"
                  />
                  <img
                    className={cx('img_off')}
                    src="/statics/images/ic_play_playlist_off.png"
                    alt="play_playlist_off_icon"
                  />
                  <p className={cx('list_text')}>플레이리스트</p>
                </div>
              </NavLink>
            </div>
            <div className={cx('select_media_box')}>
              <div className={cx('select_text_box')}>
                <p className={cx('select_text')}>장바구니</p>
              </div>

              {wishPlayList &&
                wishPlayList.map((el) => {
                  return (
                    <div
                      className={cx('select_item')}
                      key={el?.id}
                      role="presentation"
                      onClick={() => handleRemove(el.id)}
                    >
                      <div className={cx('select_img_box')}>
                        <img
                          className={cx('select_img')}
                          src={
                            el?.thumbnailUrl
                              ? el.thumbnailUrl
                              : '/statics/images/abstract_wave.jpg'
                          }
                          alt="abstract_wave"
                        />
                      </div>
                      <div className={cx('select_sub_text_box')}>
                        <p className={cx('select_sub_text')}>{el?.title}</p>
                      </div>
                    </div>
                  );
                })}

              {/* <div className={cx('select_item')}>
                <div className={cx('select_img_box')}>
                  <img
                    className={cx('select_img')}
                    src="/statics/images/abstract_wave.jpg"
                    alt="abstract_wave"
                  />
                </div>
                <div className={cx('select_sub_text_box')}>
                  <p className={cx('select_sub_text')}>Test12</p>
                </div>
              </div> */}
            </div>
            <div className={cx('bottom_select_inner')}>
              <div className={cx('select_keep_box')}>
                {isUnlimitedPlaylist && (
                  <div className={cx('select_keep_text_box')}>
                    <p className={cx('select_keep_text')}>
                      무제한 솔루션 이용중
                    </p>
                  </div>
                )}
                {isUnlimitedPlaylist ? (
                  <div className={cx('select_keep_button_box')}>
                    <button
                      className={cx('select_keep_button')}
                      onClick={handleUpdatePlayList}
                    >
                      무료결제{' '}
                      {wishPlayList?.length > 10
                        ? `(${numberWithCommas(wishPlayList?.length)} 개)`
                        : `(0${wishPlayList?.length} 개)`}
                    </button>
                  </div>
                ) : (
                  <div className={cx('select_keep_button_box')}>
                    <button
                      className={cx('select_keep_button')}
                      onClick={onClickDirectBasketMove}
                    >
                      장바구니{' '}
                      {wishPlayList?.length > 10
                        ? `(${numberWithCommas(wishPlayList?.length)} 개)`
                        : `(0${wishPlayList?.length} 개)`}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            {/* 추천 콘텐츠 */}
            <div className={cx('suggestion_inner')}>
              {/* 아무것도 없을 때 전체 */}
              {!data?.length && (
                <div className={cx('empty_box')}>
                  <p className={cx('empty_title')}>
                    아티스트 콘텐츠 준비 중 입니다.
                  </p>
                  <p className={cx('empty_text')}>
                    최대한 빠르게 준비해 드리겠습니다.
                  </p>
                </div>
              )}
              {/* 내용물 있을떄 */}
              {data?.length > 0 &&
                data?.map((el, listIndex) => {
                  return (
                    <div key={el?.name} className={cx('suggestion_session')}>
                      <div className={cx('media_left_button_box')}>
                        <img
                          onClick={() => slide(listIndex, -400)}
                          src="/statics/images/ic-viausl-prev-on.png"
                          alt="media_arrow"
                        />
                      </div>
                      <div className={cx('media_right_button_box')}>
                        <img
                          onClick={() => slide(listIndex, +400)}
                          src="/statics/images/ic-viausl-next-on.png"
                          alt="media_arrow"
                        />
                      </div>
                      <div className={cx('suggestion_title_box')}>
                        <p className={cx('suggestion_title')}>{el?.name}</p>
                      </div>
                      <div
                        className={cx('overflow_inner')}
                        ref={(ref) => {
                          scrollRef.current = {
                            ...scrollRef.current,
                            [listIndex]: ref,
                          };
                        }}
                      >
                        {/* 내용물 없을때 단일 */}
                        {!el?.contents?.length && (
                          <div className={cx('empty_box')}>
                            <p className={cx('empty_title')}>
                              아티스트 콘텐츠 준비 중 입니다.
                            </p>
                            <p className={cx('empty_text')}>
                              최대한 빠르게 준비해 드리겠습니다.
                            </p>
                          </div>
                        )}
                        {/* 내용물 있을 때 */}
                        {el?.contents?.length > 0 &&
                          el?.contents?.map((item, itemIndex) => {
                            return (
                              <div
                                key={item?.id}
                                className={cx('suggestion_box')}
                                role="presentation"
                                onClick={(e) => handleClickDetail(e, item)}
                              >
                                <div className={cx('suggestion_img_box')}>
                                  <div
                                    role="presentation"
                                    onClick={(e) =>
                                      onClickDetailMove(e, item?.id)
                                    }
                                    className={cx('detail_img_box')}
                                  >
                                    <img
                                      src="/statics/images/ic-input-search.png"
                                      alt="상세보기 아이콘"
                                    />
                                  </div>
                                  {myPlayList?.indexOf(item.id) > -1 ? (
                                    <div
                                      className={cx('active_basket_img_box')}
                                    >
                                      <img
                                        src="/statics/images/ic-paid.png"
                                        alt="결제된 아이콘"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      onClick={(e) => handleWishList(e, item)}
                                      className={cx(
                                        [
                                          ...wishPlayList.map((p) => p.id),
                                        ].indexOf(item?.id) > -1
                                          ? 'active_basket_img_box'
                                          : 'basket_img_box',
                                      )}
                                    >
                                      <img
                                        src="/statics/images/ic-playlist-cart.png"
                                        alt="장바구니 추가 아이콘"
                                      />
                                    </div>
                                  )}
                                  {[...wishPlayList.map((p) => p.id)].indexOf(
                                    item?.id,
                                  ) > -1 ? (
                                    <img
                                      className={cx('active_suggestion_img')}
                                      src={item?.thumbnailUrl}
                                      alt="추천 콘텐츠 이미지"
                                    />
                                  ) : (
                                    <img
                                      className={cx('suggestion_img')}
                                      src={item?.thumbnailUrl}
                                      alt="추천 콘텐츠 이미지"
                                    />
                                  )}
                                  <div className={cx('quality_box')}>
                                    <p className={cx('quality_text')}>
                                      {item?.resolution}
                                    </p>
                                  </div>
                                </div>
                                <div className={cx('suggestion_info_inner')}>
                                  <div className={cx('suggestion_info_box')}>
                                    <div
                                      className={cx(
                                        'suggestion_info_title_box',
                                      )}
                                    >
                                      <p
                                        className={cx('suggestion_info_title')}
                                      >
                                        {item?.title}
                                      </p>
                                      <p className={cx('suggestion_info_name')}>
                                        {item?.creator?.name}
                                      </p>
                                    </div>
                                    <div
                                      onClick={(e) =>
                                        handleClickLike(
                                          e,
                                          listIndex,
                                          itemIndex,
                                          item?.id,
                                        )
                                      }
                                      className={cx('suggestion_info_img_box')}
                                    >
                                      {item?.liked ? (
                                        <img
                                          className={cx('on_like_img')}
                                          src="/statics/images/ic_playlist_like_on.png"
                                          alt="하트 이미지"
                                        />
                                      ) : (
                                        <img
                                          className={cx('off_like_img')}
                                          src="/statics/images/ic_playlist_like_off.png"
                                          alt="하트 이미지"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className={cx('suggestion_payment_box')}>
                                    <p className={cx('suggestion_payment')}>
                                      {item?.price
                                        ? `${numberWithCommas(item?.price)}`
                                        : ' - '}{' '}
                                      원
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>
            <PlayListArtistSearch
              contents={contents}
              categories={categories}
              orderBy={orderBy}
              currentCategory={currentCategory}
              search={search}
              currentPage={currentPage}
              lastPage={lastPage}
              perPage={perPage}
              onChange={onChange}
              onClick={onClick}
              onSearch={onSearch}
              onKeyPress={onKeyPress}
              handlePage={handlePage}
              total={total}
              myPlayList={myPlayList}
              isUnlimitedPlaylist={isUnlimitedPlaylist}
              onClickDetailMove={onClickDetailMove}
              handleWishList={handleWishList}
              handleClickDetail={handleClickDetail}
              wishPlayList={wishPlayList}
              handleLike={handleLike}
            />
          </div>
        </div>
        {/* 모바일 footer 이동 및 검색 */}
        <div className={cx('mobile_footer_inner')}>
          <div className={cx('mobile_footer_box')}>
            <div
              className={cx(
                !listMenu
                  ? 'mobile_hide_select_box'
                  : 'active_mobile_hide_select_box',
              )}
            >
              <img
                onClick={() => setListMenu(!listMenu)}
                className={cx('mobile_hide_button')}
                src="/statics/images/mobile_select_button.png"
                alt="mobile_select_button"
              />
              <div className={cx('mobile_hide_text_box')}>
                <p className={cx('mobile_hide_text')}>선택된 영상</p>
              </div>
              <div className={cx('mobile_hide_menu_item_inner')}>
                {wishPlayList &&
                  wishPlayList.map((el) => {
                    return (
                      <div
                        className={cx('mobile_hide_menu_item_box')}
                        key={el.id}
                        role="presentation"
                      >
                        <div className={cx('mobile_hide_img_box')}>
                          <img
                            className={cx('mobile_hide_img')}
                            src={
                              el.thumbnailUrl
                                ? el.thumbnailUrl
                                : '/statics/images/mobile_hide_img.png'
                            }
                            alt="mobile_hide_img"
                          />
                        </div>
                        <div className={cx('mobile_hide_menu_item_text_box')}>
                          <p className={cx('mobile_hide_menu_item_text')}>
                            {el?.title}
                          </p>
                        </div>
                        <button
                          className={cx('btn_remove_play')}
                          onClick={() => handleRemove(el.id)}
                        >
                          X
                        </button>
                      </div>
                    );
                  })}
              </div>
              <div className={cx('mobile_hide_menu_item_button_box')}>
                {/* {grade !== '프리미엄' && playList.length === maxCount ? (
                  <button
                    className={cx('mobile_hide_menu_item_button')}
                    onClick={handleResetPlayList}
                  >
                    플레이리스트 리셋 ( {playlistResetCount} / {maxReset} )
                  </button>
                ) : (
                  <button
                    className={cx('mobile_hide_menu_item_button')}
                    onClick={handleUpdatePlayList}
                  >
                    플레이리스트 보관
                  </button>
                )} */}
              </div>
            </div>
            <div className={cx('mobile_footer_header_box')}>
              <div className={cx('mobile_footer_header_img_box')}>
                <img
                  className={cx('mobile_footer_header_img')}
                  src="/statics/images/ic_m_solution.png"
                  alt="ic_m_solution"
                />
              </div>
              {isUnlimitedPlaylist ? (
                <div
                  className={cx('mobile_footer_header_title_box')}
                  role="presentation"
                  onClick={handleUpdatePlayList}
                >
                  <p className={cx('mobile_footer_header_title')}>
                    무제한 솔루션 이용중{' '}
                    {wishPlayList?.length > 10
                      ? `${numberWithCommas(wishPlayList?.length)} 개`
                      : `0${wishPlayList?.length} 개`}
                  </p>
                </div>
              ) : (
                <div
                  className={cx('mobile_footer_header_title_box')}
                  role="presentation"
                  onClick={onClickDirectBasketMove}
                >
                  <p className={cx('mobile_footer_header_title')}>
                    장바구니{' '}
                    {wishPlayList?.length > 10
                      ? `${numberWithCommas(wishPlayList?.length)} 개`
                      : `0${wishPlayList?.length} 개`}
                  </p>
                </div>
              )}
            </div>
            <div className={cx('mobile_footer_menu_inner')}>
              <NavLink to="/play-list/search" className={cx('menu_list_box')}>
                <div className={cx('menu_list_item_box')}>
                  <img
                    className={cx('img_on')}
                    src="/statics/images/ic_play_list_on.png"
                    alt="play_list_on_icon"
                  />
                  <img
                    className={cx('img_off')}
                    src="/statics/images/ic_play_list_off.png"
                    alt="play_list_off_icon"
                  />
                  <p className={cx('list_text')}>빔몬스터 콘텐츠</p>
                </div>
              </NavLink>
              <NavLink
                to="/play-list/artist"
                className={({ isActive }) =>
                  cx('menu_list_box', { active: isActive })
                }
              >
                <div className={cx('menu_list_item_box')}>
                  <img
                    className={cx('img_on')}
                    src="/statics/images/ic-play-search-on.png"
                    alt="search_om_icon"
                  />
                  <img
                    className={cx('img_off')}
                    src="/statics/images/ic-play-search-off.png"
                    alt="search_off_icon"
                  />
                  <p className={cx('list_text')}>아티스트 콘텐츠</p>
                </div>
              </NavLink>
              <NavLink to="/play-list/media" className={cx('menu_list_box')}>
                <div className={cx('menu_list_item_box')}>
                  <img
                    className={cx('img_on')}
                    src="/statics/images/ic_play_playlist_on.png"
                    alt="play_playlist_on_icon"
                  />
                  <img
                    className={cx('img_off')}
                    src="/statics/images/ic_play_playlist_off.png"
                    alt="play_playlist_off_icon"
                  />
                  <p className={cx('list_text')}>플레이리스트</p>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
