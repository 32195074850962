import React from 'react';
import classNames from 'classnames/bind';
import styled from './Refund.module.scss';

const cx = classNames.bind(styled);

export default function Refund({ content }) {
  return (
    <div className={cx('container')}>
      <p dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}
