import React from 'react';
import Calendar from 'react-calendar';
import { CalendarInner, ImgBox } from './CalendarStyles';
import moment from 'moment';

export default function CustomCalendar({
  date,
  minDay,
  handleDate,
  handleToggle,
}) {
  const resultDate = [];

  for (let i = 1; i <= new Date().getDate() + 14; i++) {
    resultDate.push(i);
  }

  const result = resultDate.map((el) => {
    return el;
  });

  const minDate = minDay
    ? new Date(moment(new Date()).add(minDay, 'days').format('YYYY-MM-DD'))
    : new Date(date);
  const formatMinDate = moment(minDate).format('YYYY-MM-DD');

  return (
    <CalendarInner resultDate={resultDate}>
      <ImgBox onClick={handleToggle}>
        <div>
          <img src="/statics/images/ic_pop_close.png" alt="달력 닫기 버튼" />
        </div>
      </ImgBox>
      <Calendar
        value={date}
        onChange={handleDate}
        calendarType="US"
        minDate={minDate}
        formatDay={(locale, date) => moment(date).format('D')}
        tileClassName={({ date, view }) => {
          const d = moment(date).format('YYYY-MM-DD');
          if (d !== formatMinDate && moment(d).isBefore(formatMinDate)) {
            return 'date_disabled';
          }
        }}
      />
    </CalendarInner>
  );
}
