import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './SolutionPublishEstimateWait.module.scss';
import CancelSolutionButton from 'components/common/CancelSolutionButton';
import { NavLink } from 'react-router-dom';
import Popup from '../Popup/Popup';
import PrevPopup from '../PrevPopup';

const cx = classNames.bind(styles);

export default function SolutionPublishEstimateWait() {

    const [buttonPopup, setButtonPopup] = useState({
        checklist: false,
        prevPopup: false
    });

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('header_container')}>
                    <div className={cx('inner')}>
                        <div className={cx('header_inner')}>
                            <div className={cx('selected_process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('selected_process_title')}>상품선택</h4>
                                </div>
                            </div>
                            <div className={cx('selected_process_bar')}></div>
                            <div className={cx('selected_process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('selected_process_title')}>견적요청</h4>
                                </div>
                            </div>
                            <div className={cx('selected_process_bar')}></div>
                            <div className={cx('select_process_box')}>
                                <div className={cx('select_process_img_box')}>
                                    <img src="/statics/images/solution_select.png" alt="solution_select" />
                                </div>
                                <div className={cx('select_process_title_box')}>
                                    <h4 className={cx('select_process_title')}>견적발행</h4>
                                </div>
                            </div>
                            <div className={cx('select_process_bar')}></div>
                            <div className={cx('process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('process_title')}>결제방식</h4>
                                </div>
                            </div>
                            <div className={cx('process_bar')}></div>
                            <div className={cx('process_status_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('process_title')}>계약현황 확인</h4>
                                </div>
                            </div>
                            <div className={cx('process_bar')}></div>
                            <div className={cx('process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('process_title')}>계약서 작성</h4>
                                </div>
                            </div>
                            <div className={cx('process_bar')}></div>
                            <div className={cx('process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('process_title')}>입금 확인</h4>
                                </div>
                            </div>
                            <div className={cx('process_bar')}></div>
                            <div className={cx('process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('process_title')}>설치 스케줄</h4>
                                </div>
                            </div>
                        </div>
                        <div className={cx('mobile_header_inner')}>
                            <div className={cx('mobile_header_progressbar_box')}>
                                <div className={cx('mobile_header_progressbar')}></div>
                            </div>
                            <div className={cx('select_process_box')}>
                                <div className={cx('mobile_select_text_box')}>
                                    <p className={cx('mobile_select_text')}>step.3</p>
                                </div>
                                <div className={cx('mobile_select_process_title_box')}>
                                    <div className={cx('select_process_title_box')}>
                                        <h4 className={cx('select_process_title')}>견적발행</h4>
                                    </div>
                                    <div className={cx('select_process_img_box')}>
                                        <img src="/statics/images/solution_select.png" alt="solution_select" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <CancelSolutionButton />
                        <div className={cx('checklist_button')} onClick={() => setButtonPopup(prev => ({ ...prev, checklist: !prev.checklist }))}>
                            <div className={cx('checklist_icon_box')}>
                                <img className={cx('checklist_icon')} src="/statics/images/check_list.png" alt="check_list" />
                            </div>
                            <div className={cx('hide_text_box')}>
                                <p className={cx('hide_text')}>체크리스트 보기</p>
                            </div>
                        </div>
                        <div className={cx('title_box')}>
                            <h4 className={cx('title')}>견적서 발행 대기</h4>
                        </div>
                        <div className={cx('content_inner')}>
                            <div className={cx('content_img_box')}>
                                <img className={cx('content_img')} src="/statics/images/img_solution_estimate_loading.png" alt="img_solution_estimate_loading" />
                            </div>
                            <div className={cx('text_box')}>
                                <p className={cx('text')}>
                                    견적서 발행 중입니다.
                                </p>
                            </div>
                            <div className={cx('sub_text_box')}>
                                <p className={cx('sub_text')}>
                                    견적 발행 기간은 영업일 기준으로 평균 1 ~ 3일 정도 소요됩니다.<br />
                                    견적서 발행 완료시 완료 문자를 안내드립니다 : )
                                </p>
                            </div>
                            {/* <div className={cx('content_button_box')}>
                                <button disabled className={cx('confirm_button')}>견적서 확인하기</button>
                                <button disabled className={cx('confirm_button')}>온라인 견적서 출력</button>
                            </div> */}
                        </div>
                        {/* <div className={cx('button_box')}>
                            <div className={cx('prev_button_box')}>
                                <button onClick={() => setButtonPopup(prev => ({ ...prev, prevPopup: !prev.prevPopup }))} className={cx('prev_button')}>이전 단계 확인</button>
                            </div>
                            <div className={cx('next_button_box')}>
                                <NavLink to='/solution/publish-estimate/success' className={cx('next_button')}>다음 단계로 이동</NavLink>
                            </div>
                        </div> */}
                        <Popup trigger={buttonPopup.checklist} setTrigger={setButtonPopup} />
                        <PrevPopup trigger={buttonPopup.prevPopup} setTrigger={setButtonPopup} />
                    </div>
                </div>
            </div>
        </div>
    )
}
