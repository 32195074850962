import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import MypagePassword from 'components/views/MypagePassword/MypagePassword';

export default function MypagePasswordWrapper({
    password,
    newPassword,
    confirmPassword,
    handleChange,
    handleSubmit,
    isPasswordError,
}) {
    return (
        <>
            <Header />
            <MypagePassword
                password={password}
                newPassword={newPassword}
                confirmPassword={confirmPassword}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isPasswordError={isPasswordError}
            />
            <Footer />
        </>
    )
}
