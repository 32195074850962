import React from 'react';
import { Header } from 'components/views';
import NewMypageAs from 'components/views/NewMypageAs';

export default function NewMypageAsWrapper() {
  return (
    <>
      <Header />
      <NewMypageAs />
    </>
  );
}
