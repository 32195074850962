import React, { useEffect, useRef, useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './FirstCheckList.module.scss';

const cx = classNames.bind(styles);

export default function FirstCheckList() {
    return (
        <div>
            <div className={cx('content_box')}>
                <div className={cx('content')}>
                    <div className={cx('title_bg_box')}>
                        <img className={cx('title_bg')} src="/statics/images/popup_led_img.png" alt="popup_led_img" />
                        <img className={cx('led_icon')} src="/statics/images/popup_led_icon.png" alt="popup_led_icon" />
                    </div>
                    <div className={cx('list_inner')}>
                        <div className={cx('list_title_box')}>
                            <h4 className={cx('list_title')}>
                                LED
                            </h4>
                        </div>
                        <div className={cx('list_text_box')}>
                            <p className={cx('list_text')}>
                                LED 패널을 통하여 영상을 재생
                            </p>
                        </div>
                        <hr className={cx('list_hr')} />
                        <div className={cx('list_item_inner')}>
                            <div className={cx('list_item_box')}>
                                <img src="/statics/images/ic_task_alt_blue.png" alt="ic_task_alt_blue" />
                                <div className={cx('list_item_text_box')}>
                                    <p className={cx('list_item_text')}>
                                        공간 밝기에 영향을 받지 않음
                                    </p>
                                </div>
                            </div>
                            <div className={cx('list_item_box')}>
                                <img src="/statics/images/ic_task_alt_blue.png" alt="ic_task_alt_blue" />
                                <div className={cx('list_item_text_box')}>
                                    <p className={cx('list_item_text')}>
                                        그림자 발생 하지 않음
                                    </p>
                                </div>
                            </div>
                            <div className={cx('list_item_box')}>
                                <img src="/statics/images/ic_task_alt_blue.png" alt="ic_task_alt_blue" />
                                <div className={cx('list_item_text_box')}>
                                    <p className={cx('list_item_text')}>
                                        최소 투사 거리 필요 없음
                                    </p>
                                </div>
                            </div>
                            <div className={cx('list_item_box')}>
                                <img src="/statics/images/ic_task_alt_blue.png" alt="ic_task_alt_blue" />
                                <div className={cx('list_item_text_box')}>
                                    <p className={cx('list_item_text')}>
                                        단차 발생 있음
                                    </p>
                                </div>
                            </div>
                            <div className={cx('sub_list_item_box')}>
                                <div>
                                    <img src="/statics/images/ic_task_alt_blue.png" alt="ic_task_alt_blue" />
                                </div>
                                <div className={cx('list_item_text_box')}>
                                    <p className={cx('sub_list_item_text')}>
                                        벽면 색상과 질감에 영향을 받지 않음
                                    </p>
                                </div>
                            </div>
                            <div className={cx('denger_text_box')}>
                                <p className={cx('denger_text')}>*</p>
                                <p className={cx('denger_text')}>LED 패널 무게로 인해,<br /> 석고보드 벽면에는 설치가 불가함</p>
                            </div>
                            <div className={cx('denger_text_box')}>
                                <p className={cx('denger_text')}>*</p>
                                <p className={cx('denger_text')}>실측 값과 실제 제품 사이즈가 패널의 특성상 5~15cm 오차 범위가 있을 수 있음</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('content')}>
                    <div className={cx('title_bg_box')}>
                        <img className={cx('title_bg')} src="/statics/images/popup_vim_img.png" alt="popup_vim_img" />
                        <img className={cx('led_icon')} src="/statics/images/img_intro_hardware_second.png" alt="img_intro_hardware_second" />
                    </div>
                    <div className={cx('list_inner')}>
                        <div className={cx('list_title_box')}>
                            <h4 className={cx('list_title')}>
                                빔프로젝터
                            </h4>
                        </div>
                        <div className={cx('list_text_box')}>
                            <p className={cx('list_text')}>
                                빛을 벽면에 비추어 영상을 재생
                            </p>
                        </div>
                        <hr className={cx('list_hr')} />
                        <div className={cx('list_item_inner')}>
                            <div className={cx('list_item_box')}>
                                <img src="/statics/images/ic_task_alt_blue.png" alt="ic_task_alt_blue" />
                                <div className={cx('list_item_text_box')}>
                                    <p className={cx('list_item_text')}>
                                        공간 밝기에 영향을 받음
                                    </p>
                                </div>
                            </div>
                            <div className={cx('list_item_box')}>
                                <img src="/statics/images/ic_task_alt_blue.png" alt="ic_task_alt_blue" />
                                <div className={cx('list_item_text_box')}>
                                    <p className={cx('list_item_text')}>
                                        그림자 발생
                                    </p>
                                </div>
                            </div>
                            <div className={cx('list_item_box')}>
                                <img src="/statics/images/ic_task_alt_blue.png" alt="ic_task_alt_blue" />
                                <div className={cx('list_item_text_box')}>
                                    <p className={cx('list_item_text')}>
                                        최소 투사 거리 필요
                                    </p>
                                </div>
                            </div>
                            <div className={cx('list_item_box')}>
                                <img src="/statics/images/ic_task_alt_blue.png" alt="ic_task_alt_blue" />
                                <div className={cx('list_item_text_box')}>
                                    <p className={cx('list_item_text')}>
                                        단차 발생 없음
                                    </p>
                                </div>
                            </div>
                            <div className={cx('sub_list_item_box')}>
                                <div>
                                    <img src="/statics/images/ic_task_alt_blue.png" alt="ic_task_alt_blue" />
                                </div>
                                <div className={cx('list_item_text_box')}>
                                    <p className={cx('sub_list_item_text')}>
                                        벽면 색상과 질감에 의해 영상이 왜곡 될 수 있음
                                    </p>
                                </div>
                            </div>
                            <div className={cx('denger_text_box')}>
                                <p className={cx('denger_text')}>*</p>
                                <p className={cx('denger_text')}>빔프로젝터 개수 별로 실측 사이즈에 맞출 수 있음</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx('caution_inner')}>
                <div>
                    <img src="/statics/images/ic_input_info_error.png" alt="ic_input_info_error" />
                </div>
                <div className={cx('caution_text_box')}>
                    <p className={cx('caution_text')}>
                        빔몬스터 미디어 인테리어 솔루션의 하드웨어는 두 가지로 구성되어 있습니다.
                    </p>
                </div>
            </div>
        </div>
    )
}
