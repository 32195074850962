import React from 'react';
import { Header } from 'components/views';
import SolutionSeal from 'components/views/SolutionSeal';
import ContractLoader from 'components/common/ContractLoader';

export default function SolutionSealWrapper({
  seal,
  sign,
  fileUrl,
  onClick,
  onChange,
  onSign,
  buttonPopup,
  setButtonPopup,
  handleReset,
  isConfirmed,
  xAxis,
  yAxis,
  handleAgree,
  handleSubmit,
  shouldSignImageIndex,
  checking,
  handleCheckSign,
  handlePosition,
  isLoading,
  currentPage,
  setCurrentPage,
}) {
  return (
    <>
      <Header />
      <SolutionSeal
        seal={seal}
        sign={sign}
        fileUrl={fileUrl}
        onClick={onClick}
        onChange={onChange}
        onSign={onSign}
        buttonPopup={buttonPopup}
        setButtonPopup={setButtonPopup}
        handleReset={handleReset}
        isConfirmed={isConfirmed}
        xAxis={xAxis}
        yAxis={yAxis}
        handleAgree={handleAgree}
        handleSubmit={handleSubmit}
        shouldSignImageIndex={shouldSignImageIndex}
        checking={checking}
        handleCheckSign={handleCheckSign}
        handlePosition={handlePosition}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {
        isLoading &&
        <ContractLoader />
      }
    </>
  )
}
