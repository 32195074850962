import React from 'react';
import classNames from 'classnames/bind';
import styles from './PrevPopup.module.scss';
import { NavLink } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function PrevPopup(props) {
  return (props.trigger) ? (
    <div className={cx('container')}>
        {/* 바탕화면 클릭 시 닫힘 클릭 이벤트 필요없음 지우면 됨!!   */}
        <div onClick={() => props.setTrigger(false)} className={cx('sub_container')}></div>
        <div className={cx('inner')}>
            <div className={cx('inner_box')}>
                <div className={cx('header')}>
                    <div onClick={() => props.setTrigger(false)} className={cx('close_button_box')}>
                        <img className={cx('close_button')} src="/statics/images/ic_pop_black_close.png" alt="ic_pop_black_close" />
                    </div>
                    <div className={cx('title_box')}>
                        <h4 className={cx('title')}>
                            이전 단계로 돌아가시겠습니까?
                        </h4>
                    </div>
                </div>
                <div className={cx('content_inner')}>
                    <div className={cx('sign_box')}>
                        <p className={cx('sign_text')}>
                            이전 단계로 돌아갈 경우,
                        </p>
                        <p className={cx('sign_text')}>
                            기존에 입력된 정보들은 자동으로 초기화됩니다.
                        </p>
                        <p className={cx('sign_text')}>
                            신중히 선택해주세요!
                        </p>
                    </div>
                    <div className={cx('button_inner')}>
                        <div className={cx('button_box')}>
                            <button onClick={() => props.setTrigger(false)} className={cx('cancle_button')}>
                                취소
                            </button>
                        </div>
                        <div className={cx('button_box')}>
                            <button className={cx('complete_button')}>
                                이전단계로 이동
                            </button>

                            {/* 이전페이지 순서대로 넣어 놓음 nav 이동 되는거   */}
                              
                            {/* <NavLink to='/solution/request-estimate/solution'>
                                <button className={cx('complete_button')}>
                                    이전단계로 이동
                                </button>
                            </NavLink> */}
                              {/* link 주소 */}
                              {/* /solution/publish-estimate/wait */}
                              {/* /solution/rental-info */}
                              {/* /solution/rental-wait */}
                              {/* /solution/rental-complete */}
                              {/* /solution/contract-waiting */}
                              {/* /solution/seal-complete */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    ) : '';
}
