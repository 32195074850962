import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './SolutionSeal.module.scss';
import { NavLink } from 'react-router-dom';
import PDFViewerBox from 'components/common/PDFViewerBox';
import CancelSolutionButton from 'components/common/CancelSolutionButton';
import Draggable from "react-draggable";
import SealPopup from '../SealPopup';
import SignPopup from '../SignPopup';

const cx = classNames.bind(styles);

export default function SolutionSeal({
    seal,
    sign,
    fileUrl,
    onClick,
    onChange,
    onSign,
    buttonPopup,
    handleReset,
    setButtonPopup,
    isConfirmed,
    xAxis,
    yAxis,
    handleAgree,
    handleSubmit,
    shouldSignImageIndex,
    checking,
    handleCheckSign,
    handlePosition,
    currentPage,
    setCurrentPage,
}) {
    const elementSizeRef = useRef(null);
    const [elementSize, setElementSize] = useState({
        width: 0,
        height: 0
    });

    const [isLoaded, setIsLoaded] = useState(false);

    const trackPos = (data) => {
        handlePosition(currentPage - 1, data.x, data.y);
    };

    const backgroundImage = seal ? `url(${seal}?time=${Date.now()})` : sign ? `url(${sign}?time=${Date.now()})` : '';
    const realImage = seal ? `${seal}?time=${Date.now()}` : sign ? `${sign}?time=${Date.now()}` : '';

    useEffect(() => {
        if (elementSizeRef?.current) {
            const handleResize = () => {
                setElementSize({
                    width: elementSizeRef?.current?.offsetWidth,
                    height: elementSizeRef?.current?.offsetHeight,
                });
            };
            window.addEventListener("resize", handleResize);
            handleResize();

            return () => window.removeEventListener("resize", handleResize);
        }
    }, [elementSizeRef, setElementSize]);

    const isAllChecking = shouldSignImageIndex.every((page) => checking[page] === 1);
    const isSignPage = shouldSignImageIndex.some((page) => (Number(page) + 1) === currentPage);

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('back_inner')}>
                    <NavLink to='/solution/contract-complete' className={cx('back_box')}>
                        <div className={cx('back_img_box')}>
                            <img src="/statics/images/ic_profile_back.png" alt="ic_profile_back" />
                        </div>
                        <div className={cx('back_text_box')}>
                            <p className={cx('back_text')}>
                                이전 페이지로 돌아가기
                        </p>
                        </div>
                    </NavLink>
                </div>
                <div id="signPdf" className={cx('inner')} ref={elementSizeRef}>
                    {/* 사인 하는곳 */}
                    <div className={cx('box_pdf')}>
                        <PDFViewerBox
                            width={elementSize?.width}
                            height={elementSize?.height}
                            fileUrl={fileUrl}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            handleLoaded={setIsLoaded}
                        />
                    </div>
                    {/* <div className={cx('sign_box')}>

                    </div> */}
                    {
                        isSignPage && shouldSignImageIndex.map((el) => {
                            if ((Number(el) + 1) === currentPage && checking[currentPage - 1] === 0 && backgroundImage) {
                                return (
                                    <Draggable
                                        key={el}
                                        bounds="parent"
                                        // allowAnyClick={true}
                                        // scale={1}
                                        defaultPosition={{
                                            x: xAxis[currentPage - 1],
                                            y: yAxis[currentPage - 1],
                                        }}
                                        onDrag={(e, data) => trackPos(data)}
                                        onStart={() => { }}
                                        onStop={() => { }}
                                    >
                                        <div className={cx('sign_move_box')} style={{
                                            backgroundImage
                                        }}>
                                            <img className={cx('sign_move')} src="/statics/images/ic_sign_move.png" alt="ic_sign_move" />
                                        </div>
                                    </Draggable>
                                );
                            }
                        })
                    }
                    {
                        isSignPage && shouldSignImageIndex.map((el) => {
                            if ((Number(el) + 1) === currentPage && checking[currentPage - 1] === 1) {
                                return (
                                    <div
                                        key={el}
                                        className={cx('sign_confirm_box')}
                                        style={{
                                            // backgroundImage,
                                            transform: `translate(${xAxis[currentPage - 1]}px, ${yAxis[currentPage - 1]}px)`
                                        }}
                                    >
                                        <img className={cx('sign_img')} src={realImage} alt="" crossOrigin="anonymous" />
                                    </div>
                                );
                            }
                        })
                    }
                </div>
                {
                    isAllChecking && (seal || sign) && (
                        <div className={cx('check_inner')}>
                            <input className={cx('check_box')} type='checkbox' id='agree' onClick={() => { handleAgree(); }} />
                            <label className={cx('check_box_label')} htmlFor="agree"></label>
                            <label className={cx('check_label')} htmlFor="agree"><b className={cx('check_label_b')}>계약서 내용을 모두 숙지</b> 하였고,<br className={cx('mobile_br')} /> 전자 서명 진행에 동의합니다.</label>
                        </div>
                    )
                }
                {
                    !isAllChecking && (
                        <div className={cx('check_inner')}>
                            <label className={cx('check_label')} htmlFor="agree"><b className={cx('check_label_b')}>* {shouldSignImageIndex?.reduce((init, el) => { if (checking[el] === 0) { init.push(Number(el) + 1) } return init; }, []).join(', ')} 페이지</b>에 서명이 필요합니다.</label>
                        </div>
                    )
                }
                {
                    (!seal && !sign) && (
                        <div className={cx('button_inner')}>
                            <div className={cx('button_box')}>
                                <button onClick={() => setButtonPopup(prev => ({ ...prev, seal: !prev.seal }))} className={cx('button')}>인감 도장 업로드</button>
                            </div>
                            <div className={cx('button_box')}>
                                <button onClick={() => setButtonPopup(prev => ({ ...prev, sign: !prev.sign }))} className={cx('button')}>전자 수기 서명</button>
                            </div>
                        </div>
                    )
                }
                {
                    (seal || sign) && (
                        <div className={cx('confirm_button_box')}>
                            <div className={cx('prev_button_box')} onClick={handleReset}>
                                <button className={cx('prev_button')}>인감 / 서명 다시하기</button>
                            </div>
                            {
                                isAllChecking
                                    ? (<div className={cx('next_button_box')}>
                                        <button className={cx('next_button', { active: isConfirmed })} disabled={!isConfirmed} onClick={handleSubmit}>
                                            다음 단계로 이동
                                        </button>
                                    </div>)
                                    : (
                                        <div className={cx('next_button_box')}>
                                            <button className={cx('next_button', { active: isSignPage })} disabled={!isSignPage} onClick={() => handleCheckSign(currentPage - 1, xAxis[currentPage - 1], yAxis[currentPage - 1])}>
                                                {checking[currentPage - 1] === 1 ? '다시하기' : '서명하기'}
                                            </button>
                                        </div>
                                    )
                            }
                        </div>
                    )
                }
                <CancelSolutionButton />
                <SealPopup trigger={buttonPopup.seal} setTrigger={setButtonPopup} onClick={onClick} onChange={onChange} />
                <SignPopup trigger={buttonPopup.sign} setTrigger={setButtonPopup} onSign={onSign} />
            </div>
        </div>
    )
}
