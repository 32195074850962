import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './SolutionDepositCheck.module.scss';
import { NavLink } from 'react-router-dom';
import Popup from '../Popup/Popup';

const cx = classNames.bind(styles);

export default function SolutionDepositCheck() {

    const [buttonPopup, setButtonPopup] = useState(false);

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('header_container')}>
                    <div className={cx('inner')}>
                        <div className={cx('header_inner')}>
                            <div className={cx('selected_process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('selected_process_title')}>상품선택</h4>
                                </div>
                            </div>
                            <div className={cx('selected_process_bar')}></div>
                            <div className={cx('selected_process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('selected_process_title')}>견적요청</h4>
                                </div>
                            </div>
                            <div className={cx('selected_process_bar')}></div>
                            <div className={cx('selected_process_box')}>
                                <div className={cx('selected_process_title_box')}>
                                    <h4 className={cx('selected_process_title')}>견적발행</h4>
                                </div>
                            </div>
                            <div className={cx('selected_process_bar')}></div>
                            <div className={cx('selected_process_box')}>
                                <div className={cx('selected_process_title_box')}>
                                    <h4 className={cx('selected_process_title')}>결제방식</h4>
                                </div>
                            </div>
                            <div className={cx('selected_process_bar')}></div>
                            <div className={cx('selected_status_process_box')}>
                                <div className={cx('selected_process_title_box')}>
                                    <h4 className={cx('selected_process_title')}>계약현황 확인</h4>
                                </div>
                            </div>
                            <div className={cx('selected_process_bar')}></div>
                            <div className={cx('selected_process_box')}>
                                <div className={cx('selected_process_title_box')}>
                                    <h4 className={cx('selected_process_title')}>계약서 작성</h4>
                                </div>
                            </div>
                            <div className={cx('selected_process_bar')}></div>
                            <div className={cx('select_process_box')}>
                                <div className={cx('select_process_img_box')}>
                                    <img src="/statics/images/solution_select.png" alt="solution_select" />
                                </div>
                                <div className={cx('select_process_title_box')}>
                                    <h4 className={cx('select_process_title')}>입금 확인</h4>
                                </div>
                            </div>
                            <div className={cx('select_process_bar')}></div>
                            <div className={cx('process_box')}>
                                <div className={cx('process_title_box')}>
                                    <h4 className={cx('process_title')}>설치 스케줄</h4>
                                </div>
                            </div>
                        </div>
                        <div className={cx('mobile_header_inner')}>
                            <div className={cx('mobile_header_progressbar_box')}>
                                <div className={cx('mobile_header_progressbar')}></div>
                            </div>
                            <div className={cx('select_process_box')}>
                                <div className={cx('mobile_select_text_box')}>
                                    <p className={cx('mobile_select_text')}>step.7</p>
                                </div>
                                <div className={cx('mobile_select_process_title_box')}>
                                    <div className={cx('select_process_title_box')}>
                                        <h4 className={cx('select_process_title')}>입금 확인</h4>
                                    </div>
                                    <div className={cx('select_process_img_box')}>
                                        <img src="/statics/images/solution_select.png" alt="solution_select" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <div className={cx('checklist_button')} onClick={() => setButtonPopup(true)}>
                            <div className={cx('checklist_icon_box')}>
                                <img className={cx('checklist_icon')} src="/statics/images/check_list.png" alt="check_list" />
                            </div>
                            <div className={cx('hide_text_box')}>
                                <p className={cx('hide_text')}>체크리스트 보기</p>
                            </div>
                        </div>
                        <div className={cx('title_box')}>
                            <h4 className={cx('title')}>입금 확인</h4>
                        </div>
                        <div className={cx('content_inner')}>
                            <div className={cx('content_sub_inner')}>
                                <div className={cx('img_box')}>
                                    <img src="/statics/images/deposit_check.png" alt="deposit_check" />
                                </div>
                                <div className={cx('content_sub_title_box')}>
                                    <h4 className={cx('content_sub_title')}>
                                        입금을 확인중입니다.
                                    </h4>
                                </div>
                                <div className={cx('content_sub_text_box')}>
                                    <p className={cx('content_sub_text')}>
                                        입금 완료 화면이 나타날 때까지 조금만 기다려주세요.
                                    </p>
                                </div>
                                <div className={cx('review_botton_box')}>
                                    <button className={cx('review_botton')}>계약서 다시보기</button>
                                </div>
                                <div className={cx('consulting_box')}>
                                    <div className={cx('consulting_text_box')}>
                                        <p className={cx('consulting_text')}>
                                            진행이 어려우시다면 친절한 상담을 받아보세요!
                                        </p>
                                    </div>
                                    <div className={cx('consulting_tell_box')}>
                                        <p className={cx('consulting_tell')}>
                                            02-000-0000
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={cx('button_box')}>
                            <div className={cx('prev_button_box')}>
                                <NavLink to='/solution/deposit-wait' className={cx('prev_button')}>이전 단계 확인</NavLink>
                            </div>
                            <div className={cx('next_button_box')}>
                                <NavLink to='/solution/deposit-complete' className={cx('next_button')}>다음 단계로 이동</NavLink>
                            </div>
                        </div>
                        <Popup trigger={buttonPopup} setTrigger={setButtonPopup} />
                    </div>
                </div>
            </div>
        </div>
    )
}
