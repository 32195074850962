import React from 'react';
import classNames from 'classnames/bind';
import styles from './PigContent.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function PigContent() {

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('sub_container_bg')}>
                    <div {...useScrollClipPath('up', 1, 0.4)} className={cx('box_sub')}>

                    </div>
                    <div className={cx('inner')}>
                        <div className={cx('text_inner')}>
                            <div {...useScrollFadeIn('up', 1, 0.6)} className={cx('title_box')}>
                                <h4 className={cx('title')}>
                                    광고 운영을 통한<br className={cx('mobile_br')} /> 부가 수익 창출
                                </h4>
                            </div>
                            <div {...useScrollFadeIn('bottom', 1, 0.8)} className={cx('text_box')}>
                                <p className={cx('text')}>
                                    오직 특별한 멤버에게만 드리는 혜택.<br className={cx('mobile_br')} /> 프리미엄 멤버십을 확인해보세요!
                                </p>
                            </div>
                        </div>
                        <div {...useScrollClipPath('bottom', 1, 1.2)} className={cx('pig_img_box')}>
                            <img className={cx('pig_img')} src="/statics/images/pig.png" alt="pig" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
