export const CHANGE_DATA = 'CHANGE_DATA';

export const changeCompanyData = (payload) => ({ type: CHANGE_DATA, payload });

const initialState = {
  address: '',
  businessName: '',
  businessNumber: '',
  ceoName: '',
  email: '',
  facebookUrl: '',
  instagramUrl: '',
  youtubeUrl: '',
  onlineSaleRegistrationNumber: '',
  phone: '',
  privacyTerm: '',
  serviceTerm: '',
  advertiseRefundTerm: '',
};

function company(state = initialState, action) {
  switch (action.type) {
    case CHANGE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default company;
