import React, {
    useRef,
    useState,
    useEffect,
} from 'react';
import classNames from 'classnames/bind';
import styles from './SignPopup.module.scss';
import SignatureCanvas from 'react-signature-canvas'

const cx = classNames.bind(styles);

export default function SignPopup({
    trigger,
    setTrigger,
    onSign,
}) {
    const signRef = useRef(null);
    const handleClick = () => {
        onSign(signRef?.current?.getTrimmedCanvas());
    }

    return (trigger) ? (
        <div className={cx('container')}>
            {/* 바탕화면 클릭 시 닫힘 클릭 이벤트 필요없음 지우면 됨!!   */}
            <div onClick={() => setTrigger(false)} className={cx('sub_container')}></div>
            <div className={cx('inner')}>
                <div className={cx('inner_box')}>
                    <div className={cx('header')}>
                        <div className={cx('title_box')}>
                            <h4 className={cx('title')}>
                                전자서명하기
                        </h4>
                        </div>
                        <div onClick={() => setTrigger(false)} className={cx('close_button_box')}>
                            <img className={cx('close_button')} src="/statics/images/ic_pop_close.png" alt="ic_pop_close" />
                        </div>
                    </div>
                    <div className={cx('content_inner')}>
                        <div className={cx('sign_box')}>
                            <SignatureCanvas
                                ref={signRef}
                                penColor='black'
                                throttle={5}
                                canvasProps={{ width: 500, height: 240, className: 'sigCanvas' }}
                            />
                        </div>
                        <div className={cx('button_inner')}>
                            <div className={cx('button_box')}>
                                <button className={cx('initialization_button')} onClick={() => signRef?.current?.clear()}>
                                    서명 초기화
                            </button>
                            </div>
                            <div className={cx('button_box')}>
                                <button className={cx('complete_button')} onClick={handleClick}>
                                    입력 완료
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : '';
}
