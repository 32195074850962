import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeCompanyData } from 'store/modules/company';

export default function useCompany() {
  const company = useSelector((state) => state.company);
  const {
    address,
    businessName,
    businessNumber,
    ceoName,
    email,
    facebookUrl,
    instagramUrl,
    youtubeUrl,
    onlineSaleRegistrationNumber,
    phone,
    privacyTerm,
    serviceTerm,
    advertiseRefundTerm,
  } = company;
  const dispatch = useDispatch();

  const handleChangeCompanyData = useCallback(
    (payload) => dispatch(changeCompanyData(payload)),
    [dispatch],
  );

  return {
    company,
    address,
    businessName,
    businessNumber,
    ceoName,
    email,
    facebookUrl,
    instagramUrl,
    youtubeUrl,
    onlineSaleRegistrationNumber,
    phone,
    privacyTerm,
    serviceTerm,
    advertiseRefundTerm,
    handleChangeCompanyData,
  };
}
