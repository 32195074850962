import React from 'react';
import classNames from 'classnames/bind';
import styles from './IntroServiceProcess.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function IntroServiceProcess() {
    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <div {...useScrollFadeIn('up', 1, 0)} className={cx('title_box')}>
                            <h4 className={cx('title')}>
                                방문없는 간편한<br className={cx('mobile_br')} /> 구매 프로세스
                            </h4>
                        </div>
                        <div {...useScrollFadeIn('up', 1, 0.1)} className={cx('text_box')}>
                            <p className={cx('text')}>
                                별도 방문 없이 전자 계약 시스템으로 정확한 견적 산출을 통해 맞춤형 솔루션을 구매하실 수 있습니다.
                            </p>
                        </div>
                    </div>
                    <div className={cx('process_inner')}>
                        <div {...useScrollClipPath('left', 1, 0.2)} className={cx('process_img_box')}>
                            <img className={cx('process_img')} src="/statics/images/product_select.png" alt="product_select" />
                        </div>
                        <div {...useScrollClipPath('left', 1, 0.3)} className={cx('process_arrow_box')}>
                            <img className={cx('process_arrow')} src="/statics/images/ic_intro_arrow_forward.png" alt="arrow" />
                        </div>
                        <div {...useScrollClipPath('left', 1, 0.4)} className={cx('process_img_box')}>
                            <img className={cx('process_img')} src="/statics/images/estimating.png" alt="estimating" />
                        </div>
                        <div {...useScrollClipPath('left', 1, 0.5)} className={cx('process_arrow_box')}>
                            <img className={cx('process_arrow')} src="/statics/images/ic_intro_arrow_forward.png" alt="arrow" />
                        </div>
                        <div {...useScrollClipPath('left', 1, 0.6)} className={cx('process_img_box')}>
                            <img className={cx('process_img')} src="/statics/images/contract_writing.png" alt="contract_writing" />
                        </div>
                        <div {...useScrollClipPath('left', 1, 0.7)} className={cx('process_arrow_box')}>
                            <img className={cx('process_arrow')} src="/statics/images/ic_intro_arrow_forward.png" alt="arrow" />
                        </div>
                        <div {...useScrollClipPath('left', 1, 0.8)} className={cx('process_img_box')}>
                            <img className={cx('process_img')} src="/statics/images/schedule.png" alt="schedule" />
                        </div>
                        <div {...useScrollClipPath('left', 1, 0.9)} className={cx('process_arrow_box')}>
                            <img className={cx('process_arrow')} src="/statics/images/ic_intro_arrow_forward.png" alt="arrow" />
                        </div>
                        <div {...useScrollClipPath('left', 1, 1)} className={cx('process_img_box')}>
                            <img className={cx('process_img')} src="/statics/images/Hardware_installation.png" alt="Hardware_installation" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
