import React from 'react';
import Header from 'components/views/Header/Header';
import PlayListSearch from 'components/views/PlayListSearch/PlayListSearch';
import MediaContentPopup from 'components/views/MediaContentPopup';
import Footer from 'components/views/Footer/Footer';

export default function PlayListSearchWrapper({
  contents,
  categories,
  currentCategory,
  search,
  orderBy,
  currentPage,
  lastPage,
  perPage,
  onChange,
  onSearch,
  onClick,
  onKeyPress,
  handlePage,
  grade,
  wishPlayList,
  handleClickLike,
  handleClickDetail,
  handlePlayList,
  handleRemove,
  handleUpdatePlayList,
  handlePopupClose,
  isShow,
  selectedData,
  total,
  playList,
  playlistResetCount,
  handleResetPlayList,
  data,
  isUnlimitedPlaylist,
  isFetching,
}) {
  return (
    <>
      <Header />
      <PlayListSearch
        data={data}
        isFetching={isFetching}
        contents={contents}
        categories={categories}
        orderBy={orderBy}
        currentCategory={currentCategory}
        search={search}
        currentPage={currentPage}
        lastPage={lastPage}
        perPage={perPage}
        onChange={onChange}
        onClick={onClick}
        onSearch={onSearch}
        onKeyPress={onKeyPress}
        handlePage={handlePage}
        grade={grade}
        wishPlayList={wishPlayList}
        handleClickLike={handleClickLike}
        handleClickDetail={handleClickDetail}
        handlePlayList={handlePlayList}
        handleRemove={handleRemove}
        handleUpdatePlayList={handleUpdatePlayList}
        total={total}
        playList={playList}
        playlistResetCount={playlistResetCount}
        handleResetPlayList={handleResetPlayList}
        isUnlimitedPlaylist={isUnlimitedPlaylist}
      />
      <MediaContentPopup
        key={selectedData?.id}
        data={selectedData}
        isShow={isShow}
        onClose={handlePopupClose}
      />
      <Footer />
    </>
  );
}
