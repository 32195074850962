import React from 'react';
import { Header } from 'components/views';
import SolutionDepositComplete from 'components/views/SolutionDepositComplete';

export default function SolutionDepositCompleteWrapper({
  onClick,
  phone,
}) {
  return (
    <>
      <Header />
      <SolutionDepositComplete
        onClick={onClick}
        phone={phone}
      />
    </>
  )
}
