import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionInstallmentExaminationReview from 'components/views/SolutionInstallmentExaminationReview/SolutionInstallmentExaminationReview';

export default function SolutionInstallmentExaminationReviewWrapper() {
    return (
        <>
            <Header />
            <SolutionInstallmentExaminationReview />
        </>
    )
}
