import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import * as api from 'apis';
import useCompany from 'hooks/useCompany';
import useAuth from 'hooks/useAuth';
import {
    handleCheckSolution,
} from 'libs/authService';
import moment from 'moment';
import SolutionPublishEstimateSuccessWrapper from 'components/SolutionPublishEstimateSuccessWrapper';

export default function SolutionPublishEstimateSuccessContainer() {
    const navigator = useNavigate();
    const {
        isLogIn,
        estimate,
    } = useAuth();

    const [data, setData] = useState({
        fileUrl: '',
    });

    // 파일 업로드
    const [fileUrls, setFileUrls] = useState({
        0: {},
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
        6: {},
    });

    const [currentPage, setCurrentPage] = useState(1);

    const {
        phone,
    } = useCompany();

    useEffect(() => {
        (async () => {
            try {
                const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })]);

                setData({
                    fileUrl: res[0]?.data?.templateUrl,
                });

                handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
            } catch (err) {
                console.log(err);
            }
        })();
    }, [setData, isLogIn, navigator]);

    const handleClick = useCallback(() => {
        if (!data) return;
        window.open(data.fileUrl, `빔몬스터 견적서(${moment().format('YYYY-MM-DD HH:mm:ss')})`, 'status=no, menubar=no, toolbar=no, resizable=no');
    }, [data]);

    const handleDownload = useCallback(() => {
        try {
            fetch(data.fileUrl).then(resp => resp.arrayBuffer()).then(resp => {

                // set the blog type to final pdf
                const file = new Blob([resp], { type: 'application/pdf' });

                // process to auto download it
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = `빔몬스터 견적서(${moment().format('YYYY-MM-DD HH:mm:ss')}).pdf`;
                link.click();
                link.remove();
            });
        } catch (err) {

        }
    }, [data]);

    const handleRequest = useCallback(async () => {
        try {
            const fieldPhotos = fileUrls && Object.keys(fileUrls).map((key) => fileUrls[key]?.url).filter(url => !!url);

            await api.updateEstimate(estimate.id, {
                fieldPhotos,
            });
            await api.requestAdminConfirm(estimate.id);
            navigator('/solution/install/request');
        } catch (err) {
            console.log(err);
        }
    }, [estimate?.id, fileUrls]);


    return (
        <SolutionPublishEstimateSuccessWrapper
            estimateId={estimate?.id}
            fileUrl={data.fileUrl}
            fileUrls={fileUrls}
            handleFileUrls={setFileUrls}
            onClick={handleClick}
            onDownload={handleDownload}
            phone={phone}
            estimateUrl={data.fileUrl}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            handleRequest={handleRequest}
        />
    )
}
