import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './MypageSecession.module.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function MypageSecession({
    password,
    isChecked,
    handleChange,
    handleClick,
    handleSubmit,
}) {

    const [passwordType, setPasswordType] = useState({
        type: 'password',
        visible: false
    });

    const handlePasswordType = (e) => {
        e.preventDefault();
        setPasswordType(() => {
            if (!passwordType.visible) {
                return { type: 'text', visible: true };
            }
            return { type: 'password', visible: false };
        })
    }

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <Link to='/mypage' className={cx('back_box')}>
                        <img className={cx('back_img')} src="/statics/images/ic_profile_back.png" alt="ic_profile_back" />
                        <div className={cx('back_text_box')}>
                            <p className={cx('back_text')}>마이페이지</p>
                        </div>
                    </Link>
                    <div className={cx('inner_box')}>
                        <div className={cx('title_box')}>
                            <h4 className={cx('title')}>
                                계정 탈퇴
                            </h4>
                        </div>
                        <div className={cx('text_box')}>
                            <p className={cx('text')}>
                                계정을 탈퇴하시면 빔몬스터 서비스를 이용하셨던
                                모든 데이터는 삭제되며, 환불 및 복구가 불가능합니다.
                            </p>
                        </div>
                        <div className={cx('password_input_box')}>
                            <div className={cx('password_img_box')}>
                                <input
                                    className={cx('password_input')}
                                    type={passwordType.type}
                                    placeholder="비밀번호 재확인"
                                    value={password}
                                    name="password"
                                    onChange={handleChange}
                                />
                                <span className={cx('password_view_img')} onClick={handlePasswordType}>
                                    {
                                        passwordType.visible ?
                                            <img src="/statics/images/ic_pw_visibility_on.png" alt="hide" /> :
                                            <img src="/statics/images/ic_pw_visibility_off.png" alt="view" />
                                    }
                                </span>
                            </div>
                            <div className={cx('password_check_box')}>
                                <input id='check' className={cx('check_box', { active: isChecked })} type="checkbox" onClick={handleClick} />
                                <label className={cx('check_box_label')} htmlFor="check"></label>
                                <label className={cx('password_check_label')} htmlFor="check"><b className={cx('password_check_label_b')}>위 내용들을 모두 확인</b>하였으며, 이에 동의합니다.</label>
                            </div>
                        </div>
                        <div className={cx('button_box')}>
                            <button className={cx('button', { disabled: !password || !isChecked })} disabled={!password || !isChecked} onClick={handleSubmit}>탈퇴하기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
