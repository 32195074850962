import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './SolutionRequestEstimateLed.module.scss';
import CancelSolutionButton from 'components/common/CancelSolutionButton';
import { NavLink } from 'react-router-dom';
import Popup from '../Popup/Popup';

const cx = classNames.bind(styles);

export default function SolutionRequestEstimateLed({
  data,
  onChange,
  onSubmit,
}) {
  const [buttonPopup, setButtonPopup] = useState(false);

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('header_container')}>
          <div className={cx('inner')}>
            <div className={cx('header_inner')}>
              <div className={cx('selected_process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('selected_process_title')}>상품선택</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('select_process_box')}>
                <div className={cx('select_process_img_box')}>
                  <img
                    src="/statics/images/solution_select.png"
                    alt="solution_select"
                  />
                </div>
                <div className={cx('select_process_title_box')}>
                  <h4 className={cx('select_process_title')}>견적요청</h4>
                </div>
              </div>
              <div className={cx('select_process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>견적검토</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>설치확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>유저정보</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>결제방식</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_status_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>계약현황 확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>계약서 작성</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>입금 확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>설치 스케줄</h4>
                </div>
              </div>
            </div>
            <div className={cx('mobile_header_inner')}>
              <div className={cx('mobile_header_progressbar_box')}>
                <div className={cx('mobile_header_progressbar')}></div>
              </div>
              <div className={cx('select_process_box')}>
                <div className={cx('mobile_select_text_box')}>
                  <p className={cx('mobile_select_text')}>step.2</p>
                </div>
                <div className={cx('mobile_select_process_title_box')}>
                  <div className={cx('select_process_title_box')}>
                    <h4 className={cx('select_process_title')}>견적요청</h4>
                  </div>
                  <div className={cx('select_process_img_box')}>
                    <img
                      src="/statics/images/solution_select.png"
                      alt="solution_select"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <CancelSolutionButton />
            <div
              className={cx('checklist_button')}
              onClick={() => setButtonPopup(true)}
            >
              <div className={cx('checklist_icon_box')}>
                <img
                  className={cx('checklist_icon')}
                  src="/statics/images/check_list.png"
                  alt="check_list"
                />
              </div>
              <div className={cx('hide_text_box')}>
                <p className={cx('hide_text')}>체크리스트 보기</p>
              </div>
            </div>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>LED 패널 크기 입력</h4>
            </div>
            <div className={cx('size_inner')}>
              <div className={cx('size_view_inner')}>
                <div className={cx('size_height_panel')}>
                  <hr className={cx('height_panel')} />
                  <p className={cx('panel_height_text')}>H</p>
                  <hr className={cx('height_panel')} />
                </div>
                <div className={cx('size_width_panel')}>
                  <hr className={cx('panel_hr')} />
                  <p className={cx('panel_text')}>W</p>
                  <hr className={cx('panel_hr')} />
                </div>
                <div className={cx('size_view_box')}>
                  <p className={cx('size_view_text')}>LED 패널</p>
                </div>
              </div>
              <div className={cx('size_select_box')}>
                <div className={cx('size_select_input_box')}>
                  <label className={cx('size_label')}>
                    W값 <span>(단위 : mm)</span>
                  </label>
                  <input
                    className={cx('size_input')}
                    name="width"
                    onChange={onChange}
                    value={data.width}
                    type="text"
                    placeholder="숫자만 입력"
                  />
                </div>
                <div className={cx('size_select_input_box')}>
                  <label className={cx('size_label')}>
                    H값 <span>(단위 : mm)</span>
                  </label>
                  <input
                    className={cx('size_input')}
                    name="height"
                    onChange={onChange}
                    value={data.height}
                    type="text"
                    placeholder="숫자만 입력"
                  />
                </div>
              </div>
            </div>
            <div className={cx('denger_text_box')}>
              <p className={cx('denger_text')}>
                * 실제 벽면 사이즈에 가로/세로 15cm 가량 여유를 두고 측정하세요!
              </p>
            </div>
            <div className={cx('button_box')}>
              <div className={cx('prev_button_box')}>
                <NavLink
                  to="/solution/choice-product"
                  className={cx('prev_button')}
                >
                  이전 단계 확인
                </NavLink>
              </div>
              <div className={cx('next_button_box')}>
                <button className={cx('next_button')} onClick={onSubmit}>
                  다음 단계로 이동
                </button>
              </div>
            </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} />
          </div>
        </div>
      </div>
    </div>
  );
}
