import React, { useEffect, useRef, useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './SixCheckList.module.scss';

const cx = classNames.bind(styles);

export default function SixCheckList() {
    return (
        <div className={cx('inner')}>
            <div className={cx('img_box')}>
                <img className={cx('img')} src="/statics/images/checklist_wall_state.png" alt="checklist_wall_state" />
            </div>
            <div className={cx('text_box')}>
                <p className={cx('text')}>
                    LED 패널의 무게 하중을 견디기 위해서는 콘크리트벽과 목공벽이 가장 좋습니다.
                </p>
            </div>
        </div>
    )
}
