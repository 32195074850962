import React, {
  useEffect,
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import * as api from 'apis';
import useContract from 'hooks/useContract';
import useAuth from 'hooks/useAuth';
import {
  handleCheckSolution,
} from 'libs/authService';
import SolutionRentalWaitWrapper from 'components/SolutionRentalWaitWrapper/SolutionRentalWaitWrapper';

export default function SolutionRentalWaitContainer() {
  const navigator = useNavigate();
  const {
    isLogIn,
  } = useAuth();

  const {
    contractId,
    handleChangeContractData,
  } = useContract();

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })]);
        handleChangeContractData({
          contractId: res[1]?.data?.id
        });
        if (res[1]?.data?.status === "RENTAL_CHECKED" || res[1]?.data?.status === "UPLOADED") {
          navigator('/solution/contract-waiting');
        } else {
          handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [isLogIn, navigator]);

  return (
    <SolutionRentalWaitWrapper />
  )
}
