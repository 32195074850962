import React, { useEffect, useRef, useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './FourCheckList.module.scss';

const cx = classNames.bind(styles);

export default function FourCheckList() {
  return (
    <div>
      <div className={cx('list_title_inner')}>
        <h4 className={cx('list_title', 'black')}>
          빔프로젝터 선택시 벽면 색상 추천
        </h4>
        <p className={cx('txt_cation')}>
          * 벽면 색에 따라 영상이 왜곡 될 수 있습니다.
        </p>
      </div>
      <div className={cx('img_box')}>
        <img
          className={cx('img')}
          src="/statics/images/gradation.png"
          alt="gradation"
        />
      </div>
      <div className={cx('text_box')}>
        <p className={cx('text')}>
          최적의 빔 프로젝터 투사 환경을 위해 벽면의 상태로는 무광 실내 수성
          페인트가 가장 좋습니다.
        </p>
      </div>
      <div className={cx('content_inner')}>
        <div className={cx('title_inner')}>
          <img src="/statics/images/ic_error.png" alt="ic_error" />
          <div className={cx('title_box')}>
            <h4 className={cx('title')}>이런 벽면은 안돼요!!</h4>
          </div>
        </div>
        <div className={cx('content_box')}>
          <div className={cx('list_img_box')}>
            <img
              className={cx('list_img')}
              src="/statics/images/black_wall.png"
              alt="black_wall"
            />
          </div>
          <div className={cx('list_img_box')}>
            <img
              className={cx('list_img')}
              src="/statics/images/red_wall.png"
              alt="red_wall"
            />
          </div>
          <div className={cx('list_img_box')}>
            <img
              className={cx('list_img')}
              src="/statics/images/orange_wall.png"
              alt="orange_wall"
            />
          </div>
          <div className={cx('list_img_box')}>
            <img
              className={cx('list_img')}
              src="/statics/images/blue_wall.png"
              alt="blue_wall"
            />
          </div>
        </div>
        <div className={cx('caution_inner')}>
          <div>
            <img
              src="/statics/images/ic_input_info_error.png"
              alt="ic_input_info_error"
            />
          </div>
          <div className={cx('caution_text_box')}>
            <p className={cx('caution_text')}>
              빔프로젝터는 창문 개수, 직사광선 유무에 따라 현장 밝기에 영향을
              받습니다.
            </p>
            <p className={cx('caution_text')}>
              색이 있는 벽면의 경우 위의 이미지처럼 영상이 선명하게 보이지 않을
              수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
