import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionInstallmentExaminationChoice from 'components/views/SolutionInstallmentExaminationChoice/SolutionInstallmentExaminationChoice';

export default function SolutionInstallmentExaminationChoiceWrapper() {
    return (
        <>
            <Header />   
            <SolutionInstallmentExaminationChoice />
        </>
    )
}
