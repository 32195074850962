import React, {
    useCallback,
    useEffect,
} from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import useEstimate from 'hooks/useEstimate';
import useAuth from 'hooks/useAuth';
import {
    handleCheckSolution,
} from 'libs/authService';
import * as api from 'apis';
import SolutionRequestEstimateSolutionWrapper from 'components/SolutionRequestEstimateSolutionWrapper/SolutionRequestEstimateSolutionWrapper';

export default function SolutionRequestEstimateSolutionContainer() {
    const navigator = useNavigate();
    const {
        isLogIn,
    } = useAuth();

    const {
        estimateId,
        solutionType,
        handleChangeData,
    } = useEstimate();

    useEffect(() => {
        (async () => {
            try {
                const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })])
                handleChangeData({
                    estimateId: res[0]?.data?.id || null,
                    hardware: res[0]?.data?.hardware || null,
                    beamSize: res[0]?.data?.beamSize || '',
                    ledWidth: res[0]?.data?.ledWidth * 1000 || '',
                    ledHeight: res[0]?.data?.ledHeight * 1000 || '',
                    address: res[0]?.data?.address || '',
                    detailAddress: res[0]?.data?.detailAddress || '',
                    extraInfo: res[0]?.data?.extraInfo || '',
                    solutionType: res[0]?.data?.solutionType || '',
                });

                handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
            } catch (err) {
                console.log(err);
            }
        })()
    }, [handleChangeData, isLogIn, navigator]);

    const handleSubmit = useCallback(async (data) => {
        try {
            let solutionType = '';
            if (data.basic) {
                solutionType = 'BASIC';
            } else if (data.standard) {
                solutionType = 'STANDARD';
            } else if (data.premium) {
                solutionType = 'PREMIUM';
            }

            if (!solutionType) {
                alert('솔루션을 선택해주세요 : )');
                return;
            }

            if (window.confirm("견적서 제출 시 수정이 불가합니다.\n제출하시겠습니까?")) {
                await api.updateEstimate(estimateId, {
                    solutionType,
                });
                handleChangeData({
                    solutionType,
                });
                await api.requestEstimate(estimateId)
                navigator('/solution/publish-estimate/wait');
            }
        } catch (err) {
            console.log(err);
        }
    }, [handleChangeData, navigator]);

    // useEffect(() => {
    //     if (estimateId) return;
    //     alert('잘못된 접근입니다.');
    //     navigator('/');
    // }, [estimateId, navigator]);

    return (
        <SolutionRequestEstimateSolutionWrapper
            solutionType={solutionType}
            onSubmit={handleSubmit}
        />
    )
}
