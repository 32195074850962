import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { numberWithCommas } from 'libs/numbers';
import styles from './PlayListArtistSubDetail.module.scss';
import { useNavigate } from 'react-router-dom';
import Pagination from 'components/common/Pagination';

const cx = classNames.bind(styles);

export default function PlayListArtistSubDetail({
  contents,
  pageData,
  handlePage,
  handleClickDetail,
  handleClickLike,
  parentContentId,
  wishPlayList,
  handleWishList,
  myPlayList,
}) {
  // 장바구니 이미지 클릭시 이벤트
  const [basket, setBasket] = useState(false);

  // 디테일 페이지 이동
  const navigator = useNavigate();

  const onClickDetailMove = (e, id) => {
    e.stopPropagation();
    navigator(`/play-list/artist/detail?id=${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <div className={cx('title_box')}>
          <p className={cx('title')}>작가 다른 작품 보기</p>
        </div>
        <div className={cx('suggestion_list_inner')}>
          {/* 아무것도 없을 때 전체 */}
          {!contents?.length && (
            <div className={cx('empty_box')}>
              <p className={cx('empty_title')}>
                아티스트 콘텐츠 준비 중 입니다.
              </p>
              <p className={cx('empty_text')}>
                최대한 빠르게 준비해 드리겠습니다.
              </p>
            </div>
          )}
          {contents?.length > 0 &&
            contents?.map((el, index) => {
              return (
                <div
                  key={el.id}
                  className={cx('suggestion_box', {
                    isSame: parentContentId === el?.id,
                  })}
                  role="presentation"
                  onClick={(e) =>
                    parentContentId !== el?.id && handleClickDetail(e, el)
                  }
                >
                  <div className={cx('suggestion_img_box')}>
                    <div
                      onClick={(e) =>
                        parentContentId !== el?.id &&
                        onClickDetailMove(e, el?.id)
                      }
                      className={cx('detail_img_box')}
                    >
                      <img
                        src="/statics/images/ic-input-search.png"
                        alt="상세보기 아이콘"
                      />
                    </div>
                    {myPlayList?.indexOf(el.id) > -1 ? (
                      <div className={cx('active_basket_img_box')}>
                        <img
                          src="/statics/images/ic-paid.png"
                          alt="결제된 아이콘"
                        />
                      </div>
                    ) : (
                      <div
                        onClick={(e) => handleWishList(e, el)}
                        className={cx(
                          [...wishPlayList.map((p) => p.id)].indexOf(el?.id) >
                            -1
                            ? 'active_basket_img_box'
                            : 'basket_img_box',
                        )}
                      >
                        <img
                          src="/statics/images/ic-playlist-cart.png"
                          alt="장바구니 추가 아이콘"
                        />
                      </div>
                    )}
                    {[...wishPlayList.map((p) => p.id)].indexOf(el?.id) > -1 ? (
                      <>
                        {el?.thumbnailUrl ? (
                          <img
                            className={cx('active_suggestion_img')}
                            src={el?.thumbnailUrl}
                            alt="추천 콘텐츠 이미지"
                          />
                        ) : (
                          <img
                            className={cx('active_suggestion_img')}
                            src="/statics/images/pink_cloud.jpg"
                            alt="추천 콘텐츠 이미지"
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {el?.thumbnailUrl ? (
                          <img
                            className={cx('suggestion_img')}
                            src={el?.thumbnailUrl}
                            alt="추천 콘텐츠 이미지"
                          />
                        ) : (
                          <img
                            className={cx('suggestion_img')}
                            src="/statics/images/pink_cloud.jpg"
                            alt="추천 콘텐츠 이미지"
                          />
                        )}
                      </>
                    )}
                    <div className={cx('quality_box')}>
                      <p className={cx('quality_text')}>{el?.resolution}</p>
                    </div>
                  </div>
                  <div className={cx('suggestion_info_inner')}>
                    <div className={cx('suggestion_info_box')}>
                      <div className={cx('suggestion_info_title_box')}>
                        <p className={cx('suggestion_info_title')}>
                          {el?.title}
                        </p>
                        <p className={cx('suggestion_info_name')}>
                          {el?.creator?.name}
                        </p>
                      </div>
                      <div
                        onClick={(e) =>
                          parentContentId !== el?.id &&
                          handleClickLike(e, el?.id)
                        }
                        className={cx('suggestion_info_img_box')}
                      >
                        {el?.liked ? (
                          <img
                            className={cx('on_like_img')}
                            src="/statics/images/ic_playlist_like_on.png"
                            alt="하트 이미지"
                          />
                        ) : (
                          <img
                            className={cx('off_like_img')}
                            src="/statics/images/ic_playlist_like_off.png"
                            alt="하트 이미지"
                          />
                        )}
                      </div>
                    </div>
                    <div className={cx('suggestion_payment_box')}>
                      <p className={cx('suggestion_payment')}>{`${
                        el?.price ? numberWithCommas(el?.price) : el?.price
                      }`}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          {pageData.total && pageData.total !== 0 && (
            <Pagination
              currentPage={pageData.currentPage}
              lastPage={pageData.lastPage}
              perPage={pageData.perPage}
              handlePage={handlePage}
            />
          )}
        </div>
      </div>
    </div>
  );
}
