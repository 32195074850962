import React, {
    useState,
    useCallback,
    useEffect,
} from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import useEstimate from 'hooks/useEstimate';
import useAuth from 'hooks/useAuth';
import {
    handleCheckSolution,
} from 'libs/authService';
import * as api from 'apis';
import SolutionRequestEstimatePlaceWrapper from 'components/SolutionRequestEstimatePlaceWrapper/SolutionRequestEstimatePlaceWrapper'

export default function SolutionRequestEstimatePlaceContainer() {
    const navigator = useNavigate();
    const {
        isLogIn,
    } = useAuth();

    const {
        estimateId,
        hardware,
        address,
        detailAddress,
        extraInfo,
        handleChangeData,
    } = useEstimate();
    const [data, setData] = useState({
        tempAddress: '',
        tempDetailAddress: '',
        tempExtraInfo: '',
    });
    const [isOpenPost, setIsOpenPost] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })])
                handleChangeData({
                    estimateId: res[0]?.data?.id || null,
                    hardware: res[0]?.data?.hardware || null,
                    beamSize: res[0]?.data?.beamSize || '',
                    ledWidth: res[0]?.data?.ledWidth * 1000 || '',
                    ledHeight: res[0]?.data?.ledHeight * 1000 || '',
                    address: res[0]?.data?.address || '',
                    detailAddress: res[0]?.data?.detailAddress || '',
                    extraInfo: res[0]?.data?.extraInfo || '',
                });

                handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
            } catch (err) {
                console.log(err);
            }
        })()
    }, [handleChangeData, isLogIn, navigator]);

    const handleClickAddress = useCallback((
        zonecode,
        address,
    ) => {
        setData(prev => ({
            ...prev,
            tempAddress: address,
            tempDetailAddress: '',
        }))
    }, [setData]);

    const handleSubmit = useCallback(async () => {
        try {
            const {
                tempAddress,
                tempDetailAddress,
                tempExtraInfo,
            } = data;
            if (!tempAddress || !tempDetailAddress) {
                alert('설치 장소를 입력해주세요 : )');
                return;
            }
            const submitData = {
                address: tempAddress,
                detailAddress: tempDetailAddress,
                extraInfo: tempExtraInfo,
            };

            await api.updateEstimate(estimateId, submitData);
            handleChangeData(submitData);
            navigator('/solution/request-estimate/solution')
        } catch (err) {
            console.log(err);
        }
    }, [data, navigator]);

    const handleChange = useCallback((e) => {
        setData(prev => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    }, [setData]);

    const handleOpenPost = useCallback(() => {
        setIsOpenPost(true);
    }, [setIsOpenPost]);

    const handleClosePost = useCallback(() => {
        setIsOpenPost(false);
    }, [setIsOpenPost]);

    // useEffect(() => {
    //     if (estimateId) return;
    //     alert('잘못된 접근입니다.');
    //     navigator('/');
    // }, [estimateId, navigator]);

    useEffect(() => {
        setData(prev => ({
            ...prev,
            tempAddress: address,
            tempDetailAddress: detailAddress,
            tempExtraInfo: extraInfo,
        }));
    }, [
        address,
        detailAddress,
        extraInfo,
        setData,
    ]);

    const handlePrev = useCallback(() => {
        if (hardware === 'LED') {
            navigator('/solution/request-estimate/led')
        } else {
            navigator('/solution/request-estimate/beam')
        }
    }, [navigator, hardware]);

    return (
        <SolutionRequestEstimatePlaceWrapper
            address={data.tempAddress}
            detailAddress={data.tempDetailAddress}
            extraInfo={data.tempExtraInfo}
            isOpenPost={isOpenPost}
            handleOpenPost={handleOpenPost}
            handleClosePost={handleClosePost}
            handleClickAddress={handleClickAddress}
            onSubmit={handleSubmit}
            onChange={handleChange}
            handlePrev={handlePrev}
        />
    )
}
