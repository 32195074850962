import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './AdvertisementSelectedStore.module.scss';
import { numberWithCommas } from 'libs/numbers';
// import InfiniteScroll from 'react-infinite-scroller';

const cx = classNames.bind(styles);

const WEEKS = {
  MONDAY: '월요일',
  TUESDAY: '화요일',
  WEDNESDAY: '수요일',
  THURSDAY: '목요일',
  FRIDAY: '금요일',
  SATURDAY: '토요일',
  SUNDAY: '일요일',
};

export default function AdvertisementSelectedStore({
  handleSingleCheck,
  handleAllCheck,
  storeCheckList,
  selectedStores,
  checkDelete,
}) {
  return (
    <div className={cx('category_inner')}>
      <div className={cx('category_title_box')}>
        <p className={cx('category_title')}>
          스토어 리스트
          <b className={cx('category_text')}>
            검색 된 스토어 리스트입니다. 스토어의 데이터는 사전에 산정된 것이며,
            실제 집행 시점에는 통행량이 다를 수 있습니다.
          </b>
        </p>
      </div>
      <div className={cx('selected_store_inner')}>
        <div className={cx('selected_header_box')}>
          <div className={cx('store_title_box')}>
            <p className={cx('category_title', 'mobile_category_title')}>
              선택된 스토어
            </p>
            <b className={cx('store_text_b')}>{selectedStores.length}</b>
          </div>
          <div className={cx('store_select_box')}>
            <button className={cx('all_button')}>
              <input
                type="checkbox"
                className={cx('store_hide_check_box')}
                id="store_selected_all"
                onChange={(e) => handleAllCheck(e.target.checked)}
                checked={
                  storeCheckList.length === selectedStores.length ? true : false
                }
                readOnly
              />
              <label
                className={cx('store_check_box_label')}
                htmlFor="store_selected_all"
              >
                <span className={cx('store_check_box')}>
                  <span className={cx('store_check_box_checked')}></span>
                </span>
                <b className={cx('store_check_text')}>전체 선택</b>
              </label>
            </button>
            <button
              className={cx('cancel_button')}
              onClick={() => checkDelete(storeCheckList)}
            >
              <img
                className={cx('cancel_icon')}
                src="/statics/images/ic-select-del-pink.png"
                alt="선택 삭제 이미지"
              />
              <p className={cx('cancel_check_text')}>선택 삭제</p>
            </button>
          </div>
        </div>
        <div className={cx('selected_list_inner')}>
          {selectedStores.map((el) => {
            return (
              <div
                role="presentation"
                onClick={() => handleSingleCheck(el.id)}
                key={el.id}
                className={cx('selected_list_box')}
              >
                {storeCheckList.some((item) => item === el.id) ? (
                  <div
                    className={cx('active_selected_store_item')}
                    style={{
                      backgroundImage: el?.profileImageUrl
                        ? `url(${el?.profileImageUrl})`
                        : 'url(/statics/images/beammonster.jpeg)',
                    }}
                  >
                    <img
                      className={cx('acive_selected_img')}
                      src="/statics/images/ic-task-alt.png"
                      alt="선택 표시 이미지"
                    />
                  </div>
                ) : (
                  <div
                    className={cx('selected_store_item')}
                    style={{
                      backgroundImage: el?.profileImageUrl
                        ? `url(${el?.profileImageUrl})`
                        : 'url(/statics/images/beammonster.jpeg)',
                    }}
                  ></div>
                )}
                <div className={cx('selected_store_info_inner')}>
                  <p className={cx('selected_store_info_title')}>
                    {el.businessName}
                  </p>
                  <div className={cx('selected_store_info_box')}>
                    <div className={cx('select_store_info_avg_box')}>
                      <p className={cx('select_store_info_avg_text')}>업종</p>
                      <p className={cx('selected_store_info_avg_text_b')}>
                        {`${el?.businessCategory} - ${el?.businessSubCategory}`}
                      </p>
                    </div>
                  </div>
                  <div className={cx('selected_store_info_box')}>
                    <div className={cx('selected_store_info_avg_box')}>
                      <p className={cx('selected_store_info_avg_text')}>
                        일 평균 방문객
                      </p>
                      <p className={cx('selected_store_info_avg_text_b')}>
                        {numberWithCommas(el?.businessDailyVisitor) || '-'}
                      </p>
                    </div>
                    <span
                      className={cx('selected_store_info_avg_text_hr')}
                    ></span>
                    <div className={cx('selected_store_info_avg_box')}>
                      <p className={cx('selected_store_info_avg_text')}>
                        최대수용 인원
                      </p>
                      <p className={cx('selected_store_info_avg_text_b')}>
                        {numberWithCommas(el?.businessMaxSeat) || '-'}
                      </p>
                    </div>
                  </div>
                  <div className={cx('selected_store_info_address_box')}>
                    <p
                      className={cx('selected_store_info_text')}
                    >{`${el?.businessAddress} ${el?.businessDetailAddress}`}</p>
                    <div className={cx('selected_store_info_text_box')}>
                      <p
                        className={cx('selected_store_info_text')}
                      >{`${el?.businessOpenTime} ~ ${el?.businessCloseTime}`}</p>
                      <span className={cx('selected_store_info_rest')}></span>
                      <p className={cx('selected_store_info_text')}>
                        {el?.dayoff?.length > 0
                          ? `매주 ${el?.dayoff
                              ?.map((el) => WEEKS[el])
                              .join(', ')}`
                          : '휴일 없음'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
