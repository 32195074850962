import React from 'react';
import classNames from 'classnames/bind';
import styles from './SolutionSealComplete.module.scss';
import { NavLink } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function SolutionSealComplete() {
    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('back_inner')}>
                    <NavLink to='/solution/contract-complete' className={cx('back_box')}>
                        <div className={cx('back_img_box')}>
                            <img src="/statics/images/ic_profile_back.png" alt="ic_profile_back" />
                        </div>
                        <div className={cx('back_text_box')}>
                            <p className={cx('back_text')}>
                                이전 페이지로 돌아가기
                        </p>
                        </div>
                    </NavLink>
                </div>
                <div className={cx('inner')}>
                    <div className={cx('sign_box')}>
                    </div>
                    <div className={cx('button_inner')}>
                        <div className={cx('button_box')}>
                            <NavLink to='/solution/seal'>
                                <button className={cx('reset_button')}>서명 다시하기</button>
                            </NavLink>
                        </div>
                        <div className={cx('button_box')}>
                            <NavLink to='/solution/deposit-wait'>
                                <button className={cx('complete_button')}>서명 완료</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
