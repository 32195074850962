import React from 'react';
import classNames from 'classnames/bind';
import DragDropFile from 'components/common/DragDropFile';
import styles from './SealPopup.module.scss';
import { NavLink } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function SealPopup({
    onChange,
    onClick,
    setTrigger,
    trigger,
}) {

    return (trigger) ? (
        <div className={cx('container')}>
            {/* 바탕화면 클릭 시 닫힘 클릭 이벤트 필요없음 지우면 됨!!   */}
            <div onClick={() => setTrigger(false)} className={cx('sub_container')}></div>
            <div className={cx('inner')}>
                <div className={cx('inner_box')}>
                    <div className={cx('header')}>
                        <div className={cx('title_box')}>
                            <h4 className={cx('title')}>
                                인감도장 등록하기
                        </h4>
                        </div>
                        <div onClick={() => setTrigger(false)} className={cx('close_button_box')}>
                            <img className={cx('close_button')} src="/statics/images/ic_pop_close.png" alt="ic_pop_close" />
                        </div>
                    </div>
                    <div className={cx('content_inner')}>
                        {/* <div className={cx('sign_box')}>
                            <div className={cx('seal_img_box')}>
                                <img className={cx('seal_img')} src="/statics/images/ic_sign_upload.png" alt="ic_sign_upload" />
                            </div>
                            <p className={cx('sign_text')}>
                                파일 찾기 버튼을 눌러 서명 또는<br /> 인감도장 이미지를 등록해주세요.
                            </p>
                        </div> */}
                        <DragDropFile
                            onChange={onChange}
                        />
                        <div className={cx('button_inner')}>
                            <div className={cx('button_box')}>
                                {/* <input id='upload' type='file' className={cx('upload_input')} onChange={handleUpload} /> */}
                                {/* <label htmlFor="upload" className={cx('complete_button')}>업로드</label> */}
                                <button className={cx('complete_button')} onClick={onClick}>업로드</button>

                                {/* 파일 업로드 후 NavLink 이거 쓰면 될듯... */}
                                {/* <NavLink to='/solution/seal-complete'>
                                <label htmlFor="upload" className={cx('complete_button')}>업로드</label>
                            </NavLink> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : '';
}
