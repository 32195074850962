import React from 'react';
import classNames from 'classnames/bind';
import styles from './MypagInquiry.module.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function MypagInquiry({
    title,
    content,
    files,
    onChange,
    onUpload,
    onRemove,
    onSubmit,
}) {
    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <div className={cx('title_box')}>
                            <h4 className={cx('title')}>
                                고객센터
                            </h4>
                        </div>
                        <div className={cx('tab_inner')}>
                            <hr className={cx('tab_hr')} />
                            <Link to='/mypage/faq' className={cx('tab_box')}>
                                <h5 className={cx('sub_tab')}>
                                    자주 묻는 질문
                                </h5>
                            </Link>
                            <Link to='/mypage/inquiry' className={cx('tab_box')}>
                                <h5 className={cx('tab')}>
                                    제휴 문의
                                </h5>
                            </Link>
                        </div>
                        <div className={cx('content_inner')}>
                            <div className={cx('content_text_box')}>
                                <p className={cx('content_text')}>
                                    제안하고 싶은 제휴 내용을 작성해주세요.
                                </p>
                            </div>
                            <div className={cx('content_sub_text_box')}>
                                <p className={cx('content_sub_text')}>
                                    작성 시, 고객님의 정보는 자동으로 입력됩니다. 빠른 시일내로 답변 드리겠습니다.
                                </p>
                            </div>
                            <hr className={cx('hr')} />
                            <div className={cx('input_box')}>
                                <label className={cx('label')}>
                                    제목 입력
                                </label>
                                <input className={cx('input')} name="title" value={title} onChange={onChange} placeholder='제휴 내용과 관련된 제목을 입력해주세요.' type="text" />
                            </div>
                            <div className={cx('textarea_box')}>
                                <label className={cx('label')}>
                                    상세 내용 입력
                                </label>
                                <textarea className={cx('textarea')} name="content" value={content} onChange={onChange} placeholder='제안을 희망하는 상세한 제휴 내용을 작성해주세요.' />
                            </div>
                            <div className={cx('upload_box')}>
                                {
                                    files.length > 0 && (
                                        <div className={cx('list_upload')}>
                                            {files.map((el, i) => {
                                                return (
                                                    <a key={`${el.filename}-${i}`} className={cx('download_file')} download href={el.url}>
                                                        {el.filename}
                                                        <button className={cx('btn_remove')} onClick={(e) => onRemove(e, i)}></button>
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    )
                                }
                                {
                                    files.length === 0
                                    && (
                                        <p className={cx('upload_text')}>
                                            제안서 및 회사소개서 또는 관련 자료들을 첨부해주세요.
                                        </p>
                                    )
                                }
                                <label className={cx('upload_label')} htmlFor="file">찾아보기</label>
                                <input className={cx('upload')} id="file" type="file" name="files" multiple onChange={onUpload} />
                            </div>
                            <div className={cx('button_box')}>
                                <button className={cx('button')} onClick={onSubmit}>문의 등록하기</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
