import React from 'react';
import classNames from 'classnames/bind';
import styles from './IntroServiceTrend.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function IntroServiceTrend() {
  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('inner')}>
          <div className={cx('sub_inner')}>
            <div className={cx('inner_box')}>
              <div {...useScrollFadeIn('up', 1, 0)} className={cx('title_box')}>
                <h4 className={cx('title')}>
                  지루할 틈 없이 쉽고,
                  <br className={cx('mobile_br')} /> 빠르고, 다양하게
                </h4>
              </div>
              <div
                {...useScrollFadeIn('up', 1, 0.1)}
                className={cx('text_box')}
              >
                <p className={cx('text')}>
                  어느 장비에서나, MD 추천과 함께 분기별로 업데이트되는 1,000여
                  개 이상의 미디어 콘텐츠로 트렌디한 인테리어를 연출해 보세요.
                </p>
              </div>
            </div>
            <div
              {...useScrollFadeIn('up', 1, 0.2)}
              className={cx('img_right_box')}
            >
              <img
                className={cx('right_img')}
                src="/statics/images/ic_service_pc_m_t.png"
                alt="ic_service_pc_m_t"
              />
            </div>
          </div>
          <div className={cx('top_img_inner')}>
            <div
              {...useScrollClipPath('right', 1, 0.2)}
              className={cx('img_box')}
            >
              <img
                className={cx('img')}
                src="/statics/images/moon_beach.jpg"
                alt="moon_beach"
              />
            </div>
            <div
              {...useScrollClipPath('right', 1, 0.3)}
              className={cx('img_box')}
            >
              <img
                className={cx('img')}
                src="/statics/images/aurora.jpg"
                alt="aurora"
              />
            </div>
            <div
              {...useScrollClipPath('right', 1, 0.4)}
              className={cx('img_box')}
            >
              <img
                className={cx('img')}
                src="/statics/images/purple_beach.jpg"
                alt="purple_beach"
              />
            </div>
          </div>
          <div className={cx('bottom_img_inner')}>
            <div
              {...useScrollClipPath('left', 1, 0.2)}
              className={cx('img_box')}
            >
              <img
                className={cx('img')}
                src="/statics/images/glacier.jpg"
                alt="glacier"
              />
            </div>
            <div
              {...useScrollClipPath('left', 1, 0.3)}
              className={cx('img_box')}
            >
              <img
                className={cx('img')}
                src="/statics/images/flower_garden.jpg"
                alt="flower_garden"
              />
            </div>
            <div
              {...useScrollClipPath('left', 1, 0.4)}
              className={cx('img_box')}
            >
              <img
                className={cx('img')}
                src="/statics/images/whale.jpg"
                alt="whale"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
