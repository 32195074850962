import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as api from 'apis';
import useAuth from 'hooks/useAuth';
import useSignup from 'hooks/useSignup';
import { regEmail } from 'constants/regExp';
import SolutionUserInfoWrapper from 'components/SolutionUserInfoWrapper';
import moment from 'moment';

export default function SolutionUserInfoContainer() {
  const { isLogIn, estimate } = useAuth();

  const navigate = useNavigate();
  const [isOpenPost, setIsOpenPost] = useState(false);

  const [isOtherError, setOtherError] = useState({
    business: false,
  });

  const { signup, handleChangeData, handleResetSignupData } = useSignup();

  const [fileUrls, setFileUrls] = useState({
    0: {},
  });

  const handleChange = useCallback(
    (e) => {
      if (
        e.target.name === 'businessNumber' ||
        e.target.name === 'businessMaxSeat' ||
        e.target.name === 'businessDailyVisitor'
      ) {
        if (isNaN(e.target.value)) return;
      }

      if (e.target.name === 'businessCategory') {
        handleChangeData({
          [e.target.name]: e.target.value,
          businessSubCategory: '',
        });
        return;
      }

      if (e.target.name === 'businessNumber') {
        handleChangeData({
          [e.target.name]: e.target.value,
          isBusinessVerified: false,
        });
        setOtherError((prev) => ({
          ...prev,
          business: false,
        }));
        return;
      }

      handleChangeData({ [e.target.name]: e.target.value });
    },
    [handleChangeData, setOtherError],
  );

  const handleChangeDate = useCallback(
    (e) => {
      const d = moment(new Date()).format('YYYY-MM-DD');
      if (e.target.name === 'businessOpenTime') {
        const openTime = moment(`${d} ${e.target.value}`);
        const closeTime = signup?.businessCloseTime
          ? moment(`${d} ${signup?.businessCloseTime}`)
          : null;
        const isSame = closeTime && openTime.isSame(closeTime);
        if (isSame) return alert('종료 운영시간과 다르게 설정해주세요 : )');

        handleChangeData({
          [e.target.name]: e.target.value,
        });
      }

      if (e.target.name === 'businessCloseTime') {
        const openTime = signup?.businessOpenTime
          ? moment(`${d} ${signup?.businessOpenTime}`)
          : null;
        const closeTime = moment(`${d} ${e.target.value}`);
        const isSame = openTime && closeTime.isSame(openTime);
        if (isSame) return alert('시작 운영시간과 다르게 설정해주세요 : )');

        handleChangeData({
          [e.target.name]: e.target.value,
        });
      }
    },
    [handleChangeData, signup?.businessOpenTime, signup?.businessCloseTime],
  );

  const handleOpenPost = useCallback(() => {
    setIsOpenPost(true);
  }, [setIsOpenPost]);

  const handleClosePost = useCallback(() => {
    setIsOpenPost(false);
  }, [setIsOpenPost]);

  const handleCheckBusinessNumber = useCallback(async () => {
    try {
      const res = await api.checkBusinessNumber(signup.businessNumber);
      if (res?.data?.isValid) {
        handleChangeData({ isBusinessVerified: true });
      } else {
        handleChangeData({ isBusinessVerified: false });
        setOtherError((prev) => ({
          ...prev,
          business: true,
        }));
      }
    } catch (err) {
      handleChangeData({ isBusinessVerified: false });
      setOtherError((prev) => ({
        ...prev,
        business: true,
      }));
      console.log(err);
    }
  }, [signup.businessNumber, handleChangeData]);

  const handleClickAddress = useCallback(
    (zonecode, address, sido) => {
      let location = sido;
      if (sido === '세종특별자치시') {
        location = '세종';
      } else if (sido === '제주특별자치도') {
        location = '제주';
      }

      handleChangeData({
        businessAddress: address,
        businessDetailAddress: '',
        location,
      });
    },
    [handleChangeData],
  );

  const handleWeeks = useCallback(
    (e) => {
      if (signup.dayoff.indexOf(e.target.value) > -1) {
        handleChangeData({
          dayoff: signup.dayoff.filter((el) => el !== e.target.value),
        });
      } else {
        handleChangeData({ dayoff: [...signup.dayoff, e.target.value] });
      }
    },
    [signup, handleChangeData],
  );

  const handleSubmit = useCallback(async () => {
    const {
      isBusinessVerified,
      businessCategory,
      businessSubCategory,
      businessNumber,
      businessName,
      businessAddress,
      businessDetailAddress,
      businessCustomerAge,
      businessOpenTime,
      businessCloseTime,
      businessBusyHourOpen,
      businessBusyHourClose,
      businessMaxSeat,
      businessDailyVisitor,
      location,
      dayoff,
      businessEmail,
      businessRegistrationImgUrl,
    } = signup;
    if (!businessCategory) {
      alert('사업자 업종을 선택해 주세요 : )');
      return;
    }

    if (!businessSubCategory) {
      alert('사업자 업종 중분류를 선택해 주세요 : )');
      return;
    }

    if (!businessNumber) {
      alert('사업자 번호를 입력해주세요 : )');
      return;
    }

    if (!isBusinessVerified) {
      alert('사업자 번호를 인증해주세요 : )');
      return;
    }

    if (!businessName) {
      alert('사업자 상호를 입력해주세요 : )');
      return;
    }

    if (!businessAddress || !location) {
      alert('사업장 주소를 입력해주세요 : )');
      return;
    }

    if (!businessDetailAddress) {
      alert('사업장 상세주소를 입력해주세요 : )');
      return;
    }

    if (!businessCustomerAge) {
      alert('고객 연령대를 선택해주세요 : )');
      return;
    }

    if (!businessOpenTime) {
      alert('매장 오픈시간을 선택해주세요 : )');
      return;
    }

    if (!businessCloseTime) {
      alert('매장 마감시간을 선택해주세요 : )');
      return;
    }

    if (!businessBusyHourOpen || !businessBusyHourClose) {
      alert('고객 최대 방문 시간대를 입력해주세요 : )');
      return;
    }

    if (!businessMaxSeat) {
      alert('고객 수용인원을 입력해주세요 : )');
      return;
    }

    if (!businessDailyVisitor) {
      alert('고객 평균 방문자를 입력해주세요 : )');
      return;
    }

    if (!regEmail.test(businessEmail)) {
      alert('이메일 형식에 맞게 입력해주세요 : )');
      return;
    }

    if (!fileUrls[0]?.url) {
      alert('사업자등록증을 업로드해주세요 : )');
      return;
    }

    try {
      await api.updateMyInfo({
        businessCategory,
        businessSubCategory,
        businessNumber,
        businessName,
        businessAddress,
        businessDetailAddress,
        businessCustomerAge,
        businessOpenTime,
        businessCloseTime,
        businessBusyHour: `${businessBusyHourOpen} ~ ${businessBusyHourClose}`,
        businessMaxSeat,
        businessDailyVisitor,
        location,
        dayoff,
        businessEmail,
        businessRegistrationImgUrl: fileUrls[0]?.url || '',
      });
      await api.updateEstimate(estimate.id, {
        status: 'CONFIRM',
      });
      navigate('/solution/publish-estimate/payment');
      handleResetSignupData();
    } catch (err) {
      console.log(err);
    }
  }, [signup, fileUrls]);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await api.getBusinessCategories();
        setCategories(res?.data?.value?.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <SolutionUserInfoWrapper
      signup={signup}
      isOpenPost={isOpenPost}
      handleOpenPost={handleOpenPost}
      handleClosePost={handleClosePost}
      handleClickAddress={handleClickAddress}
      handleCheckBusinessNumber={handleCheckBusinessNumber}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      isOtherError={isOtherError}
      handleWeeks={handleWeeks}
      fileUrls={fileUrls}
      handleFileUrls={setFileUrls}
      categories={categories}
      handleChangeDate={handleChangeDate}
    />
  );
}
