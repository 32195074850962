import React, {
    useEffect,
    useState,
    useCallback,
} from 'react';
import * as api from 'apis';
import MypagFaqWrapper from 'components/MypagFaqWrapper/MypagFaqWrapper';

export default function MypagFaqContainer() {
    const [data, setData] = useState({
        total: 0,
        currentCategory: '',
        currentPage: 1,
        lastPage: 1,
        perPage: 5,
        limit: 10,
        search: '',
        faqList: [],
        categoryList: [],
    });

    useEffect(() => {
        (async () => {
            try {
                const res = await api.getFaqs({
                    page: 0,
                    limit: 10,
                });

                const {
                    data,
                    total,
                    wholeCategories,
                } = res?.data;

                setData(prev => ({
                    ...prev,
                    total,
                    categoryList: wholeCategories,
                    faqList: data,
                    lastPage: Math.ceil(total / 10) || 1,
                }))
            } catch (err) {
                console.log(err);
            }
        })();
    }, [setData]);

    const handleSearch = useCallback(async (currentPage) => {
        try {
            const res = await api.getFaqs({
                page: currentPage - 1,
                limit: 10,
                search: data.search,
                category: data.currentCategory,
            });

            const {
                total,
                wholeCategories,
            } = res?.data;

            setData(prev => ({
                ...prev,
                total,
                currentPage,
                lastPage: Math.ceil(total / 10) || 1,
                categoryList: wholeCategories,
                faqList: res?.data?.data || [],
            }));
        } catch (err) {
            console.log(err);
        }
    }, [data, setData]);

    const handleKeyPress = (e) => {
        if (e.key !== 'Enter') return;
        handleSearch(1);
    }

    const handleChange = (e) => {
        setData(prev => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    }

    const handlePage = (currentPage) => {
        handleSearch(currentPage);
    }

    return (
        <MypagFaqWrapper
            faqList={data.faqList || []}
            categoryList={data.categoryList}
            currentCategory={data.currentCategory}
            search={data.search}
            currentPage={data.currentPage}
            lastPage={data.lastPage}
            perPage={data.perPage}
            onChange={handleChange}
            onSearch={handleSearch}
            onKeyPress={handleKeyPress}
            handlePage={handlePage}
        />
    )
}
