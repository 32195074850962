import React from 'react'
import classNames from 'classnames/bind';
import styles from './IntroduceMain.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function IntroduceMain() {

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <img {...useScrollClipPath('down', 1, 0)} className={cx('container_bg')} src="/statics/images/intro_visual.png" alt="intro_visual" />
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <div className={cx('content_box')}>
                            <div className={cx('content_sub_box')}>
                                <div {...useScrollFadeIn('up', 1, 0.2)} className={cx('sub_title_box')}>
                                    <p className={cx('sub_title')}>
                                        Company Introduce
                                    </p>
                                </div>
                                <div {...useScrollFadeIn('up', 1, 0.3)} className={cx('title_box')}>
                                    <h4 className={cx('title')}>
                                        클릭 한 번으로 바뀌는<br className={cx('mobile_br')} /> 미디어 인테리어
                                    </h4>
                                </div>
                                <div {...useScrollFadeIn('up', 1, 0.4)} className={cx('text_box')}>
                                    <p className={cx('text')}>
                                        빔몬스터는 지난 7년간 쌓은 최고의 기술력과<br className={cx('mobile_br')} /> 노하우를 통하여 <br className={cx('pc_br')} />
                                        미디어 아트로 일상적인 공간을<br className={cx('mobile_br')} /> 특별하게 만들어 왔습니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
