import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import MypageScheduleWaiting from 'components/views/MypageScheduleWaiting/MypageScheduleWaiting';

export default function MypageScheduleWaitingWrapper() {
    return (
        <>
            <Header />   
            <MypageScheduleWaiting />
            <Footer />
        </>
    )
}
