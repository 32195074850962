import React, { useRef, useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './SolutionUserInfo.module.scss';
import CancelSolutionButton from 'components/common/CancelSolutionButton';
import Popup from '../Popup/Popup';
import PrevPopup from '../PrevPopup';
import { NavLink, useNavigate } from 'react-router-dom';
import FileUpload from 'components/common/FileUpload';
import SearchAddress from 'components/common/SearchAddress';

const cx = classNames.bind(styles);

export default function SolutionUserInfo({
  signup,
  isOpenPost,
  handleOpenPost,
  handleClosePost,
  handleClickAddress,
  handleCheckBusinessNumber,
  handleSubmit,
  handleChange,
  isOtherError,
  handleWeeks,
  fileUrls,
  handleFileUrls,
  categories,
  handleChangeDate,
}) {
  const [buttonPopup, setButtonPopup] = useState({
    checklist: false,
    prevPopup: false,
  });

  // 날짜
  const weeks = [
    { id: 1, name: '월', value: 'MONDAY' },
    { id: 2, name: '화', value: 'TUESDAY' },
    { id: 3, name: '수', value: 'WEDNESDAY' },
    { id: 4, name: '목', value: 'THURSDAY' },
    { id: 5, name: '금', value: 'FRIDAY' },
    { id: 6, name: '토', value: 'SATURDAY' },
    { id: 7, name: '일', value: 'SUNDAY' },
  ];

  const onChangeImage = (file, index) => {
    handleFileUrls((prev) => ({
      ...prev,
      [index]: file,
    }));
  };

  const subCategoires = useMemo(() => {
    const res = categories?.filter(
      (el) => el?.category === signup?.businessCategory,
    );
    return res[0]?.subCategories || [];
  }, [signup?.businessCategory, categories]);

  return (
    <>
      <div className={cx('container')}>
        <div className={cx('sub_container')}>
          <div className={cx('header_container')}>
            <div className={cx('inner')}>
              <div className={cx('header_inner')}>
                <div className={cx('selected_process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('selected_process_title')}>상품선택</h4>
                  </div>
                </div>
                <div className={cx('selected_process_bar')}></div>
                <div className={cx('selected_process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('selected_process_title')}>견적요청</h4>
                  </div>
                </div>
                <div className={cx('selected_process_bar')}></div>
                <div className={cx('selected_process_box')}>
                  <div className={cx('selected_process_title_box')}>
                    <h4 className={cx('selected_process_title')}>견적검토</h4>
                  </div>
                </div>
                <div className={cx('selected_process_bar')}></div>
                <div className={cx('selected_process_box')}>
                  <div className={cx('selected_process_title_box')}>
                    <h4 className={cx('selected_process_title')}>설치확인</h4>
                  </div>
                </div>
                <div className={cx('selected_process_bar')}></div>
                <div className={cx('select_process_box')}>
                  <div className={cx('select_process_img_box')}>
                    <img
                      src="/statics/images/solution_select.png"
                      alt="solution_select"
                    />
                  </div>
                  <div className={cx('select_process_title_box')}>
                    <h4 className={cx('select_process_title')}>유저정보</h4>
                  </div>
                </div>
                <div className={cx('select_process_bar')}></div>
                <div className={cx('process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('process_title')}>결제방식</h4>
                  </div>
                </div>
                <div className={cx('process_bar')}></div>
                <div className={cx('process_status_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('process_title')}>계약현황 확인</h4>
                  </div>
                </div>
                <div className={cx('process_bar')}></div>
                <div className={cx('process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('process_title')}>계약서 작성</h4>
                  </div>
                </div>
                <div className={cx('process_bar')}></div>
                <div className={cx('process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('process_title')}>입금 확인</h4>
                  </div>
                </div>
                <div className={cx('process_bar')}></div>
                <div className={cx('process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('process_title')}>설치 스케줄</h4>
                  </div>
                </div>
              </div>
              <div className={cx('mobile_header_inner')}>
                <div className={cx('mobile_header_progressbar_box')}>
                  <div className={cx('mobile_header_progressbar')}></div>
                </div>
                <div className={cx('select_process_box')}>
                  <div className={cx('mobile_select_text_box')}>
                    <p className={cx('mobile_select_text')}>step.5</p>
                  </div>
                  <div className={cx('mobile_select_process_title_box')}>
                    <div className={cx('select_process_title_box')}>
                      <h4 className={cx('select_process_title')}>유저정보</h4>
                    </div>
                    <div className={cx('select_process_img_box')}>
                      <img
                        src="/statics/images/solution_select.png"
                        alt="solution_select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={cx('inner')}>
            <div className={cx('inner_box')}>
              <CancelSolutionButton />
              <div
                className={cx('checklist_button')}
                onClick={() =>
                  setButtonPopup((prev) => ({
                    ...prev,
                    checklist: !prev.checklist,
                  }))
                }
              >
                <div className={cx('checklist_icon_box')}>
                  <img
                    className={cx('checklist_icon')}
                    src="/statics/images/check_list.png"
                    alt="check_list"
                  />
                </div>
                <div className={cx('hide_text_box')}>
                  <p className={cx('hide_text')}>체크리스트 보기</p>
                </div>
              </div>
              <div className={cx('title_box')}>
                <h4 className={cx('title')}>유저 정보</h4>
              </div>
              <div className={cx('content_inner')}>
                {/* 유저 정보 추가 폼 */}
                <div className={cx('user_info_inner')}>
                  <div className={cx('user_info_input_inner')}>
                    <label className={cx('user_info_label')} htmlFor="">
                      사업자 업종
                    </label>
                    <select
                      className={cx('ueser_info_select')}
                      style={{
                        backgroundImage:
                          'url(/statics/images/ic_expand_more.png)',
                      }}
                      name="businessCategory"
                      value={signup.businessCategory}
                      onChange={handleChange}
                    >
                      <option value="">대분류</option>
                      {categories?.length > 0 &&
                        categories.map((el) => (
                          <option key={el?.category} value={el?.category}>
                            {el?.category}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className={cx('user_info_input_inner')}>
                    <select
                      className={cx('ueser_info_select')}
                      style={{
                        backgroundImage:
                          'url(/statics/images/ic_expand_more.png)',
                      }}
                      name="businessSubCategory"
                      value={signup.businessSubCategory}
                      onChange={handleChange}
                    >
                      <option value="">중분류</option>
                      {subCategoires?.length > 0 &&
                        subCategoires?.map((el) => (
                          <option key={el} value={el}>
                            {el}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className={cx('user_info_input_inner')}>
                    <label className={cx('user_info_label')} htmlFor="">
                      사업자 번호
                    </label>
                    <div className={cx('user_info_input_box')}>
                      <input
                        className={cx('user_info_input')}
                        type="text"
                        name="businessNumber"
                        placeholder="서비스 설치 사업자 번호 입력"
                        value={signup.businessNumber}
                        onChange={handleChange}
                      />
                      <button
                        disabled={!signup.businessNumber}
                        onClick={handleCheckBusinessNumber}
                        className={cx('user_info_button', {
                          active: signup.businessNumber,
                          success: signup.isBusinessVerified,
                        })}
                      >
                        {signup.isBusinessVerified ? '완료' : '인증'}
                      </button>
                      {isOtherError.business && (
                        <div className={cx('fail_box')}>
                          <img
                            className={cx('fail_img')}
                            src="/statics/images/ic_input_info_error.png"
                            alt="info_check"
                          />
                          <p className={cx('fail_text')}>
                            사업자번호가 유효하지않습니다.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={cx('user_info_input_inner')}>
                    <label className={cx('user_info_label')} htmlFor="">
                      사업장 상호 또는 브랜드명
                    </label>
                    <input
                      className={cx('user_info_all_input')}
                      type="text"
                      placeholder="직접 입력"
                      name="businessName"
                      value={signup.businessName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={cx('user_info_input_inner')}>
                    <label className={cx('user_info_label')} htmlFor="">
                      사업장 주소지
                    </label>
                    <div className={cx('user_info_input_box')}>
                      <input
                        className={cx('user_info_input')}
                        type="text"
                        placeholder="사업장 주소지 입력"
                        name="businessAddress"
                        value={signup.businessAddress}
                        readOnly
                      />
                      <button
                        className={cx('user_info_button', 'active')}
                        onClick={handleOpenPost}
                      >
                        {signup.businessAddress ? '수정' : '검색'}
                      </button>
                      {!signup.businessAddress && (
                        <button
                          className={cx('btn_search')}
                          onClick={handleOpenPost}
                        ></button>
                      )}
                    </div>
                    <input
                      className={cx(
                        'user_info_all_input',
                        'user_info_bottom_input',
                      )}
                      type="text"
                      placeholder="상세 주소 입력"
                      name="businessDetailAddress"
                      value={signup.businessDetailAddress}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={cx('user_info_hr')}></div>
                  <div className={cx('user_info_input_inner')}>
                    <label className={cx('user_info_label')} htmlFor="">
                      고객 연령대
                    </label>
                    <select
                      className={cx('ueser_info_select')}
                      style={{
                        backgroundImage:
                          'url(/statics/images/ic_expand_more.png)',
                      }}
                      name="businessCustomerAge"
                      value={signup.businessCustomerAge}
                      onChange={handleChange}
                    >
                      <option value="">선택하기</option>
                      <option value="10대">10대</option>
                      <option value="20대">20대</option>
                      <option value="30대">30대</option>
                      <option value="40대">40대</option>
                      <option value="50대">50대</option>
                      <option value="60대">60대</option>
                    </select>
                  </div>
                  <div className={cx('user_info_input_inner')}>
                    <label className={cx('user_info_label')} htmlFor="">
                      매장 운영시간
                    </label>
                    <div className={cx('ueser_info_half_select_box')}>
                      <select
                        className={cx('ueser_info_half_select')}
                        style={{
                          backgroundImage:
                            'url(/statics/images/ic_expand_more.png)',
                        }}
                        name="businessOpenTime"
                        value={signup.businessOpenTime}
                        onChange={handleChangeDate}
                      >
                        <option value="">선택하기</option>
                        <option value="00:00">00:00</option>
                        <option value="01:00">01:00</option>
                        <option value="02:00">02:00</option>
                        <option value="03:00">03:00</option>
                        <option value="04:00">04:00</option>
                        <option value="05:00">05:00</option>
                        <option value="06:00">06:00</option>
                        <option value="07:00">07:00</option>
                        <option value="08:00">08:00</option>
                        <option value="09:00">09:00</option>
                        <option value="10:00">10:00</option>
                        <option value="11:00">11:00</option>
                        <option value="12:00">12:00</option>
                        <option value="13:00">13:00</option>
                        <option value="14:00">14:00</option>
                        <option value="15:00">15:00</option>
                        <option value="16:00">16:00</option>
                        <option value="17:00">17:00</option>
                        <option value="18:00">18:00</option>
                        <option value="19:00">19:00</option>
                        <option value="20:00">20:00</option>
                        <option value="21:00">21:00</option>
                        <option value="22:00">22:00</option>
                        <option value="23:00">23:00</option>
                      </select>
                      <p className={cx('ueser_info_half_select_text')}>~</p>
                      <select
                        className={cx('ueser_info_half_select')}
                        style={{
                          backgroundImage:
                            'url(/statics/images/ic_expand_more.png)',
                        }}
                        name="businessCloseTime"
                        value={signup.businessCloseTime}
                        onChange={handleChangeDate}
                      >
                        <option value="">선택하기</option>
                        <option value="01:00">01:00</option>
                        <option value="02:00">02:00</option>
                        <option value="03:00">03:00</option>
                        <option value="04:00">04:00</option>
                        <option value="05:00">05:00</option>
                        <option value="06:00">06:00</option>
                        <option value="07:00">07:00</option>
                        <option value="08:00">08:00</option>
                        <option value="09:00">09:00</option>
                        <option value="10:00">10:00</option>
                        <option value="11:00">11:00</option>
                        <option value="12:00">12:00</option>
                        <option value="13:00">13:00</option>
                        <option value="14:00">14:00</option>
                        <option value="15:00">15:00</option>
                        <option value="16:00">16:00</option>
                        <option value="17:00">17:00</option>
                        <option value="18:00">18:00</option>
                        <option value="19:00">19:00</option>
                        <option value="20:00">20:00</option>
                        <option value="21:00">21:00</option>
                        <option value="22:00">22:00</option>
                        <option value="23:00">23:00</option>
                        <option value="24:00">24:00</option>
                      </select>
                    </div>
                  </div>
                  <div className={cx('user_info_input_inner')}>
                    <label className={cx('user_info_label')} htmlFor="">
                      고객 최대 방문 시간대
                    </label>
                    <div className={cx('ueser_info_half_select_box')}>
                      <select
                        className={cx('ueser_info_half_select')}
                        style={{
                          backgroundImage:
                            'url(/statics/images/ic_expand_more.png)',
                        }}
                        name="businessBusyHourOpen"
                        value={signup.businessBusyHourOpen}
                        onChange={handleChange}
                      >
                        <option value="">선택하기</option>
                        <option value="00:00">00:00</option>
                        <option value="01:00">01:00</option>
                        <option value="02:00">02:00</option>
                        <option value="03:00">03:00</option>
                        <option value="04:00">04:00</option>
                        <option value="05:00">05:00</option>
                        <option value="06:00">06:00</option>
                        <option value="07:00">07:00</option>
                        <option value="08:00">08:00</option>
                        <option value="09:00">09:00</option>
                        <option value="10:00">10:00</option>
                        <option value="11:00">11:00</option>
                        <option value="12:00">12:00</option>
                        <option value="13:00">13:00</option>
                        <option value="14:00">14:00</option>
                        <option value="15:00">15:00</option>
                        <option value="16:00">16:00</option>
                        <option value="17:00">17:00</option>
                        <option value="18:00">18:00</option>
                        <option value="19:00">19:00</option>
                        <option value="20:00">20:00</option>
                        <option value="21:00">21:00</option>
                        <option value="22:00">22:00</option>
                        <option value="23:00">23:00</option>
                      </select>
                      <p className={cx('ueser_info_half_select_text')}>~</p>
                      <select
                        className={cx('ueser_info_half_select')}
                        style={{
                          backgroundImage:
                            'url(/statics/images/ic_expand_more.png)',
                        }}
                        name="businessBusyHourClose"
                        value={signup.businessBusyHourClose}
                        onChange={handleChange}
                      >
                        <option value="">선택하기</option>
                        <option value="01:00">01:00</option>
                        <option value="02:00">02:00</option>
                        <option value="03:00">03:00</option>
                        <option value="04:00">04:00</option>
                        <option value="05:00">05:00</option>
                        <option value="06:00">06:00</option>
                        <option value="07:00">07:00</option>
                        <option value="08:00">08:00</option>
                        <option value="09:00">09:00</option>
                        <option value="10:00">10:00</option>
                        <option value="11:00">11:00</option>
                        <option value="12:00">12:00</option>
                        <option value="13:00">13:00</option>
                        <option value="14:00">14:00</option>
                        <option value="15:00">15:00</option>
                        <option value="16:00">16:00</option>
                        <option value="17:00">17:00</option>
                        <option value="18:00">18:00</option>
                        <option value="19:00">19:00</option>
                        <option value="20:00">20:00</option>
                        <option value="21:00">21:00</option>
                        <option value="22:00">22:00</option>
                        <option value="23:00">23:00</option>
                        <option value="24:00">24:00</option>
                      </select>
                    </div>
                  </div>
                  <div className={cx('user_info_input_inner')}>
                    <label className={cx('user_info_label')} htmlFor="">
                      고객 최대 수용인원
                    </label>
                    <input
                      className={cx('user_info_all_input')}
                      type="number"
                      placeholder="50"
                      name="businessMaxSeat"
                      value={signup.businessMaxSeat}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={cx('user_info_input_inner')}>
                    <label className={cx('user_info_label')} htmlFor="">
                      고객 평균 방문자
                    </label>
                    <input
                      className={cx('user_info_all_input')}
                      type="number"
                      placeholder="150"
                      name="businessDailyVisitor"
                      value={signup.businessDailyVisitor}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={cx('user_info_input_inner')}>
                    <label className={cx('user_info_label')} htmlFor="">
                      휴무일 설정
                    </label>
                    <div className={cx('user_info_input_box')}>
                      {weeks.map((el) => {
                        return (
                          <button
                            onClick={handleWeeks}
                            value={el.value}
                            key={el.id}
                            name="dayoff"
                            className={cx(
                              signup.dayoff.find((day) => day === el.value)
                                ? 'active_user_info_week_button'
                                : 'user_info_week_button',
                            )}
                          >
                            {el.name}
                          </button>
                        );
                      })}
                      {/* <button className={cx('user_info_week_button')}>화</button>
                    <button className={cx('user_info_week_button', 'active')}>
                      수
                    </button>
                    <button className={cx('user_info_week_button')}>목</button>
                    <button className={cx('user_info_week_button')}>금</button>
                    <button className={cx('user_info_week_button')}>토</button>
                    <button className={cx('user_info_week_button')}>일</button> */}
                    </div>
                  </div>
                  <div className={cx('user_info_input_inner')}>
                    <label className={cx('user_info_label')} htmlFor="">
                      세금계산서 발행을 위한 이메일
                    </label>
                    <input
                      className={cx('user_info_all_input')}
                      type="email"
                      placeholder="이메일 입력"
                      name="businessEmail"
                      value={signup.businessEmail}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={cx('user_info_input_inner')}>
                    <label className={cx('user_info_label')} htmlFor="">
                      사업자 등록증을 업로드 해주세요
                    </label>
                    <div className={cx('user_info_input_box')}>
                      <div className={cx('user_file_upload_box')}>
                        {Object.keys(fileUrls).map((key, i) => (
                          <FileUpload
                            key={`${fileUrls[key]?.url}-${i}`}
                            index={i}
                            file={fileUrls[key]}
                            onChangeImage={onChangeImage}
                            style={{
                              width: '60px',
                              height: '60px',
                              marginLeft: '0',
                            }}
                          />
                        ))}
                      </div>
                      <div className={cx('user_file_upload_text_box')}>
                        {fileUrls[0]?.filename ? (
                          <p
                            className={cx('user_file_upload_text')}
                            style={{ color: '#333' }}
                          >
                            {fileUrls[0]?.filename}
                          </p>
                        ) : (
                          <p className={cx('user_file_upload_text')}>
                            첨부 파일명
                          </p>
                        )}
                        {/* <b className={cx('user_file_upload_text_b')}>000,000mb</b> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cx('button_box')}>
                  {/* <div className={cx('prev_button_box')}>
                                <button onClick={() => setButtonPopup(prev => ({ ...prev, prevPopup: !prev.prevPopup }))} className={cx('prev_button')}>이전 단계 확인</button>
                            </div> */}
                  <div className={cx('next_button_box')}>
                    <button
                      onClick={handleSubmit}
                      className={cx('next_button')}
                    >
                      계약 하기
                    </button>
                  </div>
                </div>
              </div>
              <Popup
                trigger={buttonPopup.checklist}
                setTrigger={setButtonPopup}
              />
              <PrevPopup
                trigger={buttonPopup.prevPopup}
                setTrigger={setButtonPopup}
              />
            </div>
          </div>
        </div>
      </div>
      <SearchAddress
        isOpenPost={isOpenPost}
        handleOpenPost={handleOpenPost}
        handleClosePost={handleClosePost}
        handleClickAddress={handleClickAddress}
      />
    </>
  );
}
