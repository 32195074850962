import React from 'react';
import useAuth from 'hooks/useAuth';
import * as api from 'apis';
import MypageWrapper from 'components/MypageWrapper/MypageWrapper';
import useArtist from 'hooks/useArtist';

export default function MypageContainer() {
  const {
    email,
    name,
    profileImageUrl,
    estimate,
    contract,
    handleChangeAuthData,
    isUnlimitedPlaylist,
  } = useAuth();

  const { wishArtistPlayList } = useArtist();

  const getGrade = () => {
    if (estimate && estimate.status === 'CONFIRM') {
      if (
        contract &&
        (contract.status === 'FINISH' || contract.status === 'SCHEDULED')
      ) {
        if (estimate.solutionType === 'PREMIUM') {
          return '프리미엄';
        } else if (estimate.solutionType === 'STANDARD') {
          return '스탠다드';
        } else if (estimate.solutionType === 'BASIC') {
          return '베이직';
        } else {
          return '';
        }
      }
    }

    return '';
  };

  const grade = getGrade();

  const handleChangeProfileImage = async (e) => {
    if (window.confirm('프로필을 변경하시겠습니까?')) {
      let formData = new FormData();
      formData.append(e.target.name, e.target.files[0]);
      try {
        const file = await api.uploadFiles(formData);
        await api.updateMyInfo({ profileImageUrl: file.data[0].url });
        handleChangeAuthData({ profileImageUrl: file.data[0].url });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <MypageWrapper
      email={email}
      name={name}
      profileImageUrl={profileImageUrl}
      grade={grade}
      handleChangeProfileImage={handleChangeProfileImage}
      wishArtistPlayList={wishArtistPlayList}
      isUnlimitedPlaylist={isUnlimitedPlaylist}
    />
  );
}
