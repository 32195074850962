import React from 'react';
import Header from 'components/views/Header/Header';
import MypagFaq from 'components/views/MypagFaq/MypagFaq';
import Footer from 'components/views/Footer/Footer';

export default function MypagFaqWrapper({
    faqList,
    categoryList,
    currentCategory,
    search,
    currentPage,
    lastPage,
    perPage,
    onChange,
    onSearch,
    onKeyPress,
    handlePage,
}) {
    return (
        <>
            <Header />
            <MypagFaq
                faqList={faqList}
                categoryList={categoryList}
                currentCategory={currentCategory}
                search={search}
                currentPage={currentPage}
                lastPage={lastPage}
                perPage={perPage}
                onChange={onChange}
                onSearch={onSearch}
                onKeyPress={onKeyPress}
                handlePage={handlePage}
            />
            <Footer />
        </>
    )
}
