import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionPublishEstimatePayment from 'components/views/SolutionPublishEstimatePayment/SolutionPublishEstimatePayment';

export default function SolutionPublishEstimatePaymentWrapper({
    paymentType,
    onSubmit,
}) {
    return (
        <>
            <Header />
            <SolutionPublishEstimatePayment
                paymentType={paymentType}
                onSubmit={onSubmit}
            />
        </>
    )
}
