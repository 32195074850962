import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import * as api from 'apis';
import MypagAsDetailWrapper from 'components/MypagAsDetailWrapper/MypagAsDetailWrapper';

export default function MypagAsDetailContainer() {
  const [data, setData] = useState({
    category: '',
    content: '',
    createdAt: null,
    managerName: '',
    managerPhone: '',
    managerProfileImageUrl: '',
    response: '',
    status: '',
    visitDate: null,
    files: {}
  });
  const location = useLocation();
  const navigator = useNavigate();
  const { id } = qs.parse(location.search);

  useEffect(() => {
    if (!id) {
      alert('잘못된 접근입니다!');
      navigator('/');
      return;
    }
    (async () => {
      try {
        const res = await api.getAfterService(id);
        const {
          category,
          content,
          createdAt,
          managerName,
          managerPhone,
          managerProfileImageUrl,
          response,
          status,
          visitDate,
          files,
        } = res?.data;
        setData((prev) => ({
          ...prev,
          category,
          content,
          createdAt,
          managerName,
          managerPhone,
          managerProfileImageUrl,
          response,
          status,
          visitDate,
          files,
        }));
      } catch (err) {
        console.log(err);
      }
    })();
  }, [id, navigator, setData]);

  return (
    <MypagAsDetailWrapper
      data={data}
    />
  );
}
