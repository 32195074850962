import React from 'react';
import { Header } from 'components/views';
import SolutionUserInfo from 'components/views/SolutionUserInfo';

export default function SolutionUserInfoWrapper({
  signup,
  isOpenPost,
  handleOpenPost,
  handleClosePost,
  handleClickAddress,
  handleCheckBusinessNumber,
  handleSubmit,
  handleChange,
  isOtherError,
  handleWeeks,
  fileUrls,
  handleFileUrls,
  categories,
  handleChangeDate,
}) {
  return (
    <>
      <Header />
      <SolutionUserInfo
        signup={signup}
        isOpenPost={isOpenPost}
        handleOpenPost={handleOpenPost}
        handleClosePost={handleClosePost}
        handleClickAddress={handleClickAddress}
        handleCheckBusinessNumber={handleCheckBusinessNumber}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        isOtherError={isOtherError}
        handleWeeks={handleWeeks}
        fileUrls={fileUrls}
        handleFileUrls={handleFileUrls}
        categories={categories}
        handleChangeDate={handleChangeDate}
      />
    </>
  );
}
