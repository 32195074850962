import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionInstallmentExaminationInfo from 'components/views/SolutionInstallmentExaminationInfo/SolutionInstallmentExaminationInfo';

export default function SolutionInstallmentExaminationInfoWrapper() {
    return (
        <>
            <Header /> 
            <SolutionInstallmentExaminationInfo />
        </>
    )
}
