import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import MypagePayment from 'components/views/MypagePayment/MypagePayment';

export default function MypagePaymentWrapper() {
    return (
        <>
            <Header />
            <MypagePayment />
            <Footer />
        </>
    )
}
