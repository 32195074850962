import React, { useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './MypageScheduleEdit.module.scss';
import { Link } from 'react-router-dom';
import Calendar from './Calendar';

const cx = classNames.bind(styles);

export default function MypageScheduleEdit() {
    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <Link to='/mypage' className={cx('back_box')}>
                        <img className={cx('back_img')} src="/statics/images/ic_profile_back.png" alt="ic_profile_back" />
                        <div className={cx('back_text_box')}>
                            <p className={cx('back_text')}>마이페이지</p>
                        </div>
                    </Link>
                    <div className={cx('inner_box')}>
                        <div className={cx('header_inner')}>
                            <div className={cx('title_box')}>
                                <h4 className={cx('title')}>
                                    새로운 설치 일정을 선택해주세요.
                                </h4>
                            </div>
                            <div className={cx('change_box')}>
                                <p className={cx('change_text')}>
                                    스케줄 변경 신청하기
                                </p>
                            </div>
                        </div>
                        <div className={cx('text_box')}>
                            <p className={cx('text')}>
                                설치 스케줄은 당일 변경이 불가합니다. 자세한 문의는 하단의 고객센터 전화번호 또는 이메일로 문의해주세요.
                            </p>
                        </div>
                        <div className={cx('calendar_inner')}>
                            <Calendar />
                        </div>
                        <div className={cx('button_box')}>
                            <button className={cx('button')}>
                                스케줄 변경 신청하기
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
