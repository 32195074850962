import React, { Fragment } from 'react';
import classNames from 'classnames/bind';
import styles from './AdvertisementStore.module.scss';

const cx = classNames.bind(styles);

export default function AdvertisementStore({
  selectedCategory,
  businiessCategories,
  selectedBusinessCategories,
  handleSelectBusinessCategory,
}) {
  return (
    <div className={cx('category_inner')}>
      <div className={cx('category_title_box')}>
        <p className={cx('category_title')}>
          희망 스토어 업종
          <b className={cx('category_text_b')}>
            이 상품은 스토어 업종 변경이 불가능합니다.
          </b>
        </p>
      </div>
      <div className={cx('store_category')}>
        {businiessCategories?.length > 0 && businiessCategories.map((el, i) => {
          const isDisplay = !(el?.subCategories?.some((c) => selectedCategory?.excludeBusinessSubCategories?.some(excludeCategory => excludeCategory === c)));
          if (!isDisplay) return <Fragment key={`business-${i}`}></Fragment>;
          return (
            <button
              onClick={() => handleSelectBusinessCategory(el.category)}
              value={el.category}
              key={i}
              className={cx(
                selectedBusinessCategories.find((item) => item.category === el.category)
                  ? 'active_user_info_week_button'
                  : 'user_info_week_button',
              )}
            >
              {el.category}
            </button>
          );
        })}
      </div>
    </div>
  );
}
