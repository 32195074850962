import React from 'react'
import classNames from 'classnames/bind';
import styles from './IntroduceContent.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function IntroduceContent() {

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <img {...useScrollClipPath('down', 1, 0)} className={cx('container_bg')} src="/statics/images/intro_place.png" alt="intro_place" />
                <div className={cx('inner')}>
                    <div {...useScrollClipPath('up', 1, 0.2)} className={cx('content_box')}>
                        <div {...useScrollFadeIn('up', 1, 0.2)} className={cx('title_box')}>
                            <h4 className={cx('title')}>
                                누구나, 언제나,<br className={cx('mobile_br')} /> 어디서나&nbsp;<br className={cx('pc_br')} />
                                공간을<br className={cx('mobile_br')} /> 예술로 채우는<br />
                                세상을 만듭니다.
                            </h4>
                        </div>
                        <div {...useScrollFadeIn('up', 1, 0.3)} className={cx('text_box')}>
                            <p className={cx('text')}>
                                빔몬스터는 미디어 인테리어로 모두가 <br />
                                손쉽게 예술을 향유할 수 있게 돕습니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
