import React, { useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './MypagePaymentHistory.module.scss';
import AdvertisementPaymentHistory from './AdvertisementPaymentHistory';
import ArtistPaymentHistory from './ArtistPaymentHistory';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function MypagePaymentHistory({
  handlePage,
  pageData,
  data,
  handleArtistPage,
  artistData,
  artistPageData,
  tab,
  handleTab,
}) {
  const tabButtons = [
    { id: 'Artist', tab: '작가 콘텐츠' },
    { id: 'Advertisement', tab: '광고' },
  ];

  const navigate = useNavigate();

  const backMove = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <div className={cx('conatiner')}>
      <div className={cx('inner')}>
        <div onClick={backMove} className={cx('back_box')}>
          <img
            className={cx('back_img')}
            src="/statics/images/ic_profile_back.png"
            alt="ic_profile_back"
          />
          <div className={cx('back_text_box')}>
            <p className={cx('back_text')}>뒤로가기</p>
          </div>
        </div>
        <div className={cx('tab_inner')}>
          <div className={cx('tab_button_box')}>
            <span className={cx('tab_vertical')}></span>
            {tabButtons.map((el, i) => (
              <button
                onClick={() => handleTab(el.id)}
                className={cx(
                  el.id === tab ? 'active_tab_button' : 'tab_button',
                )}
                key={el.id}
              >
                {el.tab}
              </button>
            ))}
          </div>
          <div className={cx('tab_content_box')}>
            {tab === 'Artist' && (
              <ArtistPaymentHistory
                handleArtistPage={handleArtistPage}
                artistData={artistData}
                artistPageData={artistPageData}
              />
            )}
            {tab === 'Advertisement' && (
              <AdvertisementPaymentHistory
                handlePage={handlePage}
                pageData={pageData}
                data={data}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
