import React from 'react';
import { Header } from 'components/views';
import SolutionInstallFail from 'components/views/SolutionInstallFail';

export default function SolutionInstallFailWrapper({
  handleCancel,
}) {
  return (
    <>
      <Header />
      <SolutionInstallFail handleCancel={handleCancel} />
    </>
  );
}
