import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Main.module.scss';
import Fade from 'react-reveal/Fade';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function Main({
  themes,
  selectedMainContent,
  onClick,
  selectedIndex,
  handleSolution,
  clickedIndex,
}) {
  const navigator = useNavigate();

  // const serviceNavigator = () => {
  //     navigator('/signup');
  //     window.scrollTo(0, 0)
  // }

  const [scrollWitdh, setScrollWitdh] = useState(0);

  /* 미디어 화살표 눌렀을 때, 움직이는 code */
  const scollButton = useRef(null);

  const slide = (shift) => {
    scollButton.current.scrollLeft += shift;
  };

  return (
    <div className={cx('container')}>
      <div className={cx('sub_conatiner')}>
        {themes?.map((el, i) => {
          // (selectedIndex === i && !isAnimating)
          const style =
            selectedIndex !== i
              ? {
                opacity: 0,
                display: 'none',
              }
              : {};
          return (
            <video
              id={`video-${i}`}
              style={style}
              className={cx('video_bg')}
              key={el?.name}
              loop
              autoPlay
              muted
              playsInline
              preload="true"
              controls={false}
            >
              <source src={el?.url} type="video/mp4" />
            </video>
          );
        })}
        {/* {
                    selectedMainContent?.url && (
                        <video className={cx('video_bg')} key={selectedIndex} loop autoPlay muted>
                            <source src={selectedMainContent?.url} type="video/mp4" />
                        </video>
                    )
                } */}
        {/* 기본 Video URL "/statics/videos/index_main_background.mp4" */}
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <div className={cx('content_text_box')}>
              <Fade bottom delay={1000}>
                <div className={cx('sub_text_box')}>
                  <p className={cx('sub_text')}>
                    The media interior changed with a single click
                  </p>
                </div>
              </Fade>
              <Fade bottom delay={1400}>
                <div className={cx('title_text_box')}>
                  <h3 className={cx('title_text')}>
                    클릭 한 번으로 바뀌는
                    <br className={cx('mobile_br')} /> 미디어 인테리어
                  </h3>
                </div>
              </Fade>
              <Fade bottom delay={1800}>
                <div className={cx('text_box')}>
                  <p className={cx('text')}>
                    클릭 한 번으로 바뀌는 미디어 인테리어 차원이 다른 편리함을
                    느껴보세요.
                    <br className={cx('pc_br')} /> 많은 비용과 시간은 이제 그만!
                    단 1분이면 인테리어를 바꿀 수 있습니다.
                  </p>
                </div>
              </Fade>
            </div>
            <Fade left delay={2200}>
              <div className={cx('button_box')}>
                <button className={cx('button')} onClick={handleSolution}>
                  간편 상담 신청
                  <div className={cx('button_arrow')}>
                    <div className={cx('button_arrow_hr')}></div>
                  </div>
                </button>
              </div>
            </Fade>
            <Fade bottom delay={2600}>
              <div className={cx('media_theme')}>
                <div className={cx('media_theme_box')}>
                  <p className={cx('media_theme_text')}>미디어 테마</p>
                  <div className={cx('media_theme_button_box')}>
                    <div className={cx('media_theme_left_button_box')}>
                      <img
                        className={cx('media_theme_left_button')}
                        src="/statics/images/main_media_left_arrow.png"
                        alt="media_arrow"
                      />
                      <img
                        onClick={() => slide(-220)}
                        className={cx('hover_media_left_button')}
                        src="/statics/images/main_hover_media_left_arrow.png"
                        alt="media_arrow"
                      />
                    </div>
                    <div className={cx('media_theme_right_button_box')}>
                      <img
                        className={cx('media_theme_right_button')}
                        src="/statics/images/main_media_right_arrow.png"
                        alt="media_arrow"
                      />
                      <img
                        onClick={() => slide(+220)}
                        className={cx('hover_media_right_button')}
                        src="/statics/images/main_hover_media_right_arrow.png"
                        alt="media_arrow"
                      />
                    </div>
                  </div>
                </div>
                <div className={cx('media_progress_box')}>
                  <div
                    style={{ width: `${scrollWitdh}%` }}
                    className={cx('media_progress_bar')}
                  ></div>
                </div>
              </div>
            </Fade>
            <div className={cx('media_box')}>
              <div className={cx('media_theme_list_box')} ref={scollButton}>
                {themes?.map((el, i) => {
                  return (
                    <Fade key={i} bottom delay={3000 + i * 400}>
                      <button
                        className={cx('media_theme_img_box', {
                          active: i === clickedIndex,
                        })}
                        onClick={() => onClick(i)}
                      >
                        <img
                          className={cx('media_play_button')}
                          src="/statics/images/media_play_button.png"
                          alt="media_play_button"
                        />
                        <img
                          className={cx('media_theme_hover_bg')}
                          src="/statics/images/media_hover_img.png"
                          alt="media_hover_bg"
                        />
                        <img
                          className={cx('media_theme_img')}
                          src={el.thumbnailImageUrl}
                          alt="beach"
                        />
                      </button>
                    </Fade>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
