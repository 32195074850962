import React, { useState } from 'react';
import classNames from 'classnames/bind';
import CancelSolutionButton from 'components/common/CancelSolutionButton';
import styles from './SolutionRentalInfo.module.scss';
import { NavLink } from 'react-router-dom';
import Popup from '../Popup/Popup';

const cx = classNames.bind(styles);

export default function SolutionRentalInfo({ onClick }) {
  const [buttonPopup, setButtonPopup] = useState(false);

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('header_container')}>
          <div className={cx('inner')}>
            <div className={cx('header_inner')}>
              <div className={cx('selected_process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('selected_process_title')}>상품선택</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('selected_process_title')}>견적요청</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('selected_process_title_box')}>
                  <h4 className={cx('selected_process_title')}>견적검토</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('selected_process_title_box')}>
                  <h4 className={cx('selected_process_title')}>설치확인</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('selected_process_title_box')}>
                  <h4 className={cx('selected_process_title')}>유저정보</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('selected_process_title_box')}>
                  <h4 className={cx('selected_process_title')}>결제방식</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('select_status_process_box')}>
                <div className={cx('select_process_img_box')}>
                  <img
                    src="/statics/images/solution_select.png"
                    alt="solution_select"
                  />
                </div>
                <div className={cx('select_process_title_box')}>
                  <h4 className={cx('select_process_title')}>계약현황 확인</h4>
                </div>
              </div>
              <div className={cx('select_process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>계약서 작성</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>입금 확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>설치 스케줄</h4>
                </div>
              </div>
            </div>
            <div className={cx('mobile_header_inner')}>
              <div className={cx('mobile_header_progressbar_box')}>
                <div className={cx('mobile_header_progressbar')}></div>
              </div>
              <div className={cx('select_process_box')}>
                <div className={cx('mobile_select_text_box')}>
                  <p className={cx('mobile_select_text')}>step.7</p>
                </div>
                <div className={cx('mobile_select_process_title_box')}>
                  <div className={cx('select_process_title_box')}>
                    <h4 className={cx('select_process_title')}>
                      계약 현황 확인
                    </h4>
                  </div>
                  <div className={cx('select_process_img_box')}>
                    <img
                      src="/statics/images/solution_select.png"
                      alt="solution_select"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <CancelSolutionButton />
            <div
              className={cx('checklist_button')}
              onClick={() => setButtonPopup(true)}
            >
              <div className={cx('checklist_icon_box')}>
                <img
                  className={cx('checklist_icon')}
                  src="/statics/images/check_list.png"
                  alt="check_list"
                />
              </div>
              <div className={cx('hide_text_box')}>
                <p className={cx('hide_text')}>계약 정보 확인</p>
              </div>
            </div>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>계약 정보 확인</h4>
            </div>
            <div className={cx('content_inner')}>
              <div className={cx('content_box')}>
                <div className={cx('content_title_box')}>
                  <h4 className={cx('content_title')}>렌탈 계약 안내</h4>
                </div>
                <div className={cx('content_sub_title_box')}>
                  <h4 className={cx('content_sub_title')}>신청 한도 금액</h4>
                </div>
                <div className={cx('content_price_box')}>
                  <h4 className={cx('content_price')}>￦ 30,000,000</h4>
                </div>
                <div className={cx('content_hr')}></div>
                <div className={cx('content_text_inner')}>
                  <div className={cx('content_text_box')}>
                    <div className={cx('content_text_img_box')}>
                      <img
                        className={cx('content_text_img')}
                        src="/statics/images/ic_task_alt.png"
                        alt="ic_task_alt"
                      />
                    </div>
                    <div className={cx('content_sub_text_box')}>
                      <p className={cx('content_text')}>
                        렌탈 기간 : 24개월 할부
                      </p>
                    </div>
                  </div>
                  <div className={cx('content_text_box')}>
                    <div className={cx('content_text_img_box')}>
                      <img
                        className={cx('content_text_img')}
                        src="/statics/images/ic_task_alt.png"
                        alt="ic_task_alt"
                      />
                    </div>
                    <div className={cx('content_sub_text_box')}>
                      <p className={cx('content_text')}>
                        신청 시, 렌탈 상담 및 심사 진행.
                      </p>
                    </div>
                  </div>
                  {/* <div className={cx('content_text_box')}>
                                        <div className={cx('content_text_img_box')}>
                                            <img className={cx('content_text_img')} src="/statics/images/ic_task_alt_off.png" alt="ic_task_alt_off" />
                                        </div>
                                        <div className={cx('content_sub_text_box')}>
                                            <p className={cx('content_text')}>
                                                승인 시, 최저 금리 및 상세 내용 안내 예정.
                                        </p>
                                        </div>
                                    </div> */}
                </div>
                <div className={cx('content_button_box')}>
                  <button className={cx('content_button')} onClick={onClick}>
                    신청하기
                  </button>
                </div>
              </div>
            </div>
            <div className={cx('button_box')}>
              <div className={cx('prev_button_box')}>
                <NavLink
                  to="/solution/publish-estimate/payment"
                  className={cx('prev_button')}
                >
                  이전 단계 확인
                </NavLink>
              </div>
              <div className={cx('next_button_box')}>
                <button
                  className={cx('next_button', 'active')}
                  onClick={onClick}
                >
                  다음 단계로 이동
                </button>
              </div>
            </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} />
          </div>
        </div>
      </div>
    </div>
  );
}
