import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './MypageBasketArtist.module.scss';
import { numberWithCommas } from 'libs/numbers';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function MypageBasketArtist({
  wishPlayList,
  handleWishList,
  checkPlayList,
  handleCheckList,
  handleCheckAll,
  handleRemove,
  isUnlimitedPlaylist,
  handleUpdatePlayList,
}) {
  // 결제하기 페이지 이동

  const navigator = useNavigate();

  const onClickPaymentMove = () => {
    navigator('/mypage/payment/artist');
  };

  return (
    <div className={cx('sub_container')}>
      <div className={cx('container')}>
        <div className={cx('inner')}>
          <div className={cx('inner_header')}>
            <div className={cx('title_box')}>
              <p className={cx('title')}>장바구니</p>
              <p className={cx('basket_number')}>{wishPlayList?.length}개</p>
            </div>
          </div>
          <div className={cx('sub_header_box')}>
            <div className={cx('all_input_box')}>
              <input
                className={cx('hide_check_box', {
                  checked:
                    !!wishPlayList.length &&
                    wishPlayList.length === checkPlayList.length,
                })}
                type="checkbox"
                id="all_checked"
                onChange={handleCheckAll}
              />
              <label className={cx('checkbox_label')} htmlFor="all_checked">
                <span className={cx('check_box')}>
                  <span className={cx('check_box_checked')}></span>
                </span>
                전체 선택
              </label>
            </div>
            <div className={cx('select_delete_button_box')}>
              <button
                className={cx('select_delete_button')}
                onClick={handleRemove}
              >
                <img
                  className={cx('select_delete_img')}
                  src="/statics/images/ic-select-del.png"
                  alt="삭제 이미지"
                />
                선택 삭제
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('list_container')}>
        <div className={cx('list_inner', { no_data: !wishPlayList?.length })}>
          {!wishPlayList?.length && (
            <p className={cx('error_text')}>
              장바구니에 담은 아티스트 <br className={cx('mobile_br')} />
              콘텐츠가 존재하지 않습니다.
            </p>
          )}
          {!!wishPlayList?.length &&
            wishPlayList?.map((el, index) => (
              <div className={cx('list_item_inner')} key={el?.id}>
                <div className={cx('list_item_box')}>
                  <div className={cx('artist_info_box')}>
                    <div className={cx('all_input_box', 'mobile_checkbox')}>
                      <input
                        className={cx('hide_check_box', {
                          checked: checkPlayList.indexOf(el.id) > -1,
                        })}
                        type="checkbox"
                        id={`check_list-${index}`}
                        onChange={(e) => handleCheckList(e, el)}
                      />
                      <label
                        className={cx('checkbox_label')}
                        htmlFor={`check_list-${index}`}
                      >
                        <span className={cx('check_box')}>
                          <span className={cx('check_box_checked')}></span>
                        </span>
                      </label>
                      {/* 모바일 삭제 버튼 */}
                      <button
                        className={cx('mobile_delete_button')}
                        onClick={(e) => handleWishList(e, el)}
                      >
                        <img
                          className={cx('mobile_delete_img')}
                          src="/statics/images/ic-select-del.png"
                          alt="삭제 이미지"
                        />
                      </button>
                    </div>
                    <div className={cx('list_sub_box')}>
                      <div className={cx('list_img_box')}>
                        {el?.thumbnailUrl ? (
                          <img
                            className={cx('list_img')}
                            src={el?.thumbnailUrl}
                            alt="장바구니 이미지"
                          />
                        ) : (
                            <img
                              className={cx('list_img')}
                              src="/statics/images/window.jpg"
                              alt="장바구니 이미지"
                            />
                          )}

                        {/* {like ? (
                        <img
                        className={cx('like_img')}
                        src="/statics/images/ic_playlist_like_on.png"
                        alt="좋아요 이미지"
                        />
                        ) : (
                          <img
                          className={cx('like_img')}
                          src="/statics/images/ic_playlist_like_off.png"
                          alt="좋아요 이미지"
                          />
                        )} */}
                      </div>
                      <div className={cx('artist_info')}>
                        <p className={cx('artist_title')}>
                          작품명 - {el?.title}
                        </p>
                        <p className={cx('artist_name')}>
                          작가명 - {el?.creator?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={cx('payment_box')}>
                    <p className={cx('payment')}>{`${
                      el?.price ? numberWithCommas(el?.price) : ' - '
                      }원`}</p>
                  </div>
                </div>
                <div className={cx('delete_button_box')}>
                  <button
                    className={cx('delete_button')}
                    onClick={(e) => handleWishList(e, el)}
                  >
                    <img
                      className={cx('delete_img')}
                      src="/statics/images/ic-select-del.png"
                      alt="삭제 이미지"
                    />
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      {!!wishPlayList?.length && (
        <div className={cx('total_payment_inner')}>
          <div className={cx('total_payment_sub_inner')}>
            <div className={cx('total_box')}>
              <div className={cx('select_number_box')}>
                <p className={cx('payment_sub_text')}>선택 작품</p>
                <p className={cx('payment_text')}>{wishPlayList?.length}개</p>
              </div>
              <div className={cx('total_payment')}>
                <p
                  className={cx('payment_sub_text', 'mobile_payment_sub_text')}
                >
                  총 주문금액
                </p>
                <p className={cx('payment_text', 'payment_total_text')}>
                  {numberWithCommas(
                    wishPlayList.reduce((acc, el) => {
                      acc += el?.price;
                      return acc;
                    }, 0),
                  )}
                  원
                </p>
              </div>
            </div>
            <div className={cx('button_box')}>
              <button onClick={isUnlimitedPlaylist ? handleUpdatePlayList : onClickPaymentMove} className={cx('button')}>
                {
                  isUnlimitedPlaylist
                  ? '무료결제'
                  : '결제하기'
                }
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
