import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';
import { NavLink } from 'react-router-dom';
import Popup from '../Popup/Popup';
import styles from './SolutionScheduleComplete.module.scss';

const cx = classNames.bind(styles);

export default function SolutionScheduleComplete({
  visitDate,
  visitManagerName,
  visitManagerPhone,
  visitManagerProfileImageUrl,
}) {
  const [buttonPopup, setButtonPopup] = useState(false);

  // phoneNum = phoneNum.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('header_container')}>
          <div className={cx('inner')}>
            <div className={cx('header_inner')}>
              <div className={cx('selected_process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('selected_process_title')}>상품선택</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('selected_process_title')}>견적요청</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('selected_process_title_box')}>
                  <h4 className={cx('selected_process_title')}>견적검토</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('selected_process_title_box')}>
                  <h4 className={cx('selected_process_title')}>설치확인</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('selected_process_title_box')}>
                  <h4 className={cx('selected_process_title')}>유저정보</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('selected_process_title_box')}>
                  <h4 className={cx('selected_process_title')}>결제방식</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('process_selected_status_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('selected_process_title')}>
                    계약현황 확인
                  </h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('selected_process_title_box')}>
                  <h4 className={cx('selected_process_title')}>계약서 작성</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('selected_process_title_box')}>
                  <h4 className={cx('selected_process_title')}>입금 확인</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('select_process_box')}>
                <div className={cx('select_process_img_box')}>
                  <img
                    src="/statics/images/solution_select.png"
                    alt="solution_select"
                  />
                </div>
                <div className={cx('select_process_title_box')}>
                  <h4 className={cx('select_process_title')}>설치 스케줄</h4>
                </div>
              </div>
            </div>
            <div className={cx('mobile_header_inner')}>
              <div className={cx('mobile_header_progressbar_box')}>
                <div className={cx('mobile_header_progressbar')}></div>
              </div>
              <div className={cx('select_process_box')}>
                <div className={cx('mobile_select_text_box')}>
                  <p className={cx('mobile_select_text')}>step.10</p>
                </div>
                <div className={cx('mobile_select_process_title_box')}>
                  <div className={cx('select_process_title_box')}>
                    <h4 className={cx('select_process_title')}>설치 스케줄</h4>
                  </div>
                  <div className={cx('select_process_img_box')}>
                    <img
                      src="/statics/images/solution_select.png"
                      alt="solution_select"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <div
              className={cx('checklist_button')}
              onClick={() => setButtonPopup(true)}
            >
              <div className={cx('checklist_icon_box')}>
                <img
                  className={cx('checklist_icon')}
                  src="/statics/images/check_list.png"
                  alt="check_list"
                />
              </div>
              <div className={cx('hide_text_box')}>
                <p className={cx('hide_text')}>체크리스트 보기</p>
              </div>
            </div>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>설치 스케줄 확인</h4>
            </div>
            <div className={cx('content_inner')}>
              <div className={cx('engineer_box')}>
                <div className={cx('engineer_charge_box')}>
                  <p className={cx('engineer_charge')}>설치 담당 기사</p>
                </div>
                <div className={cx('engineer_profile_box')}>
                  <img
                    className={cx('img_profile')}
                    src={
                      visitManagerProfileImageUrl
                        ? visitManagerProfileImageUrl
                        : '/statics/images/profile.png'
                    }
                    alt="profile"
                  />
                </div>
                <div className={cx('engineer_name_box')}>
                  <p className={cx('engineer_name')}>{visitManagerName}</p>
                </div>
                <div className={cx('engineer_tell_box')}>
                  <p className={cx('engineer_tell')}>
                    {visitManagerPhone
                      ? visitManagerPhone.replace(
                          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                          '$1-$2-$3',
                        )
                      : '-'}
                  </p>
                </div>
                <div className={cx('engineer_visit_box')}>
                  <p className={cx('engineer_visit')}>
                    {moment(visitDate).format('YYYY')}년{' '}
                    {moment(visitDate).format('MM')}월{' '}
                    {moment(visitDate).format('DD')}일 방문 예정
                  </p>
                </div>
              </div>
            </div>
            <div className={cx('button_box')}>
              {/* <div className={cx('prev_button_box')}>
                                <NavLink to='/solution/schedule' className={cx('prev_button')}>이전 단계 확인</NavLink>
                            </div> */}
              {/* <div className={cx('next_button_box')}>
                                <NavLink to='/solution/success' className={cx('next_button')}>다음 단계로 이동</NavLink>
                            </div> */}
            </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} />
          </div>
        </div>
      </div>
    </div>
  );
}
