import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionRequestEstimatePlace from 'components/views/SolutionRequestEstimatePlace/SolutionRequestEstimatePlace';

export default function SolutionRequestEstimatePlaceWrapper({
    address,
    detailAddress,
    extraInfo,
    isOpenPost,
    handleOpenPost,
    handleClosePost,
    handleClickAddress,
    onSubmit,
    onChange,
    handlePrev,
}) {
    return (
        <>
            <Header />
            <SolutionRequestEstimatePlace
                address={address}
                detailAddress={detailAddress}
                extraInfo={extraInfo}
                isOpenPost={isOpenPost}
                handleOpenPost={handleOpenPost}
                handleClosePost={handleClosePost}
                handleClickAddress={handleClickAddress}
                onSubmit={onSubmit}
                onChange={onChange}
                handlePrev={handlePrev}
            />
        </>
    )
}
