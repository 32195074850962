import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { regEmail, } from 'constants/regExp';
import * as api from 'apis';
import useAuth from 'hooks/useAuth';
import MypagePaymentArtistWrapper from 'components/MypagePaymentArtistWrapper';
import useArtist from 'hooks/useArtist';
import { useNavigate } from 'react-router-dom';

export default function MypagePaymentArtistContainer() {
  const navigator = useNavigate();
  const [payType, setPayType] = useState('trans'); // trans (계좌이체), card
  const [billApply, setBillApply] = useState(true);

  const [info, setInfo] = useState({
    businessEmail: "",
    registrationNumber: "",
    businessName: "",
    isBusinessVerified: false,
  });

  const [fileUrls, setFileUrls] = useState({
    0: {},
  });

  const [data, setData] = useState({
    creatorOrderId: null,
    merchantUid: null,
  });

  const [pageData, setPageData] = useState({
    total: 0,
    currentPage: 1,
    lastPage: 0,
    perPage: 5,
    limit: 10,
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [wishPlayList, setWishPlayList] = useState({});
  const {
    isUnlimitedPlaylist,
  } = useAuth();
  const { wishArtistPlayList, handleUpdateArtist, handleChangeArtist } = useArtist();

  useEffect(() => {
    (async () => {
      try {
        const wish = await handleUpdateArtist();
        const wishIds = [...wish?.map((el) => el.id)];

        const promises = wishIds.map(async id => {
          return await api.getContent(id);
        });
        const result = await Promise.all(promises);
        const realWish = result.map((item) => item?.data);

        const res = realWish.reduce((acc, item, index) => {
          const key = Math.ceil((index + 1) / 10);
          if (!acc.data[key]) {
            acc.data[key] = [];
          }
          acc.data[key].push(item);
          acc.price += item.price;
          return acc;
        }, { data: {}, price: 0 });
        setWishPlayList(res.data);
        handleChangeArtist({ wishArtistPlayList: realWish });
        setTotalPrice(res.price);
        setPageData(prev => ({
          ...prev,
          total: realWish?.length,
          lastPage: Math.ceil(realWish?.length / 10) || 1,
        }));
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (!isUnlimitedPlaylist) return;
    navigator('/');
  }, [isUnlimitedPlaylist]);

  const handlePage = (currentPage) => {
    setPageData(prev => ({
      ...prev,
      currentPage,
    }))
  }

  const handlePaymentStart = async () => {
    try {
      const { businessEmail, registrationNumber, businessName, isBusinessVerified } = info;
      const content_ids = wishArtistPlayList?.map((wish) => wish.id);

      if (payType === 'trans' && billApply) {
        if (!registrationNumber) {
          alert('사업자 번호를 입력해주세요 : )');
          return;
        }

        if (!isBusinessVerified) {
          alert('사업자 번호를 인증해주세요 : )');
          return;
        }

        if (!businessName) {
          alert('사업자 상호를 입력해주세요 : )');
          return;
        }

        if (!businessEmail) {
          alert('세금계산서 발행을 위한 이메일을 입력해주세요 : )');
          return;
        }

        if (!regEmail.test(businessEmail)) {
          alert('이메일 형식에 맞게 입력해주세요 : )');
          return;
        }

        if (!fileUrls[0]?.url) {
          alert('사업자등록증을 업로드해주세요 : )');
          return;
        }

        const res = await api.postCreatorsOrdersReady(content_ids, { businessEmail, registrationNumber, businessName, businessRegistrationImgUrl: fileUrls[0]?.url });
        setData({
          creatorOrderId: res?.data?.creatorOrder_id,
          merchantUid: res?.data?.merchant_uid,
        });
      } else {
        const res = await api.postCreatorsOrdersReady(content_ids, {});
        setData({
          creatorOrderId: res?.data?.creatorOrder_id,
          merchantUid: res?.data?.merchant_uid,
        });
      }

      return true;
    } catch (err) {
      console.log(err);
      return false;
    };
  }

  const handleChange = (e) => {
    if (e.target.name === 'businessNumber') {
      setInfo(prev => ({ ...prev, registrationNumber: e.target.value, isBusinessVerified: false }));
    } else {
      setInfo(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }
  }

  const handleValid = (value) => {
    setInfo(prev => ({ ...prev, isBusinessVerified: value }));
  }

  const handleSuccess = async (response) => {
    try {
      const { success, error_msg, imp_uid, merchant_uid, pay_method, paid_amount, status } = response;
      await api.postCreatorsOrdersConfirm(imp_uid, data.creatorOrderId);
      handleChangeArtist({ wishArtistPlayList: [] });
      navigator('/payment-complete?type=artist');
    } catch (err) {
      navigator('/payment-cancel');
      console.log(err);
    }
  };

  return <MypagePaymentArtistWrapper
    merchantUid={data.merchantUid}
    handleSuccess={handleSuccess}
    handlePaymentStart={handlePaymentStart}

    setPayType={setPayType}
    payType={payType}
    info={info}
    handleChange={handleChange}
    billApply={billApply}
    setBillApply={setBillApply}
    handleValid={handleValid}
    fileUrls={fileUrls}
    handleFileUrls={setFileUrls}

    pageData={pageData}
    totalPrice={totalPrice}
    wishPlayList={wishPlayList}
    handlePage={handlePage}
  />;
}
