import React from 'react';
import Header from 'components/views/Header/Header';
import IntroExample from 'components/views/IntroExample/IntroExample';
import ExamplePopup from 'components/views/ExamplePopup';
import Footer from 'components/views/Footer/Footer';

export default function IntroExampleWrapper({
    usageList,
    locationList,
    currentLocation,
    search,
    currentPage,
    lastPage,
    perPage,
    onChange,
    onSearch,
    onKeyPress,
    handlePage,
    isOpen,
    onClick,
    selectedData,
    onClose,
}) {
    return (
        <>
            <Header />
            <IntroExample
                usageList={usageList}
                locationList={locationList}
                currentLocation={currentLocation}
                search={search}
                currentPage={currentPage}
                lastPage={lastPage}
                perPage={perPage}
                onChange={onChange}
                onSearch={onSearch}
                onKeyPress={onKeyPress}
                handlePage={handlePage}
                onClick={onClick}
            />
            <ExamplePopup
                data={selectedData}
                isShow={isOpen}
                onClose={onClose}
            />
            <Footer />
        </>
    )
}
