import React from 'react';
import { Footer, Header } from 'components/views';
import AdvertisementUpload from 'components/views/AdvertisementUpload';

export default function AdvertisementUploadWrapper({
  videoUrl,
  handleUploadVideo,
  handleCancelVideo,
  isClicked,
  setIsClicked,
}) {
  return (
    <>
      <Header />
      <AdvertisementUpload
        videoUrl={videoUrl}
        handleUploadVideo={handleUploadVideo}
        handleCancelVideo={handleCancelVideo}
        isClicked={isClicked}
        setIsClicked={setIsClicked}
      />
      <Footer />
    </>
  );
}
