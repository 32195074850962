import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import MypageScheduleEdit from 'components/views/MypageScheduleEdit/MypageScheduleEdit';

export default function MypageScheduleEditWrapper() {
    return (
        <>
            <Header /> 
            <MypageScheduleEdit />
            <Footer />
        </>
    )
}
