import React from 'react';
import { Footer, Header } from 'components/views';
import AdvertisementPaymentCancellation from 'components/views/AdvertisementPaymentCancellation';

export default function AdvertisementPaymentCancellationWrapper() {
  return (
    <>
      <Header />
      <AdvertisementPaymentCancellation />
      <Footer />
    </>
  );
}
