import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './SolutionTemp.module.scss';
import CancelSolutionButton from 'components/common/CancelSolutionButton';
import { NavLink } from 'react-router-dom';
import Popup from '../Popup/Popup';
import PrevPopup from '../PrevPopup';

const cx = classNames.bind(styles);

export default function SolutionTemp() {
  const [buttonPopup, setButtonPopup] = useState({
    checklist: false,
    prevPopup: false,
  });

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>솔루션 구매 문의</h4>
            </div>
            <div className={cx('content_inner')}>
              <div className={cx('content_sub_inner')}>
                <div className={cx('iframe_bg')}>
                  <iframe src="https://o22d6pnr56k.typeform.com/to/Sdamhw3W" frameBorder="0"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
