import { combineReducers } from 'redux';
import { persistReducer, PURGE } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import auth from './auth';
import count from './count';
import signup from './signup';
import estimate from './estimate';
import contract from './contract';
import playList from './playList';
import company from './company';
import advertisement from './advertisement';
import artist from './artist';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['estimate', 'contract', 'auth', 'artist'],
}

const rootReducer = combineReducers({
  auth,
  count,
  signup,
  estimate,
  contract,
  playList,
  company,
  advertisement,
  artist,
});

export default persistReducer(persistConfig, rootReducer);