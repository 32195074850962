import React, {
  useCallback,
} from 'react';
import {
  useSelector,
  useDispatch,
} from "react-redux";
import { changePlayData } from 'store/modules/playList';

export default function usePlayList() {
  const {
    playList,
    playType,
    playId,
    playStart,
    selectedPlay,
    realPlayList,
    playLoop,
    playMute,
    playVolume,
    currentPlayTime,
    playDuration,
    shufflePlayList,
  } = useSelector(state => state.playList);
  const dispatch = useDispatch();

  const handleChangePlayData = useCallback((payload) => dispatch(changePlayData(payload)), [dispatch]);

  return {
    playList,
    playType,
    playId,
    playStart,
    handleChangePlayData,
    selectedPlay,
    realPlayList,
    playLoop,
    playMute,
    playVolume,
    currentPlayTime,
    playDuration,
    shufflePlayList,
  }
};