import React, { useEffect, useState } from 'react';
import mockdata from '../Mockup/Mockup';
import classNames from 'classnames/bind';
import styles from './ArtistPaymentHistory.module.scss';
import Pagination from 'components/common/Pagination';
import moment from 'moment';

const cx = classNames.bind(styles);

export default function ArtistPaymentHistory({
  handleArtistPage,
  artistData,
  artistPageData,
}) {
  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <table className={cx('table')}>
          {artistData?.length > 0 && (
            <thead className={cx('thead')}>
              <tr className={cx('thead_tr')}>
                <td className={cx('thead_td')}>구매 번호</td>
                <td className={cx('thead_td', 'title_thead_td')}>작품명</td>
                <td className={cx('thead_td')}>구매 일자</td>
                <td className={cx('thead_td')}>상품 금액</td>
              </tr>
            </thead>
          )}
          <tbody className={cx('tbody')}>
            {artistData?.length > 0 ? (
              artistData.map((el, i) => (
                <tr
                  className={cx('tbody_tr')}
                  key={`${el?.id}-${el?.contents[0]?.title}`}
                >
                  <td className={cx('tbody_td', 'mobile_tbody_purchase_td')}>
                    <p className={cx('mobile_tbody_td')}>구매 번호:</p>
                    {el?.merchant_uid}
                  </td>
                  <td className={cx('tbody_td', 'tbody_title_td')}>
                    <img
                      className={cx('tbody_td_img')}
                      src={el?.contents[0]?.thumbnailUrl}
                      alt="구매 작가콘텐츠 이미지"
                    />
                    <div className={cx('title_box')}>
                      <p className={cx('title')}>{el?.contents[0]?.title}</p>
                      <p className={cx('name')}>
                        {el?.contents[0]?.creator?.name}
                      </p>
                    </div>
                  </td>
                  <td className={cx('tbody_td', 'mobile_tbody_date_td')}>
                    {moment(el?.paidDate).format('YYYY-MM-DD')}
                  </td>
                  <td className={cx('tbody_td', 'tbody_payment_td')}>
                    {`${el?.contents[0]?.price.toLocaleString()}원`}
                  </td>
                  <td className={cx('mobile_payment_td_inner')}>
                    <div className={cx('mobile_payment_td_box')}>
                      <p className={cx('mobile_tbody_td')}>구매 일자:</p>
                      <p className={cx('mobile_td_p')}>
                        {moment(el?.paidDate).format('YYYY-MM-DD')}
                      </p>
                    </div>
                    <div className={cx('mobile_payment_td_box')}>
                      <p className={cx('mobile_tbody_td')}>상품 금액:</p>
                      <p className={cx('mobile_td_p')}>
                        {`${el?.contents[0]?.price.toLocaleString()}원`}
                      </p>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <div className={cx('empty_box')}>
                <p className={cx('empty_text')}>결제 내역이 없습니다.</p>
              </div>
            )}
          </tbody>
        </table>
        {artistData?.length > 0 && (
          <Pagination
            currentPage={artistPageData.currentPage}
            lastPage={artistPageData.lastPage}
            perPage={artistPageData.perPage}
            handlePage={handleArtistPage}
          />
        )}
      </div>
    </div>
  );
}
