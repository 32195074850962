import React from 'react';
import classNames from 'classnames/bind';
import styles from './BeamMonsterLoader.module.scss';

const cx = classNames.bind(styles);

export default function BeamMonsterLoader() {
  return (
    <div className={cx('bg')}>
      <div className={cx('box')}>
        <span className={cx("loader")}></span>
        <span className={cx("loader")}></span>
      </div>
    </div>
  )
}