import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionInstallmentExaminationPayment from 'components/views/SolutionInstallmentExaminationPayment/SolutionInstallmentExaminationPayment';

export default function SolutionInstallmentExaminationPaymentWrapper() {
    return (
        <>
            <Header /> 
            <SolutionInstallmentExaminationPayment />
        </>
    )
}
