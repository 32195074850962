import React, { useState, useRef, useEffect, useCallback } from 'react'
import classNames from 'classnames/bind';
import styles from './Login.module.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function Login({
    email,
    password,
    isError,
    isEmailError,
    onChange,
    onClick,
    handleResend,
    handleKeypress,
}) {
    /* password 숨기기 */
    const [passwordType, setPasswordType] = useState({
        type: 'password',
        visible: false
    });

    const handlePasswordType = (e) => {
        e.preventDefault();
        setPasswordType(() => {
            if (!passwordType.visible) {
                return { type: 'text', visible: true };
            }
            return { type: 'password', visible: false };
        })
    }

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <div className={cx('text_inner')}>
                            <div className={cx('sub_title_box')}>
                                <h4 className={cx('sub_title')}>로그인</h4>
                            </div>
                            <div className={cx('title_box')}>
                                <h4 className={cx('title')}>함께 인테리어를
                                    <br />
                                    PLAY 해볼까요?
                                </h4>
                            </div>
                            <div className={cx('input_box')}>
                                <div className={cx('email_box')}>
                                    <input
                                        className={cx('email_input', { error: isError })}
                                        type="email"
                                        placeholder="이메일 주소를 입력해주세요."
                                        name="email"
                                        value={email}
                                        onChange={onChange}
                                        onKeyPress={handleKeypress}
                                    />
                                </div>
                                <div className={cx('password_box')}>
                                    <input
                                        className={cx('password_input', { error: isError })}
                                        type={passwordType.type}
                                        placeholder="비밀번호를 입력해주세요."
                                        name="password"
                                        value={password}
                                        onChange={onChange}
                                        onKeyPress={handleKeypress}
                                    />
                                    <div className={cx('password_visibilit_img')} onClick={handlePasswordType}>
                                        {
                                            passwordType.visible ?
                                                <img src="/statics/images/ic_pw_visibility_on.png" alt="hide" /> :
                                                <img src="/statics/images/ic_pw_visibility_off.png" alt="view" />
                                        }
                                    </div>
                                    {
                                        isError &&
                                        <div className={cx('password_error_box')}>
                                            <img className={cx('password_error_img')} src="/statics/images/ic_input_info_error.png" alt="error" />
                                            <p className={cx('password_error_text')}>아이디 또는 비밀번호가 일치하지 않습니다.</p>
                                        </div>
                                    }
                                    {
                                        isEmailError &&
                                        <div className={cx('password_error_box')}>
                                            <img className={cx('password_error_img')} src="/statics/images/ic_input_info_error.png" alt="error" />
                                            <p className={cx('password_error_text')}>이메일을 먼저 인증해주세요. <button className={cx('btn_lose')} onClick={handleResend}><b className={cx('lose_b')}>이메일을 받지 못하셨나요?</b></button></p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={cx('login_box')}>
                                <button
                                    className={cx('login_button')}
                                    onClick={onClick}
                                >
                                    로그인
                                </button>
                            </div>
                            <div className={cx('signup_box')}>
                                <Link to="/signup" className={cx('signup_text')}>회원가입</Link>
                                <Link to="/find-password" className={cx('password_lose_text')}>
                                    <b className={cx('password_lose_b')}>비밀번호를 잊으셨나요?</b>
                                </Link>
                            </div>
                        </div>
                        <div className={cx('img_inner')}>
                            <div className={cx('left_img_box')}>
                                <div style={{
                                    backgroundImage: "url(/statics/images/flower_garden.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/mirrorball.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/fireplace.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/pink_beach.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/pink_cloud.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/particle.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/mirrorball.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                            </div>
                            <div className={cx('center_img_box')}>
                                <div style={{
                                    backgroundImage: "url(/statics/images/moon_beach.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/flawless.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/rainbow_particle.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/halloween.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/beach.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/abstract_wave.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/palm_tree.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/whale.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/rainbow_particle.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                            </div>
                            <div className={cx('right_img_box')}>
                                <div style={{
                                    backgroundImage: "url(/statics/images/space.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/curve.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/earth.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/window.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/cloud_moon.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/rainbow_palm_tree.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                                <div style={{
                                    backgroundImage: "url(/statics/images/flower_particle.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                    className={cx('img_box')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}