import React from 'react';
import { Header } from 'components/views';
import SolutionDepositCheck from 'components/views/SolutionDepositCheck';

export default function SolutionDepositCheckWrapper() {
  return (
    <>
        <Header />
        <SolutionDepositCheck />
      </>
  )
}
