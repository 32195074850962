import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './PlayListArtistSearch.module.scss';
import { numberWithCommas } from 'libs/numbers';
import Pagination from 'components/common/Pagination';

const cx = classNames.bind(styles);

export default function PlayListArtistSearch({
  contents,
  categories,
  orderBy,
  currentCategory,
  search,
  currentPage,
  lastPage,
  perPage,
  onChange,
  onClick,
  onKeyPress,
  handlePage,
  total,
  onClickDetailMove,
  handleWishList,
  handleClickDetail,
  wishPlayList,
  handleLike,
  myPlayList,
}) {

  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <div className={cx('title_box')}>
          <p className={cx('title')}>
            내게 딱 맞는 미디어 인테리어를 찾아보세요!
          </p>
        </div>
        <div className={cx('header_inner')}>
          <div className={cx('category_input_inner')}>
            <div className={cx('category_input_box')}>
              <select
                className={cx('select_box')}
                style={{
                  backgroundImage: 'url(/statics/images/ic_expand_more.png)',
                }}
                onChange={onChange}
                name="orderBy"
                value={orderBy}
              >
                <option value="">정렬 선택</option>
                <option value="id">최신순</option>
                <option value="POPULAR">인기순</option>
                <option value="LIKE">좋아요순</option>
              </select>
            </div>
            <div className={cx('category_input_box')}>
              <select
                className={cx('select_box')}
                style={{
                  backgroundImage: 'url(/statics/images/ic_expand_more.png)',
                }}
                onChange={onChange}
                name="currentCategory"
                value={currentCategory}
              >
                <option value="">카테고리 선택</option>
                {categories?.map((el) => {
                  return (
                    <option value={el?.category} key={el?.category}>
                      {`${el?.category} (${numberWithCommas(el?.count)}개)`}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className={cx('search_input_box')}>
            <input
              className={cx('search_input')}
              type="text"
              placeholder="제목으로 검색해 보세요."
              name="search"
              value={search}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
            <button className={cx('search_button')} onClick={onClick}>
              <img
                src="/statics/images/ic_input_search.png"
                alt="search_button"
              />
            </button>
          </div>
        </div>
        <div className={cx('suggestion_list_inner')}>
          {/* 아무것도 없을 때 전체 */}
          {(!total || total === 0) && (
            <div className={cx('empty_box')}>
              <p className={cx('empty_title')}>
                아티스트 콘텐츠 준비 중 입니다.
                </p>
              <p className={cx('empty_text')}>
                최대한 빠르게 준비해 드리겠습니다.
                </p>
            </div>
          )}

          {contents &&
            contents.length > 0 &&
            contents.map((item, itemIndex) => {
              return (
                <div key={item?.id} className={cx('suggestion_box')}
                  role="presentation"
                  onClick={(e) => handleClickDetail(e, item)}
                >
                  <div className={cx('suggestion_img_box')}>
                    <div
                      role="presentation"
                      onClick={(e) => onClickDetailMove(e, item?.id)}
                      className={cx('detail_img_box')}
                    >
                      <img
                        src="/statics/images/ic-input-search.png"
                        alt="상세보기 아이콘"
                      />
                    </div>
                    {
                      myPlayList?.indexOf(item.id) > -1
                        ? <div
                          className={cx('active_basket_img_box')}
                        >
                          <img
                            src="/statics/images/ic-paid.png"
                            alt="결제된 아이콘"
                          />
                        </div>
                        : <div
                          onClick={(e) => handleWishList(e, item)}
                          className={cx(
                            [...wishPlayList.map((p) => p.id)].indexOf(
                              item?.id,
                            ) > -1 ? 'active_basket_img_box' : 'basket_img_box',
                          )}
                        >
                          <img
                            src="/statics/images/ic-playlist-cart.png"
                            alt="장바구니 추가 아이콘"
                          />
                        </div>
                    }
                    {[...wishPlayList.map((p) => p.id)].indexOf(
                      item?.id,
                    ) > -1 ? (
                        <img
                          className={cx('active_suggestion_img')}
                          src={item?.thumbnailUrl}
                          alt="추천 콘텐츠 이미지"
                        />
                      ) : (
                        <img
                          className={cx('suggestion_img')}
                          src={item?.thumbnailUrl}
                          alt="추천 콘텐츠 이미지"
                        />
                      )}
                    <div className={cx('quality_box')}>
                      <p className={cx('quality_text')}>{item?.resolution}</p>
                    </div>
                  </div>
                  <div className={cx('suggestion_info_inner')}>
                    <div className={cx('suggestion_info_box')}>
                      <div className={cx('suggestion_info_title_box')}>
                        <p className={cx('suggestion_info_title')}>
                          {item?.title}
                        </p>
                        <p className={cx('suggestion_info_name')}>
                          {item?.creator?.name}
                        </p>
                      </div>
                      <div
                        onClick={(e) => handleLike(e, itemIndex, item?.id)}
                        className={cx('suggestion_info_img_box')}
                      >
                        {item?.liked ? (
                          <img
                            className={cx('on_like_img')}
                            src="/statics/images/ic_playlist_like_on.png"
                            alt="하트 이미지"
                          />
                        ) : (
                            <img
                              className={cx('off_like_img')}
                              src="/statics/images/ic_playlist_like_off.png"
                              alt="하트 이미지"
                            />
                          )}
                      </div>
                    </div>
                    <div className={cx('suggestion_payment_box')}>
                      <p className={cx('suggestion_payment')}>{item?.price ? `${numberWithCommas(item?.price)}` : ' - '} 원</p>
                    </div>
                  </div>
                </div>
              );
            })}

          {total && total !== 0 && (
            <Pagination
              currentPage={currentPage}
              lastPage={lastPage}
              perPage={perPage}
              handlePage={handlePage}
            />
          )}


        </div>
      </div>
    </div>
  );
}
