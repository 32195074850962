import React, {
    useState,
    useCallback,
    useEffect,
} from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import useEstimate from 'hooks/useEstimate';
import useAuth from 'hooks/useAuth';
import {
    handleCheckSolution,
} from 'libs/authService';
import * as api from 'apis';
import SolutionRequestEstimateBeamWrapper from 'components/SolutionRequestEstimateBeamWrapper';

export default function SolutionRequestEstimateBeamContainer() {
    const navigator = useNavigate();
    const {
        isLogIn,
    } = useAuth();

    const {
        estimateId,
        beamSize,
        handleChangeData,
    } = useEstimate();
    const [data, setData] = useState({
        size: '',
    });

    useEffect(() => {
        (async () => {
            try {
                const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })])
                handleChangeData({
                    estimateId: res[0]?.data?.id || null,
                    hardware: res[0]?.data?.hardware || null,
                    beamSize: res[0]?.data?.beamSize || '',
                    ledWidth: res[0]?.data?.ledWidth * 1000 || '',
                    ledHeight: res[0]?.data?.ledHeight * 1000 || '',
                });

                handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
            } catch (err) {
                console.log(err);
            }
        })()
    }, [handleChangeData, isLogIn, navigator]);

    const handleChage = useCallback((e) => {
        setData(prev => ({
            ...prev,
            size: e.target.value,
        }));
    }, [setData]);

    const handleSubmit = useCallback(async () => {
        try {
            if (!data.size) {
                alert('빔 프로젝터 투사 면적을 선택해주세요 : )');
                return;
            }
            const submitData = {
                beamSize: data.size,
            };
            await api.updateEstimate(estimateId, submitData);
            handleChangeData(submitData);
            navigator('/solution/request-estimate/place');
        } catch (err) {
            console.log(err);
        }
    }, [data, estimateId, navigator, handleChangeData]);

    useEffect(() => {
        setData(prev => ({
            ...prev,
            size: beamSize,
        }))
    }, [beamSize, setData]);

    // useEffect(() => {
    //     if (estimateId) return;
    //     alert('잘못된 접근입니다.');
    //     navigator('/');
    // }, [estimateId, navigator]);

    return (
        <SolutionRequestEstimateBeamWrapper
            data={data}
            onChange={handleChage}
            onSubmit={handleSubmit}
        />
    )
}
