import React from 'react';
import { Header } from 'components/views';
import SolutionRentalInfo from 'components/views/SolutionRentalInfo';

export default function SolutionRentalInfoWrapper({
  onClick,
}) {
  return (
    <>
      <Header />
      <SolutionRentalInfo onClick={onClick} />
    </>
  )
}
