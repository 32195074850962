import React from 'react'
import {
    Footer,
    Header,
    IntroduceCompanyContent,
    IntroduceContent,
    IntroduceMain,
    IntroduceSubContent,
} from '../views';

export default function IntroduceWrapper() {
    return (
        <>
            <Header type='black' />
            <IntroduceMain />
            <IntroduceContent />
            <IntroduceSubContent />
            <IntroduceCompanyContent />
            <Footer />
        </>
    )
}
