import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionSchedule from 'components/views/SolutionSchedule/SolutionSchedule';

export default function SolutionScheduleWrapper() {
    return (
        <>
            <Header /> 
            <SolutionSchedule />
        </>
    )
}
