import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './MypagePaymentHistoryDetailList.module.scss';
import * as api from 'apis';

const cx = classNames.bind(styles);

export default function MypagePaymentHistoryDetailList({
  data,
  startDate,
  endDate,
}) {
  const [storeList, setStoreList] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await api.getAdvertisesPriceEstimate(startDate, endDate, data?.map((el) => el?.storeUser?.id));
        setStoreList(res?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [data, startDate, endDate]);

  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <table className={cx('table')}>
          <thead className={cx('thead')}>
            <tr className={cx('thead_tr')}>
              <td className={cx('td')}>선택 매체</td>
              <td className={cx('td')}>일 노출수</td>
              <td className={cx('td')}>총 노출수</td>
              <td className={cx('td')}>일 노출량</td>
              <td className={cx('td')}>총 노출량</td>
              <td className={cx('td')}>1회 노출 광고비</td>
            </tr>
          </thead>

          <tbody className={cx('tbody')}>
            {storeList?.length > 0 &&
              storeList?.map((el) => {
                return (
                  <tr className={cx('tbody_tr')} key={el.id}>
                    <td className={cx('tbody_td')}>
                      <div className={cx('mobile_inner')}>
                        {el?.storeUserProfileImageUrl ? (
                          <img
                            className={cx('tbody_tr_img')}
                            src={el?.storeUserProfileImageUrl}
                            alt="선택 매체"
                          />
                        ) : (
                            <div className={cx('tbody_tr_img')}>
                              {el?.storeUserBusinessName}
                            </div>
                          )}
                        <div className={cx('mobile_box')}>
                          <p className={cx('mobile_text')}>1회 노출 광고비</p>
                          <p className={cx('mobile_text_b')}>
                            {`${Math.ceil(
                              el?.price / el?.totalViewCount,
                            ).toLocaleString()} 원`}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>일 노출수</p>
                      <p>{`${el?.dailyVisitorCount?.toLocaleString()} 회`}</p>
                    </td>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>총 노출수</p>
                      <p>{`${el?.totalVisitorCount?.toLocaleString()} 회`}</p>
                    </td>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>일 노출량</p>
                      <p>{`${el?.dailyViewCount?.toLocaleString()} 회`}</p>
                    </td>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>총 노출량</p>
                      <p>{`${el?.totalViewCount?.toLocaleString()} 회`}</p>
                    </td>
                    <td className={cx('tbody_td', 'tbody_payment_td')}>
                      <p>
                        {`${Math.ceil(
                          el?.price / el?.totalViewCount,
                        ).toLocaleString()} 원`}
                      </p>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
