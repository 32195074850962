import React, { useState, useRef, useEffect, useCallback } from 'react';
import _ from 'lodash/fp';
import classNames from 'classnames/bind';
import styles from './SolutionPublishEstimateSuccess.module.scss';
import PDFViewerBox from 'components/common/PDFViewerBox';
import CancelSolutionButton from 'components/common/CancelSolutionButton';
import { NavLink } from 'react-router-dom';
import * as api from 'apis';
import Popup from '../Popup/Popup';
import PrevPopup from '../PrevPopup';
import FileUpload from 'components/common/FileUpload';

const cx = classNames.bind(styles);

export default function SolutionPublishEstimateSuccess({
  estimateId,
  fileUrls,
  handleFileUrls,
  onClick,
  onDownload,
  estimateUrl,
  currentPage,
  setCurrentPage,
  handleRequest,
}) {
  const elementSizeRef = useRef(null);
  const [elementSize, setElementSize] = useState({
    width: 0,
    height: 0,
  });

  const [isLoaded, setIsLoaded] = useState(false);

  const [buttonPopup, setButtonPopup] = useState({
    checklist: false,
    prevPopup: false,
  });

  useEffect(() => {
    if (elementSizeRef?.current) {
      const handleResize = () => {
        setElementSize({
          width: elementSizeRef?.current?.offsetWidth - 2,
          height: elementSizeRef?.current?.offsetHeight,
        });
      };
      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, [elementSizeRef, setElementSize]);

  const onChangeImage = (file, index) => {
    handleFileUrls((prev) => ({
      ...prev,
      [index]: file,
    }));
  };

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('header_container')}>
          <div className={cx('inner')}>
            <div className={cx('header_inner')}>
              <div className={cx('selected_process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('selected_process_title')}>상품선택</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('selected_process_title')}>견적요청</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('select_process_box')}>
                <div className={cx('select_process_img_box')}>
                  <img
                    src="/statics/images/solution_select.png"
                    alt="solution_select"
                  />
                </div>
                <div className={cx('select_process_title_box')}>
                  <h4 className={cx('select_process_title')}>견적검토</h4>
                </div>
              </div>
              <div className={cx('select_process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>설치확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>유저정보</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>결제방식</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_status_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>계약현황 확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>계약서 작성</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>입금 확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>설치 스케줄</h4>
                </div>
              </div>
            </div>
            <div className={cx('mobile_header_inner')}>
              <div className={cx('mobile_header_progressbar_box')}>
                <div className={cx('mobile_header_progressbar')}></div>
              </div>
              <div className={cx('select_process_box')}>
                <div className={cx('mobile_select_text_box')}>
                  <p className={cx('mobile_select_text')}>step.3</p>
                </div>
                <div className={cx('mobile_select_process_title_box')}>
                  <div className={cx('select_process_title_box')}>
                    <h4 className={cx('select_process_title')}>견적검토</h4>
                  </div>
                  <div className={cx('select_process_img_box')}>
                    <img
                      src="/statics/images/solution_select.png"
                      alt="solution_select"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <CancelSolutionButton />
            <div
              className={cx('checklist_button')}
              onClick={() =>
                setButtonPopup((prev) => ({
                  ...prev,
                  checklist: !prev.checklist,
                }))
              }
            >
              <div className={cx('checklist_icon_box')}>
                <img
                  className={cx('checklist_icon')}
                  src="/statics/images/check_list.png"
                  alt="check_list"
                />
              </div>
              <div className={cx('hide_text_box')}>
                <p className={cx('hide_text')}>체크리스트 보기</p>
              </div>
            </div>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>견적서 검토</h4>
            </div>
            <div className={cx('content_inner')}>
              <div className={cx('content_img_box')} ref={elementSizeRef}>
                {/* 견적서 나오는곳 */}
                {!isLoaded && <div className={cx('estimate_img')}></div>}
                {estimateUrl && (
                  <PDFViewerBox
                    width={elementSize?.width}
                    height={elementSize?.height}
                    fileUrl={estimateUrl}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    handleLoaded={setIsLoaded}
                  />
                )}
              </div>
              <div className={cx('content_button_box')}>
                <button className={cx('confirm_button')} onClick={onDownload}>
                  견적서 다운로드
                </button>
                <button className={cx('confirm_button')} onClick={onClick}>
                  온라인 견적서 출력
                </button>
              </div>
              <div className={cx('file_upload_inner')}>
                <div className={cx('file_upload_text_box')}>
                  <p className={cx('file_upload_text')}>
                    설치 가능 여부 확인을 위해{' '}
                    <br className={cx('upload_br')} />
                    <b className={cx('upload_text_b')}>캐드 도면</b>과{' '}
                    <b className={cx('upload_text_b')}>현장사진</b>을 첨부
                    해주세요.
                  </p>
                </div>
                <div className={cx('file_upload_box')}>
                  {fileUrls &&
                    Object.keys(fileUrls).map((key, i) => (
                      <FileUpload
                        key={`${fileUrls[key]?.url}-${i}`}
                        index={i}
                        file={fileUrls[key]}
                        onChangeImage={onChangeImage}
                      />
                    ))}
                </div>
              </div>
              <div className={cx('button_box')}>
                {/* <div className={cx('prev_button_box')}>
                                <button onClick={() => setButtonPopup(prev => ({ ...prev, prevPopup: !prev.prevPopup }))} className={cx('prev_button')}>이전 단계 확인</button>
                            </div> */}
                <div className={cx('next_button_box')}>
                  <button className={cx('next_button')} onClick={handleRequest}>
                    구매 상담 요청
                  </button>
                  {/* <NavLink
                    //   to="/solution/publish-estimate/payment"
                    to="/solution/install/request"
                    className={cx('next_button')}
                  >
                    구매 상담 요청
                  </NavLink> */}
                </div>
              </div>
            </div>
            <Popup
              trigger={buttonPopup.checklist}
              setTrigger={setButtonPopup}
            />
            <PrevPopup
              trigger={buttonPopup.prevPopup}
              setTrigger={setButtonPopup}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
