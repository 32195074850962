import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionRequestEstimateBeam from 'components/views/SolutionRequestEstimateBeam';

export default function SolutionRequestEstimateBeamWrapper({
    data,
    onChange,
    onSubmit,
}) {
    return (
        <>
            <Header />
            <SolutionRequestEstimateBeam
                data={data}
                onChange={onChange}
                onSubmit={onSubmit}
            />
        </>
    )
}
