import React from 'react';
import classNames from 'classnames/bind';
import styles from './SubContent.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function SubContent() {
  return (
    <div className={cx('container')}>
      <img
        {...useScrollClipPath('left', 1, 0.4)}
        className={cx('img')}
        src="/statics/images/sub_content_bg.png"
        alt=""
      />
      <div className={cx('inner')}>
        <div className={cx('text_inner')}>
          <div className={cx('title_box')}>
            <h3 {...useScrollFadeIn('left', 1, 0.4)} className={cx('title')}>
              생동감 넘치는 미디어 인테리어로
              <br className={cx('pc_br')} />
              <b className={cx('title_b')}>&nbsp;</b>눈이 즐거운 추억을
              선물하세요
            </h3>
          </div>
          <div {...useScrollFadeIn('right', 1, 0.8)} className={cx('text_box')}>
            <p className={cx('text')}>
              단순한 매장 인테리어 그 이상. <br />
              모든 세대 고객들에게 사랑받는 포토존을 만들어보세요.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
