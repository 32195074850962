import React, { useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';
import FileUpload from 'components/common/FileUpload';
import styles from './MypaAsInquiry.module.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function MypaAsInquiry({
  category,
  newCategory,
  content,
  onChange,
  onSubmit,
  fileUrls,
  handleFileUrls,
}) {
  const onChangeImage = (file, index) => {
    handleFileUrls((prev) => ({
      ...prev,
      [index]: file,
    }));
  };

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('inner')}>
          <Link to="/mypage" className={cx('back_box')}>
            <img
              className={cx('back_img')}
              src="/statics/images/ic_profile_back.png"
              alt="ic_profile_back"
            />
            <div className={cx('back_text_box')}>
              <p className={cx('back_text')}>마이페이지</p>
            </div>
          </Link>
          <div className={cx('inner_box')}>
            <div className={cx('header_inner')}>
              <div className={cx('title_box')}>
                <h4 className={cx('title')}>
                  A/S가 필요한 내용을 자세히 알려주세요.
                </h4>
              </div>
              <Link to="/mypage/faq" className={cx('change_box')}>
                <button className={cx('change_button')}>
                  자주 묻는 질문 (FAQ)
                </button>
              </Link>
            </div>
            <div className={cx('text_box')}>
              <p className={cx('text')}>
                접수하시기 전에 자주묻는 질문을 먼저 확인해주세요.
              </p>
            </div>
            <div className={cx('category_select_inner')}>
              <label className={cx('label')}>카테고리 구분</label>
              <div className={cx('category_select_box')}>
                <select
                  style={{
                    backgroundImage: 'url(/statics/images/ic_expand_more.png)',
                  }}
                  className={cx('category_select')}
                  name="category"
                  value={category}
                  onChange={onChange}
                >
                  <option value="">선택하기</option>
                  <option value="빔프로젝터">빔프로젝터</option>
                  <option value="LED">LED</option>
                  {/* <option value="직접입력">직접입력</option> */}
                </select>
                {category === '직접입력' && (
                  <div className={cx('category_input_box')}>
                    <input
                      className={cx('category_input')}
                      type="text"
                      name="newCategory"
                      value={newCategory}
                      onChange={onChange}
                      placeholder="직접입력"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={cx('textarea_box')}>
              <label className={cx('label')}>상세 내용 입력</label>
              <textarea
                className={cx('textarea')}
                name="content"
                value={content}
                onChange={onChange}
                placeholder="AS 내용에 대하여 자세히 알려주시면 빠르게 안내 도와드리겠습니다:)"
              />
            </div>
            <div className={cx('file_upload_text_box')}>
              <p className={cx('file_upload_text')}>
                A/S 필요 사진 및 동영상을 첨부하시면 A/S를 상세히 도와드릴 수
                있습니다.
              </p>
            </div>
            <div className={cx('file_upload_box')}>
              {fileUrls &&
                Object.keys(fileUrls).map((key, i) => (
                  <FileUpload
                    key={`${fileUrls[key]?.url}-${i}`}
                    index={i}
                    file={fileUrls[key]}
                    onChangeImage={onChangeImage}
                  />
                ))}
            </div>
            <div className={cx('button_box')}>
              <button className={cx('button')} onClick={onSubmit}>
                새로운 A/S 접수하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
