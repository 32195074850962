import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './SolutionInstallSuccess.module.scss';
import CancelSolutionButton from 'components/common/CancelSolutionButton';
import Popup from '../Popup/Popup';
import PrevPopup from '../PrevPopup';
import { NavLink } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function SolutionInstallSuccess({
  fileUrl,
  onClick,
  onDownload,
  phone,
}) {
  const [buttonPopup, setButtonPopup] = useState({
    checklist: false,
    prevPopup: false,
  });

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('header_container')}>
          <div className={cx('inner')}>
            <div className={cx('header_inner')}>
              <div className={cx('selected_process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('selected_process_title')}>상품선택</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('selected_process_title')}>견적요청</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('selected_process_box')}>
                <div className={cx('selected_process_title_box')}>
                  <h4 className={cx('selected_process_title')}>견적검토</h4>
                </div>
              </div>
              <div className={cx('selected_process_bar')}></div>
              <div className={cx('select_process_box')}>
                <div className={cx('select_process_img_box')}>
                  <img
                    src="/statics/images/solution_select.png"
                    alt="solution_select"
                  />
                </div>
                <div className={cx('select_process_title_box')}>
                  <h4 className={cx('select_process_title')}>설치확인</h4>
                </div>
              </div>
              <div className={cx('select_process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>유저정보</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>결제방식</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_status_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>계약현황 확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>계약서 작성</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>입금 확인</h4>
                </div>
              </div>
              <div className={cx('process_bar')}></div>
              <div className={cx('process_box')}>
                <div className={cx('process_title_box')}>
                  <h4 className={cx('process_title')}>설치 스케줄</h4>
                </div>
              </div>
            </div>
            <div className={cx('mobile_header_inner')}>
              <div className={cx('mobile_header_progressbar_box')}>
                <div className={cx('mobile_header_progressbar')}></div>
              </div>
              <div className={cx('select_process_box')}>
                <div className={cx('mobile_select_text_box')}>
                  <p className={cx('mobile_select_text')}>step.4</p>
                </div>
                <div className={cx('mobile_select_process_title_box')}>
                  <div className={cx('select_process_title_box')}>
                    <h4 className={cx('select_process_title')}>설치확인</h4>
                  </div>
                  <div className={cx('select_process_img_box')}>
                    <img
                      src="/statics/images/solution_select.png"
                      alt="solution_select"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <CancelSolutionButton />
            <div
              className={cx('checklist_button')}
              onClick={() =>
                setButtonPopup((prev) => ({
                  ...prev,
                  checklist: !prev.checklist,
                }))
              }
            >
              <div className={cx('checklist_icon_box')}>
                <img
                  className={cx('checklist_icon')}
                  src="/statics/images/check_list.png"
                  alt="check_list"
                />
              </div>
              <div className={cx('hide_text_box')}>
                <p className={cx('hide_text')}>체크리스트 보기</p>
              </div>
            </div>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>설치 가능</h4>
            </div>
            <div className={cx('content_inner')}>
              <div className={cx('content_img_box')}>
                <img
                  className={cx('content_img')}
                  src="/statics/images/solution_install_ok.png"
                  alt="content_img"
                />
              </div>
              <div className={cx('content_text_box')}>
                <p className={cx('content_text')}>설치가 가능한 현장입니다.</p>
              </div>
              <div className={cx('content_sub_text_box')}>
                <p className={cx('content_sub_text')}>
                  계약 진행을 시작해주세요.
                </p>
              </div>
              <div className={cx('consulting_box')}>
                <div className={cx('consulting_text_box')}>
                  <p className={cx('consulting_text')}>
                    진행이 어려우시다면 친절한 상담을 받아보세요!
                  </p>
                </div>
                <div className={cx('consulting_tell_box')}>
                  <p className={cx('consulting_tell')}>{phone}</p>
                </div>
              </div>
              <div className={cx('button_box')}>
                {/* <div className={cx('prev_button_box')}>
                                <button onClick={() => setButtonPopup(prev => ({ ...prev, prevPopup: !prev.prevPopup }))} className={cx('prev_button')}>이전 단계 확인</button>
                            </div> */}
                <div className={cx('next_button_box')}>
                  <NavLink
                    to="/solution/user-info"
                    className={cx('next_button')}
                  >
                    계약 진행
                  </NavLink>
                </div>
              </div>
            </div>
            <Popup
              trigger={buttonPopup.checklist}
              setTrigger={setButtonPopup}
            />
            <PrevPopup
              trigger={buttonPopup.prevPopup}
              setTrigger={setButtonPopup}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
