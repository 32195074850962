import React from 'react';
import SolutionContractWaiting from 'components/views/SolutionContractWaiting';
import { Header } from 'components/views';

export default function SolutionContractWaitingWrapper() {
    return (
        <>
            <Header />
            <SolutionContractWaiting />
        </>
  )
}
