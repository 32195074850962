import React, {
    useEffect,
} from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import * as api from 'apis';
import useAuth from 'hooks/useAuth';
import {
    handleCheckSolution,
} from 'libs/authService';
import SolutionPublishEstimateWaitWrapper from 'components/SolutionPublishEstimateWaitWrapper';

export default function SolutionPublishEstimateWaitContainer() {
    const navigator = useNavigate();
    const {
        isLogIn,
    } = useAuth();

    useEffect(() => {
        (async () => {
            try {
                const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })]);
                if (res[0]?.data?.status === "CONFIRM") {
                    navigator('/solution/publish-estimate/success');
                } else {
                    handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }, [isLogIn, navigator]);

    return (
        <SolutionPublishEstimateWaitWrapper />
    )
}
