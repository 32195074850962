import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionPublishEstimateWait from 'components/views/SolutionPublishEstimateWait/SolutionPublishEstimateWait';

export default function SolutionPublishEstimateWaitWrapper() {
    return (
        <>
            <Header />
            <SolutionPublishEstimateWait />
        </>
    )
}
