import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeAdvertisementData,
  resetAdvertisementData,
} from 'store/modules/advertisement';

export default function useAdvertisement() {
  const advertisement = useSelector((state) => state.advertisement);
  const {
    selectedCategory,
    selectedBusinessCategories,
    videoUrl,
    startDate,
    endDate,
    minAdPurchaseDays,
    selectedStores,
    estimateInfo,
    advertiseOrderId,
    merchantUid,
  } = advertisement;
  const dispatch = useDispatch();

  const handleChangeAdvertisementData = useCallback(
    (payload) => dispatch(changeAdvertisementData(payload)),
    [dispatch],
  );
  const handleResetAdvertisementData = useCallback(
    () => dispatch(resetAdvertisementData()),
    [dispatch],
  );

  return {
    selectedCategory,
    selectedBusinessCategories,
    videoUrl,
    startDate,
    endDate,
    minAdPurchaseDays,
    selectedStores,
    estimateInfo,
    handleChangeAdvertisementData,
    advertiseOrderId,
    merchantUid,
    handleResetAdvertisementData,
  };
}
