import React, {
    useCallback,
    useState,
} from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import * as api from 'apis';
import MypaAsInquiryWrapper from 'components/MypaAsInquiryWrapper/MypaAsInquiryWrapper';

export default function MypaAsInquiryContainer() {
    const navigate = useNavigate();
    const [data, setData] = useState({
        category: '',
        newCategory: '',
        content: '',
    });

    // 파일 업로드
    const [fileUrls, setFileUrls] = useState({
        0: {},
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
        6: {},
    });

    const handleChange = useCallback((e) => {
        setData(prev => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }, [setData]);

    const handleSubmit = useCallback(async () => {
        if (!data.category || (data.category === '직접입력' && !data.newCategory) || !data.content) {
            alert('카테고리 및 상세내용을 입력해주세요 : )');
            return
        };
        try {
            const files = Object.keys(fileUrls).map((key) => fileUrls[key]?.url).filter(url => !!url);

            const res = await api.postAfterServiceList({
                category: data.category === '직접입력' ? data.newCategory : data.category,
                content: data.content,
                files,
            });

            alert('빠른시일내에 답변을 드리겠습니다 :)');
            navigate('/mypage/as');
        } catch (err) {
            console.log(err);
        }
    }, [data, navigate, fileUrls]);

    return (
        <MypaAsInquiryWrapper
            category={data.category}
            newCategory={data.category}
            content={data.content}
            onChange={handleChange}
            onSubmit={handleSubmit}
            fileUrls={fileUrls}
            handleFileUrls={setFileUrls}
        />
    )
}
