import React, { useEffect, useState } from 'react';
import * as api from 'apis';
import useAuth from 'hooks/useAuth';
import useEstimate from 'hooks/useEstimate';
import useContract from 'hooks/useContract';
import IndexWrapper from 'components/IndexWrapper/IndexWrapper';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

export default function IndexContainer() {
  const [data, setData] = useState({});
  const [selectedMainContent, setMainContent] = useState();
  const [selectedIndex, setIndex] = useState(0);
  const [clickedIndex, setClicked] = useState(0);
  const [selectedExample, setSelectedExmaple] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const navigator = useNavigate();

  const { isLogIn } = useAuth();

  const { estimate } = useEstimate();
  const { contract } = useContract();

  useEffect(() => {
    (async () => {
      try {
        const res = await api.getMainPageInfo();
        setData(res?.data?.value);
        setMainContent(res?.data?.value?.themes[0]);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleExampleClick = (el, index) => {
    setSelectedExmaple({ id: `${el?.name}-${index}`, ...el });
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClick = (i) => {
    if (selectedIndex === i) return;
    $(`#video-${selectedIndex}`).animate(
      { opacity: 0 },
      0,
      'linear',
      function () {
        $(this).css('display', 'none');
        $(`#video-${i}`).css('display', 'block');
        $(`#video-${i}`).animate({ opacity: 0 }, 0);
        $(`#video-${i}`).animate({ opacity: 1 }, 0, function () {
          setMainContent(data?.themes[i]);
          setIndex(i);
        });
      },
    );
    setClicked(i);
  };

  const handleSolution = () => {
    // navigator('/solution');
    window.location.href = 'https://beammonsterstore.wixsite.com/store';
    // return alert('솔루션은 문의(대표번호 : 070-4735-7246)를 통해 접수 부탁드립니다 : )');
    // if (!isLogIn) {
    //     alert('솔루션 신청을 위해 로그인이 필요합니다 : )');
    //     navigator('/login');
    //     return;
    // }

    // switch (estimate?.status) {
    //     case "READY":
    //         navigator('/solution/choice-product');
    //         break;
    //     case "READY_LED_SIZE_DONE":
    //         navigator('/solution/request-estimate/led');
    //         break;
    //     case "READY_BEAM_SIZE_DONE":
    //         navigator('/solution/request-estimate/beam');
    //         break;
    //     case "READY_ADDRESS_DONE":
    //         navigator('/solution/request-estimate/place');
    //         break;
    //     case "READY_SOLUTION_TYPE_DONE":
    //         navigator('/solution/request-estimate/solution');
    //         break;
    //     case "PENDING_CHECK_ADMIN":
    //         navigator('/solution/install/request');
    //         break;
    //     case "ADMIN_REJECTED":
    //         navigator('/solution/install/fail');
    //         break;
    //     case "ADMIN_CONFIRM":
    //         navigator('/solution/install/success');
    //         break;
    //     case "SUBMIT":
    //         navigator('/solution/publish-estimate/wait');
    //         break;
    //     case "CONFIRM":
    //         if (contract?.paymentType === 'ONCE') {
    //             if (contract?.status === 'READY') {
    //                 navigator('/solution/contract-waiting');
    //             } else if (contract?.status === 'UPLOADED') {
    //                 if (contract?.clientSignPdfUrl) {
    //                     navigator('/solution/deposit-wait');
    //                 } else {
    //                     navigator('/solution/contract-complete');
    //                 }
    //             } else if (contract?.status === 'MONEY_CHECKED') {
    //                 navigator('/solution/deposit-complete');
    //             } else if (contract?.status === 'SCHEDULED') {
    //                 navigator('/solution/schedule-complete');
    //             } else if (contract?.status === 'FINISH') {
    //                 navigator('/solution/success');
    //             } else {
    //                 navigator('/solution/publish-estimate/payment');
    //             }
    //         } else if (contract?.paymentType === 'RENTAL') {
    //             if (contract?.status === 'READY') {
    //                 navigator('/solution/rental-info');
    //             } else if (contract?.status === 'RENTAL_READY') {
    //                 navigator('/solution/rental-wait');
    //             } else if (contract?.status === 'RENTAL_CHECKED') {
    //                 navigator('/solution/contract-waiting');
    //             } else if (contract?.status === 'UPLOADED') {
    //                 if (contract?.clientSignPdfUrl) {
    //                     navigator('/solution/deposit-wait');
    //                 } else {
    //                     navigator('/solution/contract-complete');
    //                 }
    //             } else if (contract?.status === 'MONEY_CHECKED') {
    //                 navigator('/solution/deposit-complete');
    //             } else if (contract?.status === 'SCHEDULED') {
    //                 navigator('/solution/schedule-complete');
    //             } else if (contract?.status === 'FINISH') {
    //                 navigator('/solution/success');
    //             } else {
    //                 navigator('/solution/publish-estimate/payment');
    //             }
    //         } else {
    //             navigator('/solution/publish-estimate/payment');
    //         }
    //         break;
    //     default:
    //         navigator('/solution/choice-product');
    //         break;
    // }

    window.scrollTo(0, 0);
  };

  return (
    <IndexWrapper
      clickedIndex={clickedIndex}
      selectedMainContent={selectedMainContent}
      themes={data?.themes || []}
      usingExamples={data?.usingExamples || []}
      faqs={data?.faqs || []}
      onClick={handleClick}
      selectedIndex={selectedIndex}
      onExampleClick={handleExampleClick}
      isOpen={isOpen}
      selectedExample={selectedExample}
      onClose={handleClose}
      handleSolution={handleSolution}
    />
  );
}
