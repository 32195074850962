import React from 'react';
import Play from 'components/views/Play';

export default function PlayWrapper({
    onClick,
    isFullScreen,
}) {
    return (
        <Play
            onClick={onClick}
            isFullScreen={isFullScreen}
        />
    )
}
