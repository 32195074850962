import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './IntroServiceHardware.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';

const cx = classNames.bind(styles);

// 모바일 캐러셀
const slides = [
  {
    slide: (
      <div className={cx('select_box')}>
        <div className={cx('select_img_box')}>
          <img
            className={cx('select_img')}
            src="/statics/images/img_intro_hardware_first.png"
            alt="img_intro_hardware_first"
          />
        </div>
        <div className={cx('select_title_box')}>
          <h4 className={cx('select_title')}>LED</h4>
        </div>
        <div className={cx('select_sub_title_box')}>
          <h4 className={cx('select_sub_title')}>
            LED 패널을 통하여 영상을 재생
          </h4>
        </div>
        <div className={cx('select_hr')}></div>
        <div className={cx('select_list_inner')}>
          <div className={cx('select_list_item')}>
            <div className={cx('select_list_item_img_box')}>
              <img
                src="/statics/images/ic_task_alt_blue.png"
                alt="ic_task_alt_blue"
              />
            </div>
            <p className={cx('select_list_item_text')}>
              공간 밝기에 영향을 받지 않음
            </p>
          </div>
          <div className={cx('select_list_item')}>
            <div className={cx('select_list_item_img_box')}>
              <img
                src="/statics/images/ic_task_alt_blue.png"
                alt="ic_task_alt_blue"
              />
            </div>
            <p className={cx('select_list_item_text')}>그림자 발생 하지 않음</p>
          </div>
          <div className={cx('select_list_item')}>
            <div className={cx('select_list_item_img_box')}>
              <img
                src="/statics/images/ic_task_alt_blue.png"
                alt="ic_task_alt_blue"
              />
            </div>
            <p className={cx('select_list_item_text')}>
              최소 투사 거리 필요 없음
            </p>
          </div>
          <div className={cx('select_list_item')}>
            <div className={cx('select_list_item_img_box')}>
              <img
                src="/statics/images/ic_task_alt_blue.png"
                alt="ic_task_alt_blue"
              />
            </div>
            <p className={cx('select_list_item_text')}>단차 발생 있음</p>
          </div>
          <div className={cx('select_list_item')}>
            <div className={cx('select_list_item_img_box')}>
              <img
                src="/statics/images/ic_task_alt_blue.png"
                alt="ic_task_alt_blue"
              />
            </div>
            <p className={cx('select_list_item_text')}>
              벽면 색상과 질감에 영향을 받지 않음
            </p>
          </div>
          <div className={cx('denger_text_box')}>
            <p className={cx('denger_text')}>*</p>
            <p className={cx('denger_text')}>
              LED 패널 무게로 인해, 석고보드 벽면에는 설치가 불가함
            </p>
          </div>
          <div className={cx('denger_text_box')}>
            <p className={cx('denger_text')}>*</p>
            <p className={cx('denger_text')}>
              실측 값과 실제 제품 사이즈가 패널의 특성상 5~15cm 오차 범위가 있을
              수 있음
            </p>
          </div>
        </div>
        {/* <div className={cx('button_box')}>
          <div className={cx('button_sub_box')}>
            <div className={cx('button_img_box')}>
              <img
                className={cx('button_img')}
                src="/statics/images/ic_input_info_error.png"
                alt="ic_input_info_error"
              />
            </div>
            <div className={cx('button_text_box')}>
              <p className={cx('button_text')}>설치 면적(m2) 당 가격 형성</p>
            </div>
          </div>
        </div> */}
      </div>
    ),
  },
  {
    slide: (
      <div className={cx('select_box')}>
        <div className={cx('select_img_box')}>
          <img
            className={cx('select_img')}
            src="/statics/images/img_intro_hardware_second.png"
            alt="img_intro_hardware_second"
          />
        </div>
        <div className={cx('select_title_box')}>
          <h4 className={cx('select_title')}>빔 프로젝터</h4>
        </div>
        <div className={cx('select_sub_title_box')}>
          <h4 className={cx('select_sub_title')}>
            빛을 벽면에 비추어 영상을 재생
          </h4>
        </div>
        <div className={cx('select_hr')}></div>
        <div className={cx('select_list_inner')}>
          <div className={cx('select_list_item')}>
            <div className={cx('select_list_item_img_box')}>
              <img
                src="/statics/images/ic_task_alt_blue.png"
                alt="ic_task_alt_blue"
              />
            </div>
            <p className={cx('select_list_item_text')}>
              공간 밝기에 영향을 받음
            </p>
          </div>
          <div className={cx('select_list_item')}>
            <div className={cx('select_list_item_img_box')}>
              <img
                src="/statics/images/ic_task_alt_blue.png"
                alt="ic_task_alt_blue"
              />
            </div>
            <p className={cx('select_list_item_text')}>그림자 발생</p>
          </div>
          <div className={cx('select_list_item')}>
            <div className={cx('select_list_item_img_box')}>
              <img
                src="/statics/images/ic_task_alt_blue.png"
                alt="ic_task_alt_blue"
              />
            </div>
            <p className={cx('select_list_item_text')}>최소 투사 거리 필요</p>
          </div>
          <div className={cx('select_list_item')}>
            <div className={cx('select_list_item_img_box')}>
              <img
                src="/statics/images/ic_task_alt_blue.png"
                alt="ic_task_alt_blue"
              />
            </div>
            <p className={cx('select_list_item_text')}>단차 발생 없음</p>
          </div>
          <div className={cx('select_list_item')}>
            <div className={cx('select_list_item_img_box')}>
              <img
                src="/statics/images/ic_task_alt_blue.png"
                alt="ic_task_alt_blue"
              />
            </div>
            <p className={cx('select_list_item_text')}>
              백면 색상과 질감에 의해 영상이 왜곡 될 수 있음
            </p>
          </div>
        </div>
        {/* <div className={cx('button_box')}>
          <div className={cx('button_sub_box')}>
            <div className={cx('button_img_box')}>
              <img
                className={cx('button_img')}
                src="/statics/images/ic_input_info_error.png"
                alt="ic_input_info_error"
              />
            </div>
            <div className={cx('button_text_box')}>
              <p className={cx('button_text')}>대수 별 면적 비교 (가로x세로)</p>
            </div>
          </div>
          <div className={cx('button_bottom_text_box')}>
            <p className={cx('button_bottom_text')}>
              - 1대 : 1.33~4.4m x 0.75~2.5m
            </p>
            <p className={cx('button_bottom_text')}>
              - 2대 : 4.5~7.1m x 0.75~2.5m
            </p>
            <p className={cx('button_bottom_text')}>
              - 3대 : 7.2~10.6m x 0.75~2.5m
            </p>
            <p className={cx('button_bottom_text')}>
              - 4대 : 10.7~14.2m x 0.75~2.5m
            </p>
          </div>
        </div> */}
      </div>
    ),
  },
];

export default function IntroServiceHardware() {
  const [carousel, setCarousel] = useState(0);
  // const [auto, setAuto] = useState(0);
  // const max = slides.length;

  // const autoSlide = () => {
  //     auto && setCarousel(carousel === max - 1 ? 0 : carousel + 1);
  // }

  // useEffect(() => {
  //     const interval = setInterval( () => autoSlide(), 3000);
  //     return () => clearInterval(interval);
  // });

  const renderSlides = useMemo(() => {
    return slides.map((el, i) => {
      return (
        <div key={i}>
          {i === carousel && (
            <div className={cx('mobile_slider_box')}>{el.slide}</div>
          )}
        </div>
      );
    });
  }, [carousel]);

  const renderDots = useMemo(() => {
    return slides.map((el, i) => {
      return (
        <div
          key={i}
          className={cx(carousel === i ? 'active_mobile_Dots' : 'mobile_Dots')}
        >
          <div
            className={cx('mobile_Dot')}
            onClick={() => setCarousel(i)}
          ></div>
        </div>
      );
    });
  }, [slides, carousel]);

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <div {...useScrollFadeIn('up', 1, 0)} className={cx('title_box')}>
              <h4 className={cx('title')}>
                최적의 하드웨어로
                <br className={cx('mobile_br')} /> 시작하세요.
              </h4>
            </div>
            <div {...useScrollFadeIn('up', 1, 0.1)} className={cx('text_box')}>
              <p className={cx('text')}>
                하드웨어는 LED와 빔 프로젝터로 나뉘며, 선택에 따라 각 세부장비
                구성도 달라집니다.
              </p>
            </div>
          </div>
          <div className={cx('select_inner')}>
            <div {...useScrollFadeIn('up', 1, 0)} className={cx('select_box')}>
              <div className={cx('select_img_box')}>
                <img
                  className={cx('select_img')}
                  src="/statics/images/img_intro_hardware_first.png"
                  alt="img_intro_hardware_first"
                />
              </div>
              <div className={cx('select_title_box')}>
                <h4 className={cx('select_title')}>LED</h4>
              </div>
              <div className={cx('select_sub_title_box')}>
                <h4 className={cx('select_sub_title')}>
                  LED 패널을 통하여 영상을 재생
                </h4>
              </div>
              <div className={cx('select_hr')}></div>
              <div className={cx('select_list_inner')}>
                <div className={cx('select_list_item')}>
                  <div className={cx('select_list_item_img_box')}>
                    <img
                      src="/statics/images/ic_task_alt_blue.png"
                      alt="ic_task_alt_blue"
                    />
                  </div>
                  <p className={cx('select_list_item_text')}>
                    공간 밝기에 영향을 받지 않음
                  </p>
                </div>
                <div className={cx('select_list_item')}>
                  <div className={cx('select_list_item_img_box')}>
                    <img
                      src="/statics/images/ic_task_alt_blue.png"
                      alt="ic_task_alt_blue"
                    />
                  </div>
                  <p className={cx('select_list_item_text')}>
                    그림자 발생 하지 않음
                  </p>
                </div>
                <div className={cx('select_list_item')}>
                  <div className={cx('select_list_item_img_box')}>
                    <img
                      src="/statics/images/ic_task_alt_blue.png"
                      alt="ic_task_alt_blue"
                    />
                  </div>
                  <p className={cx('select_list_item_text')}>
                    최소 투사 거리 필요 없음
                  </p>
                </div>
                <div className={cx('select_list_item')}>
                  <div className={cx('select_list_item_img_box')}>
                    <img
                      src="/statics/images/ic_task_alt_blue.png"
                      alt="ic_task_alt_blue"
                    />
                  </div>
                  <p className={cx('select_list_item_text')}>단차 발생 있음</p>
                </div>
                <div className={cx('select_list_item')}>
                  <div className={cx('select_list_item_img_box')}>
                    <img
                      src="/statics/images/ic_task_alt_blue.png"
                      alt="ic_task_alt_blue"
                    />
                  </div>
                  <p className={cx('select_list_item_text')}>
                    벽면 색상과 질감에 영향을 받지 않음
                  </p>
                </div>
                <div className={cx('denger_text_box')}>
                  <p className={cx('denger_text')}>*</p>
                  <p className={cx('denger_text')}>
                    LED 패널 무게로 인해, 석고보드 벽면에는 설치가 불가함
                  </p>
                </div>
                <div className={cx('denger_text_box')}>
                  <p className={cx('denger_text')}>*</p>
                  <p className={cx('denger_text')}>
                    실측 값과 실제 제품 사이즈가 패널의 특성상 5~15cm 오차
                    범위가 있을 수 있음
                  </p>
                </div>
              </div>
              {/* <div className={cx('button_box')}>
                <div className={cx('button_sub_box')}>
                  <div className={cx('button_img_box')}>
                    <img
                      className={cx('button_img')}
                      src="/statics/images/ic_input_info_error.png"
                      alt="ic_input_info_error"
                    />
                  </div>
                  <div className={cx('button_text_box')}>
                    <p className={cx('button_text')}>
                      설치 면적(m2) 당 가격 형성
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
            <div {...useScrollFadeIn('up', 1, 0)} className={cx('select_box')}>
              <div className={cx('select_img_box')}>
                <img
                  className={cx('select_img')}
                  src="/statics/images/img_intro_hardware_second.png"
                  alt="img_intro_hardware_second"
                />
              </div>
              <div className={cx('select_title_box')}>
                <h4 className={cx('select_title')}>빔 프로젝터</h4>
              </div>
              <div className={cx('select_sub_title_box')}>
                <h4 className={cx('select_sub_title')}>
                  빛을 벽면에 비추어 영상을 재생
                </h4>
              </div>
              <div className={cx('select_hr')}></div>
              <div className={cx('select_list_inner')}>
                <div className={cx('select_list_item')}>
                  <div className={cx('select_list_item_img_box')}>
                    <img
                      src="/statics/images/ic_task_alt_blue.png"
                      alt="ic_task_alt_blue"
                    />
                  </div>
                  <p className={cx('select_list_item_text')}>
                    공간 밝기에 영향을 받음
                  </p>
                </div>
                <div className={cx('select_list_item')}>
                  <div className={cx('select_list_item_img_box')}>
                    <img
                      src="/statics/images/ic_task_alt_blue.png"
                      alt="ic_task_alt_blue"
                    />
                  </div>
                  <p className={cx('select_list_item_text')}>그림자 발생</p>
                </div>
                <div className={cx('select_list_item')}>
                  <div className={cx('select_list_item_img_box')}>
                    <img
                      src="/statics/images/ic_task_alt_blue.png"
                      alt="ic_task_alt_blue"
                    />
                  </div>
                  <p className={cx('select_list_item_text')}>
                    최소 투사 거리 필요
                  </p>
                </div>
                <div className={cx('select_list_item')}>
                  <div className={cx('select_list_item_img_box')}>
                    <img
                      src="/statics/images/ic_task_alt_blue.png"
                      alt="ic_task_alt_blue"
                    />
                  </div>
                  <p className={cx('select_list_item_text')}>단차 발생 없음</p>
                </div>
                <div className={cx('sub_select_list_item')}>
                  <div className={cx('select_list_item_img_box')}>
                    <img
                      src="/statics/images/ic_task_alt_blue.png"
                      alt="ic_task_alt_blue"
                    />
                  </div>
                  <p className={cx('sub_select_list_item_text')}>
                    벽면 색상과 질감에 의해 영상이 왜곡 될 수 있음
                  </p>
                </div>
              </div>
              {/* <div className={cx('button_box')}>
                <div className={cx('button_sub_box')}>
                  <div className={cx('button_img_box')}>
                    <img
                      className={cx('button_img')}
                      src="/statics/images/ic_input_info_error.png"
                      alt="ic_input_info_error"
                    />
                  </div>
                  <div className={cx('button_text_box')}>
                    <p className={cx('button_text')}>
                      대수 별 면적 비교 (가로x세로)
                    </p>
                  </div>
                </div>
                <div className={cx('button_bottom_text_box')}>
                  <p className={cx('button_bottom_text')}>
                    - 1대 : 1.33~4.4m x 0.75~2.5m
                  </p>
                  <p className={cx('button_bottom_text')}>
                    - 2대 : 4.5~7.1m x 0.75~2.5m
                  </p>
                  <p className={cx('button_bottom_text')}>
                    - 3대 : 7.2~10.6m x 0.75~2.5m
                  </p>
                  <p className={cx('button_bottom_text')}>
                    - 4대 : 10.7~14.2m x 0.75~2.5m
                  </p>
                </div>
              </div> */}
            </div>
          </div>
          <div className={cx('mobile_slider_inner')}>
            {renderSlides}
            <div className={cx('mobile_Dots_box')}>{renderDots}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
