import React from 'react';
import { Footer, Header } from 'components/views';
import MypageBasketArtist from 'components/views/MypageBasketArtist';

export default function MypageBasketArtistWrapper({
  wishPlayList,
  handleWishList,
  checkPlayList,
  handleCheckList,
  handleCheckAll,
  handleRemove,
  isUnlimitedPlaylist,
  handleUpdatePlayList,
}) {
  return (
    <>
      <Header />
      <MypageBasketArtist
        wishPlayList={wishPlayList}
        handleWishList={handleWishList}
        checkPlayList={checkPlayList}
        handleCheckList={handleCheckList}
        handleCheckAll={handleCheckAll}
        handleRemove={handleRemove}
        isUnlimitedPlaylist={isUnlimitedPlaylist}
        handleUpdatePlayList={handleUpdatePlayList}
      />
      <Footer />
    </>
  );
}
