import React from 'react';
import { Header } from 'components/views';
import SolutionSealComplete from 'components/views/SolutionSealComplete';

export default function SolutionSealCompleteWrapper() {
  return (
    <>
      <Header />
      <SolutionSealComplete />
    </>
  )
}
