import React, {
    useEffect,
} from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import {
    handleCheckSolution,
} from 'libs/authService';
import * as api from 'apis';
import SolutionSuccessWrapper from 'components/SolutionSuccessWrapper/SolutionSuccessWrapper';

export default function SolutionSuccessContainer() {
    const navigator = useNavigate();
    const {
        isLogIn,
    } = useAuth();

    useEffect(() => {
        (async () => {
            try {
                const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })]);
                handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
            } catch (err) {
                console.log(err);
            }
        })()
    }, [isLogIn, navigator]);

    return (
        <SolutionSuccessWrapper />
    )
}
