import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import SearchAddress from 'components/common/SearchAddress';
import CancelSolutionButton from 'components/common/CancelSolutionButton';
import { NavLink } from 'react-router-dom';
import styles from './SolutionRequestEstimatePlace.module.scss';
import Popup from '../Popup/Popup';

const cx = classNames.bind(styles);

export default function SolutionRequestEstimatePlace({
  address,
  detailAddress,
  extraInfo,
  isOpenPost,
  handleOpenPost,
  handleClosePost,
  handleClickAddress,
  onChange,
  onSubmit,
  handlePrev,
}) {
  const navigate = useNavigate();
  const [buttonPopup, setButtonPopup] = useState(false);

  return (
    <>
      <div className={cx('container')}>
        <div className={cx('sub_container')}>
          <div className={cx('header_container')}>
            <div className={cx('inner')}>
              <div className={cx('header_inner')}>
                <div className={cx('selected_process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('selected_process_title')}>상품선택</h4>
                  </div>
                </div>
                <div className={cx('selected_process_bar')}></div>
                <div className={cx('select_process_box')}>
                  <div className={cx('select_process_img_box')}>
                    <img
                      src="/statics/images/solution_select.png"
                      alt="solution_select"
                    />
                  </div>
                  <div className={cx('select_process_title_box')}>
                    <h4 className={cx('select_process_title')}>견적요청</h4>
                  </div>
                </div>
                <div className={cx('select_process_bar')}></div>
                <div className={cx('process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('process_title')}>견적검토</h4>
                  </div>
                </div>
                <div className={cx('process_bar')}></div>
                <div className={cx('process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('process_title')}>설치확인</h4>
                  </div>
                </div>
                <div className={cx('process_bar')}></div>
                <div className={cx('process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('process_title')}>유저정보</h4>
                  </div>
                </div>
                <div className={cx('process_bar')}></div>
                <div className={cx('process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('process_title')}>결제방식</h4>
                  </div>
                </div>
                <div className={cx('process_bar')}></div>
                <div className={cx('process_status_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('process_title')}>계약현황 확인</h4>
                  </div>
                </div>
                <div className={cx('process_bar')}></div>
                <div className={cx('process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('process_title')}>계약서 작성</h4>
                  </div>
                </div>
                <div className={cx('process_bar')}></div>
                <div className={cx('process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('process_title')}>입금 확인</h4>
                  </div>
                </div>
                <div className={cx('process_bar')}></div>
                <div className={cx('process_box')}>
                  <div className={cx('process_title_box')}>
                    <h4 className={cx('process_title')}>설치 스케줄</h4>
                  </div>
                </div>
              </div>
              <div className={cx('mobile_header_inner')}>
                <div className={cx('mobile_header_progressbar_box')}>
                  <div className={cx('mobile_header_progressbar')}></div>
                </div>
                <div className={cx('select_process_box')}>
                  <div className={cx('mobile_select_text_box')}>
                    <p className={cx('mobile_select_text')}>step.2</p>
                  </div>
                  <div className={cx('mobile_select_process_title_box')}>
                    <div className={cx('select_process_title_box')}>
                      <h4 className={cx('select_process_title')}>견적요청</h4>
                    </div>
                    <div className={cx('select_process_img_box')}>
                      <img
                        src="/statics/images/solution_select.png"
                        alt="solution_select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={cx('inner')}>
            <div className={cx('inner_box')}>
              <CancelSolutionButton />
              <div
                className={cx('checklist_button')}
                onClick={() => setButtonPopup(true)}
              >
                <div className={cx('checklist_icon_box')}>
                  <img
                    className={cx('checklist_icon')}
                    src="/statics/images/check_list.png"
                    alt="check_list"
                  />
                </div>
                <div className={cx('hide_text_box')}>
                  <p className={cx('hide_text')}>체크리스트 보기</p>
                </div>
              </div>
              <div className={cx('title_box')}>
                <h4 className={cx('title')}>설치 장소 입력</h4>
              </div>
              <div className={cx('input_inner')}>
                <div className={cx('address_inner')}>
                  <div className={cx('address_box')}>
                    <input
                      className={cx('input')}
                      type="text"
                      placeholder="주소 입력"
                      name="address"
                      defaultValue={address}
                      readOnly
                    />
                  </div>
                  <div className={cx('search_button_box')}>
                    <button
                      className={cx('search_button', 'active')}
                      onClick={handleOpenPost}
                    >
                      {address ? '수정' : '검색'}
                    </button>
                  </div>
                  {!address && (
                    <button
                      className={cx('btn_search')}
                      onClick={handleOpenPost}
                    ></button>
                  )}
                </div>
                <div className={cx('detail_inner')}>
                  <div className={cx('detail_box')}>
                    <input
                      className={cx('input')}
                      type="text"
                      name="tempDetailAddress"
                      value={detailAddress}
                      onChange={onChange}
                      placeholder="상세 주소 입력"
                    />
                  </div>
                </div>
                <div className={cx('uniqueness_inner')}>
                  <div className={cx('uniqueness_text_box')}>
                    <p className={cx('uniqueness_text')}>특이사항 입력</p>
                  </div>
                  <div className={cx('uniqueness_textarea_box')}>
                    <textarea
                      className={cx('uniqueness_textarea')}
                      name="tempExtraInfo"
                      value={extraInfo}
                      onChange={onChange}
                      placeholder="설치와 관련된 특이사항이 있으시면 자세히 알려주세요:)"
                    />
                  </div>
                </div>
              </div>
              <div className={cx('button_box')}>
                <div className={cx('prev_button_box')}>
                  <button className={cx('prev_button')} onClick={handlePrev}>
                    이전 단계 확인
                  </button>
                </div>
                <div className={cx('next_button_box')}>
                  <button className={cx('next_button')} onClick={onSubmit}>
                    다음 단계로 이동
                  </button>
                </div>
              </div>
              <Popup trigger={buttonPopup} setTrigger={setButtonPopup} />
            </div>
          </div>
        </div>
      </div>
      <SearchAddress
        isOpenPost={isOpenPost}
        handleOpenPost={handleOpenPost}
        handleClosePost={handleClosePost}
        handleClickAddress={handleClickAddress}
      />
    </>
  );
}
