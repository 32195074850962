import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionInstallmentExaminationConfirm from 'components/views/SolutionInstallmentExaminationConfirm/SolutionInstallmentExaminationConfirm';

export default function SolutionInstallmentExaminationConfirmWrapper() {
    return (
        <>
            <Header />
            <SolutionInstallmentExaminationConfirm />
        </>
    )
}
