import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import MypageEdit from 'components/views/MypageEdit/MypageEdit';

export default function MypageEditWrapper({
  name,
  phone,
  profileImageUrl,
  birthday,
  businessAddress,
  businessDetailAddress,
  businessBusyHour,
  businessBusyHourOpen,
  businessBusyHourClose,
  businessCategory,
  businessSubCategory,
  businessCloseTime,
  businessCustomerAge,
  businessDailyVisitor,
  businessMaxSeat,
  businessName,
  businessNumber,
  businessOpenTime,
  businessTimeMemo,
  email,
  gender,
  isSendCode,
  isBusinessVerified,
  code,
  isOpenPost,
  handleOpenPost,
  handleClosePost,
  isPhoneVerified,
  handleChange,
  handleChangeDate,
  isPhoneError,
  isBusinessError,
  isPhoneExpired,
  isCodeError,
  handleCode,
  handleVerifiedPhone,
  startTime,
  endTime,
  handleEditPhone,
  handleClickAddress,
  handleCheckBusinessNumber,
  handleClickGender,
  handleSubmit,
  categories,
  dayoff,
  handleWeeks,
  isTempBlock,
}) {
  return (
    <>
      <Header />
      <MypageEdit
        categories={categories}
        name={name}
        phone={phone}
        birthday={birthday}
        businessAddress={businessAddress}
        businessDetailAddress={businessDetailAddress}
        businessBusyHour={businessBusyHour}
        businessBusyHourOpen={businessBusyHourOpen}
        businessBusyHourClose={businessBusyHourClose}
        businessCategory={businessCategory}
        businessSubCategory={businessSubCategory}
        businessCloseTime={businessCloseTime}
        businessCustomerAge={businessCustomerAge}
        businessDailyVisitor={businessDailyVisitor}
        businessMaxSeat={businessMaxSeat}
        businessName={businessName}
        businessNumber={businessNumber}
        businessOpenTime={businessOpenTime}
        businessTimeMemo={businessTimeMemo}
        email={email}
        gender={gender}
        isSendCode={isSendCode}
        code={code}
        dayoff={dayoff}
        handleWeeks={handleWeeks}
        isBusinessVerified={isBusinessVerified}
        isOpenPost={isOpenPost}
        handleOpenPost={handleOpenPost}
        handleClosePost={handleClosePost}
        isPhoneVerified={isPhoneVerified}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        isPhoneError={isPhoneError}
        isBusinessError={isBusinessError}
        isPhoneExpired={isPhoneExpired}
        isCodeError={isCodeError}
        handleCode={handleCode}
        handleVerifiedPhone={handleVerifiedPhone}
        startTime={startTime}
        endTime={endTime}
        handleEditPhone={handleEditPhone}
        handleClickAddress={handleClickAddress}
        handleCheckBusinessNumber={handleCheckBusinessNumber}
        handleClickGender={handleClickGender}
        handleSubmit={handleSubmit}
        submitDisabled={
          isTempBlock
            ? (!name ||
              !phone ||
              !birthday ||
              !gender ||
              !isPhoneVerified)
            : (
              !name ||
              !phone ||
              !birthday ||
              !businessAddress ||
              !businessDetailAddress ||
              !businessBusyHour ||
              !businessCategory ||
              !businessSubCategory ||
              !businessCloseTime ||
              !businessCustomerAge ||
              !businessDailyVisitor ||
              !businessMaxSeat ||
              !businessName ||
              !businessNumber ||
              !businessOpenTime ||
              !gender ||
              !isBusinessVerified ||
              !isPhoneVerified
            )
        }
        isTempBlock={isTempBlock}
      />
      <Footer />
    </>
  );
}
