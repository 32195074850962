import React from 'react'
import classNames from 'classnames/bind';
import styles from './FindPassword.module.scss';

const cx = classNames.bind(styles);

export default function FindPassword({
    email,
    onChange,
    onClick,
}) {

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <div className={cx('login_text_box')}>
                            <p className={cx('login_text')}>로그인</p>
                        </div>
                        <div className={cx('title_box')}>
                            <h4 className={cx('title')}>비밀번호 찾기</h4>
                        </div>
                        <div className={cx('text_box')}>
                            <p className={cx('text')}>기존 가입된 이메일을 입력해주시면</p>
                            <p className={cx('text')}>비밀번호 재설정 링크 가 담긴 안내 메일을 보내드릴게요!</p>
                        </div>
                        <div className={cx('input_box')}>
                            <input className={cx('input')} type="text" name="email" value={email} onChange={onChange} placeholder="이메일 주소를 입력해주세요." />
                        </div>
                        <div className={cx('button_box')}>
                            <button className={cx('button')} onClick={onClick}>이메일 발송</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
