import React from 'react';
import classNames from 'classnames/bind';
import styles from './IntroServiceContent.module.scss';
import useScrollFadeIn from 'hooks/useScrollFadeIn';

const cx = classNames.bind(styles);

export default function IntroServiceContent() {
    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <div {...useScrollFadeIn('up', 1, 0)} className={cx('title_box')}>
                            <h4 className={cx('title')}>
                                차원이 다른 미디어<br className={cx('mobile_br')} /> 인테리어와 함께
                            </h4>
                        </div>
                        <div {...useScrollFadeIn('up', 1, 0.1)} className={cx('text_box')}>
                            <p className={cx('text')}>
                                기존 인테리어 방식과 남다른 특장점을 가진 미디어 인테리어를 시작해 보세요.
                            </p>
                        </div>
                        <div className={cx('media_interior_inner')}>
                            <div  {...useScrollFadeIn('up', 1, 0)} className={cx('media_interior_box')}>
                                <div className={cx('media_interior_title_box')}>
                                    <h3 className={cx('media_interior_title')}>
                                        가변성
                                    </h3>
                                    <img src="/statics/images/ic_service_first.png" alt="ic_service_first" />
                                </div>
                                <div className={cx('media_interior_text_box')}>
                                    <p className={cx('media_interior_text')}>
                                        클릭 한 번으로 1분만에 인테리어를 바꿀 수 있습니다.
                                    </p>
                                </div>
                            </div>
                            <div {...useScrollFadeIn('up', 1, 0.2)} className={cx('media_interior_box')}>
                                <div className={cx('media_interior_title_box')}>
                                    <h3 className={cx('media_interior_title')}>
                                        비용절감
                                    </h3>
                                    <img src="/statics/images/ic_service_second.png" alt="ic_service_second" />
                                </div>
                                <div className={cx('media_interior_text_box')}>
                                    <p className={cx('media_interior_text')}>
                                        기존 인테리어 방식의 1/10 비용으로 훨씬 저렴합니다.
                                    </p>
                                </div>
                            </div>
                            <div {...useScrollFadeIn('up', 1, 0.3)} className={cx('media_interior_box')}>
                                <div className={cx('media_interior_title_box')}>
                                    <h3 className={cx('media_interior_title')}>
                                        빠른 시공
                                    </h3>
                                    <img src="/statics/images/ic_service_third.png" alt="ic_service_third" />
                                </div>
                                <div className={cx('media_interior_text_box')}>
                                    <p className={cx('media_interior_text')}>
                                        1 DAY 시공이 가능하여 공백 없이 매장을 운영할 수 있습니다.
                                    </p>
                                </div>
                            </div>
                            <div {...useScrollFadeIn('up', 1, 0.4)} className={cx('media_interior_box')}>
                                <div className={cx('media_interior_title_box')}>
                                    <h3 className={cx('media_interior_title')}>
                                        강렬한 시각 경험 제공
                                    </h3>
                                    <img src="/statics/images/ic_service_four.png" alt="ic_service_four" />
                                </div>
                                <div className={cx('media_interior_text_box')}>
                                    <p className={cx('media_interior_text')}>
                                        실감형 미디어 인테리어를 통해 방문객에게 색다른 경험과 포토존을 제공합니다.
                                    </p>
                                </div>
                            </div>
                            <div {...useScrollFadeIn('up', 1, 0.5)} className={cx('media_interior_box')}>
                                <div className={cx('media_interior_title_box')}>
                                    <h3 className={cx('media_interior_title')}>
                                        다양한 컨셉
                                    </h3>
                                    <img src="/statics/images/ic_service_five.png" alt="ic_service_five" />
                                </div>
                                <div className={cx('media_interior_text_box')}>
                                    <p className={cx('media_interior_text')}>
                                        다양한 컨셉의 콘텐츠를 통해 매일 새로운 분위기로 인테리어를 바꿀 수 있습니다.
                                    </p>
                                </div>
                            </div>
                            <div {...useScrollFadeIn('up', 1, 0.6)} className={cx('media_interior_box')}>
                                <div className={cx('media_interior_title_box')}>
                                    <h3 className={cx('media_interior_title')}>
                                        수익 창출
                                    </h3>
                                    <img src="/statics/images/ic_service_six.png" alt="ic_service_six" />
                                </div>
                                <div className={cx('media_interior_text_box')}>
                                    <p className={cx('media_interior_text')}>
                                        옥외 광고처럼 미디어 운영을 통해 별도 수익을 창출할 수 있습니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
