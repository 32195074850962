import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';
import styles from './AdvertisementPeriod.module.scss';
import CustomCalendar from 'components/common/Calendar/Calendar';

const cx = classNames.bind(styles);

export default function AdvertisementPeriod({
  startDate,
  endDate,
  handleStartDate,
  handleEndDate,
  handleSearchStores,
  handleStartDateToggle,
  handleEndDateToggle,
  openStart,
  openEnd,
  minAdPurchaseDays,
}) {
  return (
    <div className={cx('category_inner')}>
      <div className={cx('category_title_box')}>
        <p className={cx('category_title')}>
          광고 기간
          <b className={cx('category_text')}>
            시작일은 금일 기준 2주 이후 설정가능합니다. 종료일은 시작일 기준
            최소 {minAdPurchaseDays}일 입니다.
          </b>
        </p>
      </div>
      <div className={cx('advertisement_container')}>
        <div className={cx('advertisement_inner')}>
          {/* <div className={cx('advertisement_box')}>
            <p className={cx('advertisement_text')}>광고 기간</p>
            <select
              style={{
                backgroundImage: 'url(/statics/images/ic_expand_more.png)',
              }}
              value={period}
              className={cx('advertisement_select')}
              onChange={handlePeriod}
            >
              <option value="30">30일</option>
              <option value="60">60일</option>
              <option value="90">90일</option>
            </select>
          </div> */}
          <div className={cx('advertisement_box')}>
            <p className={cx('advertisement_text')}>종료일</p>
            <input
              className={cx('advertisement_select')}
              name="advertisement-start"
              value={endDate}
              onClick={handleEndDateToggle}
              readOnly
              style={{
                backgroundImage: 'url(/statics/images/ic_expand_more.png)',
              }}
            />
            {openEnd && (
              <CustomCalendar
                date={new Date(endDate)}
                handleDate={handleEndDate}
                handleToggle={handleEndDateToggle}
              />
            )}
          </div>
          <p className={cx('advertisement_b')}></p>
          <div className={cx('advertisement_box')}>
            <p className={cx('advertisement_text')}>시작일</p>
            <input
              className={cx('advertisement_select')}
              name="advertisement-start"
              value={startDate}
              onClick={handleStartDateToggle}
              readOnly
              style={{
                backgroundImage: 'url(/statics/images/ic_expand_more.png)',
              }}
            />
            {openStart && (
              <CustomCalendar
                date={new Date(startDate)}
                minDay={14}
                handleDate={handleStartDate}
                handleToggle={handleStartDateToggle}
              />
            )}
          </div>
        </div>
        <div className={cx('button_box')}>
          <button className={cx('button')} onClick={handleSearchStores}>
            검색하기
          </button>
        </div>
      </div>
    </div>
  );
}
