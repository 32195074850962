import React from 'react';
import { Footer, Header } from 'components/views';
import PlayListArtistDetail from 'components/views/PlayListArtistDetail';
import MediaContentPopup from 'components/views/MediaContentPopup';

export default function PlayListArtistDetailWrapper({
  data,
  contents,
  pageData,
  handlePage,
  selectedData,
  isShow,
  handlePopupClose,
  handleClickDetail,
  handleClickLike,
  handleClickLikeOther,
  handleWishList,
  wishPlayList,
  myPlayList,
  isUnlimitedPlaylist,
  handleUpdatePlayList,
}) {
  return (
    <>
      <Header />
      <PlayListArtistDetail
        data={data}
        contents={contents}
        pageData={pageData}
        handlePage={handlePage}
        handleClickDetail={handleClickDetail}
        handleClickLike={handleClickLike}
        handleClickLikeOther={handleClickLikeOther}
        handleWishList={handleWishList}
        wishPlayList={wishPlayList}
        myPlayList={myPlayList}
        isUnlimitedPlaylist={isUnlimitedPlaylist}
        handleUpdatePlayList={handleUpdatePlayList}
      />
      <MediaContentPopup
        key={selectedData?.id}
        data={selectedData}
        isShow={isShow}
        onClose={handlePopupClose}
      />
      <Footer />
    </>
  );
}
