import React, {
    useEffect,
    useState,
    useCallback,
} from 'react';
import * as api from 'apis';
import IntroExampleWrapper from 'components/IntroExampleWrapper/IntroExampleWrapper';

export default function IntroExampleContainer() {
    const [data, setData] = useState({
        total: 0,
        currentLocation: '',
        currentPage: 1,
        lastPage: 1,
        perPage: 5,
        limit: 12,
        search: '',
        usageList: [],
        locationList: [],
    });
    const [selectedData, setSelectedData] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const res = await api.getServiceUsage({
                    limit: 12,
                    page: 0,
                });

                const {
                    data,
                    total,
                    wholeLocations,
                } = res?.data;
                setData(prev => ({
                    ...prev,
                    total,
                    locationList: wholeLocations,
                    usageList: data,
                    lastPage: Math.ceil(total / 12),
                }));
            } catch (err) {
                console.log(err);
            }
        })();
    }, [setData]);

    const handleSearch = useCallback(async (currentPage) => {
        try {
            const res = await api.getServiceUsage({
                page: currentPage - 1,
                limit: 12,
                search: data.search,
                location: data.currentLocation,
            });

            const {
                total,
                wholeLocations,
            } = res?.data;

            setData(prev => ({
                ...prev,
                total,
                currentPage,
                lastPage: Math.ceil(total / 12),
                locationList: wholeLocations,
                usageList: res?.data?.data || [],
            }));
        } catch (err) {
            console.log(err);
        }
    }, [data, setData]);

    const handleKeyPress = (e) => {
        if (e.key !== 'Enter') return;
        handleSearch(1);
    }

    const handleChange = (e) => {
        setData(prev => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    }

    const handlePage = (currentPage) => {
        handleSearch(currentPage);
    }

    const handleClick = (el, index) => {
        setSelectedData({ id: `${el?.name}-${index}`, ...el });
        setIsOpen(true);
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    return (
        <IntroExampleWrapper
            usageList={data.usageList || []}
            locationList={data.locationList}
            currentLocation={data.currentLocation}
            search={data.search}
            currentPage={data.currentPage}
            lastPage={data.lastPage}
            perPage={data.perPage}
            onChange={handleChange}
            onSearch={handleSearch}
            onKeyPress={handleKeyPress}
            handlePage={handlePage}
            onClick={handleClick}
            selectedData={selectedData}
            isOpen={isOpen}
            onClose={handleClose}
        />
    )
}
