import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import IntroServiceMain from 'components/views/IntroServiceMain/IntroServiceMain';
import IntroServiceContent from 'components/views/IntroServiceContent/IntroServiceContent';
import IntroServiceSolution from 'components/views/IntroServiceSolution/IntroServiceSolution';
import IntroServiceHardware from 'components/views/IntroServiceHardware/IntroServiceHardware';
import IntroServiceTrend from 'components/views/IntroServiceTrend/IntroServiceTrend';
import IntroServiceProcess from 'components/views/IntroServiceProcess/IntroServiceProcess';

export default function IntroServiceWrapper() {
    return (
        <>
            <Header />
            <IntroServiceMain />
            <IntroServiceContent />
            <IntroServiceSolution />
            <IntroServiceHardware />
            <IntroServiceTrend />
            <IntroServiceProcess />
            <Footer />
        </>
    )
}
