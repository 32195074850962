import React, { useEffect, useRef, useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './FiveCheckList.module.scss';

const cx = classNames.bind(styles);

export default function FiveCheckList() {
    return (
        <div className={cx('inner')}>
            <div className={cx('img_inner')}>
                <div className={cx('img_box')}>
                    <img className={cx('img')} src="/statics/images/ok_texture.png" alt="ok_texture.png" />
                </div>
                <div className={cx('img_box')}>
                    <img className={cx('img')} src="/statics/images/fail_texture.png" alt="fail_texture.png" />
                </div>
            </div>
            <div className={cx('text_box')}>
                <p className={cx('text')}>
                    벽면의 질감에 따라 영상의 왜곡이 발생할 수 있습니다.
                </p>
            </div>
        </div>
    )
}
