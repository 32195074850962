import React, { useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './MypagePayment.module.scss';
import mockdata from '../Mockup/Mockup';

const cx = classNames.bind(styles);

export default function Installment() {
  const [mockData, setMockData] = useState([]);

  useEffect(() => {
    const fetchMockData = () => {
      const res = mockdata.installmentMockdata;
      setMockData(res);
    };
    fetchMockData();
  }, []);

  const mockMap = (mockData) => {
    return mockData.map((el, i) => {
      return (
        <div className={cx('table_data_inner')} key={i}>
          <div className={cx('installment_data_box')}>
            <p className={cx('installment_data')}>{el.date}</p>
          </div>
          <div className={cx('installment_data_box')}>
            <p className={cx('installment_data')}>{el.state}</p>
          </div>
          <div className={cx('installment_history_data_box')}>
            <p className={cx('installment_history_data')}>{el.history}</p>
          </div>
          <div className={cx('installment_data_box')}>
            <div className={cx('installment_detaile_box')}>
              <button className={cx('installment_detaile_button')}>
                상세보기
              </button>
            </div>
            <p className={cx('installment_data')}>{el.sum}</p>
          </div>
        </div>
      );
    });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [mockDataPerPage, setMockDataPerPage] = useState(10);

  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  };

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(mockData.length / mockDataPerPage); i++) {
    pageNumbers.push(i);
  }

  const indexOfLastPost = currentPage * mockDataPerPage;
  const indexOfFirstPost = indexOfLastPost - mockDataPerPage;
  const currnetMockData = mockData.slice(indexOfFirstPost, indexOfLastPost);

  const renderPageNumbers = pageNumbers.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <div className={cx('pagination_number_box')}>
          <p
            key={number}
            id={number}
            onClick={handleClick}
            className={cx(
              currentPage === number
                ? 'active_pagination_number'
                : 'pagination_number',
            )}
          >
            {number}
          </p>
        </div>
      );
    } else {
      return null;
    }
  });

  const handleNexbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  return (
    <div>
      <div className={cx('table_header')}>
        <div className={cx('installment_box')}>
          <p className={cx('installment_header_title')}>날짜</p>
        </div>
        <div className={cx('installment_box')}>
          <p className={cx('installment_header_title')}>상태</p>
        </div>
        <div className={cx('installment_history_box')}>
          <p className={cx('installment_header_title')}>내역</p>
        </div>
        <div className={cx('installment_box')}>
          <p className={cx('installment_header_title')}>합계</p>
        </div>
      </div>
      <div className={cx('table_body')}>{mockMap(currnetMockData)}</div>
      <div className={cx('pagination_box')}>
        <div>
          <button
            className={cx('pagination_button')}
            onClick={handlePrevbtn}
            disabled={currentPage === pageNumbers[0] ? true : false}
            style={{
              backgroundImage:
                'url(/statics/images/pagination_prev_button.png)',
            }}
          />
        </div>
        <div className={cx('pagination_number_inner')}>{renderPageNumbers}</div>
        <div>
          <button
            className={cx('pagination_button')}
            onClick={handleNexbtn}
            disabled={
              currentPage === pageNumbers[pageNumbers.length - 1] ? true : false
            }
            style={{
              backgroundImage:
                'url(/statics/images/pagination_next_button.png)',
            }}
          />
        </div>
      </div>
    </div>
  );
}
