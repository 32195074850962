import React from 'react';
import { Footer, Header } from 'components/views';
import MypagePaymentHistory from 'components/views/MypagePaymentHistory';

export default function MypagePaymentHistoryWrapper({
  handlePage,
  pageData,
  data,
  tab,
  handleTab,
  handleArtistPage,
  artistData,
  artistPageData,
}) {
  return (
    <>
      <Header />
      <MypagePaymentHistory
        handlePage={handlePage}
        pageData={pageData}
        handleArtistPage={handleArtistPage}
        artistData={artistData}
        artistPageData={artistPageData}
        data={data}
        tab={tab}
        handleTab={handleTab}
      />
      <Footer />
    </>
  );
}
