import React, { useState, useEffect, useCallback } from 'react';
import AdvertisementWrapper from 'components/AdvertisementWrapper';
import useAdvertisement from 'hooks/useAdvertisement';
import moment from 'moment';
import * as api from 'apis';

export default function AdvertisementContainer() {
  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [originAdvertiseCategories, setOriginAdvertiseCategories] = useState(
    {},
  );
  const [businiessCategories, setBusiniessCategories] = useState({});
  // const [selectedCategory, setSelectedCategory] = useState({});
  // const [selectedBusinessCategories, setSelectedBusinessCategories] = useState([]);
  const [regionCountArray, setRegionCountArray] = useState([]);
  const [selectedRegionArray, setSelectedRegionArray] = useState([]);
  // const [startDate, setStartDate] = useState(moment(new Date()).add(14, 'days').format('YYYY-MM-DD'));
  // const [period, setPeriod] = useState('30');
  // const [selectedStores, setSelectedStores] = useState([]);
  const [storeCheckList, setStoreCheckList] = useState([]);
  const [availableStores, setAvailableStores] = useState({});
  const [currentStorePage, setCurrentStorePage] = useState({
    total: 0,
    currentPage: 1,
    lastPage: 0,
    perPage: 5,
    limit: 12,
  });

  const {
    selectedCategory,
    selectedBusinessCategories,
    startDate,
    minAdPurchaseDays,
    endDate,
    selectedStores,
    estimateInfo,
    handleChangeAdvertisementData,
    handleResetAdvertisementData,
  } = useAdvertisement();

  // const [estimateInfo, setEstimateInfo] = useState({
  //   dailyViewCount: null,
  //   dailyVisitorCount: null,
  //   price: null,
  //   storeUserCount: null,
  //   totalViewCount: null,
  //   totalVisitorCount: null,
  // })

  // const [pageData, setPageData] = useState();
  // const [storeCa]

  // originAdvertiseCategories
  // businiessCategories
  // selectedCategory
  // selectedBusinessCategories

  // const fetchStoreCount = useCallback(async () => {
  //   try {
  //     console.log('businiessCategories')
  //     const selected = selectedBusinessCategories.map((item) => item.category);
  //     await api.getStoresCountPerLocation(selected);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, [selectedBusinessCategories]);

  useEffect(() => {
    if (selectedBusinessCategories?.length === 0) {
      setRegionCountArray([]);
    } else {
      (async () => {
        try {
          const selected = selectedBusinessCategories.map(
            (item) => item.category,
          );
          if (selected?.length === 0) return;
          const res = await api.getStoresCountPerLocation(selected);
          setRegionCountArray(res?.data);
        } catch (err) {
          console.error(err);
        }
      })();
    }
  }, [selectedBusinessCategories]);

  const handleStartDate = useCallback(
    (value) => {
      const startDate = moment(value).format('YYYY-MM-DD');
      const minEndDate = moment(value)
        .add(minAdPurchaseDays - 1, 'days')
        .format('YYYY-MM-DD');
      const isAfter = moment(endDate).isAfter(minEndDate);
      handleChangeAdvertisementData({
        startDate,
        endDate: isAfter ? endDate : minEndDate,
      });
      setOpenStart(false);
    },
    [minAdPurchaseDays, endDate],
  );

  const handleEndDate = useCallback((value) => {
    handleChangeAdvertisementData({
      endDate: moment(value).format('YYYY-MM-DD'),
    });
    setOpenEnd(false);
  }, []);

  // const handlePeriod = useCallback(
  //   (e) => {
  //     const endDate = moment(startDate)
  //       .add(Number(e.target.value) - 1, 'days')
  //       .format('YYYY-MM-DD');
  //     handleChangeAdvertisementData({ endDate, period: e.target.value });
  //   },
  //   [startDate],
  // );

  const handleFetchStores = useCallback(
    async (currentPage) => {
      if (
        Object.keys(selectedCategory).length === 0 &&
        selectedCategory.constructor === Object
      )
        return alert('광고 카테고리를 먼저 선택해주세요 : )');

      const selectedBusiness = selectedBusinessCategories.map(
        (item) => item.category,
      );
      if (!selectedBusiness || selectedBusiness?.length === 0)
        return alert('희망하시는 스토어 업종을 먼저 선택해주세요 : )');
      if (!selectedRegionArray || selectedRegionArray?.length == 0)
        return alert('희망하시는 지역을 먼저 선택해주세요 : )');
      if (!startDate) return alert('희망하는 광고 시작일을 선택해주세요 : )');
      if (!endDate) return alert('희망하는 광고 종료일을 선택해주세요 : )');
      return await api.getAvailableAdvertiseStores(
        selectedRegionArray,
        selectedBusiness,
        startDate,
        endDate,
        currentPage - 1,
      );
    },
    [
      selectedCategory,
      selectedBusinessCategories,
      selectedRegionArray,
      startDate,
      endDate,
    ],
  );

  const handleSearchStores = useCallback(async () => {
    try {
      const res = await handleFetchStores(1);

      setCurrentStorePage({
        total: res?.data?.total || 0,
        currentPage: 1,
        lastPage: Math.ceil(res?.data?.total / 12) || 1,
        perPage: 5,
        limit: 12,
      });
      setAvailableStores({ 1: res?.data?.data });
    } catch (err) {
      console.log(err);
    }
  }, [handleFetchStores]);

  const handlePage = useCallback(
    async (currentPage) => {
      try {
        // if (availableStores[currentPage]?.length > 0) return;
        const res = await handleFetchStores(currentPage);

        setCurrentStorePage((prev) => ({
          ...prev,
          total: res?.data?.total || 0,
          currentPage,
          lastPage: Math.ceil(res?.data?.total / 12) || 1,
        }));
        setAvailableStores((prev) => ({
          ...prev,
          [currentPage]: res?.data?.data,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    [availableStores, handleFetchStores],
  );

  const handleSelectCategory = useCallback(
    (category) => {
      const selected = originAdvertiseCategories.filter(
        (el) => el.category === category,
      );
      if (selected?.length === 0) return;
      handleChangeAdvertisementData({
        selectedCategory: selected[0],
        selectedBusinessCategories: [],
      });
    },
    [originAdvertiseCategories],
  );

  const handleSelectBusinessCategory = useCallback(
    (category) => {
      if (
        Object.keys(selectedCategory).length === 0 &&
        selectedCategory.constructor === Object
      )
        return alert('광고 카테고리를 먼저 선택해주세요 : )');
      const selected = businiessCategories.filter(
        (el) => el.category === category,
      );
      if (selected?.length === 0) return;

      let tempData = [];
      if (
        selectedBusinessCategories?.filter(
          (item) => item?.category === category,
        )?.length > 0
      ) {
        tempData = [...selectedBusinessCategories].filter(
          (el) => el.category !== category,
        );
      } else {
        tempData = [...selectedBusinessCategories, selected[0]];
      }
      handleChangeAdvertisementData({
        selectedBusinessCategories: tempData,
      });
    },
    [selectedCategory, selectedBusinessCategories, businiessCategories],
  );

  const handleSelectRegion = useCallback(
    (region) => {
      const selected = regionCountArray.filter((r) => r.name === region);
      if (selected?.length === 0)
        return alert('희망스토어 업종을 먼저 선택해주세요 : )');
      if (selected[0]?.count <= 0)
        return alert(
          '현재 선택하신 카테고리에 맞는 지역은 광고 가능한 스토어가 없습니다.\n빠르게 준비하도록 하겠습니다 : )',
        );
      setSelectedRegionArray((prev) => {
        if (prev.filter((item) => item === region)?.length > 0) {
          return [...prev].filter((el) => el !== region);
        } else {
          return [...prev, region];
        }
      });
    },
    [regionCountArray],
  );

  useEffect(() => {
    if (isInit) return;
    (async () => {
      try {
        const res = await Promise.all([
          api.getAdvertiseCategories(),
          api.getBusinessCategories(),
          api.getAdvertiseInfo(),
        ]);
        setOriginAdvertiseCategories(res[0]?.data?.value?.data);
        setBusiniessCategories(res[1]?.data?.value?.data);
        handleResetAdvertisementData();
        if (res[2]?.data?.minAdPurchaseDays) {
          handleChangeAdvertisementData({
            minAdPurchaseDays: res[2]?.data?.minAdPurchaseDays,
            endDate: moment(new Date())
              .add(14, 'days')
              .add(Number(res[2]?.data?.minAdPurchaseDays) - 1, 'days')
              .format('YYYY-MM-DD'),
          });
        }
        setIsInit(true);
        window.scrollTo(0, 0);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [isInit, handleResetAdvertisementData, handleChangeAdvertisementData]);

  const handlePriceEstimate = useCallback(
    async (startDate, endDate, selectedStores) => {
      // 하루 빼기
      try {
        const storeUser_ids = selectedStores.map((store) => store.id);
        const res = await api.getAdvertisesPriceEstimate(
          startDate,
          endDate,
          storeUser_ids,
        );
        handleChangeAdvertisementData({
          estimateInfo: res?.data,
        });
      } catch (error) {
        console.error(error);
      }
    },
    [],
  );

  const handleSelectStore = useCallback(
    async (item) => {
      let tempSelectedStores = [...selectedStores];

      if (tempSelectedStores.some((store) => store.id === item.id)) {
        tempSelectedStores = tempSelectedStores.filter(
          (store) => store.id !== item.id,
        );
      } else {
        tempSelectedStores.push(item);
      }

      handleChangeAdvertisementData({
        selectedStores: tempSelectedStores,
      });
    },
    [selectedStores],
  );

  useEffect(() => {
    if (!startDate || !endDate || selectedStores?.length === 0) return;
    (async () => {
      await handlePriceEstimate(startDate, endDate, selectedStores);
    })();
  }, [startDate, endDate, selectedStores, handlePriceEstimate]);

  const handleStartDateToggle = useCallback(() => {
    setOpenStart((prev) => !prev);
  }, []);

  const handleEndDateToggle = useCallback(() => {
    setOpenEnd((prev) => !prev);
  }, []);

  const handleSingleCheck = useCallback(
    (id) => {
      if (storeCheckList?.indexOf(id) > -1) {
        setStoreCheckList(storeCheckList.filter((el) => el !== id));
      } else {
        setStoreCheckList((prev) => [...prev, id]);
      }
    },
    [storeCheckList],
  );

  // 체크박스 전체 선택
  const handleAllCheck = useCallback(
    (checked) => {
      if (checked) {
        setStoreCheckList([...selectedStores.map((el) => el.id)]);
      } else {
        setStoreCheckList([]);
      }
    },
    [selectedStores],
  );

  const checkDelete = useCallback(() => {
    handleChangeAdvertisementData({
      selectedStores: selectedStores.filter(
        (el) => storeCheckList.indexOf(el.id) === -1,
      ),
    });
    setStoreCheckList([]);
  }, [storeCheckList, selectedStores]);

  return (
    <AdvertisementWrapper
      originAdvertiseCategories={originAdvertiseCategories}
      businiessCategories={businiessCategories}
      selectedCategory={selectedCategory}
      handleSelectCategory={handleSelectCategory}
      selectedBusinessCategories={selectedBusinessCategories}
      handleSelectBusinessCategory={handleSelectBusinessCategory}
      regionCountArray={regionCountArray}
      handleSelectRegion={handleSelectRegion}
      selectedRegionArray={selectedRegionArray}
      startDate={startDate}
      endDate={endDate}
      handleStartDate={handleStartDate}
      handleEndDate={handleEndDate}
      handleSearchStores={handleSearchStores}
      availableStores={availableStores}
      currentStorePage={currentStorePage}
      selectedStores={selectedStores}
      handlePage={handlePage}
      handleSelectStore={handleSelectStore}
      estimateInfo={estimateInfo}
      handleStartDateToggle={handleStartDateToggle}
      handleEndDateToggle={handleEndDateToggle}
      openStart={openStart}
      openEnd={openEnd}
      minAdPurchaseDays={minAdPurchaseDays}
      handleSingleCheck={handleSingleCheck}
      handleAllCheck={handleAllCheck}
      storeCheckList={storeCheckList}
      checkDelete={checkDelete}
    />
  );
}
