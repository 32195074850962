
export const CHANGE_DATA = 'contract/CHANGE_DATA';
export const RESET_DATA = 'contract/RESET_DATA';

export const changeContractData = (payload) => ({ type: CHANGE_DATA, payload });
export const changeResetData = (payload) => ({ type: RESET_DATA, payload });

const initialState = {
  contractId: null,
  status: "",
  paymentType: "",
  filename: "",
  fileUrl: "",
  contractFile: "",
  visitDate: "",
  visitManagerName: "",
  visitManagerPhone: "",
  visitManagerProfileImageUrl: "",
  pdfImageUrls: [],
  shouldSignImageIndex: [],
};

function contract(state = initialState, action) {
  switch (action.type) {
    case CHANGE_DATA:
      return {
        ...state,
        ...action.payload,
      }
    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
};

export default contract;