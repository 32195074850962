import React, {
  useCallback,
  useState,
} from 'react';
import * as api from 'apis';
import {
  useSelector,
  useDispatch,
} from "react-redux";
import { changeArtistData } from 'store/modules/artist';

export default function useArtist() {
  const artist = useSelector(state => state.artist);
  const {
    wishArtistPlayList
  } = artist;
  const dispatch = useDispatch();

  const handleChangeArtist = useCallback((payload) => dispatch(changeArtistData(payload)), [dispatch]);

  const handleMyArtistPlayList = useCallback(async () => {
    const res = await api.getMyPlayList(true);
    return res?.data;
  }, []);

  const handleUpdateArtist = useCallback(async () => {
    const res = await handleMyArtistPlayList();
    const playIds = [...res?.map((p) => p.id)];
    const tempWishIds = [...wishArtistPlayList?.map((el) => el.id)];
    const wishPlay = tempWishIds.filter((id) => playIds.indexOf(id) === -1);
    const wish = wishArtistPlayList.filter(el => wishPlay.indexOf(el.id) > -1);
    handleChangeArtist({ wishArtistPlayList: wish });
    return wish;
  }, [wishArtistPlayList]);

  return {
    wishArtistPlayList,
    handleChangeArtist,
    handleUpdateArtist,
    handleMyArtistPlayList,
  }
};