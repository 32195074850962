import React from 'react';
import { Header } from 'components/views';
import Partner from 'components/views/Partner';

export default function PartnerWrapper() {
  return (
    <>
      <Header />
      <Partner />
    </>
  )
}
