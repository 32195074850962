import React, {
  useEffect,
  useRef,
} from 'react';
import classNames from 'classnames/bind';
import styles from './ExamplePopup.module.scss';

const cx = classNames.bind(styles);

export default function ExamplePopup({ data, isShow, onClose }) {
  const videoRef = useRef(null);
  useEffect(() => {
    if (!videoRef?.current) return;
    return () => {
      videoRef.current.muted = true;
    }
  }, [isShow]);

  return (
    <div key={`popup-${isShow}`} className={cx('container', { active: isShow })}>
      {/* 바탕화면 클릭 시 닫힘 클릭 이벤트 필요없음 지우면 됨!!   */}
      <div onClick={onClose} className={cx('sub_container')}></div>
      <div className={cx('inner')}>
        <div className={cx('inner_box')}>
          <div className={cx('header')}>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>{data?.name}</h4>
            </div>
            <div onClick={onClose} className={cx('close_button_box')}>
              <img
                className={cx('close_button')}
                src="/statics/images/ic_pop_close.png"
                alt="ic_pop_close"
              />
            </div>
          </div>
          <div className={cx('content_inner')}>
            <div className={cx('content_media_box')}>
              <video
                key={data?.id}
                muted
                autoPlay
                loop
                controls
                className={cx('content_media')}
                ref={videoRef}
              >
                <source src={data?.url} />
              </video>
              <div className={cx('media_title_inner')}>
                <div className={cx('media_sub_title_box')}>
                  <p className={cx('media_sub_title')}>{data?.type}</p>
                </div>
                <div className={cx('media_title_box')}>
                  <h4 className={cx('media_title')}>{data?.location}</h4>
                </div>
              </div>
              <div className={cx('media_start_box')}>
                {/* 재생 버튼   */}
                {/* <button className={cx('media_play_box')}>
                                <img className={cx('media_start')} src="/statics/images/media_start.png" alt="media_start" />
                            </button> */}
                {/* 멈춤 버튼  
                            <button className={cx('media_play_box')}>
                                <img className={cx('media_stop')} src="/statics/images/media_stop.png" alt="media_stop" />
                            </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
