import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './PlayList.module.scss';
import { NavLink } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function PlayList({
  data,
  playList,
  handleClickDetail,
  handleClickLike,
  handlePlayList,
  grade,
  wishPlayList,
  handleRemove,
  handleUpdatePlayList,
  playlistResetCount,
  handleResetPlayList,
  isFetching,
}) {
  const scrollRef = useRef(null);

  const slide = (index, shift) => {
    scrollRef.current[index].scrollLeft += shift;
  };

  const playIds = [...playList.map((p) => p.id)];
  const maxCount = grade === '베이직' ? 10 : grade === '스탠다드' ? 30 : null;
  const maxReset = grade === '베이직' ? 1 : grade === '스탠다드' ? 2 : null;

  // 모바일 아래 탭 메뉴
  const [listMenu, setListMenu] = useState(false);

  return (
    <div className={cx('inner_box')}>
      {/* 데이터 없을 떄 */}
      {(isFetching && !data) ||
        (isFetching && data.length === 0 && (
          <div className={cx('no_data_inner')}>
            <div className={cx('no_data_box')}>
              <div className={cx('no_data_title_box')}>
                <p className={cx('no_data_title')}>Play List 준비 중 입니다.</p>
              </div>
              <div className={cx('no_data_text_box')}>
                <p className={cx('no_data_text')}>
                  최대한 빠르게 준비해 드리겠습니다.
                </p>
              </div>
            </div>
          </div>
        ))}
      {
        !isFetching && (
          <div className={cx('no_data_inner')}>
            <div className={cx('no_data_box')}>
              <div className={cx('no_data_title_box')}>
                <p className={cx('no_data_title')}>빔몬스터 콘텐츠 로딩 중 입니다.</p>
              </div>
              <div className={cx('no_data_text_box')}>
                <p className={cx('no_data_text')}>
                  잠시만 기다려주세요.
                </p>
              </div>
            </div>
          </div>
        )
      }
      {data &&
        data.length > 0 &&
        data.map((item, i) => {
          return (
            <div key={i} className={cx('media_list_inner')}>
              <div className={cx('media_left_button_box')}>
                <img
                  onClick={() => slide(i, -400)}
                  src="/statics/images/ic-viausl-prev-on.png"
                  alt="media_arrow"
                />
              </div>
              <div className={cx('media_right_button_box')}>
                <img
                  onClick={() => slide(i, +400)}
                  src="/statics/images/ic-viausl-next-on.png"
                  alt="media_arrow"
                />
              </div>
              <div className={cx('media_list_title_box')}>
                <h4 className={cx('media_list_title')}>{item.name}</h4>
              </div>
              <div
                className={cx('media_list_box')}
                ref={(ref) => {
                  scrollRef.current = { ...scrollRef.current, [i]: ref };
                }}
              >
                {item &&
                  item?.contents?.map((el, index) => {
                    return (
                      <div
                        key={`${i}-${index}`}
                        className={cx('media_list_item_box')}
                        role="presentation"
                        onClick={(e) => handleClickDetail(e, el)}
                      >
                        <div
                          className={cx('media_list_item', {
                            media_list_item_active:
                              [...wishPlayList.map((p) => p.id)].indexOf(
                                el.id,
                              ) > -1,
                          })}
                        >
                          <img
                            className={cx('media_list_item_img')}
                            src={
                              el.thumbnailUrl
                                ? el.thumbnailUrl
                                : '/statics/images/glacier.jpg'
                            }
                            alt="glacier"
                          />
                          <div
                            role="presentation"
                            className={cx('media_list_add_button_box')}
                            onClick={(e) => handlePlayList(e, el)}
                          >
                            <img
                              className={cx('media_list_item_add_button')}
                              src="/statics/images/ic_playlist_add.png"
                              alt="추가 버튼"
                            />
                          </div>
                          <div className={cx('media_quality_box')}>
                            <p className={cx('media_quality')}>
                              {el?.resolution}
                            </p>
                          </div>
                        </div>
                        <div className={cx('media_list_item_title_box')}>
                          <p className={cx('media_list_item_title')}>
                            {el.title}
                          </p>
                          <div className={cx('like_button')}>
                            {el.liked ? (
                              <img
                                onClick={(e) =>
                                  handleClickLike(e, i, index, el.id)
                                }
                                className={cx('media_list_item_like_on')}
                                src="/statics/images/ic_playlist_like_on.png"
                                alt="playlist_like_on"
                              />
                            ) : (
                                <img
                                  onClick={(e) =>
                                    handleClickLike(e, i, index, el.id)
                                  }
                                  className={cx('media_list_item_like_off')}
                                  src="/statics/images/ic_playlist_like_off.png"
                                  alt="playlist_like_off"
                                />
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {item.contents.length === 0 && (
                  <div key={`${i}-no`} className={cx('media_list_item_box')}>
                    {/* 데이터 하나만 없을때 */}
                    <div className={cx('no_single_data_title_box')}>
                      <p className={cx('no_single_data_title')}>
                        Play List 준비 중 입니다.
                      </p>
                    </div>
                    <div className={cx('no_single_data_text_box')}>
                      <p className={cx('no_single_data_text')}>
                        최대한 빠르게 준비해 드리겠습니다.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>

    // <div className={cx('container')}>
    //   <div className={cx('sub_container')}>
    //     <div className={cx('side_container')}>
    //       <div className={cx('side_inner')}>
    //         <NavLink
    //           to="/play-list"
    //           className={({ isActive }) =>
    //             cx('menu_list_box', { active: isActive })
    //           }
    //         >
    //           <div className={cx('menu_list_item_box')}>
    //             <img
    //               className={cx('img_on')}
    //               src="/statics/images/ic_play_list_on.png"
    //               alt="play_list_on_icon"
    //             />
    //             <img
    //               className={cx('img_off')}
    //               src="/statics/images/ic_play_list_off.png"
    //               alt="play_list_off_icon"
    //             />
    //             <p className={cx('list_text')}>빔몬스터 콘텐츠</p>
    //           </div>
    //         </NavLink>
    //         <NavLink to="/play-list/search" className={cx('menu_list_box')}>
    //           <div className={cx('menu_list_item_box')}>
    //             <img
    //               className={cx('img_on')}
    //               src="/statics/images/ic-play-search-on.png"
    //               alt="search_om_icon"
    //             />
    //             <img
    //               className={cx('img_off')}
    //               src="/statics/images/ic-play-search-off.png"
    //               alt="search_off_icon"
    //             />
    //             <p className={cx('list_text')}>아티스트 콘텐츠</p>
    //           </div>
    //         </NavLink>
    //         <NavLink to="/play-list/media" className={cx('menu_list_box')}>
    //           <div className={cx('menu_list_item_box')}>
    //             <img
    //               className={cx('img_on')}
    //               src="/statics/images/ic_play_playlist_on.png"
    //               alt="play_playlist_on_icon"
    //             />
    //             <img
    //               className={cx('img_off')}
    //               src="/statics/images/ic_play_playlist_off.png"
    //               alt="play_playlist_off_icon"
    //             />
    //             <p className={cx('list_text')}>플레이리스트</p>
    //           </div>
    //         </NavLink>
    //         <div className={cx('select_media_box')}>
    //           <div className={cx('select_text_box')}>
    //             <p className={cx('select_text')}>선택된 영상</p>
    //           </div>
    //           {wishPlayList &&
    //             wishPlayList.map((el) => {
    //               const isPlayList =
    //                 grade !== '프리미엄' && !!(playIds.indexOf(el.id) > -1);
    //               return (
    //                 <div
    //                   className={cx('select_item', {
    //                     play_list_item: isPlayList,
    //                   })}
    //                   key={el.id}
    //                   role="presentation"
    //                   disabled={isPlayList}
    //                   onClick={() => (isPlayList ? {} : handleRemove(el.id))}
    //                 >
    //                   <div className={cx('select_img_box')}>
    //                     <img
    //                       className={cx('select_img')}
    //                       src={
    //                         el.thumbnailUrl
    //                           ? el.thumbnailUrl
    //                           : '/statics/images/abstract_wave.jpg'
    //                       }
    //                       alt="abstract_wave"
    //                     />
    //                   </div>
    //                   <div className={cx('select_sub_text_box')}>
    //                     <p className={cx('select_sub_text')}>{el?.title}</p>
    //                   </div>
    //                 </div>
    //               );
    //             })}
    //         </div>
    //         <div className={cx('bottom_select_inner')}>
    //           <div className={cx('select_number_box')}>
    //             <div className={cx('select_number_text_box')}>
    //               <p className={cx('select_number_text')}>남은 선택 횟수</p>
    //             </div>
    //             <div className={cx('selecet_count_box')}>
    //               {grade !== '프리미엄' ? (
    //                 <>
    //                   <b className={cx('selecet_count_number_b')}>
    //                     {playList.length || 0}
    //                   </b>{' '}
    //                   / {grade === '베이직' ? 10 : 30}
    //                 </>
    //               ) : (
    //                 <p className={cx('selecet_count_number')}>무제한</p>
    //               )}
    //             </div>
    //           </div>
    //           <div className={cx('select_keep_box')}>
    //             <div className={cx('select_keep_text_box')}>
    //               <p className={cx('select_keep_text')}>
    //                 {grade} 솔루션 이용중
    //               </p>
    //             </div>
    //             <div className={cx('select_keep_button_box')}>
    //               {grade !== '프리미엄' && playList.length === maxCount ? (
    //                 <button
    //                   className={cx('select_keep_button')}
    //                   onClick={handleResetPlayList}
    //                 >
    //                   플레이리스트 리셋 ( {playlistResetCount} / {maxReset} )
    //                 </button>
    //               ) : (
    //                 <button
    //                   className={cx('select_keep_button')}
    //                   onClick={handleUpdatePlayList}
    //                 >
    //                   플레이리스트 보관
    //                 </button>
    //               )}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className={cx('inner')}>
    //       <div className={cx('inner_box')}>
    //         {/* 데이터 없을 떄 */}
    //         {!data ||
    //           (data.length === 0 && (
    //             <div className={cx('no_data_inner')}>
    //               <div className={cx('no_data_box')}>
    //                 <div className={cx('no_data_title_box')}>
    //                   <p className={cx('no_data_title')}>
    //                     Play List 준비 중 입니다.
    //                   </p>
    //                 </div>
    //                 <div className={cx('no_data_text_box')}>
    //                   <p className={cx('no_data_text')}>
    //                     최대한 빠르게 준비해 드리겠습니다.
    //                   </p>
    //                 </div>
    //               </div>
    //             </div>
    //           ))}
    //         {data &&
    //           data.length > 0 &&
    //           data.map((item, i) => {
    //             return (
    //               <div key={i} className={cx('media_list_inner')}>
    //                 <div className={cx('media_list_title_box')}>
    //                   <h4 className={cx('media_list_title')}>{item.name}</h4>
    //                 </div>
    //                 <div className={cx('media_list_box')}>
    //                   {item &&
    //                     item?.contents?.map((el, index) => {
    //                       return (
    //                         <div
    //                           key={`${i}-${index}`}
    //                           className={cx('media_list_item_box')}
    //                           role="presentation"
    //                           onClick={(e) => handleClickDetail(e, el)}
    //                         >
    //                           <div
    //                             className={cx('media_list_item', {
    //                               media_list_item_active:
    //                                 [...wishPlayList.map((p) => p.id)].indexOf(
    //                                   el.id,
    //                                 ) > -1,
    //                             })}
    //                           >
    //                             <img
    //                               className={cx('media_list_item_img')}
    //                               src={
    //                                 el.thumbnailUrl
    //                                   ? el.thumbnailUrl
    //                                   : '/statics/images/glacier.jpg'
    //                               }
    //                               alt="glacier"
    //                             />
    //                             <div
    //                               role="presentation"
    //                               className={cx('media_list_add_button_box')}
    //                               onClick={(e) => handlePlayList(e, el)}
    //                             >
    //                               <img
    //                                 className={cx('media_list_item_add_button')}
    //                                 src="/statics/images/ic_playlist_add.png"
    //                                 alt="추가 버튼"
    //                               />
    //                             </div>
    //                             <div className={cx('media_quality_box')}>
    //                               <p className={cx('media_quality')}>
    //                                 {el?.resolution}
    //                               </p>
    //                             </div>
    //                           </div>
    //                           <div className={cx('media_list_item_title_box')}>
    //                             <p className={cx('media_list_item_title')}>
    //                               {el.title}
    //                             </p>
    //                             <div className={cx('like_button')}>
    //                               {el.liked ? (
    //                                 <img
    //                                   onClick={(e) =>
    //                                     handleClickLike(e, i, index, el.id)
    //                                   }
    //                                   className={cx('media_list_item_like_on')}
    //                                   src="/statics/images/ic_playlist_like_on.png"
    //                                   alt="playlist_like_on"
    //                                 />
    //                               ) : (
    //                                 <img
    //                                   onClick={(e) =>
    //                                     handleClickLike(e, i, index, el.id)
    //                                   }
    //                                   className={cx('media_list_item_like_off')}
    //                                   src="/statics/images/ic_playlist_like_off.png"
    //                                   alt="playlist_like_off"
    //                                 />
    //                               )}
    //                             </div>
    //                           </div>
    //                         </div>
    //                       );
    //                     })}
    //                   {item.contents.length === 0 && (
    //                     <div
    //                       key={`${i}-no`}
    //                       className={cx('media_list_item_box')}
    //                     >
    //                       {/* 데이터 하나만 없을때 */}
    //                       <div className={cx('no_single_data_title_box')}>
    //                         <p className={cx('no_single_data_title')}>
    //                           Play List 준비 중 입니다.
    //                         </p>
    //                       </div>
    //                       <div className={cx('no_single_data_text_box')}>
    //                         <p className={cx('no_single_data_text')}>
    //                           최대한 빠르게 준비해 드리겠습니다.
    //                         </p>
    //                       </div>
    //                     </div>
    //                   )}
    //                 </div>
    //               </div>
    //             );
    //           })}
    //       </div>
    //     </div>

    //     {/* 모바일 footer 이동 및 검색 */}
    //     <div className={cx('mobile_footer_inner')}>
    //       <div className={cx('mobile_footer_box')}>
    //         <div
    //           className={cx(
    //             !listMenu
    //               ? 'mobile_hide_select_box'
    //               : 'active_mobile_hide_select_box',
    //           )}
    //         >
    //           <img
    //             onClick={() => setListMenu(!listMenu)}
    //             className={cx('mobile_hide_button')}
    //             src="/statics/images/mobile_select_button.png"
    //             alt="mobile_select_button"
    //           />
    //           <div className={cx('mobile_hide_text_box')}>
    //             <p className={cx('mobile_hide_text')}>선택된 영상</p>
    //           </div>
    //           <div className={cx('mobile_hide_menu_item_inner')}>
    //             {wishPlayList &&
    //               wishPlayList.map((el) => {
    //                 const isPlayList =
    //                   grade !== '프리미엄' && !!(playIds.indexOf(el.id) > -1);
    //                 return (
    //                   <div
    //                     className={cx('mobile_hide_menu_item_box', {
    //                       play_list_item: isPlayList,
    //                     })}
    //                     key={el.id}
    //                     role="presentation"
    //                   >
    //                     <div className={cx('mobile_hide_img_box')}>
    //                       <img
    //                         className={cx('mobile_hide_img')}
    //                         src={
    //                           el.thumbnailUrl
    //                             ? el.thumbnailUrl
    //                             : '/statics/images/mobile_hide_img.png'
    //                         }
    //                         alt="mobile_hide_img"
    //                       />
    //                     </div>
    //                     <div className={cx('mobile_hide_menu_item_text_box')}>
    //                       <p className={cx('mobile_hide_menu_item_text')}>
    //                         {el?.title}
    //                       </p>
    //                     </div>
    //                     {!isPlayList && (
    //                       <button
    //                         className={cx('btn_remove_play')}
    //                         onClick={() => handleRemove(el.id)}
    //                       >
    //                         X
    //                       </button>
    //                     )}
    //                   </div>
    //                 );
    //               })}
    //           </div>
    //           <div className={cx('mobile_hide_menu_item_button_box')}>
    //             {grade !== '프리미엄' && playList.length === maxCount ? (
    //               <button
    //                 className={cx('mobile_hide_menu_item_button')}
    //                 onClick={handleResetPlayList}
    //               >
    //                 플레이리스트 리셋 ( {playlistResetCount} / {maxReset} )
    //               </button>
    //             ) : (
    //               <button
    //                 className={cx('mobile_hide_menu_item_button')}
    //                 onClick={handleUpdatePlayList}
    //               >
    //                 플레이리스트 보관
    //               </button>
    //             )}
    //           </div>
    //         </div>
    //         <div className={cx('mobile_footer_header_box')}>
    //           <div className={cx('mobile_footer_select_box')}>
    //             <p className={cx('mobile_footer_select')}>
    //               {grade !== '프리미엄' ? (
    //                 <>
    //                   <b className={cx('mobile_footer_select_b')}>
    //                     {playList.length || 0}
    //                   </b>{' '}
    //                   / {grade === '베이직' ? 10 : 30}
    //                 </>
    //               ) : (
    //                 <p className={cx('mobile_footer_select')}>무제한</p>
    //               )}
    //             </p>
    //           </div>
    //           <div className={cx('mobile_footer_header_img_box')}>
    //             <img
    //               className={cx('mobile_footer_header_img')}
    //               src="/statics/images/ic_m_solution.png"
    //               alt="ic_m_solution"
    //             />
    //           </div>
    //           <div className={cx('mobile_footer_header_title_box')}>
    //             <p className={cx('mobile_footer_header_title')}>
    //               {grade} 솔루션 이용중
    //             </p>
    //           </div>
    //         </div>
    //         <div className={cx('mobile_footer_menu_inner')}>
    //           <NavLink
    //             to="/play-list"
    //             className={({ isActive }) =>
    //               cx('menu_list_box', { active: isActive })
    //             }
    //           >
    //             <div className={cx('menu_list_item_box')}>
    //               <img
    //                 className={cx('img_on')}
    //                 src="/statics/images/ic_play_list_on.png"
    //                 alt="play_list_on_icon"
    //               />
    //               <img
    //                 className={cx('img_off')}
    //                 src="/statics/images/ic_play_list_off.png"
    //                 alt="play_list_off_icon"
    //               />
    //               <p className={cx('list_text')}>빔몬스터 콘텐츠</p>
    //             </div>
    //           </NavLink>
    //           <NavLink to="/play-list/search" className={cx('menu_list_box')}>
    //             <div className={cx('menu_list_item_box')}>
    //               <img
    //                 className={cx('img_on')}
    //                 src="/statics/images/ic-play-search-on.png"
    //                 alt="search_om_icon"
    //               />
    //               <img
    //                 className={cx('img_off')}
    //                 src="/statics/images/ic-play-search-off.png"
    //                 alt="search_off_icon"
    //               />
    //               <p className={cx('list_text')}>아티스트 콘텐츠</p>
    //             </div>
    //           </NavLink>
    //           <NavLink to="/play-list/media" className={cx('menu_list_box')}>
    //             <div className={cx('menu_list_item_box')}>
    //               <img
    //                 className={cx('img_on')}
    //                 src="/statics/images/ic_play_playlist_on.png"
    //                 alt="play_playlist_on_icon"
    //               />
    //               <img
    //                 className={cx('img_off')}
    //                 src="/statics/images/ic_play_playlist_off.png"
    //                 alt="play_playlist_off_icon"
    //               />
    //               <p className={cx('list_text')}>플레이리스트</p>
    //             </div>
    //           </NavLink>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
