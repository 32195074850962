import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './AdvertisementUpload.module.scss';
import { NavLink } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function AdvertisementUpload({
  isClicked,
  setIsClicked,
  videoUrl,
  handleUploadVideo,
  handleCancelVideo,
}) {
  // 파일 업로드
  const fileRef = useRef(null);

  const onClickUpload = () => {
    if (isClicked) return;
    setIsClicked(true);
    fileRef.current?.click();
  };

  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <div className={cx('title_box')}>
          <h2 className={cx('title')}>광고 등록</h2>
          <p className={cx('text')}>
            광고 검토 후 결제가 취소될 수도 있습니다.
          </p>
        </div>
        <div className={cx('upload_inner')}>
          <input
            className={cx('upload_file_input')}
            ref={fileRef}
            type="file"
            id="upload"
            onChange={handleUploadVideo}
            accept="video/mp4"
          />
          {/* 업로드 전 */}
          {videoUrl ? (
            <div className={cx('upload_after_box')}>
              <video className={cx('upload_after_img')} src={videoUrl}></video>
              {/* <img
                  className={cx('upload_after_img')}
                  src=""
                  alt="업로드된 이미지"
                /> */}
              <img
                className={cx('file_upload_delete_img')}
                src="/statics/images/ic_photo_del.png"
                alt="upload_button"
                onClick={handleCancelVideo}
              />
            </div>
          ) : (
            <div onClick={onClickUpload} className={cx('upload_prev_box')}>
              <img
                className={cx('upload_img')}
                src="/statics/images/ic-ad-video-thumbnail.png"
                alt="광고 업로드 이미지"
              />
            </div>
          )}
          {/* 업로드 후 나오는 영상 */}
          {/*  */}
          <p className={cx('danger_text')}>
            * 광고 영상은 MP4파일만 가능합니다.
          </p>
        </div>
        <div className={cx('upload_button_box')}>
          {videoUrl ? (
            <NavLink
              to="/advertisement/payment"
              className={cx('payment_button')}
            >
              결제 진행
            </NavLink>
          ) : (
            <button
              onClick={onClickUpload}
              className={cx('registration_button')}
            >
              광고 등록
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
