import React, {
  useEffect,
  useCallback,
} from 'react';
import * as api from 'apis';
import { useNavigate } from 'react-router-dom';
import useContract from 'hooks/useContract';
import useCompany from 'hooks/useCompany';
import useAuth from 'hooks/useAuth';
import {
  handleCheckSolution,
} from 'libs/authService';
import SolutionDepositWaitWrapper from 'components/SolutionDepositWaitWrapper';

export default function SolutionDepositWaitContainer() {
  const {
    contractId,
    contractFile,
    handleChangeContractData,
  } = useContract();
  const navigator = useNavigate();
  const {
    isLogIn,
  } = useAuth();

  const {
    phone,
  } = useCompany();

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })]);
        handleChangeContractData({
          contractId: res[1]?.data?.id,
          contractFile: res[1]?.data?.clientSignPdfUrl,
        });
        if (res[1]?.data?.status === 'MONEY_CHECKED') {
          navigator('/solution/deposit-complete');
        } else if (res[1]?.data?.status === 'SCHEDULED') {
          navigator('/solution/schedule-complete');
        } else if (res[1]?.data?.status === 'FINISH') {
          navigator('/solution/success');
        } else {
          handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [handleChangeContractData, isLogIn, navigator]);

  const handleClick = useCallback(() => {
    try {
      fetch(contractFile).then(resp => resp.arrayBuffer()).then(resp => {

        // set the blog type to final pdf
        const file = new Blob([resp], { type: 'application/pdf' });

        // process to auto download it
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `빔몬스터_계약서.pdf`;
        link.click();
        link.remove();
      });
    } catch (err) {

    }
  }, [contractFile]);

  return (
    <SolutionDepositWaitWrapper
      contractFile={contractFile}
      onClick={handleClick}
      phone={phone}
    />
  )
}
