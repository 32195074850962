import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import MypagAsList from 'components/views/MypagAsList/MypagAsList';

export default function MypagAsListWrapper() {
    return (
        <>
            <Header />
            <MypagAsList />
            <Footer />
        </>
    )
}
