import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './PlayListArtistDetail.module.scss';
import mockdata from '../Mockup/Mockup';
import PlayListArtistSubDetail from './PlayListArtistSubDetail';
import { numberWithCommas } from 'libs/numbers';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function PlayListArtistDetail({
  data,
  contents,
  pageData,
  handlePage,
  handleClickDetail,
  handleClickLike,
  handleClickLikeOther,
  handleWishList,
  wishPlayList,
  myPlayList,
  isUnlimitedPlaylist,
  handleUpdatePlayList,
}) {
  // 바로구매 장바구니 이동
  const navigator = useNavigate();

  const onClickDirectBasketMove = () => {
    navigator('/mypage/basket/artist');
  };

  const onClickPaymentMove = () => {
    navigator('/mypage/payment-history?type=artist');
  };

  // 장바구니 유무 팝업창
  const [open, setOpen] = useState(false);

  const isPaid = myPlayList.indexOf(data?.id) > -1;

  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <div className={cx('artist_info_inner')}>
          <div
            className={cx('media_box')}
            role="presentation"
            onClick={(e) => handleClickDetail(e, data)}
          >
            {data?.thumbnailUrl ? (
              <img
                className={cx('artist_media_img')}
                src={data?.thumbnailUrl}
                alt="아티스트 콘텐츠 이미지"
              />
            ) : (
              <img
                className={cx('artist_media_img')}
                src="/statics/images/pink_cloud.jpg"
                alt="아티스트 콘텐츠 이미지"
              />
            )}
            <div className={cx('media_play_button_box')}>
              <img
                className={cx('media_play_button_img')}
                src="/statics/images/media_play_button_arrow.png"
                alt="재생 버튼"
              />
            </div>
          </div>
          <div className={cx('artist_info_box')}>
            <div className={cx('info_title_box')}>
              <p className={cx('info_title')}>{data?.title}</p>
            </div>
            <div className={cx('info_text_box')}>
              <p className={cx('info_text')}>{data?.creator?.name}</p>
              <span className={cx('info_dot')}></span>
              <p className={cx('info_text')}>
                {`판매수 ${
                  data?.sellCount ? numberWithCommas(data?.sellCount) : '-'
                }`}
              </p>
              <span className={cx('info_dot')}></span>
              <p className={cx('info_text')}>
                {`좋아요 ${
                  data?.likeCount ? numberWithCommas(data?.likeCount) : '-'
                }`}
              </p>
            </div>
            <div className={cx('sub_info_text_box')}>
              <p className={cx('info_text')}>{data?.shortDescription}</p>
            </div>
            <hr className={cx('hr')} />
            <div className={cx('info_payment_box')}>
              <p className={cx('info_payment_text')}>제품 가격</p>
              <p className={cx('info_price')}>{`${
                data?.price ? numberWithCommas(data?.price) : '-'
              }원`}</p>
            </div>
            <div className={cx('info_button_box')}>
              <div className={cx('direct_button_box')}>
                <button
                  disabled={isPaid}
                  onClick={
                    isUnlimitedPlaylist
                      ? (e) => handleUpdatePlayList(data)
                      : (e) => {
                          handleWishList(e, data);
                          onClickDirectBasketMove();
                        }
                  }
                  className={cx('direct_button')}
                >
                  {isPaid
                    ? '구매 완료'
                    : isUnlimitedPlaylist
                    ? '무료구매'
                    : '바로구매'}
                </button>
              </div>
              <div className={cx('sub_button_inner')}>
                <div className={cx('sub_button_box')}>
                  <button
                    onClick={(e) => handleClickLike(e, data?.id)}
                    className={cx('like_button')}
                  >
                    {data?.liked ? (
                      <img
                        className={cx('like_img')}
                        src="/statics/images/ic_playlist_like_on.png"
                        alt="하트 이미지"
                      />
                    ) : (
                      <img
                        className={cx('like_img')}
                        src="/statics/images/ic_playlist_like_off.png"
                        alt="하트 이미지"
                      />
                    )}
                    {data?.likeCount ? numberWithCommas(data?.likeCount) : '-'}
                  </button>
                </div>
                <div className={cx('sub_button_box')}>
                  <>
                    {isPaid ? (
                      <button
                        disabled={isUnlimitedPlaylist}
                        onClick={onClickPaymentMove}
                        className={cx('basket_button')}
                      >
                        결제 내역
                      </button>
                    ) : (
                      <>
                        {isUnlimitedPlaylist ? (
                          <>
                            {[...wishPlayList.map((p) => p.id)].indexOf(
                              data?.id,
                            ) > -1 ? (
                              <button
                                onClick={(e) => {
                                  handleWishList(e, data);
                                }}
                                className={cx('basket_button')}
                              >
                                장바구니 삭제
                              </button>
                            ) : (
                              <button
                                onClick={(e) => {
                                  handleWishList(e, data);
                                }}
                                className={cx('basket_button')}
                              >
                                장바구니에 담기
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            {[...wishPlayList.map((p) => p.id)].indexOf(
                              data?.id,
                            ) > -1 ? (
                              <button
                                onClick={onClickDirectBasketMove}
                                className={cx('basket_button')}
                              >
                                장바구니로 이동
                              </button>
                            ) : (
                              <button
                                onClick={(e) => {
                                  handleWishList(e, data);
                                  setOpen(true);
                                }}
                                className={cx('basket_button')}
                              >
                                장바구니에 담기
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                  {/* 장바구니 유무 팝업창 */}
                  {open && (
                    <div className={cx('popup_container')}>
                      <div className={cx('popup_inner')}>
                        <p className={cx('popup_text')}>
                          장바구니에 추가되었습니다.
                        </p>
                        <p className={cx('popup_text')}>
                          장바구니로 이동하시겠습니까?
                        </p>
                        <div className={cx('popup_button_box')}>
                          <button
                            onClick={onClickDirectBasketMove}
                            className={cx('popup_button')}
                          >
                            예
                          </button>
                          <button
                            onClick={() => setOpen(false)}
                            className={cx('popup_button')}
                          >
                            아니오
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('artist_info_inner')}>
          <div className={cx('info_inner')}>
            <div className={cx('info_content_box')}>
              <p className={cx('info_content')}>{data?.description}</p>
            </div>
            <span className={cx('info_vertical')}></span>
            <span className={cx('mobile_info_vertical')}></span>
            <div className={cx('info_sub_content_inner')}>
              <div className={cx('mobile_profile_inner')}>
                <div className={cx('profile_box')}>
                  {data?.creator?.profileImageUrl ? (
                    <img
                      className={cx('profile_img')}
                      src={data?.creator?.profileImageUrl}
                      alt="프로필 사진"
                    />
                  ) : (
                    <img
                      className={cx('profile_img')}
                      src="/statics/images/profile.png"
                      alt="프로필 사진"
                    />
                  )}
                </div>
                <p className={cx('mobile_content')}>{data?.creator?.name}</p>
              </div>
              <div className={cx('info_sub_content_box')}>
                <p className={cx('info_content', 'mobile_info_content')}>
                  {data?.creator?.name}
                </p>
                <p className={cx('info_sub_content')}>
                  {data?.creator?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PlayListArtistSubDetail
        contents={contents}
        pageData={pageData}
        handlePage={handlePage}
        handleClickDetail={handleClickDetail}
        handleClickLike={handleClickLikeOther}
        parentContentId={data?.id}
        wishPlayList={wishPlayList}
        handleWishList={handleWishList}
        myPlayList={myPlayList}
      />
    </div>
  );
}
