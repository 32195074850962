import React from 'react';
import { Header } from 'components/views';
import SolutionRentalComplete from 'components/views/SolutionRentalComplete';

export default function SolutionRentalCompleteWrapper() {
  return (
    <>
          <Header />
          <SolutionRentalComplete />
    </>
  )
}
