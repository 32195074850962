import React from 'react';
import Refund from 'components/views/Refund';
import { Footer, Header } from 'components/views';

export default function RefundWrapper({ content }) {
  return (
    <>
      <Header />
      <Refund content={content} />
      <Footer />
    </>
  );
}
