import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './IntroExample.module.scss';
import Pagination from 'components/common/Pagination';
import useScrollFadeIn from 'hooks/useScrollFadeIn';

const cx = classNames.bind(styles);

export default function IntroExample({
  usageList,
  locationList,
  currentLocation,
  search,
  currentPage,
  lastPage,
  perPage,
  onChange,
  onSearch,
  onKeyPress,
  handlePage,
  onClick,
}) {
  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('inner_bg')}>
          <div className={cx('inner')}>
            <div className={cx('inner_box')}>
              <div
                {...useScrollFadeIn('up', 1, 0)}
                className={cx('sub_title_box')}
              >
                <h4 className={cx('sub_title')}>서비스 이용 사례</h4>
              </div>
              <div
                {...useScrollFadeIn('up', 1, 0.1)}
                className={cx('title_box')}
              >
                <h4 className={cx('title')}>이유가 있는 사랑</h4>
              </div>
              <div
                {...useScrollFadeIn('up', 1, 0.2)}
                className={cx('text_box')}
              >
                <p className={cx('text')}>
                  이미 많은 분들에게 사랑받는 다양한 미디어 인테리어 사례를
                  구경해 보세요.
                </p>
              </div>
              <div className={cx('select_inner')}>
                <div className={cx('select_sub_inner')}>
                  {/* 불필요함
                                        <div className={cx('recent_select_box')}>
                                        <select
                                            className={cx('select_box')}
                                            style={{ backgroundImage: 'url(/statics/images/ic_expand_more.png)' }}
                                            onChange={onChange}
                                        >
                                            <option value=''>최신순</option>
                                            <option>좋아요 순</option>
                                        </select>
                                    </div> */}
                  <div
                    {...useScrollFadeIn('up', 1, 0.3)}
                    className={cx('category_select_box')}
                  >
                    <select
                      className={cx('select_box')}
                      style={{
                        backgroundImage:
                          'url(/statics/images/ic_expand_more.png)',
                      }}
                      onChange={onChange}
                      name="currentLocation"
                      value={currentLocation}
                    >
                      <option value="">지역 선택</option>
                      {locationList.map((el, i) => {
                        return (
                          <option key={i} value={el}>
                            {el}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div
                  {...useScrollFadeIn('up', 1, 0.3)}
                  className={cx('search_box')}
                >
                  <input
                    className={cx('search_input')}
                    name="search"
                    value={search}
                    onChange={onChange}
                    type="text"
                    placeholder="제목으로 검색해보세요."
                    onKeyPress={onKeyPress}
                  />
                  <button
                    className={cx('search_button')}
                    onClick={() => onSearch(1)}
                  >
                    <img
                      src="/statics/images/ic_input_search.png"
                      alt="search_button"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('container')}>
          <div className={cx('bottom_container')}>
            <div className={cx('inner')}>
              <div className={cx('sub_inner')}>
                <div
                  {...useScrollFadeIn('up', 1, 0)}
                  className={cx('media_inner')}
                >
                  {usageList.map((el, i) => {
                    return (
                      <div
                        role="presentation"
                        key={i}
                        className={cx('media_box')}
                        onClick={() => onClick(el, i)}
                      >
                        <div className={cx('media_sub_box')}>
                          <div className={cx('media_play_button_box')}>
                            <img
                              className={cx('media_play_button')}
                              src="/statics/images/media_play_button_arrow.png"
                              alt="media_play_button"
                            />
                          </div>
                          <img
                            className={cx('media_play_img')}
                            src={
                              el?.thumbnailImageUrl
                                ? el.thumbnailImageUrl
                                : '/statics/images/tag_viedo.png'
                            }
                            alt="media"
                          />
                        </div>
                        <div className={cx('media_infor_box')}>
                          <div className={cx('media_infor_icon_box')}>
                            <img
                              className={cx('media_infor_icon')}
                              src={
                                el?.profileImageUrl
                                  ? el.profileImageUrl
                                  : '/statics/images/tag_icon.png'
                              }
                              alt="icon"
                            />
                            <div className={cx('media_infor_text_box')}>
                              <p className={cx('media_infor_text')}>
                                {el.name} ({el.location})
                              </p>
                            </div>
                          </div>
                          {el.type === 'LED' ? (
                            <div className={cx('media_infor_LED_box')}>
                              <p className={cx('media_infor_LED_text')}>LED</p>
                            </div>
                          ) : (
                            <div className={cx('media_infor_vim_box')}>
                              <p className={cx('media_infor_vim_text')}>
                                빔프로젝터
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Pagination
                currentPage={currentPage}
                lastPage={lastPage}
                perPage={perPage}
                handlePage={handlePage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
