import React, { Fragment } from 'react';
import classNames from 'classnames/bind';
import styles from './AdvertisementCategory.module.scss';

const cx = classNames.bind(styles);

export default function AdvertisementCategory({
  originAdvertiseCategories,
  selectedCategory,
  handleSelectCategory,
}) {
  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        {/* 광고 카테고리 선택 */}
        <div className={cx('category_inner')}>
          <div className={cx('category_title_box')}>
            <p className={cx('category_title')}>
              광고 카테고리
              <b className={cx('category_text')}>
                진행하는 광고에 맞는 카테고리를 선택
              </b>
            </p>
          </div>
          <div className={cx('category_box')}>
            {
              originAdvertiseCategories?.length > 0 &&
              originAdvertiseCategories.map((el, i) => {
                if (el.isSensitive) return <Fragment key={`insensitive-${i}`}></Fragment>;
                return (
                  <div className={cx('category_input_box')} key={el.category}>
                    <input
                      className={cx('hide_input', { active: el.category === selectedCategory.category })}
                      name="category"
                      id={el.category}
                      type="radio"
                      onClick={() => el.category !== selectedCategory.category && handleSelectCategory(el.category)}
                    />
                    <label className={cx('custom_label')} htmlFor={el.category}>
                      <span className={cx('circle_label')}></span>
                      <p className="text_label">{el.category}</p>
                    </label>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className={cx('category_inner')}>
          <div className={cx('category_title_box')}>
            <p className={cx('category_danger_text')}>
              아래는 민감한 콘텐츠로 분류된 카테고리입니다. 선택 시 매체 수가
              제한될 수 있습니다.
            </p>
          </div>
          <div className={cx('category_box')}>
            {
              originAdvertiseCategories?.length > 0 &&
              originAdvertiseCategories.map((el, i) => {
                if (!el.isSensitive) return <Fragment key={`sensitive-${i}`}></Fragment>;
                return (
                  <div className={cx('category_input_box')} key={el.category}>
                    <input
                      className={cx('hide_input', { active: el.category === selectedCategory.category })}
                      name="category"
                      id={el.category}
                      type="radio"
                      onClick={() => el.category !== selectedCategory.category && handleSelectCategory(el.category)}
                    />
                    <label className={cx('custom_label')} htmlFor={el.category}>
                      <span className={cx('circle_label')}></span>
                      <p className="text_label">{el.category}</p>
                    </label>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}
