import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './Pagination.module.scss';

const cx = classNames.bind(styles);

const Pagination = ({ currentPage, lastPage, perPage, handlePage }) => {
  const renderPages = useMemo(() => {
    const components = [];
    const per =
      currentPage % perPage === 0
        ? currentPage / perPage - 1
        : Math.floor(currentPage / perPage);
    const last =
      lastPage >= (per + 1) * perPage ? (per + 1) * perPage : lastPage;

    for (let i = per * perPage; i < last; i++) {
      components.push(
        <div
          role="presentation"
          className={cx('pagination_number_box')}
          key={i}
          onClick={() => handlePage(i + 1)}
        >
          <p
            id={i}
            className={cx(
              currentPage === i + 1
                ? 'active_pagination_number'
                : 'pagination_number',
            )}
          >
            {i + 1}
          </p>
        </div>,
      );
    }

    return components;
  }, [currentPage, lastPage, perPage, handlePage]);

  return (
    <>
      <div className={cx('pagination_box')}>
        <div>
          <button
            className={cx('pagination_button')}
            onClick={() => handlePage(currentPage - 1)}
            disabled={currentPage === 1 ? true : false}
            style={{
              backgroundImage:
                'url(/statics/images/pagination_prev_button.png)',
            }}
          />
        </div>
        <div className={cx('pagination_number_inner')}>{renderPages}</div>
        <div>
          <button
            className={cx('pagination_button')}
            onClick={() => handlePage(currentPage + 1)}
            disabled={currentPage === lastPage ? true : false}
            style={{
              backgroundImage:
                'url(/statics/images/pagination_next_button.png)',
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Pagination;
