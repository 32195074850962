import React from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';
import styles from './MypageScheduleAccess.module.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function MypageScheduleAccess({
  visitDate,
  visitManagerName,
  visitManagerPhone,
  visitManagerProfileImageUrl,
}) {
  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('inner')}>
          <Link to="/mypage" className={cx('back_box')}>
            <img
              className={cx('back_img')}
              src="/statics/images/ic_profile_back.png"
              alt="ic_profile_back"
            />
            <div className={cx('back_text_box')}>
              <p className={cx('back_text')}>마이페이지</p>
            </div>
          </Link>
          <div className={cx('inner_box')}>
            <div className={cx('header_inner')}>
              <div className={cx('title_inner')}>
                <hr className={cx('hr')} />
                <Link to="/mypage/schedule/access" className={cx('title_box')}>
                  <h4 className={cx('title')}>설치 스케줄 현황</h4>
                </Link>
                <Link to="/mypage/as" className={cx('sub_title_box')}>
                  <h4 className={cx('sub_title')}>A/S 신청 현황</h4>
                </Link>
              </div>
            </div>
            <div className={cx('content_inner')}>
              <div className={cx('engineer_box')}>
                <div className={cx('engineer_charge_box')}>
                  <p className={cx('engineer_charge')}>설치 담당 기사</p>
                </div>
                {visitDate ? (
                  <>
                    <div className={cx('engineer_profile_box')}>
                      <img
                        className={cx('img_profile')}
                        src={
                          visitManagerProfileImageUrl
                            ? visitManagerProfileImageUrl
                            : '/statics/images/profile.png'
                        }
                        alt="profile"
                      />
                    </div>
                    <div className={cx('engineer_name_box')}>
                      <p className={cx('engineer_name')}>{visitManagerName}</p>
                    </div>
                    <div className={cx('engineer_tell_box')}>
                      <p className={cx('engineer_tell')}>
                        {visitManagerPhone
                          ? visitManagerPhone.replace(
                            /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                            '$1-$2-$3',
                          )
                          : '-'}
                      </p>
                    </div>
                    <div className={cx('engineer_visit_box')}>
                      <p className={cx('engineer_visit')}>
                        {moment(visitDate).format('YYYY')}년{' '}
                        {moment(visitDate).format('MM')}월{' '}
                        {moment(visitDate).format('DD')}일 방문 예정
                      </p>
                    </div>
                  </>
                ) : (
                    <div>
                      <p className={cx('text')}>
                        솔루션 구매 후 확인 가능합니다.
                    </p>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
