import React, { useEffect, useState, useCallback } from 'react';
import MypagePaymentHistoryWrapper from 'components/MypagePaymentHistoryWrapper';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import * as api from 'apis';

export default function MypagePaymentHistoryCotanier() {
  const location = useLocation();
  const { type } = qs.parse(location.search);
  const [tab, setTab] = useState('Artist'); // Artist, Advertisement
  const [isInit, setIsInit] = useState(true);
  const [data, setData] = useState([]);
  const [pageData, setPageData] = useState({
    total: 0,
    currentPage: 1,
    lastPage: 0,
    perPage: 5,
    limit: 10,
    search: '',
    orderBy: '',
  });

  const [artistData, setArtistData] = useState([]);
  const [artistPageData, setArtistPageData] = useState({
    total: 0,
    currentPage: 1,
    lastPage: 0,
    perPage: 5,
    limit: 10,
    search: '',
    orderBy: '',
  });

  const handleAdvertiseSearch = useCallback(
    async (currentPage) => {
      try {
        const res = await api.getAdvertisesOrders(
          currentPage - 1,
          pageData.limit,
        );
        setData(res?.data?.data);
        setPageData((prev) => ({
          ...prev,
          currentPage,
          total: res?.data?.total || 0,
          lastPage: Math.ceil(res?.data?.total / 10) || 1,
        }));
      } catch (err) {
        console.error(err);
      }
    },
    [pageData.limit],
  );

  const handlePage = useCallback(
    (currentPage) => {
      handleAdvertiseSearch(currentPage);
    },
    [handleAdvertiseSearch],
  );

  const handleTab = useCallback((tab) => {
    setTab(tab);
  }, []);

  const handleAritistSearch = useCallback(
    async (currentPage) => {
      try {
        const res = await api.getCreatorsArtistOrders(
          currentPage - 1,
          pageData.limit,
        );
        setArtistData(res?.data);
        setArtistPageData((prev) => ({
          ...prev,
          currentPage,
          total: res?.data?.total || 0,
          lastPage: Math.ceil(res?.data?.total / 10) || 1,
        }));
      } catch (err) {
        console.error(err);
      }
    },
    [pageData.limit],
  );

  const handleArtistPage = useCallback(
    (currentPage) => {
      handleAritistSearch(currentPage);
    },
    [handleAritistSearch],
  );

  useEffect(() => {
    if (!isInit) return;
    handleAdvertiseSearch(1);
    handleAritistSearch(1);
    setIsInit(true);
    if (type === 'artist') {
      setTab('Artist');
    }

    if (type === 'advertisement') {
      setTab('Advertisement');
    }
  }, [handleAdvertiseSearch, handleAritistSearch, isInit, type, setTab]);

  return (
    <MypagePaymentHistoryWrapper
      handlePage={handlePage}
      pageData={pageData}
      data={data}
      handleArtistPage={handleArtistPage}
      artistData={artistData}
      artistPageData={artistPageData}
      tab={tab}
      handleTab={handleTab}
    />
  );
}
