import React, {
  useEffect,
} from 'react';
import * as api from 'apis';
import { useNavigate } from 'react-router-dom';
import useContract from 'hooks/useContract';
import useAuth from 'hooks/useAuth';
import {
  handleCheckSolution,
} from 'libs/authService';
import SolutionScheduleCompleteWrapper from 'components/SolutionScheduleCompleteWrapper';

export default function SolutionScheduleCompleteContainer() {
  const {
    contractId,
    visitDate,
    visitManagerName,
    visitManagerPhone,
    visitManagerProfileImageUrl,
    handleChangeContractData,
  } = useContract();
  const navigator = useNavigate();
  const {
    isLogIn,
  } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })]);
        handleChangeContractData({
          contractId: res[1]?.data?.id,
          contractFile: res[1]?.data?.clientSignPdfUrl,
          visitDate: res[1]?.data?.visitDate,
          visitManagerName: res[1]?.data?.visitManagerName,
          visitManagerPhone: res[1]?.data?.visitManagerPhone,
          visitManagerProfileImageUrl: res[1]?.data?.visitManagerProfileImageUrl,
        });
        if (res[1]?.data?.status === 'FINISH') {
          navigator('/solution/success');
        } else {
          handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [handleChangeContractData, isLogIn, navigator]);

  return (
    <SolutionScheduleCompleteWrapper
      visitDate={visitDate}
      visitManagerName={visitManagerName}
      visitManagerPhone={visitManagerPhone}
      visitManagerProfileImageUrl={visitManagerProfileImageUrl}
    />
  )
}
