const mockdata = {
  accountMockdata: [
    {
      id: 1,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 2,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ -30,000,000 (컨텐츠 이용료)',
    },
    {
      id: 3,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 4,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 5,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 6,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 7,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 8,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 9,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 10,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 11,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 12,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 13,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 14,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 15,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 16,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
    {
      id: 17,
      date: '2020.00.00 00:00',
      state: '변경완료',
      history: '프리미엄 솔루션',
      account: '카드결제',
      sum: '₩ 10,000,000',
    },
  ],
  installmentMockdata: [
    {
      id: 1,
      date: '2020.00.00 00:00',
      state: '심사중',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 2,
      date: '2020.00.00 00:00',
      state: '할부취소',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 3,
      date: '2020.00.00 00:00',
      state: '심사완료',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 4,
      date: '2020.00.00 00:00',
      state: '심사중',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 5,
      date: '2020.00.00 00:00',
      state: '할부취소',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 6,
      date: '2020.00.00 00:00',
      state: '심사완료',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 7,
      date: '2020.00.00 00:00',
      state: '심사중',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 8,
      date: '2020.00.00 00:00',
      state: '할부취소',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 9,
      date: '2020.00.00 00:00',
      state: '심사완료',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 10,
      date: '2020.00.00 00:00',
      state: '심사중',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 11,
      date: '2020.00.00 00:00',
      state: '할부취소',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 12,
      date: '2020.00.00 00:00',
      state: '심사완료',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 13,
      date: '2020.00.00 00:00',
      state: '심사중',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 14,
      date: '2020.00.00 00:00',
      state: '할부취소',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
    {
      id: 15,
      date: '2020.00.00 00:00',
      state: '심사완료',
      history: 'N개월 무이자 할부',
      sum: '₩ 10,000,000',
    },
  ],
  faq: [
    {
      id: 1,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
    {
      id: 2,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
    {
      id: 3,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
    {
      id: 4,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
    {
      id: 5,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
    {
      id: 6,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
    {
      id: 7,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
    {
      id: 8,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
    {
      id: 9,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
    {
      id: 10,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
    {
      id: 11,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
    {
      id: 12,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
    {
      id: 13,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
    {
      id: 14,
      title: '빔몬스터 서비스는 무엇인가요?',
      content:
        '질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다. 질문에 대한 답변이 들어가는 부분입니다.',
    },
  ],
  asList: [
    {
      id: 1,
      date: '2020.00.00 00:00',
      state: '접수 완료',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 2,
      date: '2020.00.00 00:00',
      state: '담당자 배정',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 3,
      date: '2020.00.00 00:00',
      state: '접수 대기',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 4,
      date: '2020.00.00 00:00',
      state: '접수 완료',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 5,
      date: '2020.00.00 00:00',
      state: '담당자 배정',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 6,
      date: '2020.00.00 00:00',
      state: '접수 대기',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 7,
      date: '2020.00.00 00:00',
      state: '접수 완료',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 8,
      date: '2020.00.00 00:00',
      state: '담당자 배정',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 9,
      date: '2020.00.00 00:00',
      state: '접수 대기',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 10,
      date: '2020.00.00 00:00',
      state: '접수 완료',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 11,
      date: '2020.00.00 00:00',
      state: '담당자 배정',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 12,
      date: '2020.00.00 00:00',
      state: '접수 대기',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 13,
      date: '2020.00.00 00:00',
      state: '접수 완료',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 14,
      date: '2020.00.00 00:00',
      state: '담당자 배정',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
    {
      id: 15,
      date: '2020.00.00 00:00',
      state: '접수 대기',
      history:
        '상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. 상세 내용이 들어가는 부분입니다. ',
    },
  ],
  storeList: [
    {
      id: 1,
      sotreName: '강남씨티약국 역삼점',
      avg: '0.00만',
      max: '0.00만',
      address: '서울 강남구 강남대로 416 (역삼동) 1층',
      time: '09:00 ~ 20:00',
      closedDays: '월요일',
      situation: '광고 거절',
    },
    {
      id: 2,
      sotreName: '강남씨티약국 역삼점',
      avg: '0.00만',
      max: '0.00만',
      address: '서울 강남구 강남대로 416 (역삼동) 1층',
      time: '09:00 ~ 20:00',
      closedDays: '월요일',
      situation: '결제 대기',
    },
    {
      id: 3,
      sotreName: '강남씨티약국 역삼점',
      avg: '0.00만',
      max: '0.00만',
      address: '서울 강남구 강남대로 416 (역삼동) 1층',
      time: '09:00 ~ 20:00',
      closedDays: '월요일',
      situation: '결제 완료',
    },
    {
      id: 4,
      sotreName: '강남씨티약국 역삼점',
      avg: '0.00만',
      max: '0.00만',
      address: '서울 강남구 강남대로 416 (역삼동) 1층',
      time: '09:00 ~ 20:00',
      closedDays: '월요일',
      situation: '광고 진행 중',
    },
    {
      id: 5,
      sotreName: '강남씨티약국 역삼점',
      avg: '0.00만',
      max: '0.00만',
      address: '서울 강남구 강남대로 416 (역삼동) 1층',
      time: '09:00 ~ 20:00',
      closedDays: '월요일',
      situation: '광고 완료',
    },
    {
      id: 6,
      sotreName: '강남씨티약국 역삼점',
      avg: '0.00만',
      max: '0.00만',
      address: '서울 강남구 강남대로 416 (역삼동) 1층',
      time: '09:00 ~ 20:00',
      closedDays: '월요일',
      situation: '광고 거절',
    },
  ],
  paymentList: [
    {
      id: 1,
      impressions: 2000,
      totalImpressions: 200000,
      dosage: 3000,
      totalDosage: 300000,
      price: 300000,
    },
    {
      id: 2,
      impressions: 1000,
      totalImpressions: 100000,
      dosage: 4000,
      totalDosage: 400000,
      price: 300000,
    },
    {
      id: 3,
      impressions: 9000,
      totalImpressions: 400000,
      dosage: 7000,
      totalDosage: 700000,
      price: 300000,
    },
  ],
  artistList: [
    {
      id: 1,
      title: '피카소의 마지막 작품',
      name: '피카소',
      price: 100000,
      priceNumber: 1000,
      likeNumber: 123,
      content:
        '자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.',
      subContent:
        '간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.',
    },
    {
      id: 2,
      title: '디카프리오의 마지막 작품',
      name: '디카프리오',
      price: 90000,
      priceNumber: 10,
      likeNumber: 3,
      content:
        '자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.',
      subContent:
        '간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.',
    },
    {
      id: 3,
      title: '피카소의 마지막 작품',
      name: '피카소',
      price: 100000,
      priceNumber: 10,
      likeNumber: 3,
      content:
        '자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.',
      subContent:
        '간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.',
    },
    {
      id: 4,
      title: '라라라라의 마지막 작품',
      name: '라라라라',
      price: 104000,
      priceNumber: 10,
      likeNumber: 3,
      content:
        '자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.',
      subContent:
        '간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.',
    },
    {
      id: 5,
      title: '캬캬캬캬캬의 마지막 작품',
      name: '캬캬',
      price: 1000000,
      priceNumber: 10,
      likeNumber: 3,
      content:
        '자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.',
      subContent:
        '간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.',
    },
    {
      id: 6,
      title: '뀨뀨의 마지막 작품',
      name: '뀨뀨',
      price: 10000000,
      priceNumber: 10,
      likeNumber: 3,
      content:
        '자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.자세한 설명이 들어가는 부분입니다.',
      subContent:
        '간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.간력 설명이 들어가는 부분입니다.',
    },
  ],
};

export default mockdata;
