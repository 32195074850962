import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from 'apis';
import useEstimate from 'hooks/useEstimate';
import useContract from 'hooks/useContract';
import useAuth from 'hooks/useAuth';
import { handleCheckSolution } from 'libs/authService';
import SolutionPublishEstimatePaymentWrapper from 'components/SolutionPublishEstimatePaymentWrapper/SolutionPublishEstimatePaymentWrapper';

export default function SolutionPublishEstimatePaymentContainer() {
  const navigator = useNavigate();
  const { isLogIn } = useAuth();

  const { estimateId } = useEstimate();
  const { paymentType, handleChangeContractData } = useContract();

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([
          api.getMyEstimate().catch(() => {}),
          api.getMyContract().catch(() => {}),
        ]);
        handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [isLogIn, navigator]);

  // <NavLink to='/solution/rental-info' className={cx('next_button')}>다음 단계로 이동</NavLink>

  const handleSubmit = useCallback(
    async (data) => {
      try {
        if (!data.once && !data.rental) {
          alert('결제방식을 선택해주세요 : )');
          return;
        }
        if (data.once) {
          if (
            window.confirm(
              '이후 결제방식을 변경하시려면 관리자에게 문의해주세요.\n진행하시겠습니까?',
            )
          ) {
            await api.requestContract({
              estimate_id: estimateId,
              paymentType: 'ONCE',
            });

            handleChangeContractData({
              paymentType: 'ONCE',
            });
            window.location.replace('/solution/contract-waiting');
          }
        } else {
          await api.requestContract({
            estimate_id: estimateId,
            paymentType: 'RENTAL',
          });

          handleChangeContractData({
            paymentType: 'RENTAL',
          });
          navigator(`/solution/rental-info`);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [navigator, estimateId, handleChangeContractData],
  );

  return (
    <SolutionPublishEstimatePaymentWrapper
      paymentType={paymentType}
      onSubmit={handleSubmit}
    />
  );
}
