import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Play.module.scss';
import { NavLink } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function Play({ onClick, isFullScreen }) {
  return (
    <>
      <div id="video-container" className={cx('container')}></div>
      {!isFullScreen && (
        <div className={cx('box_btn')}>
          <button className={cx('btn_window')} onClick={onClick}>
            전체화면
          </button>
        </div>
      )}
    </>
  );
}
