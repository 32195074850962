import React, { useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './MypageScheduleEdit.module.scss';
import CalendarDate from './CalendarDate';

const cx = classNames.bind(styles);

export default function CalendarBody(props) {

    const { totalDate, today, month, year } = props;
    const lastDate = totalDate.indexOf(1);
    const firstDate = totalDate.indexOf(1, 7);

    //today
    const findToday = totalDate.indexOf(today);
    const getMonth = new Date().getMonth() + 1;

    return (
        <div className={cx('calendar_body_inner')}>
            {totalDate.map((el, i) => {
                return (
                <div
                    className={cx('calendar_body_box')}
                    key={i}
                    i={i}
                    lastDate={lastDate}
                    firstDate={firstDate}
                    el={el}
                    findToday={findToday === i && month === getMonth && findToday}
                    month={month}
                    year={year}
                ></div>
                );
            })}
        </div>
    )
}
