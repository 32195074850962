import React from 'react';
import {
  Header,
  Main,
  ContentMain,
  SubContent,
  TimeContent,
  ConceptContent,
  PigContent,
  MediaContent,
  FaqContent,
  SubFooter,
  Footer,
} from '../views';
import ExamplePopup from 'components/views/ExamplePopup';
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  FadeOut,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  StickyOut,
  Zoom,
  ZoomIn,
  ZoomOut,
} from 'react-scroll-motion';

export default function IndexWrapper({
  themes,
  selectedMainContent,
  usingExamples,
  onClick,
  selectedIndex,
  faqs,
  onExampleClick,
  selectedExample,
  isOpen,
  onClose,
  handleSolution,
  clickedIndex,
}) {
  return (
    <>
      <Header type="black" />
      <Main
        clickedIndex={clickedIndex}
        selectedIndex={selectedIndex}
        themes={themes}
        selectedMainContent={selectedMainContent}
        onClick={onClick}
        handleSolution={handleSolution}
      />
      {/* <ScrollContainer> */}
      <ContentMain />
      <SubContent />
      <TimeContent />
      <ConceptContent />
      <PigContent />
      <MediaContent
        usingExamples={usingExamples}
        onExampleClick={onExampleClick}
      />
      <FaqContent faqs={faqs} />
      <ExamplePopup data={selectedExample} isShow={isOpen} onClose={onClose} />
      <SubFooter handleSolution={handleSolution} />
      <Footer />
      {/* </ScrollContainer> */}
    </>
  );
}
