import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import qs from 'query-string';
import _ from 'lodash/fp';
import * as api from 'apis';
import useArtist from 'hooks/useArtist';
import PlayListArtistDetailWrapper from 'components/PlayListArtistDetailWrapper';

export default function PlayListArtistDetailContainer() {
  const navigator = useNavigate();
  const location = useLocation();
  const { id } = qs.parse(location.search);

  const [isInit, setIsInit] = useState(false);
  const [data, setData] = useState({});
  const [contents, setContents] = useState([]);
  const [pageData, setPageData] = useState({
    total: 0,
    currentCategory: '',
    currentPage: 1,
    lastPage: 0,
    perPage: 5,
    limit: 12,
    search: '',
    orderBy: '',
  });
  const [view, setView] = useState(false);
  const [myPlayList, setMyPlayList] = useState([]);
  const [selectedData, setSelectedData] = useState({});

  const { isUnlimitedPlaylist, checkAuthPlay } = useAuth();

  const {
    wishArtistPlayList,
    handleUpdateArtist,
    handleChangeArtist,
    handleMyArtistPlayList,
  } = useArtist();

  useEffect(() => {
    if (isUnlimitedPlaylist) return setIsInit(true);
    (async () => {
      try {
        const res = await Promise.all([
          api.getMyEstimate().catch(() => {}),
          api.getMyContract().catch(() => {}),
        ]);
        const estimate = res[0]?.data;
        const contract = res[1]?.data;
        if (estimate && estimate?.status === 'CONFIRM') {
          if (
            contract &&
            (contract?.status === 'FINISH' || contract?.status === 'SCHEDULED')
          ) {
            if (estimate?.solutionType === 'PREMIUM') {
              setIsInit(true);
            } else if (estimate?.solutionType === 'STANDARD') {
              setIsInit(true);
            } else if (estimate?.solutionType === 'BASIC') {
              setIsInit(true);
            } else {
              checkAuthPlay(estimate, contract);
              alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
              navigator('/');
            }
          } else {
            checkAuthPlay(estimate, contract);
            alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
            navigator('/');
          }
        } else {
          checkAuthPlay(estimate, contract);
          alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
          navigator('/');
        }
      } catch (err) {}
    })();
  }, [navigator, setIsInit, isUnlimitedPlaylist]);

  const handleSearch = async (currentPage, creator_id) => {
    try {
      const res = await api.getContentList({
        creator_id: creator_id ? creator_id : data?.creator?.id,
        isCreatorContent: true,
        page: currentPage - 1,
        limit: 12,
        search: pageData.search ? pageData.search : null,
        category: pageData.currentCategory ? pageData.currentCategory : null,
        orderBy: pageData.orderBy ? pageData.orderBy : null,
      });

      const { total, data } = res?.data;

      setPageData((prev) => ({
        ...prev,
        total,
        currentPage,
        lastPage: Math.ceil(total / 12) || 1,
      }));
      setContents(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePage = (currentPage) => {
    handleSearch(currentPage);
  };

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const res = await api.getContent(id);
        setData(res?.data);
        await handleSearch(1, res?.data?.creator?.id);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      try {
        const [res, _] = await Promise.all([
          handleMyArtistPlayList(),
          handleUpdateArtist(),
        ]);
        const playIds = [...res?.map((p) => p.id)];
        setMyPlayList(playIds);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleWishList = async (e, content) => {
    e.stopPropagation();
    try {
      const wishIds = [...wishArtistPlayList.map((w) => w.id)];
      let data = [];
      if (wishIds.indexOf(content.id) > -1) {
        data = [...wishArtistPlayList.filter((item) => item.id !== content.id)];
      } else {
        data = [...wishArtistPlayList, content];
      }

      handleChangeArtist({ wishArtistPlayList: data });
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdatePlayList = async (data) => {
    try {
      let wishIds = [...wishArtistPlayList.map((w) => w.id)].sort();
      const isDirect = wishIds.indexOf(data.id) === -1;
      if (isDirect) {
        wishIds.push(data.id);
      }
      const playIds = [...myPlayList.map((p) => p.id)].sort();
      const isSame = wishIds.every((el, i) => {
        return el === (playIds[i] || null);
      });

      if (wishIds.length === 0 || isSame) return;
      const newIds = wishIds.filter((el, i) => {
        return el !== (playIds[i] || null);
      });

      if (newIds.length === 0) return;

      if (
        window.confirm(
          newIds.length > 1
            ? `현재 작품을 포함한 아티스트 콘텐츠 ${newIds.length}개를 무료구매 하시겠습니까?`
            : '현재 아티스트 콘텐츠를 무료 구매하시겠습니까?',
        )
      ) {
        await api.postMyPlayList({
          content_ids: newIds,
        });
        alert('아티스트 콘텐츠 구매 완료 : )');
        handleChangeArtist({ wishArtistPlayList: [] });
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickDetail = (e, content) => {
    e.preventDefault();
    setSelectedData(content);
    setView(true);
  };

  const handlePopupClose = () => {
    setView(false);
  };

  const handleClickLike = async (e, contentId) => {
    e.stopPropagation();
    const tempData = _.cloneDeep(data);
    const contentData = _.cloneDeep(contents);
    const selected = contentData.filter((item) => item.id === contentId)[0];
    try {
      if (tempData.liked) {
        await api.cancelLikeContent(contentId);
        tempData.likeCount -= 1;
        selected.likeCount -= 1;
      } else {
        await api.likeContent(contentId);
        tempData.likeCount += 1;
        selected.likeCount += 1;
      }
      tempData.liked = !tempData.liked;
      selected.liked = !selected.liked;
      setData(tempData);
      setContents(contentData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickLikeOther = async (e, contentId) => {
    e.stopPropagation();
    const tempData = _.cloneDeep(contents);
    const selected = tempData.filter((item) => item.id === contentId)[0];
    try {
      if (selected?.liked) {
        await api.cancelLikeContent(contentId);
        selected.likeCount -= 1;
      } else {
        await api.likeContent(contentId);
        selected.likeCount += 1;
      }
      selected.liked = !selected.liked;
      setContents(tempData);
    } catch (err) {
      console.log(err);
    }
  };

  if (!isInit || !id) return <></>;
  return (
    <PlayListArtistDetailWrapper
      data={data}
      contents={contents}
      pageData={pageData}
      handlePage={handlePage}
      isShow={view}
      selectedData={selectedData}
      handleClickDetail={handleClickDetail}
      wishPlayList={wishArtistPlayList}
      handleWishList={handleWishList}
      handlePopupClose={handlePopupClose}
      handleClickLike={handleClickLike}
      handleClickLikeOther={handleClickLikeOther}
      myPlayList={myPlayList}
      isUnlimitedPlaylist={isUnlimitedPlaylist}
      handleUpdatePlayList={handleUpdatePlayList}
    />
  );
}
