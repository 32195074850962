import React from 'react';
import classNames from 'classnames/bind';
import styles from './AdvertisementReservationExpired.module.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function AdvertisementReservationExpired() {
  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <div className={cx('inner_box')}>
          <div className={cx('title_box')}>
            <p className={cx('title')}>예약 만료</p>
          </div>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/img-solution-deposit-ing.png"
              alt="예약 만료 이미지"
            />
          </div>
          <div className={cx('text_box')}>
            <p className={cx('text')}>
              요청하신 매체 중 “A 매체”가 이미 예약이 완료 되어 결제가 취소
              되었습니다.
            </p>
          </div>
          <div className={cx('button_box')}>
            {/* 결제 실패시 어디로 이동 되어야 할지 모르겠어서 일단 광고 문의로 이동하기로 해놈 / 작가콘텐츠도 있는뎁 ㅎㅅㅎ */}
            <Link to="/advertisement" className={cx('button')}>
              돌아가기
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
