import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import useEstimate from 'hooks/useEstimate';
import useContract from 'hooks/useContract';
import _ from 'lodash/fp';
import * as api from 'apis';
import PlayListWrapper from 'components/PlayListWrapper/PlayListWrapper';

export default function PlayListContainer() {
  const [isInit, setIsInit] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const [playList, setPlayList] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [view, setView] = useState(false);
  const [wishPlayList, setWishPlayList] = useState([]);
  const navigator = useNavigate();

  const { estimate } = useEstimate();

  const { contract } = useContract();

  const { playlistResetCount, isUnlimitedPlaylist, checkAuthPlay } = useAuth();

  const getGrade = () => {
    if (estimate && estimate.status === 'CONFIRM') {
      if (
        contract &&
        (contract.status === 'FINISH' || contract.status === 'SCHEDULED')
      ) {
        if (estimate.solutionType === 'PREMIUM') {
          return '프리미엄';
        } else if (estimate.solutionType === 'STANDARD') {
          return '스탠다드';
        } else if (estimate.solutionType === 'BASIC') {
          return '베이직';
        } else {
          return '';
        }
      }
    }

    return '';
  };

  const grade = getGrade();

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([
          api.getMainContents(false).catch(() => []),
          api.getMyPlayList(false).catch(() => []),
        ]);
        setData(res[0].data);
        const playList = res[1].data || [];
        const playIds = [...playList.map((p) => p.id)];
        setPlayList(playList || []);
        const tempWish = JSON.parse(localStorage.getItem('wishPlayList')) || [];
        const wishPlay = tempWish.filter((el) => playIds.indexOf(el.id) === -1);
        setWishPlayList([...playList]);
        setIsFetching(true);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [setData, setPlayList, setWishPlayList, setIsFetching]);

  useEffect(() => {
    if (isUnlimitedPlaylist) return setIsInit(true);
    (async () => {
      try {
        const res = await Promise.all([
          api.getMyEstimate().catch(() => {}),
          api.getMyContract().catch(() => {}),
        ]);
        const estimate = res[0]?.data;
        const contract = res[1]?.data;
        if (estimate && estimate?.status === 'CONFIRM') {
          if (
            contract &&
            (contract?.status === 'FINISH' || contract?.status === 'SCHEDULED')
          ) {
            if (estimate?.solutionType === 'PREMIUM') {
              setIsInit(true);
            } else if (estimate?.solutionType === 'STANDARD') {
              setIsInit(true);
            } else if (estimate?.solutionType === 'BASIC') {
              setIsInit(true);
            } else {
              checkAuthPlay(estimate, contract);
              alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
              navigator('/');
            }
          } else {
            checkAuthPlay(estimate, contract);
            alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
            navigator('/');
          }
        } else {
          checkAuthPlay(estimate, contract);
          alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
          navigator('/');
        }
      } catch (err) {}
    })();
  }, [navigator, setIsInit, isUnlimitedPlaylist]);

  const handleClickLike = async (e, listIndex, itemIndex, contentId) => {
    e.stopPropagation();
    const tempData = _.cloneDeep(data);
    try {
      if (tempData[listIndex].contents[itemIndex].liked) {
        await api.cancelLikeContent(contentId);
      } else {
        await api.likeContent(contentId);
      }
      tempData[listIndex].contents[itemIndex].liked =
        !tempData[listIndex].contents[itemIndex].liked;
      setData(tempData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickDetail = (e, content) => {
    e.preventDefault();
    setSelectedData(content);
    setView(true);
  };

  const handlePlayList = async (e, content) => {
    e.stopPropagation();
    try {
      const wishIds = [...wishPlayList.map((w) => w.id)];
      const playIds = [...playList.map((p) => p.id)].sort();
      if (
        wishIds.indexOf(content.id) > -1 &&
        playIds.indexOf(content.id) > -1
      ) {
        return;
      }

      let data = [];
      if (wishIds.indexOf(content.id) > -1) {
        data = [...wishPlayList.filter((item) => item.id !== content.id)];
        setWishPlayList(data);
      } else {
        if (grade === '스탠다드') {
          if (wishIds.length + 1 > 30) {
            alert('스탠다드 이용시 최대 30개까지 선택가능하십니다 : )');
            return;
          }
        } else if (grade === '베이직') {
          if (wishIds.length + 1 > 10) {
            alert('베이직 이용시 최대 10개까지 선택가능하십니다 : )');
            return;
          }
        }
        data = [...wishPlayList, content];
        setWishPlayList(data);
      }

      localStorage.setItem('wishPlayList', JSON.stringify(data));
    } catch (err) {
      console.log(err);
    }
  };

  const handlePopupClose = () => {
    setView(false);
  };

  const handleRemove = (id) => {
    if (window.confirm('선택된 영상 리스트에서 제외하시겠습니까?')) {
      const wishIds = [...wishPlayList.map((w) => w.id)];
      const playIds = [...playList.map((p) => p.id)].sort();
      if (wishIds.indexOf(id) > -1 && playIds.indexOf(id) > -1) {
        return;
      }

      const data = [...wishPlayList.filter((item) => item.id !== id)];
      localStorage.setItem('wishPlayList', JSON.stringify(data));
      setWishPlayList(data);
    }
  };

  const handleUpdatePlayList = async () => {
    try {
      const wishIds = [...wishPlayList.map((w) => w.id)].sort();
      const playIds = [...playList.map((p) => p.id)].sort();
      const isSame = wishIds.every((el, i) => {
        return el === (playIds[i] || null);
      });

      if (wishIds.length === 0 || isSame) return;
      const newIds = wishIds.filter((el, i) => {
        return el !== (playIds[i] || null);
      });
      if (newIds.length === 0) return;
      await api.postMyPlayList({
        content_ids: newIds,
      });
      alert('플레이리스트 보관 성공 : )');
      window.location.reload();
    } catch (err) {
      console.log(err);
      if (
        err?.response?.status === 400 &&
        err?.response?.data?.message === 'exceed playlist change count'
      ) {
        alert('남은 선택 횟수를 모두 사용하셨습니다.');
      } else {
        alert(
          '잠시 후 다시 시도해주시면 감사하겠습니다.\n오류 지속 시 관리자에게 문의하세요.',
        );
      }
    }
  };

  const handleResetPlayList = async () => {
    try {
      if (!isUnlimitedPlaylist) {
        if (grade === '베이직') {
          if (playlistResetCount >= 1) {
            alert('플레이리스트 리셋 횟수를 모두 사용하셨습니다. : )');
            return;
          }
        }

        if (grade === '스탠다드') {
          if (playlistResetCount >= 2) {
            alert('플레이리스트 리셋 횟수를 모두 사용하셨습니다. : )');
            return;
          }
        }
      }

      if (
        window.confirm(
          '플레이리스트 리셋 시 이후 변경이 어렵습니다.\n리셋하시겠습니까?',
        )
      ) {
        await api.patchMyPlayList({
          content_ids: [],
        });
        alert('플레이리스트 리셋 성공 : )');
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      if (
        err?.response?.status === 400 &&
        err?.response?.data?.message === 'exceed playlist change count'
      ) {
        alert('남은 선택 횟수를 모두 사용하셨습니다.');
      } else {
        alert(
          '잠시 후 다시 시도해주시면 감사하겠습니다.\n오류 지속 시 관리자에게 문의하세요.',
        );
      }
    }
  };

  if (!isInit) return <></>;

  return (
    <PlayListWrapper
      data={data}
      playList={playList}
      selectedData={selectedData}
      handleClick={handleClickDetail}
      handleClickLike={handleClickLike}
      handlePlayList={handlePlayList}
      isShow={view}
      handlePopupClose={handlePopupClose}
      grade={grade}
      wishPlayList={wishPlayList}
      handleRemove={handleRemove}
      handleUpdatePlayList={handleUpdatePlayList}
      playlistResetCount={playlistResetCount}
      handleResetPlayList={handleResetPlayList}
      isFetching={isFetching}
    />
  );
}
