import React, {
    useState,
    useEffect,
} from 'react'
import moment from 'moment';
import SearchAddress from 'components/common/SearchAddress';
import classNames from 'classnames/bind';
import styles from './SignupOtherProcess.module.scss';

const cx = classNames.bind(styles);

export default function SignupOtherProcess({
    name,
    phone,
    code,
    gender,
    birthday,
    isPhoneVerified,
    businessCategory,
    businessNumber,
    businessName,
    businessAddress,
    businessDetailAddress,
    businessCustomerAge,
    businessOpenTime,
    businessCloseTime,
    businessOpenMemo,
    businessBusyHour,
    businessMaxSeat,
    businessDailyVisitor,
    handleChange,
    handleSubmit,
    isOpenPost,
    handleOpenPost,
    handleClosePost,
    signupDisabled,
    isPhoneError,
    isBusinessError,
    isBusinessVerified,
    isPhoneExpired,
    isCodeError,
    handleCode,
    handleVerifiedPhone,
    isSendCode,
    startTime,
    endTime,
    handleEditPhone,
    handleClickAddress,
    handleCheckBusinessNumber,
    handleClickGender,
}) {
    const [leftTime, setLeftTime] = useState(null);

    useEffect(() => {
        if (!startTime || !endTime) return;
        const diff = moment.duration(moment(endTime).diff(moment(startTime)));
        const minutes = diff.minutes() >= 10 ? diff.minutes() : `0${diff.minutes()}`;
        const seconds = diff.seconds() >= 10 ? diff.seconds() : `0${diff.seconds()}`;
        setLeftTime(`${minutes}:${seconds}`);
    }, [startTime, endTime, setLeftTime]);

    return (
        <>
            <div className={cx('other_container')}>
                <div className={cx('other_sub_container')}>
                    <div className={cx('inner')}>
                        <div className={cx('inner_box')}>
                            <div className={cx('other_inner')}>
                                <div className={cx('step_box')}>
                                    <p className={cx('step_text')}>
                                        <b className={cx('step_text_b')}>3 </b>/ 3 단계
                                    </p>
                                </div>
                                <div className={cx('other_title_box')}>
                                    <h4 className={cx('title')}>
                                        마지막 단계에요!
                                    </h4>
                                </div>
                                <div className={cx('text_box')}>
                                    <p className={cx('text_b')}>상세한 정보로 완성되는 더 멋진 미디어 인테리어</p>
                                    <p className={cx('text')}>입력한 정보는 마이페이지에서 언제든지 수정 가능합니다.</p>
                                </div>
                                <div className={cx('other_box')}>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>이름</label>
                                        <input
                                            className={cx('all_input')}
                                            type="text"
                                            placeholder='이름을 입력'
                                            name="name"
                                            value={name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>연락처</label>
                                        <div className={cx('half_box')}>
                                            <input
                                                className={cx('one_third_input')}
                                                type="tel"
                                                placeholder='휴대폰 번호 입력(-는 제외)'
                                                name="phone"
                                                value={phone}
                                                onChange={handleChange}
                                            />
                                            <div className={cx('input_button_box')}>
                                                <button className={cx('input_button', { active: phone, success: isPhoneVerified })} disabled={!phone} onClick={handleVerifiedPhone}>{isPhoneVerified ? '완료' : '인증'}</button>
                                            </div>
                                        </div>
                                        {
                                            isPhoneVerified &&
                                            <button className={cx('edit_btn')} onClick={handleEditPhone}></button>
                                        }
                                        {
                                            isPhoneError &&
                                            <div className={cx('other_fail_box')}>
                                                <img className={cx('other_fail_img')} src="/statics/images/ic_input_info_error.png" alt="info_check" />
                                                <p className={cx('other_fail_text')}>형식에 맞게 입력해주세요.</p>
                                            </div>
                                        }
                                        {
                                            isCodeError &&
                                            <div className={cx('other_fail_box')}>
                                                <img className={cx('other_fail_img')} src="/statics/images/ic_input_info_error.png" alt="info_check" />
                                                <p className={cx('other_fail_text')}>인증번호가 유효하지않습니다.</p>
                                            </div>
                                        }
                                        {
                                            !isCodeError && isPhoneExpired &&
                                            <div className={cx('other_fail_box')}>
                                                <img className={cx('other_fail_img')} src="/statics/images/ic_input_info_error.png" alt="info_check" />
                                                <p className={cx('other_fail_text')}>제한 시간이 초과되었습니다.</p>
                                            </div>
                                        }
                                        {
                                            !isPhoneVerified && isSendCode &&
                                            (
                                                <>
                                                    <div className={cx('half_box', 'code_box')}>
                                                        <input
                                                            className={cx('one_third_input')}
                                                            type="text"
                                                            placeholder='인증번호'
                                                            name="code"
                                                            value={code}
                                                            onChange={handleChange}
                                                        />
                                                        <div className={cx('input_button_box')}>
                                                            <button className={cx('input_button', { active: code })} disabled={!code} onClick={handleCode}>확인</button>
                                                        </div>
                                                    </div>
                                                    {
                                                        !isCodeError && (startTime <= endTime) &&
                                                        <p className={cx('txt_error')}>남은시간 {leftTime}</p>
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>생년월일</label>
                                        <div className={cx('birth_half_box')}>
                                            <input className={cx('half_input')} type="text" name="birthday" value={birthday} onChange={handleChange} placeholder='0000-00-00' />
                                            <div className={cx('half_button_box')}>
                                                <input className={cx('other_checkbox')} type="radio" name="gener" id="man" />
                                                <input className={cx('other_checkbox')} type="radio" name="gener" id="woman" />
                                                <label
                                                    onClick={() => handleClickGender('MALE')}
                                                    className={cx({ half_button_active: gender === 'MALE', half_button: gender !== 'MALE' })}
                                                    htmlFor="man">
                                                    남자
                                                </label>
                                                <label
                                                    onClick={() => handleClickGender('FEMALE')}
                                                    className={cx({ half_button_active: gender === 'FEMALE', half_button: gender !== 'FEMALE' })}
                                                    htmlFor="woman">
                                                    여자
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className={cx('other_hr')}></div>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>사업자 업종</label>
                                        <input
                                            className={cx('all_input')}
                                            type="text"
                                            placeholder='업종 입력'
                                            name="businessCategory"
                                            value={businessCategory}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>사업자 번호</label>
                                        <div className={cx('half_box')}>
                                            <input
                                                className={cx('one_third_input')}
                                                type="text"
                                                placeholder='서비스 설치 사업자 번호 입력'
                                                name="businessNumber"
                                                value={businessNumber}
                                                onChange={handleChange}
                                            />
                                            <div className={cx('input_button_box')}>
                                                <button className={cx('input_button', { active: businessNumber, success: isBusinessVerified })} disabled={!businessNumber} onClick={handleCheckBusinessNumber}>{isBusinessVerified ? '완료' : '인증'}</button>
                                            </div>
                                        </div>
                                        {
                                            isBusinessError &&
                                            <div className={cx('other_fail_box')}>
                                                <img className={cx('other_fail_img')} src="/statics/images/ic_input_info_error.png" alt="info_check" />
                                                <p className={cx('other_fail_text')}>사업자번호가 유효하지않습니다.</p>
                                            </div>
                                        }
                                    </div>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>사업장 상호 또는 브랜드명</label>
                                        <input
                                            className={cx('all_input')}
                                            type="text"
                                            placeholder='직접 입력'
                                            name="businessName"
                                            value={businessName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>사업장 주소지</label>
                                        <div className={cx('half_box')}>
                                            <input
                                                className={cx('one_third_input')}
                                                type="text"
                                                placeholder='사업장 주소지 입력'
                                                name="businessAddress"
                                                value={businessAddress}
                                                readOnly
                                            />
                                            <div className={cx('input_button_box')}>
                                                <button className={cx('input_button', 'active')} onClick={handleOpenPost}>{businessAddress ? '수정' : '검색'}</button>
                                            </div>
                                        </div>
                                        {
                                            !businessAddress &&
                                            <button className={cx('btn_search')} onClick={handleOpenPost}></button>
                                        }
                                        <div className={cx('bottom_input_box')}>
                                            <input
                                                className={cx('all_input')}
                                                type="text"
                                                placeholder='상세 주소 입력'
                                                name="businessDetailAddress"
                                                value={businessDetailAddress}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={cx('other_hr')}></div>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>고객 연령대</label>
                                        <select
                                            className={cx('select_box')}
                                            style={{ backgroundImage: 'url(/statics/images/ic_expand_more.png)' }}
                                            name="businessCustomerAge"
                                            value={businessCustomerAge}
                                            onChange={handleChange}
                                        >
                                            <option value=''>선택하기</option>
                                            <option value='10대'>10대</option>
                                            <option value='20대'>20대</option>
                                            <option value='30대'>30대</option>
                                            <option value='40대'>40대</option>
                                            <option value='50대'>50대</option>
                                            <option value='60대'>60대</option>
                                        </select>
                                    </div>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>매장 오픈시간</label>
                                        <select
                                            className={cx('select_box')}
                                            style={{ backgroundImage: 'url(/statics/images/ic_expand_more.png)' }}
                                            name="businessOpenTime"
                                            value={businessOpenTime}
                                            onChange={handleChange}
                                        >
                                            <option value=''>선택하기</option>
                                            <option value='00:00'>00:00</option>
                                            <option value='01:00'>01:00</option>
                                            <option value='02:00'>02:00</option>
                                            <option value='03:00'>03:00</option>
                                            <option value='04:00'>04:00</option>
                                            <option value='05:00'>05:00</option>
                                            <option value='06:00'>06:00</option>
                                            <option value='07:00'>07:00</option>
                                            <option value='08:00'>08:00</option>
                                            <option value='09:00'>09:00</option>
                                            <option value='10:00'>10:00</option>
                                            <option value='11:00'>11:00</option>
                                            <option value='12:00'>12:00</option>
                                            <option value='13:00'>13:00</option>
                                            <option value='14:00'>14:00</option>
                                            <option value='15:00'>15:00</option>
                                            <option value='16:00'>16:00</option>
                                            <option value='17:00'>17:00</option>
                                            <option value='18:00'>18:00</option>
                                            <option value='19:00'>19:00</option>
                                            <option value='20:00'>20:00</option>
                                            <option value='21:00'>21:00</option>
                                            <option value='22:00'>22:00</option>
                                            <option value='23:00'>23:00</option>
                                        </select>
                                    </div>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>매장 마감시간</label>
                                        <select
                                            className={cx('select_box')}
                                            style={{ backgroundImage: 'url(/statics/images/ic_expand_more.png)' }}
                                            name="businessCloseTime"
                                            value={businessCloseTime}
                                            onChange={handleChange}
                                        >
                                            <option value=''>선택하기</option>
                                            <option value='00:00'>00:00</option>
                                            <option value='01:00'>01:00</option>
                                            <option value='02:00'>02:00</option>
                                            <option value='03:00'>03:00</option>
                                            <option value='04:00'>04:00</option>
                                            <option value='05:00'>05:00</option>
                                            <option value='06:00'>06:00</option>
                                            <option value='07:00'>07:00</option>
                                            <option value='08:00'>08:00</option>
                                            <option value='09:00'>09:00</option>
                                            <option value='10:00'>10:00</option>
                                            <option value='11:00'>11:00</option>
                                            <option value='12:00'>12:00</option>
                                            <option value='13:00'>13:00</option>
                                            <option value='14:00'>14:00</option>
                                            <option value='15:00'>15:00</option>
                                            <option value='16:00'>16:00</option>
                                            <option value='17:00'>17:00</option>
                                            <option value='18:00'>18:00</option>
                                            <option value='19:00'>19:00</option>
                                            <option value='20:00'>20:00</option>
                                            <option value='21:00'>21:00</option>
                                            <option value='22:00'>22:00</option>
                                            <option value='23:00'>23:00</option>
                                        </select>
                                    </div>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>고객 최대 방문 시간대</label>
                                        <input
                                            className={cx('all_input')}
                                            type="text"
                                            placeholder='09:00 ~ 18:00'
                                            name="businessBusyHour"
                                            value={businessBusyHour}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>고객 수용인원</label>
                                        <input
                                            className={cx('all_input')}
                                            type="number"
                                            placeholder='50'
                                            name="businessMaxSeat"
                                            value={businessMaxSeat}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>고객 평균 방문자</label>
                                        <input
                                            className={cx('all_input')}
                                            type="number"
                                            placeholder='150'
                                            name="businessDailyVisitor"
                                            value={businessDailyVisitor}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className={cx('other_input_box')}>
                                        <label className={cx('input_label')}>매장 관련 비고사항</label>
                                        <input
                                            className={cx('all_input')}
                                            type="text"
                                            placeholder='주말은 휴무입니다 : )'
                                            name="businessOpenMemo"
                                            value={businessOpenMemo}
                                            onChange={handleChange}
                                        />
                                    </div> */}
                                </div>
                                <div className={cx('button_box')}>
                                    <button className={cx('button', { disabled: signupDisabled })} disabled={signupDisabled} type="button" onClick={handleSubmit}>계정 생성</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <SearchAddress
                isOpenPost={isOpenPost}
                handleOpenPost={handleOpenPost}
                handleClosePost={handleClosePost}
                handleClickAddress={handleClickAddress}
            />
        </>
    )
}
