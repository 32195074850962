import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Popup.module.scss';
import FirstCheckList from './FirstCheckList';
import SecondCheckList from './SecondCheckList';
import ThirdCheckList from './ThirdCheckList';
import FourCheckList from './FourCheckList';
import FiveCheckList from './FiveCheckList';
import SixCheckList from './SixCheckList';
import SevenCheckList from './SevenCheckList';
import EightCheckList from './EightCheckList';

const cx = classNames.bind(styles);

export default function Popup(props) {

    const [page, setPage] = useState(0);
    const title = [
        '구매 전 반드시 체크리스트를 꼼꼼히 검토해주세요 :)',
        '설치 현장의 밝기를 확인하세요!',
        '업종 별 적정 밝기를 확인하세요!',
        '설치 벽면의 상태를 점검하세요!',
        '빔프로젝터 설치 벽면의 상태를 점검하세요!',
        'LED 설치 벽면의 상태를 점검하세요!',
        '빔프로젝터 설치 위치를 확보해주세요!',
        '서버 PC 설치 위치를 확보해주세요!',
    ]

    const pageDisplay = () => {
        if (page === 0) {
            return <FirstCheckList />
        }
        else if (page === 1) {
            return <SecondCheckList />
        }
        else if (page === 2) {
            return <ThirdCheckList />
        }
        else if (page === 3) {
            return <FourCheckList />
        }
        else if (page === 4) {
            return <FiveCheckList />
        }
        else if (page === 5) {
            return <SixCheckList />
        }
        else if (page === 6) {
            return <SevenCheckList />
        }
        else if (page === 7) {
            return <EightCheckList />
        }
    }

    return (props.trigger) ? (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <div className={cx('inner_box')}>
                        <div className={cx('header')}>
                            <div className={cx('title_box')}>
                                <h4 className={cx('title')}>
                                    {title[page]}
                                </h4>
                            </div>
                            <div onClick={() => props.setTrigger(false)} className={cx('close_button_box')}>
                                <img src="/statics/images/ic_pop_close.png" alt="ic_pop_close" />
                            </div>
                        </div>
                        <div className={cx('content_inner')}>
                            {pageDisplay()}
                            <div className={cx('button_inner')}>
                                <button disabled={page === 0} onClick={() => { setPage((currentPage) => currentPage - 1) }} className={cx('prev_button')}>이전</button>
                                <div className={cx('step_number_box')}>
                                    <p className={cx('step_number')}>
                                        <b className={cx('step_number_b')}>{page + 1}</b> / 8
                                    </p>
                                </div>
                                <button disabled={page === title.length - 1} onClick={() => { setPage((currentPage) => currentPage + 1) }} className={cx('next_button')}>다음</button>
                                <div onClick={() => props.setTrigger(false)} className={cx('skip_button_box')}>
                                    <p className={cx('skip_button')}>Skip &gt;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : '';
}
