import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import useEstimate from 'hooks/useEstimate';
import useContract from 'hooks/useContract';
import * as api from 'apis';

export default function useCancel() {
  const navigator = useNavigate();
  const { handleResetData } = useEstimate();
  const { handleResetContractData } = useContract();

  const {
    isLogIn,
    estimate,
  } = useAuth();

  const cancelSolutionApply = async (id) => {
    try {
      if (!isLogIn || !estimate?.id) return;
      if (window.confirm('솔루션 구매를 취소하사겠습니까?\n솔루션 구매 취소시 현재까지 진행하신 모든 사항은 모두 초기화 됩니다.\n정말 취소하시겠습니까?')) {
        const res = await api.cancelSolutionApply(estimate.id);
        handleResetData();
        handleResetContractData();
        alert('솔루션 구매 취소가 완료되었습니다.\n솔루션에서 다시 진행가능하십니다. : )')
        window.scrollTo(0, 0);
        navigator('/');
      }
    } catch (err) {

    }
  }

  return {
    cancelSolutionApply,
  };
}