import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from 'apis';
import qs from 'query-string';
import usePlayList from 'hooks/usePlayList';
import PlayWrapper from 'components/PlayWrapper';
import { useLocation } from 'react-router-dom';
import BeamMonsterLoader from 'components/common/BeamMonsterLoader';
import AdvertisementPlay from 'components/views/AdvertisementPlay';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import _ from 'lodash/fp';
import $ from 'jquery';

var ADVERTISE_TYPE = {
  A: 'a',
  B: 'b',
  C: 'c',
  D: 'd',
  E: 'e',
  F: 'f',
};
var realPlayList;
var realPlayDuration = 0;
var leftDuration = 0;
var isLoop = false;
var isOnlyOne = false;
var isPreparing = false;
var isNeedEnd = false;
var isVideoLengthPlay = false;
var containerPlayIndex = 0;
var spareTime = 3;
var VIDEO_BLOB = {};
var USE_CONVERT = false;

async function convertBlob(id, url) {
  try {
    const blob = await fetch(url).then(r => r.blob());
    // console.log('blob111 ', blob);
    const buf = await blob.arrayBuffer();
    // console.log('blo222222322 ', buf);
    // console.log('buf123123 ', buf);
    const blobUrl = await window.URL.createObjectURL(new Blob([buf]));
    // console.log('blobUrl33333 ', blobUrl);
    VIDEO_BLOB[id] = blobUrl;
  } catch (err) {
    console.log(err);
  }
}

var HIGH_VIMEO_LIST = [
  {
    name: 'high_1',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101746/rendition/2160p/file.mp4?loc=external&signature=eec36539cc136cbb254b6985d3ac8a7be4bff1fc2b729e14de367639f9a2afbc',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101746/rendition/1440p/file.mp4?loc=external&signature=0a16804d1992d207886e24215624a51f5554140281570a1a2afd1ee7b0bb35e4',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101746/rendition/1080p/file.mp4?loc=external&signature=ce51633bd229f6d12d97c574b960b57ab05479768987021c4549b892f2d73165',
    },
  },
  {
    name: 'high_2',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101874/rendition/2160p/file.mp4?loc=external&signature=36c37a3779ea0637e921f8b0c4b1035a57a7bd6f4922bbf4e9f4081506a2ce37',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101874/rendition/1440p/file.mp4?loc=external&signature=bb04785791668add708f3e8bd167668473b102179fe7310a7f10017aa762c6de',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101874/rendition/1080p/file.mp4?loc=external&signature=29f9834a1cae540068c72894c59a1a43f19c5b53cd634afc464cbaa825ead286',
    },
  },
  {
    name: 'high_3',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101920/rendition/2160p/file.mp4?loc=external&signature=9083efffe58029fee3812d68229ae5837922ec8f9e9b0acd019819225ee7a0cd',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101920/rendition/1440p/file.mp4?loc=external&signature=8a9cc36936172efd9bcc05e4379a9c16b9654f88e8f7ac99d26e908cdda25362',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101920/rendition/1080p/file.mp4?loc=external&signature=fac21bfafb03270d6921192e8d1534d2c70db766f63baab1282cffbd67eaf8c4',
    },
  },
  {
    name: 'high_4',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101998/rendition/2160p/file.mp4?loc=external&signature=a25d198474e4c239813be75617fb868e3d28e8eefb7001181c425af9116ccc6c',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101998/rendition/1440p/file.mp4?loc=external&signature=86e74bee8752d92147e3e58b1e2db321723de9b5d2ef4775e759cb808fb848c5',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101998/rendition/1080p/file.mp4?loc=external&signature=51e519a756c38a82f596c0b6c30e4a1f9cce547d3174a74d969d12e26b3092e6',
    },
  },
  {
    name: 'high_5',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837102053/rendition/2160p/file.mp4?loc=external&signature=b59949489e2df969d0daa82579cf1070d54d30961a8e7f4e2ec1f5a0c0aa5531',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837102053/rendition/1440p/file.mp4?loc=external&signature=4afbf47bfc59e59b5b38a1f9aafe3efb899181aa83b8e1997332298dcb4533b7',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837102053/rendition/1080p/file.mp4?loc=external&signature=fd94d9d7138602c454f20ae1dfe937641b9ee7f3ad5d7962e6bb2845ae2d6393',
    },
  },
  {
    name: 'high_6',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837102112/rendition/2160p/file.mp4?loc=external&signature=f333049bbba0cb448faa8b38d10dd33251cc42cc5c3f390150c2cd38d7741847',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837102112/rendition/1440p/file.mp4?loc=external&signature=c856db1bd24511e3f26f4f4ddfc00e8a3898258a24de91d853acf925f9ae2334',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837102112/rendition/1080p/file.mp4?loc=external&signature=16d51de5071be501f1a58c2b11cd85a5b6408823640ac3471698a8732d981b6a',
    },
  },
  {
    name: 'high_7',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837102152/rendition/2160p/file.mp4?loc=external&signature=0e9c086e4f9ecca3a85db481455dd86d2537d2a48063d6480274f3a4c73bd931',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837102152/rendition/1440p/file.mp4?loc=external&signature=cb1beb9310036e829fc72c5a0f6f8e592b5321edf66859909fcde4ae3cda6f29',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837102152/rendition/1080p/file.mp4?loc=external&signature=322e79f05fe4b5a1a21fa48a7a9674479a031c76f09b2b5aa2df895909653dcc',
    },
  },
  {
    name: 'high_8',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837102176/rendition/2160p/file.mp4?loc=external&signature=f7c0e8045eab5d4fc42bc68d28ecce5fed49e23be69b761c83ca90f6b3a874d1',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837102176/rendition/1440p/file.mp4?loc=external&signature=1a9489e10237edfe63863d1cef9e1476824520e4665857978dc03c9da89a29d9',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837102176/rendition/1080p/file.mp4?loc=external&signature=f77db2ce91a59504d8791fb5923f13ba111862d0627fe5d3c243c025f4d3a1ad',
    }
  },
  {
    name: 'high_9',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837102220/rendition/2160p/file.mp4?loc=external&signature=6be158bcf31cf33d985519f8f9be18c8964bb27d844e1fafaf4e136eb10164b6',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837102220/rendition/1440p/file.mp4?loc=external&signature=deb846c3f0fd0f22cb6bca410b173abcc39726ebe24696b245791d45e7c312ee',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837102220/rendition/1080p/file.mp4?loc=external&signature=5204372ced6a5909347b37c6b7015e74b3cad786b7df71913337d18368346301',
    },
  },
  {
    name: 'high_10',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837102250/rendition/2160p/file.mp4?loc=external&signature=5db51504e9afdc14b58f6901f820c623cafe8828e23daaa546462727e3c3e1b0',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837102250/rendition/1440p/file.mp4?loc=external&signature=0e7fd186ad77f0538df503579dcdea80dc1093a9ec310c54737d99e04102462b',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837102250/rendition/1080p/file.mp4?loc=external&signature=78c5e6233660571988319e887987e4a19aba05b2aa37ba2ced1ec74e9133ad66',
    },
  },
  {
    name: 'high_11',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837102276/rendition/2160p/file.mp4?loc=external&signature=a06a2e52c5b328718d737fb4c7fe7412f42c2d72f2c2937b3be1af8c310ad50e',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837102276/rendition/1440p/file.mp4?loc=external&signature=85bc7b3e6683746f096c48c8300e8d6c2c20765c9af317fb014827c2b32ec4a8',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837102276/rendition/1080p/file.mp4?loc=external&signature=702997daaab77cc164fc958a8af000b3279318987a97d64981d9589f9e508f55',
    },
  },
  {
    name: 'high_12',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837102297/rendition/2160p/file.mp4?loc=external&signature=6c3ef364ba8fc03d9ad16661c8ffde38f0ece7294d2f08a7926d33d0cd98061a',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837102297/rendition/1440p/file.mp4?loc=external&signature=d91b14db8d4ea1e4ee2208b70cbe51a0749c000347c2e824e99e2c35f8039399',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837102297/rendition/1080p/file.mp4?loc=external&signature=789b30e7cc695d17bdf36015cdb7807e3685ba6cb4727082d9e835b4d854fc16',
    },
  },
  {
    name: 'high_13',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837102328/rendition/2160p/file.mp4?loc=external&signature=9aa3798cfff3cbb9f269fb2c36aace24d4edae1b2b111b51cd5b5c1009f293a7',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837102328/rendition/1440p/file.mp4?loc=external&signature=dcc83442f0551c9356377f1a50be003eedc6ecf8b73c356fb2750ae409d46196',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837102328/rendition/1080p/file.mp4?loc=external&signature=a63620453b71b82defb386ac56471a0976b3c7c79728fc0e55b61646ef7fe6dc',
    },
  },
  {
    name: 'high_14',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837102360/rendition/2160p/file.mp4?loc=external&signature=1c5cdbe77b3ebc6df0d6993ddd8dd7e670120d8aeecf0a855e744d4566367def',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837102360/rendition/1440p/file.mp4?loc=external&signature=5d35ca839341f98a7e6eec468a639590f30fd1c6cfd3f71135222211d950441f',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837102360/rendition/1080p/file.mp4?loc=external&signature=fd154abd9a9e3f310cee8eddb7161de3f82421650e12e448070151fef04a40f6',
    },
  },
  {
    name: 'high_15',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837102368/rendition/2160p/file.mp4?loc=external&signature=05143032aaa844b98e4c6031d938555627fcc10b09a016b5e5bf153676921b87',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837102368/rendition/1440p/file.mp4?loc=external&signature=d4bc613c32ce271c625ef7e9e79fb4914fd7158d7fbac555aa939e6e1b58058c',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837102368/rendition/1080p/file.mp4?loc=external&signature=dce0a4f49e5af8c318068783ba7b7ce278414a7d145602ae81cf98bda713093c',
    },
  },
]

var LOW_VIMEO_LIST = [
  {
    name: 'low_1',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101242/rendition/2160p/file.mp4?loc=external&signature=c949a470317f6309468830d3e18b4b81a38539dabaad67fffee3b59fb74996d7',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101242/rendition/1440p/file.mp4?loc=external&signature=7aab0da28e9dd1e3001531b8f0e1f6ea911ff7cacfd1a333f2e41234105dfb81',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101242/rendition/1080p/file.mp4?loc=external&signature=6bb11c900fd299fa148dc8a72b69dc526c00eac2c57159573053d0176166ab06',
    },
  },
  {
    name: 'low_2',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101275/rendition/2160p/file.mp4?loc=external&signature=5a66c8943af5dad1c261d9b1dd70c06b68aceeb70defdf9259a56dae6c2dc3f7',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101275/rendition/1440p/file.mp4?loc=external&signature=4a96800749ecb4a504e08c5ec4c219b189e3197c5aa31dfcd5bb824571447b72',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101275/rendition/1080p/file.mp4?loc=external&signature=ddde7be1c1b52f218690caedcdc9eac6302eaa45274626cca90c0831b79c6d26',
    },
  },
  {
    name: 'low_3',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101341/rendition/2160p/file.mp4?loc=external&signature=10f83035b82a7bb739e07ad8692efbb9965365569240c3a8956c97c469480951',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101341/rendition/1440p/file.mp4?loc=external&signature=d0b9326589775e91af6bfbef025a6eb1ee82e642cc93cf57acd27ab8da75d9d8',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101341/rendition/1080p/file.mp4?loc=external&signature=cbe9b758258d067af2b4ef5ef37efa68e59fd36f41c41b03764190da5821d96b',
    },
  },
  {
    name: 'low_4',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101383/rendition/2160p/file.mp4?loc=external&signature=04feacc61e8ef2f65ce97f70751302fab649dba0b28f30a21d5291a0f364d266',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101383/rendition/1440p/file.mp4?loc=external&signature=a4539a36eb1529fbdec0ec35f3a488addff5139a6f358991d0152566d6c26f0f',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101383/rendition/1080p/file.mp4?loc=external&signature=9f4e15bef876b95af8510e67845920bd6781a594d2a318af680677ee228356b7',
    },
  },
  {
    name: 'low_5',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101402/rendition/2160p/file.mp4?loc=external&signature=bc846104bdc30415dc194ffbd4e2dce46b77a054fbca88d3fca59aedb98f085f',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101402/rendition/1440p/file.mp4?loc=external&signature=5d6626c1b89a54600cb93d4aad2b4af52292e9419efd4ba62fca97a538b14b5d',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101402/rendition/1080p/file.mp4?loc=external&signature=ca96a7c121d882be817c8b5bc6bc1411d8773180ac201005fb7d2fee41f4b80e',
    },
  },
  {
    name: 'low_6',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101440/rendition/2160p/file.mp4?loc=external&signature=495a5a5c461b01b74ac5493303f8b768afdea05d21059ab4fec70470b361fff8',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101440/rendition/1440p/file.mp4?loc=external&signature=77c6c5ede597c5a21f3a8238645953724ac783dd7d08a3d31111742876ccbc22',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101440/rendition/1080p/file.mp4?loc=external&signature=798986db2ecf6f8c1fd4685fc6780c28253385b2258a42ce122562921c96f23f',
    },
  },
  {
    name: 'low_7',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101489/rendition/2160p/file.mp4?loc=external&signature=0b100f8a9e61aac3fe5ce4a6429af2af6429d6685b98e5fae290aa8fc5fbf1cf',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101489/rendition/1440p/file.mp4?loc=external&signature=21a5a4bb783e5ea2929e8d07a9455a2cae631ff9d601bee1c0b02048619c7efc',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101489/rendition/1080p/file.mp4?loc=external&signature=6fadff07abf67de9167b2e76d6afc22ea0a2d0eb536f77030551208f63e9d19f',
    },
  },
  {
    name: 'low_8',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101519/rendition/2160p/file.mp4?loc=external&signature=a1b58c4fdfca050a230a90f47f4d6b6ac7df7130889a634679eea6207d752445',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101519/rendition/1440p/file.mp4?loc=external&signature=8180bfc9314d87d8a586e44aca2f9767e9125c7814bc0a2cef04101848b4e82d',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101519/rendition/1080p/file.mp4?loc=external&signature=7ecf0ee8b7dd59a14ffd0e09b78e3c4352ac55d708b56af3beb9e0a3131e3ce4',
    }
  },
  {
    name: 'low_9',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101552/rendition/2160p/file.mp4?loc=external&signature=8d36d62207e6f0efaa4d44cf6518b4c387c26b6f7f2dc3ee0b5dc2e2f88ea3e1',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101552/rendition/1440p/file.mp4?loc=external&signature=31d0c6ae9ac669959f6845c5df6e4b1623289bc9de9091169b8ddb7603558253',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101552/rendition/1080p/file.mp4?loc=external&signature=baffdf908235515342005a97916d8d7da76844dfc74cecdf3b3be84350d8c30f',
    },
  },
  {
    name: 'low_10',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101591/rendition/2160p/file.mp4?loc=external&signature=574c314c6091b6483dd3d4aee9f6502323e2c14125d60a48dd1250ff52e8da53',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101591/rendition/1440p/file.mp4?loc=external&signature=b1da507876ca8cf83771250aa556510d7b2d3c3d0724b886c95d8452ed4088d2',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101591/rendition/1080p/file.mp4?loc=external&signature=c08a3c90f6a9f3e04f3eb717921187fd47f3c2235913168e72f441157cdd48d5',
    },
  },
  {
    name: 'low_11',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101632/rendition/2160p/file.mp4?loc=external&signature=b4d5faf7b6ad7ae1028d05519db2f27530942c3551ab8e50d918bdb67bd3fed4',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101632/rendition/1440p/file.mp4?loc=external&signature=5f3f28fb78ec0a5b2a8f005cf984443e8d340d83ab2f277dd4ed3eff8d46b931',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101632/rendition/1080p/file.mp4?loc=external&signature=6587f14dbbddd6e986bf8325899dfb4134a5d0df4bb28f07e9d44cbe6915162d',
    },
  },
  {
    name: 'low_12',
    videoUrl: {
      '4k': 'https://player.vimeo.com/progressive_redirect/playback/837101649/rendition/2160p/file.mp4?loc=external&signature=0594152e042af038a24b033c1c6e24f7bd56f449e0ca4d10acae7e228287fdbf',
      '2k': 'https://player.vimeo.com/progressive_redirect/playback/837101649/rendition/1440p/file.mp4?loc=external&signature=b48d452b7e3bed5d5f0766e2423d4bb2c8c9fcc7812c6c1ddcec1be64b9e7e62',
      '1080p': 'https://player.vimeo.com/progressive_redirect/playback/837101649/rendition/1080p/file.mp4?loc=external&signature=e6f04b8a882d7aeac739d735cc27c9cfa386cb4047fb5776ec211ffaa4ebb722',
    },
  },
]

export default function PlayContainer() {
  const navigator = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isInit, setIsInit] = useState(false);
  const [videoType, setVideoType] = useState('4k');
  const [videoMode, setVideoMode] = useState('low');
  const [playInfo, setPlayInfo] = useState({
    playType: 'Ordering',
    playList: [],
    shufflePlayList: [],
    playId: null,
    playStart: true, // 플레이중
    playLoop: false, // 반복
    playMute: true, // 음소거
    playVolume: 100,
    currentPlayTime: null,
    selectedPlay: null,
    playDuration: '300', // 5분 Default
    realPlayList: [],
  });
  // const {
  //     playList,
  //     playLoop,
  //     playMute,
  //     playStart,
  //     playVolume,
  //     handleChangePlayData,
  //     currentPlayTime,
  // } = usePlayList();

  const [needRefetch, setNeedRefetch] = useState(true);
  const [advertiseInterval, setAdvertiseInterval] = useState(10);
  const [currentAdvertise, setCurrentAdvertise] = useState();
  const [today, setToday] = useState();
  const [advertises, setAdvertises] = useState([]);
  const [logs, setLogs] = useState({});

  const playThrottle = useRef(null);

  const checkUserRef = useRef(null);

  const timeRef = useRef(null);

  const loginUserRef = useRef(null);

  const location = useLocation();
  const { videoQuality, videoMethod } = qs.parse(location.search);

  const {
    handleResetAuthData,
  } = useAuth();

  const makeVideo = (videoUrl, index, isShow = false) => {
    var video = document.createElement('video');
    video.setAttribute('id', `video-${index}`);
    video.setAttribute('data-index', `${index}`);
    video.style.width = '100vw';
    video.style.height = '100vh';
    video.loop = true;
    if (!isShow) {
      video.setAttribute('src', videoUrl);
      video.style.visibility = 'hidden';
    } else {
      video.setAttribute('src', videoUrl);
    }
    return video;
  };

  const makeAdVideo = (videoUrl) => {
    var video = document.createElement('video');
    video.setAttribute('id', `ad-video`);
    video.style.visibility = 'hidden';
    video.setAttribute('src', videoUrl);
    return video;
  };

  const throttle = (callback, limit = 1000) => {
    let waiting = false;
    return function () {
      if (!waiting) {
        waiting = true;
        callback.apply(this, arguments);
        setTimeout(() => {
          waiting = false;
        }, limit);
      }
    };
  };

  const videoListener = async function () {
    window?.opener?.postMessage({ currentPlayTime: this.currentTime }, process.env.REACT_APP_ORIGIN);
    console.log('this.currentTime ', this.currentTime);
    console.log('!isVideoLengthPlay ', !isVideoLengthPlay);
    console.log('!isPreparing ', !isPreparing);
    console.log('realPlayList[0].videoLength ', realPlayList[0].videoLength);
    console.log('spareTime ', spareTime);
    console.log('(this.currentTime >= (realPlayList[0].videoLength - spareTime) || (this.currentTime >= leftDuration - spareTime)) ', (this.currentTime >= (realPlayList[0].videoLength - spareTime) || (this.currentTime >= leftDuration - spareTime)));
    if (!isVideoLengthPlay && !isPreparing && ((this.currentTime >= (realPlayList[0].videoLength - spareTime) || (this.currentTime >= leftDuration - spareTime)))) {
      isPreparing = true;
      if (this.currentTime >= realPlayList[0].videoLength - spareTime) {
        leftDuration = leftDuration - realPlayList[0].videoLength - spareTime;
      }

      // console.log('videoListener - this.currentTime ', this.currentTime);
      // console.log('videoListener - (realPlayList[0].videoLength - spareTime) ', (realPlayList[0].videoLength - spareTime));
      // console.log('videoListener - leftDuration ', leftDuration);
      // console.log('videoListener - this.currentTime >= (realPlayList[0].videoLength - spareTime) && leftDuration > (realPlayList[0].videoLength - spareTime) ', this.currentTime >= (realPlayList[0].videoLength - spareTime) && leftDuration > (realPlayList[0].videoLength - spareTime));
      // console.log('videoListener - this.currentTime >= (realPlayList[0].videoLength - spareTime) ', this.currentTime >= (realPlayList[0].videoLength - spareTime));
      // console.log('videoListener - && leftDuration > (realPlayList[0].videoLength - spareTime) ', leftDuration > (realPlayList[0].videoLength - spareTime))

      if (isLoop || isOnlyOne) {
        // console.log('videoListener - 루핑');
        // console.log('videoListener - isLoop || isOnlyOne');
        setTimeout(() => {
          leftDuration = realPlayDuration;
          window?.opener?.postMessage({ currentPlayTime: 0, playId: realPlayList[0].id }, process.env.REACT_APP_ORIGIN);
          isPreparing = false;
        }, spareTime * 1000);
      } else {
        if (leftDuration > (realPlayList[0].videoLength - spareTime)) {
          // console.log('videoListener - 남은시간이 더 많음');
          // console.log('videoListener - leftDuration > (realPlayList[0].videoLength - spareTime');
          // if (this.currentTime >= (realPlayList[0].videoLength - spareTime) && leftDuration > (realPlayList[0].videoLength - spareTime)) {
          setTimeout(() => {
            leftDuration = leftDuration + spareTime;
            window?.opener?.postMessage({ currentPlayTime: 0, playId: realPlayList[0].id }, process.env.REACT_APP_ORIGIN);
            isPreparing = false;
          }, spareTime * 1000);
        } else {
          // console.log('videoListener - 다음 콘텐츠');
          // console.log('videoListener - else');
          const index = Number($(this).attr("data-index"));
          $(`#video-${index}`).off('ended');
          document.getElementById(`video-${index}`).loop = true;
          $(`#video-${index}`)[0].play();
          const nextIndex = Number($(this).attr("data-index")) === 0 ? 1 : 0;
          await $(`#video-${nextIndex}`)[0].load();
          const [video, ...tempVideo] = realPlayList;
          realPlayList = [...tempVideo, video];
          leftDuration = realPlayDuration;
          await handleVideo(nextIndex, false, false);
        }
      }
    }
  };

  const adVideoEnded = async function () {
    try {
      $(`#ad-video`).off('ended');
      $(`#ad-video`).removeClass('active');
      $(`#ad-video`)
        .delay(1000)
        .queue(function () {
          $(this).remove();
        });
    } catch (error) {
      console.error(error);
    }
  };

  const videoEnded = async function () {
    try {
      if (isLoop || isOnlyOne) {
        // console.log('videoEnded - 루핑');
        // console.log('videoEnded - isLoop || isOnlyOne');
        const index = Number($(this).attr("data-index"));
        // $(`#video-${index}`).off('ended');
        // $(`#video-${index}`).off('timeupdate');
        leftDuration = realPlayDuration;
        $(`#video-${index}`)[0].play();
        await handleVideo(index, false, true);
        window?.opener?.postMessage({ currentPlayTime: 0, playId: realPlayList[0].id }, process.env.REACT_APP_ORIGIN);
      } else {
        // console.log('videoEnded - 반복');
        // console.log('videoEnded - else');
        // console.log('videoEnded - leftDuration > (realPlayList[0].videoLength - spareTime)', leftDuration > (realPlayList[0].videoLength - spareTime));
        // console.log('videoEnded - leftDuration', leftDuration);
        // console.log('videoEnded - realPlayList[0].videoLength', realPlayList[0].videoLength);
        // console.log('videoEnded - spareTime', spareTime);
        const index = Number($(this).attr("data-index"));
        // $(`#video-${index}`).off('ended');
        // $(`#video-${index}`).off('timeupdate');
        leftDuration = leftDuration + spareTime;
        $(`#video-${index}`)[0].play();
        await handleVideo(index, false, true);
        window?.opener?.postMessage({ currentPlayTime: 0, playId: realPlayList[0].id }, process.env.REACT_APP_ORIGIN);
        // if (leftDuration > (realPlayList[0].videoLength - spareTime)) {
        //   const index = Number($(this).attr("data-index"));
        //   await $(`#video-${index}`)[0].load();
        //   $(`#video-${index}`).off('ended');
        //   $(`#video-${index}`).off('timeupdate');
        //   leftDuration = leftDuration + spareTime;
        //   await handleVideo(index, false, true);
        //   window?.opener?.postMessage({ currentPlayTime: 0, playId: realPlayList[0].id }, process.env.REACT_APP_ORIGIN);
        // }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleVideo = async (index, isInit = false, looping = false) => {
    // console.log('VIDEO_BLOB ', VIDEO_BLOB);
    try {
      if ((!isInit && isLoop) || (!isInit && isOnlyOne) || looping) {
        // console.log('play 반복');

        // $(`#video-${index}`).bind('timeupdate', videoListener);
        // $(`#video-${index}`).bind('ended', videoEnded);
        isPreparing = false;
      } else {
        if (!isInit) {
          // console.log('play 새로운 것');
          const animationTimeIn = 5000;
          const animationTimeOut = 10000;
          if (index === 0) {
            // $(`#video-0`).animate({ opacity: 0 }, 0);
            $(`#video-0`).css('visibility', 'visible');
            await $(`#video-0`)[0].play();
            window?.opener?.postMessage(
              { currentPlayTime: 0, playId: realPlayList[0].id },
              process.env.REACT_APP_ORIGIN,
            );
            isPreparing = false;
            $(`#video-0`).bind('timeupdate', videoListener);
            $('#video-0').bind('ended', videoEnded);
            // $(`#video-0`).animate({ opacity: 1 }, 10000);
            $(`#video-0`).addClass('active');

            await $(`#video-1`).off('timeupdate');
            await $(`#video-1`).off('ended');
            containerPlayIndex = 0;
            // $(`#video-1`).stop(true).fadeOut(10000, function () {
            //     $(this).remove();
            // });
            $(`#video-1`).removeClass('active');
            $(`#video-1`)
              .delay(10000)
              .queue(function () {
                $(this).remove();
              });
            // $(`#video-1`).delay(30000, function () {
            //     $(this).remove();
            // })
          } else {
            // $(`#video-1`).animate({ opacity: 0 }, 0);
            $(`#video-1`).css('visibility', 'visible');
            await $(`#video-1`)[0].play();
            window?.opener?.postMessage(
              { currentPlayTime: 0, playId: realPlayList[0].id },
              process.env.REACT_APP_ORIGIN,
            );
            isPreparing = false;
            $(`#video-1`).bind('timeupdate', videoListener);
            $('#video-1').bind('ended', videoEnded);
            // $(`#video-1`).animate({ opacity: 1 }, 10000);
            $(`#video-1`).addClass('active');

            await $(`#video-0`).off('timeupdate');
            await $(`#video-0`).off('ended');
            containerPlayIndex = 1;
            $(`#video-0`).removeClass('active');
            $(`#video-0`)
              .delay(10000)
              .queue(function () {
                $(this).remove();
              });
            // $(`#video-0`).delay(30000, function () {
            //     $(this).remove();
            // })
            // $(`#video-0`).stop(true).fadeOut(10000, function () {
            //     $(this).remove();
            // });
          }
        }

        const nextIndex = index === 0 ? 1 : 0;
        if (USE_CONVERT) {
          if (!VIDEO_BLOB[realPlayList[1]?.id]) {
            try {
              const res2 = await api.getContent(realPlayList[1].id);
              await convertBlob(realPlayList[1].id, res2?.data?.videoUrl);
            } catch (err) {
              console.log(err);
            }
          }
          const nextVideo = makeVideo(VIDEO_BLOB[realPlayList[1]?.id] || '', nextIndex, false);
          // await nextVideo.load();
          $('#video-container').append(nextVideo);
        } else {
          try {
            // const res2 = await api.getContent(realPlayList[1].id);
            const nextVideo = makeVideo(realPlayList[1].videoUrl || '', nextIndex, false);
            $('#video-container').append(nextVideo);
          } catch (err) {
            console.log(err);
          }
        }

        // 처음에만
        if (isInit) {
          if (USE_CONVERT) {
            if (!VIDEO_BLOB[realPlayList[index]?.id]) {
              try {
                const res = await api.getContent(realPlayList[index].id);
                await convertBlob(realPlayList[index].id, res?.data?.videoUrl);
              } catch (err) {
                console.log(err);
              }
            }

            const currentVideo = makeVideo(VIDEO_BLOB[realPlayList[index].id] || '', 0, true);
            await currentVideo.load();
            $('#video-container').append(currentVideo);
            currentVideo.muted = playInfo?.playMute;
            currentVideo.volume = (playInfo?.playVolume / 100);
            currentVideo.currentTime = playInfo?.currentPlayTime;
            // if (isLoop) {
            //     currentVideo.loop = true;
            // }
            if (playInfo?.playStart) {
              currentVideo.play();
            }
            $('#video-0').addClass('active');
            $('#video-0').bind('timeupdate', videoListener);
            $('#video-0').bind('ended', videoEnded);
            setIsLoading(false);
          } else {
            try {
              // const res = await api.getContent(realPlayList[index].id);
              // videoUrl
              const currentVideo = makeVideo(realPlayList[index].videoUrl || '', 0, true);
              await currentVideo.load();
              $('#video-container').append(currentVideo);
              currentVideo.muted = playInfo?.playMute;
              currentVideo.volume = (playInfo?.playVolume / 100);
              currentVideo.currentTime = playInfo?.currentPlayTime;
              // if (isLoop) {
              //     currentVideo.loop = true;
              // }
              if (playInfo?.playStart) {
                currentVideo.onloadeddata = function () {
                  currentVideo.play();
                }
              }
              $('#video-0').addClass('active');
              $('#video-0').bind('timeupdate', videoListener);
              $('#video-0').bind('ended', videoEnded);
              setIsLoading(false);
            } catch (err) {
              console.log(err);
            }

          }
        }
      }
    } catch (err) {
      // alert('인증이 유효하지않습니다.');
      console.error(err);
      // window?.opener?.postMessage({ windowStatus: 'close' }, process.env.REACT_APP_ORIGIN);
      // window.close();
    }
  };

  const handleUserKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        if (isFullScreen) {
          setIsFullScreen(false);
        } else {
          window?.opener?.postMessage(
            { windowStatus: 'close' },
            process.env.REACT_APP_ORIGIN,
          );
          window.close();
        }
      }
    },
    [isFullScreen, setIsFullScreen],
  );

  const exitHandler = useCallback(() => {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setIsFullScreen(false);
    }
  }, [isFullScreen, setIsFullScreen]);

  const handlePlayNextVideo = async (id) => {
    try {
      const nextIndex = containerPlayIndex === 0 ? 1 : 0;
      const video = document.getElementById(`video-${nextIndex}`);
      if (USE_CONVERT) {
        if (!VIDEO_BLOB[id]) {
          try {
            const res = await api.getContent(id);
            await convertBlob(id, res?.data?.videoUrl);
          } catch (err) {
            console.error(err);
          }
        }
        try {
          await video.setAttribute('src', VIDEO_BLOB[id] || '');
        } catch (err) {
          VIDEO_BLOB[id] = null;
          await handlePlayNextVideo(id);
        }
      } else {
        try {
          const res = await api.getContent(id);
          await video.setAttribute('src', res?.data?.videoUrl || '');
        } catch (err) {
          VIDEO_BLOB[id] = null;
          await handlePlayNextVideo(id);
        }
      }

    } catch (err) {
      console.error(err);
    }
  };

  const handlePlayCurrentVideo = async (id, playStart) => {
    try {
      const currentIndex = containerPlayIndex;
      const video = document.getElementById(`video-${currentIndex}`);
      $(`#video-${currentIndex}`).off('timeupdate');
      if (USE_CONVERT) {
        if (!VIDEO_BLOB[id]) {
          try {
            const res = await api.getContent(id);
            await convertBlob(id, res?.data?.videoUrl);
          } catch (err) {
            console.log(err);
          }
        }

        try {
          await video.setAttribute('src', VIDEO_BLOB[id] || '');
          await video.load();
          if (playStart) {
            video.play();
          }
          $(`#video-${currentIndex}`).addClass('active');
          $(`#video-${currentIndex}`).bind('timeupdate', videoListener);
          $(`#video-${currentIndex}`).bind('ended', videoEnded);
        } catch (err) {
          $(`#video-${currentIndex}`).removeClass('active');
          $(`#video-${currentIndex}`).off('ended');
          $(`#video-${currentIndex}`).off('timeupdate');
          VIDEO_BLOB[id] = null;
          await handlePlayCurrentVideo(id, playStart);
        }
      } else {
        try {
          const res = await api.getContent(id);
          await video.setAttribute('src', res?.data?.videoUrl || '');
          await video.load();
          if (playStart) {
            video.play();
          }
          $(`#video-${currentIndex}`).addClass('active');
          $(`#video-${currentIndex}`).bind('timeupdate', videoListener);
          $(`#video-${currentIndex}`).bind('ended', videoEnded);
        } catch (err) {
          $(`#video-${currentIndex}`).removeClass('active');
          $(`#video-${currentIndex}`).off('ended');
          $(`#video-${currentIndex}`).off('timeupdate');
          VIDEO_BLOB[id] = null;
          await handlePlayCurrentVideo(id, playStart);
        }
      }
      // $(`#video-${currentIndex}`).bind('timeupdate', throttle(videoListener));
    } catch (err) {
      console.error(err);
    }
  };

  const handleParentData = async (data) => {
    setPlayInfo(data);
    if (data?.playNext || data?.playPrev) {
      setIsLoading(true);
      $(`#video-0`).off('timeupdate');
      $(`#video-0`).off('ended');
      $(`#video-1`).off('timeupdate');
      $(`#video-1`).off('ended');
    }

    if (playThrottle.current) {
      clearTimeout(playThrottle.current);
    }

    playThrottle.current = setTimeout(async () => {
      // handleChangePlayData(data);
      console.log('data ', data);
      const element = `#video-${containerPlayIndex}`;
      try {
        if (data.hasOwnProperty('playList')) {
          realPlayList = data.playList;
          if (realPlayList.length === 1) {
            realPlayList = [
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
            ];
          }

          if (!realPlayList || realPlayList.length === 0) {
            alert('등록된 플레이리스트가 없습니다!');
            window?.opener?.postMessage(
              { windowStatus: 'close' },
              process.env.REACT_APP_ORIGIN,
            );
            window.close();
            return;
          }
        }

        if (data.hasOwnProperty('playId')) {
          if (!data.playList || data?.playList?.length === 0) {
            alert('등록된 플레이리스트가 없습니다!');
            return;
          }

          let playIndex;
          data.playList.some((el, index) => {
            if (el.id === data.playId) {
              playIndex = index;
            }
            return el.id === data.playId;
          });

          if (playIndex === 0) {
            realPlayList = data.playList;
          } else if (playIndex === data.playList.length - 1) {
            const last = data.playList[playIndex];
            const restArr = data.playList.filter((el) => el.id !== last.id);
            realPlayList = [last, ...restArr];
          } else {
            const pastArray = data.playList.slice(0, playIndex);
            const newArray = data.playList.slice(
              playIndex,
              data.playList.length,
            );
            realPlayList = [...newArray, ...pastArray];
          }

          if (realPlayList.length === 1) {
            isOnlyOne = true;
            realPlayList = [
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
            ];
          }

          if (data?.playNext) {
            leftDuration = realPlayDuration;
            await handlePlayCurrentVideo(realPlayList[0].id, data?.playStart);
            handlePlayNextVideo(realPlayList[1].id);
            isPreparing = false;
          }

          if (data?.playPrev) {
            leftDuration = realPlayDuration;
            await handlePlayCurrentVideo(realPlayList[0].id, data?.playStart);
            handlePlayNextVideo(realPlayList[1].id);
            isPreparing = false;
          }

          if (data?.removed) {
            leftDuration = realPlayDuration;
            await handlePlayCurrentVideo(realPlayList[0].id, data?.playStart);
            handlePlayNextVideo(realPlayList[1].id);
          }
        }

        if (data.hasOwnProperty('currentPlayTime')) {
          $(element).prop('currentTime', data.currentPlayTime);
        }

        if (data.hasOwnProperty('playMute')) {
          if (data.playMute) {
            $(element).prop('muted', true);
          } else {
            $(element).prop('muted', false);
          }
        }

        if (data.hasOwnProperty('playVolume')) {
          $(element).prop('volume', data.playVolume / 100);
        }

        if (data.hasOwnProperty('playLoop')) {
          isLoop = data.playLoop;
        }

        if (data.hasOwnProperty('playStart')) {
          try {
            if ($(element).length) {
              await $(element)[0].load();
              if (data.playStart) {
                await $(element)[0].play();
              } else {
                await $(element)[0].pause();
              }
            }
          } catch (err) {
            console.error(err);
          }
        }

        if (data.hasOwnProperty('playDuration')) {
          realPlayDuration =
            data.playDuration === 'auto' ? 0 : Number(data.playDuration);
          leftDuration =
            data.playDuration === 'auto' ? 0 : Number(data.playDuration);
          isVideoLengthPlay = data.playDuration === 'auto';
        }

        if (data.hasOwnProperty('isInit')) {
          if (data?.isInit) {
            await handleVideo(0, true, false);
          }
        }
      } catch (err) {
        console.error(err);
        window?.opener?.postMessage(
          { windowStatus: 'close' },
          process.env.REACT_APP_ORIGIN,
        );
        window.close();
      }
      if (data?.playNext || data?.playPrev) {
        setIsLoading(false);
      }
    }, 2000);
  };

  // const receiveMessage = async (e) => {
  //   if (e.origin !== process.env.REACT_APP_ORIGIN) return;
  //   if (e.data.mode === 'play') {
  //     await handleParentData(e.data);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('message', receiveMessage, false);
  //   window?.opener?.postMessage(
  //     { windowStatus: 'ready' },
  //     process.env.REACT_APP_ORIGIN,
  //   );
  //   return () => {
  //     window.removeEventListener('message', receiveMessage, false);
  //   };
  // }, []);

  window.onbeforeunload = function (e) {
    window?.opener?.postMessage(
      { windowStatus: 'refresh' },
      process.env.REACT_APP_ORIGIN,
    );
  };

  // useEffect(() => {
  //     return () => {
  //         $('#video-0').remove();
  //         $('#video-1').remove();
  //     }
  // }, [isLoading]);

  useEffect(() => {
    if (isInit) return;
    setIsInit(true);
    (async () => {

      let type = videoQuality || videoType;
      let mode = videoMethod || videoMode;
      let vList = mode === 'high' ? HIGH_VIMEO_LIST : LOW_VIMEO_LIST;

      const testData = {
        currentPlayTime: null,
        isInit: true,
        mode: "play",
        playDuration: "180",
        playId: 262,
        playList: vList?.map((video) => {
          return {
            category: "모던",
            createdAt: "2023-02-04T10:02:02.000Z",
            creator: 1,
            description: video?.name,
            displayPrice: 1000,
            freeVideoUrl: video?.videoUrl[videoType],
            grade: "A",
            id: 262,
            isAcutalPlay: true,
            isRemoved: false,
            likeCount: 1,
            liked: true,
            price: 1000,
            resolution: "4K",
            sellCount: 2,
            shortDescription: video?.name,
            tags: "",
            thumbnailUrl: "https://picsum.photos/200",
            title: `테스트 작가 컨텐츠 ${video?.name} ${type}`,
            updatedAt: "2023-02-19T09:08:14.000Z",
            usingCount: 41,
            videoLength: 26,
            videoUrl: video?.videoUrl[type],
          }
        }),
        playLoop: false,
        playMute: true,
        playStart: true,
        playType: "Ordering",
        playVolume: 100,
      }
      console.log(`mode : ${mode} / type : ${type}`);
      await handleParentData(testData);
      setVideoType(type);
      setVideoMode(mode);
    })();
  }, [isInit, videoType, videoQuality, videoMethod, videoMode, setIsInit]);

  const alertUser = () => {
    window?.opener?.postMessage(
      { windowStatus: 'close' },
      process.env.REACT_APP_ORIGIN,
    );
    window.close();
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    window.addEventListener('webkitfullscreenchange', exitHandler, false);
    window.addEventListener('mozfullscreenchange', exitHandler, false);
    window.addEventListener('fullscreenchange', exitHandler, false);
    window.addEventListener('MSFullscreenChange', exitHandler, false);
    window.addEventListener('keydown', handleUserKeyPress, false);
    window.addEventListener('keypress', handleUserKeyPress, false);

    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('webkitfullscreenchange', exitHandler, false);
      window.removeEventListener('mozfullscreenchange', exitHandler, false);
      window.removeEventListener('fullscreenchange', exitHandler, false);
      window.removeEventListener('MSFullscreenChange', exitHandler, false);
      window.removeEventListener('keydown', handleUserKeyPress, false);
      window.addEventListener('keypress', handleUserKeyPress, false);
    };
  }, []);


  const handleClick = async (e, index) => {
    e.preventDefault();
    setIsFullScreen(true);
    window.document.documentElement.requestFullscreen();
  };

  if (!isInit) return <></>;

  return (
    <>
      {isLoading && <BeamMonsterLoader />}
      {<AdvertisementPlay />}
      <PlayWrapper
        isFullScreen={isFullScreen}
        playList={playInfo?.playList}
        onClick={handleClick}
      />
    </>
  );
}