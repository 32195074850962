import React from 'react';
import Footer from 'components/views/Footer';
import Header from 'components/views/Header';
import PlayList from 'components/views/PlayList/PlayList';
import MediaContentPopup from 'components/views/MediaContentPopup';

export default function PlayListWrapper({
  data,
  playList,
  selectedData,
  handleClick,
  handleClickLike,
  handlePlayList,
  isShow,
  handlePopupClose,
  grade,
  wishPlayList,
  handleRemove,
  handleUpdatePlayList,
  changeCountInfo,
  playlistResetCount,
  handleResetPlayList,
  isFetching,
}) {
  return (
    <>
      {/* <Header /> */}
      <PlayList
        data={data}
        playList={playList}
        selectedData={selectedData}
        handleClick={handleClick}
        handleClickLike={handleClickLike}
        handlePlayList={handlePlayList}
        grade={grade}
        wishPlayList={wishPlayList}
        handleRemove={handleRemove}
        handleUpdatePlayList={handleUpdatePlayList}
        changeCountInfo={changeCountInfo}
        playlistResetCount={playlistResetCount}
        handleResetPlayList={handleResetPlayList}
        isFetching={isFetching}
      />
      <MediaContentPopup
        key={selectedData?.id}
        data={selectedData}
        isShow={isShow}
        onClose={handlePopupClose}
      />
      {/* <Footer /> */}
    </>
  );
}
