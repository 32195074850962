import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './Mypage.module.scss';
import { numberWithCommas } from 'libs/numbers';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const cx = classNames.bind(styles);

export default function Mypage({
  email,
  name,
  grade,
  profileImageUrl,
  handleChangeProfileImage,
  wishArtistPlayList,
  isUnlimitedPlaylist,
}) {
  const fileRef = useRef();

  const onClickUpload = () => {
    fileRef.current?.click();
  };

  const { isTestAccount, isTempBlock } = useAuth();

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('inner')}>
          <div className={cx('inner_box')}>
            <div className={cx('sub_inner_box')}>
              <input
                ref={fileRef}
                className={cx('hidden_input')}
                type="file"
                onChange={handleChangeProfileImage}
              />
              <button onClick={onClickUpload} className={cx('profile_inner')}>
                <img
                  className={cx('img_profile')}
                  src={
                    profileImageUrl
                      ? profileImageUrl
                      : '/statics/images/profile.png'
                  }
                  alt="profile"
                />
                <img
                  className={cx('profile_change_img_box')}
                  src="/statics/images/profile_change.png"
                  alt="profile_change"
                />
              </button>
              <div className={cx('nick_name_box')}>
                <h4 className={cx('nick_name')}>{name}</h4>
              </div>
              <div className={cx('usage_status_box')}>
                <p className={cx('usage_status')}>
                  {isUnlimitedPlaylist
                    ? '무제한 솔루션 이용중'
                    : grade
                      ? `${grade} 솔루션 이용중`
                      : '-'}
                </p>
              </div>
            </div>
            <div className={cx('account_inner')}>
              <div className={cx('account_info_box')}>
                <div className={cx('account_info_title_box')}>
                  <h4 className={cx('account_info_title')}>계정 정보</h4>
                </div>
                <div className={cx('account_info_nick_name_box')}>
                  <p className={cx('account_info_nick_name')}>{email}</p>
                </div>
                <Link
                  to="/mypage/edit"
                  className={cx('account_info_change_box')}
                  style={{
                    backgroundImage:
                      'url(/statics/images/ic_profile_list_more.png)',
                  }}
                >
                  <button className={cx('account_info_change_button')}>
                    계정 상세정보 변경
                  </button>
                </Link>
                <Link
                  to="/mypage/password"
                  className={cx('account_info_password_change_box')}
                  style={{
                    backgroundImage:
                      'url(/statics/images/ic_profile_list_more.png)',
                  }}
                >
                  <button className={cx('account_info_change_button')}>
                    비밀번호 변경
                  </button>
                </Link>
              </div>
              {/* {
                !isTempBlock && ( */}
              <Link
                to="/mypage/payment-history"
                className={cx('secession_info_box')}
              >
                <div
                  className={cx('secession_change_box')}
                  style={{
                    backgroundImage:
                      'url(/statics/images/ic_profile_list_more.png)',
                  }}
                >
                  <button className={cx('secession_list_button')}>
                    결제 내역
                    </button>
                </div>
              </Link>
              )
              {/*  } */}
              {/* <Link
                to="/mypage/advertisement"
                className={cx('secession_info_box')}
              >
                <div
                  className={cx('secession_change_box')}
                  style={{
                    backgroundImage:
                      'url(/statics/images/ic_profile_list_more.png)',
                  }}
                >
                  <button className={cx('secession_list_button')}>
                    내 광고 보기
                  </button>
                </div>
              </Link> */}
              {/* {
                !isTempBlock && ( */}
              <Link
                to="/mypage/basket/artist"
                className={cx('secession_info_box')}
              >
                <div
                  className={cx('secession_change_box')}
                  style={{
                    backgroundImage:
                      'url(/statics/images/ic_profile_list_more.png)',
                  }}
                >
                  <button className={cx('secession_list_button')}>
                    장바구니
                    </button>
                  <span className={cx('basket_count')}>
                    {wishArtistPlayList?.length > 10
                      ? numberWithCommas(wishArtistPlayList?.length)
                      : `0${wishArtistPlayList?.length}`}
                  </span>
                </div>
              </Link>
              {/*  )} */}
              <Link to="/mypage/secession" className={cx('secession_info_box')}>
                <div
                  className={cx('secession_change_box')}
                  style={{
                    backgroundImage:
                      'url(/statics/images/ic_profile_list_more.png)',
                  }}
                >
                  <button className={cx('secession_change_button')}>
                    계정탈퇴
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
