import React, {
    useState,
} from 'react';
import * as api from 'apis';
import useAuth from 'hooks/useAuth';
import MypageSecessionWrapper from 'components/MypageSecessionWrapper/MypageSecessionWrapper';

export default function MypageSecessionContainer() {
    const {
        email,
    } = useAuth();
    const [data, setData] = useState({
        password: '',
        isChecked: false,
    });

    const handleChange = (e) => {
        setData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleClick = () => {
        setData(prev => ({ ...prev, isChecked: !prev.isChecked }));
    }

    const handleSubmit = async () => {
        try {
            const res = await api.login(email, data.password);
            if (res.status === 201) {
                await api.deleteMe();
                alert('회원탈퇴 되셨습니다.');
                localStorage.removeItem('token');
                localStorage.removeItem('persist:root');
                sessionStorage.removeItem('persist:root');
                window.location.href = '/';
            }
        } catch (err) {
            alert('비밀번호가 일치하지 않습니다.');
            console.log(err);
        }
    }

    return (
        <MypageSecessionWrapper
            password={data.password}
            isChecked={data.isChecked}
            handleChange={handleChange}
            handleClick={handleClick}
            handleSubmit={handleSubmit}
        />
    )
}
