import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import PaymentButton from 'components/views/PaymentButton';
import styles from './AdvertisementPayment.module.scss';
import Pagination from 'components/common/Pagination';
import PaymentMethod from 'components/views/PaymentMethod';
import { numberWithCommas } from 'libs/numbers';

const cx = classNames.bind(styles);

export default function AdvertisementPayment({
  handleSuccess,
  handlePaymentStart,
  handleChange,
  fileUrls,
  handleFileUrls,
  selectedStores,
  handlePage,
  pageData,
  data,
  merchantUid,
  estimateInfo,

  setPayType,
  payType,
  info,
  billApply,
  setBillApply,
  handleValid,
  count,
  totalPrice,
}) {
  return (
    <>
      <div className={cx('container')}>
        <div className={cx('inner')}>
          <div className={cx('title_box')}>
            <h2 className={cx('title')}>결제 내역</h2>
            <p className={cx('text')}>결제 진행되는 스토어를 확인해 주세요.</p>
          </div>
        </div>
        <table className={cx('table')}>
          <thead className={cx('thead')}>
            <tr className={cx('thead_tr')}>
              <td className={cx('td')}>선택 매체</td>
              <td className={cx('td')}>일 노출수</td>
              <td className={cx('td')}>총 노출수</td>
              <td className={cx('td')}>일 노출량</td>
              <td className={cx('td')}>총 노출량</td>
              <td className={cx('td')}>결제 금액</td>
            </tr>
          </thead>
          <tbody className={cx('tbody')}>
            {data &&
              pageData?.currentPage &&
              data[pageData?.currentPage]?.length > 0 &&
              data[pageData?.currentPage].map((el, i) => {
                return (
                  <tr className={cx('tbody_tr')} key={i}>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>선택 매체</p>
                      {el?.storeUserProfileImageUrl?.length ? (
                        <img
                          className={cx('tbody_tr_img')}
                          src={el?.storeUserProfileImageUrl}
                          alt="선택 매체"
                        />
                      ) : (
                        <img
                          className={cx('tbody_tr_img')}
                          src="/statics/images/beammonster.jpeg"
                          alt="선택 매체"
                        />
                      )}
                    </td>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>일 노출수</p>
                      <p
                        className={cx('mobile_tbody_td_number')}
                      >{`${el?.dailyViewCount?.toLocaleString()} 회`}</p>
                    </td>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>총 노출수</p>
                      <p
                        className={cx('mobile_tbody_td_number')}
                      >{`${el?.totalViewCount?.toLocaleString()} 회`}</p>
                    </td>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>일 노출량</p>
                      <p
                        className={cx('mobile_tbody_td_number')}
                      >{`${el?.dailyVisitorCount?.toLocaleString()} 회`}</p>
                    </td>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>총 노출량</p>
                      <p
                        className={cx('mobile_tbody_td_number')}
                      >{`${el?.totalVisitorCount?.toLocaleString()} 회`}</p>
                    </td>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>결제 금액</p>
                      <p
                        className={cx('mobile_tbody_td_number')}
                      >{`${el?.price?.toLocaleString()} 원`}</p>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!!pageData?.total && (
          <Pagination
            currentPage={pageData?.currentPage}
            lastPage={pageData?.lastPage}
            perPage={pageData?.perPage}
            handlePage={handlePage}
          />
        )}
        <PaymentMethod
          payType={payType}
          setPayType={setPayType}
          setBillApply={setBillApply}
          billApply={billApply}
          onChange={handleChange}
          onValid={handleValid}
          businessNumber={info?.registrationNumber}
          businessName={info?.businessName}
          businessEmail={info?.businessEmail}
          isBusinessVerified={info?.isBusinessVerified}
          fileUrls={fileUrls}
          handleFileUrls={handleFileUrls}
        />
      </div>
      <div className={cx('total_container')}>
        <div className={cx('total_inner')}>
          <div className={cx('media_total_inner')}>
            <div className={cx('media_total_box')}>
              <p className={cx('media_sub_text')}>총 선택 매체 수</p>
              <p className={cx('media_text')}>{estimateInfo?.length}개</p>
            </div>
            <div className={cx('media_total_sub_inner')}>
              <div className={cx('media_total_box')}>
                <p className={cx('media_sub_text')}>총 노출수</p>
                <p className={cx('media_text')}>
                  {numberWithCommas(count?.totalVisitorCount)}회
                </p>
              </div>
              <div className={cx('media_total_box')}>
                <p className={cx('media_sub_text')}>총 노출량</p>
                <p className={cx('media_text')}>
                  {numberWithCommas(count?.totalViewCount)}회
                </p>
              </div>
            </div>
          </div>
          <div className={cx('media_total_payment_inner')}>
            <div className={cx('media_total_payment_box')}>
              <p className={cx('media_sub_text')}>총 주문금액</p>
              <p className={cx('media_payment_text')}>
                {numberWithCommas(totalPrice)}원
              </p>
            </div>
            <PaymentButton
              merchantUid={merchantUid}
              handlePaymentStart={handlePaymentStart}
              handleSuccess={handleSuccess}
              useVerified={payType === 'trans' && billApply}
              isVerified={info?.isBusinessVerified}
              payType={payType}
              amount={totalPrice}
              productName={`스토어 광고 ${numberWithCommas(pageData?.total)}EA`}
            />
          </div>
        </div>
      </div>
    </>
  );
}
