import React from 'react';
import Header from 'components/views/Header/Header';
import SolutionInstallmentExaminationPaymentComplete from 'components/views/SolutionInstallmentExaminationPaymentComplete/SolutionInstallmentExaminationPaymentComplete';

export default function SolutionInstallmentExaminationPaymentCompleteWrapper() {
    return (
        <>
            <Header />
            <SolutionInstallmentExaminationPaymentComplete />
        </>
    )
}
