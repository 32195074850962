import React from 'react';
import { Footer, Header } from 'components/views';
import AdvertisementCategory from 'components/views/AdvertisementCategory';
import AdvertisementStore from 'components/views/AdvertisementStore';
import AdvertisementRegion from 'components/views/AdvertisementRegion';
import AdvertisementPeriod from 'components/views/AdvertisementPeriod';
import AdvertisementSelectedStore from 'components/views/AdvertisementSelectedStore';
import AdvertisementStoreList from 'components/views/AdvertisementStoreList';
import AdvertisementCalculation from 'components/views/AdvertisementCalculation';

export default function AdvertisementWrapper({
  originAdvertiseCategories,
  businiessCategories,
  selectedCategory,
  handleSelectCategory,
  selectedBusinessCategories,
  handleSelectBusinessCategory,
  regionCountArray,
  handleSelectRegion,
  selectedRegionArray,
  startDate,
  endDate,
  handleStartDate,
  handleEndDate,
  handleSearchStores,
  availableStores,
  currentStorePage,
  selectedStores,
  handlePage,
  handleSelectStore,
  estimateInfo,
  handleStartDateToggle,
  handleEndDateToggle,
  openStart,
  openEnd,
  handleSingleCheck,
  handleAllCheck,
  storeCheckList,
  checkDelete,
  minAdPurchaseDays,
}) {
  return (
    <>
      <Header />
      <AdvertisementCategory
        originAdvertiseCategories={originAdvertiseCategories}
        selectedCategory={selectedCategory}
        handleSelectCategory={handleSelectCategory}
      />
      <AdvertisementStore
        selectedCategory={selectedCategory}
        businiessCategories={businiessCategories}
        selectedBusinessCategories={selectedBusinessCategories}
        handleSelectBusinessCategory={handleSelectBusinessCategory}
      />
      <AdvertisementRegion
        regionCountArray={regionCountArray}
        handleSelectRegion={handleSelectRegion}
        selectedRegionArray={selectedRegionArray}
      />
      <AdvertisementPeriod
        startDate={startDate}
        endDate={endDate}
        handleStartDate={handleStartDate}
        handleEndDate={handleEndDate}
        handleSearchStores={handleSearchStores}
        handleStartDateToggle={handleStartDateToggle}
        handleEndDateToggle={handleEndDateToggle}
        openStart={openStart}
        openEnd={openEnd}
        minAdPurchaseDays={minAdPurchaseDays}
      />
      {selectedStores?.length > 0 && (
        <AdvertisementSelectedStore
          handleSingleCheck={handleSingleCheck}
          handleAllCheck={handleAllCheck}
          storeCheckList={storeCheckList}
          selectedStores={selectedStores}
          checkDelete={checkDelete}
        />
      )}
      {currentStorePage?.total && currentStorePage?.total !== 0 ? (
        <AdvertisementStoreList
          availableStores={availableStores}
          currentStorePage={currentStorePage}
          selectedStores={selectedStores}
          handlePage={handlePage}
          handleSelectStore={handleSelectStore}
        />
      ) : null}
      {selectedStores?.length > 0 && (
        <AdvertisementCalculation
          selectedStores={selectedStores}
          estimateInfo={estimateInfo}
        />
      )}
      <Footer />
    </>
  );
}
