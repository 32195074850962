import React, {
  useCallback,
} from 'react';
import {
  useSelector,
  useDispatch,
} from "react-redux";
import { changeContractData, changeResetData } from 'store/modules/contract';

export default function useContract() {
  const contract = useSelector(state => state.contract);
  const {
    contractId,
    status,
    paymentType,
    filename,
    fileUrl,
    visitDate,
    visitManagerName,
    visitManagerPhone,
    visitManagerProfileImageUrl,
    contractFile,
    pdfImageUrls,
    shouldSignImageIndex,
  } = contract;
  const dispatch = useDispatch();

  const handleChangeContractData = useCallback((payload) => dispatch(changeContractData(payload)), [dispatch]);
  const handleResetContractData = useCallback(() => dispatch(changeResetData()), [dispatch]);

  return {
    contract,
    contractId,
    status,
    paymentType,
    filename,
    fileUrl,
    visitDate,
    visitManagerName,
    visitManagerPhone,
    visitManagerProfileImageUrl,
    handleChangeContractData,
    contractFile,
    pdfImageUrls,
    handleResetContractData,
    shouldSignImageIndex,
  }
};