import React, { useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './MypageScheduleEdit.module.scss';

const cx = classNames.bind(styles);

export default function CalendarHeader(props) {

    const { year, month, goToday, setMonth } = props;

    const day = ['일', '월', '화', '수', '목', '금', '토'];

    return (
        <div className={cx('calendar_header_inner')}>
            <div className={cx('calendar_header_box')}>
                <div className={cx('calendar_header_button_box')}>
                    <img src='/statics/images/calendar_left.png' alt='calendar_left' onClick={() => setMonth(month - 1)} />
                </div>
                <div className={cx('calendar_header')}>
                    {year}년 {month}월
                </div>
                <div className={cx('calendar_header_button_box')}>
                    <img src='/statics/images/calendar_right.png' alt='calendar_right' onClick={() => setMonth(month + 1)} />
                </div>
            </div>
            <div className={cx('calendar_days_box')}>
                {day.map((el, i) => {
                    return <div className={cx('calendar_days')} key={i}>{el}</div>;
                })}
            </div>
        </div>
    )
}
