import moment from 'moment';

export const CHANGE_DATA = 'artist/CHANGE_DATA';

export const changeArtistData = (payload) => ({ type: CHANGE_DATA, payload });

const initialState = {
  wishArtistPlayList: [],
};

function artist(state = initialState, action) {
  switch (action.type) {
    case CHANGE_DATA:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state;
  }
};

export default artist;