import React from 'react';
import classNames from 'classnames/bind';
import styles from './AdvertisementPaymentComplete.module.scss';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function AdvertisementPaymentComplete() {
  const location = useLocation();
  const { type } = qs.parse(location.search);

  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <div className={cx('inner_box')}>
          <div className={cx('title_box')}>
            <p className={cx('title')}>결제가 완료 되었습니다.</p>
          </div>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/img_solution_estimate_ok.png"
              alt="결제 완료 이미지"
            />
          </div>
          <div className={cx('button_box')}>
            <Link to={type ? `/mypage/payment-history?type=${type}` : "/mypage/payment-history"} className={cx('button')}>
              결제내역으로 이동하기
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
