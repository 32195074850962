import React, { useEffect, useState } from 'react';
import * as api from 'apis';
import classNames from 'classnames/bind';
import styles from './MediaContentPopup.module.scss';

const cx = classNames.bind(styles);

export default function MediaContentPopup({ data, isShow, onClose }) {
  const [videoLink, setVideoLink] = useState('');

  useEffect(() => {
    if (data?.id) {
      (async () => {
        try {
          const res = await api.getContent(data.id).catch({});
          setVideoLink(res?.data?.freeVideoUrl || '');
        } catch (err) {
          console.error(err);
        }
      })();
    }
  }, [data?.id, setVideoLink]);
  return (
    <div className={cx('container', { active: isShow && videoLink })}>
      {/* 바탕화면 클릭 시 닫힘 클릭 이벤트 필요없음 지우면 됨!!  */}
      <div onClick={onClose} className={cx('sub_container')}></div>
      <div className={cx('inner')}>
        <div className={cx('inner_box')}>
          <div className={cx('header')}>
            <div className={cx('title_box')}>
              <h4 className={cx('title')}>PLAY 확인 30초</h4>
            </div>
            <div onClick={onClose} className={cx('close_button_box')}>
              <img
                className={cx('close_button')}
                src="/statics/images/ic_pop_close.png"
                alt="ic_pop_close"
              />
            </div>
          </div>
          <div className={cx('content_inner')}>
            <div className={cx('content_media_box')}>
              <video
                key={`${videoLink}-${data?.id}`}
                muted
                autoPlay
                loop
                controls
                controlsList="nodownload"
                className={cx('content_media')}
              >
                <source src={videoLink} />
              </video>
              <div className={cx('media_title_inner')}>
                <div className={cx('media_sub_title_box')}>
                  <p className={cx('media_sub_title')}>{data?.resolution}</p>
                </div>
                <div className={cx('media_title_box')}>
                  <h4 className={cx('media_title')}>{data?.title}</h4>
                </div>
              </div>
              <div className={cx('media_start_box')}>
                {/* 재생 버튼   */}
                {/* <button className={cx('media_play_box')}>
                                <img className={cx('media_start')} src="/statics/images/media_start.png" alt="media_start" />
                            </button> */}
                {/* 멈춤 버튼  
                            <button className={cx('media_play_box')}>
                                <img className={cx('media_stop')} src="/statics/images/media_stop.png" alt="media_stop" />
                            </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
