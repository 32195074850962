import React from 'react';
import classNames from 'classnames/bind';
import styles from './IntroAdvertisement.module.scss';
import { useNavigate } from 'react-router-dom';
import useScrollFadeIn from 'hooks/useScrollFadeIn';
import useScrollClipPath from 'hooks/useScrollClipPath';

const cx = classNames.bind(styles);

export default function IntroAdvertisement() {
  const navigator = useNavigate();

  const onClickAdvertisementMove = () => {
    navigator('/advertisement');
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className={cx('container')}>
        <div className={cx('inner')}>
          <div className={cx('title_box')}>
            <div {...useScrollFadeIn('up', 1, 0.2)} className={cx('text_box')}>
              <p className={cx('text')}>
                타깃 설정을 통한 전국 실시간 동시 송출!
              </p>
            </div>
            <h2 {...useScrollFadeIn('up', 1, 0.3)} className={cx('title')}>
              쉽고 저렴하게 전국 한번에
              <br /> 송출이 가능한 <br className={cx('mobile_br')} /> 빔몬스터
              옥외광고 서비스
            </h2>
          </div>
          <div className={cx('advertiser_inner')}>
            <div
              {...useScrollFadeIn('up', 1, 0.4)}
              className={cx('advertiser_box')}
            >
              <p className={cx('advertiser_text')}>광고주</p>
            </div>
            <div
              {...useScrollFadeIn('up', 1, 0.5)}
              className={cx('outdoor_box')}
            >
              <p className={cx('outdoor_text')}>
                빔몬스터
                <br /> 옥외광고 서비스
              </p>
            </div>
          </div>
          <div className={cx('send_out_inner')}>
            <div
              {...useScrollFadeIn('up', 1, 0.6)}
              className={cx('send_out_text_box')}
            >
              <img
                src="/statics/images/ic-service-ad-wifi.png"
                alt="광고송출"
              />
            </div>
            <div
              {...useScrollFadeIn('up', 1, 0.7)}
              className={cx('send_out_img_box')}
            >
              <img
                className={cx('send_out_img')}
                src="/statics/images/img-service-wifi.png"
                alt="광고송출 이미지"
              />
            </div>
            <div className={cx('send_out_item_inner')}>
              <div
                {...useScrollFadeIn('up', 1, 0)}
                className={cx('send_out_item_box')}
              >
                <img
                  src="/statics/images/ic-service-alcohol.png"
                  alt="송출 주류 이미지"
                />
                <p className={cx('send_out_item_text')}>주류</p>
              </div>
              <div
                {...useScrollFadeIn('up', 1, 0.1)}
                className={cx('send_out_item_box')}
              >
                <img
                  src="/statics/images/ic-service-restaurant.png"
                  alt="송출 요식 이미지"
                />
                <p className={cx('send_out_item_text')}>요식</p>
              </div>
              <div
                {...useScrollFadeIn('up', 1, 0.2)}
                className={cx('send_out_item_box')}
              >
                <img
                  src="/statics/images/ic-service-fashion.png"
                  alt="송출 패션잡화 이미지"
                />
                <p className={cx('send_out_item_text')}>패션잡화</p>
              </div>
              <div
                {...useScrollFadeIn('up', 1, 0.3)}
                className={cx('send_out_item_box')}
              >
                <img
                  src="/statics/images/ic-service-beauty.png"
                  alt="송출 뷰티 이미지"
                />
                <p className={cx('send_out_item_text')}>뷰티</p>
              </div>
              <div
                {...useScrollFadeIn('up', 1, 0.4)}
                className={cx('send_out_item_box')}
              >
                <img
                  src="/statics/images/ic-service-hospital.png"
                  alt="송출 병원 이미지"
                />
                <p className={cx('send_out_item_text')}>병원</p>
              </div>
              <div
                {...useScrollFadeIn('up', 1, 0.5)}
                className={cx('send_out_item_box')}
              >
                <img
                  src="/statics/images/ic-service-fitness.png"
                  alt="송출 휘트니스 이미지"
                />
                <p className={cx('send_out_item_text')}>휘트니스</p>
              </div>
            </div>
            <div className={cx('next_img_box')}>
              <img
                src="/statics/images/ic-service-scrolldown.png"
                alt="다음 이미지"
              />
            </div>
          </div>
        </div>
      </div>
      {/* 이미지 비교 */}
      <div className={cx('img_compare_inner')}>
        <div className={cx('initial_img_box')}>
          <img
            className={cx('initial_img')}
            src="/statics/images/img-outdoor-ad-on.png"
            alt="기존옥외광고 이미지"
          />
          <p className={cx('compare_text')}>기존 옥외광고</p>
        </div>
        <div className={cx('beam_img_box')}>
          <img
            className={cx('beam_img')}
            src="/statics/images/img-beammonster-ad.png"
            alt="빔몬스터 옥외광고 이미지"
          />
          <p className={cx('compare_text')}>빔몬스터 광고</p>
        </div>
      </div>
      {/* 텍스트 비교 */}
      <div className={cx('sub_container')}>
        <div className={cx('sub_inner')}>
          <div className={cx('compare_text_inner')}>
            <div
              {...useScrollFadeIn('up', 1, 0.1)}
              className={cx('compare_initial_box')}
            >
              <p className={cx('initial_title')}>기존 옥외 광고</p>
              <p className={cx('initial_text')}>
                타깃 상관없이 무분별하게
                <br /> 노출되는 광고
              </p>
            </div>
            <div
              {...useScrollFadeIn('up', 1, 0.1)}
              className={cx('compare_beam_box')}
            >
              <p className={cx('beam_title')}>빔몬스터 광고</p>
              <p className={cx('beam_text')}>
                원하시는 업종 타겟에 맞는
                <br /> 정확한 광고 노출
              </p>
            </div>
          </div>
          <div
            {...useScrollFadeIn('up', 1, 0.1)}
            className={cx('compare_text_inner')}
          >
            <div className={cx('compare_initial_box')}>
              <p className={cx('initial_title')}>기존 옥외 광고</p>
              <p className={cx('initial_text')}>
                지역에 한정적인
                <br /> 기존 옥외 광고 매체
              </p>
            </div>
            <div
              {...useScrollFadeIn('up', 1, 0.1)}
              className={cx('compare_beam_box')}
            >
              <p className={cx('beam_title')}>빔몬스터 광고</p>
              <p className={cx('beam_text')}>
                한 번에 전국으로
                <br /> 동시 송출이 가능
              </p>
            </div>
          </div>
          <div
            {...useScrollFadeIn('up', 1, 0.1)}
            className={cx('compare_text_inner')}
          >
            <div className={cx('compare_initial_box')}>
              <p className={cx('initial_title')}>기존 옥외 광고</p>
              <p className={cx('initial_text')}>
                높은 광고 비용으로
                <br /> 진입 장벽이 높은 기존 광고
              </p>
            </div>
            <div
              {...useScrollFadeIn('up', 1, 0.1)}
              className={cx('compare_beam_box')}
            >
              <p className={cx('beam_title')}>빔몬스터 광고</p>
              <p className={cx('beam_text')}>
                중소기업, 소상공인도
                <br /> 합리적인 금액으로 광고 가능
              </p>
            </div>
          </div>
          <div
            {...useScrollFadeIn('up', 1, 0.1)}
            className={cx('compare_text_inner')}
          >
            <div className={cx('compare_initial_box')}>
              <p className={cx('initial_title')}>기존 옥외 광고</p>
              <p className={cx('initial_text')}>
                복잡한 광고 집행 방식 기존 광고
              </p>
            </div>
            <div
              {...useScrollFadeIn('up', 1, 0.1)}
              className={cx('compare_beam_box')}
            >
              <p className={cx('beam_title')}>빔몬스터 광고</p>
              <p className={cx('beam_text')}>
                SNS 광고처럼 누구나 쉽고
                <br /> 간편하게 광고 운영이 가능
              </p>
            </div>
          </div>
          <div
            {...useScrollFadeIn('up', 1, 0.1)}
            className={cx('compare_text_inner')}
          >
            <div className={cx('compare_initial_box')}>
              <p className={cx('initial_title')}>기존 옥외 광고</p>
              <p className={cx('initial_text')}>
                소비자가 싫어하는 광고를 위한
                <br /> 광고 매체인 기존 옥외광고 매체
              </p>
            </div>
            <div className={cx('compare_beam_box')}>
              <p className={cx('beam_title')}>빔몬스터 광고</p>
              <p className={cx('beam_text')}>
                미디어 아트로
                <br /> 고객의 눈을 사로 잡는 광고
              </p>
            </div>
          </div>
          <div
            {...useScrollFadeIn('up', 1, 0.1)}
            className={cx('compare_text_inner')}
          >
            <div className={cx('compare_initial_box')}>
              <p className={cx('initial_title')}>기존 옥외 광고</p>
              <p className={cx('initial_text')}>
                얼마나 노출이 되는지
                <br /> 정확히 알 수 없는 기존 광고
              </p>
            </div>
            <div
              {...useScrollFadeIn('up', 1, 0.1)}
              className={cx('compare_beam_box')}
            >
              <p className={cx('beam_title')}>빔몬스터 광고</p>
              <p className={cx('beam_text')}>정확한 노출량을 비교 가능</p>
            </div>
          </div>
          <div className={cx('process_inner')}>
            <div className={cx('process_title_box')}>
              <p
                {...useScrollFadeIn('up', 1, 0.1)}
                className={cx('process_title')}
              >
                방문 없는 간편한 구매 프로세스
              </p>
              <p
                {...useScrollFadeIn('up', 1, 0.2)}
                className={cx('process_text')}
              >
                별도 방문 없이 전자 계약 시스템으로 정확한 견적 산출을 통해
                맞춤형 솔루션을 구매하실 수 있습니다.
              </p>
            </div>
            <div
              {...useScrollClipPath('left', 1, 0.3)}
              className={cx('process_item_inner')}
            >
              <div className={cx('process_item_box')}>
                <p className={cx('process_item_number')}>01</p>
                <p className={cx('process_item_text')}>매체/기간 선택</p>
                <img
                  src="/statics/images/ic-service-process-1.png"
                  alt="구매 프로세스 이미지"
                />
              </div>
              <img
                className={cx('servie_arrow')}
                src="/statics/images/ic-intro-arrow-forward.png"
                alt="구매 프로세스 화살표"
              />
              <div className={cx('process_item_box')}>
                <p className={cx('process_item_number')}>02</p>
                <p className={cx('process_item_text')}>광고 심사</p>
                <img
                  src="/statics/images/ic-service-process-2.png"
                  alt="구매 프로세스 이미지"
                />
              </div>
              <img
                className={cx('servie_arrow')}
                src="/statics/images/ic-intro-arrow-forward.png"
                alt="구매 프로세스 화살표"
              />
              <div className={cx('process_item_box')}>
                <p className={cx('process_item_number')}>03</p>
                <p className={cx('process_item_text')}>광고비 결제</p>
                <img
                  src="/statics/images/ic-service-process-3.png"
                  alt="구매 프로세스 이미지"
                />
              </div>
              <img
                className={cx('servie_arrow')}
                src="/statics/images/ic-intro-arrow-forward.png"
                alt="구매 프로세스 화살표"
              />
              <div className={cx('process_item_box')}>
                <p className={cx('process_item_number')}>04</p>
                <p className={cx('process_item_text')}>광고 송출</p>
                <img
                  src="/statics/images/ic-service-process-4.png"
                  alt="구매 프로세스 이미지"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={cx('service_inner')}>
          <div className={cx('service_box')}>
            <div
              {...useScrollFadeIn('up', 1, 0.1)}
              className={cx('service_title_box')}
            >
              <p className={cx('service_title')}>
                SNS 광고처럼 쉽고 저렴한 빔몬스터 옥외광고!
                <br /> 옥외광고 보급화에 빔몬스터가 앞장서겠습니다.
              </p>
            </div>
            <div
              {...useScrollFadeIn('up', 1, 0.2)}
              className={cx('service_button_box')}
            >
              <button
                onClick={onClickAdvertisementMove}
                className={cx('service_button')}
              >
                광고 문의하기
                <div className={cx('service_button_arrow')}>
                  <div className={cx('service_button_arrow_hr')}></div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
