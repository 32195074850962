import React from 'react';
import AdvertisementPaymentComplete from 'components/views/AdvertisementPaymentComplete';
import { Footer, Header } from 'components/views';

export default function AdvertisementPaymentCompleteWrapper() {
  return (
    <>
      <Header />
      <AdvertisementPaymentComplete />
      <Footer />
    </>
  );
}
