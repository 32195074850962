import React, {
  useEffect,
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import * as api from 'apis';
import useContract from 'hooks/useContract';
import useAuth from 'hooks/useAuth';
import {
  handleCheckSolution,
} from 'libs/authService';
import SolutionRentalInfoWrapper from 'components/SolutionRentalInfoWrapper';

export default function SolutionRentalInfoContainer() {
  const navigator = useNavigate();
  const {
    isLogIn,
  } = useAuth();

  const {
    contractId,
    handleChangeContractData,
  } = useContract();
  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })]);
        handleChangeContractData({
          contractId: res[1]?.data?.id
        });
        if (res[1]?.data?.status === "RENTAL_READY") {
          navigator('/solution/rental-wait');
        } else {
          handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [navigator, handleChangeContractData, isLogIn, navigator]);

  const handleClick = async () => {
    if (window.confirm("이후 결제방식을 변경하시려면 관리자에게 문의해주세요.\n진행하시겠습니까?")) {
      try {
        const res = await api.updateContract(contractId, {
          status: 'RENTAL_READY',
        });

        navigator('/solution/rental-wait');
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <SolutionRentalInfoWrapper
      onClick={handleClick}
    />
  )
}
