import moment from 'moment';

export const CHANGE_DATA = 'advertisement/CHANGE_DATA';
export const RESET_DATA = 'advertisement/RESET_DATA';

export const changeAdvertisementData = (payload) => ({
  type: CHANGE_DATA,
  payload,
});
export const resetAdvertisementData = () => ({ type: RESET_DATA });

const initialState = {
  selectedCategory: {},
  selectedBusinessCategories: [],
  videoUrl: '',
  startDate: moment(new Date()).add(14, 'days').format('YYYY-MM-DD'),
  endDate: moment(new Date())
    .add(14, 'days')
    .add(Number('7') - 1, 'days')
    .format('YYYY-MM-DD'),
  minAdPurchaseDays: 7,
  selectedStores: [],
  estimateInfo: [],
  advertiseOrderId: null,
  merchantUid: null,
};

function advertisement(state = initialState, action) {
  switch (action.type) {
    case CHANGE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_DATA:
      return {
        selectedCategory: {},
        selectedBusinessCategories: [],
        videoUrl: '',
        startDate: moment(new Date()).add(14, 'days').format('YYYY-MM-DD'),
        endDate: moment(new Date())
          .add(14, 'days')
          .add(Number('1') - 1, 'days')
          .format('YYYY-MM-DD'),
        selectedStores: [],
        estimateInfo: [],
        advertiseOrderId: null,
        merchantUid: null,
      };
    default:
      return state;
  }
}

export default advertisement;
