import React from 'react'
import Header from 'components/views/Header/Header'
import FindPassword from 'components/views/FindPassword/FindPassword'

export default function FindPasswordWrapper({
    email,
    onChange,
    onClick,
}) {
    return (
        <>
            <Header />
            <FindPassword
                email={email}
                onChange={onChange}
                onClick={onClick}
            />
        </>
    )
}
