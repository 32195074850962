import { PROCESS } from 'constants/signup';

export const CHANGE_DATA = 'signup/CHANGE_DATA';
export const RESET_DATA = 'signup/RESET_DATA';

export const changeData = (payload) => ({ type: CHANGE_DATA, payload });
export const resetSingupData = (payload) => ({ type: RESET_DATA, payload });

const initialState = {
  process: PROCESS.FIRST,
  email: '',
  allAgree: false,
  termsAgree: false,
  privacyAgree: false,
  marketingAgree: false,
  password: '',
  name: '',
  phone: '',
  code: '',
  gender: '', // MALE, FEMALE
  birthday: '',
  businessCategory: '',
  businessSubCategory: '',
  businessNumber: '',
  businessName: '',
  businessAddress: '',
  businessDetailAddress: '',
  businessCustomerAge: '',
  businessOpenTime: '',
  businessCloseTime: '',
  businessOpenMemo: '',
  businessBusyHour: '',
  businessMaxSeat: '',
  businessDailyVisitor: '',
  addressKeyword: '',
  isCheckedEmail: false,
  isPhoneVerified: false,
  isBusinessVerified: false,
  location: '',
  dayoff: [],
  businessEmail: '',
  businessRegistrationImgUrl: '',
  businessBusyHourOpen: '',
  businessBusyHourClose: '',
};

function signup(state = initialState, action) {
  switch (action.type) {
    case CHANGE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
}

export default signup;
