import React, { useEffect, useRef, useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './EightCheckList.module.scss';

const cx = classNames.bind(styles);

export default function EightCheckList() {
  return (
    <div>
      <div className={cx('inner_box')}>
        <div className={cx('title_box')}>
          <h4 className={cx('title')}>LED PC 최소 - 최대 거리</h4>
        </div>
        <div className={cx('text_box')}>
          <p className={cx('text')}>
            최소 10m 거리에서 최대 100m 거리여야 합니다.
          </p>
        </div>
        <div className={cx('img_box')}>
          <img
            className={cx('img')}
            src="/statics/images/led_distance.png"
            alt="led_distance"
          />
        </div>
      </div>
      <div className={cx('inner_box')}>
        <div className={cx('title_box')}>
          <h4 className={cx('title')}>빔프로젝터 PC 최소 - 최대 거리</h4>
        </div>
        <div className={cx('text_box')}>
          <p className={cx('text')}>
            최소 10m 거리에서 최대 100m 거리여야 합니다.
          </p>
        </div>
        <div className={cx('img_box')}>
          <img
            className={cx('img')}
            src="/statics/images/beampc_distance.png"
            alt="beampc_distance"
          />
        </div>
      </div>
      <div className={cx('caution_inner')}>
        <div className={cx('caution_img_box')}>
          <img
            src="/statics/images/ic_input_info_error.png"
            alt="ic_input_info_error"
          />
        </div>
        <div className={cx('caution_text_box')}>
          <p className={cx('caution_text')}>
            디스플레이(빔 or LED)로 부터 서버 PC 까지의 거리는{' '}
            <b className={cx('caution_text_b')}>최소 10~100m를 확보</b>해야
            합니다.
          </p>
        </div>
      </div>
    </div>
  );
}
