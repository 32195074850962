import React, { useCallback, useEffect, useRef, useState } from 'react';
import AdvertisementUploadWrapper from 'components/AdvertisementUploadWrapper';
import useAdvertisement from 'hooks/useAdvertisement';
import * as api from 'apis';

export default function AdvertisementUploadContainer() {
  const [videoLink, setVideoLink] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const { videoUrl, handleChangeAdvertisementData } = useAdvertisement();

  const handleUploadVideo = useCallback(async (e) => {
    try {
      const video = e.target.files[0];
      let formData = new FormData();
      formData.append(e.target.name, video);
      try {
        console.log('vimeoLink ', video);
        const { data: url } = await api.uploadViemo(formData);
        console.log('url123123 ', url);

        // 950472237

        const videoLink = URL.createObjectURL(video);
        setVideoLink(videoLink);
        console.log('vimeoLink ', videoLink);

        handleChangeAdvertisementData({ videoUrl: `${url}` });
      } catch (err) {
        console.log(err);
      }
    } catch (error) {}
    setIsClicked(false);
  }, []);

  const handleCancelVideo = useCallback(() => {
    handleChangeAdvertisementData({ videoUrl: '' });
  }, []);

  return (
    <AdvertisementUploadWrapper
      videoUrl={videoLink}
      handleUploadVideo={handleUploadVideo}
      handleCancelVideo={handleCancelVideo}
      isClicked={isClicked}
      setIsClicked={setIsClicked}
    />
  );
}
