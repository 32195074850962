import React, { useCallback, useState, useMemo, useEffect, useRef, } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import classNames from 'classnames/bind';
import styles from './PDFViewerBox.module.scss';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// Import styles
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import {
//   Document,
//   pdfjs,
//   Page,
// } from 'react-pdf';
// import { StyleSheet, Page, Font } from '@react-pdf/renderer';

// import {
//   PDFViewer, Document,
//   Page, StyleSheet, Font
// } from '@react-pdf/renderer'


const cx = classNames.bind(styles);

// Font.register({
//   family: 'SpoqaHanSans',
//   src:
//     'https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf',
// });

// const pStyles = StyleSheet.create({
//   viewer: {
//     flex: 1,
//   },
//   korean: {
//     fontFamily: 'SpoqaHanSans',
//   },
//   english: {},
// });

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Font.register({ family: "Nanum Gothic", src: "https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-ExtraBold.ttf", });

// const pStyles = StyleSheet.create({
//   text: {
//     fontFamily: 'Nanum Gothic'
//   },
// });


// console.log('url ', `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`);

const PDFViewerBox = ({
  width,
  height,
  fileUrl,
  setCurrentPage,
  currentPage,
  handleLoaded,
}) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setPageNumber(currentPage);
  }, [currentPage]);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
    setCurrentPage(1);
    handleLoaded && handleLoaded(true);
  }, [setNumPages, setPageNumber, handleLoaded]);

  if (!fileUrl) return <></>;

  return (
    <>
      {/* <PDFViewer>
        <Document>
          <Page>
            
          </Page>
        </Document>
      </PDFViewer> */}
      <Document
        file={fileUrl} // 여기는 가지고 계신 pdf 주소
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page width={width} height={height} pageNumber={pageNumber} />
      </Document>
      <div className={cx('pagination')}>
        <button
          className={cx('pagination_button')}
          onClick={() => {
            setPageNumber(prev => prev - 1);
            setCurrentPage(prev => prev - 1);
          }}
          disabled={pageNumber === 1 ? true : false}
          style={{ backgroundImage: "url(/statics/images/pagination_prev_button.png)" }}
        />
        <span className={cx('page_info')}>{pageNumber} / {numPages}</span>
        <button
          className={cx('pagination_button')}
          onClick={() => {
            setPageNumber(prev => prev + 1);
            setCurrentPage(prev => prev + 1);
          }}
          disabled={pageNumber === numPages ? true : false}
          style={{ backgroundImage: "url(/statics/images/pagination_next_button.png)" }}
        />
      </div>
    </>
  );
}

export default PDFViewerBox;