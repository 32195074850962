import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './MypagePaymentArtist.module.scss';
import { numberWithCommas } from 'libs/numbers';
import Pagination from 'components/common/Pagination';
import PaymentButton from 'components/views/PaymentButton';
import PaymentMethod from 'components/views/PaymentMethod';

const cx = classNames.bind(styles);

export default function MypagePaymentArtist({
  handlePaymentStart,
  handleSuccess,
  merchantUid,
  setPayType,
  payType,
  info,
  handleChange,
  billApply,
  setBillApply,
  handleValid,
  fileUrls,
  handleFileUrls,

  pageData,
  totalPrice,
  wishPlayList,
  handlePage,
}) {
  return (
    <>
      <div className={cx('container')}>
        <div className={cx('inner')}>
          <div className={cx('title_box')}>
            <h2 className={cx('title')}>결제 내역</h2>
            <p className={cx('text')}>결제 진행되는 콘텐츠를 확인해 주세요.</p>
          </div>
        </div>
        <table className={cx('table')}>
          <thead className={cx('thead')}>
            <tr className={cx('thead_tr')}>
              <td className={cx('td')}>작품 썸네일</td>
              <td className={cx('td')}>작품명</td>
              <td className={cx('td')}>작가명</td>
              <td className={cx('td')}>구매 금액</td>
            </tr>
          </thead>
          <tbody className={cx('tbody')}>
            {wishPlayList &&
              pageData.currentPage &&
              !!wishPlayList[pageData.currentPage]?.length &&
              wishPlayList[pageData.currentPage]?.map((el) => {
                return (
                  <tr className={cx('tbody_tr')} key={`${el.id}`}>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>작품 썸네일</p>
                      {el?.thumbnailUrl ? (
                        <img
                          className={cx('tbody_tr_img')}
                          src={el?.thumbnailUrl}
                          alt="선택 매체"
                        />
                      ) : (
                        <img
                          className={cx('tbody_tr_img')}
                          src="/statics/images/window.jpg"
                          alt="선택 매체"
                        />
                      )}
                    </td>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>작품명</p>
                      <p className={cx('mobile_tbody_td_name')}>{el?.title}</p>
                    </td>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>작가명</p>
                      <p className={cx('mobile_tbody_td_name')}>
                        {el?.creator?.name}
                      </p>
                    </td>
                    <td className={cx('tbody_td')}>
                      <p className={cx('mobile_tbody_td')}>구매 금액</p>
                      <p className={cx('mobile_tbody_td_name')}>{`${
                        el?.price ? numberWithCommas(el?.price) : ' - '
                      } 원`}</p>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!!pageData?.total && (
          <Pagination
            currentPage={pageData?.currentPage}
            lastPage={pageData?.lastPage}
            perPage={pageData?.perPage}
            handlePage={handlePage}
          />
        )}
        <PaymentMethod
          payType={payType}
          setPayType={setPayType}
          setBillApply={setBillApply}
          billApply={billApply}
          onChange={handleChange}
          onValid={handleValid}
          businessNumber={info?.registrationNumber}
          businessName={info?.businessName}
          businessEmail={info?.businessEmail}
          isBusinessVerified={info?.isBusinessVerified}
          fileUrls={fileUrls}
          handleFileUrls={handleFileUrls}
        />
      </div>
      {!!pageData?.total && (
        <div className={cx('total_container')}>
          <div className={cx('total_inner')}>
            <div className={cx('media_total_inner')}>
              <div className={cx('media_total_payment_inner')}>
                <div className={cx('media_total_box')}>
                  <p className={cx('media_sub_text')}>총 선택 매체 수</p>
                  <p className={cx('media_text')}>
                    {numberWithCommas(pageData?.total)}개
                  </p>
                </div>
                <div className={cx('media_total_payment_box')}>
                  <p className={cx('media_sub_text', 'mobile_media_sub_text')}>
                    총 주문금액
                  </p>
                  <p
                    className={cx(
                      'media_payment_text',
                      'mobile_media_payment_text',
                    )}
                  >
                    {numberWithCommas(totalPrice)}원
                  </p>
                </div>
              </div>
              <PaymentButton
                merchantUid={merchantUid}
                handlePaymentStart={handlePaymentStart}
                handleSuccess={handleSuccess}
                useVerified={payType === 'trans' && billApply}
                isVerified={info?.isBusinessVerified}
                payType={payType}
                amount={totalPrice}
                productName={`작가컨텐츠 ${pageData?.total}개`}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
