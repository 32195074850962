import React from 'react';
import classNames from 'classnames/bind';
import styles from './AdvertisementPaymentCancellation.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';


const cx = classNames.bind(styles);

export default function AdvertisementPaymentCancellation() {
  const navigator = useNavigate();
  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <div className={cx('inner_box')}>
          <div className={cx('title_box')}>
            <p className={cx('title')}>결제가 실패 되었습니다.</p>
          </div>
          <div className={cx('img_box')}>
            <img
              className={cx('img')}
              src="/statics/images/img-ad-video-cancel.png"
              alt="결제 실패 이미지"
            />
          </div>
          <div className={cx('button_box')}>
            <button onClick={() => navigator(-1)} className={cx('button')}>
              돌아가기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
