import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import * as api from 'apis';
import MypagePaymentHistoryDetailWrapper from 'components/MypagePaymentHistoryDetailWrapper';

export default function MypagePaymentHistoryDetailContainer() {
  const location = useLocation();
  const navigator = useNavigate();
  const { id } = qs.parse(location.search);
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({
    name: null,
    url: null,
    type: null,
    location: null,
  });

  useEffect(() => {
    if (!id) {
      alert('잘못된 접근입니다!');
      navigator('/');
      return;
    }

    (async () => {
      try {
        const res = await api.getAdvertiesOrder(id);
        setData(res?.data);
        setSelectedData({
          name: res?.data?.merchant_uid,
          url: res?.data?.videoUrl,
          type: res?.data?.category,
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, [id]);

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return <MypagePaymentHistoryDetailWrapper
    data={data}
    handleClick={handleClick}
    selectedData={selectedData}
    isOpen={isOpen}
    onClose={onClose}
  />;
}
