import React, {
    useState,
} from 'react';
import * as api from 'apis';
import {
    regEmail,
} from 'constants/regExp';
import {
    useNavigate,
} from 'react-router-dom';
import FindPasswordWrapper from 'components/FindPasswordWrapper';

export default function FindPasswordContainer() {
    const navigator = useNavigate();
    const [email, setEmail] = useState('');

    const handleClick = async () => {
        try {
            const test = regEmail.test(email);
            if (!test) {
                alert('이메일 형식에 맞게 입력해주세요.');
                return;
            }
            const res = await api.resetPassword(email);
            if (res?.data?.isSuccess) {
                alert('메일로 임시비밀번호를 발급해드렸습니다.\n확인 후 로그인해주세요 : )');
                navigator('/login');
            } else {
                alert('일치하는 이메일이 없습니다. 다시 한번 확인해주세요.');
            }
        } catch (err) {
            console.log(err);
            alert('일치하는 이메일이 없습니다. 다시 한번 확인해주세요.');
        }
    }

    const handleChange = (e) => {
        setEmail(e.target.value);
    }

    return (
        <FindPasswordWrapper
            email={email}
            onChange={handleChange}
            onClick={handleClick}
        />
    )
}
