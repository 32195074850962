import React from 'react';
import { Header } from 'components/views';
import SolutionRentalWait from 'components/views/SolutionRentalWait';

export default function SolutionRentalWaitWrapper() {
  return (
    <>
        <Header />
        <SolutionRentalWait />
    </>
  )
}
