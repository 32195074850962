import React, {
  useCallback,
  useEffect,
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import * as api from 'apis';
import moment from 'moment';
import useContract from 'hooks/useContract';
import useAuth from 'hooks/useAuth';
import {
  handleCheckSolution,
} from 'libs/authService';
import SolutionContractCompleteWrapper from 'components/SolutionContractCompleteWrapper';

export default function SolutionContractCompleteContainer() {
  const navigator = useNavigate();
  const {
    isLogIn,
  } = useAuth();

  const {
    fileUrl,
    filename,
    handleChangeContractData,
  } = useContract();
  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([api.getMyEstimate().catch(() => { }), api.getMyContract().catch(() => { })]);
        handleChangeContractData({
          fileUrl: res[1]?.data?.fileUrl,
          filename: res[1]?.data?.filename,
        })

        handleCheckSolution(isLogIn, res[0]?.data, res[1]?.data, navigator);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [handleChangeContractData, isLogIn, navigator]);

  const handleClick = useCallback(() => {
    try {
      fetch(fileUrl).then(resp => resp.arrayBuffer()).then(resp => {

        // set the blog type to final pdf
        const file = new Blob([resp], { type: 'application/pdf' });

        // process to auto download it
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `빔몬스터 계약서(${moment().format('YYYY-MM-DD HH:mm:ss')}).pdf`;
        link.click();
        link.remove();
      });
    } catch (err) {

    }
  }, [fileUrl]);

  return (
    <SolutionContractCompleteWrapper
      onClick={handleClick}
    />
  )
}
