import React from 'react'
import classNames from 'classnames/bind';
import styles from './IntroduceSubContent.module.scss';
import useScrollClipPath from 'hooks/useScrollClipPath';
import useScrollFadeIn from 'hooks/useScrollFadeIn';

const cx = classNames.bind(styles);

export default function IntroduceSubContent() {

    return (
        <div className={cx('conatiner')}>
            <div className={cx('sub_conatiner')}>
                <div className={cx('content_box')}>
                    <div className={cx('sub_inner')}>
                        <div {...useScrollFadeIn('up', 1, 0)} className={cx('title_box')}>
                            <h4 className={cx('title')}>
                                앞으로 빔몬스터가<br className={cx('mobile_br')} /> 만들어 갈 세상은..
                            </h4>
                        </div>
                    </div>
                </div>
                <div className={cx('content_bg_inner')}>
                    <img {...useScrollClipPath('left', 1, 0)} className={cx('content_bg')} src="/statics/images/intro_company_interior.png" alt="intro_company_interior" />
                    <div className={cx('inner')}>
                        <div {...useScrollFadeIn('right', 1, 0.2)} className={cx('text_box')}>
                            <p className={cx('text')}>
                                인테리어
                            </p>
                        </div>
                        <div {...useScrollFadeIn('right', 1, 0.3)} className={cx('sub_title_box')}>
                            <p className={cx('sub_title')}>
                                누구나 인테리어를 클릭 한 번으로 쉽게 바꾸고<br className={cx('pc_br')} />
                                미디어 아트를 즐길 수 있는 세상
                            </p>
                        </div>
                    </div>
                </div>
                <div className={cx('content_bg_inner')}>
                    <img {...useScrollClipPath('right', 1, 0)} className={cx('content_bg')} src="/statics/images/intro_company_advertising.png" alt="intro_company_advertising" />
                    <div className={cx('inner')}>
                        <div {...useScrollFadeIn('left', 1, 0.2)} className={cx('text_box')}>
                            <p className={cx('right_text')}>
                                옥외광고
                            </p>
                        </div>
                        <div {...useScrollFadeIn('left', 1, 0.3)} className={cx('sub_title_box')}>
                            <p className={cx('sub_right_title')}>
                                누구나 쉽고 저렴하게 광고주가 되고<br className={cx('pc_br')} />
                                광고 수익을 만들 수 있는 세상
                            </p>
                        </div>
                    </div>
                </div>
                <div className={cx('content_bg_inner')}>
                    <img {...useScrollClipPath('left', 1, 0)} className={cx('content_bg')} src="/statics/images/intro_company_business.png" alt="intro_company_business" />
                    <div className={cx('inner')}>
                        <div {...useScrollFadeIn('right', 1, 0.2)} className={cx('text_box')}>
                            <p className={cx('text')}>
                                아티스트 비즈니스
                            </p>
                        </div>
                        <div {...useScrollFadeIn('right', 1, 0.3)} className={cx('sub_title_box')}>
                            <p className={cx('sub_title')}>
                                아티스트가 플랫폼을 통해<br className={cx('pc_br')} />
                                자신의 창작물만으로 수익을 벌 수 있는 세상
                            </p>
                        </div>
                    </div>
                </div>
                <div className={cx('sub_content_box')}>
                    <div className={cx('sub_inner')}>
                        <div {...useScrollFadeIn('up', 1, 0.2)} className={cx('title_box')}>
                            <h4 className={cx('title')}>
                                빔몬스터는 누구보다<br className={cx('mobile_br')} /> 탁월합니다.
                            </h4>
                        </div>
                    </div>
                </div>
                <img {...useScrollFadeIn('up', 1, 0)} className={cx('sub_content_img')} src="/statics/images/intro_company_shutterstock.png" alt="intro_company_shutterstock" />
                <div className={cx('sub_content_inner')}>
                    <div className={cx('sub_inner')}>
                        <div className={cx('sub_content_menu_inner')}>
                            <div {...useScrollFadeIn('up', 1, 0)} className={cx('sub_content_menu_box')}>
                                <div>
                                    <img src="/statics/images/introduce_sub_first.png" alt="introduce_sub_first" />
                                </div>
                                <div className={cx('sub_content_menu_text_box')}>
                                    <p className={cx('sub_content_menu_text')}>
                                        국내 최고의 미디어 파사드 전문 기술과 노하우 보유
                                    </p>
                                </div>
                                <div className={cx('sub_content_menu_sub_text_box')}>
                                    <p className={cx('sub_content_menu_sub_text')}>
                                        콘텐츠 제작, 하드웨어 설치, 소프트웨어 운용 기술을 모두 갖춘 국내 유일한 기업입니다.
                                    </p>
                                </div>
                            </div>
                            <div {...useScrollFadeIn('up', 1, 0.2)} className={cx('sub_content_menu_box')}>
                                <div>
                                    <img src="/statics/images/introduce_sub_second.png" alt="introduce_sub_second" />
                                </div>
                                <div className={cx('sub_content_menu_text_box')}>
                                    <p className={cx('sub_content_menu_text')}>
                                        국내 최대 규모의 미디어 아트 콘텐츠 보유 기업
                                    </p>
                                </div>
                                <div className={cx('sub_content_menu_sub_text_box')}>
                                    <p className={cx('sub_content_menu_sub_text')}>
                                        지난 7년간 업력을 기반으로 국내 최대 규모의 미디어 아트 콘텐츠를 보유하고 있습니다.
                                    </p>
                                </div>
                            </div>
                            <div {...useScrollFadeIn('up', 1, 0.4)} className={cx('sub_content_menu_box')}>
                                <div>
                                    <img src="/statics/images/introduce_sub_third.png" alt="introduce_sub_third" />
                                </div>
                                <div className={cx('sub_content_menu_text_box')}>
                                    <p className={cx('sub_content_menu_text')}>
                                        각 분야별 전문 협력사와 함께 만드는 압도적인 가격 경쟁력
                                    </p>
                                </div>
                                <div className={cx('sub_content_menu_sub_text_box')}>
                                    <p className={cx('sub_content_menu_sub_text')}>
                                        하드웨어, 소프트웨어, 시스템 및 설비 등 각 분야별 전문 협력 업체를 보유하고 있어 유사 서비스와 비교하여 압도적인 가격 경쟁력을 갖추고 있습니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
