import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { PROCESS } from 'constants/signup';
import { regEmail, regPass, regPhone, regBirthday } from 'constants/regExp';
import SiginupWrapper from 'components/SiginupWrapper/SiginupWrapper';
import useSignup from 'hooks/useSignup';
import * as api from 'apis';

export default function SignupContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isClicked, setIsClicked] = useState(false);
  const [isEmailSuccess, setEmailSuccess] = useState(false);
  const [isEmailError, setEmailError] = useState({
    format: false,
    duplicated: false,
  });
  const [isPasswordError, setPasswordError] = useState({
    format: false,
  });
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [isOtherError, setOtherError] = useState({
    phone: false,
    business: false,
    expired: false,
    code: false,
  });
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const timerRef = useRef(null);

  const { signup, handleChangeData, handleResetSignupData } = useSignup();

  const handleChange = useCallback(
    (e) => {
      if (e.target.name === 'birthday') {
        handleChangeData({
          [e.target.name]: e.target.value.replace(
            /(\d{4})(\d{2})(\d{2})/g,
            '$1-$2-$3',
          ),
        });
        return;
      }

      handleChangeData({ [e.target.name]: e.target.value });
      if (e.target.name === 'email') {
        handleChangeData({ isCheckedEmail: false });
        setEmailError({
          format: false,
          duplicated: false,
        });
      }
      if (e.target.name === 'password') {
        setPasswordError((prev) => ({ ...prev, format: false }));
      }

      if (e.target.name === 'phone') {
        handleChangeData({
          isPhoneVerified: false,
          isSendCode: false,
          code: '',
        });
        setOtherError((prev) => ({
          ...prev,
          phone: false,
          expired: false,
          code: false,
        }));
      }

      if (e.target.name === 'businessNumber') {
        handleChangeData({ isBusinessVerified: false });
        setOtherError((prev) => ({
          ...prev,
          business: false,
        }));
      }
    },
    [handleChangeData, setEmailError, setOtherError],
  );

  const handleOpenPost = useCallback(() => {
    setIsOpenPost(true);
  }, [setIsOpenPost]);

  const handleClosePost = useCallback(() => {
    setIsOpenPost(false);
  }, [setIsOpenPost]);

  const handleEmail = useCallback(async () => {
    try {
      const test = regEmail.test(signup.email);
      if (!test) {
        setEmailError((prev) => ({ ...prev, format: true }));
        return;
      }
      const res = await api.checkEmailDuplicated(signup.email);
      if (!!res.data.isExist === false) {
        setEmailSuccess(true);
        handleChangeData({ isCheckedEmail: true });
        setTimeout(() => {
          setEmailSuccess(false);
        }, 1000);
      } else {
        setEmailError((prev) => ({ ...prev, duplicated: true }));
      }
    } catch (err) {
      console.log(err);
    }
  }, [handleChangeData, signup.email, setEmailError, setEmailSuccess]);

  const handleAgree = useCallback(
    (e) => {
      const allAgree = ['termsAgree', 'privacyAgree', 'marketingAgree'].every(
        (el) => {
          if (el === e.target.name) {
            return !signup[e.target.name];
          } else {
            return signup[el];
          }
        },
      );

      handleChangeData({
        [e.target.name]: !signup[e.target.name],
        allAgree,
      });
    },
    [handleChangeData, signup],
  );

  const handleAllAgree = useCallback(
    (e) => {
      handleChangeData({
        [e.target.name]: !signup[e.target.name],
        termsAgree: !signup[e.target.name],
        privacyAgree: !signup[e.target.name],
        marketingAgree: !signup[e.target.name],
      });
    },
    [handleChangeData, signup],
  );

  const handleEmailProcess = useCallback(() => {
    if (!signup.isCheckedEmail || !signup.termsAgree || !signup.privacyAgree) {
      return;
    }

    handleChangeData({
      process: PROCESS.SECOND,
    });
  }, [handleChangeData, signup]);

  const handlePasswordProcess = useCallback(() => {
    if (!regPass.test(signup.password)) {
      setPasswordError((prev) => ({ ...prev, format: true }));
      return;
    }

    handleChangeData({
      process: PROCESS.THIRD,
    });
  }, [handleChangeData, signup.password]);

  const handleVerifiedPhone = useCallback(async () => {
    if (!regPhone.test(signup.phone)) {
      setOtherError((prev) => ({ ...prev, phone: true }));
      return;
    }
    handleChangeData({ isSendCode: true });
    setOtherError((prev) => ({
      ...prev,
      phone: false,
      expired: false,
      code: false,
    }));
    setStartTime(new Date().getTime());
    setEndTime(new Date().getTime() + 1000 * 60 * 3);
    try {
      const res = await api.sendPhoneCode(signup.phone);
      handleChangeData({ isSendCode: true });
    } catch (err) {
      console.log(err);
    }
  }, [signup.phone, setOtherError, handleChangeData, setStartTime, setEndTime]);

  const handleCode = useCallback(async () => {
    try {
      const res = await api.checkEmailOrPhone(signup.phone, signup.code);
      if (res?.data?.isSuccess) {
        handleChangeData({ isPhoneVerified: true });
      } else {
        setOtherError((prev) => ({
          ...prev,
          phone: false,
          expired: false,
          code: true,
        }));
        handleChangeData({
          isPhoneVerified: false,
          isSendCode: false,
          code: '',
        });
      }
      clearInterval(timerRef.current);
      timerRef.current = null;
    } catch (err) {
      setOtherError((prev) => ({
        ...prev,
        code: true,
        expired: false,
        phone: false,
      }));
      handleChangeData({ isPhoneVerified: false, isSendCode: false, code: '' });
      clearInterval(timerRef.current);
      timerRef.current = null;
      console.log(err);
    }
  }, [signup.phone, signup.code, setOtherError, handleChangeData]);

  const handleCheckBusinessNumber = useCallback(async () => {
    try {
      const res = await api.checkBusinessNumber(signup.businessNumber);
      if (res?.data?.isValid) {
        handleChangeData({ isBusinessVerified: true });
      } else {
        handleChangeData({ isBusinessVerified: false });
        setOtherError((prev) => ({
          ...prev,
          business: true,
        }));
      }
    } catch (err) {
      handleChangeData({ isBusinessVerified: false });
      setOtherError((prev) => ({
        ...prev,
        business: true,
      }));
      console.log(err);
    }
  }, [signup.businessNumber, handleChangeData]);

  const handleEditPhone = useCallback(() => {
    if (window.confirm('수정하시겠습니까?')) {
      handleChangeData({
        phone: '',
        isPhoneVerified: false,
        isSendCode: false,
        code: '',
      });
    }
  }, [handleChangeData]);

  const handleClickAddress = useCallback(
    (zonecode, address) => {
      handleChangeData({ businessAddress: address, businessDetailAddress: '' });
    },
    [handleChangeData],
  );

  const handleClickGender = useCallback(
    (gender) => {
      handleChangeData({ gender });
    },
    [handleChangeData],
  );

  const handleSubmit = useCallback(async () => {
    if (isClicked) return;
    setIsClicked(true);
    try {
      const {
        email,
        password,
        name,
        phone,
        gender,
        birthday,
        isPhoneVerified,
        businessCategory,
        businessNumber,
        businessName,
        businessAddress,
        businessDetailAddress,
        businessCustomerAge,
        businessOpenTime,
        businessCloseTime,
        businessOpenMemo,
        businessBusyHour,
        businessMaxSeat,
        businessDailyVisitor,
        marketingAgree,
      } = signup;

      if (!name) {
        alert('이름을 입력해주세요 : )');
        return;
      }

      if (!phone) {
        alert('연락처를 입력해주세요 : )');
        return;
      }

      if (!isPhoneVerified) {
        alert('연락처를 먼저 인증해주세요 : )');
        return;
      }

      if (!birthday) {
        alert('생년월일을 입력해주세요 : )');
        return;
      }

      if (!regBirthday.test(birthday)) {
        alert('생년월일 형식에 맞게 입력해주세요 : )');
        return;
      }

      if (!gender) {
        alert('성별을 선택해주세요 : )');
        return;
      }

      // if (!businessCategory) {
      //     alert('사업자 업종을 입력해주세요 : )');
      //     return;
      // }

      // if (!businessNumber) {
      //     alert('사업자 번호를 입력해주세요 : )');
      //     return;
      // }

      // if (!businessName) {
      //     alert('사업자 상호를 입력해주세요 : )');
      //     return;
      // }

      // if (!businessAddress) {
      //     alert('사업장 주소를 입력해주세요 : )');
      //     return;
      // }

      // if (!businessDetailAddress) {
      //     alert('사업장 상세주소를 입력해주세요 : )');
      //     return;
      // }

      // if (!businessCustomerAge) {
      //     alert('고객 연령대를 선택해주세요 : )');
      //     return;
      // }

      // if (!businessOpenTime) {
      //     alert('매장 오픈시간을 선택해주세요 : )');
      //     return;
      // }

      // if (!businessCloseTime) {
      //     alert('매장 마감시간을 선택해주세요 : )');
      //     return;
      // }

      // if (!businessBusyHour) {
      //     alert('고객 최대 방문 시간대를 입력해주세요 : )');
      //     return;
      // }

      // if (!businessMaxSeat) {
      //     alert('고객 수용인원을 입력해주세요 : )');
      //     return;
      // }

      // if (!businessDailyVisitor) {
      //     alert('고객 평균 방문자를 입력해주세요 : )');
      //     return;
      // }

      const res = await api.signup({
        email,
        password,
        name,
        phone,
        gender,
        birthday: new Date(birthday),
        // businessCategory,
        // businessNumber,
        // businessName,
        // businessAddress,
        // businessDetailAddress,
        // businessCustomerAge,
        // businessOpenTime,
        // businessCloseTime,
        // businessOpenMemo,
        // businessBusyHour,
        // businessMaxSeat,
        // businessDailyVisitor,
        marketingAgree,
      });

      alert(
        '회원가입에 성공하셨습니다 : )\n이메일 인증을 해주시고 로그인해주세요.',
      );
      navigate('/login');
    } catch (err) {
      console.log(err);
    }
    setIsClicked(false);
  }, [isClicked, signup]);

  const handlePrivacy = () => {
    window.open('/privacy');
  };

  const handleTerms = () => {
    window.open('/terms');
  };

  useEffect(() => {
    if (!signup.isSendCode) {
      return;
    }

    if (startTime > endTime) {
      clearInterval(timerRef.current);
      timerRef.current = null;
      handleChangeData({ isPhoneVerified: false, isSendCode: false, code: '' });
      setOtherError((prev) => ({
        ...prev,
        expired: true,
      }));
      return;
    }

    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    // setInterval 사용부
    timerRef.current = setInterval(() => {
      setStartTime(new Date().getTime());
    }, 1000);
  }, [
    timerRef,
    signup.isSendCode,
    startTime,
    endTime,
    setOtherError,
    handleChangeData,
  ]);

  useEffect(() => {
    handleResetSignupData();
  }, [location, handleResetSignupData]);

  return (
    <SiginupWrapper
      signup={signup}
      isOpenPost={isOpenPost}
      handleOpenPost={handleOpenPost}
      handleClosePost={handleClosePost}
      isEmailSuccess={isEmailSuccess}
      isEmailError={isEmailError}
      isCheckedEmail={signup.isCheckedEmail}
      isPhoneVerified={signup.isPhoneVerified}
      handleEmail={handleEmail}
      handleChange={handleChange}
      handleAgree={handleAgree}
      handleAllAgree={handleAllAgree}
      emailProcessDisabled={
        !signup.isCheckedEmail || !signup.termsAgree || !signup.privacyAgree
      }
      handleEmailProcess={handleEmailProcess}
      isPasswordError={isPasswordError}
      handlePasswordProcess={handlePasswordProcess}
      isPhoneError={isOtherError.phone}
      isBusinessError={isOtherError.business}
      isPhoneExpired={isOtherError.expired}
      isCodeError={isOtherError.code}
      handleCode={handleCode}
      handleVerifiedPhone={handleVerifiedPhone}
      startTime={startTime}
      endTime={endTime}
      handleEditPhone={handleEditPhone}
      handleClickAddress={handleClickAddress}
      handleCheckBusinessNumber={handleCheckBusinessNumber}
      handleClickGender={handleClickGender}
      handleSubmit={handleSubmit}
      handlePrivacy={handlePrivacy}
      handleTerms={handleTerms}
    />
  );
}
