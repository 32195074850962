import React, {
    useState,
} from 'react';
import {
    regPass,
} from 'constants/regExp';
import * as api from 'apis';
import useAuth from 'hooks/useAuth';
import {
    useNavigate,
} from 'react-router-dom';
import MypagePasswordWrapper from 'components/MypagePasswordWrapper/MypagePasswordWrapper';

export default function MypagePasswordContainer() {
    const navigator = useNavigate();
    const {
        email,
        handleResetAuthData,
    } = useAuth();
    const [data, setData] = useState({
        password: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [isPasswordError, setIsPasswordError] = useState({
        format: false,
        same: false,
        check: false,
        unvalid: false,
    });

    const handleChange = (e) => {
        setData(prev => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
        setIsPasswordError({
            format: false,
            same: false,
            check: false,
            unvalid: false,
        });
    }

    const handleSubmit = async () => {
        try {
            if (data.password === data.newPassword) {
                setIsPasswordError(prev => ({ ...prev, same: true }));
                return;
            }

            if (!regPass.test(data.newPassword)) {
                setIsPasswordError(prev => ({ ...prev, format: true }));
                return;
            }

            if (data.newPassword !== data.confirmPassword) {
                setIsPasswordError(prev => ({ ...prev, check: true }));
                return;
            }

            const res = await api.login(email, data.password);
            if (res.status === 201) {
                await api.updateMyInfo({
                    password: data.newPassword,
                });
                await localStorage.clear();
                await sessionStorage.clear();
                handleResetAuthData();
                alert('비밀번호 변경 완료 : )');
                navigator('/login');
            } else {
                setIsPasswordError(prev => ({ ...prev, unvalid: true }));
            }
        } catch (err) {
            console.log(err);
            setIsPasswordError(prev => ({ ...prev, unvalid: true }));
        }
    }

    return (
        <MypagePasswordWrapper
            password={data.password}
            newPassword={data.newPassword}
            confirmPassword={data.confirmPassword}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isPasswordError={isPasswordError}
        />
    )
}
