import React, { useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './MypagAsList.module.scss';
import { Link } from 'react-router-dom';
import mockdata from '../Mockup/Mockup';

const cx = classNames.bind(styles);

export default function MypagAsList() {

    const [mockData, setMockData] = useState([]);

    useEffect(() => {
        const fetchMockData = () => {
            const res = mockdata.asList;
            setMockData(res);
        }
        fetchMockData();
    }, [])


    const mockMap = (mockData) => {

        return (
            mockData.map((el, i) => {

                return (
                    <div className={cx('table_data_inner')} key={i}>
                        <div className={cx('table_data_box')}>
                            <p className={cx('table_data')}>
                                {el.date}
                            </p>
                        </div>
                        <div className={cx('table_data_box')}>
                            <p className={cx('table_data')}>
                                {el.state}
                            </p>
                        </div>
                        <div className={cx('table_data_box')}>
                            <p className={cx('history_table_data')}>
                                {el.history}
                            </p>
                            <Link to='/mypage/as/detail' className={cx('detail_box')}>
                                <button className={cx('detail_button')}>
                                    상세보기
                                </button>
                            </Link>
                        </div>
                    </div>
                )
            })
        )
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [mockDataPerPage, setMockDataPerPage] = useState(10);

    const [pageNumberLimit, setPageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

    const handleClick = (e) => {
        setCurrentPage(Number(e.target.id));
    };

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(mockData.length / mockDataPerPage); i++) {
        pageNumbers.push(i)
    };

    const indexOfLastPost = currentPage * mockDataPerPage;
    const indexOfFirstPost = indexOfLastPost - mockDataPerPage;
    const currnetMockData = mockData.slice(indexOfFirstPost, indexOfLastPost);

    const renderPageNumbers = pageNumbers.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <div className={cx('pagination_number_box')}>
                    <p
                        key={number}
                        id={number}
                        onClick={handleClick}
                        className={cx(currentPage === number ? "active_pagination_number" : 'pagination_number')}>
                        {number}
                    </p>
                </div>
            );
        } else {
            return null;
        }
    });

    const handleNexbtn = () => {
        setCurrentPage(currentPage + 1)

        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const handlePrevbtn = () => {
        setCurrentPage(currentPage - 1)

        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };

    return (
        <div className={cx('container')}>
            <div className={cx('sub_container')}>
                <div className={cx('inner')}>
                    <Link to='/mypage' className={cx('back_box')}>
                        <img className={cx('back_img')} src="/statics/images/ic_profile_back.png" alt="ic_profile_back" />
                        <div className={cx('back_text_box')}>
                            <p className={cx('back_text')}>마이페이지</p>
                        </div>
                    </Link>
                    <div className={cx('inner_box')}>
                        <div className={cx('header_inner')}>
                            <div className={cx('title_inner')}>
                                <Link to='/mypage/schedule/access' className={cx('sub_title_box')}>
                                    <h4 className={cx('sub_title')}>
                                        설치 스케줄 현황
                                    </h4>
                                </Link>
                                <Link to='/mypage/as' className={cx('title_box')}>
                                    <h4 className={cx('title')}>
                                        A/S 신청 현황
                                    </h4>
                                    <hr className={cx('hr')} />
                                </Link>
                            </div>
                            <Link to='/mypage/faq' className={cx('change_box')}>
                                <p className={cx('change_text')}>
                                    자주 묻는 질문 (FAQ)
                                </p>
                            </Link>
                        </div>
                        <div className={cx('text_box')}>
                            <p className={cx('text')}>
                                자세한 문의는 하단의 고객센터 전화번호 또는 이메일로 문의해주세요.
                            </p>
                        </div>
                        <div className={cx('table_inner')}>
                            <div className={cx('table_header')}>
                                <div className={cx('table_box')}>
                                    <p className={cx('table_header_title')}>
                                        날짜
                                    </p>
                                </div>
                                <div className={cx('table_box')}>
                                    <p className={cx('table_header_title')}>
                                        상태
                                    </p>
                                </div>
                                <div className={cx('table_box')}>
                                    <p className={cx('table_header_title')}>
                                        내역
                                    </p>
                                </div>
                            </div>
                            <div className={cx('table_body')}>
                                {mockMap(currnetMockData)}
                            </div>
                            <div className={cx('pagination_inner')}>
                                <div className={cx('pagination_box')}>
                                    <div>
                                        <button
                                            className={cx('pagination_button')}
                                            onClick={handlePrevbtn}
                                            disabled={currentPage === pageNumbers[0] ? true : false}
                                            style={{ backgroundImage: "url(/statics/images/pagination_prev_button.png)" }}
                                        />
                                    </div>
                                    <div className={cx('pagination_number_inner')}>
                                        {renderPageNumbers}
                                    </div>
                                    <div>
                                        <button
                                            className={cx('pagination_button')}
                                            onClick={handleNexbtn}
                                            disabled={currentPage === pageNumbers[pageNumbers.length - 1] ? true : false}
                                            style={{ backgroundImage: "url(/statics/images/pagination_next_button.png)" }}
                                        />
                                    </div>
                                </div>
                                <Link to='/mypage/as/inquiry' className={cx('button_box')}>
                                    <button className={cx('button')}>새로운 A/S 접수하기</button>
                                </Link>
                            </div>
                            <div className={cx('info_inner')}>
                                <p className={cx('info_text')}>
                                    <b className={cx('info_text_wait')}>접수 대기</b> <b className={cx('mobile_b')}>:</b> <br className={cx('mobile_br')} />문제를 해결해드릴 A/S 담당자를 배정 중입니다.
                                </p>
                                <p className={cx('info_text')}>
                                    <b className={cx('info_text_assignment')}>담당자 배정</b> <b className={cx('mobile_b')}>:</b> <br className={cx('mobile_br')} />A/S 담당자가 배정되었습니다. 24간 이내에 전화드리겠습니다.
                                </p>
                                <p className={cx('info_text')}>
                                    <b className={cx('info_text_complete')}>접수 완료</b> <b className={cx('mobile_b')}>:</b> <br className={cx('mobile_br')} />접수 처리가 완료되었습니다. 안내된 스케줄에 맞춰 조치 예정입니다.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
