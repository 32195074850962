import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash/fp';
import { useSelector, useDispatch } from 'react-redux';
import { changeAuthData, resetAuthData } from 'store/modules/auth';
import * as Sentry from '@sentry/react';

export default function useAuth() {
  const navigator = useNavigate();
  const {
    isLogIn,
    name,
    phone,
    profileImageUrl,
    birthday,
    businessAddress,
    businessDetailAddress,
    businessBusyHour,
    businessCategory,
    businessSubCategory,
    businessCloseTime,
    businessCustomerAge,
    businessDailyVisitor,
    businessMaxSeat,
    businessName,
    businessNumber,
    businessOpenTime,
    businessTimeMemo,
    contract,
    email,
    estimate,
    gender,
    dayoff,
    playlistResetCount,
    isUnlimitedPlaylist,
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleChangeAuthData = useCallback(
    (payload) => dispatch(changeAuthData(payload)),
    [dispatch],
  );

  const handleResetAuthData = useCallback(
    () => dispatch(resetAuthData()),
    [dispatch],
  );

  const checkAuthPlay = async (estimate, contract) => {
    try {
      const scope = new Sentry.Scope();
      scope.setTag('playAuth', 'playAuthError');

      scope.setContext('playAuthContext', {
        name,
        phone,
        estimate,
        contract,
        email,
        businessName,
        businessAddress,
      });

      Sentry.captureException(estimate, () => scope);
    } catch (err) {
      const scope = new Sentry.Scope();
      scope.setTag('playAuthError', 'playAuthError');

      scope.setContext('playAuthContext', {
        name,
        phone,
        estimate,
        contract,
        email,
        businessName,
        businessAddress,
      });

      Sentry.captureException(err, () => scope);
    }
  };

  // const TEST_ACCOUNT_LIST = [
  //   'lys20741@naver.com',
  //   'z@zdimension.co.kr',
  //   'lys20741@gmail.com',
  //   'cammac@artshudder.com',
  //   'cammac@naver.com',
  //   'ngwoo333@nate.com',
  // ];
  // const isTestAccount = TEST_ACCOUNT_LIST.indexOf(email) > -1;

  const isTempBlock = useMemo(
    () =>
      process.env.REACT_APP_TEMP_BLOCK
        ? process.env.REACT_APP_TEMP_BLOCK === 'YES'
        : false,
    [],
  );

  return {
    isLogIn,
    name,
    phone,
    profileImageUrl,
    birthday,
    businessAddress,
    businessDetailAddress,
    businessBusyHour,
    businessCategory,
    businessSubCategory,
    businessCloseTime,
    businessCustomerAge,
    businessDailyVisitor,
    businessMaxSeat,
    businessName,
    businessNumber,
    businessOpenTime,
    businessTimeMemo,
    contract,
    email,
    estimate,
    gender,
    dayoff,
    handleChangeAuthData,
    handleResetAuthData,
    playlistResetCount,
    isUnlimitedPlaylist,
    // isTestAccount,
    isTempBlock,
    checkAuthPlay,
  };
}
