import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './AdvertisementCalculation.module.scss';
import { numberWithCommas } from 'libs/numbers';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function AdvertisementCalculation({ estimateInfo }) {
  const [info, setInfo] = useState({
    storeUserCount: null,
    dailyViewCount: null,
    totalViewCount: null,
    dailyVisitorCount: null,
    totalVisitorCount: null,
    perPrice: null,
    totalPrice: null,
  });

  // 광고 업로드 네비게이터
  const navigator = useNavigate();

  const uploadNavigator = () => {
    navigator('/advertisement/upload');
  };

  const calculation = useMemo(() => {
    const res = [...estimateInfo]?.reduce(
      (acc, item) => {
        acc.dailyViewCount += item.dailyViewCount;
        acc.totalViewCount += item.totalViewCount;
        acc.dailyVisitorCount += item.dailyVisitorCount;
        acc.totalVisitorCount += item.totalVisitorCount;
        acc.totalPrice += item.price;
        return acc;
      },
      {
        dailyViewCount: 0,
        totalViewCount: 0,
        dailyVisitorCount: 0,
        totalVisitorCount: 0,
        totalPrice: 0,
      },
    );
    setInfo({
      ...res,
      storeUserCount: estimateInfo?.length,
      perPrice: Math.ceil(res?.totalPrice / res?.totalViewCount),
    });
  }, [estimateInfo]);

  return (
    <>
      <div className={cx('category_inner')}>
        <div className={cx('category_title_box')}>
          <p className={cx('category_title')}>
            산출 내역
            <b className={cx('category_text_b')}>VAT 별도</b>
          </p>
        </div>
        <div className={cx('calculation_inner')}>
          <div className={cx('calculation_box')}>
            <div className={cx('calculation_item')}>
              <p className={cx('calculation_text')}>매체수</p>
              <p className={cx('calculation_number')}>
                {numberWithCommas(info?.storeUserCount)}개
              </p>
            </div>
            <div className={cx('calculation_item')}>
              <p className={cx('calculation_text')}>일 노출수</p>
              <p className={cx('calculation_number')}>
                {numberWithCommas(Math.round(info?.dailyViewCount))}회
              </p>
            </div>
            <div className={cx('calculation_item')}>
              <p className={cx('calculation_text')}>총 노출수</p>
              <p className={cx('calculation_number')}>
                {numberWithCommas(Math.round(info?.totalViewCount))}회
              </p>
            </div>
            <div className={cx('calculation_item')}>
              <p className={cx('calculation_text')}>일 노출량</p>
              <p className={cx('calculation_number')}>
                {numberWithCommas(Math.round(info?.dailyVisitorCount))}회
              </p>
            </div>
            <div className={cx('calculation_item')}>
              <p className={cx('calculation_text')}>총 노출량</p>
              <p className={cx('calculation_number')}>
                {numberWithCommas(Math.round(info?.totalVisitorCount))}회
              </p>
            </div>
            <div className={cx('calculation_item')}>
              <p className={cx('calculation_text')}>1회 노출 광고비</p>
              <p className={cx('calculation_number')}>
                {numberWithCommas(info?.perPrice)}원
              </p>
            </div>
          </div>
          <div className={cx('calculation_hr')}></div>
          <div className={cx('calculation_total_money_box')}>
            <p className={cx('calculation_total_money')}>
              {numberWithCommas(info?.totalPrice)}원
            </p>
            <p className={cx('calculation_total_sale_money')}>
              {numberWithCommas(info?.totalPrice * 1.5)}원
            </p>
          </div>
        </div>
      </div>
      <div className={cx('upload_button_box')}>
        <button onClick={uploadNavigator} className={cx('upload_button')}>
          광고 업로드
        </button>
      </div>
    </>
  );
}
