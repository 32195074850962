import React from 'react';
import Footer from 'components/views/Footer/Footer';
import Header from 'components/views/Header/Header';
import MypageSecession from 'components/views/MypageSecession/MypageSecession';

export default function MypageSecessionWrapper({
    password,
    isChecked,
    handleChange,
    handleClick,
    handleSubmit,
}) {
    return (
        <>
            <Header />
            <MypageSecession
                password={password}
                isChecked={isChecked}
                handleChange={handleChange}
                handleClick={handleClick}
                handleSubmit={handleSubmit}
            />
            <Footer />
        </>
    )
}
